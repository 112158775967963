/** @format */

import React from "react";
import { Col, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { useSelector } from "react-redux";
import { selectorLoggedUser } from "../../../redux-modules/selectors/selectorsAuth";
import { BaseColor } from "../../../theme/maps/Colors";
import { useGenericAlert } from "../../../redux-modules/hooks/useGenericAlert";
import { WidgetRecentDocuments } from "../../layout/Widgets/RecentDocuments";
import { WidgetMyRequest } from "../../layout/Widgets/CreateRequest";
import { WidgetCreatePresence } from "../../layout/Widgets/CreatePresence";
import { isMobileOnly } from "react-device-detect";
import { WidgetHolidaysCounter } from "../../layout/Widgets/HolidaysCounter";
import { WidgetPresenceNow } from "../../layout/Widgets/PresencesNow";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";

export const ViewHome = (): JSX.Element => {
  const loggedUser = useSelector(selectorLoggedUser);
  const Alert = useGenericAlert(6000);
  const permissionList = useSelector(selectorPermissionList);
  const canViewPresencesNow = hasPermission(permissionList, "Group", "Canshowpresencetoday");

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {Alert}
          <Row gutter={[8, 8]}>
            {loggedUser && (
              <>
                <UITitle
                  level={isMobileOnly ? 3 : 2}
                  color={BaseColor}
                  data-cy="page-title"
                  style={{ margin: isMobileOnly ? "20px 5px" : "0px 0px 20px 5px" }}
                >
                  Benvenuto {loggedUser.name} {loggedUser.surname}
                </UITitle>
              </>
            )}
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <WidgetCreatePresence />
            </Col>
            <Col xs={24} xl={12}>
              <WidgetHolidaysCounter />
            </Col>
            {canViewPresencesNow && loggedUser?.groups.length ? (
              <Col xs={24} xl={12}>
                <WidgetPresenceNow />
              </Col>
            ) : (
              <></>
            )}
            <Col xs={24} xl={12}>
              <WidgetMyRequest />
            </Col>
            <Col xs={24} xl={12}>
              <WidgetRecentDocuments theme="azure" />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
