/** @format */

import { css, keyframes } from "styled-components";

export const fadeInAnimation = keyframes`
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
`;

export const fadeIn = css`
  animation: ${fadeInAnimation} 1s;
`;
