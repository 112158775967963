/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaCacheSave, sagaCacheRemove } from "../sagas/sagasCache";

export function* watcherCache(): unknown {
  yield takeLatest(TActionType.loginSuccess, sagaCacheSave);
  yield takeLatest(TActionType.permissionListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.usersListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.groupsListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.documentListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.locationListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.documentTypeListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.documentListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.personalDocumentListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.justificativeStatusListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.modelDetailListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.requestListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.notificationListSuccess, sagaCacheSave);
  yield takeLatest(TActionType.notificationReadSuccess, sagaCacheSave);
  yield takeLatest(TActionType.notificationDeleteSuccess, sagaCacheSave);
  yield takeLatest(TActionType.menuCollapseRequest, sagaCacheSave);
  yield takeLatest(TActionType.menuUncollapseRequest, sagaCacheSave);
  yield takeLatest(TActionType.CustomStyleOptionUpdateSuccess, sagaCacheSave);
  // logout
  yield takeLatest(TActionType.logoutSuccess, sagaCacheRemove);
}
