/** @format */

import React, { useEffect, useState } from "react";
import { Checkbox, Col, Form, Row, Skeleton } from "antd";
import { Routes } from "../../../../../utils/Routes";
import { SETTINGSTABS } from "../../../../../constants/settings";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { actionDocumentTypeUpdateRequest } from "../../../../../redux-modules/actions/actionsDocumentType";
import { selectorDocumentTypeUpdated } from "../../../../../redux-modules/selectors/selectorsUI";
import { UIButton } from "../../../../ui/Button";
import { UICard } from "../../../../ui/Card";
import { UIInput } from "../../../../ui/Input";
import { UITitle } from "../../../../ui/Title";
import { API_ENDPOINTS } from "../../../../../constants/api";
import apiClient from "../../../../../utils/apiClient";
import { UILink } from "../../../../ui/Link";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

export const ViewDocumentTypeEdit = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const routePaths = history.location.pathname.split("/");
  const id_document_type = routePaths[routePaths.length - 1];
  const [documentType, setDocumentType] = useState(undefined);
  const isDocumentTypeUpdated = useSelector(selectorDocumentTypeUpdated);
  const [form] = Form.useForm();
  const [showShared, setShowShared] = useState(true);

  useEffect(() => {
    if (isDocumentTypeUpdated) history.push(Routes.settings + SETTINGSTABS.DOCUMENT_TYPES);
  }, [isDocumentTypeUpdated]);

  //@ts-ignore
  const handleUpdate = (e): void => {
    const contentObj = {
      id: id_document_type,
      name: e.name,
      split: e.split || false,
      shared: e.shared || false,
    };
    //dispatch
    dispatch(actionDocumentTypeUpdateRequest(contentObj));
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      const response = await apiClient.get(API_ENDPOINTS.DOCUMENT_TYPES + "/" + id_document_type);
      //@ts-ignore
      setDocumentType(response.data.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (documentType) onChangeForm();
  }, [documentType]);

  const onChangeForm = () => {
    if (form) {
      setShowShared(!form.getFieldValue("split"));
    }
  };

  return !documentType ? (
    <UICard style={{ marginBottom: "20px" }}>
      <Skeleton active />
    </UICard>
  ) : (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={4}>Modifica Tipologia Documento</UITitle>
        </Col>
      </Row>
      <Form
        name="new-document-type"
        className="new-document-type"
        onFinish={handleUpdate}
        initialValues={documentType}
        layout="vertical"
        form={form}
        onChange={onChangeForm}
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: "Inserisci un nome!" }]}
                    extra="Il nome della tipologia"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="split"
                    valuePropName="checked"
                    noStyle
                    rules={[{ required: false, message: "Seleziona un valore" }]}
                    extra={`Il documento deve essere splittato`}
                  >
                    <Checkbox>Split</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              {showShared && (
                <Row gutter={[8, 8]}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="shared"
                      valuePropName="checked"
                      noStyle
                      rules={[{ required: false, message: "Seleziona un valore" }]}
                      extra={`Il documento deve essere condiviso`}
                    >
                      <Checkbox>Condivisibile</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </UICard>
          </Col>
        </Row>
        <Row justify="end" gutter={[24, 24]}>
          <Col>
            <UILink
              route={Routes.settings + SETTINGSTABS.DOCUMENT_TYPES}
              label={<UIButton>Annulla</UIButton>}
            />
          </Col>
          <Col>
            <Form.Item>
              <UIButton type="primary" htmlType="submit">
                Salva
              </UIButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
