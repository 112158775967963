/** @format */

import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UISearch } from "../../ui/SearchInput";
import { UITable } from "../../ui/Table";
import { UIButton } from "../../ui/Button";
import { Routes } from "../../../utils/Routes";
import { locationListColumns } from "../../../constants/tables/locationListColumns";
import { TablePaginationConfig } from "antd/lib/table";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedInput } from "../../../redux-modules/hooks/useDebouncedInput";
import { selectorLocationsList } from "../../../redux-modules/selectors/selectorsLocations";
import {
  selectorLocationDeleted,
  selectorLocationsListLoading,
} from "../../../redux-modules/selectors/selectorsUI";
import { utilityFormatLocationsToTableDataSourceList } from "../../../utils/format/locationTable";
import { actionLocationListRequest } from "../../../redux-modules/actions/actionsLocations";
import { UICard } from "../../ui/Card";
import UITransition from "../../ui/Transition";
import { UILink } from "../../ui/Link";

export const ViewLocationList = (): JSX.Element => {
  const dispatch = useDispatch();
  const locationList = useSelector(selectorLocationsList);
  const locationListLoading = useSelector(selectorLocationsListLoading);
  const isLocationDeleted = useSelector(selectorLocationDeleted);

  useEffect(() => {
    if (isLocationDeleted)
      dispatch(
        actionLocationListRequest({
          query: debouncedQuery || undefined,
          columns: ["name", "address"],
        }),
      );
  }, [isLocationDeleted]);

  const [debouncedQuery, currentQuery, setQuery] = useDebouncedInput("", () => true, false, 600);

  const formattedLocationList = utilityFormatLocationsToTableDataSourceList(locationList.data);

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    dispatch(
      actionLocationListRequest({
        page: JSON.stringify(pagination.current || pagination.defaultCurrent),
        query: debouncedQuery || undefined,
        columns: ["name", "address"],
      }),
    );
  };

  useEffect(() => {
    dispatch(
      actionLocationListRequest({
        query: debouncedQuery || undefined,
        columns: ["name", "address"],
      }),
    );
  }, [debouncedQuery]);
  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Sedi
          </UITitle>
        </Col>
        <Col>
          <UILink
            data-cy="new-location-button"
            route={Routes.locationsNew}
            label={<UIButton type="primary">+ Aggiungi sede</UIButton>}
          />
        </Col>
      </Row>
      <UICard>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col>
            <UISearch placeholder="Cerca..." onChange={setQuery} value={currentQuery} />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UITable
              size="middle"
              data-cy="location-list-table"
              loading={locationListLoading}
              columns={locationListColumns}
              dataSource={formattedLocationList}
              onChange={handleTableChange}
              pagination={{ ...locationList.pagination }}
            />
          </Col>
        </Row>
      </UICard>
    </UITransition>
  );
};
