/** @format */

import { AxiosResponse } from "axios";
import { put, all, call, select } from "redux-saga/effects";
import {
  apiDocumentCreate,
  apiDocumentDelete,
  apiDocumentList,
  apiPersonalDocumentList,
} from "../../api/apiDocuments";
import { showNotification } from "../../utils/showNotification";
import {
  actionDocumentCreateSuccess,
  actionDocumentCreateReset,
  actionDocumentCreateFailure,
  actionDocumentListFailure,
  actionDocumentDeleteSuccess,
  actionDocumentDeleteReset,
  actionDocumentListSuccess,
  actionDocumentListLoadingRequest,
  actionDocumentListLoadingSuccess,
  actionDocumentListLoadingFailure,
} from "../actions/actionsDocument";
import { actionGroupDeleteFailure } from "../actions/actionsGroups";
import {
  actionPersonalDocumentListFailure,
  actionPersonalDocumentListSuccess,
} from "../actions/actionsPersonalDocument";
import {
  actionUserDocumentUploadSuccess,
  actionUserDocumentUploadReset,
} from "../actions/actionsUsers";
import {
  TActionDocumentCreateRequest,
  TActionDocumentListRequest,
  TActionDocumentDeleteRequest,
} from "../declarations/actions/document";
import { TActionPersonalDocumentListRequest } from "../declarations/actions/personalDocument";
import { TAPIResponse } from "../declarations/apis/general";
import { selectorLoggedUser } from "../selectors/selectorsAuth";
/* import { TDocument } from "../declarations/maps/documents";
 */
export function* sagaDocumentCreate(action: TActionDocumentCreateRequest): unknown {
  try {
    const response = yield call(apiDocumentCreate, action.payload);

    yield put(actionDocumentCreateSuccess(response.data.id));
    yield put(actionDocumentCreateReset());
    yield all([put(actionUserDocumentUploadSuccess()), put(actionUserDocumentUploadReset())]);
    showNotification("success", "Caricato!", "Documento caricato con successo");
  } catch (e) {
    yield put(actionDocumentCreateFailure());
    yield all([put(actionUserDocumentUploadSuccess()), put(actionUserDocumentUploadReset())]);
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaDocumentList(action: TActionDocumentListRequest): unknown {
  try {
    yield put(actionDocumentListLoadingRequest());
    const response: AxiosResponse<TAPIResponse<unknown>> = yield call(apiDocumentList);
    //@ts-ignore
    yield put(actionDocumentListSuccess(response.data.data));
    yield put(actionDocumentListLoadingSuccess());
  } catch (e) {
    yield put(actionDocumentListFailure());
    yield put(actionDocumentListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaPersonalDocumentList(action: TActionPersonalDocumentListRequest): unknown {
  try {
    const loggedUser = yield select(selectorLoggedUser);
    yield put(actionDocumentListLoadingRequest());
    const response: AxiosResponse<TAPIResponse<unknown>> = yield call(
      apiPersonalDocumentList,
      //@ts-ignore
      loggedUser.id,
    );

    //@ts-ignore
    yield put(actionPersonalDocumentListSuccess(response.data.data));
    yield put(actionDocumentListLoadingSuccess());
  } catch (e) {
    yield put(actionPersonalDocumentListFailure());
    yield put(actionDocumentListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaDocumentDelete(action: TActionDocumentDeleteRequest): unknown {
  try {
    yield call(apiDocumentDelete, action.payload);

    yield all([put(actionDocumentDeleteSuccess(action.payload)), put(actionDocumentDeleteReset())]);
    yield all([put(actionUserDocumentUploadSuccess()), put(actionUserDocumentUploadReset())]);
    showNotification("success", "Eliminato!", "Documento eliminato con successo");
  } catch (e) {
    yield put(actionGroupDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
