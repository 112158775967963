/** @format */

import { TStore } from "../declarations/store";
import { TAlert, TCustomStyleOptions } from "../declarations/maps/ui";
import { TAPIValidationError } from "../declarations/apis/general";

export const selectorLoginValidation = (store: TStore): TAPIValidationError =>
  store.ui.loginValidation || {};

export const selectorUsersListLoading = (store: TStore): boolean =>
  store.ui.usersListLoading || false;

export const selectorGroupsListLoading = (store: TStore): boolean =>
  store.ui.groupsListLoading || false;

export const selectorJustificativeListLoading = (store: TStore): boolean =>
  store.ui.justificativeListLoading || false;

export const selectorPermissionListLoading = (store: TStore): boolean =>
  store.ui.permissionListLoading || false;

export const selectorLocationsListLoading = (store: TStore): boolean =>
  store.ui.locationsListLoading || false;

export const selectorDocumentListLoading = (store: TStore): boolean =>
  store.ui.documentListLoading || false;

export const selectorModelDetailLoading = (store: TStore): boolean =>
  store.ui.modelDetailLoading || false;

export const selectorModelDetailUpdated = (store: TStore): boolean =>
  store.ui.modelDetailUpdated || false;

export const selectorJustificativeStatusListLoading = (store: TStore): boolean =>
  store.ui.justificativeStatusListLoading || false;

export const selectorRequestListLoading = (store: TStore): boolean =>
  store.ui.requestListLoading || false;

export const selectorDeviceListLoading = (store: TStore): boolean =>
  store.ui.deviceListLoading || false;

export const selectorProductListLoading = (store: TStore): boolean =>
  store.ui.productListLoading || false;

export const selectorWarehouseListLoading = (store: TStore): boolean =>
  store.ui.warehouseListLoading || false;

export const selectorCommunicationListLoading = (store: TStore): boolean =>
  store.ui.communicationListLoading || false;

export const selectorDepartmentListLoading = (store: TStore): boolean =>
  store.ui.departmentListLoading || false;

export const selectorPresenceTypeListLoading = (store: TStore): boolean =>
  store.ui.presenceTypeListLoading || false;

export const selectorPresenceListLoading = (store: TStore): boolean =>
  store.ui.presenceListLoading || false;

export const selectorShiftTypeListLoading = (store: TStore): boolean =>
  store.ui.shiftTypeListLoading || false;

export const selectorShiftListLoading = (store: TStore): boolean =>
  store.ui.shiftListLoading || false;

export const selectorFleetUpdated = (store: TStore): boolean => store.ui.fleetUpdated || false;
export const selectorFleetDeleted = (store: TStore): boolean => store.ui.fleetDeleted || false;
export const selectorFleetListLoading = (store: TStore): boolean =>
  store.ui.fleetListLoading || false;

export const selectorTravelUpdated = (store: TStore): boolean => store.ui.travelUpdated || false;
export const selectorTravelDeleted = (store: TStore): boolean => store.ui.travelDeleted || false;
export const selectorTravelListLoading = (store: TStore): boolean =>
  store.ui.travelListLoading || false;

export const selectorExpenseUpdated = (store: TStore): boolean => store.ui.expenseUpdated || false;
export const selectorExpenseDeleted = (store: TStore): boolean => store.ui.expenseDeleted || false;
export const selectorExpenseListLoading = (store: TStore): boolean =>
  store.ui.expenseListLoading || false;

export const selectorReservationUpdated = (store: TStore): boolean =>
  store.ui.reservationUpdated || false;
export const selectorReservationDeleted = (store: TStore): boolean =>
  store.ui.reservationDeleted || false;
export const selectorReservationListLoading = (store: TStore): boolean =>
  store.ui.reservationListLoading || false;

export const selectorDeviceUpdated = (store: TStore): boolean => store.ui.deviceUpdated || false;
export const selectorProductUpdated = (store: TStore): boolean => store.ui.productUpdated || false;
export const selectorWarehouseUpdated = (store: TStore): boolean =>
  store.ui.warehouseUpdated || false;

export const selectorCommunicationUpdated = (store: TStore): boolean =>
  store.ui.communicationUpdated || false;

export const selectorDepartmentUpdated = (store: TStore): boolean =>
  store.ui.departmentUpdated || false;

export const selectorPresenceTypeUpdated = (store: TStore): boolean =>
  store.ui.presenceTypeUpdated || false;

export const selectorPresenceUpdated = (store: TStore): boolean =>
  store.ui.presenceUpdated || false;
export const selectorShiftTypeUpdated = (store: TStore): boolean =>
  store.ui.shiftTypeUpdated || false;

export const selectorShiftUpdated = (store: TStore): boolean => store.ui.shiftUpdated || false;

export const selectorDeviceDeleted = (store: TStore): boolean => store.ui.deviceDeleted || false;

export const selectorProductDeleted = (store: TStore): boolean => store.ui.productDeleted || false;
export const selectorWarehouseDeleted = (store: TStore): boolean =>
  store.ui.warehouseDeleted || false;
export const selectorCommunicationDeleted = (store: TStore): boolean =>
  store.ui.communicationDeleted || false;

export const selectorDepartmentDeleted = (store: TStore): boolean =>
  store.ui.departmentDeleted || false;

export const selectorPresenceTypeDeleted = (store: TStore): boolean =>
  store.ui.presenceTypeDeleted || false;

export const selectorShiftTypeDeleted = (store: TStore): boolean =>
  store.ui.shiftTypeDeleted || false;
export const selectorShiftDeleted = (store: TStore): boolean => store.ui.shiftDeleted || false;

export const selectorTriggerListLoading = (store: TStore): boolean =>
  store.ui.triggerListLoading || false;

export const selectorNotificationListLoading = (store: TStore): boolean =>
  store.ui.notificationListLoading || false;

export const selectorRequestListUpdated = (store: TStore): boolean =>
  store.ui.requestUpdated || false;

export const selectorUserCreated = (store: TStore): boolean | string => store.ui.userCreated || false;
export const selectorUserUpdated = (store: TStore): boolean => store.ui.userUpdated || false;
export const selectorUserDeleted = (store: TStore): boolean => store.ui.userDeleted || false;

export const selectorUserAvatarUpdated = (store: TStore): boolean =>
  store.ui.userAvatarUpdated || false;

export const selectorUserDocumentUpdated = (store: TStore): boolean =>
  store.ui.userDocumentUpdated || false;

export const selectorGroupCreated = (store: TStore): boolean => store.ui.groupCreated || false;
export const selectorGroupUpdated = (store: TStore): boolean => store.ui.groupUpdated || false;
export const selectorGroupDeleted = (store: TStore): boolean => store.ui.groupDeleted || false;

export const selectorJustificativeCreated = (store: TStore): boolean =>
  store.ui.justificativeCreated || false;
export const selectorJustificativeUpdated = (store: TStore): boolean =>
  store.ui.justificativeUpdated || false;
export const selectorJustificativeDeleted = (store: TStore): boolean =>
  store.ui.justificativeDeleted || false;

export const selectorLocationCreated = (store: TStore): boolean =>
  store.ui.locationCreated || false;
export const selectorLocationUpdated = (store: TStore): boolean =>
  store.ui.locationUpdated || false;
export const selectorLocationDeleted = (store: TStore): boolean =>
  store.ui.locationDeleted || false;
export const selectorDocumentTypeCreated = (store: TStore): boolean =>
  store.ui.documentTypeCreated || false;
export const selectorDocumentTypeUpdated = (store: TStore): boolean =>
  store.ui.documentTypeUpdated || false;
export const selectorDocumentTypeDeleted = (store: TStore): boolean =>
  store.ui.documentTypeDeleted || false;

//#region alert

export const selectorAlert = (store: TStore): TAlert | undefined => store.ui.alert;
export const selectorStaticAlert = (store: TStore): TAlert | undefined => store.ui.staticAlert;

//#endregion

export const selectorMenuCollapsed = (store: TStore): boolean => !!store.ui.menuCollapsed;

export const selectorCustomStyleOptions = (store: TStore): TCustomStyleOptions =>
  store.ui.customStyleOptions;
