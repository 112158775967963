/** @format */

import moment from "moment";
import { TDocumentComplete } from "../declarations/maps/documents";
import { TStore } from "../declarations/store";

export const selectorPersonalDocuments = (state: TStore): TDocumentComplete[] =>
  state.personalDocuments.documentList || [];

export const selectorRecentPersonalDocuments = (
  state: TStore,
  count: number = 5,
): TDocumentComplete[] => {
  if (!state.personalDocuments.documentList) return [];
  const docs = state.personalDocuments.documentList.sort((a, b) =>
    moment(a.created_at) > moment(b.created_at) ? -1 : 1,
  );
  return [...docs.slice(0, count)];
};
