/** @format */

import React, { useEffect } from "react";
import { Col, Form, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectorPresenceTypeUpdated } from "../../../redux-modules/selectors/selectorsUI";
import { actionPresenceTypeCreateRequest } from "../../../redux-modules/actions/actionsPresenceTypes";
import { SETTINGSTABS } from "../../../constants/settings";
import { Routes } from "../../../utils/Routes";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UILink } from "../../ui/Link";
import { UITitle } from "../../ui/Title";

export const ViewPresenceTypeCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isPresenceTypeCreated = useSelector(selectorPresenceTypeUpdated);
  const [form] = Form.useForm();

  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      name: e.name,
      color: e.color,
      export_code: e.export_code,
    };
    //dispatch
    dispatch(actionPresenceTypeCreateRequest(contentObj));
  };

  useEffect(() => {
    if (isPresenceTypeCreated) history.push(Routes.settings + SETTINGSTABS.PRESENCE_TYPES);
  }, [isPresenceTypeCreated]);

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={4}>Nuova Tipologia Presenza</UITitle>
        </Col>
      </Row>
      <Form
        name="new-presence-type"
        className="new-presence-type"
        onFinish={handleSave}
        layout="vertical"
        form={form}
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: "Inserisci un nome!" }]}
                    extra="Il nome della tipologia"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={2}>
                  <Form.Item
                    name="color"
                    label="Colore"
                    rules={[{ required: true, message: "Inserisci un colore!" }]}
                    extra="Il colore della tipologia"
                  >
                    <UIInput type="color" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="export_code"
                    label="Codice Esportazione"
                    rules={[{ required: false, message: "Inserisci un codice!" }]}
                    extra="Codice esportazione della tipologia"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
        <Row justify="end" gutter={[24, 24]}>
          <Col>
            <UILink
              route={Routes.settings + SETTINGSTABS.PRESENCE_TYPES}
              label={<UIButton>Annulla</UIButton>}
            />
          </Col>
          <Col>
            <Form.Item>
              <UIButton type="primary" htmlType="submit">
                + Crea Tipologia Presenze
              </UIButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
