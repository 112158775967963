/** @format */

import { Form, Drawer, Row, Col, Divider, Radio, AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS, LOCATION_ENDPOINTS } from "../../../../constants/api";
import { actionFleetListRequest } from "../../../../redux-modules/actions/actionsFleets";
import { actionTravelCreateSuccess } from "../../../../redux-modules/actions/actionsTravels";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import { ExpenseTypes } from "../../../../redux-modules/declarations/maps/expenses";
import { TTravel } from "../../../../redux-modules/declarations/maps/travels";
import { selectorLoggedUser } from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorFleetList } from "../../../../redux-modules/selectors/selectorsFleets";
import apiClient, { locationClient } from "../../../../utils/apiClient";
import { showNotification } from "../../../../utils/showNotification";
import { UIButton } from "../../../ui/Button";
import { UICheckbox } from "../../../ui/Checkbox";
import { UIDatePicker } from "../../../ui/DatePicker";
import { UIFileUploader } from "../../../ui/FileUploader";
import { UIInput } from "../../../ui/Input";
import { UISelect } from "../../../ui/Select";
import { UITextArea } from "../../../ui/Textarea";
const { Option } = UISelect;

interface Props {
  visible: boolean;
  onClose: (refresh?: boolean) => void;
  travels: TTravel[];
}

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

interface TPlace {
  place_id: string;
  display_name: string;
  lat: string;
  lon: string;
}

export const ExpenseCreateDrawer = ({ visible, onClose, travels }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loggedUser = useSelector(selectorLoggedUser);
  const [options, setOptions] = useState<Option[]>([]);
  const [isChilometrico, setIsChilometrico] = useState(false);
  const [isAziendale, setIsAziendale] = useState(false);
  const [isAndataERitorno, setIsAndataERitorno] = useState(false);
  const fleetList = useSelector(selectorFleetList);
  const [result, setResult] = useState<TPlace[]>([]);
  const [startLatLon, setStartLatLon] = useState<string[]>([]);
  const [endLatLon, setEndLatLon] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<File[] | null>();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedValues, setSelectedValues] = useState([]);

  const onSelectStart = (value: unknown) => {
    //@ts-ignore
    console.log(value);
    const latLon = (value as string).split("[")[1].split(",");
    if (!!latLon) {
      setStartLatLon([latLon[0], latLon[1]]);
    } else {
      setStartLatLon([]);
    }
  };

  const handleUpload = (e: FileList | object[]): void => {
    if (e[0]) {
      //@ts-ignore
      setAttachments(e);
      //@ts-ignore
      console.log(e.map(el => el.originFileObj));
    }
  };

  const onSelectStop = (value: unknown) => {
    //@ts-ignore
    console.log(value);
    const latLon = (value as string).split("[")[1].split(",");
    if (!!latLon) {
      setEndLatLon([latLon[0], latLon[1]]);
    } else {
      setEndLatLon([]);
    }
  };

  const handleClose = () => {
    if (onClose)
      // quello che vogliamo
      onClose();
    form.resetFields();
  };

  const onChangeForm = () => {
    if (form) {
      const categorySelected = form.getFieldValue("expense_type");
      const ritornoSelected = form.getFieldValue("is_return_included");
      const tipoMezzo = form.getFieldValue("fleet_type");
      const mezzoUtilizzato = form.getFieldValue("fleet_id");
      const kmPercorsi = form.getFieldValue("km_distance");

      if (categorySelected === "Carburante") {
        setIsChilometrico(true);
      } else {
        setIsChilometrico(false);
      }

      if (tipoMezzo === "Aziendale") {
        setIsAziendale(true);
      } else {
        setIsAziendale(false);
      }

      if (!!ritornoSelected) {
        setIsAndataERitorno(true);
      } else {
        setIsAndataERitorno(false);
      }

      if (!!mezzoUtilizzato && !!kmPercorsi) {
        form.setFieldsValue({
          rimborso_previsto:
            (parseFloat(
              fleetList.find(el => el.id === mezzoUtilizzato)?.costo_per_chilometro || "0",
            ) || 0) * parseFloat(kmPercorsi.toString().replace(",", ".")),
        });
      }
    }
  };

  const onSave = () => {
    setIsLoading(true);
    form
      .validateFields()
      .then(values => {
        const obj = {
          note: form.getFieldValue("note"),
          expense_type: form.getFieldValue("expense_type"),
          fleet_type: form.getFieldValue("fleet_type"),
          departure: form.getFieldValue("departure"),
          arrival: form.getFieldValue("arrival"),
          is_return_included: form.getFieldValue("is_return_included"),
          km_distance: form.getFieldValue("km_distance"),
          fleet_id: form.getFieldValue("fleet_id"),
          rimborso_previsto: form.getFieldValue("rimborso_previsto"),
          rimborso_richiesto: form.getFieldValue("rimborso_richiesto"),
          rimborso_approvato: form.getFieldValue("rimborso_approvato"),
          date: form.getFieldValue("date").format("YYYY-MM-DD"),
          travel_id: form.getFieldValue("travel_id"),
          user_id: loggedUser?.id,
          attachments: attachments,
        };

        const config = { headers: { "Content-Type": "multipart/form-data" } };

        var formData = new FormData();
        if (!!obj.attachments) {
          obj.attachments.forEach((attachment: File, i) => {
            //@ts-ignore
            formData.append("attachments_" + i, attachment.originFileObj);
          });
        }
        if (!!obj.note) formData.append("note", `${obj.note}`);
        if (!!obj.expense_type) formData.append("expense_type", `${obj.expense_type}`);
        if (!!obj.fleet_type) formData.append("fleet_type", `${obj.fleet_type}`);
        if (!!obj.departure) formData.append("departure", `${obj.departure}`);
        if (!!obj.arrival) formData.append("arrival", `${obj.arrival}`);
        if (!!obj.is_return_included)
          formData.append("is_return_included", `${obj.is_return_included ? 1 : 0}`);
        if (!!obj.km_distance) formData.append("km_distance", `${obj.km_distance}`);
        if (!!obj.fleet_id) formData.append("fleet_id", `${obj.fleet_id}`);
        if (!!obj.rimborso_previsto)
          formData.append("rimborso_previsto", `${obj.rimborso_previsto}`);
        if (!!obj.rimborso_richiesto)
          formData.append("rimborso_richiesto", `${obj.rimborso_richiesto}`);
        if (!!obj.rimborso_approvato)
          formData.append("rimborso_approvato", `${obj.rimborso_approvato}`);
        if (!!obj.date) formData.append("date", `${obj.date}`);
        if (!!obj.travel_id) formData.append("travel_id", `${obj.travel_id}`);
        if (!!obj.user_id) formData.append("user_id", `${obj.user_id}`);
        apiClient
          .post(API_ENDPOINTS.EXPENSES, formData, config)
          .then(res => {
            showNotification("success", "Nota spese salvata", "Nota spese salvata con successo!");
            form.resetFields();
            setIsLoading(false);
            // OPERARE SUL REDUCER
            dispatch(actionTravelCreateSuccess(res.data.data));
            if (!!onClose) onClose(true);
          })
          .catch(err => {
            form.resetFields();
            setIsLoading(false);
            showNotification(
              "error",
              "Errore", //@ts-ignore
              err.response.data.errors
                ? //@ts-ignore
                  Object.values(err.response.data.errors).join(`\n`)
                : //@ts-ignore
                  err.response.data.message,
            );
          });
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    dispatch(actionFleetListRequest());
  }, []);

  const handleSearch = (value: string) => {
    locationClient
      .get(LOCATION_ENDPOINTS.SEARCH, {
        params: {
          city: value,
        },
      })
      .then(res => {
        console.log(res);
        setResult(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Drawer
      title="Nuova Nota Spese"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <Row justify="space-between">
          <Col>
            <Form.Item key="submit" name="submit" htmlFor="form_create_expense">
              <UIButton
                form="form_create_expense"
                key="submit"
                htmlType="submit"
                type="primary"
                loading={isLoading}
              >
                Salva
              </UIButton>
            </Form.Item>
          </Col>
          <Col>
            <UIButton onClick={() => onClose()}>Annulla</UIButton>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <>
            <Form
              id="form_create_expense"
              form={form}
              onFinish={onSave}
              onValuesChange={onChangeForm}
            >
              <Row>
                <Col span={24}>
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="travel_id"
                        label="Viaggio di riferimento"
                        rules={[{ required: false, message: "Dai un nome al viaggio" }]}
                      >
                        <UISelect>
                          <Option value={undefined}>Nessuno</Option>
                          {travels
                            .filter(tra => tra.status != "Completato" && tra.status != "Archiviato")
                            .map(travel => (
                              <Option value={travel.id} key={travel.id}>
                                {travel.name} - {travel.description}
                              </Option>
                            ))}
                        </UISelect>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="date"
                        label="Data"
                        rules={[{ required: true, message: "Indica la data della spesa" }]}
                      >
                        <UIDatePicker format={"DD/MM/YYYY"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="expense_type"
                        label="Categoria"
                        rules={[{ required: true, message: "Indica la tipologia di spesa" }]}
                      >
                        <UISelect>
                          {ExpenseTypes.map(type => (
                            <Option value={type} key={type}>
                              {type}
                            </Option>
                          ))}
                        </UISelect>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="note"
                        label="Note"
                        rules={[
                          { required: false, message: "Ulteriori dettagli relativi alla spesa" },
                        ]}
                      >
                        <UITextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                  {isChilometrico && (
                    <>
                      <Divider />
                      <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="fleet_type"
                            label="Tipologia Mezzo"
                            rules={[
                              {
                                required: isChilometrico,
                                message: "Indica il tipo di mezzo utilizzato",
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value={"Aziendale"}>Aziendale</Radio>
                              <Radio value={"Personale"}>Personale</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      {isAziendale && (
                        <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                          <Col span={24}>
                            <Form.Item
                              name="fleet_id"
                              label="Mezzo utilizzato"
                              rules={[
                                { required: isAziendale, message: "Indica il mezzo utilizzato" },
                              ]}
                            >
                              <UISelect>
                                {fleetList.map(fleet => (
                                  <Option value={fleet.id} key={fleet.id}>
                                    {fleet.marca + " - " + fleet.targa}
                                  </Option>
                                ))}
                              </UISelect>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="departure"
                            label="Città di partenza"
                            rules={[{ required: isChilometrico, message: "Indica la partenza" }]}
                          >
                            <CustomAutocomplete style={{ width: 200 }} onSelect={onSelectStart}>
                              {result.map((loc: TPlace) => (
                                <Option
                                  key={loc.place_id}
                                  value={
                                    loc.display_name +
                                    "                           [" +
                                    loc.lat +
                                    "," +
                                    loc.lon
                                  }
                                >
                                  {loc.display_name}
                                </Option>
                              ))}
                            </CustomAutocomplete>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="arrival"
                            label="Città di arrivo"
                            rules={[
                              { required: isChilometrico, message: "Indica la destinazione" },
                            ]}
                          >
                            <CustomAutocomplete style={{ width: 200 }} onSelect={onSelectStop}>
                              {result.map((loc: TPlace) => (
                                <Option
                                  key={loc.place_id}
                                  value={
                                    loc.display_name + "                [" + loc.lat + "," + loc.lon
                                  }
                                >
                                  {loc.display_name}
                                </Option>
                              ))}
                            </CustomAutocomplete>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="km_distance"
                            label="Km percorsi"
                            rules={[{ required: isChilometrico, message: "Indica i km percorsi" }]}
                          >
                            <UIInput />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="is_return_included"
                            label="A / R"
                            valuePropName="checked"
                            rules={[{ required: false, message: "Considera andata e ritorno" }]}
                          >
                            <UICheckbox />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Divider />
                  {isAziendale && (
                    <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                      <Col span={24}>
                        <Form.Item
                          name="rimborso_previsto"
                          rules={[
                            { required: isAziendale, message: "Indica il valore del rimborso" },
                          ]}
                          label="Rimborso previsto"
                        >
                          <UIInput type="number" step="0.1" suffix="€" disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="rimborso_richiesto"
                        label="Rimborso richiesto"
                        rules={[{ required: true, message: "Indica il valore del rimborso" }]}
                      >
                        <UIInput type="number" step="0.1" suffix="€" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="attachments" label="Allegati" rules={[{ required: false }]}>
                        <UIFileUploader handleUpload={handleUpload} forImg={true} multiple={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </>
        </Col>
      </Row>
    </Drawer>
  );
};

const CustomAutocomplete = styled(AutoComplete)`
  .ant-select-selector {
    border-radius: 7px !important;
  }
`;
