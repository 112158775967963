/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaDocumentTypeCreate, sagaDocumentTypeDelete, sagaDocumentTypeList, sagaDocumentTypeUpdate } from "../sagas/sagasDocumentTypes";

export function* watcherDocumentTypes(): unknown {
  yield takeLatest(TActionType.documentTypeCreateRequest, sagaDocumentTypeCreate);
  yield takeLatest(TActionType.documentTypeListRequest, sagaDocumentTypeList);
  yield takeLatest(TActionType.documentTypeDeleteRequest, sagaDocumentTypeDelete);
  yield takeLatest(TActionType.documentTypeUpdateRequest, sagaDocumentTypeUpdate);
}
