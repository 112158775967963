/** @format */

import {
  TActionPersonalDocumentListRequest,
  TActionPersonalDocumentListSuccess,
  TActionPersonalDocumentListFailure,
} from "../declarations/actions/personalDocument";
import { TActionType } from "../declarations/actionTypes";
import { TDocuments } from "../declarations/maps/documents";

type Actions =
  | TActionPersonalDocumentListRequest
  | TActionPersonalDocumentListSuccess
  | TActionPersonalDocumentListFailure;

export const reducerPersonalDocuments = (
  prevStore: TDocuments = {},
  action: Actions,
): TDocuments => {
  switch (action.type) {
    case TActionType.personalDocumentListRequest:
      return { ...prevStore, documentList: [] };
    case TActionType.personalDocumentListSuccess:
      return { ...prevStore, documentList: action.payload };
    case TActionType.personalDocumentListFailure:
      return { ...prevStore, documentList: [] };
    default:
      return prevStore;
  }
};
