/** @format */

interface TAvailableProduct {
  [key: string]: {
    [key: string]: unknown;
  };
}

export const formatAvailableProductToArray = (available_products: TAvailableProduct): unknown[] => {
  let availableProductsArray: unknown[] = [];
  Object.values(available_products).forEach(product_status => {
    for (const key in product_status) {
      if (
        Object.prototype.hasOwnProperty.call(product_status, key) &&
        // @ts-ignore
        !!Object.keys(product_status[key]).length
      ) {
        let element = product_status[key];
        //@ts-ignore
        element.status = key;
        availableProductsArray.push(element);
      }
    }
  });
  return availableProductsArray;
};

export const formatAssignedProductToArray = (available_products: TAvailableProduct): unknown[] => {
  let availableProductsArray: unknown[] = [];
  Object.values(available_products).forEach(product_status => {
    for (const key in product_status) {
      if (Object.prototype.hasOwnProperty.call(product_status, key)) {
        //@ts-ignore
        for (const utente in product_status[key].users) {
          let element = {
            user_name: utente,
            //@ts-ignore
            product_name: product_status[key].product_name,
            //@ts-ignore
            quantity: product_status[key].users[utente],
            status: key,
          };
          availableProductsArray.push(element);
        }
      }
    }
  });
  return availableProductsArray;
};
