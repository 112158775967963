/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaCommunicationCreate,
  sagaCommunicationDelete,
  sagaCommunicationList,
} from "../sagas/sagasCommunications";

export function* watcherCommunications(): unknown {
  yield takeLatest(TActionType.communicationCreateRequest, sagaCommunicationCreate);
  yield takeLatest(TActionType.communicationListRequest, sagaCommunicationList);
  yield takeLatest(TActionType.communicationDeleteRequest, sagaCommunicationDelete);
}
