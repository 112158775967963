/** @format */

import { put, call, all } from "redux-saga/effects";
import { apiGroupCreate, apiGroupDelete, apiGroupsList, apiGroupUpdate } from "../../api/apiGroups";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import {
  actionGroupsListLoadingRequest,
  actionGroupsListSuccess,
  actionGroupsListLoadingSuccess,
  actionGroupsListFailure,
  actionGroupsListLoadingFailure,
  actionGroupCreateReset,
  actionGroupCreateFailure,
  actionGroupCreateSuccess,
  actionGroupDeleteFailure,
  actionGroupDeleteSuccess,
  actionGroupUpdateFailure,
  actionGroupUpdateSuccess,
  actionGroupDeleteReset,
  actionGroupUpdateReset,
} from "../actions/actionsGroups";
import {
  TActionGroupCreateRequest,
  TActionGroupDeleteRequest,
  TActionGroupsListRequest,
  TActionGroupUpdateRequest,
} from "../declarations/actions/groups";
import { utilityFormatGroupTable } from "../../utils/format/groupTable";
import { TGroup } from "../declarations/maps/groups";
import { showNotification } from "../../utils/showNotification";

export function* sagaGroupCreate(action: TActionGroupCreateRequest): unknown {
  try {
    const response = yield call(apiGroupCreate, action.payload);

    yield put(actionGroupCreateSuccess(response.data.id));
    yield put(actionGroupCreateReset());
    showNotification("success", "Creato!", "Gruppo creato con successo");
  } catch (e) {
    yield put(actionGroupCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaGroupsList(action: TActionGroupsListRequest): unknown {
  try {
    yield put(actionGroupsListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TGroup[]>> = yield call(
      apiGroupsList,
      action.payload,
    );
    const groupsList = utilityFormatGroupTable(response.data);

    //@ts-ignore
    yield put(actionGroupsListSuccess(groupsList));
    yield put(actionGroupsListLoadingSuccess());
  } catch (e) {
    yield put(actionGroupsListFailure());
    yield put(actionGroupsListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaGroupUpdate(action: TActionGroupUpdateRequest): unknown {
  try {
    yield call(apiGroupUpdate, action.payload);

    yield all([put(actionGroupUpdateSuccess()), put(actionGroupUpdateReset())]);
    showNotification("success", "Aggiornato!", "Gruppo modificato con successo");
  } catch (e) {
    yield put(actionGroupUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaGroupDelete(action: TActionGroupDeleteRequest): unknown {
  try {
    yield call(apiGroupDelete, action.payload);

    yield all([put(actionGroupDeleteSuccess(action.payload)), put(actionGroupDeleteReset())]);
    showNotification("success", "Eliminato!", "Gruppo eliminato con successo");
  } catch (e) {
    yield put(actionGroupDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
