/** @format */

import { TLocation } from "./locations";

export interface TWarehouse {
  id: string;
  name: string;
  description: string;
  note: string;
  office: TLocation;
}

export interface TWarehouses {
  warehouseList?: TWarehouse[];
}

export enum ProductStatus {
  NUOVO = "NUOVO",
  USATO = "USATO",
}

export enum WarehouseOperation {
  CARICO = "CARICO",
  ASSEGNAZIONE = "ASSEGNAZIONE",
  RICONSEGNA = "RICONSEGNA",
}

export interface TWarehouseCreateRequest {
  name: string;
  description: string;
  note: string;
  office_id: string;
}

export interface TWarehouseUpdateRequest {
  id: string;
  name: string;
  description: string;
  note: string;
  office_id: string;
}

export interface TWarehouseOperation {
  warehouse_id: string;
  product_id: string;
  quantity: string;
  product_status: ProductStatus;
  operation_type: WarehouseOperation;
  user_id?: string;
  effective_return_delivery_date?: string;
}
