/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaExpenseCreate,
  sagaExpenseDelete,
  sagaExpenseList,
  sagaExpenseUpdate,
} from "../sagas/sagasExpenses";

export function* watcherExpenses(): unknown {
  yield takeLatest(TActionType.expenseCreateRequest, sagaExpenseCreate);
  yield takeLatest(TActionType.expenseListRequest, sagaExpenseList);
  yield takeLatest(TActionType.expenseDeleteRequest, sagaExpenseDelete);
  yield takeLatest(TActionType.expenseUpdateRequest, sagaExpenseUpdate);
}
