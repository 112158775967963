/** @format */

import { Button, Col, Layout, Menu, Row } from "antd";
import {
  UsergroupAddOutlined,
  FileOutlined,
  HomeOutlined,
  ShopOutlined,
  ToolOutlined,
  CheckSquareOutlined,
  UserOutlined,
  MobileOutlined,
  AuditOutlined,
  AppstoreOutlined,
  TableOutlined,
  ControlOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BarChartOutlined,
  CodeSandboxOutlined,
  SkinOutlined,
  HddOutlined,
  MailOutlined,
  CarOutlined,
  CalculatorOutlined,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import "./App.less";
import Theme from "./theme/Theme";
import appLogo from "./assets/svg/logo_strong.svg";
//import appLogo from "./assets/images/logo_notext.png";
import { Routes } from "./utils/Routes";
import { UserMenu } from "./components/layout/Header/UserMenu";
import { RenderRouteLogged } from "./components/utils/RouteLogged";
import { RenderRouteGuest } from "./components/utils/RouteGuest";
import { ViewLogin } from "./components/view/ViewLogin";
import { useDispatch, useSelector } from "react-redux";
import { selectorAuthenticated, selectorLoggedUser } from "./redux-modules/selectors/selectorsAuth";
import { UINavLinkItem } from "./components/ui/NavLinkItem";
import { ViewForgotPassword } from "./components/view/ViewForgotPassword";
import moment from "moment";
import ViewRequestList from "./components/view/ViewRequestList";
import ViewRequestTypeCreate from "./components/view/ViewRequestTypeCreate";
import { actionStartApiKeyCheck } from "./redux-modules/actions/actionsAuth";
import { ViewLocationEdit } from "./components/view/ViewLocationEdit";
import { ViewGroupEdit } from "./components/view/ViewGroupEdit";
import { ViewUserEdit } from "./components/view/ViewUserEdit";
import { ViewGroupCreate } from "./components/view/ViewGroupCreate";
import { ViewLocationCreate } from "./components/view/ViewLocationCreate";
import { ViewUserCreate } from "./components/view/ViewUserCreate";
import ViewSettings from "./components/view/ViewSettings";
import { ViewDocumentList } from "./components/view/ViewDocumentList";
import { ViewLocationList } from "./components/view/ViewLocationList";
import { ViewGroupList } from "./components/view/ViewGroupList";
import { ViewHome } from "./components/view/ViewHome";
import { ViewUserList } from "./components/view/ViewUserList";
import { Route, Switch, useHistory, useLocation } from "react-router";
import { UILink, UILinkItem } from "./components/ui/Link";
import { ViewResetPassword } from "./components/view/ViewResetPassword";
import ViewRequestTypeEdit from "./components/view/ViewRequestTypeEdit";
import ModelDetails from "./components/view/ViewSettings/tabs/ModelDetails";
import TriggersTab from "./components/view/ViewSettings/tabs/Triggers";
import { ViewDevicesList } from "./components/view/ViewDevicesList";
import { ViewDeviceCreate } from "./components/view/ViewDeviceCreate";
import { ViewDeviceEdit } from "./components/view/ViewDeviceEdit";
import PresenceTypes from "./components/view/ViewSettings/tabs/PresenceTypes";
import { ViewPresencesList } from "./components/view/ViewPresencesList";
import { ViewBadge } from "./components/view/ViewBadge";
import { isMobileOnly } from "react-device-detect";
import { Footer } from "antd/lib/layout/layout";
import { ViewDepartmentList } from "./components/view/ViewDepartmentList";
import { ViewDepartmentCreate } from "./components/view/ViewDepartmentCreate";
import { ViewDepartmentEdit } from "./components/view/ViewDepartmentEdit";
import { ViewShiftTypesList } from "./components/view/ViewShiftTypesList";
import { BaseColor } from "./theme/maps/Colors";
import { ViewShiftsList } from "./components/view/ViewShiftsList";
import { ViewReportPresences } from "./components/view/ViewReportPresences";
import { usePermission } from "./redux-modules/hooks/usePermission";
import { ViewProductsList } from "./components/view/ViewProductsList";
import { ViewProductCreate } from "./components/view/ViewProductCreate";
import { ViewProductEdit } from "./components/view/ViewProductEdit";
import { ViewWarehouseList } from "./components/view/ViewWarehouseList";
import { ViewWarehouseCreate } from "./components/view/ViewWarehouseCreate";
import { ViewWarehouseEdit } from "./components/view/ViewWarehouseEdit";
import { ViewCommunicationList } from "./components/view/ViewCommunicationList";
import { ViewCommunicationCreate } from "./components/view/ViewCommunicationCreate";
import { ViewCommunicationView } from "./components/view/ViewCommunicationView";
import { selectorMenuCollapsed } from "./redux-modules/selectors/selectorsUI";
import { actionCollapseRequest, actionUncollapseRequest } from "./redux-modules/actions/actionsUI";
import { ViewFleetList } from "./components/view/ViewFleetList";
import { ViewTravelAndExpenseList } from "./components/view/ViewTravelAndExpenseList";
import { ViewInitialConfig } from "./components/view/ViewInitialConfig";
import apiClient from "./utils/apiClient";
import { API_ENDPOINTS } from "./constants/api";

const { Header, Content, Sider } = Layout;

const App = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const isAuthenticated = useSelector(selectorAuthenticated);
  const dispatch = useDispatch();
  //const [collapsed, setCollapsed] = useState(false);
  const collapsed = useSelector(selectorMenuCollapsed);
  const toggleCollapsed = () => {
    //setCollapsed(!collapsed);
    if (!!collapsed) dispatch(actionUncollapseRequest());
    if (!collapsed) dispatch(actionCollapseRequest());
  };

  const { checkPermission } = usePermission();
  const canUpdateUser =
    checkPermission("Office", "Show") ||
    checkPermission("Group", "Show") ||
    checkPermission("Department", "Show") ||
    checkPermission("ShiftType", "Show") ||
    checkPermission("Device", "Show");
  const canManageDotazioni = checkPermission("Warehouse", "Show");

  useEffect(() => {
    moment().locale("it");
    dispatch(actionStartApiKeyCheck());
    /* 
    //TODO: check if the app is configured
    //rimuovere il commento per attivare il controllo
    apiClient.get(API_ENDPOINTS.SETUP_CHECK).then(res => {
      if (!res.data.is_configured) {
        history.push(Routes.initialConfig);
      }
    });*/
  }, []);

  return (
    <>
      <span className="advice">
        <div className="phone"></div>
        <br />
        <div className="message">Per favore, ruota il tuo dispositivo</div>
      </span>
      <Theme>
        <Layout className="app" style={{ height: "100%" }}>
          <Header
            className="header"
            style={{ display: isAuthenticated ? "" : "none", padding: "0 5px 0 20px" }}
          >
            <Row className="justify-content-between">
              <Col span={18}>
                <Row align="bottom">
                  <Col>
                    <UILink
                      route={Routes.home}
                      label={
                        <>
                          <Col>
                            <img
                              style={{
                                maxHeight: "45px",
                                //filter: "drop-shadow(1px -1px 0.02rem #aeacac)",
                              }}
                              alt="logo"
                              src={appLogo}
                            ></img>
                            {/**
                             
                             <CustomLogo
                            level={3}
                            style={{
                              whiteSpace: "nowrap",
                              display: "inline",
                              float: "none",
                              position: "relative",
                              top: "5px",
                            }}
                            className="logo"
                          >
                            {KEY_APP_NAME}
                          </CustomLogo>
                             */}
                          </Col>
                        </>
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={toggleCollapsed}
                      style={{
                        marginBottom: 10,
                        marginLeft: 10,
                        verticalAlign: "bottom",
                        display: isMobileOnly ? "none" : "",
                      }}
                    >
                      {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={6} style={{ textAlign: "right" }}>
                <UserMenu />
              </Col>
            </Row>
          </Header>
          <Layout>
            <Sider
              //width={200}
              //className="site-layout-background"
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={{ display: isAuthenticated ? (isMobileOnly ? "none" : "") : "none" }}
            >
              <Menu
                mode="inline"
                data-cy="side-menu"
                defaultSelectedKeys={["/"]}
                //inlineCollapsed={collapsed}
                selectedKeys={[location.pathname, "/" + location.pathname.split("/")[1]]}
                style={{ height: "100%", borderRight: 0 }}
              >
                <UILinkItem
                  key={Routes.home}
                  to={Routes.home}
                  title={"Home"}
                  icon={<HomeOutlined />}
                />
                <UILinkItem
                  key={Routes.users}
                  to={Routes.users}
                  title={"Dipendenti"}
                  icon={<UserOutlined />}
                />
                <UILinkItem
                  key={Routes.comunications}
                  to={Routes.comunications}
                  title={"Comunicazioni"}
                  icon={<MailOutlined />}
                />
                <UILinkItem
                  key={Routes.shifts}
                  to={Routes.shifts}
                  title={"Turni"}
                  icon={<TableOutlined />}
                />
                <UILinkItem
                  key={Routes.requests}
                  to={Routes.requests}
                  title={"Richieste"}
                  icon={<CheckSquareOutlined />}
                />
                <UILinkItem
                  key={Routes.presences}
                  to={Routes.presences}
                  title={"Presenze"}
                  icon={<AuditOutlined />}
                />
                <UILinkItem
                  key={Routes.documents}
                  to={Routes.documents}
                  title={"Documenti"}
                  icon={<FileOutlined />}
                />
                <UILinkItem
                  key={Routes.travelsAndExpenses}
                  to={Routes.travelsAndExpenses}
                  title={"Viaggi e Spese"}
                  icon={<CalculatorOutlined />}
                />
                <UILinkItem
                  key={Routes.presencesReport}
                  to={Routes.presencesReport}
                  title={"Report e Analitiche"}
                  icon={<BarChartOutlined />}
                />
                {canManageDotazioni && (
                  <Menu.SubMenu
                    title="Assets Aziendali"
                    key={"sub-dotazioni"}
                    icon={<CodeSandboxOutlined style={{ color: BaseColor }} />}
                  >
                    <UILinkItem
                      key={Routes.products}
                      to={Routes.products}
                      title={"Prodotti"}
                      icon={<SkinOutlined />}
                    />
                    <UILinkItem
                      key={Routes.warehouses}
                      to={Routes.warehouses}
                      title={"Magazzini"}
                      icon={<HddOutlined />}
                    />
                  </Menu.SubMenu>
                )}
                {canUpdateUser && (
                  <Menu.SubMenu
                    title="Impostazioni"
                    key={"sub-impostazioni"}
                    icon={<ControlOutlined style={{ color: BaseColor }} />}
                  >
                    <UILinkItem
                      key={Routes.locations}
                      to={Routes.locations}
                      title={"Sedi"}
                      icon={<ShopOutlined />}
                    />
                    <UILinkItem
                      key={Routes.groups}
                      to={Routes.groups}
                      title={"Gruppi"}
                      icon={<UsergroupAddOutlined />}
                    />
                    <UINavLinkItem
                      key={Routes.departments}
                      to={Routes.departments}
                      title={"Reparti"}
                      icon={<AppstoreOutlined />}
                    />
                    <UINavLinkItem
                      key={Routes.shiftTypes}
                      to={Routes.shiftTypes}
                      title={"Tipologie Turni"}
                      icon={<TableOutlined />}
                    />
                    <UINavLinkItem
                      key={Routes.devices}
                      to={Routes.devices}
                      title={"Dispositivi"}
                      icon={<MobileOutlined />}
                    />
                    <UINavLinkItem
                      key={Routes.fleets}
                      to={Routes.fleets}
                      title={"Mezzi"}
                      icon={<CarOutlined />}
                    />
                  </Menu.SubMenu>
                )}

                <UILinkItem
                  key={Routes.settings}
                  to={Routes.settings}
                  title={"Configurazione"}
                  icon={<ToolOutlined />}
                />
              </Menu>
            </Sider>
            <Layout
              style={{
                padding: isMobileOnly ? "0px 15px 15px 15px" : "0 24px 12px",
                background: isAuthenticated
                  ? "#f0f2f5"
                  : "linear-gradient(180deg, #363988 0%, #E42261 50%, #F39A75 100%)",
              }}
            >
              <Content
                className="site-layout-background"
                data-cy="page-content"
                style={{
                  padding: isMobileOnly ? "5px 0px" : "24px 5px",
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <Switch location={location}>
                  <Route
                    path={Routes.home}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.home} component={ViewHome} />
                    )}
                  />
                  <Route
                    path={Routes.groups}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.groups} component={ViewGroupList} />
                    )}
                  />
                  <Route
                    path={Routes.locations}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.locations} component={ViewLocationList} />
                    )}
                  />
                  <Route
                    path={Routes.documents}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.documents} component={ViewDocumentList} />
                    )}
                  />
                  <Route
                    path={Routes.settings}
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.settings} component={ViewSettings} />
                    )}
                  />
                  <Route
                    path={Routes.users}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.users} component={ViewUserList} />
                    )}
                  />
                  <Route
                    path={Routes.usersNew}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.usersNew} component={ViewUserCreate} />
                    )}
                  />
                  <Route
                    path={Routes.comunications}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.comunications}
                        component={ViewCommunicationList}
                      />
                    )}
                  />
                  <Route
                    path={Routes.comunicationsNew}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.comunicationsNew}
                        component={ViewCommunicationCreate}
                      />
                    )}
                  />

                  <Route
                    path={Routes.communicationsView}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.communicationsView}
                        component={ViewCommunicationView}
                      />
                    )}
                  />
                  <Route
                    path={Routes.groupsNew}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.groupsNew} component={ViewGroupCreate} />
                    )}
                  />
                  <Route
                    path={Routes.locationsNew}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.locationsNew}
                        component={ViewLocationCreate}
                      />
                    )}
                  />
                  <Route
                    path={Routes.usersEdit}
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.usersEdit} component={ViewUserEdit} />
                    )}
                  />
                  <Route
                    path={Routes.groupsEdit}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.groupsEdit} component={ViewGroupEdit} />
                    )}
                  />
                  <Route
                    path={Routes.locationsEdit}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.locationsEdit}
                        component={ViewLocationEdit}
                      />
                    )}
                  />
                  <Route
                    path={Routes.departments}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.departments}
                        component={ViewDepartmentList}
                      />
                    )}
                  />
                  <Route
                    path={Routes.departmentsNew}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.departmentsNew}
                        component={ViewDepartmentCreate}
                      />
                    )}
                  />
                  <Route
                    path={Routes.departmentsEdit}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.departmentsEdit}
                        component={ViewDepartmentEdit}
                      />
                    )}
                  />
                  <Route
                    path={Routes.shiftTypes}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.shiftTypes} component={ViewShiftTypesList} />
                    )}
                  />
                  <Route
                    path={Routes.requests}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.requests} component={ViewRequestList} />
                    )}
                  />
                  <Route
                    path={Routes.presences}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.presences} component={ViewPresencesList} />
                    )}
                  />
                  <Route
                    path={Routes.presencesReport}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.presencesReport}
                        component={ViewReportPresences}
                      />
                    )}
                  />
                  <Route
                    path={Routes.shifts}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.shifts} component={ViewShiftsList} />
                    )}
                  />
                  <Route
                    path={Routes.devices}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.devices} component={ViewDevicesList} />
                    )}
                  />
                  <Route
                    path={Routes.devicesCreate}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.devicesCreate}
                        component={ViewDeviceCreate}
                      />
                    )}
                  />
                  <Route
                    path={Routes.devicesEdit}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.devicesEdit} component={ViewDeviceEdit} />
                    )}
                  />
                  <Route
                    path={Routes.fleets}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.fleets} component={ViewFleetList} />
                    )}
                  />

                  <Route
                    path={Routes.travelsAndExpenses}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.travelsAndExpenses}
                        component={ViewTravelAndExpenseList}
                      />
                    )}
                  />
                  <Route
                    path={Routes.products}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.products} component={ViewProductsList} />
                    )}
                  />
                  <Route
                    path={Routes.productsNew}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.productsNew} component={ViewProductCreate} />
                    )}
                  />
                  <Route
                    path={Routes.productsEdit}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.productsEdit} component={ViewProductEdit} />
                    )}
                  />
                  <Route
                    path={Routes.warehouses}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.warehouses} component={ViewWarehouseList} />
                    )}
                  />
                  <Route
                    path={Routes.warehousesNew}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.warehousesNew}
                        component={ViewWarehouseCreate}
                      />
                    )}
                  />
                  <Route
                    path={Routes.warehousesEdit}
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.warehousesEdit}
                        component={ViewWarehouseEdit}
                      />
                    )}
                  />
                  <Route
                    path={Routes.requestTypeNew}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.requestTypeNew}
                        component={ViewRequestTypeCreate}
                      />
                    )}
                  />
                  <Route
                    path={Routes.requestTypeNew}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.settingsRequestTypeEdit}
                        component={ViewRequestTypeEdit}
                      />
                    )}
                  />
                  <Route
                    path={Routes.settingsModelDetails}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged
                        route={Routes.settingsModelDetails}
                        component={ModelDetails}
                      />
                    )}
                  />
                  <Route
                    path={Routes.settingsTriggers}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.settingsTriggers} component={TriggersTab} />
                    )}
                  />
                  <Route
                    path={Routes.settingsPresenceTypes}
                    exact
                    render={(): JSX.Element => (
                      <RenderRouteLogged route={Routes.presenceTypes} component={PresenceTypes} />
                    )}
                  />
                  <Route
                    path={Routes.badge}
                    render={(): JSX.Element => <RenderRouteGuest component={ViewBadge} noStyle />}
                  />
                  <Route
                    path={Routes.forgotPassword}
                    render={(): JSX.Element => <RenderRouteGuest component={ViewForgotPassword} />}
                  />
                  <Route
                    path={Routes.resetPassword}
                    render={(): JSX.Element => <RenderRouteGuest component={ViewResetPassword} />}
                  />
                  <Route
                    path={Routes.initialConfig}
                    render={(): JSX.Element => <RenderRouteGuest component={ViewInitialConfig} />}
                  />
                  <Route
                    path="/"
                    render={(): JSX.Element => <RenderRouteGuest component={ViewLogin} />}
                  />
                </Switch>
              </Content>
              <Footer
                style={{
                  textAlign: "center",
                  padding: "0px 50px",
                  display: isAuthenticated ? "block" : "none",
                }}
              >
                Copyright © 2022{" "}
                <a href="https://rstore.it/" target="_blank" rel="noopener noreferrer">
                  R-Store S.p.a.
                </a>{" "}
                - Versione 2.0.0
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      </Theme>
    </>
  );
};

export default App;
