/** @format */

import {
  TActionPresenceCreateSuccess,
  TActionPresenceDeleteSuccess,
  TActionPresenceListFailure,
  TActionPresenceListRequest,
  TActionPresenceListSuccess,
  TActionPresenceUpdateSuccess,
} from "../declarations/actions/presences";
import { TActionType } from "../declarations/actionTypes";
import { TPresences } from "../declarations/maps/presences";

type Actions =
  | TActionPresenceListRequest
  | TActionPresenceListSuccess
  | TActionPresenceListFailure
  | TActionPresenceCreateSuccess
  | TActionPresenceDeleteSuccess
  | TActionPresenceUpdateSuccess;

export const reducerPresences = (
  prevStore: TPresences = { presenceList: [] },
  action: Actions,
): TPresences => {
  switch (action.type) {
    case TActionType.presenceListRequest:
      return { ...prevStore, presenceList: [] };
    case TActionType.presenceListSuccess:
      return { ...prevStore, presenceList: action.payload };
    case TActionType.presenceListFailure:
      return { ...prevStore, presenceList: [] };
    case TActionType.presenceCreateSuccess:
      if (!!action.payload) {
        return { ...prevStore, presenceList: [...prevStore.presenceList, action.payload] };
      } else {
        return { ...prevStore, presenceList: [...prevStore.presenceList] };
      }
    case TActionType.presenceDeleteSuccess:
      return {
        ...prevStore,
        presenceList: prevStore.presenceList.filter(p => p.original.id !== action.payload),
      };
    case TActionType.presenceUpdateSuccess:
      return {
        ...prevStore,
        presenceList: prevStore.presenceList.map(p => {
          if (p.original.id === action.payload.original.id) return action.payload;
          return p;
        }),
      };
    default:
      return prevStore;
  }
};
