/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TShiftTypeCreateRequest,
  TShiftTypeUpdateRequest,
} from "../redux-modules/declarations/maps/shiftTypes";
import apiClient from "../utils/apiClient";

export const apiShiftTypeList = (): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.SHIFT_TYPES);
};

export const apiShiftTypeCreate = (
  shiftType: TShiftTypeCreateRequest,
): Promise<AxiosResponse<unknown>> => apiClient.post(API_ENDPOINTS.SHIFT_TYPES, shiftType);

export const apiShiftTypeUpdate = (
  shiftType: TShiftTypeUpdateRequest,
): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.SHIFT_TYPES + "/" + shiftType.id, shiftType);

export const apiShiftTypeDelete = (shiftTypeId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.SHIFT_TYPES + "/" + shiftTypeId);
