/** @format */

import { AxiosResponse } from "axios";
import { put, all, call } from "redux-saga/effects";
import {
  apiDocumentTypeCreate,
  apiDocumentTypeDelete,
  apiDocumentTypeList,
  apiDocumentTypeUpdate,
} from "../../api/apiDocumentTypes";
import { showNotification } from "../../utils/showNotification";
import {
  actionDocumentTypeCreateSuccess,
  actionDocumentTypeCreateReset,
  actionDocumentTypeCreateFailure,
  actionDocumentTypeListFailure,
  actionDocumentTypeUpdateSuccess,
  actionDocumentTypeUpdateReset,
  actionDocumentTypeUpdateFailure,
  actionDocumentTypeDeleteSuccess,
  actionDocumentTypeDeleteReset,
  actionDocumentTypeListSuccess,
} from "../actions/actionsDocumentType";
import { actionGroupDeleteFailure } from "../actions/actionsGroups";
import {
  TActionDocumentTypeCreateRequest,
  TActionDocumentTypeListRequest,
  TActionDocumentTypeUpdateRequest,
  TActionDocumentTypeDeleteRequest,
} from "../declarations/actions/documentType";
import { TAPIResponse } from "../declarations/apis/general";
/* import { TDocumentType } from "../declarations/maps/documentTypes";
 */
export function* sagaDocumentTypeCreate(action: TActionDocumentTypeCreateRequest): unknown {
  try {
    const response = yield call(apiDocumentTypeCreate, action.payload);

    yield put(actionDocumentTypeCreateSuccess(response.data.id));
    yield put(actionDocumentTypeCreateReset());
    showNotification("success", "Creato!", "Tipo di documento creato con successo");
  } catch (e) {
    yield put(actionDocumentTypeCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaDocumentTypeList(action: TActionDocumentTypeListRequest): unknown {
  try {
    const response: AxiosResponse<TAPIResponse<unknown>> = yield call(apiDocumentTypeList);

    //@ts-ignore
    yield put(actionDocumentTypeListSuccess(response.data.data));
  } catch (e) {
    yield put(actionDocumentTypeListFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaDocumentTypeUpdate(action: TActionDocumentTypeUpdateRequest): unknown {
  try {
    yield call(apiDocumentTypeUpdate, action.payload);

    yield all([put(actionDocumentTypeUpdateSuccess()), put(actionDocumentTypeUpdateReset())]);
    showNotification("success", "Aggiornato!", "Tipo di documento modificato con successo");
  } catch (e) {
    yield put(actionDocumentTypeUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaDocumentTypeDelete(action: TActionDocumentTypeDeleteRequest): unknown {
  try {
    yield call(apiDocumentTypeDelete, action.payload);

    yield all([
      put(actionDocumentTypeDeleteSuccess(action.payload)),
      put(actionDocumentTypeDeleteReset()),
    ]);
    showNotification("success", "Eliminato!", "Tipo di documento eliminato con successo");
  } catch (e) {
    yield put(actionGroupDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
