/** @format */

import {
  TActionJustificativeStatusListFailure,
  TActionJustificativeStatusListRequest,
  TActionJustificativeStatusListSuccess,
} from "../declarations/actions/justificativeStatuses";
import { TActionType } from "../declarations/actionTypes";
import { TJustificativeStatuses } from "../declarations/maps/justificatives";

type Actions =
  | TActionJustificativeStatusListRequest
  | TActionJustificativeStatusListSuccess
  | TActionJustificativeStatusListFailure;

export const reducerJustificativeStatuses = (
  prevStore: TJustificativeStatuses = {},
  action: Actions,
): TJustificativeStatuses => {
  switch (action.type) {
    case TActionType.justificativeStatusListRequest:
      return { ...prevStore, justificativeStatusList: [] };
    case TActionType.justificativeStatusListSuccess:
      return { ...prevStore, justificativeStatusList: action.payload };
    case TActionType.justificativeStatusListFailure:
      return { ...prevStore, justificativeStatusList: [] };
    default:
      return prevStore;
  }
};
