/** @format */

import { Row, Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productListColumns } from "../../../constants/tables/productListColumns";
import { actionProductListRequest } from "../../../redux-modules/actions/actionsProducts";
import { selectorProductList } from "../../../redux-modules/selectors/selectorsProducts";
import {
  selectorProductListLoading,
  selectorProductDeleted,
} from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../theme/maps/Colors";
import { Routes } from "../../../utils/Routes";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UILink } from "../../ui/Link";
import { UITable } from "../../ui/Table";
import { UITitle } from "../../ui/Title";
import UITransition from "../../ui/Transition";

export const ViewProductsList = () => {
  const dispatch = useDispatch();
  const productList = useSelector(selectorProductList);
  const productListLoading = useSelector(selectorProductListLoading);
  const isProductDeleted = useSelector(selectorProductDeleted);

  useEffect(() => {
    dispatch(actionProductListRequest());
  }, []);

  useEffect(() => {
    if (isProductDeleted) dispatch(actionProductListRequest());
  }, [isProductDeleted]);

  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Prodotti
          </UITitle>
        </Col>
        <Col>
          <UILink
            data-cy="new-product-button"
            route={Routes.productsNew}
            label={<UIButton type="primary">+ Aggiungi Prodotto</UIButton>}
          />
        </Col>
      </Row>
      <UICard>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UITable
              size="middle"
              data-cy="product-list-table"
              loading={productListLoading}
              columns={productListColumns}
              dataSource={productList}
            />
          </Col>
        </Row>
      </UICard>
    </UITransition>
  );
};
