/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaCheckApiToken, sagaLogin, sagaLogout } from "../sagas/sagasAuth";
import { sagaCommunicationList } from "../sagas/sagasCommunications";
import { sagaDepartmentList } from "../sagas/sagasDepartments";
import { sagaDeviceList } from "../sagas/sagasDevices";
import { sagaPersonalDocumentList } from "../sagas/sagasDocuments";
import { sagaJustificativeList } from "../sagas/sagasJustificatives";
import { sagaJustificativeStatusList } from "../sagas/sagasJustificativeStatuses";
import { sagaLocationList } from "../sagas/sagasLocations";
import { sagaModelDetailList } from "../sagas/sagasModelDetails";
import { sagaNotificationList } from "../sagas/sagasNotifications";
import { sagaPresenceTypeList } from "../sagas/sagasPresenceTypes";
import { sagaRequestList } from "../sagas/sagasRequests";
import { sagaShiftTypeList } from "../sagas/sagasShiftTypes";
import { sagaCustomStyleOptionsList } from "../sagas/sagasUsers";

export function* watcherAuth(): unknown {
  yield takeLatest(TActionType.loginRequest, sagaLogin);
  yield takeLatest(TActionType.logoutRequest, sagaLogout);
  yield takeLatest(TActionType.startCheckStatus, sagaCheckApiToken);
  //Login Actions:
  yield takeLatest(TActionType.loginSuccess, sagaPersonalDocumentList);
  yield takeLatest(TActionType.loginSuccess, sagaJustificativeStatusList);
  yield takeLatest(TActionType.loginSuccess, sagaRequestList);
  yield takeLatest(TActionType.loginSuccess, sagaJustificativeList);
  yield takeLatest(TActionType.loginSuccess, sagaModelDetailList);
  yield takeLatest(TActionType.loginSuccess, sagaDeviceList);
  yield takeLatest(TActionType.loginSuccess, sagaPresenceTypeList);
  yield takeLatest(TActionType.loginSuccess, sagaDepartmentList);
  yield takeLatest(TActionType.loginSuccess, sagaShiftTypeList);
  yield takeLatest(TActionType.loginSuccess, sagaCommunicationList);
  yield takeLatest(TActionType.loginSuccess, sagaLocationList);
  yield takeLatest(TActionType.loginSuccess, sagaCustomStyleOptionsList);
  // Redirect
  yield takeLatest("@@router/LOCATION_CHANGE", sagaNotificationList);
}
