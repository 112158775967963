/** @format */

import {
  TActionPermissionListRequest,
  TActionPermissionListSuccess,
  TActionPermissionListFailure,
} from "../declarations/actions/permission";
import { TActionType } from "../declarations/actionTypes";
import { TPermissions } from "../declarations/maps/permissions";

type Actions =
  | TActionPermissionListRequest
  | TActionPermissionListSuccess
  | TActionPermissionListFailure;

export const reducerPermission = (
  prevStore: TPermissions = {
    permissionList: [],
  },
  action: Actions,
): TPermissions => {
  switch (action.type) {
    case TActionType.permissionListRequest:
      return {
        ...prevStore,
      };
    case TActionType.permissionListSuccess:
      return { ...prevStore, permissionList: action.payload };
    case TActionType.permissionListFailure:
      return {
        ...prevStore,
      };
    default:
      return prevStore;
  }
};
