/** @format */

import { Col, Divider, Form, Row, Skeleton } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionTriggerUpdateRequest } from "../../../../../../redux-modules/actions/actionsTrigger";
import { TTrigger } from "../../../../../../redux-modules/declarations/maps/triggers";
import { selectorTriggerListLoading } from "../../../../../../redux-modules/selectors/selectorsUI";
import { UICard } from "../../../../../ui/Card";
import { UICheckbox } from "../../../../../ui/Checkbox";
import { UITitle } from "../../../../../ui/Title";

interface Props {
  trigger: TTrigger;
}

export const TriggerTab = ({ trigger }: Props): JSX.Element => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const triggersLoading = useSelector(selectorTriggerListLoading);
  //@ts-ignore
  const onChange = valueChanges => {
    const updateObject = {
      ...trigger,
      ...valueChanges,
    };
    dispatch(actionTriggerUpdateRequest(updateObject));
  };

  if (!!triggersLoading)
    return (
      <UICard style={{ marginBottom: "20px" }}>
        <Skeleton active />
      </UICard>
    );
  return (
    <>
      <Form form={form} initialValues={trigger} onValuesChange={onChange}>
        <Row>
          <Col span={24}>
            <UITitle level={4}>{trigger.description}</UITitle>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Form.Item valuePropName="checked" name="to_user" label="Invio a utente">
                  <UICheckbox />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item valuePropName="checked" name="to_manager" label="Invio a manager">
                  <UICheckbox />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  valuePropName="checked"
                  name="to_vicemanager"
                  label="Invio a vice manager"
                >
                  <UICheckbox />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item valuePropName="checked" name="to_admin" label="Invio ad amministratore">
                  <UICheckbox />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};
