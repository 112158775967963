/** @format */

import React, { useEffect } from "react";
import { Col, Form, Row, Skeleton } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { useHistory } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UIText } from "../../ui/Text";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorDepartmentUpdated,
  selectorDeviceUpdated,
  selectorLocationsListLoading,
} from "../../../redux-modules/selectors/selectorsUI";
import { UILink } from "../../ui/Link";
import { selectorLocationsList } from "../../../redux-modules/selectors/selectorsLocations";
import { UISelect } from "../../ui/Select";
import { actionDeviceCreateRequest } from "../../../redux-modules/actions/actionsDevices";
import { actionLocationListRequest } from "../../../redux-modules/actions/actionsLocations";
import { actionDepartmentCreateRequest } from "../../../redux-modules/actions/actionsDepartments";
const { Option } = UISelect;

export const ViewDepartmentCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDepartmentCreated = useSelector(selectorDepartmentUpdated);
  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      name: e.name,
    };
    //dispatch

    dispatch(actionDepartmentCreateRequest(contentObj));
  };

  useEffect(() => {
    if (isDepartmentCreated) history.push(Routes.departments);
  }, [isDepartmentCreated]);

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Nuovo Reparto
          </UITitle>
        </Col>
      </Row>
      <Form
        name="new-department"
        className="new-department"
        data-cy="new-department-form"
        onFinish={handleSave}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    label={<UIText strong={true}>Nome</UIText>}
                    rules={[{ required: true, message: "Inserisci un nome!" }]}
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="end" gutter={[24, 24]}>
                <Col>
                  <UILink route={Routes.departments} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item>
                    <UIButton type="primary" data-cy="new-department-button" htmlType="submit">
                      + Crea Reparto
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
    </>
  );
};
