/** @format */
import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import styled from "styled-components";
import { UploadProps } from "antd/lib/upload/Upload";
/* import { UploadChangeParam } from "antd/lib/upload";
 */
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

interface Props extends UploadProps {
  handleUpload: (e: FileList | object[]) => void;
  forXml?: boolean;
  forImg?: boolean;
  multiple?: boolean;
}

export const UIFileUploader = ({
  handleUpload,
  forXml = false,
  forImg = false,
  multiple = false,
}: Props): JSX.Element => {
  const [fileList, setFileList] = useState([]);

  //@ts-ignore
  const onChange = (e: UploadChangeParam<UploadFile<unknown>>): void => {
    if (!multiple) {
      let localList = [...e.fileList];
      localList = localList.slice(-1);
      if (localList[0]) {
        localList[0].status = "done";
      }
      //@ts-ignore
      setFileList(localList);
    } else {
      //@ts-ignore
      setFileList(e.fileList.map(e => ({ ...e, status: "done" })));
    }
  };

  useEffect(() => {
    handleUpload(fileList);
  }, [fileList]);

  return (
    <StyledDragger
      name="file"
      data-xml={forXml}
      multiple={multiple}
      accept={forXml ? ".xml" : forImg ? ".png,.jpeg,.jpg,.heic" : ".pdf,.doc,.docx"}
      fileList={fileList}
      onChange={onChange}
      style={{ borderRadius: "7px" }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Trascina quì il file</p>
      <p className="ant-upload-hint">Oppure clicca, e seleziona un file da caricare</p>
    </StyledDragger>
  );
};

const StyledDragger = styled(Dragger)`
  display: ${props =>
    //@ts-ignore
    !!props.fileList?.length && !!props["data-xml"] ? "none" : "flex"};
`;
