/** @format */

import { TActionType } from "../declarations/actionTypes";
import { TActionLoginSuccess, TActionLogoutSuccess } from "../declarations/actions/auth";
import { TAuth } from "../declarations/maps/auth";
import {
  TActionUserAvatarSuccess,
  TActionUserProfileUpdateSuccess,
} from "../declarations/actions/users";

type Actions =
  | TActionLoginSuccess
  | TActionLogoutSuccess
  | TActionUserAvatarSuccess
  | TActionUserProfileUpdateSuccess;

export const reducerAuth = (prevStore: TAuth = {}, action: Actions): TAuth => {
  switch (action.type) {
    case TActionType.loginSuccess:
      return { user: action.payload.user, tenance: action.payload.tenance };
    case TActionType.userAvatarSuccess:
      return {
        user: prevStore.user
          ? { ...prevStore.user, avatar: action.payload ? action.payload : prevStore.user.avatar }
          : undefined,
        tenance: prevStore.tenance
          ? {
              ...prevStore.tenance,
            }
          : undefined,
      };
    case TActionType.userProfileUpdateSuccess:
      //@ts-ignore
      return { user: { ...prevStore.user, ...action.payload }, tenance: prevStore.tenance };
    case TActionType.logoutSuccess:
      return {};
    default:
      return prevStore;
  }
};
