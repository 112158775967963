/** @format */

import { G2, Heatmap, HeatmapConfig } from "@ant-design/plots";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { API_ENDPOINTS } from "../../../../constants/api";
import apiClient from "../../../../utils/apiClient";

export function shadeColor(color: string, percent: number): string {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt(`${(R * (100 + percent)) / 100}`);
  G = parseInt(`${(G * (100 + percent)) / 100}`);
  B = parseInt(`${(B * (100 + percent)) / 100}`);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

interface Props {
  date: moment.Moment | null;
}
export const RequestStatistics = ({ date }: Props): JSX.Element => {
  const [requestData, setRequestData] = useState<HeatmapConfig>();
  const [_, setRequestLoading] = useState(false);

  useEffect(() => {
    setRequestLoading(true);
    apiClient
      .get(
        API_ENDPOINTS.ANALITICHE_RICHIESTE.replace(
          ":date",
          date ? date.format("YYYY-MM-01") : moment().format("YYYY-MM-01"),
        ),
      )
      .then(res => {
        const config: HeatmapConfig = {
          // @ts-ignore
          data: res.data.map(e => ({
            ...e,
            day: parseInt(moment(e.date, "YYYY-MM-DD").subtract(1, "days").format("d")),
            week:
              moment(e.date, "YYYY-MM-DD").week() -
              moment(e.date, "YYYY-MM-DD").startOf("month").week(),
            date: moment(e.date).format("DD/MM/YYYY"),
            month: 1,
          })),
          height: 400,
          autoFit: false,
          xField: "week",
          yField: "day",
          colorField: "richieste",
          color: ({ richieste }) => {
            //@ts-ignore
            const max = res.data.reduce((max, game) =>
              max.richieste > game.richieste ? max : game,
            ).richieste;
            //@ts-ignore
            const min = res.data.reduce((max, game) =>
              max.richieste < game.richieste ? max : game,
            ).richieste;

            if (min == 0 && max == 0) return "#ffbf60";
            return shadeColor("#ffbf60", -((65 * (richieste - min)) / (max - min)));
          },
          reflect: "y",
          shape: "boundary-polygon",
          meta: {
            day: {
              type: "cat",
              values: ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"],
            },
            week: {
              type: "cat",
            },
            richieste: {
              sync: true,
            },
            date: {
              type: "cat",
            },
          },
          yAxis: {
            grid: null,
          },
          tooltip: {
            title: "date",
            showMarkers: false,
            formatter: (datum: unknown) => {
              return {
                name: "Richieste",
                // @ts-ignore
                value: datum.richieste,
              };
            },
          },
          interactions: [
            {
              type: "element-active",
            },
          ],
          xAxis: {
            position: "top",
            tickLine: null,
            line: null,
            label: {
              offset: 12,
              style: {
                fontSize: 12,
                fill: "#666",
                textBaseline: "top",
              },
              formatter: val => {
                return "";
              },
            },
          },
        };
        setRequestData(config as HeatmapConfig);
        setRequestLoading(false);
      });
  }, [date]);

  G2.registerShape("polygon", "boundary-polygon", {
    draw(cfg, container) {
      const group = container.addGroup();
      const attrs = {
        stroke: "#fff",
        lineWidth: 1,
        fill: cfg.color,
        paht: [],
      };
      const points = cfg.points;
      const path = [
        // @ts-ignore
        ["M", points[0].x, points[0].y],
        // @ts-ignore
        ["L", points[1].x, points[1].y],
        // @ts-ignore
        ["L", points[2].x, points[2].y],
        // @ts-ignore
        ["L", points[3].x, points[3].y],
        ["Z"],
      ]; // @ts-ignore

      attrs.path = this.parsePath(path);
      group.addShape("path", {
        attrs,
      });

      // @ts-ignore
      if (cfg.data.lastWeek) {
        const linePath = [
          // @ts-ignore
          ["M", points[2].x, points[2].y],
          // @ts-ignore
          ["L", points[3].x, points[3].y],
        ];

        group.addShape("path", {
          attrs: {
            // @ts-ignore
            path: this.parsePath(linePath),
            lineWidth: 4,
            stroke: "#404040",
          },
        });

        // @ts-ignore
        if (cfg.data.lastDay) {
          group.addShape("path", {
            attrs: {
              // @ts-ignore
              path: this.parsePath([
                // @ts-ignore
                ["M", points[1].x, points[1].y],
                // @ts-ignore
                ["L", points[2].x, points[2].y],
              ]),
              lineWidth: 4,
              stroke: "#404040",
            },
          });
        }
      }

      return group;
    },
  });

  return <>{requestData && <Heatmap {...requestData} />}</>;
};
