/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TModelDetailCreateRequest,
  TModelDetailUpdateRequest,
} from "../redux-modules/declarations/maps/modelDetails";
import apiClient from "../utils/apiClient";

export const apiModelDetailList = (): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.MODEL_DETAILS);
};

export const apiModelDetailCreate = (
  request: TModelDetailCreateRequest,
): Promise<AxiosResponse<unknown>> => apiClient.post(API_ENDPOINTS.MODEL_DETAILS, request);

export const apiModelDetailUpdate = (
  request: TModelDetailUpdateRequest,
): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.MODEL_DETAILS + "/" + request.id, request);

export const apiModelDetailDelete = (userId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.MODEL_DETAILS + "/" + userId);
