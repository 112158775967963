/** @format */

import { Row, Col, Form } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { actionLocationListRequest } from "../../../redux-modules/actions/actionsLocations";
import { actionWarehouseCreateRequest } from "../../../redux-modules/actions/actionsWarehouses";
import { selectorLocationsList } from "../../../redux-modules/selectors/selectorsLocations";
import {
  selectorLocationsListLoading,
  selectorWarehouseUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../theme/maps/Colors";
import { Routes } from "../../../utils/Routes";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UILink } from "../../ui/Link";
import { UISelect } from "../../ui/Select";
import { UIText } from "../../ui/Text";
import { UITextArea } from "../../ui/Textarea";
import { UITitle } from "../../ui/Title";
const { Option } = UISelect;

export const ViewWarehouseCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationList = useSelector(selectorLocationsList);
  const locationListLoading = useSelector(selectorLocationsListLoading);
  const isWarehouseCreated = useSelector(selectorWarehouseUpdated);

  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      name: e.name,
      description: e.description,
      note: e.note,
      office_id: e.office_id,
    };
    //dispatch

    dispatch(actionWarehouseCreateRequest(contentObj));
  };
  useEffect(() => {
    dispatch(actionLocationListRequest({}));
  }, []);

  useEffect(() => {
    if (isWarehouseCreated) history.push(Routes.warehouses);
  }, [isWarehouseCreated]);

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Nuovo Magazzino
          </UITitle>
        </Col>
      </Row>
      <Form
        name="new-warehouse"
        className="new-warehouse"
        data-cy="new-warehouse-form"
        onFinish={handleSave}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="name"
                    label={<UIText strong={true}>Nome</UIText>}
                    rules={[{ required: false, message: "Inserisci un nome valido!" }]}
                    extra="Il nome del magazzino"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="office_id"
                    label={<UIText strong={true}>Sede</UIText>}
                    rules={[{ required: true, message: "Seleziona una sede!" }]}
                    extra="La sede associata al magazzino"
                  >
                    <UISelect>
                      {locationList.data.map(location => (
                        <Option value={location.id}>{location.name}</Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={18}>
                  <Form.Item
                    name="description"
                    label={<UIText strong={true}>Descrizione</UIText>}
                    extra="Breve descrizione del magazzino"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col xs={24} md={18}>
                  <Form.Item
                    name="note"
                    label={<UIText strong={true}>Note</UIText>}
                    extra="Note aggiuntive"
                  >
                    <UITextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" gutter={[24, 24]}>
                <Col>
                  <UILink route={Routes.warehouses} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item>
                    <UIButton type="primary" data-cy="new-warehouse-button" htmlType="submit">
                      + Crea Magazzino
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
    </>
  );
};
