/** @format */

import { TAPILogoutSuccess } from "../../redux-modules/declarations/apis/auth";

// NOT FOR FAKE
/* import { AxiosResponse } from "axios";
import apiClient from "../../utils/apiClient";
import { API_ENDPOINTS } from "../../constants/api";
 */
/* export const apiLogout = (): Promise<AxiosResponse> => apiClient.post(API_ENDPOINTS.LOGOUT);
 */

export const apiLogout = (): TAPILogoutSuccess => ({
  data: {
    status: "OK!",
    message: "Logout completed",
  },
});
