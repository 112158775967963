/** @format */

import {
  TActionPersonalDocumentListRequest,
  TActionPersonalDocumentListSuccess,
  TActionPersonalDocumentListFailure,
  TActionPersonalDocumentCreateRequest,
  TActionPersonalDocumentCreateSuccess,
  TActionPersonalDocumentCreateFailure,
  TActionPersonalDocumentDeleteRequest,
  TActionPersonalDocumentDeleteSuccess,
  TActionPersonalDocumentDeleteFailure,
  TActionPersonalDocumentDeleteReset,
} from "../declarations/actions/personalDocument";
import { TActionType, TAction } from "../declarations/actionTypes";
import {
  TDocument,
  TDocumentComplete,
  TDocumentCreateRequest,
} from "../declarations/maps/documents";

export const actionPersonalDocumentListRequest = (
  userId: string,
): TActionPersonalDocumentListRequest => ({
  type: TActionType.personalDocumentListRequest,
  payload: userId,
});

export const actionPersonalDocumentListSuccess = (
  payload: TDocumentComplete[],
): TActionPersonalDocumentListSuccess => ({
  type: TActionType.personalDocumentListSuccess,
  payload,
});

export const actionPersonalDocumentListFailure = (): TActionPersonalDocumentListFailure => ({
  type: TActionType.personalDocumentListFailure,
});

export const actionPersonalDocumentCreateRequest = (
  payload: TDocumentCreateRequest,
): TActionPersonalDocumentCreateRequest => ({
  type: TActionType.personalDocumentCreateRequest,
  payload,
});
export const actionPersonalDocumentCreateSuccess = (
  payload: string,
): TActionPersonalDocumentCreateSuccess => ({
  type: TActionType.personalDocumentCreateSuccess,
  payload,
});
export const actionPersonalDocumentCreateFailure = (): TActionPersonalDocumentCreateFailure => ({
  type: TActionType.personalDocumentCreateFailure,
});
export const actionPersonalDocumentCreateReset = (): TAction => ({
  type: TActionType.personalDocumentCreateReset,
});

export const actionPersonalDocumentDeleteRequest = (
  payload: string,
): TActionPersonalDocumentDeleteRequest => ({
  type: TActionType.personalDocumentDeleteRequest,
  payload,
});

export const actionPersonalDocumentDeleteSuccess = (
  payload: string,
): TActionPersonalDocumentDeleteSuccess => ({
  type: TActionType.personalDocumentDeleteSuccess,
  payload,
});

export const actionPersonalDocumentDeleteFailure = (): TActionPersonalDocumentDeleteFailure => ({
  type: TActionType.personalDocumentDeleteFailure,
});
export const actionPersonalDocumentDeleteReset = (): TActionPersonalDocumentDeleteReset => ({
  type: TActionType.personalDocumentDeleteReset,
});
