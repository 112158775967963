/** @format */

import { LeftCircleFilled } from "@ant-design/icons";
import { Col, Drawer, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../../../constants/api";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import { TProduct } from "../../../../redux-modules/declarations/maps/products";
import {
  ProductStatus,
  TWarehouse,
  WarehouseOperation,
} from "../../../../redux-modules/declarations/maps/warehouses";
import { selectorProductList } from "../../../../redux-modules/selectors/selectorsProducts";
import apiClient from "../../../../utils/apiClient";
import { showNotification } from "../../../../utils/showNotification";
import { UIAlert } from "../../../ui/Alert";
import { UIButton } from "../../../ui/Button";
import { UIDatePicker } from "../../../ui/DatePicker";
import { UIInput } from "../../../ui/Input";
import { UISelect } from "../../../ui/Select";
const { Option } = UISelect;

interface Props {
  user_id: string;
  visible: boolean;
  onClose: () => void;
  updateData: () => void;
}

export const AddProductAssignedUserDrawer = ({ user_id, visible, onClose, updateData }: Props) => {
  const [form] = Form.useForm();
  const productList = useSelector(selectorProductList);
  const [warehouse_list, setWarehouseList] = useState<TWarehouse[]>([]);
  const [isProductAvailable, setIsProductAvailable] = useState<boolean | undefined>();
  const [isProductSerializable, setIsProductSerializable] = useState<boolean | undefined>();

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.WAREHOUSES).then(res => {
      setWarehouseList(res.data.data);
    });
  }, []);

  const handleClose = () => {
    form.resetFields();
    setIsProductAvailable(undefined);

    onClose();
  };
  const changingValue = (changedValue: unknown) => {
    const product_id = form.getFieldValue("product_id");
    const product_status = form.getFieldValue("product_status");
    let quantity = form.getFieldValue("quantity");
    const warehouse_id = form.getFieldValue("warehouse_id");

    if (product_id) {
      const serializable = productList.find((product: TProduct) => product.id === product_id)
        ?.is_specific;
      setIsProductSerializable(!!serializable);
      if (!!serializable) {
        quantity = 1;
        form.setFieldsValue({ quantity: 1 });
      }
    }

    if (product_id && product_status) {
      apiClient
        .post(API_ENDPOINTS.WAREHOUSES_AVAILABILITY, {
          product_id,
          product_status,
          quantity,
          warehouse_id,
        })
        .then(res => {
          setIsProductAvailable(!!res.data);
        })
        .catch(err => {
          setIsProductAvailable(false);
        });
    }
  };
  const onSave = () => {
    const values = form.getFieldsValue();
    const caricoObj = {
      product_id: values.product_id,
      user_id,
      product_status: values.product_status,
      quantity: values.quantity,
      operation_type: WarehouseOperation.ASSEGNAZIONE,
      warehouse_id: values.warehouse_id,
      scheduled_return_delivery_date: values.scheduled_return_delivery_date,
      serial: values.serial,
    };
    apiClient
      .post(API_ENDPOINTS.WAREHOUSES_WORKFLOW, caricoObj)
      .then(res => {
        showNotification("success", "Operazione completata!", res.data.message);

        updateData();
        handleClose();
      })
      .catch(e => {
        showNotification("error", "Errore", "Errore durante il caricamento della disponibilità");
      });
  };
  return (
    <Drawer
      title="Aggiungi disponibilità"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <Row justify="space-between">
          <Col>
            <Form.Item name="submit">
              <UIButton
                form="form"
                key="submit"
                htmlType="submit"
                type="primary"
                loading={!productList || !warehouse_list}
              >
                Salva
              </UIButton>
            </Form.Item>
          </Col>
          <Col>
            <UIButton onClick={handleClose}>Annulla</UIButton>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <>
            <Form
              id="form"
              form={form}
              onFinish={onSave}
              onValuesChange={changedValues => changingValue(changedValues)}
            >
              <Row>
                <Col span={24}>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24}>
                      <Form.Item
                        name="product_id"
                        label="Prodotto"
                        rules={[{ required: true, message: "Seleziona un prodotto" }]}
                      >
                        <UISelect
                          loading={!productList}
                          showSearch
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {!!productList &&
                            productList.map((product: TProduct) => (
                              <Option value={product.id}>{product.name}</Option>
                            ))}
                        </UISelect>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24}>
                      <Form.Item
                        name="product_status"
                        label="Stato"
                        rules={[{ required: true, message: "Indica lo stato del prodotto" }]}
                      >
                        <UISelect>
                          {Object.values(ProductStatus).map((status: ProductStatus) => (
                            <Option value={status}>{status}</Option>
                          ))}
                        </UISelect>
                      </Form.Item>
                    </Col>
                  </Row>
                  {!!isProductSerializable && (
                    <>
                      <Row style={{ marginBottom: "5px" }}>
                        <Col span={24}>
                          <Form.Item
                            name="serial"
                            label="Seriale"
                            rules={[{ required: true, message: "Indica il seriale del prodotto" }]}
                          >
                            <UIInput />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24}>
                      <Form.Item
                        name="quantity"
                        label="Quantità"
                        hidden={!!isProductSerializable}
                        rules={[{ required: true, message: "Indica la quantità del prodotto" }]}
                      >
                        <UIInput type="number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24}>
                      <Form.Item
                        name="warehouse_id"
                        label="Magazzino"
                        rules={[{ required: true, message: "Seleziona un magazzino" }]}
                      >
                        <UISelect
                          loading={!warehouse_list}
                          showSearch
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {!!warehouse_list &&
                            warehouse_list.map((warehouse: TWarehouse) => (
                              <Option value={warehouse.id}>{warehouse.name}</Option>
                            ))}
                        </UISelect>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="scheduled_return_delivery_date"
                        label="Data restituzione"
                        rules={[
                          {
                            required: false,
                            message: "Seleziona la data di restituzione del prodotto",
                          },
                        ]}
                      >
                        <UIDatePicker />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </>
        </Col>
      </Row>
      {typeof isProductAvailable !== "undefined" &&
        (isProductAvailable ? (
          <UIAlert message="Prodotto disponibile" type="success" />
        ) : (
          <UIAlert message="Quantità non disponibile in questo magazzino" type="warning" />
        ))}
    </Drawer>
  );
};
