/** @format */

import React from "react";
import styled from "styled-components";

interface Props {
  category: string | null;
  year: string | null;
  month: string | null;
  onClickHome: () => void;
  onClickYear: (year: string) => void;
  onClickCategory: (category: string) => void;
  filesCount?: number;
}
export const FinderBreadcrumbs = ({
  category,
  year,
  month,
  onClickHome,
  onClickCategory,
  onClickYear,
  filesCount = 0,
}: Props): JSX.Element => {
  return (
    <StyledBreadcrumbsDiv>
      {filesCount > 0 && (
        <StyledBreadcrumbLink onClick={() => onClickHome()}>Tipologie</StyledBreadcrumbLink>
      )}
      {!!category && (
        <>
          <StyledBreadcrumbSeparator>{" > "}</StyledBreadcrumbSeparator>
          <StyledBreadcrumbLink onClick={() => onClickCategory(category)}>
            {category}
          </StyledBreadcrumbLink>
        </>
      )}
      {!!year && (
        <>
          <StyledBreadcrumbSeparator>{" > "}</StyledBreadcrumbSeparator>
          <StyledBreadcrumbLink onClick={() => onClickYear(year)}>{year}</StyledBreadcrumbLink>
        </>
      )}
      {!!month && <StyledBreadcrumbSeparator>{" > " + month}</StyledBreadcrumbSeparator>}
    </StyledBreadcrumbsDiv>
  );
};

const StyledBreadcrumbSeparator = styled.span`
  margin-right: 5px;
  font-size: 13px;
  margin-top: 2px;
`;
const StyledBreadcrumbLink = styled.span`
  cursor: pointer;
  font-weight: bold;
  margin-right: 5px;
  color: var(--antd-wave-shadow-color);
  font-size: 15px;
`;
const StyledBreadcrumbsDiv = styled.div`
  display: inline-flex;
  margin: 20px 10px;
`;
