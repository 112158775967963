/** @format */

import { AxiosResponse } from "axios";
import { put, all, call, select } from "redux-saga/effects";

import { showNotification } from "../../utils/showNotification";
import {
  actionRequestCreateSuccess,
  actionRequestCreateReset,
  actionRequestCreateFailure,
  actionRequestListFailure,
  actionRequestDeleteSuccess,
  actionRequestDeleteReset,
  actionRequestListSuccess,
  actionRequestListLoadingRequest,
  actionRequestListLoadingSuccess,
  actionRequestListLoadingFailure,
  actionRequestUpdateFailure,
  actionRequestUpdateReset,
  actionRequestUpdateSuccess,
} from "../actions/actionsRequest";
import { actionGroupDeleteFailure } from "../actions/actionsGroups";

import {
  TActionRequestCreateRequest,
  TActionRequestListRequest,
  TActionRequestDeleteRequest,
  TActionRequestUpdateRequest,
} from "../declarations/actions/request";
import { TAPIResponse } from "../declarations/apis/general";
import {
  apiRequestCreate,
  apiRequestList,
  apiRequestDelete,
  apiRequestUpdate,
} from "../../api/apiReuqests";
/* import { TRequest } from "../declarations/maps/requests";
 */
export function* sagaRequestCreate(action: TActionRequestCreateRequest): unknown {
  try {
    const response = yield call(apiRequestCreate, action.payload);

    yield put(actionRequestCreateSuccess(response.data.id));
    yield put(actionRequestCreateReset());
    showNotification("success", "Caricata!", "Richiesta caricata con successo");
  } catch (e) {
    yield put(actionRequestCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaRequestUpdate(action: TActionRequestUpdateRequest): unknown {
  try {
    yield call(apiRequestUpdate, action.payload);

    yield all([put(actionRequestUpdateSuccess()), put(actionRequestUpdateReset())]);
    showNotification("success", "Aggiornata!", "Richiesta modificata con successo");
  } catch (e) {
    yield put(actionRequestUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaRequestList(action: TActionRequestListRequest): unknown {
  try {
    yield put(actionRequestListLoadingRequest());
    const response: AxiosResponse<TAPIResponse<unknown>> = yield call(
      apiRequestList,
      action.payload,
    );
    //@ts-ignore
    yield put(actionRequestListSuccess(response.data.data));
    yield put(actionRequestListLoadingSuccess());
  } catch (e) {
    yield put(actionRequestListFailure());
    yield put(actionRequestListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaRequestDelete(action: TActionRequestDeleteRequest): unknown {
  try {
    yield call(apiRequestDelete, action.payload);

    yield all([put(actionRequestDeleteSuccess(action.payload)), put(actionRequestDeleteReset())]);
    showNotification("success", "Eliminata!", "Richiesta eliminata con successo");
  } catch (e) {
    yield put(actionGroupDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
