/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaWarehouseCreate,
  sagaWarehouseDelete,
  sagaWarehouseList,
  sagaWarehouseUpdate,
} from "../sagas/sagasWarehouses";

export function* watcherWarehouses(): unknown {
  yield takeLatest(TActionType.warehouseCreateRequest, sagaWarehouseCreate);
  yield takeLatest(TActionType.warehouseListRequest, sagaWarehouseList);
  yield takeLatest(TActionType.warehouseDeleteRequest, sagaWarehouseDelete);
  yield takeLatest(TActionType.warehouseUpdateRequest, sagaWarehouseUpdate);
}
