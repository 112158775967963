/** @format */

import { Row, Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { SETTINGSTABS } from "../../../../../constants/settings";
import { presenceTypeListColumns } from "../../../../../constants/tables/presenceTypeListColumns";
import { actionPresenceTypeListRequest } from "../../../../../redux-modules/actions/actionsPresenceTypes";
import { selectorPresenceTypeList } from "../../../../../redux-modules/selectors/selectorsPresenceTypes";
import {
  selectorPresenceTypeDeleted,
  selectorPresenceTypeListLoading,
  selectorPresenceTypeUpdated,
} from "../../../../../redux-modules/selectors/selectorsUI";
import { Routes } from "../../../../../utils/Routes";
import { UIButton } from "../../../../ui/Button";
import { UITable } from "../../../../ui/Table";
import { UITitle } from "../../../../ui/Title";
import UITransition from "../../../../ui/Transition";
import { ViewPresenceTypeCreate } from "../../../ViewPresenceTypeCreate";
import { ViewPresenceTypeEdit } from "../../../ViewPresenceTypeEdit";

const PresenceTypesTab = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const presenceTypeDeleted = useSelector(selectorPresenceTypeDeleted);
  const presenceTypeUpdated = useSelector(selectorPresenceTypeUpdated);
  const presenceTypeListLoading = useSelector(selectorPresenceTypeListLoading);
  const presenceTypeList = useSelector(selectorPresenceTypeList);

  useEffect(() => {
    if (history.location.pathname === Routes.settings + SETTINGSTABS.PRESENCE_TYPES) {
      dispatch(actionPresenceTypeListRequest());
    }
  }, [presenceTypeDeleted, presenceTypeUpdated]);

  switch (location.pathname) {
    case Routes.settings + SETTINGSTABS.PRESENCE_TYPES + "/nuovo":
      return <ViewPresenceTypeCreate />;
    case Routes.settings + SETTINGSTABS.PRESENCE_TYPES:
      return (
        <UITransition>
          <Row justify="space-between" align="middle" gutter={[16, 16]}>
            <Col>
              <UITitle level={4}>Tipologie Presenze</UITitle>
            </Col>
            <Col>
              <span
                onClick={() =>
                  history.push(Routes.settings + SETTINGSTABS.PRESENCE_TYPES + "/nuovo")
                }
              >
                <UIButton type="primary">Crea Nuova</UIButton>
              </span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <UITable
                loading={presenceTypeListLoading}
                columns={presenceTypeListColumns}
                dataSource={presenceTypeList}
                pagination={false}
                size="middle"
              />
            </Col>
          </Row>
        </UITransition>
      );
    default:
      return <ViewPresenceTypeEdit />;
  }
};
export default React.memo(PresenceTypesTab);
