/** @format */

import {
    TActionGroupsListRequest,
    TActionGroupsListSuccess,
    TActionGroupsListFailure,
    TActionGroupsListLoadingFailure,
    TActionGroupsListLoadingRequest,
    TActionGroupsListLoadingSuccess,
    TActionGroupCreateFailure,
    TActionGroupCreateRequest,
    TActionGroupCreateSuccess,
    TActionGroupUpdateFailure,
    TActionGroupUpdateRequest,
    TActionGroupUpdateSuccess,
    TActionGroupDeleteFailure,
    TActionGroupDeleteRequest,
    TActionGroupDeleteSuccess,
    TActionGroupDeleteReset,
    TActionGroupUpdateReset,
  } from "../declarations/actions/groups";
  /** @format */
  
  import { TAction, TActionType } from "../declarations/actionTypes";
import { TAPIGroupsListRequest } from "../declarations/apis/groups";
import { TGroupCreateRequest, TGroupList, TGroupUpdateRequest } from "../declarations/maps/groups";
  
//List
  export const actionGroupsListRequest = (
      payload: TAPIGroupsListRequest,
  ): TActionGroupsListRequest => ({
    type: TActionType.groupsListRequest,
    payload,
  });
  
  export const actionGroupsListSuccess = (
    payload: TGroupList,
  ): TActionGroupsListSuccess => ({
    type: TActionType.groupsListSuccess,
    payload,
  });
  
  export const actionGroupsListFailure = (): TActionGroupsListFailure => ({
    type: TActionType.groupsListFailure,
  });

  //---

  //Loading
  
  export const actionGroupsListLoadingRequest = (): TActionGroupsListLoadingRequest => ({
    type: TActionType.groupsListLoadingRequest,
  });
  
  export const actionGroupsListLoadingSuccess = (): TActionGroupsListLoadingSuccess => ({
    type: TActionType.groupsListLoadingSuccess,
  });
  
  export const actionGroupsListLoadingFailure = (): TActionGroupsListLoadingFailure => ({
    type: TActionType.groupsListLoadingFailure,
  });

  // ---
  //Create
  export const actionGroupCreateRequest = (payload: TGroupCreateRequest): TActionGroupCreateRequest => ({type: TActionType.groupCreateRequest, payload});
  export const actionGroupCreateSuccess = (payload:string): TActionGroupCreateSuccess => ({type: TActionType.groupCreateSuccess, payload});
  export const actionGroupCreateFailure = (): TActionGroupCreateFailure => ({type: TActionType.groupCreateFailure});
  export const actionGroupCreateReset = ():TAction => ({type: TActionType.groupCreateReset});
  // ---
    // Update
    export const actionGroupUpdateRequest = (
      payload: TGroupUpdateRequest,
    ): TActionGroupUpdateRequest => ({
      type: TActionType.groupUpdateRequest,
      payload,
    });
    
    export const actionGroupUpdateSuccess = (): TActionGroupUpdateSuccess => ({
      type: TActionType.groupUpdateSuccess,
    });
    
    export const actionGroupUpdateFailure = (): TActionGroupUpdateFailure => ({
      type: TActionType.groupUpdateFailure
    });
    
export const actionGroupUpdateReset = (): TActionGroupUpdateReset => ({
  type: TActionType.groupUpdateReset,
});

  // ---
  // Delete
  export const actionGroupDeleteRequest = (payload: string): TActionGroupDeleteRequest => ({
    type: TActionType.groupDeleteRequest,
    payload,
  });
  
  export const actionGroupDeleteSuccess = (payload: string): TActionGroupDeleteSuccess => ({
    type: TActionType.groupDeleteSuccess,
    payload,
  });
  
  export const actionGroupDeleteFailure = (): TActionGroupDeleteFailure => ({
    type: TActionType.groupDeleteFailure
  });

  export const actionGroupDeleteReset = (): TActionGroupDeleteReset => ({
    type: TActionType.groupDeleteReset,
  });
  
  // ---