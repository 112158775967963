/** @format */

import {
  TActionTriggerListFailure,
  TActionTriggerListRequest,
  TActionTriggerListSuccess,
} from "../declarations/actions/trigger";
import { TActionType } from "../declarations/actionTypes";
import { TTriggers } from "../declarations/maps/triggers";

type Actions = TActionTriggerListRequest | TActionTriggerListSuccess | TActionTriggerListFailure;

export const reducerTriggers = (prevStore: TTriggers = {}, action: Actions): TTriggers => {
  switch (action.type) {
    case TActionType.triggerListRequest:
      return { ...prevStore, triggerList: [] };
    case TActionType.triggerListSuccess:
      return { ...prevStore, triggerList: action.payload };
    case TActionType.triggerListFailure:
      return { ...prevStore, triggerList: [] };
    default:
      return prevStore;
  }
};
