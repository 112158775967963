/** @format */

import { Row, Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { communicationListColumns } from "../../../constants/tables/communicationListColumns";
import { actionCommunicationListRequest } from "../../../redux-modules/actions/actionsCommunications";
import { selectorCommunicationList } from "../../../redux-modules/selectors/selectorsCommunications";
import {
  selectorCommunicationDeleted,
  selectorCommunicationListLoading,
} from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../theme/maps/Colors";
import { Routes } from "../../../utils/Routes";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UILink } from "../../ui/Link";
import { UITable } from "../../ui/Table";
import { UITitle } from "../../ui/Title";
import UITransition from "../../ui/Transition";

export const ViewCommunicationList = (): JSX.Element => {
  const dispatch = useDispatch();
  const communicationList = useSelector(selectorCommunicationList);
  const communicationListLoading = useSelector(selectorCommunicationListLoading);
  const communicationDeleted = useSelector(selectorCommunicationDeleted);

  useEffect(() => {
    dispatch(actionCommunicationListRequest());
  }, []);

  useEffect(() => {
    if (communicationDeleted) dispatch(actionCommunicationListRequest());
  }, [communicationDeleted]);

  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Comunicazioni
          </UITitle>
        </Col>
        <Col>
          <UILink
            data-cy="new-communication-button"
            route={Routes.comunicationsNew}
            label={<UIButton type="primary">+ Nuova Comunicazione</UIButton>}
          />
        </Col>
      </Row>
      <UICard>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UITable
              size="middle"
              data-cy="device-list-table"
              loading={communicationListLoading}
              columns={communicationListColumns}
              dataSource={communicationList}
            />
          </Col>
        </Row>
      </UICard>
    </UITransition>
  );
};
