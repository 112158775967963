/** @format */

import { Input } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import styled from "styled-components";

const { Search } = Input;

interface Props extends TextProps {}

export const UISearch = styled(Search)<Props>`
  border-radius: 7px;
  height: 36px;

  .ant-input-search-icon::before {
    border: 0 !important;
  }

  .ant-input-group > .ant-input:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  .ant-input-search-button {
    border-top-right-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .anticon svg {
    color: ${(props): string => props.theme.colors.darkBlue}!important;
  }
`;
