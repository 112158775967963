/** @format */

export enum TActionType {
  //region Login

  loginRequest = "[Action] - Create Login Request",
  loginSuccess = "[Event] - Login Success",
  loginFailure = "[Event] - Login Failure",
  loginReset = "[Action] - Login Reset",

  logoutRequest = "[Action] - Create Logout Request",
  logoutSuccess = "[Event] - Logout Success",
  logoutFailure = "[Event] - Logout Failure",

  startCheckStatus = "[Action] - Start Api Key Check",
  successCheckStatus = "[Event] - Success Api Key Check",
  failureCheckStatus = "[Event] - Failure Api Key Check",
  endCheckStatus = "[Event] - End Api Key Check",

  //endregion

  //region Navigation

  navigationRequest = "[Action] - Navigation Request",
  navigationSuccess = "[Action] - Navigation Success",
  navigationFailure = "[Action] - Navigation Failure",

  appLoadingRequest = "[Action] - App Loading Request",
  appLoadingSuccess = "[Action] - App Loading Success",
  appLoadingFailure = "[Action] - App Loading Failure",

  //endregion

  //region Users

  usersListRequest = "[Action] - Users List Request",
  usersListSuccess = "[Action] - Users List Success",
  usersListFailure = "[Action] - Users List Failure",

  userCreateRequest = "[Action] - Users Create Request",
  userCreateSuccess = "[Action] - Users Create Success",
  userCreateFailure = "[Action] - Users Create Failure",
  userCreateReset = "[Action] - Users Create Reset",

  userUpdateRequest = "[Action] - Users Update Request",
  userUpdateSuccess = "[Action] - Users Update Success",
  userUpdateFailure = "[Action] - Users Update Failure",
  userUpdateReset = "[Action] - Users Update Reset",

  userDeleteRequest = "[Action] - Users Delete Request",
  userDeleteSuccess = "[Action] - Users Delete Success",
  userDeleteFailure = "[Action] - Users Delete Failure",
  userDeleteReset = "[Action] - Users Delete Reset",

  userAvatarRequest = "[Action] - Users Avatar Upload Request",
  userAvatarSuccess = "[Action] - Users Avatar Upload Success",
  userAvatarFailure = "[Action] - Users Avatar Upload Failure",
  userAvatarReset = "[Action] - Users Avatar Upload Reset",

  userProfileUpdateRequest = "[Action] - Users Profile Update Request",
  userProfileUpdateSuccess = "[Action] - Users Profile Update Success",
  userProfileUpdateFailure = "[Action] - Users Profile Update Failure",
  userProfileUpdateReset = "[Action] - Users Profile Update Reset",

  userDocumentUploadRequest = "[Action] - Users Document Upload Request",
  userDocumentUploadSuccess = "[Action] - Users Document Upload Success",
  userDocumentUploadFailure = "[Action] - Users Document Upload Failure",
  userDocumentUploadReset = "[Action] - Users Document Upload Reset",

  usersListLoadingRequest = "[Action] - Loading Users List Request",
  usersListLoadingSuccess = "[Action] - Loading Users List Success",
  usersListLoadingFailure = "[Action] - Loading Users List Failure",

  documentListLoadingRequest = "[Action] - Loading Document List Request",
  documentListLoadingSuccess = "[Action] - Loading Document List Success",
  documentListLoadingFailure = "[Action] - Loading Document List Failure",

  usersListPageSizeRequest = "[Action] - User List Page Size Change Request",
  usersListPageSizeSuccess = "[Action] - User List Page Size Change Success",
  usersListPageSizeFailure = "[Action] - User List Page Size Change Failure",

  //endregion

  //region Permissions
  permissionListRequest = "[Action] - Permission List Request",
  permissionListSuccess = "[Action] - Permission List Success",
  permissionListFailure = "[Action] - Permission List Failure",

  permissionListLoadingRequest = "[Action] - Loading Permission List Request",
  permissionListLoadingSuccess = "[Action] - Loading Permission List Success",
  permissionListLoadingFailure = "[Action] - Loading Permission List Failure",
  //endregion

  //region Groups

  groupsListRequest = "[Action] - Groups List Request",
  groupsListSuccess = "[Action] - Groups List Success",
  groupsListFailure = "[Action] - Groups List Failure",

  groupCreateRequest = "[Action] - Groups Create Request",
  groupCreateSuccess = "[Action] - Groups Create Success",
  groupCreateFailure = "[Action] - Groups Create Failure",
  groupCreateReset = "[Action] - Groups Create Reset",

  groupUpdateRequest = "[Action] - Groups Update Request",
  groupUpdateSuccess = "[Action] - Groups Update Success",
  groupUpdateFailure = "[Action] - Groups Update Failure",
  groupUpdateReset = "[Action] - Groups Update Reset",

  groupDeleteRequest = "[Action] - Groups Delete Request",
  groupDeleteSuccess = "[Action] - Groups Delete Success",
  groupDeleteFailure = "[Action] - Groups Delete Failure",
  groupDeleteReset = "[Action] - Groups Delete Reset",

  groupsListLoadingRequest = "[Action] - Loading Groups List Request",
  groupsListLoadingSuccess = "[Action] - Loading Groups List Success",
  groupsListLoadingFailure = "[Action] - Loading Groups List Failure",

  //endregion

  //region Location

  locationListRequest = "[Action] - Location List Request",
  locationListSuccess = "[Action] - Location List Success",
  locationListFailure = "[Action] - Location List Failure",

  locationCreateRequest = "[Action] - Location Create Request",
  locationCreateSuccess = "[Action] - Location Create Success",
  locationCreateFailure = "[Action] - Location Create Failure",
  locationCreateReset = "[Action] - Location Create Reset",

  locationUpdateRequest = "[Action] - Location Update Request",
  locationUpdateSuccess = "[Action] - Location Update Success",
  locationUpdateFailure = "[Action] - Location Update Failure",
  locationUpdateReset = "[Action] - Location Update Reset",

  locationDeleteRequest = "[Action] - Location Delete Request",
  locationDeleteSuccess = "[Action] - Location Delete Success",
  locationDeleteFailure = "[Action] - Location Delete Failure",
  locationDeleteReset = "[Action] - Location Delete Reset",

  locationListLoadingRequest = "[Action] - Loading Location List Request",
  locationListLoadingSuccess = "[Action] - Loading Location List Success",
  locationListLoadingFailure = "[Action] - Loading Location List Failure",

  //endregion

  //region Document
  documentListRequest = "[Action] - Document  List Request",
  documentListSuccess = "[Action] - Document  List Success",
  documentListFailure = "[Action] - Document  List Failure",

  documentCreateRequest = "[Action] - Document  Create Request",
  documentCreateSuccess = "[Action] - Document  Create Success",
  documentCreateFailure = "[Action] - Document  Create Failure",
  documentCreateReset = "[Action] - Document  Create Reset",

  documentDeleteRequest = "[Action] - Document  Delete Request",
  documentDeleteSuccess = "[Action] - Document  Delete Success",
  documentDeleteFailure = "[Action] - Document  Delete Failure",
  documentDeleteReset = "[Action] - Document  Delete Reset",

  //endregion

  //endregion

  //region PersonalDocument
  personalDocumentListRequest = "[Action] - Personal Document List Request",
  personalDocumentListSuccess = "[Action] - Personal Document List Success",
  personalDocumentListFailure = "[Action] - Personal Document List Failure",

  personalDocumentCreateRequest = "[Action] - Personal Document Create Request",
  personalDocumentCreateSuccess = "[Action] - Personal Document Create Success",
  personalDocumentCreateFailure = "[Action] - Personal Document Create Failure",
  personalDocumentCreateReset = "[Action] - Personal Document Create Reset",

  personalDocumentDeleteRequest = "[Action] - Personal Document Delete Request",
  personalDocumentDeleteSuccess = "[Action] - Personal Document Delete Success",
  personalDocumentDeleteFailure = "[Action] - Personal Document Delete Failure",
  personalDocumentDeleteReset = "[Action] - Personal Document Delete Reset",

  //endregion

  //region DocumentType
  documentTypeListRequest = "[Action] - Document Type List Request",
  documentTypeListSuccess = "[Action] - Document Type List Success",
  documentTypeListFailure = "[Action] - Document Type List Failure",

  documentTypeCreateRequest = "[Action] - Document Type Create Request",
  documentTypeCreateSuccess = "[Action] - Document Type Create Success",
  documentTypeCreateFailure = "[Action] - Document Type Create Failure",
  documentTypeCreateReset = "[Action] - Document Type Create Reset",

  documentTypeUpdateRequest = "[Action] - Document Type Update Request",
  documentTypeUpdateSuccess = "[Action] - Document Type Update Success",
  documentTypeUpdateFailure = "[Action] - Document Type Update Failure",
  documentTypeUpdateReset = "[Action] - Document Type Update Reset",

  documentTypeDeleteRequest = "[Action] - Document Type Delete Request",
  documentTypeDeleteSuccess = "[Action] - Document Type Delete Success",
  documentTypeDeleteFailure = "[Action] - Document Type Delete Failure",
  documentTypeDeleteReset = "[Action] - Document Type Delete Reset",

  //endregion

  //region ModelDetail
  modelDetailListRequest = "[Action] - Model Detail List Request",
  modelDetailListSuccess = "[Action] - Model Detail List Success",
  modelDetailListFailure = "[Action] - Model Detail List Failure",

  modelDetailCreateRequest = "[Action] - Model Detail Create Request",
  modelDetailCreateSuccess = "[Action] - Model Detail Create Success",
  modelDetailCreateFailure = "[Action] - Model Detail Create Failure",
  modelDetailCreateReset = "[Action] - Model Detail Create Reset",

  modelDetailUpdateRequest = "[Action] - Model Detail Update Request",
  modelDetailUpdateSuccess = "[Action] - Model Detail Update Success",
  modelDetailUpdateFailure = "[Action] - Model Detail Update Failure",
  modelDetailUpdateReset = "[Action] - Model Detail Update Reset",

  modelDetailDeleteRequest = "[Action] - Model Detail Delete Request",
  modelDetailDeleteSuccess = "[Action] - Model Detail Delete Success",
  modelDetailDeleteFailure = "[Action] - Model Detail Delete Failure",
  modelDetailDeleteReset = "[Action] - Model Detail Delete Reset",

  //endregion

  //region ModelDetail
  requestListRequest = "[Action] - Request List Request",
  requestListSuccess = "[Action] - Request List Success",
  requestListFailure = "[Action] - Request List Failure",

  requestCreateRequest = "[Action] - Request Create Request",
  requestCreateSuccess = "[Action] - Request Create Success",
  requestCreateFailure = "[Action] - Request Create Failure",
  requestCreateReset = "[Action] - Request Create Reset",

  requestUpdateRequest = "[Action] - Request Update Request",
  requestUpdateSuccess = "[Action] - Request Update Success",
  requestUpdateFailure = "[Action] - Request Update Failure",
  requestUpdateReset = "[Action] - Request Update Reset",

  requestDeleteRequest = "[Action] - Request Delete Request",
  requestDeleteSuccess = "[Action] - Request Delete Success",
  requestDeleteFailure = "[Action] - Request Delete Failure",
  requestDeleteReset = "[Action] - Request Delete Reset",

  requestListLoadingRequest = "[Action] - Loading Request List Request",
  requestListLoadingSuccess = "[Action] - Loading Request List Success",
  requestListLoadingFailure = "[Action] - Loading Request List Failure",

  //endregion

  // region Triggers

  triggerListRequest = "[Action] - Trigger List Request",
  triggerListSuccess = "[Action] - Trigger List Success",
  triggerListFailure = "[Action] - Trigger List Failure",

  triggerUpdateRequest = "[Action] - Trigger Update Request",
  triggerUpdateSuccess = "[Action] - Trigger Update Success",
  triggerUpdateFailure = "[Action] - Trigger Update Failure",
  triggerUpdateReset = "[Action] - Trigger Update Reset",

  triggerListLoadingRequest = "[Action] - Loading Trigger List Request",
  triggerListLoadingSuccess = "[Action] - Loading Trigger List Success",
  triggerListLoadingFailure = "[Action] - Loading Trigger List Failure",

  //endregion Triggers
  // region Notifications

  notificationListRequest = "[Action] - Notification List Request",
  notificationListSuccess = "[Action] - Notification List Success",
  notificationListFailure = "[Action] - Notification List Failure",

  notificationReadRequest = "[Action] - Notification Read Request",
  notificationReadSuccess = "[Action] - Notification Read Success",
  notificationReadFailure = "[Action] - Notification Read Failure",

  notificationDeleteRequest = "[Action] - Notification Delete Request",
  notificationDeleteSuccess = "[Action] - Notification Delete Success",
  notificationDeleteFailure = "[Action] - Notification Delete Failure",
  notificationDeleteReset = "[Action] - Notification Delete Reset",

  notificationListLoadingRequest = "[Action] - Loading Notification List Request",
  notificationListLoadingSuccess = "[Action] - Loading Notification List Success",
  notificationListLoadingFailure = "[Action] - Loading Notification List Failure",

  //endregion Notifications

  //region Justificatives

  justificativeListRequest = "[Action] - Justificative List Request",
  justificativeListSuccess = "[Action] - Justificative List Success",
  justificativeListFailure = "[Action] - Justificative List Failure",

  justificativeCreateRequest = "[Action] - Justificative Create Request",
  justificativeCreateSuccess = "[Action] - Justificative Create Success",
  justificativeCreateFailure = "[Action] - Justificative Create Failure",
  justificativeCreateReset = "[Action] - Justificative Create Reset",

  justificativeUpdateRequest = "[Action] - Justificative Update Request",
  justificativeUpdateSuccess = "[Action] - Justificative Update Success",
  justificativeUpdateFailure = "[Action] - Justificative Update Failure",
  justificativeUpdateReset = "[Action] - Justificative Update Reset",

  justificativeDeleteRequest = "[Action] - Justificative Delete Request",
  justificativeDeleteSuccess = "[Action] - Justificative Delete Success",
  justificativeDeleteFailure = "[Action] - Justificative Delete Failure",
  justificativeDeleteReset = "[Action] - Justificative Delete Reset",

  justificativeListLoadingRequest = "[Action] - Loading Justificative List Request",
  justificativeListLoadingSuccess = "[Action] - Loading Justificative List Success",
  justificativeListLoadingFailure = "[Action] - Loading Justificative List Failure",

  //endregion

  // region  Devices

  deviceListRequest = "[Action] - Devices List Request",
  deviceListSuccess = "[Action] - Devices List Success",
  deviceListFailure = "[Action] - Devices List Failure",

  deviceCreateRequest = "[Action] - Device Create Request",
  deviceCreateSuccess = "[Action] - Device Create Success",
  deviceCreateFailure = "[Action] - Device Create Failure",
  deviceCreateReset = "[Action] - Device Create Reset",

  deviceUpdateRequest = "[Action] - Devices Update Request",
  deviceUpdateSuccess = "[Action] - Devices Update Success",
  deviceUpdateFailure = "[Action] - Devices Update Failure",
  deviceUpdateReset = "[Action] - Devices Update Reset",

  deviceDeleteRequest = "[Action] - Devices Delete Request",
  deviceDeleteSuccess = "[Action] - Devices Delete Success",
  deviceDeleteFailure = "[Action] - Devices Delete Failure",
  deviceDeleteReset = "[Action] - Devices Delete Reset",

  deviceListLoadingRequest = "[Action] - Loading Devices List Request",
  deviceListLoadingSuccess = "[Action] - Loading Devices List Success",
  deviceListLoadingFailure = "[Action] - Loading Devices List Failure",

  //endregion Devices

  // region  Department

  departmentListRequest = "[Action] - Department  List Request",
  departmentListSuccess = "[Action] - Department  List Success",
  departmentListFailure = "[Action] - Department  List Failure",

  departmentCreateRequest = "[Action] - Department Create Request",
  departmentCreateSuccess = "[Action] - Department Create Success",
  departmentCreateFailure = "[Action] - Department Create Failure",
  departmentCreateReset = "[Action] - Department Create Reset",

  departmentUpdateRequest = "[Action] - Department  Update Request",
  departmentUpdateSuccess = "[Action] - Department  Update Success",
  departmentUpdateFailure = "[Action] - Department  Update Failure",
  departmentUpdateReset = "[Action] - Department  Update Reset",

  departmentDeleteRequest = "[Action] - Department  Delete Request",
  departmentDeleteSuccess = "[Action] - Department  Delete Success",
  departmentDeleteFailure = "[Action] - Department  Delete Failure",
  departmentDeleteReset = "[Action] - Department  Delete Reset",

  departmentListLoadingRequest = "[Action] - Loading Department  List Request",
  departmentListLoadingSuccess = "[Action] - Loading Department  List Success",
  departmentListLoadingFailure = "[Action] - Loading Department  List Failure",

  //endregion Devices

  // region  ShiftType

  shiftTypeListRequest = "[Action] - ShiftType  List Request",
  shiftTypeListSuccess = "[Action] - ShiftType  List Success",
  shiftTypeListFailure = "[Action] - ShiftType  List Failure",

  shiftTypeCreateRequest = "[Action] - ShiftType Create Request",
  shiftTypeCreateSuccess = "[Action] - ShiftType Create Success",
  shiftTypeCreateFailure = "[Action] - ShiftType Create Failure",
  shiftTypeCreateReset = "[Action] - ShiftType Create Reset",

  shiftTypeUpdateRequest = "[Action] - ShiftType  Update Request",
  shiftTypeUpdateSuccess = "[Action] - ShiftType  Update Success",
  shiftTypeUpdateFailure = "[Action] - ShiftType  Update Failure",
  shiftTypeUpdateReset = "[Action] - ShiftType  Update Reset",

  shiftTypeDeleteRequest = "[Action] - ShiftType  Delete Request",
  shiftTypeDeleteSuccess = "[Action] - ShiftType  Delete Success",
  shiftTypeDeleteFailure = "[Action] - ShiftType  Delete Failure",
  shiftTypeDeleteReset = "[Action] - ShiftType  Delete Reset",

  shiftTypeListLoadingRequest = "[Action] - Loading ShiftType  List Request",
  shiftTypeListLoadingSuccess = "[Action] - Loading ShiftType  List Success",
  shiftTypeListLoadingFailure = "[Action] - Loading ShiftType  List Failure",

  //endregion ShiftType

  // region  Shift

  shiftListRequest = "[Action] - Shift  List Request",
  shiftListSuccess = "[Action] - Shift  List Success",
  shiftListFailure = "[Action] - Shift  List Failure",

  shiftCreateRequest = "[Action] - Shift Create Request",
  shiftCreateSuccess = "[Action] - Shift Create Success",
  shiftCreateFailure = "[Action] - Shift Create Failure",
  shiftCreateReset = "[Action] - Shift Create Reset",

  shiftUpdateRequest = "[Action] - Shift Update Request",
  shiftUpdateSuccess = "[Action] - Shift Update Success",
  shiftUpdateFailure = "[Action] - Shift Update Failure",
  shiftUpdateReset = "[Action] - Shift Update Reset",

  shiftDeleteRequest = "[Action] - Shift  Delete Request",
  shiftDeleteSuccess = "[Action] - Shift  Delete Success",
  shiftDeleteFailure = "[Action] - Shift  Delete Failure",
  shiftDeleteReset = "[Action] - Shift  Delete Reset",

  shiftListLoadingRequest = "[Action] - Loading Shift  List Request",
  shiftListLoadingSuccess = "[Action] - Loading Shift  List Success",
  shiftListLoadingFailure = "[Action] - Loading Shift  List Failure",

  //endregion Shift

  // region  PresenceType

  presenceTypeListRequest = "[Action] - PresenceType  List Request",
  presenceTypeListSuccess = "[Action] - PresenceType  List Success",
  presenceTypeListFailure = "[Action] - PresenceType  List Failure",

  presenceTypeCreateRequest = "[Action] - PresenceType Create Request",
  presenceTypeCreateSuccess = "[Action] - PresenceType Create Success",
  presenceTypeCreateFailure = "[Action] - PresenceType Create Failure",
  presenceTypeCreateReset = "[Action] - PresenceType Create Reset",

  presenceTypeUpdateRequest = "[Action] - PresenceType  Update Request",
  presenceTypeUpdateSuccess = "[Action] - PresenceType  Update Success",
  presenceTypeUpdateFailure = "[Action] - PresenceType  Update Failure",
  presenceTypeUpdateReset = "[Action] - PresenceType  Update Reset",

  presenceTypeDeleteRequest = "[Action] - PresenceType  Delete Request",
  presenceTypeDeleteSuccess = "[Action] - PresenceType  Delete Success",
  presenceTypeDeleteFailure = "[Action] - PresenceType  Delete Failure",
  presenceTypeDeleteReset = "[Action] - PresenceType  Delete Reset",

  presenceTypeListLoadingRequest = "[Action] - Loading PresenceType  List Request",
  presenceTypeListLoadingSuccess = "[Action] - Loading PresenceType  List Success",
  presenceTypeListLoadingFailure = "[Action] - Loading PresenceType  List Failure",

  //endregion PresenceType

  // region  Presence

  presenceListRequest = "[Action] - Presence  List Request",
  presenceListSuccess = "[Action] - Presence  List Success",
  presenceListFailure = "[Action] - Presence  List Failure",

  presenceCreateRequest = "[Action] - Presence Create Request",
  presenceCreateSuccess = "[Action] - Presence Create Success",
  presenceCreateFailure = "[Action] - Presence Create Failure",
  presenceCreateReset = "[Action] - Presence Create Reset",

  presenceDeleteSuccess = "[Action] - Presence Delete Success",
  presenceUpdateSuccess = "[Action] - Presence Update Success",

  presenceListLoadingRequest = "[Action] - Loading Presence  List Request",
  presenceListLoadingSuccess = "[Action] - Loading Presence  List Success",
  presenceListLoadingFailure = "[Action] - Loading Presence  List Failure",

  //endregion Presence

  // region  Product
  productListRequest = "[Action] - Product List Request",
  productListSuccess = "[Action] - Product List Success",
  productListFailure = "[Action] - Product List Failure",

  productCreateRequest = "[Action] - Product Create Request",
  productCreateSuccess = "[Action] - Product Create Success",
  productCreateFailure = "[Action] - Product Create Failure",
  productCreateReset = "[Action] - Product Create Reset",

  productUpdateRequest = "[Action] - Product Update Request",
  productUpdateSuccess = "[Action] - Product Update Success",
  productUpdateFailure = "[Action] - Product Update Failure",
  productUpdateReset = "[Action] - Product Update Reset",

  productDeleteRequest = "[Action] - Product Delete Request",
  productDeleteSuccess = "[Action] - Product Delete Success",
  productDeleteFailure = "[Action] - Product Delete Failure",
  productDeleteReset = "[Action] - Product Delete Reset",

  productListLoadingRequest = "[Action] - Loading Product List Request",
  productListLoadingSuccess = "[Action] - Loading Product List Success",
  productListLoadingFailure = "[Action] - Loading Product List Failure",

  //endregion Product

  // region  Warehouse
  warehouseListRequest = "[Action] - Warehouse List Request",
  warehouseListSuccess = "[Action] - Warehouse List Success",
  warehouseListFailure = "[Action] - Warehouse List Failure",

  warehouseCreateRequest = "[Action] - Warehouse Create Request",
  warehouseCreateSuccess = "[Action] - Warehouse Create Success",
  warehouseCreateFailure = "[Action] - Warehouse Create Failure",
  warehouseCreateReset = "[Action] - Warehouse Create Reset",

  warehouseUpdateRequest = "[Action] - Warehouse Update Request",
  warehouseUpdateSuccess = "[Action] - Warehouse Update Success",
  warehouseUpdateFailure = "[Action] - Warehouse Update Failure",
  warehouseUpdateReset = "[Action] - Warehouse Update Reset",

  warehouseDeleteRequest = "[Action] - Warehouse Delete Request",
  warehouseDeleteSuccess = "[Action] - Warehouse Delete Success",
  warehouseDeleteFailure = "[Action] - Warehouse Delete Failure",
  warehouseDeleteReset = "[Action] - Warehouse Delete Reset",

  warehouseListLoadingRequest = "[Action] - Loading Warehouse List Request",
  warehouseListLoadingSuccess = "[Action] - Loading Warehouse List Success",
  warehouseListLoadingFailure = "[Action] - Loading Warehouse List Failure",

  //endregion Warehouse

  //region Fleet

  fleetListRequest = "[Action] - Fleet List Request",
  fleetListSuccess = "[Action] - Fleet List Success",
  fleetListFailure = "[Action] - Fleet List Failure",

  fleetCreateRequest = "[Action] - Fleet Create Request",
  fleetCreateSuccess = "[Action] - Fleet Create Success",
  fleetCreateFailure = "[Action] - Fleet Create Failure",
  fleetCreateReset = "[Action] - Fleet Create Reset",

  fleetUpdateRequest = "[Action] - Fleet Update Request",
  fleetUpdateSuccess = "[Action] - Fleet Update Success",
  fleetUpdateFailure = "[Action] - Fleet Update Failure",
  fleetUpdateReset = "[Action] - Fleet Update Reset",

  fleetDeleteRequest = "[Action] - Fleet Delete Request",
  fleetDeleteSuccess = "[Action] - Fleet Delete Success",
  fleetDeleteFailure = "[Action] - Fleet Delete Failure",
  fleetDeleteReset = "[Action] - Fleet Delete Reset",

  fleetListLoadingRequest = "[Action] - Loading Fleet List Request",
  fleetListLoadingSuccess = "[Action] - Loading Fleet List Success",
  fleetListLoadingFailure = "[Action] - Loading Fleet List Failure",

  //endregion Fleet

  //region Expense

  expenseListRequest = "[Action] - Expense List Request",
  expenseListSuccess = "[Action] - Expense List Success",
  expenseListFailure = "[Action] - Expense List Failure",

  expenseCreateRequest = "[Action] - Expense Create Request",
  expenseCreateSuccess = "[Action] - Expense Create Success",
  expenseCreateFailure = "[Action] - Expense Create Failure",
  expenseCreateReset = "[Action] - Expense Create Reset",

  expenseUpdateRequest = "[Action] - Expense Update Request",
  expenseUpdateSuccess = "[Action] - Expense Update Success",
  expenseUpdateFailure = "[Action] - Expense Update Failure",
  expenseUpdateReset = "[Action] - Expense Update Reset",

  expenseDeleteRequest = "[Action] - Expense Delete Request",
  expenseDeleteSuccess = "[Action] - Expense Delete Success",
  expenseDeleteFailure = "[Action] - Expense Delete Failure",
  expenseDeleteReset = "[Action] - Expense Delete Reset",

  expenseListLoadingRequest = "[Action] - Loading Expense List Request",
  expenseListLoadingSuccess = "[Action] - Loading Expense List Success",
  expenseListLoadingFailure = "[Action] - Loading Expense List Failure",

  //endregion Expense

  //region Travel

  travelListRequest = "[Action] - Travel List Request",
  travelListSuccess = "[Action] - Travel List Success",
  travelListFailure = "[Action] - Travel List Failure",

  travelCreateRequest = "[Action] - Travel Create Request",
  travelCreateSuccess = "[Action] - Travel Create Success",
  travelCreateFailure = "[Action] - Travel Create Failure",
  travelCreateReset = "[Action] - Travel Create Reset",

  travelUpdateRequest = "[Action] - Travel Update Request",
  travelUpdateSuccess = "[Action] - Travel Update Success",
  travelUpdateFailure = "[Action] - Travel Update Failure",
  travelUpdateReset = "[Action] - Travel Update Reset",

  travelDeleteRequest = "[Action] - Travel Delete Request",
  travelDeleteSuccess = "[Action] - Travel Delete Success",
  travelDeleteFailure = "[Action] - Travel Delete Failure",
  travelDeleteReset = "[Action] - Travel Delete Reset",

  travelListLoadingRequest = "[Action] - Loading Travel List Request",
  travelListLoadingSuccess = "[Action] - Loading Travel List Success",
  travelListLoadingFailure = "[Action] - Loading Travel List Failure",

  //endregion Travel

  //region Reservation

  reservationListRequest = "[Action] - Reservation List Request",
  reservationListSuccess = "[Action] - Reservation List Success",
  reservationListFailure = "[Action] - Reservation List Failure",

  reservationCreateRequest = "[Action] - Reservation Create Request",
  reservationCreateSuccess = "[Action] - Reservation Create Success",
  reservationCreateFailure = "[Action] - Reservation Create Failure",
  reservationCreateReset = "[Action] - Reservation Create Reset",

  reservationUpdateRequest = "[Action] - Reservation Update Request",
  reservationUpdateSuccess = "[Action] - Reservation Update Success",
  reservationUpdateFailure = "[Action] - Reservation Update Failure",
  reservationUpdateReset = "[Action] - Reservation Update Reset",

  reservationDeleteRequest = "[Action] - Reservation Delete Request",
  reservationDeleteSuccess = "[Action] - Reservation Delete Success",
  reservationDeleteFailure = "[Action] - Reservation Delete Failure",
  reservationDeleteReset = "[Action] - Reservation Delete Reset",

  reservationListLoadingRequest = "[Action] - Loading Reservation List Request",
  reservationListLoadingSuccess = "[Action] - Loading Reservation List Success",
  reservationListLoadingFailure = "[Action] - Loading Reservation List Failure",

  //endregion Reservation

  // region  Communication
  communicationListRequest = "[Action] - Communication List Request",
  communicationListSuccess = "[Action] - Communication List Success",
  communicationListFailure = "[Action] - Communication List Failure",

  communicationCreateRequest = "[Action] - Communication Create Request",
  communicationCreateSuccess = "[Action] - Communication Create Success",
  communicationCreateFailure = "[Action] - Communication Create Failure",
  communicationCreateReset = "[Action] - Communication Create Reset",

  communicationDeleteRequest = "[Action] - Communication Delete Request",
  communicationDeleteSuccess = "[Action] - Communication Delete Success",
  communicationDeleteFailure = "[Action] - Communication Delete Failure",
  communicationDeleteReset = "[Action] - Communication Delete Reset",

  communicationListLoadingRequest = "[Action] - Loading Communication List Request",
  communicationListLoadingSuccess = "[Action] - Loading Communication List Success",
  communicationListLoadingFailure = "[Action] - Loading Communication List Failure",

  communicationReadRequest = "[Action] - Communication Read Request",
  //endregion Communication

  // region JustificativeStatuses

  justificativeStatusListRequest = "[Action] - Justificative Status List Request",
  justificativeStatusListSuccess = "[Action] - Justificative Status List Success",
  justificativeStatusListFailure = "[Action] - Justificative Status List Failure",
  justificativeStatusListLoadingRequest = "[Action] - Loading Justificative Status List Request",
  justificativeStatusListLoadingSuccess = "[Action] - Loading Justificative Status List Success",
  justificativeStatusListLoadingFailure = "[Action] - Loading Justificative Status List Failure",

  // endregion JustificativeStatuses

  // region Custom Styles Option

  CustomStyleOptionListRequest = "[Action] - Custom Style Option List Request",
  CustomStyleOptionListSuccess = "[Action] - Custom Style Option List Success",
  CustomStyleOptionListFailure = "[Action] - Custom Style Option List Failure",

  CustomStyleOptionUpdateRequest = "[Action] - Custom Style Option Update Request",
  CustomStyleOptionUpdateSuccess = "[Action] - Custom Style Option Update Success",
  CustomStyleOptionUpdateFailure = "[Action] - Custom Style Option Update Failure",
  CustomStyleOptionUpdateReset = "[Action] - Custom Style Option Update Reset",

  //endregion Custom Styles Option

  //region Alert
  alertGenerate = "[Event] - Alert Generate",
  alertRemove = "[Event] - Alert Remove",
  //endregion

  //region Static Alert
  staticAlertGenerate = "[Event] - Static Alert Generate",
  staticAlertRemove = "[Event] - Static Alert Remove",
  //endregion

  //region Cache
  cacheSaveSuccess = "[Event] - Save to Local Storage Success",
  cacheSaveFailure = "[Event] - Save to Local Storage Failure",
  cacheRemoveSuccess = "[Event] - Remove from Local Storage Success",
  cacheRemoveFailure = "[Event] - Remove from Local Storage Success",
  //endregion Cache

  menuCollapseRequest = "[Action] - Menu Collapse Request",
  menuUncollapseRequest = "[Action] - Menu Uncollapse Request",
}

export interface TAction<T = TActionType> {
  type: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}
