/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaTravelCreate,
  sagaTravelDelete,
  sagaTravelList,
  sagaTravelUpdate,
} from "../sagas/sagasTravels";

export function* watcherTravels(): unknown {
  yield takeLatest(TActionType.travelCreateRequest, sagaTravelCreate);
  yield takeLatest(TActionType.travelListRequest, sagaTravelList);
  yield takeLatest(TActionType.travelDeleteRequest, sagaTravelDelete);
  yield takeLatest(TActionType.travelUpdateRequest, sagaTravelUpdate);
}
