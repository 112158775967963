/** @format */

export const stringToHex = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export const darkenFromHex = (hex: string, percent: number = 35): string => {
  const color = hex.slice(1);
  const num = parseInt(color, 16);
  const r = (num >> 16) + percent;
  const g = ((num >> 8) & 0x00ff) + percent;
  const b = (num & 0x0000ff) + percent;
  const newColor = ((r << 16) + (g << 8) + b).toString(16);
  return `#${newColor}`;
};

export const darkenColorFromHsl = (hsl: string): string => {
  const hslArray = hsl.split(",");
  const h = parseInt(hslArray[0].split("(")[1], 10);
  const s = parseInt(hslArray[1], 10);
  const l = parseInt(hslArray[2].split("%")[0], 10);
  const newL = l - 20;
  return `hsl(${h}, ${s}%, ${newL}%)`;
};
