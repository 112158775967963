/** @format */

import { Col, Drawer, Form, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../../../constants/api";
import { TProduct } from "../../../../redux-modules/declarations/maps/products";
import {
  ProductStatus,
  WarehouseOperation,
} from "../../../../redux-modules/declarations/maps/warehouses";
import { selectorProductList } from "../../../../redux-modules/selectors/selectorsProducts";
import apiClient from "../../../../utils/apiClient";
import { showNotification } from "../../../../utils/showNotification";
import { UIButton } from "../../../ui/Button";
import { UIInput } from "../../../ui/Input";
import { UISelect } from "../../../ui/Select";
const { Option } = UISelect;

interface Props {
  warehouse_id: string;
  visible: boolean;
  onClose: () => void;
  updateData: () => void;
}

export const AddProductAvailableDrawer = ({
  warehouse_id,
  visible,
  onClose,
  updateData,
}: Props) => {
  const [form] = Form.useForm();
  const productList = useSelector(selectorProductList);
  const handleClose = () => {
    form.resetFields();
    onClose();
  };
  const onSave = () => {
    const values = form.getFieldsValue();
    const caricoObj = {
      product_id: values.product_id,
      warehouse_id,
      product_status: values.product_status,
      quantity: values.quantity,
      operation_type: WarehouseOperation.CARICO,
    };
    apiClient
      .post(API_ENDPOINTS.WAREHOUSES_WORKFLOW, caricoObj)
      .then(res => {
        showNotification("success", "Operazione completata!", res.data.message);
        updateData();
        handleClose();
      })
      .catch(e => {
        showNotification("error", "Errore", "Errore durante il caricamento della disponibilità");
      });
  };
  return (
    <Drawer
      title="Aggiungi disponibilità"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <Row justify="space-between">
          <Col>
            <Form.Item name="submit">
              <UIButton
                form="form"
                key="submit"
                htmlType="submit"
                type="primary"
                loading={!productList}
              >
                Salva
              </UIButton>
            </Form.Item>
          </Col>
          <Col>
            <UIButton onClick={handleClose}>Annulla</UIButton>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <>
            <Form id="form" form={form} onFinish={onSave}>
              <Row>
                <Col span={24}>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24}>
                      <Form.Item
                        name="product_id"
                        label="Prodotto"
                        rules={[{ required: true, message: "Seleziona un prodotto" }]}
                      >
                        <UISelect
                          loading={!productList}
                          showSearch
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {!!productList &&
                            productList.map((product: TProduct) => (
                              <Option value={product.id}>{product.name}</Option>
                            ))}
                        </UISelect>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24}>
                      <Form.Item
                        name="product_status"
                        label="Stato"
                        rules={[{ required: true, message: "Indica lo stato del prodotto" }]}
                      >
                        <UISelect>
                          {Object.values(ProductStatus).map((status: ProductStatus) => (
                            <Option value={status}>{status}</Option>
                          ))}
                        </UISelect>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24}>
                      <Form.Item
                        name="quantity"
                        label="Quantità"
                        rules={[{ required: true, message: "Indica la quantità del prodotto" }]}
                      >
                        <UIInput type="number" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </>
        </Col>
      </Row>
    </Drawer>
  );
};
