/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TProductCreateRequest,
  TProductUpdateRequest,
} from "../redux-modules/declarations/maps/products";
import apiClient from "../utils/apiClient";

export const apiProductsList = (): Promise<AxiosResponse<unknown>> =>
  apiClient.get(API_ENDPOINTS.PRODUCTS);

export const apiProductCreate = (request: TProductCreateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.post(API_ENDPOINTS.PRODUCTS, request);

export const apiProductUpdate = (request: TProductUpdateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.PRODUCTS + "/" + request.id, request);

export const apiProductDelete = (productId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.PRODUCTS + "/" + productId);
