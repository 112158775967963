/** @format */

import {
  TActionModelDetailListFailure,
  TActionModelDetailListRequest,
  TActionModelDetailListSuccess,
  TActionModelDetailUpdateSuccess,
} from "../declarations/actions/modelDetail";
import { TActionType } from "../declarations/actionTypes";
import { TModelDetails } from "../declarations/maps/modelDetails";

type Actions =
  | TActionModelDetailListRequest
  | TActionModelDetailListSuccess
  | TActionModelDetailListFailure
  | TActionModelDetailUpdateSuccess;

export const reducerModelDetails = (
  prevStore: TModelDetails = { modelDetailList: [] },
  action: Actions,
): TModelDetails => {
  switch (action.type) {
    case TActionType.modelDetailListRequest:
      return { ...prevStore, modelDetailList: [] };
    case TActionType.modelDetailListSuccess:
      return { ...prevStore, modelDetailList: action.payload };
    case TActionType.modelDetailListFailure:
      return { ...prevStore, modelDetailList: [] };
    case TActionType.modelDetailUpdateSuccess:
      return { ...prevStore };
    default:
      return prevStore;
  }
};
