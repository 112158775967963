/** @format */

import { TUser, TUserComplete, TUserForm } from "../../redux-modules/declarations/maps/auth";

export const utilityFormatApiUser = (user: TUser): TUser => ({
  id: user.id,
  name: user.name,
  surname: user.surname,
  email: user.email,
  mobile: user.mobile,
  fiscal_code: user.fiscal_code,
  avatar: user.avatar,
  role: user.role,
  groups: user.groups,
  office: user.office,
  department: user.department,
  daily_hours: user.daily_hours,
  contract_weekly_hours: user.contract_weekly_hours,
  contract_weekly_days: user.contract_weekly_days,
  is_single_badge: user.is_single_badge,
  is_presence_in_app: user.is_presence_in_app,
  is_nfc_enabled: user.is_nfc_enabled,
  is_disabled: user.is_disabled,
  is_forfettizzato: user.is_forfettizzato,
});

export const utilityFormatApiUserList = (list: TUser[]): TUser[] =>
  list.map(user => utilityFormatApiUser(user));

export const utilityFormatFindList = (user: TUserComplete): TUserForm => {
  let baseObj = {
    id: user.id,
    can_be_edited: user.can_be_edited,
    name: user.name,
    surname: user.surname,
    fiscal_code: user.fiscal_code,
    documents: user.documents,
    email: user.email,
    mobile: user.mobile,
    avatar: user.avatar,
    role: user.role.id,
    groups: user.groups.map(e => e.id),
    office: user.office.id,
    department: user.department.id,
    daily_hours: user.daily_hours,
    contract_weekly_hours: user.contract_weekly_hours,
    contract_weekly_days: user.contract_weekly_days,
    is_single_badge: user.is_single_badge,
    is_presence_in_app: user.is_presence_in_app,
    is_pausapranzo_enabled: user.is_pausapranzo_enabled,
    pausapranzo_hours: user.pausapranzo_hours,
    warehouses_products: user.warehouses_products,
    communications: user.communications,
    is_nfc_enabled: user.is_nfc_enabled,
    is_disabled: user.is_disabled,
    is_forfettizzato: user.is_forfettizzato,
  };

  user.details.forEach(det => {
    const key: string = "detail?" + det.model_detail_id;
    const value = det.value;
    // @ts-ignore
    baseObj[key] = value;
  });
  return baseObj;
};
