/** @format */

import { DatePicker } from "antd";
import { DatePickerProps } from "antd/lib/date-picker";
import styled from "styled-components";

//@ts-ignore
interface Props extends DatePickerProps {}

export const UIDatePicker = styled(DatePicker)<Props>`
  border-radius: 7px;
  width: 100%;
`;
