/** @format */

import {
  ExceptionOutlined,
  FundOutlined,
  PrinterOutlined,
  RetweetOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Row, Col, DatePickerProps, Segmented, Slider } from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../constants/api";
import { actionCustomStyleOptionUpdateRequest } from "../../../redux-modules/actions/actionsUI";
import { useShift } from "../../../redux-modules/hooks/useShifts";
import { selectorLoggedUser } from "../../../redux-modules/selectors/selectorsAuth";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { selectorCustomStyleOptions } from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../theme/maps/Colors";
import apiClient from "../../../utils/apiClient";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { ShiftsCalendar } from "../../layout/ShiftsCalendar";
import { CheckShiftModal } from "../../layout/SideDialogs/ShiftCheck";
import { ShiftLastWeekCheckModal } from "../../layout/SideDialogs/ShiftLastWeekCheck";
import { RepeatShiftModal } from "../../layout/SideDialogs/ShiftRepeat";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIDatePicker } from "../../ui/DatePicker";
import { UISelect } from "../../ui/Select";
import { UIText } from "../../ui/Text";
import { UITitle } from "../../ui/Title";
import UITransition from "../../ui/Transition";
import { CustomStyleProvider } from "../../utils/CustomStyleProvider";

const { Option } = UISelect;

export const ViewShiftsList = (): JSX.Element => {
  const loggedUser = useSelector(selectorLoggedUser);
  const [repeatModalVisible, setRepeatModalVisible] = useState(false);
  const [checkModalVisible, setCheckModalVisible] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [selectedTimeFormat, setSelectedTimeFormat] = useState<"Ore" | "Minuti">("Ore");
  const [selectedGroup, setSelectedGroup] = useState(loggedUser?.groups.map(e => e.id) || []);
  const permissionList = useSelector(selectorPermissionList);
  const canCreatePlanning = hasPermission(permissionList, "ShiftPlanning", "Create");
  const [selectedDepartment, setSelectedDepartment] = useState(
    loggedUser ? [loggedUser?.department.id] : [],
  );
  const dispatch = useDispatch();
  const [showCustomStyleOptions, setShowCustomStyleOptions] = useState(false);
  const customStyleOptions = useSelector(selectorCustomStyleOptions);
  const [zoomLevel, setZoomLevel] = useState(customStyleOptions["ShiftsListZoomLevel"]);

  useEffect(() => {
    if (customStyleOptions["ShiftsListZoomLevel"] !== zoomLevel) {
      dispatch(
        actionCustomStyleOptionUpdateRequest({
          ...customStyleOptions,
          ShiftsListZoomLevel: zoomLevel,
        }),
      );
    }
  }, [zoomLevel]);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const {
    groupList,
    departmentList,
    shiftTypeList,
    shiftList,
    isLoading,
    filteredDepartmentList,
    filteredGroupList,
    userList,
    isCounterLoading,
    userShiftDetails,
    groupShiftDetails,
    departmentShiftDetails,
    fetchCountersData,
    replyShifts,
  } = useShift({
    format: selectedTimeFormat,
    date_from: selectedDate.clone().startOf("week").format("YYYY-MM-DD"),
    date_to: selectedDate.clone().endOf("week").format("YYYY-MM-DD"),
    selectedGroupId: selectedGroup,
    selectedDepartmentId: selectedDepartment,
  });

  const customFormat: DatePickerProps["format"] = value =>
    `${value.clone().startOf("week").format("DD/MM/YYYY")} - ${value
      .clone()
      .endOf("week")
      .format("DD/MM/YYYY")}`;

  const printShifts = (): void => {
    apiClient
      .post(
        API_ENDPOINTS.SHIFTS + "/print",
        {
          group_ids: selectedGroup.length === 0 ? groupList.map(e => e.id) : selectedGroup,
          department_ids:
            selectedDepartment.length === 0 ? departmentList.map(e => e.id) : selectedDepartment,
          date_from: moment(selectedDate).startOf("week").format("YYYY-MM-DD"),
          date_to: moment(selectedDate).endOf("week").format("YYYY-MM-DD"),
        },
        {
          responseType: "blob",
        },
      )
      .then(res => {
        //@ts-ignore
        const url = URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("target", "_blank");
        link.setAttribute(
          "download",
          "Turni " +
            moment(selectedDate).startOf("week").format("YYYY-MM-DD") +
            " - " +
            moment(selectedDate).endOf("week").format("YYYY-MM-DD") +
            ".pdf",
        );
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <>
      <UITransition>
        <CustomStyleProvider section="Shifts">
          <Row justify="space-between" align="middle" gutter={[16, 16]}>
            <Col style={{ display: "inline-flex", alignItems: "baseline" }}>
              <UITitle level={2} color={BaseColor}>
                Turni
              </UITitle>
              <span
                style={{
                  fontSize: "18px",
                  color: BaseColor,
                  cursor: "pointer",
                  marginLeft: "10px",
                  position: "relative",
                  top: "-3px",
                }}
                onClick={() => setShowCustomStyleOptions(!showCustomStyleOptions)}
              >
                <SettingOutlined />
              </span>
            </Col>
          </Row>
          {showCustomStyleOptions && (
            <UITransition>
              <Row gutter={[8, 8]} style={{ marginBottom: "10px" }}>
                <Col span={24}>
                  <UICard>
                    <Row gutter={[8, 8]}>
                      <Col>
                        <UITitle level={5} color={BaseColor}>
                          Personalizza pagina
                        </UITitle>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={8}>
                        <UIText>Livello di zoom</UIText>
                        <Slider
                          min={0.7}
                          max={1}
                          onChange={(value: number) => setZoomLevel(value)}
                          value={typeof zoomLevel === "number" ? zoomLevel : 0}
                          step={0.1}
                          tooltipVisible={false}
                          marks={{
                            0.7: "70%",
                            0.8: "80%",
                            0.9: "90%",
                            1: "100%",
                          }}
                        />
                      </Col>
                    </Row>
                  </UICard>
                </Col>
              </Row>
            </UITransition>
          )}
          <CustomCard>
            {canCreatePlanning && (
              <Row gutter={[16, 16]} justify="end" style={{ marginBottom: "15px" }}>
                <Col>
                  <UIButton type="primary" onClick={(): void => setReportModalVisible(true)}>
                    <FundOutlined />
                    Report
                  </UIButton>
                </Col>
                <Col>
                  <UIButton type="primary" onClick={(): void => setCheckModalVisible(true)}>
                    <ExceptionOutlined />
                    Verifica
                  </UIButton>
                </Col>
                <Col>
                  <UIButton type="primary" onClick={(): void => setRepeatModalVisible(true)}>
                    <RetweetOutlined /> Replica Turni
                  </UIButton>
                </Col>
                <Col>
                  <UIButton type="primary" onClick={printShifts}>
                    <PrinterOutlined />
                    Stampa
                  </UIButton>
                </Col>
              </Row>
            )}

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <CustomDiv>
                  <Row style={{ marginBottom: "15px" }}>
                    <Col>
                      <Segmented
                        options={["Ore", "Minuti"]}
                        //@ts-ignore
                        onChange={(value): void => setSelectedTimeFormat(value)}
                      />
                    </Col>
                  </Row>
                  <Row justify="space-evenly" gutter={[14, 14]}>
                    <Col span={8}>
                      <Row>
                        <Col span={24}>
                          <UIText>Gruppi</UIText>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <UISelect
                            showSearch
                            value={selectedGroup}
                            //@ts-ignore
                            onChange={(value): void => setSelectedGroup(value)}
                            mode="multiple"
                            placeholder="Tutti"
                            filterOption={(input, option): boolean =>
                              ((option!.children as unknown) as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ width: "100%" }}
                          >
                            {groupList.map(
                              (e): JSX.Element => (
                                <Option value={e.id} key={e.id}>
                                  {e.name}
                                </Option>
                              ),
                            )}
                          </UISelect>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row>
                        <Col span={24}>
                          <UIText>Reparti</UIText>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <UISelect
                            showSearch
                            mode="multiple"
                            placeholder="Tutti"
                            value={selectedDepartment}
                            //@ts-ignore
                            onChange={(value): void => setSelectedDepartment(value)}
                            filterOption={(input, option): boolean =>
                              ((option!.children as unknown) as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ width: "100%" }}
                          >
                            {departmentList.map(
                              (e): JSX.Element => (
                                <Option value={e.id} key={e.id}>
                                  {e.name}
                                </Option>
                              ),
                            )}
                          </UISelect>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row>
                        <Col span={24}>
                          <UIText>Settimana</UIText>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <CustomWeekPicker
                            value={selectedDate}
                            //@ts-ignore
                            onChange={(v: Moment | null): void => setSelectedDate(v)}
                            picker="week"
                            allowClear={false}
                            format={customFormat}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CustomDiv>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <ShiftsCalendar
                  loading={isLoading}
                  format={selectedTimeFormat}
                  activeDate={selectedDate}
                  groupList={filteredGroupList}
                  departmentList={filteredDepartmentList}
                  shiftTypeList={shiftTypeList}
                  shiftList={shiftList}
                  userList={userList}
                  isCounterLoading={isCounterLoading}
                  userShiftDetails={userShiftDetails}
                  groupShiftDetails={groupShiftDetails}
                  departmentShiftDetails={departmentShiftDetails}
                  fetchCountersData={fetchCountersData}
                />
              </Col>
            </Row>
          </CustomCard>
        </CustomStyleProvider>
      </UITransition>
      <RepeatShiftModal
        isModalVisible={repeatModalVisible}
        onOk={replyShifts}
        closeModal={(): void => setRepeatModalVisible(false)}
        userList={userList}
      />
      <CheckShiftModal
        selectedGroups={selectedGroup}
        isModalVisible={checkModalVisible}
        closeModal={(): void => setCheckModalVisible(false)}
        selectedDepartments={selectedDepartment}
        selectedDate={selectedDate}
      />
      <ShiftLastWeekCheckModal
        selectedGroups={selectedGroup}
        isModalVisible={reportModalVisible}
        closeModal={(): void => setReportModalVisible(false)}
        selectedDepartments={selectedDepartment}
      />
    </>
  );
};

const CustomWeekPicker = styled(UIDatePicker)`
  .ant-picker-cell-week {
    display: none !important;
  }

  [aria-label="empty cell"] {
    display: none;
  }
`;

const CustomDiv = styled.div`
  padding: 15px;
  margin-bottom: 10px;
  background-color: #fafafa;
  border-radius: 7px;
`;
const CustomCard = styled(UICard)`
  .ant-card-body {
    padding: 10px;
  }
`;
