/** @format */

export const API_PARAMS = {
  BASE_URL: process.env.REACT_APP_API_URL,
  LOCATION_BASE_URL: process.env.REACT_APP_NOMINATIM_ENDPOINT,
};
console.log("@API_BASE_URL@ " + process.env.REACT_APP_API_URL);
export enum LOCATION_ENDPOINTS {
  SEARCH = "/search",
  REVERSE = "/reverse",
}
export enum API_ENDPOINTS {
  CSRF_TOKEN = "/sanctum/csrf-cookie",
  SETUP_CHECK = "/setup_check",
  SETUP = "/setup",
  LOGIN = "/login",
  LOGOUT = "/logout",
  PERMISSIONS = "/permissions",
  USERS_LIST = "/users",
  USER_AVATAR = "users/:id_user/avatar",
  USERS_EXPORT = "/users/export",
  ROLES = "/roles",
  GROUPS = "/groups",
  LOCATIONS = "/offices",
  DOCUMENTS = "/documents",
  PERSONAL_DOCUMENTS = "/documents/users/:id_user",
  PERSONAL_DOCUMENTS_READ = "/documents/read/:id_document/:id_user",
  PERSONAL_DOCUMENTS_DELETE = "/documents/users/:id_document/:id_user",
  DOCUMENT_TYPES = "/document-types",
  PASSWORD_RECOVERY = "/password-recovery",
  PASSWORD_RESET = "/password-reset",
  JUSTIFICATIVE_TYPES = "/justificative_types",
  JUSTIFICATIVE_STATUSES = "/justificative_statuses",
  JUSTIFICATIVE_COUNTER = "/justificativies_counter_list",
  MODEL_DETAILS = "/model_details",
  REQUESTS = "/requests",
  WORKLOW_STATUSES = "/workflow/statuses/update",
  TRIGGERS = "/triggers",
  NOTIFICATIONS = "/app_notifications",
  NOTIFICATION_READ = "/app_notifications/read",
  DEVICES = "/devices",
  PRESENCE_TYPES = "/presence_types",
  PRESENCES = "/presences",
  REPORT_PRESENCES_START = "/report_presences_start",
  REPORT_PRESENCES_STATUS = "/report_presences_status/:id",
  ANALITICHE_PRESENZE = "/analitiche_presenze/:date",
  ANALITICHE_TURNI = "/analitiche_turni/:date",
  ANALITICHE_RICHIESTE = "/analitiche_richieste/:date",
  DEPARTMENTS = "/departments",
  SHIFTS = "/shift_plannings",
  SHIFTS_SWITCH = "/shift_plannings/switch",
  SHIFTS_SWITCH_MANAGE = "/shift_plannings/switch/manage",
  SHIFTS_NEXT = "/shift_plannings/next",
  SHIFT_TYPES = "/shift_types",
  SHIFT_DETAILS = "/shift_plannings/details",
  SHIFT_ABSENCES = "/shift_plannings/absences",
  SHIFT_LAST_WEEK = "/report_previous_weeks",
  ABSENCES = "/absence",
  COMMUNICATIONS = "/communications",
  COMMUNICATIONS_PERSONAL = "/communications/personal",
  PRODUCTS = "/products",
  WAREHOUSES = "/warehouses",
  WAREHOUSES_WORKFLOW = "/warehouse_workflow",
  WAREHOUSES_AVAILABILITY = "/warehouse_availability_check",
  PRESENCES_NOW = "/presences_now/:id_group",
  IMPORT_XML = "/loadxml",
  SAVE_FROM_XML = "/savefromxml",
  FLEETS = "/fleets",
  RESERVATIONS = "/reservation",
  TRAVELS = "/travels",
  TRAVEL = "/travel",
  TRAVEL_LIST = "/travel_list",
  EXPENSES = "/expenses",
  EXPENSES_LIST = "/expense_list",
  TIMEBANK_DINIEGO = "/timebank_diniego",
  BLOCK_MONTHS = "/blocks",
  UNBLOCK_MONTHS = "/unblocks",
  HOLIDAY_CALENDAR = "/holidays",
  CUSTOM_STYLE_OPTIONS = "/custom_style_options",
  USER_DISABLE = "/disable_user",
  USER_ENABLE = "/enable_user",
  USER_EXPORT_FIELDS = "/users_export_fields",
  CHANGE_PASSWORD = "/change_password",
  AUTH_DOCUMENT = "/auth_document/:document_id",
  AUTH_EXPENSE_DOWNLOAD = "/auth_expense_download/:expense_id",
}
