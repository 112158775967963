/** @format */

import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";

interface Props extends InputProps {}

export const UIInputPassword = styled(Input.Password)<Props>`
  border-radius: 7px;
`;
