/** @format */

import React, { FC, useEffect, useState } from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import { fadeOut } from "./fadeOut";
import { fadeIn } from "./fadeIn";

interface Props {
  show?: boolean;
  children?: React.ReactNode | React.ReactNodeArray;
}

const FadeContainer = styled.div<Props>`
  ${({ show }): string | FlattenSimpleInterpolation => (show ? fadeIn : fadeOut)}
`;

export const Fade: FC<Props> = ({ show, children }): JSX.Element => {
  const [shouldRender, setShouldRender] = useState(show);

  useEffect(() => {
    if (show) setShouldRender(true);
  }, [show]);

  const onAnimationEnd = (): void => {
    if (!show) setShouldRender(false);
  };

  return (
    <>
      {shouldRender && (
        <FadeContainer show={show} onAnimationEnd={onAnimationEnd}>
          {children}
        </FadeContainer>
      )}
    </>
  );
};
