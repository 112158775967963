/** @format */

import {
  TActionNotificationListRequest,
  TActionNotificationListSuccess,
  TActionNotificationListFailure,
  TActionNotificationListLoadingRequest,
  TActionNotificationListLoadingSuccess,
  TActionNotificationListLoadingFailure,
  TActionNotificationReadRequest,
  TActionNotificationReadSuccess,
  TActionNotificationReadFailure,
  TActionNotificationDeleteRequest,
  TActionNotificationDeleteSuccess,
  TActionNotificationDeleteFailure,
  TActionNotificationDeleteReset,
} from "../declarations/actions/notification";
import { TAction, TActionType } from "../declarations/actionTypes";
import { TNotification, TNotificationReadRequest } from "../declarations/maps/notifications";

//List
export const actionNotificationListRequest = (): TActionNotificationListRequest => ({
  type: TActionType.notificationListRequest,
});

export const actionNotificationListSuccess = (
  payload: TNotification[],
): TActionNotificationListSuccess => ({
  type: TActionType.notificationListSuccess,
  payload,
});

export const actionNotificationListFailure = (): TActionNotificationListFailure => ({
  type: TActionType.notificationListFailure,
});

//---

//Loading

export const actionNotificationListLoadingRequest = (): TActionNotificationListLoadingRequest => ({
  type: TActionType.notificationListLoadingRequest,
});

export const actionNotificationListLoadingSuccess = (): TActionNotificationListLoadingSuccess => ({
  type: TActionType.notificationListLoadingSuccess,
});

export const actionNotificationListLoadingFailure = (): TActionNotificationListLoadingFailure => ({
  type: TActionType.notificationListLoadingFailure,
});

// ---
// Read
export const actionNotificationReadRequest = (
  payload: TNotificationReadRequest,
): TActionNotificationReadRequest => ({
  type: TActionType.notificationReadRequest,
  payload,
});

export const actionNotificationReadSuccess = (): TActionNotificationReadSuccess => ({
  type: TActionType.notificationReadSuccess,
});

export const actionNotificationReadFailure = (): TActionNotificationReadFailure => ({
  type: TActionType.notificationReadFailure,
});

// ---
// Delete
export const actionNotificationDeleteRequest = (
  payload: string[],
): TActionNotificationDeleteRequest => ({
  type: TActionType.notificationDeleteRequest,
  payload,
});

export const actionNotificationDeleteSuccess = (): TActionNotificationDeleteSuccess => ({
  type: TActionType.notificationDeleteSuccess,
});

export const actionNotificationDeleteFailure = (): TActionNotificationDeleteFailure => ({
  type: TActionType.notificationDeleteFailure,
});

export const actionNotificationDeleteReset = (): TActionNotificationDeleteReset => ({
  type: TActionType.notificationDeleteReset,
});

// ---
