/** @format */

import { Avatar, Breadcrumb, Col, Row, Skeleton, Tag, Image, Tooltip, Segmented } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../constants/api";
import { TCommunication } from "../../../redux-modules/declarations/maps/communications";
import { BaseColor } from "../../../theme/maps/Colors";
import apiClient from "../../../utils/apiClient";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { UILink } from "../../ui/Link";
import { UITitle } from "../../ui/Title";
import UITransition from "../../ui/Transition";

export interface ReadingUser {
  avatar: string;
  id: string;
  name: string;
  read?: string;
}

export const ViewCommunicationView = (): JSX.Element => {
  //@ts-ignore
  const { id_communication } = useParams();
  const [communicationInfo, setCommunicationInfo] = useState<TCommunication>();
  const [chiVedere, setChiVedere] = useState<string | number>("Tutti");

  const filterByChiVedere = (array: ReadingUser[]): ReadingUser[] => {
    switch (chiVedere) {
      case "Non letti":
        return array.filter(e => !e.read);
      case "Letti":
        return array.filter(e => !!e.read);
      default:
      case "Tutti":
        return array;
    }
  };

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.COMMUNICATIONS + "/" + id_communication).then(response => {
      setCommunicationInfo(response.data);
    });
  }, []);

  return !!!communicationInfo ? (
    <UICard style={{ marginBottom: "20px" }}>
      <Skeleton active />
    </UICard>
  ) : (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col>
              <UITitle level={2} color={BaseColor} data-cy="page-title">
                Visualizza Comunicazione
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <UILink route={Routes.comunications} label={"Comunicazioni"} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>{communicationInfo ? communicationInfo.title : ""}</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <Row justify="space-between" gutter={[16, 16]} style={{ marginTop: "15px" }}>
            <Col xs={24}>
              <UICard>
                <CommunicationContainer>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} md={24}>
                      <UITitle level={4}>{communicationInfo.title}</UITitle>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} md={24}>
                      <div dangerouslySetInnerHTML={{ __html: communicationInfo.content }} />
                    </Col>
                  </Row>
                </CommunicationContainer>
                <Row>
                  <Col>
                    <UITitle level={5}>Destinatari</UITitle>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "15px" }}>
                  <Col>
                    <Segmented
                      options={["Tutti", "Letti", "Non letti"]}
                      value={chiVedere}
                      onChange={setChiVedere}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ display: "contents" }}>
                    {
                      //@ts-ignore
                      filterByChiVedere(communicationInfo.receivers).map((receiver, index) => (
                        <UITransition>
                          <Tooltip
                            title={
                              !!receiver.read ? "Letta il " + receiver.read : "Non ancora letta"
                            }
                          >
                            <Tag
                              style={{
                                padding: "3px 5px",
                                borderRadius: "7px",
                                cursor: "default",
                                marginBottom: "3px",
                              }}
                              color={!!receiver.read ? "green" : "geekblue"}
                            >
                              <Avatar size={20} src={<Image src={receiver.avatar} />} />
                              {"\u00A0"}
                              {receiver.name}
                            </Tag>
                          </Tooltip>
                        </UITransition>
                      ))
                    }
                  </Col>
                </Row>
              </UICard>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const CommunicationContainer = styled.span`
  border: 1px solid #e8e8e8;
  border-radius: 7px;
  display: block;
  padding: 10px;
  margin-bottom: 10px;
`;
