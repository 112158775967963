/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaTriggerList, sagaTriggerUpdate } from "../sagas/sagasTriggers";

export function* watcherTriggers(): unknown {
  yield takeLatest(TActionType.triggerListRequest, sagaTriggerList);
  yield takeLatest(TActionType.triggerUpdateRequest, sagaTriggerUpdate);
  yield takeLatest(TActionType.triggerUpdateSuccess, sagaTriggerList);
}
