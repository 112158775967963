/** @format */

import styled from "styled-components";
import TreeSelect from "antd/lib/tree-select";

export const UITreeSelect = styled(TreeSelect)`
  border-radius: 7px;

  .ant-select-selector {
    border-radius: 7px !important;
  }
  .ant-select-selection-item {
    border-radius: 7px;
  }
`;
