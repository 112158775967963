import { TActionDocumentTypeListFailure, TActionDocumentTypeListRequest, TActionDocumentTypeListSuccess } from "../declarations/actions/documentType";
import { TActionType } from "../declarations/actionTypes";
import { TDocumentTypes } from "../declarations/maps/documentTypes";

type Actions = TActionDocumentTypeListRequest | TActionDocumentTypeListSuccess | TActionDocumentTypeListFailure;

export const reducerDocumentTypes = (prevStore: TDocumentTypes = {}, action: Actions): TDocumentTypes => {
  switch (action.type) {
    case TActionType.documentTypeListRequest:
      return { ...prevStore, documentTypeList: [] };
    case TActionType.documentTypeListSuccess:
      return { ...prevStore, documentTypeList: action.payload };
    case TActionType.documentTypeListFailure:
      return { ...prevStore, documentTypeList: [] };
    default:
      return prevStore;
  }
};
