/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TPresenceCreateRequest,
  TPresenceListQuery,
} from "../redux-modules/declarations/maps/presences";
import apiClient from "../utils/apiClient";

export const apiPresenceList = (payload?: TPresenceListQuery): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(
    API_ENDPOINTS.PRESENCES +
      (payload?.datetime_to
        ? `?datetime_from=${payload.datetime_from}&datetime_to=${payload.datetime_to}&custom=${payload.catafratto}`
        : ``),
  );
};

export const apiPresenceCheck = (payload: string): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.PRESENCES + "/check/" + payload);
};

export const apiPresenceCreate = (
  presence: TPresenceCreateRequest,
): Promise<AxiosResponse<unknown>> => apiClient.post(API_ENDPOINTS.PRESENCES, presence);

export const apiReportPResencesStatus = (id: number): Promise<AxiosResponse<unknown>> =>
  apiClient.get(API_ENDPOINTS.REPORT_PRESENCES_STATUS.replace(":id", `${id}`));
