/** @format */

import { TWarehouse } from "../declarations/maps/warehouses";
import { TStore } from "../declarations/store";

export const selectorWarehouseList = (store: TStore): TWarehouse[] =>
  store.warehouses.warehouseList || [];

export const selectorWarehouse = (store: TStore, id: string): TWarehouse | undefined =>
  store.warehouses.warehouseList?.find(warehouse => warehouse.id === id) || undefined;
