/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TDeviceCreateRequest,
  TDeviceUpdateRequest,
} from "../redux-modules/declarations/maps/devices";
import apiClient from "../utils/apiClient";

export const apiDeviceList = (): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.DEVICES);
};

export const apiDeviceCreate = (device: TDeviceCreateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.post(API_ENDPOINTS.DEVICES, device);

export const apiDeviceUpdate = (device: TDeviceUpdateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.DEVICES + "/" + device.id, device);

export const apiDeviceDelete = (deviceId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.DEVICES + "/" + deviceId);
