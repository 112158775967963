/** @format */

import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, DatePickerProps, Progress, Skeleton } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { apiReportPResencesStatus } from "../../../../api/apiPresences";
import { API_ENDPOINTS } from "../../../../constants/api";
import { selectorDepartmentList } from "../../../../redux-modules/selectors/selectorsDepartments";
import { selectorGroupsList } from "../../../../redux-modules/selectors/selectorsGroups";
import { BaseColor } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { showNotification } from "../../../../utils/showNotification";
import { sleep } from "../../../../utils/sleep";
import { UIButton } from "../../../ui/Button";
import { UICard } from "../../../ui/Card";
import { UIDatePicker } from "../../../ui/DatePicker";
import UIModal from "../../../ui/Modal";

interface Props {
  isModalVisible: boolean;
  closeModal: () => void;
  selectedGroups: string[];
  selectedDepartments: string[];
}

interface UserData {
  dipendente: string;
  ore_contratto: string;
  ore_turno: string;
  ore_timbratura: string;
  ore_riconosciute: string;
  banca_ora: string;
}
export const ShiftLastWeekCheckModal = ({
  isModalVisible,
  closeModal,
  selectedGroups,
  selectedDepartments,
}: Props): JSX.Element => {
  const [usersData, setUsersData] = useState<UserData[]>([]);
  const groups = useSelector(selectorGroupsList);
  const departments = useSelector(selectorDepartmentList);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [percentage, setPercentage] = useState<string | undefined>();
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());

  const fetchData = () => {
    setIsShowLoading(true);
    let stillPolling = true;

    apiClient
      .get(API_ENDPOINTS.REPORT_PRESENCES_START)
      .then(async r => {
        const id = r.data.id;

        apiClient
          .post(API_ENDPOINTS.SHIFT_LAST_WEEK, {
            group_ids: selectedGroups.length === 0 ? groups.data.map(e => e.id) : selectedGroups,
            department_ids:
              selectedDepartments.length === 0 ? departments.map(e => e.id) : selectedDepartments,
            date_from: moment(selectedDate).startOf("week").format("YYYY-MM-DD"),
            date_to: moment(selectedDate).endOf("week").format("YYYY-MM-DD"),
            temp_id: id,
          })
          .then(res => {
            stillPolling = false;
            setPercentage(undefined);
            setUsersData(res.data);
            setIsShowLoading(false);
          })
          .catch(err => {
            stillPolling = false;
            setPercentage(undefined);
            setIsShowLoading(false);
            showNotification("error", "Oops..", "Errore durante la generazione del report");
          });

        //VA IN POLLINGO
        let counter = 0;
        while (stillPolling) {
          apiReportPResencesStatus(id)
            .then(res => {
              //@ts-ignore
              if (res.data.status === "completed") {
                stillPolling = false;
                setPercentage(undefined);
              } else {
                //@ts-ignore
                setPercentage(res.data.msg);
              }
            })
            .catch(e => {
              stillPolling = false;
              setPercentage(undefined);
              setIsShowLoading(false);
              showNotification("error", "Oops..", "Errore durante la generazione del report");
            });

          if (counter > 90) {
            stillPolling = false;
            setPercentage(undefined);
          }

          counter++;
          await sleep(1000);
        }
      })
      .catch(e => {
        setPercentage(undefined);
        setIsShowLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const customFormat: DatePickerProps["format"] = value =>
    `${value.clone().startOf("week").format("DD/MM/YYYY")} - ${value
      .clone()
      .endOf("week")
      .format("DD/MM/YYYY")}`;

  return (
    <CustomModal
      title={"Report settimanale"}
      width={700}
      visible={isModalVisible}
      cancelButtonProps={{ style: { borderRadius: "7px" } }}
      onCancel={closeModal}
    >
      <Row justify="center">
        <Col style={{ marginRight: "10px" }}>
          <CustomWeekPicker
            value={selectedDate}
            //@ts-ignore
            onChange={(v: Moment | null): void => setSelectedDate(v)}
            picker="week"
            format={customFormat}
          />
        </Col>
        <Col>
          <UIButton loading={isShowLoading} type="primary" onClick={fetchData}>
            <SearchOutlined /> Genera
          </UIButton>
        </Col>
      </Row>
      {!!percentage && (
        <Row>
          <Col span={24}>
            <ProgressCustom
              percent={parseInt(percentage)}
              strokeColor={BaseColor}
              style={{ width: "100%", marginTop: "15px" }}
              status="active"
            />
          </Col>
        </Row>
      )}
      {!!isShowLoading && (
        <UICard style={{ marginBottom: "20px", border: "none" }}>
          <Skeleton active />
        </UICard>
      )}
      {usersData.length > 0 && !isShowLoading && (
        <Row style={{ marginBottom: "10px" }} className="repeat-container">
          <Col span={24}>
            <Row className="repeat-row">
              <Col span={8} className="repeat-col">
                Dipendente
              </Col>
              <Col span={3} className="repeat-col">
                Contratto
              </Col>
              <Col span={3} className="repeat-col">
                Assegnate
              </Col>
              <Col span={3} className="repeat-col">
                Timbrate
              </Col>
              <Col span={4} className="repeat-col">
                Riconosciute
              </Col>
              <Col span={3} className="repeat-col">
                Banca ore
              </Col>
            </Row>
            {usersData.map(user => (
              <Row className="repeat-row" key={user.dipendente}>
                <Col className="repeat-col" span={8}>
                  {user.dipendente}
                </Col>
                <Col className="repeat-col" span={3}>
                  {user.ore_contratto}
                </Col>
                <Col className="repeat-col" span={3}>
                  {user.ore_turno}
                </Col>
                <Col className="repeat-col" span={3}>
                  {user.ore_timbratura}
                </Col>
                <Col className="repeat-col" span={4}>
                  {user.ore_riconosciute}
                </Col>
                <Col className="repeat-col" span={3}>
                  {user.banca_ora}
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      )}
    </CustomModal>
  );
};

const CustomWeekPicker = styled(UIDatePicker)`
  .ant-picker-cell-week {
    display: none !important;
  }

  [aria-label="empty cell"] {
    display: none;
  }
`;

const ProgressCustom = styled(Progress)`
  font-size: 16px !important;
  .ant-progress-inner {
    height: 11px;
  }

  .ant-progress-bg {
    height: 11px !important;
  }
`;

const CustomModal = styled(UIModal)`
  .repeat-col {
    padding: 3px 5px;
  }
  .repeat-row {
    border-bottom: 1px solid #f0f0f0;
  }
  .repeat-row:first-of-type .repeat-col {
    font-weight: bold;
  }

  .repeat-container {
    max-height: 500px;
    overflow-y: auto;
  }
`;
