/** @format */

import {
  TActionRequestListRequest,
  TActionRequestListSuccess,
  TActionRequestListFailure,
  TActionRequestCreateRequest,
  TActionRequestCreateSuccess,
  TActionRequestCreateFailure,
  TActionRequestUpdateRequest,
  TActionRequestUpdateSuccess,
  TActionRequestUpdateFailure,
  TActionRequestUpdateReset,
  TActionRequestDeleteRequest,
  TActionRequestDeleteSuccess,
  TActionRequestDeleteFailure,
  TActionRequestDeleteReset,
  TActionRequestListLoadingFailure,
  TActionRequestListLoadingRequest,
  TActionRequestListLoadingSuccess,
} from "../declarations/actions/request";
import { TActionType, TAction } from "../declarations/actionTypes";
import {
  TRequest,
  TRequestCreateRequest,
  TRequestListQuery,
  TRequestUpdateRequest,
} from "../declarations/maps/requests";

export const actionRequestListRequest = (
  payload?: TRequestListQuery,
): TActionRequestListRequest => ({
  type: TActionType.requestListRequest,
  payload,
});

export const actionRequestListSuccess = (payload: TRequest[]): TActionRequestListSuccess => ({
  type: TActionType.requestListSuccess,
  payload,
});

export const actionRequestListFailure = (): TActionRequestListFailure => ({
  type: TActionType.requestListFailure,
});

export const actionRequestCreateRequest = (
  payload: TRequestCreateRequest,
): TActionRequestCreateRequest => ({ type: TActionType.requestCreateRequest, payload });
export const actionRequestCreateSuccess = (payload: string): TActionRequestCreateSuccess => ({
  type: TActionType.requestCreateSuccess,
  payload,
});
export const actionRequestCreateFailure = (): TActionRequestCreateFailure => ({
  type: TActionType.requestCreateFailure,
});
export const actionRequestCreateReset = (): TAction => ({
  type: TActionType.requestCreateReset,
});

export const actionRequestUpdateRequest = (
  payload: TRequestUpdateRequest,
): TActionRequestUpdateRequest => ({
  type: TActionType.requestUpdateRequest,
  payload,
});

export const actionRequestUpdateSuccess = (): TActionRequestUpdateSuccess => ({
  type: TActionType.requestUpdateSuccess,
});

export const actionRequestUpdateFailure = (): TActionRequestUpdateFailure => ({
  type: TActionType.requestUpdateFailure,
});

export const actionRequestUpdateReset = (): TActionRequestUpdateReset => ({
  type: TActionType.requestUpdateReset,
});

export const actionRequestDeleteRequest = (payload: string): TActionRequestDeleteRequest => ({
  type: TActionType.requestDeleteRequest,
  payload,
});

export const actionRequestDeleteSuccess = (payload: string): TActionRequestDeleteSuccess => ({
  type: TActionType.requestDeleteSuccess,
  payload,
});

export const actionRequestDeleteFailure = (): TActionRequestDeleteFailure => ({
  type: TActionType.requestDeleteFailure,
});
export const actionRequestDeleteReset = (): TActionRequestDeleteReset => ({
  type: TActionType.requestDeleteReset,
});

//Loading

export const actionRequestListLoadingRequest = (): TActionRequestListLoadingRequest => ({
  type: TActionType.requestListLoadingRequest,
});

export const actionRequestListLoadingSuccess = (): TActionRequestListLoadingSuccess => ({
  type: TActionType.requestListLoadingSuccess,
});

export const actionRequestListLoadingFailure = (): TActionRequestListLoadingFailure => ({
  type: TActionType.requestListLoadingFailure,
});

// ---
