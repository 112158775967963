/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaReservationCreate,
  sagaReservationDelete,
  sagaReservationList,
  sagaReservationUpdate,
} from "../sagas/sagasReservations";

export function* watcherReservations(): unknown {
  yield takeLatest(TActionType.reservationCreateRequest, sagaReservationCreate);
  yield takeLatest(TActionType.reservationListRequest, sagaReservationList);
  yield takeLatest(TActionType.reservationDeleteRequest, sagaReservationDelete);
  yield takeLatest(TActionType.reservationUpdateRequest, sagaReservationUpdate);
}
