/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaDepartmentCreate,
  sagaDepartmentDelete,
  sagaDepartmentList,
  sagaDepartmentUpdate,
} from "../sagas/sagasDepartments";

export function* watcherDepartments(): unknown {
  yield takeLatest(TActionType.departmentCreateRequest, sagaDepartmentCreate);
  yield takeLatest(TActionType.departmentListRequest, sagaDepartmentList);
  yield takeLatest(TActionType.departmentDeleteRequest, sagaDepartmentDelete);
  yield takeLatest(TActionType.departmentUpdateRequest, sagaDepartmentUpdate);
}
