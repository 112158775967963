/** @format */

import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fleetListColumns } from "../../../constants/tables/fleetListColumns";
import { actionFleetListRequest } from "../../../redux-modules/actions/actionsFleets";
import { selectorFleetList } from "../../../redux-modules/selectors/selectorsFleets";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import {
  selectorFleetListLoading,
  selectorFleetDeleted,
  selectorFleetUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../theme/maps/Colors";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { Routes } from "../../../utils/Routes";
import { FleeCreateDrawer } from "../../layout/SideDialogs/FleetCreate";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UILink } from "../../ui/Link";
import { UITable } from "../../ui/Table";
import { UITitle } from "../../ui/Title";
import UITransition from "../../ui/Transition";

export const ViewFleetList = () => {
  const dispatch = useDispatch();
  const fleetList = useSelector(selectorFleetList);
  const fleetListLoading = useSelector(selectorFleetListLoading);
  const isFleetDeleted = useSelector(selectorFleetDeleted);
  const isFleetUpdated = useSelector(selectorFleetUpdated);
  const [createFleetVisible, setCreateFleetVisible] = useState(false);

  const onClose = (): void => setCreateFleetVisible(false);
  const permissionList = useSelector(selectorPermissionList);
  const canAddFleet = hasPermission(permissionList, "Fleet", "Create");

  useEffect(() => {
    dispatch(actionFleetListRequest());
  }, []);

  useEffect(() => {
    if (isFleetDeleted || isFleetUpdated) dispatch(actionFleetListRequest());
  }, [isFleetDeleted, isFleetUpdated]);

  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Mezzi
          </UITitle>
        </Col>
        {canAddFleet && (
          <Col>
            <UIButton type="primary" onClick={() => setCreateFleetVisible(true)}>
              + Aggiungi Mezzo
            </UIButton>
          </Col>
        )}
      </Row>
      <UICard>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UITable
              size="middle"
              data-cy="device-list-table"
              loading={fleetListLoading}
              columns={fleetListColumns}
              dataSource={fleetList.map(e => ({ ...e, key: e.id }))}
            />
          </Col>
        </Row>
      </UICard>
      {canAddFleet && <FleeCreateDrawer visible={createFleetVisible} onClose={onClose} />}
    </UITransition>
  );
};
