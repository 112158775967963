/** @format */

import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import styled from "styled-components";

const { TextArea } = Input;

interface Props extends TextAreaProps {}

export const UITextArea = styled(TextArea)<Props>`
  border-radius: 7px;
`;
