/** @format */

import React, { useEffect, useState } from "react";
import { Breadcrumb, Checkbox, Col, Form, Row, Skeleton } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UIText } from "../../ui/Text";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorDepartmentDeleted,
  selectorDepartmentUpdated,
  selectorDeviceDeleted,
  selectorDeviceUpdated,
  selectorLocationDeleted,
  selectorLocationUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { API_ENDPOINTS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import {
  actionLocationDeleteRequest,
  actionLocationUpdateRequest,
} from "../../../redux-modules/actions/actionsLocations";
import { UITable } from "../../ui/Table";
import { TLocation } from "../../../redux-modules/declarations/maps/locations";
import { DeleteCard } from "../../layout/DeleteCard";
import { TActionLocationDeleteRequest } from "../../../redux-modules/declarations/actions/location";
import { UILink } from "../../ui/Link";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { TActionDeviceDeleteRequest } from "../../../redux-modules/declarations/actions/devices";
import {
  actionDeviceDeleteRequest,
  actionDeviceUpdateRequest,
} from "../../../redux-modules/actions/actionsDevices";
import { TDevice } from "../../../redux-modules/declarations/maps/devices";
import { UISelect } from "../../ui/Select";
import {
  actionDepartmentDeleteRequest,
  actionDepartmentUpdateRequest,
} from "../../../redux-modules/actions/actionsDepartments";
import { TActionDepartmentDeleteRequest } from "../../../redux-modules/declarations/actions/departments";
import { TDepartment } from "../../../redux-modules/declarations/maps/departments";
const { Option } = UISelect;

export const ViewDepartmentEdit = (): JSX.Element => {
  //@ts-ignore
  const { id_department } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const [departmentInfo, setDepartmentInfo] = useState<TDepartment>();
  const isDepartmentUpdated = useSelector(selectorDepartmentUpdated);
  const isDepartmentDeleted = useSelector(selectorDepartmentDeleted);
  const permissionList = useSelector(selectorPermissionList);

  const canDeleteDepartment = hasPermission(permissionList, "Department", "Delete");

  const removeDepartment = (): TActionDepartmentDeleteRequest =>
    dispatch(actionDepartmentDeleteRequest(id_department));

  useEffect(() => {
    if (isDepartmentUpdated || isDepartmentDeleted) history.push(Routes.departments);
  }, [isDepartmentUpdated, isDepartmentDeleted]);

  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      id: id_department,
      name: e.name,
    };
    //dispatch
    dispatch(actionDepartmentUpdateRequest(contentObj));
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      const response = await apiClient.get(API_ENDPOINTS.DEPARTMENTS + "/" + id_department);
      //@ts-ignore
      setDepartmentInfo(response.data.data);
    };
    fetchData();
  }, []);

  return !!!departmentInfo ? (
    <UICard style={{ marginBottom: "20px" }}>
      <Skeleton active />
    </UICard>
  ) : (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <Row>
            <Col>
              <UITitle level={2} color={BaseColor} data-cy="page-title">
                Modifica Reparto
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <UILink route={Routes.departments} label={"Reparti"} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>{departmentInfo ? departmentInfo.name : ""}</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
      </Row>
      <Form
        style={{ marginTop: "15px" }}
        name="update-department"
        className="update-department"
        data-cy="update-department-form"
        onFinish={handleSave}
        initialValues={departmentInfo}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="name"
                    label={<UIText strong={true}>Nome</UIText>}
                    rules={[{ required: false, message: "Inserisci un nome valido!" }]}
                    extra="Il nome del reparto"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                justify="space-between"
                align="bottom"
                gutter={[12, 12]}
                style={{ marginTop: "15px" }}
              >
                <Col>
                  <UILink route={Routes.departments} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item noStyle>
                    <UIButton type="primary" data-cy="update-department-button" htmlType="submit">
                      Salva Reparto
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
      {canDeleteDepartment && (
        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col xs={24}>
            <DeleteCard
              data-cy="remove-department-card"
              actionToDispatch={removeDepartment}
              title="Elimina Reparto"
              text="Cliccando sul bottone elimina eliminerai definitivamente il reparto selezionato. Assicurati di voler rimuoverlo."
              removeButtonLabel="Elimina Reparto"
            />
          </Col>
        </Row>
      )}
    </>
  );
};
