/** @format */

import React, { useEffect, useState } from "react";
import { PersonalInfoTab } from "./partials/PersonalInfoTab";
import { ProfessionalInfoTab } from "./partials/ProfessionalInfoTab";
import { Col, Form, Row, Steps } from "antd";
import { BaseColor } from "../../../theme/maps/Colors";
import { useHistory } from "react-router";
import { Routes } from "../../../utils/Routes";
import apiClient from "../../../utils/apiClient";
import { API_ENDPOINTS } from "../../../constants/api";
import { useDispatch, useSelector } from "react-redux";
import { actionUserCreateRequest } from "../../../redux-modules/actions/actionsUsers";
import { selectorUserCreated } from "../../../redux-modules/selectors/selectorsUI";
import { UICard } from "../../ui/Card";
import { UITitle } from "../../ui/Title";
import { UIButton } from "../../ui/Button";
import { selectorLoggedUser } from "../../../redux-modules/selectors/selectorsAuth";
import { TModel } from "../../../constants/maps/BackendEnums";
import { TModelDetail } from "../../../redux-modules/declarations/maps/modelDetails";
import { TStore } from "../../../redux-modules/declarations/store";
import { selectorModelDetailsByModel } from "../../../redux-modules/selectors/selectorsModelDetails";
import { useForm } from "antd/lib/form/Form";
const { Step } = Steps;

export const ViewUserCreate = (): JSX.Element => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [form] = useForm();
  const isUserCreated = useSelector(selectorUserCreated);
  const loggedUser = useSelector(selectorLoggedUser);
  const isAdmin = loggedUser?.role === "ROOT";

  const [roleList, setRoleList] = useState<[]>([]);
  const [groupList, setGroupList] = useState<[]>([]);
  const [locationList, setLocationList] = useState<[]>([]);
  const [current, setCurrent] = useState(0);
  const details: TModelDetail[] = useSelector((store: TStore) =>
    selectorModelDetailsByModel(store, TModel.User, true),
  );

  const steps = [
    {
      step: 1,
      title: "Informazioni Personali",
      content: <PersonalInfoTab roles={roleList} />,
    },
    {
      step: 2,
      title: "Informazioni Professionali",
      content: <ProfessionalInfoTab groups={groupList} locations={locationList} />,
    },
  ];

  const next = (): void => {
    const newCurrent = current + 1;
    setCurrent(newCurrent);
  };

  const prev = (): void => {
    const newCurrent = current - 1;
    setCurrent(newCurrent);
  };

  const cancel = (): void => {
    history.push(Routes.users);
  };
  //@ts-ignore
  const handleSave = (e): void => {
    const userObj = {
      fiscal_code: e.fiscalCode,
      name: e.name,
      surname: e.lastName,
      email: e.email,
      role_id: e.role,
      groups: e.groups,
      phone: e.phone,
      phone2: e.phone2,
      mobile: e.mobile,
      mobile2: e.mobile2,
      password: e.password,
      resetPasswordTime: e.resetPasswordTime,
      address: e.address,
      city: e.city,
      cap: e.cap,
      state: e.state,
      office_id: e.location,
      department_id: e.department,
      pinCode: e.pinCode,
      qrCode: e.qrCode,
      workEmail: e.workEmail,
      intNumberPhone: e.intNumberPhone,
      relationship: e.relationship,
      details: [],
      /* avatar: avatar, */
    };
    details.forEach(detail => {
      const detailValue = form.getFieldValue("detail?" + detail.id);

      if (detailValue) {
        //@ts-ignore
        userObj["details"].push({
          //@ts-ignore
          model_detail_id: detail.id,
          //@ts-ignore
          value: detailValue,
        });
      }
    });
    //dispatch
    dispatch(actionUserCreateRequest(userObj));
  };

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.ROLES).then(res => {
      let list = res.data.data;
      //@ts-ignore
      if (!isAdmin) list = list.filter(role => role.id != 1); // se non è root tolgo l'utente root dalla select
      setRoleList(list);
    });
    apiClient.get(API_ENDPOINTS.GROUPS).then(res => {
      setGroupList(res.data.data);
    });
    apiClient.get(API_ENDPOINTS.LOCATIONS).then(res => {
      setLocationList(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (isUserCreated) history.push(Routes.usersEdit.replace(":id_user", `${isUserCreated}`));
  }, [isUserCreated]);

  return (
    <>
      <Row style={{ marginBottom: "15px" }}>
        <Col xs={12}>
          <UITitle level={2} color={BaseColor}>
            Crea Dipendente
          </UITitle>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <UICard>
            <Form
              name="new-user"
              className="new-user"
              onFinish={handleSave}
              data-cy="new-user-form"
              layout="vertical"
              form={form}
            >
              <Steps current={current} style={{ marginBottom: "25px" }}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              {steps.map(
                (item): JSX.Element => (
                  <div
                    className={`steps-content`}
                    style={{ display: item.step !== current + 1 ? "none" : "" }}
                  >
                    {item.content}
                  </div>
                ),
              )}
              <div className="steps-action">
                {current < steps.length - 1 && (
                  //@ts-ignore
                  <UIButton
                    type="primary"
                    data-cy="new-user-next-button"
                    style={{ float: "right" }}
                    onClick={next}
                  >
                    Avanti
                  </UIButton>
                )}
                {current === steps.length - 1 && (
                  <Form.Item style={{ display: "inline-block", float: "right" }}>
                    <UIButton type="primary" data-cy="new-user-button" htmlType="submit">
                      Crea nuovo dipendente
                    </UIButton>
                  </Form.Item>
                )}
                {current > 0 && (
                  //@ts-ignore
                  <UIButton onClick={prev} data-cy="new-user-back-button">
                    Indietro
                  </UIButton>
                )}
                {current === 0 && (
                  //@ts-ignore
                  <UIButton onClick={cancel}>Annulla</UIButton>
                )}
              </div>
            </Form>
          </UICard>
        </Col>
      </Row>
    </>
  );
};
