/** @format */

import { Row, Col } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionShiftCreateRequest } from "../../../../redux-modules/actions/actionsShifts";
import { selectorLocationsList } from "../../../../redux-modules/selectors/selectorsLocations";
import { selectorShiftTypeList } from "../../../../redux-modules/selectors/selectorsShiftTypes";
import { UIInput } from "../../../ui/Input";
import UIModal from "../../../ui/Modal";
import { UISelect } from "../../../ui/Select";
import { UIText } from "../../../ui/Text";
const { Option } = UISelect;

interface Props {
  isModalVisible: boolean;
  closeModal: () => void;
  reloadData: () => void;
  user_id: string;
  user_name: string;
  date: string;
}
export const AddShiftModal = ({
  isModalVisible,
  closeModal,
  reloadData,
  user_id,
  user_name,
  date,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const shiftTypeList = useSelector(selectorShiftTypeList);
  const officeList = useSelector(selectorLocationsList);
  const [selectedShiftType, setSelectedShiftType] = useState<string>();
  const [selectedOffice, setSelectedOffice] = useState<string>();
  const handleOk = () => {
    if (selectedShiftType) {
      dispatch(
        actionShiftCreateRequest({
          user_id,
          date,
          shift_type_id: selectedShiftType,
          office_id: selectedOffice,
        }),
      );
    }
    closeModal();
  };

  useEffect(() => {
    if (!isModalVisible) {
      setSelectedShiftType(undefined);
      setSelectedOffice(undefined);
    }
  }, [isModalVisible]);
  return (
    <UIModal
      title={"Nuovo turno  -  " + moment(date).format("DD/MM/YYYY")}
      visible={isModalVisible}
      onOk={handleOk}
      okText="Salva"
      okButtonProps={{
        style: { borderRadius: "7px" },
      }}
      cancelButtonProps={{ style: { borderRadius: "7px" } }}
      onCancel={closeModal}
    >
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          <UIText>
            Nuovo turno per l'utente <span style={{ fontWeight: 600 }}>{user_name}</span>
          </UIText>
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col span={24}>
          <Row>
            <Col>
              <UIText>Seleziona una tipologia di turno:</UIText>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <UISelect
                value={selectedShiftType}
                style={{ width: "50%" }} //@ts-ignore
                onChange={value => setSelectedShiftType(value)}
                showSearch
                filterOption={(input, option) =>
                  ((option!.children as unknown) as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {shiftTypeList.map(e => (
                  <Option key={e.id} value={e.id}>
                    { (e.name) + " " + (e.is_notturno ? "(Notturno)" : "")}
                  </Option>
                ))}
              </UISelect>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Row>
            <Col>
              <UIText>Seleziona una sede:</UIText>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <UISelect
                showSearch
                value={selectedOffice}
                placeholder={"N/D"}
                style={{ width: "50%" }}
                //@ts-ignore
                onChange={value => setSelectedOffice(value)}
                filterOption={(input, option) =>
                  ((option!.children as unknown) as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {officeList.data.map(e => (
                  <Option key={e.id} value={e.id}>
                    {e.name}
                  </Option>
                ))}
              </UISelect>
            </Col>
          </Row>
        </Col>
      </Row>
    </UIModal>
  );
};
