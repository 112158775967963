/** @format */

import { Col, Form, Row, Select } from "antd";

import React, { FC } from "react";
import { UIInput } from "../../../ui/Input";
import { UISelect } from "../../../ui/Select";
const { Option } = Select;

interface Props {
  roles?: [];
}

export const PersonalInfoTab: FC<Props> = ({ roles }: Props): JSX.Element => {
  return (
    <>
      <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="name"
            label="Nome"
            rules={[{ required: true, message: "Inserisci un nome!" }]}
            extra="Il nome dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            name="lastName"
            label="Cognome"
            rules={[{ required: true, message: "Inserisci un cognome!" }]}
            extra="Il cognome dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col md={16}>
          <Form.Item
            name="fiscalCode"
            label="Codice Fiscale"
            rules={[{ required: true, message: "Inserisci il codice fiscale dell'utente!" }]}
            extra="Il codice fiscale dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name="role"
            label="Ruolo"
            rules={[{ required: true, message: "Seleziona il ruolo dell'utente!" }]}
            extra="Il ruolo dell'utente nella piattaforma"
          >
            <UISelect>
              {roles?.map(role => (
                //@ts-ignore
                <Option key={role.id} value={role.id}>
                  {
                    //@ts-ignore
                    role.name
                  }
                </Option>
              ))}
            </UISelect>
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={[12, 0]}>
        <Col md={16}>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: false, message: "Inserisci una password valida!" }]}
            extra="La password dell'utente (min. 8 caratteri)"
          >
            <UIInputPassword />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name="resetPasswordTime"
            label="Reset Password (gg)"
            rules={[{ required: false, message: "Inserisci un numero!" }]}
            extra="Dopo quanti giorni richiedere il cambio password"
          >
            <UIInput type="number" />
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Inserisci l'email dell'utente!" }]}
            extra="Indirizzo email dell'utente"
          >
            <UIInput type="email" />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="phone"
            label="Telefono 1"
            rules={[{ required: false, message: "Inserisci almeno un numero di telefono!" }]}
            extra="Numero di telefono dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            name="phone2"
            label="Telefono 2"
            rules={[{ required: false, message: "Inserisci almeno un numero di telefono!" }]}
            extra="Numero di telefono dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>
      </Row> */}
      {/* <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="mobile"
            label="Mobile 1"
            rules={[{ required: false, message: "Inserisci almeno un numero di telefono!" }]}
            extra="Numero di cellulare dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            name="mobile2"
            label="Mobile 2"
            rules={[{ required: false, message: "Inserisci almeno un numero di telefono!" }]}
            extra="Numero di cellulare dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>
      </Row> */}
      {/* <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="address"
            label="Indirizzo"
            rules={[{ required: false, message: "Inserisci l'indirizzo dell'utente!" }]}
            extra="Indirizzo dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>

        <Col md={6}>
          <Form.Item
            name="cap"
            label="CAP"
            rules={[{ required: false, message: "Inserisci l'indirizzo dell'utente!" }]}
          >
            <UIInput />
          </Form.Item>
        </Col>
      </Row> */}
      {/* <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="city"
            label="Località"
            rules={[{ required: false, message: "Inserisci l'indirizzo dell'utente!" }]}
          >
            <UIInput />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            name="state"
            label="Provincia"
            rules={[{ required: false, message: "Inserisci l'indirizzo dell'utente!" }]}
          >
            <UIInput />
          </Form.Item>
        </Col>
      </Row> */}
    </>
  );
};
