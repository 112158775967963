/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TCommunicationCreateRequest,
  TCommunicationUpdateRequest,
} from "../redux-modules/declarations/maps/communications";
import apiClient from "../utils/apiClient";

export const apiCommunicationsList = (): Promise<AxiosResponse<unknown>> =>
  apiClient.get(API_ENDPOINTS.COMMUNICATIONS);

export const apiCommunicationCreate = (
  request: TCommunicationCreateRequest,
): Promise<AxiosResponse<unknown>> => apiClient.post(API_ENDPOINTS.COMMUNICATIONS, request);

export const apiCommunicationUpdate = (
  request: TCommunicationUpdateRequest,
): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.COMMUNICATIONS + "/" + request.id, request);

export const apiCommunicationDelete = (communicationId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.COMMUNICATIONS + "/" + communicationId);
