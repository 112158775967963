import { TActionDocumentTypeListRequest, TActionDocumentTypeListSuccess, TActionDocumentTypeListFailure, TActionDocumentTypeCreateRequest, TActionDocumentTypeCreateSuccess, TActionDocumentTypeCreateFailure, TActionDocumentTypeUpdateRequest, TActionDocumentTypeUpdateSuccess, TActionDocumentTypeUpdateFailure, TActionDocumentTypeUpdateReset, TActionDocumentTypeDeleteRequest, TActionDocumentTypeDeleteSuccess, TActionDocumentTypeDeleteFailure, TActionDocumentTypeDeleteReset } from "../declarations/actions/documentType";
import { TActionType, TAction } from "../declarations/actionTypes";
import { TDocumentType, TDocumentTypeCreateRequest, TDocumentTypeUpdateRequest } from "../declarations/maps/documentTypes";

export const actionDocumentTypeListRequest = (): TActionDocumentTypeListRequest => ({
  type: TActionType.documentTypeListRequest
});

export const actionDocumentTypeListSuccess = (
  payload: TDocumentType[],
): TActionDocumentTypeListSuccess => ({
  type: TActionType.documentTypeListSuccess,
  payload,
});

export const actionDocumentTypeListFailure = (): TActionDocumentTypeListFailure => ({
  type: TActionType.documentTypeListFailure,
});

export const actionDocumentTypeCreateRequest = (payload: TDocumentTypeCreateRequest): TActionDocumentTypeCreateRequest => ({type: TActionType.documentTypeCreateRequest, payload});
export const actionDocumentTypeCreateSuccess = (payload:string): TActionDocumentTypeCreateSuccess => ({type: TActionType.documentTypeCreateSuccess, payload});
export const actionDocumentTypeCreateFailure = (): TActionDocumentTypeCreateFailure => ({type: TActionType.documentTypeCreateFailure});
export const actionDocumentTypeCreateReset = ():TAction => ({type: TActionType.documentTypeCreateReset});

export const actionDocumentTypeUpdateRequest = (
    payload: TDocumentTypeUpdateRequest,
  ): TActionDocumentTypeUpdateRequest => ({
    type: TActionType.documentTypeUpdateRequest,
    payload,
  });
  
  export const actionDocumentTypeUpdateSuccess = (): TActionDocumentTypeUpdateSuccess => ({
    type: TActionType.documentTypeUpdateSuccess,
  });
  
  export const actionDocumentTypeUpdateFailure = (): TActionDocumentTypeUpdateFailure => ({
    type: TActionType.documentTypeUpdateFailure
  });
  
  export const actionDocumentTypeUpdateReset = (): TActionDocumentTypeUpdateReset => ({
    type: TActionType.documentTypeUpdateReset,
  });

  export const actionDocumentTypeDeleteRequest = (payload: string): TActionDocumentTypeDeleteRequest => ({
    type: TActionType.documentTypeDeleteRequest,
    payload,
  });
  
  export const actionDocumentTypeDeleteSuccess = (payload: string): TActionDocumentTypeDeleteSuccess => ({
    type: TActionType.documentTypeDeleteSuccess,
    payload,
  });
  
  export const actionDocumentTypeDeleteFailure = (): TActionDocumentTypeDeleteFailure => ({
    type: TActionType.documentTypeDeleteFailure
  });
  export const actionDocumentTypeDeleteReset = (): TActionDocumentTypeDeleteReset => ({
    type: TActionType.documentTypeDeleteReset,
  });