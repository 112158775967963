
  /** @format */
  
import { TActionLocationListRequest, TActionLocationListSuccess, TActionLocationListFailure, TActionLocationListLoadingRequest, TActionLocationListLoadingSuccess, TActionLocationListLoadingFailure, TActionLocationCreateFailure, TActionLocationCreateRequest, TActionLocationCreateSuccess, TActionLocationUpdateFailure, TActionLocationUpdateRequest, TActionLocationUpdateSuccess, TActionLocationDeleteFailure, TActionLocationDeleteRequest, TActionLocationDeleteSuccess, TActionLocationDeleteReset, TActionLocationUpdateReset } from "../declarations/actions/location";
import { TAction, TActionType } from "../declarations/actionTypes";
import { TAPILocationListRequest } from "../declarations/apis/locations";
import { TLocationCreateRequest, TLocationList, TLocationUpdateRequest } from "../declarations/maps/locations";
  
//List
  export const actionLocationListRequest = (
      payload: TAPILocationListRequest,
  ): TActionLocationListRequest => ({
    type: TActionType.locationListRequest,
    payload,
  });
  
  export const actionLocationListSuccess = (
    payload: TLocationList,
  ): TActionLocationListSuccess => ({
    type: TActionType.locationListSuccess,
    payload,
  });
  
  export const actionLocationListFailure = (): TActionLocationListFailure => ({
    type: TActionType.locationListFailure,
  });

  //---

  //Loading
  
  export const actionLocationListLoadingRequest = (): TActionLocationListLoadingRequest => ({
    type: TActionType.locationListLoadingRequest,
  });
  
  export const actionLocationListLoadingSuccess = (): TActionLocationListLoadingSuccess => ({
    type: TActionType.locationListLoadingSuccess,
  });
  
  export const actionLocationListLoadingFailure = (): TActionLocationListLoadingFailure => ({
    type: TActionType.locationListLoadingFailure,
  });

  // ---
  //Create
  export const actionLocationCreateRequest = (payload: TLocationCreateRequest): TActionLocationCreateRequest => ({type: TActionType.locationCreateRequest, payload});
  export const actionLocationCreateSuccess = (payload:string): TActionLocationCreateSuccess => ({type: TActionType.locationCreateSuccess, payload});
  export const actionLocationCreateFailure = (): TActionLocationCreateFailure => ({type: TActionType.locationCreateFailure});
  export const actionLocationCreateReset = ():TAction => ({type: TActionType.locationCreateReset});
  // ---
    // Update
    export const actionLocationUpdateRequest = (
      payload: TLocationUpdateRequest,
    ): TActionLocationUpdateRequest => ({
      type: TActionType.locationUpdateRequest,
      payload,
    });
    
    export const actionLocationUpdateSuccess = (): TActionLocationUpdateSuccess => ({
      type: TActionType.locationUpdateSuccess,
    });
    
    export const actionLocationUpdateFailure = (): TActionLocationUpdateFailure => ({
      type: TActionType.locationUpdateFailure
    });
    export const actionLocationUpdateReset = (): TActionLocationUpdateReset => ({
      type: TActionType.locationUpdateReset,
    });
    
  // ---
    // Delete
    export const actionLocationDeleteRequest = (payload: string): TActionLocationDeleteRequest => ({
      type: TActionType.locationDeleteRequest,
      payload,
    });
    
    export const actionLocationDeleteSuccess = (payload: string): TActionLocationDeleteSuccess => ({
      type: TActionType.locationDeleteSuccess,
      payload,
    });
    
    export const actionLocationDeleteFailure = (): TActionLocationDeleteFailure => ({
      type: TActionType.locationDeleteFailure
    });
    export const actionLocationDeleteReset = (): TActionLocationDeleteReset => ({
      type: TActionType.locationDeleteReset,
    });
    // ---