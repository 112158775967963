/** @format */

import { Row, Col, Empty, Badge } from "antd";
import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../../constants/api";
import { TCommunication } from "../../../../redux-modules/declarations/maps/communications";
import { BaseColor } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { hexToRgb } from "../../../../utils/styles";
import { UITitle } from "../../../ui/Title";

interface Props {
  communications: TCommunication[];
  user_id: string;
}
const CommunicationTab = ({ communications, user_id }: Props): JSX.Element => {
  const [selectedCommunication, setSelectedCommunication] = React.useState<string | null>(null);
  const [communicationList, setCommunicationList] = useState(communications);

  const readCommunication = (id: string): void => {
    setCommunicationList(
      communicationList.map(communication => {
        if (communication.id === id) {
          communication.read = true;
        }
        return communication;
      }),
    );
  };
  useEffect(() => {
    if (
      !!selectedCommunication &&
      !communications.find(el => el.id === selectedCommunication)?.read
    ) {
      apiClient
        .post(API_ENDPOINTS.COMMUNICATIONS + "/read", {
          communication_id: selectedCommunication,
          user_id,
        })
        .then(r => {
          readCommunication(selectedCommunication);
        });
    }
  }, [selectedCommunication]);
  return (
    <>
      <Row
        style={{
          marginBottom: "15px",
          border: "1px solid #f0f0f0",
          borderRadius: "7px",
          maxHeight: "50vh",
        }}
      >
        <Col span={5} style={{ maxHeight: "50vh", overflow: "auto" }}>
          {communicationList.map((communication, index) => {
            return (
              <CommunicationItem
                key={communication.id}
                onClick={(): void => setSelectedCommunication(communication.id)}
                className={[
                  selectedCommunication === communication.id ? "selected" : "",
                  !communication.read ? "to-read" : "read",
                ].join(" ")}
              >
                <Badge color="blue" style={{ left: "100%", top: "5px" }}>
                  <Row>
                    <Col span={24}>
                      <h3 style={{ marginBottom: "5px" }}>{communication.title}</h3>
                    </Col>
                  </Row>
                </Badge>
                <Row>
                  <Col span={24}>
                    <h5 style={{ color: "gray", marginBottom: "3px" }}>
                      Ricevuta il {communication.created_at}
                    </h5>
                  </Col>
                </Row>
              </CommunicationItem>
            );
          })}
        </Col>
        <Col
          span={19}
          style={{
            padding: "20px",
            maxHeight: "50vh",
            overflow: "auto",
            backgroundColor: selectedCommunication
              ? `rgb(${hexToRgb("" + BaseColor) + ", 0.07"})`
              : `rgb(${hexToRgb("" + BaseColor) + ", 0.07"})`,
            borderTopRightRadius: "7px",
            borderBottomRightRadius: "7px",
            borderLeft: "1px solid #f0f0f0",
          }}
        >
          {selectedCommunication ? (
            <>
              <Row>
                <Col span={24}>
                  <UITitle level={3}>
                    {communications.find(el => el.id === selectedCommunication)?.title}
                  </UITitle>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `${
                        communications.find(el => el.id === selectedCommunication)?.content
                      }`,
                    }}
                  ></span>
                </Col>
              </Row>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Empty description="Nessuna comunicazione selezionata" />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

const CommunicationItem = styled.div`
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  padding: 10px;

  &.selected {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.15"});
  }
  &.to-read h5,
  &.to-read h3 {
    font-weight: 900;
  }
  &.read:not(.selected) {
    background-color: #f6f5f5c2;
  }
`;

export default memo(CommunicationTab);
