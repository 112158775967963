/** @format */

import {
  TActionShiftCreateSuccess,
  TActionShiftDeleteSuccess,
  TActionShiftListFailure,
  TActionShiftListRequest,
  TActionShiftListSuccess,
  TActionShiftUpdateSuccess,
} from "../declarations/actions/shifts";
import { TActionType } from "../declarations/actionTypes";
import { TShifts } from "../declarations/maps/shifts";

type Actions =
  | TActionShiftListRequest
  | TActionShiftListSuccess
  | TActionShiftListFailure
  | TActionShiftDeleteSuccess
  | TActionShiftCreateSuccess
  | TActionShiftUpdateSuccess;

export const reducerShifts = (
  prevStore: TShifts = {
    shiftList: [],
  },
  action: Actions,
): TShifts => {
  switch (action.type) {
    case TActionType.shiftListRequest:
      return { ...prevStore, shiftList: [] };
    case TActionType.shiftListSuccess:
      return { ...prevStore, shiftList: action.payload };
    case TActionType.shiftListFailure:
      return { ...prevStore, shiftList: [] };
    case TActionType.shiftDeleteSuccess:
      return {
        ...prevStore,
        shiftList: prevStore?.shiftList?.filter(shift => shift.id !== action.payload),
      };
    case TActionType.shiftCreateSuccess:
      const isTurnoRecupero = parseInt(action.payload.shift_type.working_minutes) == 0;
      const turniGiaEsistenti = prevStore.shiftList
        ?.filter(
          shift =>
            shift.user.id == action.payload.user.id &&
            shift.date == action.payload.date &&
            !!parseInt(shift.shift_type.working_minutes) !=
              !!parseInt(action.payload.shift_type.working_minutes),
        )
        .map(e => e.id);
      let prevShiftList = prevStore.shiftList?.filter(
        e => !turniGiaEsistenti?.find(id => id == e.id),
      );
      const turni_da_rimuovere = isTurnoRecupero
        ? prevStore.shiftList?.filter(
            shift =>
              shift.user.id == action.payload.user.id &&
              shift.date == action.payload.date &&
              parseInt(shift.shift_type.working_minutes) > 0,
          )
        : prevStore.shiftList?.filter(
            shift =>
              shift.user.id == action.payload.user.id &&
              shift.date == action.payload.date &&
              parseInt(shift.shift_type.working_minutes) == 0,
          );

      return {
        ...prevStore,
        shiftList: [...(prevShiftList || []), action.payload].filter(
          e => !turni_da_rimuovere?.includes(e.id),
        ),
      };

    case TActionType.shiftUpdateSuccess:
      return {
        ...prevStore,
        shiftList:
          prevStore.shiftList?.map(e =>
            e.id == action.payload.old_id
              ? { ...e, ...action.payload }
              : e.id == action.payload.id
              ? { ...e, ...action.payload }
              : e,
          ) || [],
      };
    default:
      return prevStore;
  }
};
