/** @format */

import { Avatar, Col, Divider, Drawer, Form, Row, Skeleton, Tag, Timeline } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../../../constants/api";
import { actionRequestListRequest } from "../../../../redux-modules/actions/actionsRequest";
import { TRequest } from "../../../../redux-modules/declarations/maps/requests";
import { TStore } from "../../../../redux-modules/declarations/store";
import { usePermission } from "../../../../redux-modules/hooks/usePermission";
import { selectorJustificativeList } from "../../../../redux-modules/selectors/selectorsJustificatives";
import { selectorJustificativeStatusesList } from "../../../../redux-modules/selectors/selectorsJustificativeStatuses";
import { selectorPermissionList } from "../../../../redux-modules/selectors/selectorsPermissions";
import { selectorUserById } from "../../../../redux-modules/selectors/selectorsUsers";
import { BaseColor } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { hasPermission } from "../../../../utils/permissions/hasPermission";
import { showNotification } from "../../../../utils/showNotification";
import { darkenFromHex, stringToHex } from "../../../../utils/strings/stringToColor";
import { UICard } from "../../../ui/Card";
import { UISelect } from "../../../ui/Select";
import { UIText } from "../../../ui/Text";
import { UITitle } from "../../../ui/Title";
const { Option } = UISelect;

interface Props {
  visible: boolean;
  onClose?: (request?: boolean) => void;
  request?: TRequest;
  modalLoading: boolean;
  request_id?: string;
  currentRange: string[];
  editMode?: boolean;
}

export const RequestDetailDrawer: FC<Props> = ({
  visible,
  onClose,
  request,
  modalLoading,
  request_id,
  currentRange,
  editMode = true,
}: Props): JSX.Element => {
  //@ts-ignore
  const dispatch = useDispatch();
  const [currentRequest, setCurrentRequest] = useState<TRequest | undefined>(undefined);
  const justificativeStatuses = useSelector(selectorJustificativeStatusesList);
  const justificativeTypes = useSelector(selectorJustificativeList);
  const requestOwner = request?.user;
  const [showLoading, setShowLoading] = useState(false);
  const permissionList = useSelector(selectorPermissionList);
  const canManageRequests = hasPermission(permissionList, "Request", "Canmanage");
  const canViewStatusHistory = hasPermission(permissionList, "Request", "Canshowhistory");

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.REQUESTS + `/${request_id}`).then(res => {
      setCurrentRequest(res.data.data);
    });
  }, []);

  const handleClose = () => {
    if (onClose)
      // quello che vogliamo
      onClose();
  };

  //@ts-ignore
  const onChangeStatus = value => {
    apiClient
      .post(API_ENDPOINTS.WORKLOW_STATUSES, {
        request_id: request?.id,
        justificative_status_id: value.current_status_id,
        user_id: requestOwner?.id,
      })
      .then(() => {
        dispatch(
          actionRequestListRequest({
            datetime_from: currentRange[0],
            datetime_to: currentRange[currentRange.length - 1],
          }),
        );
        apiClient.get(API_ENDPOINTS.REQUESTS + `/${request_id}`).then(res => {
          setCurrentRequest(res.data.data);
        });
        showNotification(
          "success",
          "Aggiornato!",
          "Stato della richiesta aggiornato correttamente",
        );
        
        if(onClose) onClose(true);
        
      })
      .catch(e => {
        showNotification("error", "Oops..", e.response.data.message);
      });
  };

  if (modalLoading || !currentRequest || !requestOwner) return <></>;

  return (
    <Drawer
      title={`${currentRequest.justificative_type_name} - ${requestOwner.name} ${requestOwner.surname}`}
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
    >
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <Avatar size={64} src={requestOwner.avatar} />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <UITitle level={3} color={BaseColor}>
                {requestOwner.name + " " + requestOwner.surname}
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              {requestOwner.groups.map((group, index) => (
                <Tag
                  key={index}
                  color={darkenFromHex(stringToHex(group.name))}
                  style={{ borderRadius: "5px" }}
                >
                  {group.name}
                </Tag>
              ))}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <UITitle level={5} color={BaseColor}>
                Info
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <UIText>Tipologia:</UIText>{" "}
                  <UIText style={{ fontWeight: "bold" }}>
                    <Tag
                      color={
                        justificativeTypes.data.find(
                          e => e.id === currentRequest.justificative_type_id, //@ts-ignore
                        )?.color
                      }
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      {currentRequest.justificative_type_name}
                    </Tag>
                  </UIText>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <UIText>Dal:</UIText>{" "}
                  <UIText style={{ fontWeight: "bold" }}>{currentRequest.datetime_from}</UIText>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <UIText>Al:</UIText>{" "}
                  <UIText style={{ fontWeight: "bold" }}>{currentRequest.datetime_to}</UIText>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <UIText>Stato richiesta:</UIText>{" "}
                  <UIText style={{ fontWeight: "bold" }}>
                    {currentRequest.current_status_name}
                  </UIText>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          {canManageRequests && editMode && (
            <>
              <Row>
                <Col span={24}>
                  <UITitle level={5} color={BaseColor}>
                    Modifica stato
                  </UITitle>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form initialValues={currentRequest} onValuesChange={onChangeStatus}>
                    <Form.Item name="current_status_id">
                      <UISelect style={{ width: "100%" }}>
                        {justificativeStatuses.map((status, i) => (
                          <Option key={i} value={status.id}>
                            {status.name}
                          </Option>
                        ))}
                      </UISelect>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
            </>
          )}

          {canViewStatusHistory && (
            <>
              <Row>
                <Col span={24}>
                  <UITitle level={5} color={BaseColor}>
                    Storico stati
                  </UITitle>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Timeline style={{ marginTop: "10px" }}>
                    {!!currentRequest.history_statuses &&
                      currentRequest.history_statuses?.map((status, i) => (
                        <Timeline.Item
                          key={i}
                          color={
                            currentRequest.history_statuses &&
                            i === currentRequest.history_statuses?.length - 1
                              ? BaseColor
                              : "#f0f0f0"
                          }
                        >
                          {`${status.justificative_status_name} - ${status.data_cambio_stato} `}
                          <br />
                          {status.operator &&
                            `(${status.operator.name + " " + status.operator.surname})`}
                        </Timeline.Item>
                      ))}
                  </Timeline>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Drawer>
  );
};
