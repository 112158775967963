/** @format */

import { ClockCircleOutlined, PauseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { actionPresenceCreateRequest } from "../../../../../redux-modules/actions/actionsPresences";
import { TPresence } from "../../../../../redux-modules/declarations/maps/presences";
import { TPresenceType } from "../../../../../redux-modules/declarations/maps/presenceTypes";
import { TShift } from "../../../../../redux-modules/declarations/maps/shifts";
import {
  selectorIsGestioneTimebankEnabled,
  selectorLoggedUser,
} from "../../../../../redux-modules/selectors/selectorsAuth";
import { selectorPresenceTypeList } from "../../../../../redux-modules/selectors/selectorsPresenceTypes";
import { BaseColor } from "../../../../../theme/maps/Colors";
import { UIText } from "../../../../ui/Text";

interface InAppBadgeProps {
  runningPresence: TPresence[];
  todaysPalannings: TShift[];
  isLoading: boolean;
}

export const InAppBadge = ({
  runningPresence,
  todaysPalannings,
  isLoading,
}: InAppBadgeProps): JSX.Element => {
  const isGestioneTimebankAbilitata = useSelector(selectorIsGestioneTimebankEnabled);
  const dispatch = useDispatch();
  const [selectedPresenceType, setSelectedPresenceType] = useState<TPresenceType | undefined>();
  const presenceTypes = useSelector(selectorPresenceTypeList);
  const loggedUser = useSelector(selectorLoggedUser);
  const [componenteTimbraturaVisible, setComponenteTimbraturaVisible] = useState(false);

  const handleSelectPresenceType = (id: string) =>
    setSelectedPresenceType(presenceTypes.find(p => p.id === id));

  useEffect(() => {
    if (presenceTypes.length == 1) {
      setSelectedPresenceType(presenceTypes[0]);
      setComponenteTimbraturaVisible(true);
    }
  }, [presenceTypes]);

  useEffect(() => {
    if (!!selectedPresenceType) {
      setComponenteTimbraturaVisible(true);
    }
  }, [selectedPresenceType]);

  const startTimbratura = (shiftPlanningId: string | null = null) => {
    if (!!selectedPresenceType && !!loggedUser) {
      dispatch(
        actionPresenceCreateRequest({
          generate_date: moment().format("YYYY-MM-DD HH:mm:ss"),
          user_id: loggedUser?.id,
          shift_planning_id: shiftPlanningId,
          presence_type_id: selectedPresenceType?.id,
          origin: "web",
          device_code: "1234567",
        }),
      );
    }
  };
  const stopTimbratura = () => {
    if (!!runningPresence.length && !!loggedUser) {
      dispatch(
        actionPresenceCreateRequest({
          generate_date: moment().format("YYYY-MM-DD HH:mm:ss"),
          user_id: loggedUser?.id,
          presence_type_id: runningPresence[0].presence_type_id,
          origin: "web",
          device_code: "1234567",
        }),
      );
    }
  };

  return (
    <>
      {!!runningPresence.length ? (
        <>
          <Row style={{ marginBottom: "20px" }}>
            <Col span={24}>
              <UIText style={{ fontWeight: 600, fontSize: "16px" }}>Timbratura in corso:</UIText>
            </Col>
          </Row>
          <Row>
            <Col span={isMobileOnly ? 24 : 12}>
              <TheBigButton>
                <Row>
                  <Col style={{ fontSize: "15px" }}>{runningPresence[0].presence_type_name}</Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Row>
                      <Col>
                        <UIText color={"white"} style={{ fontSize: "15px" }}>
                          Inizio:
                        </UIText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <UIText color={"white"} style={{ fontSize: "20px", marginRight: "15px" }}>
                          {moment(runningPresence[0].datetime_from, "DD/MM/YYYY HH:mm:ss").format(
                            "HH:mm",
                          )}
                        </UIText>
                        <SyncOutlined spin />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    span={12}
                    style={{ alignSelf: "center", textAlign: "right", cursor: "pointer" }}
                    onClick={stopTimbratura}
                  >
                    <PauseCircleOutlined style={{ fontSize: "45px" }} />
                  </Col>
                </Row>
              </TheBigButton>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <PresenceTypesContainer>
            {presenceTypes
              .filter(e => !!e.is_visible)
              .map(presenceType => (
                <PresenceTypeItem
                  onClick={() => handleSelectPresenceType(presenceType.id)}
                  style={{
                    backgroundColor:
                      presenceType.id == selectedPresenceType?.id
                        ? presenceType.color
                        : !selectedPresenceType
                        ? presenceType.color
                        : presenceType.color + "60",
                    margin: "5px",
                    zIndex: 999,
                  }}
                >
                  {presenceType.name}
                </PresenceTypeItem>
              ))}
          </PresenceTypesContainer>
          {selectedPresenceType && componenteTimbraturaVisible && !isLoading && (
            <TimbraturaButtonContainer>
              {runningPresence.length === 0 && todaysPalannings.length > 0 ? (
                <>
                  <Row>
                    {todaysPalannings.map(shift => (
                      <Col style={{ marginRight: "10px" }}>
                        <TheBigButton
                          onClick={() => startTimbratura(shift.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <ClockCircleOutlined style={{ marginRight: "10px" }} />
                          Apri Turno <br />{" "}
                          {
                            // @ts-ignore
                            shift.orario
                          }
                        </TheBigButton>
                      </Col>
                    ))}
                  </Row>
                </>
              ) : (
                <TheBigButton onClick={() => startTimbratura(null)} style={{ cursor: "pointer" }}>
                  <ClockCircleOutlined style={{ marginRight: "10px" }} />
                  Apri Turno
                </TheBigButton>
              )}
            </TimbraturaButtonContainer>
          )}
        </>
      )}
    </>
  );
};
const PresenceTypeItem = styled.span`
  padding: 8px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 7px;
  color: white;
  cursor: pointer;
`;
const PresenceTypesContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  height: 40px;
  display: ${isMobileOnly ? "inline-grid" : "block"};
`;

const TimbraturaButtonContainer = styled.div`
  margin-top: ${isMobileOnly ? "125px" : "15px"};
  width: 100%;
  display: ${isMobileOnly ? "inline-grid" : "flex"};
`;

const TheBigButton = styled.span`
  padding: 20px;
  border-radius: 7px;
  background-color: ${BaseColor};
  color: white;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  display: inline-block;
  width: 100%;
`;
