/** @format */

import { put, call, select } from "redux-saga/effects";
import { TActionLoginRequest } from "../declarations/actions/auth";
import {
  actionLoginSuccess,
  actionLoginFailure,
  actionLogoutFailure,
  actionLogoutSuccess,
  actionLogoutRequest,
  actionFailureApiKeyCheck,
  actionEndApiKeyCheck,
  actionStartApiKeyCheck,
} from "../actions/actionsAuth";
import { apiLogout } from "../../api/auth/apiLogout";
import { apiLogin } from "../../api/auth/apiLogin";
import { AxiosError, AxiosResponse } from "axios";
import { utilitySaveToLocalStorage } from "../../utils/cache/saveToLocalStorage";
import { utilityGetFromLocalStorage } from "../../utils/cache/getFromLocalStorage";
import { selectorLoggedUser } from "../selectors/selectorsAuth";
import { sleep } from "../../utils/sleep";
import { TAPIResponse, TAPIValidationError } from "../declarations/apis/general";
import { actionPermissionListRequest } from "../actions/actionsPermission";
import { showNotification } from "../../utils/showNotification";
import { error } from "console";

export function* sagaLogin(action: TActionLoginRequest): unknown {
  try {
    const response: AxiosResponse<TAPIResponse<unknown>> = yield call(apiLogin, {
      email: action.payload.email,
      password: action.payload.password,
    });
    //@ts-ignore
    if (!response.response) {
      //@ts-ignore
      throw response.error.response.data.message;
    }
    //@ts-ignore
    const token = response.response.data.token;
    //@ts-ignore
    const userData = response.response.data;
    utilitySaveToLocalStorage("apiKey", token);

    yield put(actionLoginSuccess(userData));
    yield put(actionStartApiKeyCheck());
    yield put(actionPermissionListRequest({}));
  } catch (e) {
    //@ts-ignore
    yield put(actionLoginFailure((e as TAPIValidationError) || {}));
    //@ts-ignore
    if (!!e.message) {
      showNotification(
        "error",
        "Oops..",
        //@ts-ignore
        "Impossibile effettuare il login, attendi qualche secondo e riprova",
      );
    } else {
      showNotification(
        "error",
        "Oops..",
        //@ts-ignore
        e ?? "Impossibile effettuare il login, attendi qualche secondo e riprova",
      );
    }
  }
}

export function* sagaLogout(): unknown {
  try {
    try {
      yield call(apiLogout);
    } catch (e) {
      //@ts-ignore
      console.error(e.stack);
    }
    yield put(actionLogoutSuccess());
  } catch {
    yield put(actionLogoutFailure());
  }
}

export function* sagaCheckApiToken(): unknown {
  while (true) {
    try {
      const userUid = yield select(selectorLoggedUser);
      if (!userUid) return;
      const apiKey = utilityGetFromLocalStorage("apiKey");
      if (!apiKey) {
        yield put(actionLogoutRequest());
        yield put(actionEndApiKeyCheck());
        return;
      }
      //yield put(actionSuccessApiKeyCheck());
    } catch (e) {
      //@ts-ignore
      yield put(actionFailureApiKeyCheck(e.stack));
    }
    yield sleep(6000);
  }
}
