/** @format */

import { TTenance, TUser } from "../declarations/maps/auth";
/** @format */

import { TStore } from "../declarations/store";

export const selectorAuthenticated = (store: TStore): boolean => !!store.auth?.user;

export const selectorLoggedUser = (store: TStore): TUser | undefined => store.auth.user;

export const selectorLoggedTenance = (store: TStore): TTenance | undefined => store.auth.tenance;

export const selectorIsSingleBadge = (store: TStore): boolean =>
  store.auth.user?.is_single_badge || false;

export const selectorIsPresenceInApp = (store: TStore): boolean =>
  store.auth.user?.is_presence_in_app || false;

export const selectorIsGestioneTimebankEnabled = (store: TStore): boolean =>
  store.auth.tenance?.is_gestione_timebank_abilitata || false;
// static TRUE
/* export const selectorAuthenticated = (store: TStore): boolean => false; */

export const selectorIsUser = (store: TStore): boolean => store.auth?.user?.role == "UTENTE";
