/** @format */

import React, { memo, FC } from "react";
import { Routes } from "../../utils/Routes";
import { useSelector } from "react-redux";
import {
  selectorAuthenticated,
  selectorLoggedUser,
} from "../../redux-modules/selectors/selectorsAuth";
import { useHistory, useParams } from "react-router";
import { Skeleton } from "antd";
import { UICard } from "../ui/Card";
import { showNotification } from "../../utils/showNotification";
import { selectorPermissionListLoading } from "../../redux-modules/selectors/selectorsUI";
import {
  usePermissionForRoute,
  utilityPathMatchRoute,
} from "../../utils/permissions/routeToPermissionMap";

interface Props {
  component: FC;
  route: Routes;
}

export const RenderRouteLogged: FC<Props> = memo(
  ({ component: Component, route }: Props): JSX.Element => {
    const history = useHistory();
    const isPermissionLoading = useSelector(selectorPermissionListLoading);
    const hasPermission = usePermissionForRoute(route);
    const isAuthenticated = useSelector(selectorAuthenticated);
    const loggedUser = useSelector(selectorLoggedUser);
    //@ts-ignore
    const { id_user } = useParams();

    if (!isAuthenticated) history.push(Routes.login);

    if (isPermissionLoading)
      return (
        <UICard style={{ marginBottom: "20px" }}>
          <Skeleton active />
        </UICard>
      );

    if (!hasPermission) {
      history.push(Routes.home);
      showNotification(
        "warning",
        "Oops..",
        "Sembra che tu non abbia i permessi per visualizzare la pagina",
      );
    }

    return <Component />;
  },
);
RenderRouteLogged.displayName = "RenderRouteLogged";
