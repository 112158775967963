/** @format */

import {
  TActionRequestListFailure,
  TActionRequestListRequest,
  TActionRequestListSuccess,
} from "../declarations/actions/request";
import { TActionType } from "../declarations/actionTypes";
import { TRequests } from "../declarations/maps/requests";

type Actions = TActionRequestListRequest | TActionRequestListSuccess | TActionRequestListFailure;

export const reducerRequests = (prevStore: TRequests = {}, action: Actions): TRequests => {
  switch (action.type) {
    case TActionType.requestListRequest:
      return { ...prevStore, requestList: [] };
    case TActionType.requestListSuccess:
      return { ...prevStore, requestList: action.payload };
    case TActionType.requestListFailure:
      return { ...prevStore, requestList: [] };
    default:
      return prevStore;
  }
};
