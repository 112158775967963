/** @format */

import {
  TActionNotificationListFailure,
  TActionNotificationListRequest,
  TActionNotificationListSuccess,
} from "../declarations/actions/notification";
import { TActionType } from "../declarations/actionTypes";
import { TNotifications } from "../declarations/maps/notifications";

type Actions =
  | TActionNotificationListRequest
  | TActionNotificationListSuccess
  | TActionNotificationListFailure;

export const reducerNotifications = (
  prevStore: TNotifications = {},
  action: Actions,
): TNotifications => {
  switch (action.type) {
    case TActionType.notificationListRequest:
      return { ...prevStore, notificationList: [] };
    case TActionType.notificationListSuccess:
      return { ...prevStore, notificationList: action.payload };
    case TActionType.notificationListFailure:
      return { ...prevStore, notificationList: [] };
    default:
      return prevStore;
  }
};
