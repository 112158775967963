/** @format */

import React, { useEffect } from "react";
import { Col, Form, Row, Skeleton } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { useHistory } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UIText } from "../../ui/Text";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorDeviceUpdated,
  selectorLocationsListLoading,
} from "../../../redux-modules/selectors/selectorsUI";
import { UILink } from "../../ui/Link";
import { selectorLocationsList } from "../../../redux-modules/selectors/selectorsLocations";
import { UISelect } from "../../ui/Select";
import { actionDeviceCreateRequest } from "../../../redux-modules/actions/actionsDevices";
import { actionLocationListRequest } from "../../../redux-modules/actions/actionsLocations";
const { Option } = UISelect;

export const ViewDeviceCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDeviceCreated = useSelector(selectorDeviceUpdated);
  const locationList = useSelector(selectorLocationsList);
  const locationListLoading = useSelector(selectorLocationsListLoading);

  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      office_id: e.office_id,
      nfc_code: e.nfc_code,
      mac_address: e.mac_address,
    };
    //dispatch

    dispatch(actionDeviceCreateRequest(contentObj));
  };

  useEffect(() => {
    dispatch(actionLocationListRequest({}));
  }, []);

  useEffect(() => {
    if (isDeviceCreated) history.push(Routes.devices);
  }, [isDeviceCreated]);

  if (locationListLoading)
    return (
      <UICard style={{ marginBottom: "20px" }}>
        <Skeleton active />
      </UICard>
    );
  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Nuovo Dispositivo
          </UITitle>
        </Col>
      </Row>
      <Form
        name="new-device"
        className="new-device"
        data-cy="new-device-form"
        onFinish={handleSave}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="office_id"
                    label={<UIText strong={true}>Sede</UIText>}
                    rules={[{ required: true, message: "Seleziona una sede!" }]}
                    extra="La sede associata al dispositivo"
                  >
                    <UISelect>
                      {locationList.data.map(location => (
                        <Option value={location.id}>{location.name}</Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="mac_address"
                    label={<UIText strong={true}>MAC Address</UIText>}
                    rules={[{ required: false, message: "Inserisci un MAC Address valido!" }]}
                    extra="Il MAC Address del dispositivo"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="nfc_code"
                    label={<UIText strong={true}>Codice NFC</UIText>}
                    rules={[{ required: false, message: "Inserisci un codice NFC valido!" }]}
                    extra="Il codice NFC"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" gutter={[24, 24]}>
                <Col>
                  <UILink route={Routes.devices} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item>
                    <UIButton type="primary" data-cy="new-device-button" htmlType="submit">
                      + Crea Dispositivo
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
    </>
  );
};
