/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TReservationCreateRequest,
  TReservationUpdateRequest,
} from "../redux-modules/declarations/maps/reservations";
import apiClient from "../utils/apiClient";

export const apiReservationList = (): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.RESERVATIONS);
};

export const apiReservationCreate = (
  reservation: TReservationCreateRequest,
): Promise<AxiosResponse<unknown>> => apiClient.post(API_ENDPOINTS.RESERVATIONS, reservation);

export const apiReservationUpdate = (
  reservation: TReservationUpdateRequest,
): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.RESERVATIONS + "/" + reservation.id, reservation);

export const apiReservationDelete = (reservationId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.RESERVATIONS + "/" + reservationId);
