/** @format */

import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionShiftTypeCreateRequest,
  actionShiftTypeDeleteRequest,
  actionShiftTypeListRequest,
  actionShiftTypeUpdateRequest,
} from "../../../redux-modules/actions/actionsShiftTypes";
import {
  TShiftTypeCreateRequest,
  TShiftTypeUpdateRequest,
} from "../../../redux-modules/declarations/maps/shiftTypes";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { selectorShiftTypeList } from "../../../redux-modules/selectors/selectorsShiftTypes";
import {
  selectorShiftTypeDeleted,
  selectorShiftTypeUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../theme/maps/Colors";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { UICard } from "../../ui/Card";
import { UITitle } from "../../ui/Title";
import { EditShiftTypeModal } from "./partials/EditShiftTypeModal";
import { NewShiftTypeModal } from "./partials/NewShiftTypeModal";

export const ViewShiftTypesList = (): JSX.Element => {
  const dispatch = useDispatch();
  const shiftTypes = useSelector(selectorShiftTypeList);
  const [editingShiftType, setEditingShiftType] = useState<string | undefined>();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const isShiftTypeUpdated = useSelector(selectorShiftTypeUpdated);
  const isShiftTypeDeleted = useSelector(selectorShiftTypeDeleted);
  const permissionList = useSelector(selectorPermissionList);
  const canCreateShiftType = hasPermission(permissionList, "ShiftType", "Create");

  useEffect(() => {
    dispatch(actionShiftTypeListRequest());
  }, []);

  useEffect(() => {
    if (isShiftTypeUpdated || isShiftTypeDeleted) {
      dispatch(actionShiftTypeListRequest());
    }
  }, [isShiftTypeUpdated, isShiftTypeDeleted]);

  const onEdit = (shiftTypeId: string): void => {
    setEditingShiftType(shiftTypeId);
    setIsEditModalVisible(true);
  };

  const onCreate = (): void => {
    setIsCreateModalVisible(true);
  };

  const onCreateShiftType = (shiftType: TShiftTypeCreateRequest): void => {
    dispatch(actionShiftTypeCreateRequest(shiftType));
    setIsCreateModalVisible(false);
  };
  const onEditShiftType = (shiftType: TShiftTypeUpdateRequest): void => {
    dispatch(actionShiftTypeUpdateRequest(shiftType));
    setIsEditModalVisible(false);
  };
  const onCloseEditModal = (): void => {
    setEditingShiftType(undefined);
    setIsEditModalVisible(false);
  };
  const onCloseCreateModal = (): void => {
    setIsCreateModalVisible(false);
  };
  const onDelete = (shiftTypeId: string): void => {
    dispatch(actionShiftTypeDeleteRequest(shiftTypeId));
    setEditingShiftType(undefined);
    setIsEditModalVisible(false);
  };
  const gridStyle: React.CSSProperties = {
    width: "25%",
    textAlign: "center",
    cursor: "pointer",
  };
  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor}>
            Tipologie Turno
          </UITitle>
        </Col>
      </Row>
      <UICard title="">
        {shiftTypes.map(shiftType => (
          <UICard.Grid style={gridStyle} onClick={() => onEdit(shiftType.id)}>
            <Row justify="center">
              <Col>
                <UITitle level={4}>{shiftType.name} <br></br>{shiftType.is_notturno ? "Notturno" : ""}</UITitle>
              </Col>
            </Row>
          </UICard.Grid>
        ))}
        {canCreateShiftType && (
          <UICard.Grid style={gridStyle} onClick={onCreate}>
            <Row justify="center" style={{ height: "100%", alignContent: "center" }}>
              <Col>
                <PlusCircleOutlined style={{ fontSize: "28px", color: BaseColor }} />
              </Col>
            </Row>
          </UICard.Grid>
        )}
      </UICard>
      {isEditModalVisible && (
        <EditShiftTypeModal
          visible={isEditModalVisible}
          shiftTypeId={editingShiftType}
          onCancel={onCloseEditModal}
          onOk={onEditShiftType}
          onDelete={onDelete}
        />
      )}
      {isCreateModalVisible && (
        <NewShiftTypeModal
          visible={isCreateModalVisible}
          onCancel={onCloseCreateModal}
          onOk={onCreateShiftType}
        />
      )}
    </>
  );
};
