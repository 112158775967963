/** @format */

import { Row, Col, Dropdown, Menu, Tag } from "antd";
import React from "react";
import { Routes } from "../../utils/Routes";
import { UILink } from "../../components/ui/Link";
import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { UIText } from "../../components/ui/Text";
import { BaseColor } from "../../theme/maps/Colors";
import { useDispatch, useSelector } from "react-redux";
import { selectorPermissionList } from "../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../utils/permissions/hasPermission";
import { actionPresenceTypeDeleteRequest } from "../../redux-modules/actions/actionsPresenceTypes";

export const presenceTypeListColumns = [
  { title: "#", dataIndex: "id", key: "id", showMobile: true },
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
    showMobile: true,
  },
  {
    title: "Exp. Code",
    dataIndex: "export_code",
    key: "export_code",
    showMobile: true,
  },
  {
    title: "Colore",
    dataIndex: "color",
    key: "color",
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (row, text, index) => {
      return (
        <>
          <Tag color={`${text.color}`} key={index}>
            {`\u00A0\u00A0`}
          </Tag>
        </>
      );
    },
  },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  const history = useHistory();
  const permissionList = useSelector(selectorPermissionList);
  const dispatch = useDispatch();

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() =>
          history.push(Routes.settingsPresenceTypesEdit.replace(":id_presence_type", row.id))
        }
        icon={<EditOutlined />}
      >
        Gestisci
      </Menu.Item>

      <Menu.Item
        key="2"
        onClick={() => {
          dispatch(actionPresenceTypeDeleteRequest(row.id));
        }}
        icon={<DeleteOutlined />}
      >
        Elimina
      </Menu.Item>
    </Menu>
  );

  if (!hasPermission(permissionList, "PresenceType", "Update")) return <></>;
  return (
    <>
      <Dropdown overlay={menu}>
        <UIText css={"font-size: 24px; cursor: pointer;"} color={BaseColor}>
          <MoreOutlined />
        </UIText>
      </Dropdown>
    </>
  );
};
