/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import { apiTriggerList, apiTriggerUpdate } from "../../api/apiTriggers";
import {
  actionTriggerListLoadingRequest,
  actionTriggerListSuccess,
  actionTriggerListLoadingSuccess,
  actionTriggerListFailure,
  actionTriggerListLoadingFailure,
  actionTriggerUpdateSuccess,
  actionTriggerUpdateReset,
  actionTriggerUpdateFailure,
} from "../actions/actionsTrigger";
import {
  TActionTriggerListRequest,
  TActionTriggerUpdateRequest,
} from "../declarations/actions/trigger";
import { TTrigger } from "../declarations/maps/triggers";

export function* sagaTriggerList(action: TActionTriggerListRequest): unknown {
  try {
    yield put(actionTriggerListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TTrigger[]>> = yield call(
      apiTriggerList,
      action.payload,
    );
    yield put(actionTriggerListSuccess(response.data.data));
    yield put(actionTriggerListLoadingSuccess());
  } catch (e) {
    yield put(actionTriggerListFailure());
    yield put(actionTriggerListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaTriggerUpdate(action: TActionTriggerUpdateRequest): unknown {
  try {
    yield call(apiTriggerUpdate, action.payload);

    yield all([put(actionTriggerUpdateSuccess()), put(actionTriggerUpdateReset())]);
    showNotification("success", "Aggiornato!", "Tipologia Notifica modificata con successo");
  } catch (e) {
    yield put(actionTriggerUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
