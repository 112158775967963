/** @format */

import { Skeleton, Row, Col, Form, TabsProps, Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { API_ENDPOINTS } from "../../../constants/api";
import { WAREHOUSESTABS } from "../../../constants/settings";
import { actionLocationListRequest } from "../../../redux-modules/actions/actionsLocations";
import { actionProductListRequest } from "../../../redux-modules/actions/actionsProducts";
import { actionWarehouseDeleteRequest } from "../../../redux-modules/actions/actionsWarehouses";
import { TActionWarehouseDeleteRequest } from "../../../redux-modules/declarations/actions/warehouses";
import { TWarehouse } from "../../../redux-modules/declarations/maps/warehouses";
import { selectorLocationsList } from "../../../redux-modules/selectors/selectorsLocations";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import {
  selectorLocationsListLoading,
  selectorWarehouseUpdated,
  selectorWarehouseDeleted,
} from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../theme/maps/Colors";
import apiClient from "../../../utils/apiClient";
import { formatAvailableProductToArray } from "../../../utils/format/warehouseObjectFormat";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { Routes } from "../../../utils/Routes";
import { DeleteCard } from "../../layout/DeleteCard";
import { UICard } from "../../ui/Card";
import { UILink } from "../../ui/Link";
import RoutedTabs from "../../ui/RoutedTabs";
import { UISelect } from "../../ui/Select";
import { UITitle } from "../../ui/Title";
import { WarehouseAssignedTab } from "./partials/AssignmentTab";
import { WarehouseAvailableTab } from "./partials/AvailableTab";
import { WarehouseInfoTab } from "./partials/InfoTab";

export const ViewWarehouseEdit = () => {
  //@ts-ignore
  const { id_warehouse } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const locationList = useSelector(selectorLocationsList);
  const [warehouseInfo, setWarehouseInfo] = useState<TWarehouse>();
  const [warehouseAvailableProducts, setWarehouseAvailableProducts] = useState<unknown[]>([]);
  const [warehouseAssignmentProducts, setWarehouseAssignmentProducts] = useState<unknown[]>([]);
  const locationListLoading = useSelector(selectorLocationsListLoading);
  const isWarehouseUpdated = useSelector(selectorWarehouseUpdated);
  const isWarehouseDeleted = useSelector(selectorWarehouseDeleted);
  const permissionList = useSelector(selectorPermissionList);
  const canDeleteWarehouse = hasPermission(permissionList, "Warehouse", "Delete");

  const removeWarehouse = (): TActionWarehouseDeleteRequest =>
    dispatch(actionWarehouseDeleteRequest(id_warehouse));

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const fetchData = async () => {
    dispatch(actionLocationListRequest({}));
    dispatch(actionProductListRequest());
    const response = await apiClient.get(API_ENDPOINTS.WAREHOUSES + "/" + id_warehouse);
    //@ts-ignore
    setWarehouseInfo(response.data.warehouse);
    if (response.data.available_products) {
      setWarehouseAvailableProducts(
        //@ts-ignore
        formatAvailableProductToArray(response.data.available_products),
      );
    }
    if (response.data.assignment_products) {
      /*setWarehouseAssignmentProducts(
        //@ts-ignore
        formatAssignedProductToArray(response.data.assignment_products),
      );*/
      setWarehouseAssignmentProducts(response.data.assignment_products);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isWarehouseUpdated || isWarehouseDeleted) history.push(Routes.warehouses);
  }, [isWarehouseUpdated, isWarehouseDeleted]);

  const tabsProps: TabsProps = {
    tabPosition: "top",
    destroyInactiveTabPane: false,
  };

  if (!!!warehouseInfo)
    return (
      <UICard style={{ marginBottom: "20px" }}>
        <Skeleton active />
      </UICard>
    );

  const routeTabsConfig = {
    "/assegnazioni": {
      label: "Assegnazioni",
      component: function assigned() {
        return (
          <WarehouseAssignedTab
            assigned_products={warehouseAssignmentProducts}
            warehouseInfo={warehouseInfo}
            updateData={fetchData}
          />
        );
      },
      getRoute: (url: string): string => {
        return `${url}${WAREHOUSESTABS.ASSIGNED}`;
      },
    },
    "/disponibilita": {
      label: "Giacenze",
      component: function available() {
        return (
          <WarehouseAvailableTab
            available_products={warehouseAvailableProducts}
            warehouseInfo={warehouseInfo}
            updateData={fetchData}
          />
        );
      },
      getRoute: (url: string): string => `${url}${WAREHOUSESTABS.AVAILABLE}`,
    },
    "/": {
      label: "Informazioni Magazzino",
      component: function general() {
        return <WarehouseInfoTab warehouseInfo={warehouseInfo} />;
      },
      getRoute: (url: string): string => url,
    },
  };

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Modifica Magazzino
          </UITitle>
        </Col>
      </Row>
      <Row>
        <Breadcrumb>
          <Breadcrumb.Item>
            <UILink route={Routes.warehouses} label={"Magazzini"} />
          </Breadcrumb.Item>
          <Breadcrumb.Item>{warehouseInfo ? warehouseInfo.name : ""}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <UICard style={{ marginTop: "15px" }}>
        <RoutedTabs
          tabsProps={tabsProps}
          //@ts-ignore
          routeConfig={routeTabsConfig}
          basePath={"/magazzini/" + warehouseInfo?.id}
        />
      </UICard>
      {canDeleteWarehouse && (
        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col xs={24}>
            <DeleteCard
              data-cy="remove-warehouse-card"
              actionToDispatch={removeWarehouse}
              title="Elimina Magazzino"
              text="Cliccando sul bottone elimina eliminerai definitivamente il magazzino selezionato. Assicurati di voler rimuoverlo."
              removeButtonLabel="Elimina Magazzino"
            />
          </Col>
        </Row>
      )}
    </>
  );
};
