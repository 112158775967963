/** @format */

import { RedoOutlined } from "@ant-design/icons";
import { RadialBar, RadialBarConfig } from "@ant-design/plots";
import { Avatar, Col, Collapse, List, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../../constants/api";
import { actionGroupsListRequest } from "../../../../redux-modules/actions/actionsGroups";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import { selectorLoggedUser } from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorGroupsList } from "../../../../redux-modules/selectors/selectorsGroups";
import { selectorPermissionList } from "../../../../redux-modules/selectors/selectorsPermissions";
import { BaseColor } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { hasPermission } from "../../../../utils/permissions/hasPermission";
import { hexToRgb } from "../../../../utils/styles";
import { UICard } from "../../../ui/Card";
import { UISelect } from "../../../ui/Select";
import { UITitle } from "../../../ui/Title";
import { WidgetCard } from "../RecentDocuments";
const { Option } = UISelect;
const { Panel } = Collapse;

interface TData {
  utenti: TUser[];
  totali: { [key: string]: { tot: number; badge: number } };
}

export const WidgetPresenceNow = (): JSX.Element => {
  const loggedUser = useSelector(selectorLoggedUser);
  const dispatch = useDispatch();
  const groupList = useSelector(selectorGroupsList);
  const permissionList = useSelector(selectorPermissionList);
  const canSwithGroup = hasPermission(permissionList, "Group", "Canswitchpresencetoday");
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(loggedUser?.groups[0]?.id);
  const [nowPresences, setNowPresences] = useState<TData>();
  const [dataForGraph, setDataForGraph] = useState<
    {
      name: string;
      users: number;
      tot: number;
    }[]
  >([]);
  const [nowPresencesLoading, setNowPresencesLoading] = useState(true);
  const refreshNowPresences = async () => {
    setNowPresencesLoading(true);
    const response = await apiClient.get(
      API_ENDPOINTS.PRESENCES_NOW.replace(":id_group", selectedGroup || "1"),
    );
    setNowPresences(response.data as TData);
    setDataForGraph(formatForGraph(response.data));
    setNowPresencesLoading(false);
  };

  useEffect(() => {
    apiClient
      .get(API_ENDPOINTS.PRESENCES_NOW.replace(":id_group", selectedGroup || "1"))
      .then(response => {
        setNowPresences(response.data as TData);
        setDataForGraph(formatForGraph(response.data));
        setNowPresencesLoading(false);
      })
      .catch(error => {
        setNowPresencesLoading(false);
      });
  }, [selectedGroup]);

  useEffect(() => {
    dispatch(actionGroupsListRequest({}));
  }, []);

  useEffect(() => {
    if (loggedUser) {
      setSelectedGroup(loggedUser?.groups[0]?.id);
    }
  }, [loggedUser, groupList]);

  const formatForGraph = (
    data: TData,
  ): {
    name: string;
    users: number;
    tot: number;
  }[] => {
    let returnData = [];
    let totBadge = 0;
    let totTot = 0;
    let totColor = "";
    for (const reparto in data.totali) {
      if (Object.prototype.hasOwnProperty.call(data.totali, reparto)) {
        const perc = (data.totali[reparto].badge / data.totali[reparto].tot) * 100;
        const element = {
          name: reparto,
          users: data.totali[reparto].badge,
          tot: data.totali[reparto].tot,
          color: perc > 90 ? "#49a9d4" : perc > 50 ? "#7fd449" : perc > 25 ? "#d6b552" : "#d46e49",
        };
        totBadge += data.totali[reparto].badge;
        totTot += data.totali[reparto].tot;
        totColor =
          perc > 90 ? "#49a9d4" : perc > 50 ? "#7fd449" : perc > 25 ? "#d6b552" : "#d46e49";
        returnData.push(element);
      }
    }
    returnData = [...returnData, { name: "Tot", users: totBadge, tot: totTot, color: totColor }];
    return returnData;
  };

  const config: RadialBarConfig = {
    //@ts-ignore
    data: dataForGraph,
    xField: "name",
    yField: "users",
    maxAngle: 270,
    radius: 0.8,
    innerRadius: 0.2,
    legend: {
      position: "bottom",
      itemName: {
        formatter(text, item, index) {
          if (text === "#49a9d4") return "90%+";
          if (text === "#7fd449") return "50-90%";
          if (text === "#d6b552") return "25-50%";
          if (text === "#d46e49") return "0-25%";
        },
      },
    },
    xAxis: {
      title: {
        text: "Reparti",
      },
      label: {
        offset: 30,
        formatter: (text, item, index) => {
          if (text === "Tot") return "Totale";
          return text;
        },
      },
    },
    tooltip: {
      //@ts-ignore
      formatter: datum => {
        return {
          name: "Utenti",
          value: datum.users + "/" + dataForGraph.find(el => el.name === datum.name)?.tot,
        };
      },
    },
    colorField: "color",
    color(datum, defaultColor?) {
      return datum.color;
    },
    barStyle: {
      lineCap: "round",
    },
    annotations: [
      {
        type: "html",
        position: ["50%", "50%"],
        // @ts-ignore
        html: (container, view) => {
          const coord = view.getCoordinate();
          // @ts-ignore
          const w = coord.polarRadius * coord.innerRadius * 1.15;
          return `<div style="transform:translate(-50%,-46%)">
          <img width="${(w / 203) * 231}" height="${w}" alt="" src="logo_notext.png">
        </div>`;
        },
      },
    ],
  };

  if (!loggedUser || !groupList || !nowPresences || !selectedGroup)
    return (
      <WidgetCard title="Chi c'è in ufficio?">
        <UICard style={{ marginBottom: "20px", border: "none" }}>
          <Skeleton active />
        </UICard>
      </WidgetCard>
    );
  return (
    <>
      <WidgetCard
        title="Chi c'è in ufficio?"
        customTitleContent={
          canSwithGroup ? (
            <Col span={6} style={{ display: "flex", alignItems: "center" }}>
              <RedoOutlined
                style={{
                  display: "inline",
                  fontSize: "23px",
                  color: BaseColor,
                  marginRight: "8px",
                  cursor: "pointer",
                }}
                onClick={refreshNowPresences}
              />
              <UISelect
                showSearch
                value={selectedGroup}
                //@ts-ignore
                onChange={(value): void => setSelectedGroup(value)}
                placeholder="Tutti"
                filterOption={(input, option): boolean =>
                  ((option!.children as unknown) as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%", textAlign: "left" }}
              >
                {groupList.data.map(
                  (e): JSX.Element => (
                    <Option value={e.id} key={e.id}>
                      {e.name}
                    </Option>
                  ),
                )}
              </UISelect>
            </Col>
          ) : (
            <Col style={{ display: "flex" }}>
              <RedoOutlined
                style={{
                  display: "inline",
                  fontSize: "23px",
                  color: BaseColor,
                  marginRight: "8px",
                  cursor: "pointer",
                }}
                onClick={refreshNowPresences}
              />
              <UITitle level={4}>{groupList.data.find(el => el.id == selectedGroup)?.name}</UITitle>
            </Col>
          )
        }
      >
        <Row>
          <Col xl={12} xs={24}>
            <CustomCollapse accordion defaultActiveKey={loggedUser.department.id}>
              {!!nowPresences &&
                Object.keys(nowPresences?.totali).length &&
                Object.values(nowPresences?.totali).map((dato, index) => (
                  <Panel
                    header={
                      Object.keys(nowPresences.totali)[index] +
                      " (" +
                      dato.badge +
                      "/" +
                      dato.tot +
                      ")"
                    }
                    key={index}
                  >
                    <List
                      size="small"
                      bordered
                      // @ts-ignore
                      dataSource={nowPresences?.turni?.filter(
                        // @ts-ignore
                        el => el.department === Object.keys(nowPresences.totali)[index],
                      )}
                      renderItem={item => (
                        <List.Item>
                          <Avatar
                            size="small"
                            style={{ marginRight: "10px" }}
                            // @ts-ignore
                            src={item.avatar}
                          />
                          {
                            // @ts-ignore
                            item.name
                          }
                          {" (" +
                            // @ts-ignore
                            item.office +
                            ")"}
                          {"  -  "}
                          <strong>
                            {
                              // @ts-ignore
                              item.datetime_from
                            }
                          </strong>
                        </List.Item>
                      )}
                    />
                  </Panel>
                ))}
            </CustomCollapse>
          </Col>
          <Col xl={12} xs={24}>
            <RadialBar {...config} />
          </Col>
        </Row>
      </WidgetCard>
    </>
  );
};

const CustomCollapse = styled(Collapse)`
  border-radius: 7px;
  //background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.20"}!important;
  background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.25"}) !important;

  .ant-collapse-item:not(.ant-collapse-item-active):last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: none;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse > .ant-collapse-item-active:last-child {
    border-radius: none;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse-item-active:last-child,
  .ant-collapse-item-active:last-child > .ant-collapse-header {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse-content {
    max-height: 250px;
    overflow-y: auto;
  }
  .ant-list-bordered {
    border: none;
  }
`;
