/** @format */

import { Form, Row, Col, TimePicker } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TShiftTypeUpdateRequest } from "../../../../redux-modules/declarations/maps/shiftTypes";
import { TStore } from "../../../../redux-modules/declarations/store";
import { UIButton } from "../../../ui/Button";
import UIModal from "../../../ui/Modal";

interface Props {
  visible: boolean;
  shiftTypeId: string | undefined;
  onOk: (obj: TShiftTypeUpdateRequest) => void;
  onDelete: (shiftTypeId: string) => void;
  onCancel: () => void;
}

export const EditShiftTypeModal = ({
  visible,
  onOk,
  onCancel,
  onDelete,
  shiftTypeId,
}: Props): JSX.Element => {
  const selectedShiftType = useSelector((state: TStore) =>
    state.shiftTypes.shiftTypeList
      ?.map(e => ({
        ...e,
        time_from: moment(e.time_from, "HH:mm"),
        time_to: moment(e.time_to, "HH:mm"),
      }))
      .find(e => e.id === shiftTypeId),
  );
  const handleOk = () => {
    if (shiftTypeId) {
      const shiftType: TShiftTypeUpdateRequest = {
        id: shiftTypeId,
        time_from: moment(form.getFieldValue("time_from")).format("HH:mm:ss"),
        time_to: moment(form.getFieldValue("time_to")).format("HH:mm:ss"),
      };
      onOk(shiftType);
      form.resetFields();
    }
  };

  const [form] = Form.useForm();
  return (
    <UIModal
      title={"Modifica Tipologia Turno"}
      visible={visible}
      onOk={handleOk}
      okText="Aggiorna"
      okButtonProps={{
        style: { borderRadius: "7px" },
      }}
      cancelButtonProps={{ style: { borderRadius: "7px" } }}
      onCancel={onCancel}
      footer={[
        <UIButton key="back" onClick={onCancel}>
          Annulla
        </UIButton>,
        <UIButton key="submit" type="primary" onClick={handleOk}>
          Modifica
        </UIButton>,
        <UIButton
          key="delete"
          type="default"
          style={{
            backgroundColor: "#e00102",
            borderColor: "transparent",
            color: "#fff",
            float: "left",
          }}
          hoverBgColor="#da3233"
          onClick={() => onDelete(`${shiftTypeId}`)}
        >
          Elimina
        </UIButton>,
      ]}
    >
      <Form form={form} initialValues={selectedShiftType}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Form.Item name="time_from" label="Dalle" rules={[{ required: true }]}>
                  <CustomTimePicker format={"HH:mm"} showNow={false} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Form.Item name="time_to" label="Dalle" rules={[{ required: true }]}>
                  <CustomTimePicker format={"HH:mm"} showNow={false} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </UIModal>
  );
};
const CustomTimePicker = styled(TimePicker)`
  width: 100%;
  border-radius: 7px;
`;
