/** @format */
import { Badge, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import RequestTypeListColumnsRenderer from "../../components/renderers/RequestTypeTableMoreActions";
import TextRenderer from "../../components/renderers/Text";
/*
  id: string;
  key: string;
  name: string;
  color?: string;
  */

export const justificativeListColumns = [
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Colore",
    dataIndex: "color",
    key: "color",
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (row, text, index) => {
      return (
        <>
          <Tag color={`${text.color}`} key={index}>
            {`\u00A0\u00A0`}
          </Tag>
        </>
      );
    },
  },
  {
    align: "center",
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (row, text, index) => RequestTypeListColumnsRenderer(text.id),
  },
];
