/** @format */

import { TJustificative } from "../../redux-modules/declarations/maps/justificatives";

export const utilityFormatApiJustificative = (justificative: TJustificative): TJustificative => ({
  id: justificative.id,
  //@ts-ignore
  key: justificative.id,
  name: justificative.name,
  color: justificative.color,
  is_requestable: justificative.is_requestable,
  size_unit: justificative.size_unit,
});

export const utilityFormatApiJustificativeList = (list: TJustificative[]): TJustificative[] =>
  list.map(group => utilityFormatApiJustificative(group));
