/** @format */

import React, { useEffect, useState } from "react";
import { Checkbox, Col, Form, Row, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { selectorPresenceTypeUpdated } from "../../../redux-modules/selectors/selectorsUI";
import { actionPresenceTypeUpdateRequest } from "../../../redux-modules/actions/actionsPresenceTypes";
import { API_ENDPOINTS } from "../../../constants/api";
import { SETTINGSTABS } from "../../../constants/settings";
import apiClient from "../../../utils/apiClient";
import { Routes } from "../../../utils/Routes";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UILink } from "../../ui/Link";
import { UITitle } from "../../ui/Title";

export const ViewPresenceTypeEdit = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const routePaths = history.location.pathname.split("/");
  const id_presence_type = routePaths[routePaths.length - 1];
  const [presenceType, setPresenceType] = useState(undefined);
  const isPresenceTypeUpdated = useSelector(selectorPresenceTypeUpdated);
  const [form] = Form.useForm();

  useEffect(() => {
    if (isPresenceTypeUpdated) history.push(Routes.settings + SETTINGSTABS.PRESENCE_TYPES);
  }, [isPresenceTypeUpdated]);

  //@ts-ignore
  const handleUpdate = (e): void => {
    const contentObj = {
      id: id_presence_type,
      name: e.name,
      color: e.color,
      export_code: e.export_code,
    };
    //dispatch
    dispatch(actionPresenceTypeUpdateRequest(contentObj));
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      const response = await apiClient.get(API_ENDPOINTS.PRESENCE_TYPES + "/" + id_presence_type);
      //@ts-ignore
      setPresenceType(response.data.data);
    };
    fetchData();
  }, []);

  return !presenceType ? (
    <UICard style={{ marginBottom: "20px" }}>
      <Skeleton active />
    </UICard>
  ) : (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={4}>Modifica Tipologia Turno</UITitle>
        </Col>
      </Row>
      <Form
        name="new-presence-type"
        className="new-presence-type"
        onFinish={handleUpdate}
        initialValues={presenceType}
        layout="vertical"
        form={form}
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: "Inserisci un nome!" }]}
                    extra="Il nome della tipologia"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={2}>
                  <Form.Item
                    name="color"
                    label="Colore"
                    rules={[{ required: true, message: "Inserisci un colore!" }]}
                    extra="Il colore della tipologia"
                  >
                    <UIInput type="color" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="export_code"
                    label="Codice Esportazione"
                    rules={[{ required: false, message: "Inserisci un codice!" }]}
                    extra="Codice esportazione della tipologia"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
        <Row justify="end" gutter={[24, 24]}>
          <Col>
            <UILink
              route={Routes.settings + SETTINGSTABS.PRESENCE_TYPES}
              label={<UIButton>Annulla</UIButton>}
            />
          </Col>
          <Col>
            <Form.Item>
              <UIButton type="primary" htmlType="submit">
                Salva
              </UIButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
