/** @format */

import { BellOutlined, DeleteOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Tabs } from "antd";
import React, { useState } from "react";
import CustomNoticeList, { NoticeIconTabProps } from "./NoticeList";

import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorNotificationList,
  selectorUnreadNotification,
  selectorUnreadNotificationCount,
} from "../../../redux-modules/selectors/selectorNotifications";
import {
  actionNotificationDeleteRequest,
  actionNotificationReadRequest,
} from "../../../redux-modules/actions/actionsNotfication";
import { UITitle } from "../../ui/Title";
import { UIText } from "../../ui/Text";
import { BaseColor } from "../../../theme/maps/Colors";
import { isMobileOnly } from "react-device-detect";

const { TabPane } = Tabs;

export interface NoticeIconData {
  avatar?: string | React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  datetime?: React.ReactNode;
  extra?: React.ReactNode;
  style?: React.CSSProperties;
  key?: string | number;
  read?: boolean;
  onClick?: () => void;
}

export interface NoticeIconProps {
  count?: number;
  bell?: React.ReactNode;
  className?: string;
  loading?: boolean;
  onClear?: (tabName: string, tabKey: string) => void;
  onItemClick?: (item: NoticeIconData, tabProps: NoticeIconTabProps) => void;
  onViewMore?: (tabProps: NoticeIconTabProps, e: MouseEvent) => void;
  onTabChange?: (tabTile: string) => void;
  style?: React.CSSProperties;
  onPopupVisibleChange?: (visible: boolean) => void;
  popupVisible?: boolean;
  clearText?: string;
  viewMoreText?: string;
  clearClose?: boolean;
  emptyImage?: string;
  children: React.ReactElement<NoticeIconTabProps>;
}

const NoticeIcon: React.FC<NoticeIconProps> & {
  Tab: typeof CustomNoticeList;
} = props => {
  const getNotificationBox = ({
    removeVisbility,
    removeAll,
  }: {
    removeVisbility: () => void;
    removeAll: () => void;
  }): React.ReactNode => {
    const {
      children,
      onClear,
      onTabChange,
      onItemClick,
      onViewMore,
      clearText,
      viewMoreText,
    } = props;
    if (!children) {
      return null;
    }
    const panes: React.ReactNode[] = [];
    const { list, title, count, tabKey, showClear, showViewMore } = children.props;
    const len = list && list.length ? list.length : 0;
    const msgCount = count || count === 0 ? count : len;
    const tabTitle: string = msgCount > 0 ? `${title} (${msgCount})` : title;
    panes.push(
      <TabPane tab={tabTitle} key={tabKey}>
        <CustomNoticeList
          clearText={"Segna tutte come lette"}
          viewMoreText={viewMoreText}
          //@ts-ignore
          data={list.map(e => ({ ...e, onRemove: removeVisbility }))}
          onClear={(): void => onClear && onClear(title, tabKey)}
          //@ts-ignore
          onClick={(item): void => {
            if (onItemClick) {
              onItemClick(item, children.props);
              removeVisbility();
            }
          }}
          //@ts-ignore
          onViewMore={(event): void => onViewMore && onViewMore(children.props, event)}
          showClear={true}
          showViewMore={showViewMore}
          {...children.props}
        />
      </TabPane>,
    );
    return (
      <>
        {list.length > 0 && (
          <UIText
            //@ts-ignore
            onClick={() => removeAll()}
            style={{
              position: "absolute",
              right: "15px",
              top: "25px",
              color: BaseColor,
              cursor: "pointer",
              zIndex: 10,
            }}
          >
            <DeleteOutlined style={{ marginRight: "10px" }} />
            Elimina Tutte
          </UIText>
        )}

        <CustomTabs onChange={onTabChange}>{panes}</CustomTabs>
      </>
    );
  };

  const { count, bell } = props;

  const unreadCount = useSelector(selectorUnreadNotificationCount);
  const notif = useSelector(selectorNotificationList);
  const [visible, setVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const notificationBox = getNotificationBox({
    removeVisbility: () => setVisible(false),
    removeAll: () => {
      //@ts-ignore
      dispatch(actionNotificationDeleteRequest({ ids: notif.map(e => e.id) }));
    },
  });
  const NoticeBellIcon = bell || (
    <BellOutlined className="icon" style={{ color: "white", fontSize: "18px" }} />
  );
  const trigger = (
    <span style={{ cursor: "pointer" }}>
      <Badge
        count={unreadCount}
        style={{
          boxShadow: "none",
          color: "white !important",
          fontSize: "13px",
          minWidth: "15px!important",
          minHeight: "15px!important",
          width: "15px!important",
          height: "15px!important",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "1px",
        }}
      >
        {NoticeBellIcon}
      </Badge>
    </span>
  );
  if (!notificationBox) {
    return trigger;
  }

  if (isMobileOnly) return <></>;
  return (
    <CustomDropdown
      placement="bottomRight"
      overlay={<>{notificationBox}</>}
      overlayClassName={"popover"}
      trigger={["click"]}
      visible={visible}
      onVisibleChange={setVisible}
    >
      {trigger}
    </CustomDropdown>
  );
};

NoticeIcon.defaultProps = {
  emptyImage: "https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg",
};

NoticeIcon.Tab = CustomNoticeList;

const CustomDropdown = styled(Dropdown)`
  .popover {
    background: white !important;
    border: 1px solid #ebebeb !important;
    padding: 10px !important;
  }
`;

const NoticeBell = styled(NoticeIcon)`
  .popover {
    position: relative;
    width: 336px;
  }

  .noticeButton {
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s;
  }
  .icon {
    padding: 4px;
    vertical-align: middle;
  }

  .badge {
    color: white !important;
  }

  .ant-tabs-nav-scroll {
    text-align: center;
  }
`;

const CustomTabs = styled(Tabs)`
  margin-bottom: 0;
  background: white !important;
  border: 1px solid #ebebeb !important;
  padding: 10px !important;
  min-width: 350px;
  width: 100%;
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export default NoticeBell;
