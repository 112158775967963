/** @format */

import { TPagination } from "./ui";

export enum TSizeUnit {
  ORE = "ORE",
  GIORNI = "GIORNI",
}

export enum TIncrementalPeriod {
  MESE = "MESE",
  ANNO = "ANNO",
}

export enum TPeriodType {
  TUTTO_IL_GIORNO = "TUTTO IL GIORNO",
  PERIODO = "PERIODO",
  ORARI = "ORARI",
}

export interface TJustificatives {
  justificativeList?: TJustificativeList;
}
export interface TJustificativeList {
  data: TJustificative[];
  pagination: TPagination;
  loading: boolean;
}

export interface TJustificativeStatus {
  id: string;
  name: string;
  is_notification_enabled: boolean;
  is_active: boolean;
}

export interface TJustificativeStatuses {
  justificativeStatusList?: TJustificativeStatus[];
}

export interface TJustificativeDataSource {
  id: string;
  key: string;
  name: string;
  color?: string;
}

export type TJustificativeDataSourceList = TJustificativeDataSource[];

export interface TJustificative {
  id: string;
  name: string;
  description?: string;
  period_type: TPeriodType;
  size_unit: TSizeUnit;
  justificative_status_default_id: string;
  incremental_period?: TIncrementalPeriod;
  incremental_value?: number;
  switch_amount_date?: string; // DD/MM   ?
  color?: string;
  is_requestable: boolean;
}

export interface TJustificativeCreateRequest {
  name: string;
  description?: string;
  period_type: TPeriodType;
  size_unit: TSizeUnit;
  justificative_status_default_id: string;
  incremental_period?: TIncrementalPeriod;
  incremental_value?: number;
  switch_amount_date?: string;
  color?: string;
  is_requestable: boolean;
}

export interface TJustificativeUpdateRequest {
  id: string;
  name: string;
  description?: string;
  period_type: TPeriodType;
  size_unit: TSizeUnit;
  justificative_status_default_id: string;
  incremental_period?: TIncrementalPeriod;
  incremental_value?: number;
  switch_amount_date?: string;
  color?: string;
  is_requestable: boolean;
}
