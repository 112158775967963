/** @format */

import { EditOutlined } from "@ant-design/icons";
import { Col, Popover, Avatar } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { TUserMinified } from "../../redux-modules/declarations/maps/auth";
import { selectorPermissionList } from "../../redux-modules/selectors/selectorsPermissions";
import { BaseColor } from "../../theme/maps/Colors";
import { hasPermission } from "../../utils/permissions/hasPermission";
import { Routes } from "../../utils/Routes";
import { UICard } from "../ui/Card";

export const CustomUserItem = ({ user }: { user: TUserMinified }): JSX.Element => {
  const permissionList = useSelector(selectorPermissionList);
  const canEditUser = hasPermission(permissionList, "User", "Update");
  const history = useHistory();
  if (canEditUser)
    return (
      <Popover
        overlayInnerStyle={{ borderRadius: "7px" }}
        content={
          <UICard
            style={{ width: 300 }}
            actions={[
              <EditOutlined
                key="edit"
                onClick={() => history.push(Routes.usersEdit.replace(":id_user", user.id))}
              />,
            ]}
          >
            <Meta
              avatar={<Avatar src={user.avatar} />}
              title={user.name}
              description={user.group + " (" + user.manager + ")"}
            />
          </UICard>
        }
      >
        <span
          style={{
            color: BaseColor,
            cursor: "pointer",
            textTransform: "capitalize",
            marginLeft: "3px",
          }}
        >
          {user.name.toLowerCase()}
        </span>
      </Popover>
    );

  return (
    <span
      style={{
        color: BaseColor,
        textTransform: "capitalize",
        marginLeft: "3px",
      }}
    >
      {user.name.toLowerCase()}
    </span>
  );
};
