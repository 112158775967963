/** @format */

import { select, put } from "redux-saga/effects";
import { selectorReduxStore } from "../selectors/selectorsGeneral";
import { utilitySaveToLocalStorage } from "../../utils/cache/saveToLocalStorage";
import { KEY_APP_NAME } from "../../constants/general";
import {
  actionCacheSaveSuccess,
  actionCacheSaveFailure,
  actionCacheRemoveSuccess,
  actionCacheRemoveFailure,
} from "../actions/actionsGeneral";
import { TStore } from "../declarations/store";
import { utilityRemoveLocalStorage } from "../../utils/cache/removeFromLocalStorage";

export function* sagaCacheSave(): unknown {
  try {
    const store: TStore = yield select(selectorReduxStore);
    utilitySaveToLocalStorage(KEY_APP_NAME, store);
    yield put(actionCacheSaveSuccess(store));
  } catch {
    yield put(actionCacheSaveFailure());
  }
}

export function* sagaCacheRemove(): unknown {
  try {
    utilityRemoveLocalStorage(KEY_APP_NAME);
    yield put(actionCacheRemoveSuccess());
  } catch {
    yield put(actionCacheRemoveFailure());
  }
}
