/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TDepartmentCreateRequest,
  TDepartmentUpdateRequest,
} from "../redux-modules/declarations/maps/departments";
import apiClient from "../utils/apiClient";

export const apiDepartmentList = (): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.DEPARTMENTS);
};

export const apiDepartmentCreate = (
  department: TDepartmentCreateRequest,
): Promise<AxiosResponse<unknown>> => apiClient.post(API_ENDPOINTS.DEPARTMENTS, department);

export const apiDepartmentUpdate = (
  department: TDepartmentUpdateRequest,
): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.DEPARTMENTS + "/" + department.id, department);

export const apiDepartmentDelete = (departmentId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.DEPARTMENTS + "/" + departmentId);
