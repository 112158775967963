/** @format */

import { Avatar, Col, Divider, Drawer, Row, Skeleton, Tag, Timeline } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import { TCustomPresence } from "../../../../redux-modules/declarations/maps/presences";
import { selectorPermissionList } from "../../../../redux-modules/selectors/selectorsPermissions";
import { selectorPresenceTypeList } from "../../../../redux-modules/selectors/selectorsPresenceTypes";
import { BaseColor } from "../../../../theme/maps/Colors";
import { hasPermission } from "../../../../utils/permissions/hasPermission";
import { darkenFromHex, stringToHex } from "../../../../utils/strings/stringToColor";
import { UIButton } from "../../../ui/Button";
import { UICard } from "../../../ui/Card";
import { UIText } from "../../../ui/Text";
import { UITitle } from "../../../ui/Title";

interface Props {
  visible: boolean;
  onClose?: () => void;
  presence?: TCustomPresence;
  modalLoading?: boolean;
}

export const ShowPresenceDrawer: FC<Props> = ({
  visible,
  onClose,
  presence,
  modalLoading,
}: Props): JSX.Element => {
  //@ts-ignore
  const [user, setUser] = useState<TUser>();
  const presenceTypes = useSelector(selectorPresenceTypeList);
  const permissionList = useSelector(selectorPermissionList);
  const canViewStatusHistory = hasPermission(permissionList, "Presence", "Canshowhistory");

  useEffect(() => {
    if (presence) {
      setUser(presence.original.user);
    }
  }, [presence]);

  const handleClose = () => {
    if (onClose)
      // quello che vogliamo
      onClose();
  };

  if (!user || !presenceTypes)
    return (
      <Drawer
        title="Presenza"
        placement="right"
        onClose={handleClose}
        closable={true}
        visible={visible}
        width={400}
      >
        <UICard style={{ marginBottom: "20px", border: "none" }}>
          <Skeleton active />
        </UICard>
      </Drawer>
    );

  return (
    <Drawer
      title="Presenza"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <UIButton onClick={onClose}>Annulla</UIButton>
        </div>
      }
    >
      <Row>
        <Col span={24}>
          {!presence ? (
            <UICard style={{ marginBottom: "20px", border: "none" }}>
              <Skeleton active />
            </UICard>
          ) : (
            <>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Avatar
                    size={64}
                    //@ts-ignore
                    src={presence?.original.user.avatar}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <UITitle level={3} color={BaseColor}>
                    {presence.original.user.name + " " + presence.original.user.surname}
                  </UITitle>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  {presence.original.user.groups.map((group, index) => (
                    <Tag
                      key={index}
                      color={darkenFromHex(stringToHex(group.name))}
                      style={{ borderRadius: "5px" }}
                    >
                      {group.name}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <UITitle level={5} color={BaseColor}>
                    Info
                  </UITitle>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <UIText>Tipologia Turno:</UIText>{" "}
                      <UIText style={{ fontWeight: "bold" }}>
                        <Tag
                          color={presence.original.presence_type_color}
                          style={{
                            borderRadius: "5px",
                          }}
                        >
                          {presence.original.presence_type_name}
                        </Tag>
                      </UIText>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UIText>Dalle:</UIText>{" "}
                      <UIText style={{ fontWeight: "bold" }}>
                        {presence.modified
                          ? presence.modified.presence_datetime_from
                          : presence.original.datetime_from}
                      </UIText>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UIText>Alle:</UIText>{" "}
                      <UIText style={{ fontWeight: "bold" }}>
                        {presence.original.datetime_to
                          ? presence.modified
                            ? presence.modified.presence_datetime_to
                            : presence.original.datetime_to
                          : "In corso"}
                      </UIText>
                    </Col>
                  </Row>
                  {presence.original.datetime_to && (
                    <Row>
                      <Col span={24}>
                        <UIText>Durata:</UIText>{" "}
                        <UIText style={{ fontWeight: "bold" }}>
                          {" "}
                          {presence.modified
                            ? presence.modified.presence_duration
                            : presence.original.duration}
                        </UIText>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </>
          )}

          {canViewStatusHistory && (
            <>
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <UITitle level={5} color={BaseColor}>
                    Storico Eventi
                  </UITitle>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Timeline style={{ marginTop: "10px" }}>
                    {!!presence?.original.history &&
                      presence.original.history?.map((status, i) => (
                        <Timeline.Item key={i}>
                          {`${status.note} `}
                          <br />
                          <span style={{ fontSize: "13px" }}>{`(${status.date_operation} - ${
                            status.user && status.user
                          })`}</span>
                        </Timeline.Item>
                      ))}
                  </Timeline>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Drawer>
  );
};
