/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TRequestCreateRequest,
  TRequestListQuery,
  TRequestUpdateRequest,
} from "../redux-modules/declarations/maps/requests";
import apiClient from "../utils/apiClient";

export const apiRequestList = (payload?: TRequestListQuery): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(
    API_ENDPOINTS.REQUESTS +
      (payload?.datetime_to
        ? `?datetime_from=${payload.datetime_from}&datetime_to=${payload.datetime_to}`
        : ``),
  );
};

export const apiRequestCreate = (request: TRequestCreateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.post(API_ENDPOINTS.REQUESTS, request);

export const apiRequestUpdate = (request: TRequestUpdateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.REQUESTS + "/" + request.id, request);

export const apiRequestDelete = (requestId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.REQUESTS + "/" + requestId);
