/** @format */

import { PauseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { apiPresenceCheck } from "../../../../api/apiPresences";
import { API_ENDPOINTS } from "../../../../constants/api";
import { actionPresenceCreateRequest } from "../../../../redux-modules/actions/actionsPresences";
import { actionPresenceTypeListRequest } from "../../../../redux-modules/actions/actionsPresenceTypes";
import { TPresence } from "../../../../redux-modules/declarations/maps/presences";
import { TShift } from "../../../../redux-modules/declarations/maps/shifts";
import {
  selectorIsPresenceInApp,
  selectorLoggedUser,
} from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorPresenceUpdated } from "../../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { UIText } from "../../../ui/Text";
import { WidgetCard } from "../RecentDocuments";
import { InAppBadge } from "./partials/InAppBadge";
import { QrCodeBadge } from "./partials/QrCodeBadge";

export const WidgetCreatePresence = (): JSX.Element => {
  const dispatch = useDispatch();
  const [todaysPalannings, setTodaysPalannings] = useState<TShift[]>([]);
  const loggedUser = useSelector(selectorLoggedUser);
  const [runningPresence, setRunningPresence] = useState<TPresence[]>([]);
  const isPresenceInApp = useSelector(selectorIsPresenceInApp);
  const isPresenceUpdated = useSelector(selectorPresenceUpdated);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!!loggedUser) {
      setLoading(true);
      apiPresenceCheck(`${loggedUser?.id}`).then(r => {
        //@ts-ignore
        setRunningPresence(r.data.data);
      });

      apiClient.get(API_ENDPOINTS.SHIFTS_NEXT + "?version=2&device=web").then(r => {
        setTodaysPalannings(r.data.today);
        setLoading(false);
      });
      dispatch(actionPresenceTypeListRequest());
    }
  }, []);

  useEffect(() => {
    if (!!isPresenceUpdated) {
      apiPresenceCheck(`${loggedUser?.id}`).then(r => {
        //@ts-ignore
        setRunningPresence(r.data.data);
      });
    }
  }, [isPresenceUpdated]);

  return (
    <>
      <WidgetCard title="Timbratura" style={{ minHeight: isMobileOnly ? "350px" : "" }}>
        <>
          <Row style={{ marginBottom: "20px" }}>
            <Col span={24}>
              {!isPresenceInApp ? (
                <QrCodeBadge
                  runningPresence={runningPresence}
                  todaysPalannings={todaysPalannings}
                  isLoading={loading}
                />
              ) : (
                <InAppBadge
                  runningPresence={runningPresence}
                  todaysPalannings={todaysPalannings}
                  isLoading={loading}
                />
              )}
            </Col>
          </Row>
        </>
      </WidgetCard>
    </>
  );
};
