/** @format */

import { AxiosResponse } from "axios";
import { put, all, call } from "redux-saga/effects";
import {
  apiWarehouseCreate,
  apiWarehouseUpdate,
  apiWarehouseDelete,
  apiWarehousesList,
} from "../../api/apiWarehouses";
import { showNotification } from "../../utils/showNotification";
import {
  actionWarehouseCreateSuccess,
  actionWarehouseCreateReset,
  actionWarehouseCreateFailure,
  actionWarehouseListLoadingRequest,
  actionWarehouseListSuccess,
  actionWarehouseListLoadingSuccess,
  actionWarehouseListFailure,
  actionWarehouseListLoadingFailure,
  actionWarehouseUpdateSuccess,
  actionWarehouseUpdateReset,
  actionWarehouseUpdateFailure,
  actionWarehouseDeleteSuccess,
  actionWarehouseDeleteReset,
  actionWarehouseDeleteFailure,
} from "../actions/actionsWarehouses";
import {
  TActionWarehouseCreateRequest,
  TActionWarehouseListRequest,
  TActionWarehouseUpdateRequest,
  TActionWarehouseDeleteRequest,
} from "../declarations/actions/warehouses";
import { TAPIResponse } from "../declarations/apis/general";
import { TWarehouse } from "../declarations/maps/warehouses";

export function* sagaWarehouseCreate(action: TActionWarehouseCreateRequest): unknown {
  try {
    const response = yield call(apiWarehouseCreate, action.payload);

    yield put(actionWarehouseUpdateSuccess());
    yield put(actionWarehouseUpdateReset());
    showNotification("success", "Creato!", "Magazzino creato con successo");
  } catch (e) {
    yield put(actionWarehouseCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaWarehouseList(action: TActionWarehouseListRequest): unknown {
  try {
    yield put(actionWarehouseListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TWarehouse[]>> = yield call(apiWarehousesList);

    yield put(actionWarehouseListSuccess(response.data.data));
    yield put(actionWarehouseListLoadingSuccess());
  } catch (e) {
    yield put(actionWarehouseListFailure());
    yield put(actionWarehouseListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaWarehouseUpdate(action: TActionWarehouseUpdateRequest): unknown {
  try {
    yield call(apiWarehouseUpdate, action.payload);

    yield all([put(actionWarehouseUpdateSuccess()), put(actionWarehouseUpdateReset())]);
    showNotification("success", "Aggiornato!", "Magazzino modificato con successo");
  } catch (e) {
    yield put(actionWarehouseUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaWarehouseDelete(action: TActionWarehouseDeleteRequest): unknown {
  try {
    yield call(apiWarehouseDelete, action.payload);

    yield all([
      put(actionWarehouseDeleteSuccess(action.payload)),
      put(actionWarehouseDeleteReset()),
    ]);
    showNotification("success", "Eliminato!", "Magazzino eliminato con successo");
  } catch (e) {
    yield put(actionWarehouseDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
