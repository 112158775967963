/** @format */

import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TPresenceType } from "../../../../../redux-modules/declarations/maps/presenceTypes";
import { selectorPresenceTypeList } from "../../../../../redux-modules/selectors/selectorsPresenceTypes";
import UIModal from "../../../../ui/Modal";
import { UITitle } from "../../../../ui/Title";
import { QRCode } from "react-qrcode-logo";
import {
  selectorIsGestioneTimebankEnabled,
  selectorLoggedUser,
} from "../../../../../redux-modules/selectors/selectorsAuth";
import moment from "moment";
import { UIButton } from "../../../../ui/Button";
import { PauseCircleOutlined, QrcodeOutlined, SyncOutlined } from "@ant-design/icons";
import { isMobileOnly } from "react-device-detect";
import { TPresence } from "../../../../../redux-modules/declarations/maps/presences";
import { UIText } from "../../../../ui/Text";
import { BaseColor } from "../../../../../theme/maps/Colors";
import { TShift } from "../../../../../redux-modules/declarations/maps/shifts";

export interface QrCodeBadgeProps {
  runningPresence: TPresence[];
  todaysPalannings: TShift[];
  isLoading: boolean;
}

export const QrCodeBadge = ({
  runningPresence,
  todaysPalannings,
  isLoading,
}: QrCodeBadgeProps): JSX.Element => {
  const isGestioneTimebankAbilitata = useSelector(selectorIsGestioneTimebankEnabled);
  const loggedUser = useSelector(selectorLoggedUser);
  const [selectedPresenceType, setSelectedPresenceType] = useState<TPresenceType | undefined>();
  const [selectedShiftPlanning, setSelectedShiftPlanning] = useState<string | null | undefined>();
  const [qrCodeValue, setQrCodeValue] = useState("");
  const presenceTypes = useSelector(selectorPresenceTypeList);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [counter, setCounter] = useState<number | undefined>(300000);
  const [running, setRunning] = useState(false);

  const handleSelectPresenceType = (id: string) =>
    setSelectedPresenceType(presenceTypes.find(p => p.id === id));

  const generateQrCodeValue = () => {
    setRunning(false);
    setCounter(300000);
    const object = {
      user_id: loggedUser?.id,
      shift_planning_id: selectedShiftPlanning,
      generate_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      presence_type_id: selectedPresenceType?.id,
    };
    setQrCodeValue(JSON.stringify(object));
  };

  useEffect(() => {
    if (!!qrCodeValue) {
      setRunning(true);
    }
  }, [qrCodeValue]);

  useEffect(() => {
    if (running) {
      const interval = setInterval(() => {
        setCounter(counter => (counter ? counter - 1000 : 0));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [running]);

  useEffect(() => {
    if (!counter) {
      generateQrCodeValue();
    }
  }, [counter]);

  useEffect(() => {
    if (!!selectedPresenceType) {
      generateQrCodeValue();
      setQrModalVisible(true);
    }
  }, [selectedPresenceType]);

  useEffect(() => {
    if (!!selectedShiftPlanning) {
      generateQrCodeValue();
    }
  }, [selectedShiftPlanning]);

  return (
    <>
      {!!runningPresence.length ? (
        <>
          <Row style={{ marginBottom: "20px" }}>
            <Col span={24}>
              <UIText style={{ fontWeight: 600, fontSize: "16px" }}>Timbratura in corso</UIText>
            </Col>
          </Row>
          <Row>
            <Col span={isMobileOnly ? 24 : 12}>
              <TheBigButton>
                <Row>
                  <Col style={{ fontSize: "15px" }}>{runningPresence[0].presence_type_name}</Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Row>
                      <Col>
                        <UIText color={"white"} style={{ fontSize: "15px" }}>
                          Inizio:
                        </UIText>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <UIText color={"white"} style={{ fontSize: "20px", marginRight: "15px" }}>
                          {moment(runningPresence[0].datetime_from, "DD/MM/YYYY HH:mm:ss").format(
                            "HH:mm",
                          )}
                        </UIText>
                        <SyncOutlined spin />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    span={12}
                    style={{ alignSelf: "center", textAlign: "right", cursor: "pointer" }}
                    onClick={() => handleSelectPresenceType(runningPresence[0].presence_type_id)}
                  >
                    <PauseCircleOutlined style={{ fontSize: "45px" }} />
                  </Col>
                </Row>
              </TheBigButton>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <PresenceTypesContainer>
            {presenceTypes
              .filter(e => !!e.is_visible)
              .map(presenceType => (
                <PresenceTypeItem
                  onClick={() => handleSelectPresenceType(presenceType.id)}
                  style={{
                    backgroundColor: presenceType.color,
                    margin: "5px",
                    height: "40px",
                  }}
                >
                  {presenceType.name}
                </PresenceTypeItem>
              ))}
          </PresenceTypesContainer>
        </>
      )}
      <UIModal
        visible={qrModalVisible}
        onOk={() => {
          setQrModalVisible(false);
          setSelectedShiftPlanning(undefined);
          setSelectedPresenceType(undefined);
        }}
        onCancel={() => {
          setQrModalVisible(false);
          setSelectedShiftPlanning(undefined);
          setSelectedPresenceType(undefined);
        }}
        title={runningPresence.length ? "Chiudi Timbratura" : "Nuova timbratura"}
        cancelButtonProps={{ hidden: true }}
      >
        {!!selectedPresenceType && !isLoading && (
          <>
            <Row justify="center">
              <Col>
                <UITitle level={4} color={selectedPresenceType.color}>
                  {selectedPresenceType.name}
                </UITitle>
              </Col>
            </Row>
            {isGestioneTimebankAbilitata &&
            typeof selectedShiftPlanning == "undefined" &&
            runningPresence.length === 0 &&
            todaysPalannings.length > 0 ? (
              <>
                <Row justify="center">
                  <Col>
                    <UITitle level={5}>Seleziona il turno da effettuare</UITitle>
                  </Col>
                </Row>
                {todaysPalannings.map(e => (
                  <Row justify="center" style={{ margin: "10px 0px 15px 0px" }}>
                    <Col>
                      <TipologiaTurno onClick={() => setSelectedShiftPlanning(e.id)}>
                        {
                          // @ts-ignore
                          e.orario
                        }
                      </TipologiaTurno>
                    </Col>
                  </Row>
                ))}
              </>
            ) : (
              <>
                <Row justify="center">
                  <Col>
                    <QRCode
                      value={qrCodeValue}
                      eyeRadius={5} // 5 pixel radius for all corners of all positional eyes
                    />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col>
                    <UIText>{moment(counter).format("mm:ss")}</UIText>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col>
                    <UIButton onClick={generateQrCodeValue}>
                      <QrcodeOutlined style={{ marginRight: "2px" }} /> Rigenera
                    </UIButton>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </UIModal>
    </>
  );
};

const TipologiaTurno = styled.span`
  border: 1px solid black;
  border-radius: 7px;
  padding: 5px;
  font-size: 15px;
  cursor: pointer;
`;

const PresenceTypeItem = styled.span`
  padding: 8px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 7px;
  color: white;
  cursor: pointer;
`;
const PresenceTypesContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  display: ${isMobileOnly ? "inline-grid" : "block"};
`;

const TheBigButton = styled.span`
  padding: 20px;
  border-radius: 7px;
  background-color: ${BaseColor};
  color: white;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  display: inline-block;
  width: 100%;
`;
