/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import {
  actionReservationCreateSuccess,
  actionReservationCreateReset,
  actionReservationCreateFailure,
  actionReservationListLoadingRequest,
  actionReservationListLoadingSuccess,
  actionReservationListFailure,
  actionReservationListLoadingFailure,
  actionReservationUpdateSuccess,
  actionReservationUpdateReset,
  actionReservationUpdateFailure,
  actionReservationDeleteSuccess,
  actionReservationDeleteReset,
  actionReservationDeleteFailure,
  actionReservationListSuccess,
} from "../actions/actionsReservations";
import {
  TActionReservationCreateRequest,
  TActionReservationListRequest,
  TActionReservationUpdateRequest,
  TActionReservationDeleteRequest,
} from "../declarations/actions/reservations";
import { TReservation } from "../declarations/maps/reservations";
import {
  apiReservationCreate,
  apiReservationUpdate,
  apiReservationDelete,
  apiReservationList,
} from "../../api/apiReservations";

export function* sagaReservationCreate(action: TActionReservationCreateRequest): unknown {
  try {
    const response = yield call(apiReservationCreate, action.payload);

    yield put(actionReservationCreateSuccess(response.data.id));
    yield put(actionReservationCreateReset());
    showNotification("success", "Creato!", "Prenotazione creata con successo");
  } catch (e) {
    yield put(actionReservationCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaReservationList(action: TActionReservationListRequest): unknown {
  try {
    yield put(actionReservationListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TReservation[]>> = yield call(apiReservationList);

    yield put(actionReservationListSuccess(response.data.data));
    yield put(actionReservationListLoadingSuccess());
  } catch (e) {
    yield put(actionReservationListFailure());
    yield put(actionReservationListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaReservationUpdate(action: TActionReservationUpdateRequest): unknown {
  try {
    yield call(apiReservationUpdate, action.payload);

    yield all([put(actionReservationUpdateSuccess()), put(actionReservationUpdateReset())]);
    showNotification("success", "Aggiornato!", "Prenotazione modificata con successo");
  } catch (e) {
    yield put(actionReservationUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaReservationDelete(action: TActionReservationDeleteRequest): unknown {
  try {
    yield call(apiReservationDelete, action.payload);

    yield all([
      put(actionReservationDeleteSuccess(action.payload)),
      put(actionReservationDeleteReset()),
    ]);
    showNotification("success", "Eliminato!", "Prenotazione eliminata con successo");
  } catch (e) {
    yield put(actionReservationDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
