/** @format */

import { TActionLocationListFailure, TActionLocationListRequest, TActionLocationListSuccess } from "../declarations/actions/location";
import { TActionType } from "../declarations/actionTypes";
import { TLocations } from "../declarations/maps/locations";

type Actions = TActionLocationListRequest | TActionLocationListSuccess | TActionLocationListFailure;

export const reducerLocations = (prevStore: TLocations = {}, action: Actions): TLocations => {
  switch (action.type) {
    case TActionType.locationListRequest:
      return { ...prevStore, locationList: {
        data: [],
        pagination: { current: 0, pageSize: 0, lastPage: 0, total: 0 },
        loading: false,
      } };
    case TActionType.locationListSuccess:
      return { ...prevStore, locationList: action.payload };
    case TActionType.locationListFailure:
      return { ...prevStore, locationList: {
        data: [],
        pagination: { current: 0, pageSize: 0, lastPage: 0, total: 0 },
        loading: false,
      } };
    default:
      return prevStore;
  }
};
