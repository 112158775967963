/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import {
  actionJustificativeCreateSuccess,
  actionJustificativeCreateReset,
  actionJustificativeCreateFailure,
  actionJustificativeListLoadingRequest,
  actionJustificativeListLoadingSuccess,
  actionJustificativeListFailure,
  actionJustificativeListLoadingFailure,
  actionJustificativeUpdateSuccess,
  actionJustificativeUpdateReset,
  actionJustificativeUpdateFailure,
  actionJustificativeDeleteSuccess,
  actionJustificativeDeleteReset,
  actionJustificativeDeleteFailure,
  actionJustificativeListSuccess,
} from "../actions/actionsJustificatives";
import {
  TActionJustificativeCreateRequest,
  TActionJustificativeListRequest,
  TActionJustificativeUpdateRequest,
  TActionJustificativeDeleteRequest,
} from "../declarations/actions/justificatives";
import { TJustificative } from "../declarations/maps/justificatives";
import {
  apiJustificativeCreate,
  apiJustificativeUpdate,
  apiJustificativeDelete,
  apiJustificativeList,
} from "../../api/apiJustificatives";
import { utilityFormatJustificativeTable } from "../../utils/format/justificativeTable";

export function* sagaJustificativeCreate(action: TActionJustificativeCreateRequest): unknown {
  try {
    const response = yield call(apiJustificativeCreate, action.payload);

    yield put(actionJustificativeCreateSuccess(response.data.id));
    yield put(actionJustificativeCreateReset());
    showNotification("success", "Creato!", "Giustificativo creato con successo");
  } catch (e) {
    yield put(actionJustificativeCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaJustificativeList(action: TActionJustificativeListRequest): unknown {
  try {
    yield put(actionJustificativeListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TJustificative[]>> = yield call(
      apiJustificativeList,
      action.payload,
    );
    const justificativeList = utilityFormatJustificativeTable(response.data);

    yield put(actionJustificativeListSuccess(justificativeList));
    yield put(actionJustificativeListLoadingSuccess());
  } catch (e) {
    yield put(actionJustificativeListFailure());
    yield put(actionJustificativeListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaJustificativeUpdate(action: TActionJustificativeUpdateRequest): unknown {
  try {
    yield call(apiJustificativeUpdate, action.payload);

    yield all([put(actionJustificativeUpdateSuccess()), put(actionJustificativeUpdateReset())]);
    showNotification("success", "Aggiornato!", "Giustificativo modificato con successo");
  } catch (e) {
    yield put(actionJustificativeUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaJustificativeDelete(action: TActionJustificativeDeleteRequest): unknown {
  try {
    yield call(apiJustificativeDelete, action.payload);

    yield all([
      put(actionJustificativeDeleteSuccess(action.payload)),
      put(actionJustificativeDeleteReset()),
    ]);
    showNotification("success", "Eliminato!", "Giustificativo eliminato con successo");
  } catch (e) {
    yield put(actionJustificativeDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
