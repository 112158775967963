/** @format */

import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UITable } from "../../ui/Table";
import { UIButton } from "../../ui/Button";
import { Routes } from "../../../utils/Routes";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorDepartmentDeleted,
  selectorDepartmentListLoading,
} from "../../../redux-modules/selectors/selectorsUI";
import { UICard } from "../../ui/Card";
import UITransition from "../../ui/Transition";
import { UILink } from "../../ui/Link";
import { selectorDepartmentList } from "../../../redux-modules/selectors/selectorsDepartments";
import { actionDepartmentListRequest } from "../../../redux-modules/actions/actionsDepartments";
import { departmentListColumns } from "../../../constants/tables/departmentListColumns";

export const ViewDepartmentList = (): JSX.Element => {
  const dispatch = useDispatch();
  const departmentList = useSelector(selectorDepartmentList);
  const departmentListLoading = useSelector(selectorDepartmentListLoading);
  const isDepartmentDeleted = useSelector(selectorDepartmentDeleted);

  useEffect(() => {
    if (isDepartmentDeleted) dispatch(actionDepartmentListRequest());
  }, [isDepartmentDeleted]);

  useEffect(() => {
    dispatch(actionDepartmentListRequest());
  }, []);

  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Reparti
          </UITitle>
        </Col>
        <Col>
          <UILink
            route={Routes.departmentsNew}
            label={
              <UIButton type="primary" data-cy="new-department-button">
                + Crea Reparto
              </UIButton>
            }
          />
        </Col>
      </Row>
      <UICard>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UITable
              size="middle"
              loading={departmentListLoading}
              columns={departmentListColumns}
              dataSource={departmentList}
              data-cy="department-list-table"
            />
          </Col>
        </Row>
      </UICard>
    </UITransition>
  );
};
