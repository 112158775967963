/** @format */

import { put, call, all, select } from "redux-saga/effects";
import {
  apiCustomStyleOptionList,
  apiCustomStyleOptionUpdate,
  apiUserAvatar,
  apiUserCreate,
  apiUserDelete,
  apiUserDocumentUpload,
  apiUsersList,
  apiUserUpdate,
} from "../../api/apiUsers";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import {
  actionUsersListLoadingRequest,
  actionUsersListSuccess,
  actionUsersListLoadingSuccess,
  actionUsersListFailure,
  actionUsersListLoadingFailure,
  actionUserCreateFailure,
  actionUserCreateSuccess,
  actionUserCreateReset,
  actionUserDeleteFailure,
  actionUserDeleteSuccess,
  actionUserUpdateFailure,
  actionUserUpdateSuccess,
  actionUserDeleteReset,
  actionUserUpdateReset,
  actionUserAvatarFailure,
  actionUserAvatarReset,
  actionUserAvatarSuccess,
  actionUserDocumentUploadSuccess,
  actionUserDocumentUploadReset,
  actionUserDocumentUploadFailure,
  actionUserListPageSizeRequest,
  actionUserProfileUpdateSuccess,
} from "../actions/actionsUsers";
import {
  TActionUserAvatarRequest,
  TActionUserCreateRequest,
  TActionUserDeleteRequest,
  TActionUserDocumentUploadRequest,
  TActionUsersListRequest,
  TActionUserUpdateRequest,
} from "../declarations/actions/users";
import { utilityFormatUserTable } from "../../utils/format/userTable";
import { TUser } from "../declarations/maps/auth";
import { showNotification } from "../../utils/showNotification";
import { TStore } from "../declarations/store";
import { Routes } from "../../utils/Routes";
import { selectorLoggedUser } from "../selectors/selectorsAuth";
import { actionPermissionListRequest } from "../actions/actionsPermission";
import {
  TActionCustomStylesOptionListRequest,
  TActionCustomStylesOptionUpdateRequest,
} from "../declarations/actions/ui";
import {
  actionCustomStyleOptionUpdateFailure,
  actionCustomStyleOptionUpdateReset,
  actionCustomStyleOptionUpdateSuccess,
  actionCustomStylesOptionListFailure,
  actionCustomStylesOptionListSuccess,
} from "../actions/actionsUI";

export function* sagaUserCreate(action: TActionUserCreateRequest): unknown {
  try {
    const response = yield call(apiUserCreate, action.payload);

    yield put(actionUserCreateSuccess(response.data.data.id));
    showNotification("success", "Creato!", "Utente creato con successo");
    yield put(actionUserCreateReset());
  } catch (e) {
    yield put(actionUserCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaUsersList(action: TActionUsersListRequest): unknown {
  try {
    yield put(actionUsersListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TUser[]>> = yield call(apiUsersList, action.payload);
    const usersList = utilityFormatUserTable(response.data);

    //@ts-ignore
    yield put(actionUsersListSuccess(usersList));
    yield put(actionUsersListLoadingSuccess());

    const route = yield select((state: TStore) => state.router.location.pathname);
    if (route !== Routes.users) {
      yield put(actionUserListPageSizeRequest(15));
    }
  } catch (e) {
    yield put(actionUsersListFailure());
    yield put(actionUsersListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaUserUpdate(action: TActionUserUpdateRequest): unknown {
  try {
    const response = yield call(apiUserUpdate, action.payload);

    yield all([put(actionUserUpdateSuccess()), put(actionUserUpdateReset())]);
    showNotification("success", "Aggiornato!", "Utente modificato con successo");

    const loggedUser = yield select(selectorLoggedUser);
    if (loggedUser.id == action.payload.id) {
      yield all([
        put(actionUserProfileUpdateSuccess(response.data.data)),
        put(actionPermissionListRequest({})),
      ]);
    }
  } catch (e) {
    yield put(actionUserUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaUserDelete(action: TActionUserDeleteRequest): unknown {
  try {
    yield call(apiUserDelete, action.payload);

    yield all([put(actionUserDeleteSuccess(action.payload)), put(actionUserDeleteReset())]);
    showNotification("success", "Eliminato!", "Utente eliminato con successo");
  } catch (e) {
    yield put(actionUserDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaUserAvatar(action: TActionUserAvatarRequest): unknown {
  try {
    const response = yield call(apiUserAvatar, action.payload);

    const user_id = yield select((state: TStore) => state.auth.user?.id);
    if (response.data.data.id == user_id) {
      yield put(actionUserAvatarSuccess(response.data.data.avatar));
    } else {
      yield put(actionUserAvatarSuccess());
    }

    yield all([put(actionUserAvatarReset())]);
    showNotification("success", "Aggiornato!", "Nuovo Avatar caricato correttamente");
  } catch (e) {
    yield put(actionUserAvatarFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaUserDocumentUpload(action: TActionUserDocumentUploadRequest): unknown {
  try {
    const response = yield call(apiUserDocumentUpload, action.payload);

    yield all([put(actionUserDocumentUploadSuccess()), put(actionUserDocumentUploadReset())]);
    showNotification("success", "Aggiornato!", "Documento caricato con successo");
  } catch (e) {
    yield put(actionUserDocumentUploadFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaCustomStyleOptionsList(action: TActionCustomStylesOptionListRequest): unknown {
  try {
    const response: AxiosResponse<TAPIResponse> = yield call(apiCustomStyleOptionList);

    //@ts-ignore
    yield put(actionCustomStylesOptionListSuccess(JSON.parse(response.data.json)));
  } catch (e) {
    yield put(actionCustomStylesOptionListFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaCustomStyleOptionsUpdate(
  action: TActionCustomStylesOptionUpdateRequest,
): unknown {
  try {
    //TODO QUNDO ESISTERA API TOGLIERE IL COMMENTO QUI SOTTO
    const response = yield call(apiCustomStyleOptionUpdate, {
      json: action.payload,
    });

    yield console.log("entro quì");
    yield all([
      put(actionCustomStyleOptionUpdateSuccess(action.payload)),
      put(actionCustomStyleOptionUpdateReset()),
    ]);
    //showNotification("success", "Aggiornato!", "Opzioni aggiornate con successo");
  } catch (e) {
    yield put(actionCustomStyleOptionUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
