/** @format */

import { Row, Col, Collapse, List } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../../constants/api";
import { selectorDepartmentList } from "../../../../redux-modules/selectors/selectorsDepartments";
import { selectorGroupsList } from "../../../../redux-modules/selectors/selectorsGroups";
import { BaseColor } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { hexToRgb } from "../../../../utils/styles";
import UIModal from "../../../ui/Modal";
import { UIText } from "../../../ui/Text";
const { Panel } = Collapse;

interface Props {
  isModalVisible: boolean;
  closeModal: () => void;
  selectedGroups: string[];
  selectedDepartments: string[];
  selectedDate: moment.Moment;
}

interface DateErrors {
  [date: string]: string[];
}
export const CheckShiftModal = ({
  isModalVisible,
  closeModal,
  selectedGroups,
  selectedDepartments,
  selectedDate,
}: Props): JSX.Element => {
  const [errors, setErrors] = useState<DateErrors>({});
  const groups = useSelector(selectorGroupsList);
  const departments = useSelector(selectorDepartmentList);

  useEffect(() => {
    apiClient
      .post(API_ENDPOINTS.SHIFTS + "/verify", {
        group_ids: selectedGroups.length === 0 ? groups.data.map(e => e.id) : selectedGroups,
        department_ids:
          selectedDepartments.length === 0 ? departments.map(e => e.id) : selectedDepartments,
        date_from: moment(selectedDate).startOf("week").format("YYYY-MM-DD"),
        date_to: moment(selectedDate).endOf("week").format("YYYY-MM-DD"),
      })
      .then(res => setErrors(res.data));
    if (isModalVisible) {
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (!!errors) console.log(errors);
  }, [errors]);
  return (
    <UIModal
      title={"Verifica Turni"}
      visible={isModalVisible}
      width={700}
      cancelButtonProps={{ style: { borderRadius: "7px" } }}
      onCancel={closeModal}
      onOk={closeModal}
    >
      <Row>
        <Col>
          <UIText>Turni non assegnati per i seguenti utenti:</UIText>
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col span={24}>
          <CustomCollapse accordion>
            {Object.keys(errors).length &&
              sortDatesString(Object.keys(errors)).map(date => (
                <Panel
                  header={
                    moment(date).format("DD/MM/YYYY") + ` (${errors[date].length} Dipendenti)`
                  }
                  key={date}
                >
                  <List
                    size="small"
                    bordered
                    // @ts-ignore
                    dataSource={errors[date]}
                    renderItem={item => <List.Item>{item}</List.Item>}
                  />
                </Panel>
              ))}
          </CustomCollapse>
        </Col>
      </Row>
    </UIModal>
  );
};

const sortDatesString = (dates: string[]): string[] =>
  dates.sort((a, b) => {
    const newA = a.split("/").reverse().join("-");
    const newB = b.split("/").reverse().join("-");
    return +new Date(newA) - +new Date(newB);
  });

const CustomCollapse = styled(Collapse)`
  border-radius: 7px;
  //background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.20"}!important;
  background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.25"}) !important;

  .ant-collapse-item:not(.ant-collapse-item-active):last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: none;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse > .ant-collapse-item-active:last-child {
    border-radius: none;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse-item-active:last-child,
  .ant-collapse-item-active:last-child > .ant-collapse-header {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse-content {
    max-height: 180px;
    overflow-y: auto;
  }
  .ant-list-bordered {
    border: none;
  }
`;
