/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import {
  actionPresenceTypeCreateSuccess,
  actionPresenceTypeCreateReset,
  actionPresenceTypeCreateFailure,
  actionPresenceTypeListLoadingRequest,
  actionPresenceTypeListLoadingSuccess,
  actionPresenceTypeListFailure,
  actionPresenceTypeListLoadingFailure,
  actionPresenceTypeUpdateSuccess,
  actionPresenceTypeUpdateReset,
  actionPresenceTypeUpdateFailure,
  actionPresenceTypeDeleteSuccess,
  actionPresenceTypeDeleteReset,
  actionPresenceTypeDeleteFailure,
  actionPresenceTypeListSuccess,
} from "../actions/actionsPresenceTypes";
import {
  TActionPresenceTypeCreateRequest,
  TActionPresenceTypeListRequest,
  TActionPresenceTypeUpdateRequest,
  TActionPresenceTypeDeleteRequest,
} from "../declarations/actions/presenceTypes";
import { TPresenceType } from "../declarations/maps/presenceTypes";
import {
  apiPresenceTypeCreate,
  apiPresenceTypeUpdate,
  apiPresenceTypeDelete,
  apiPresenceTypeList,
} from "../../api/apiPresenceTypes";

export function* sagaPresenceTypeCreate(action: TActionPresenceTypeCreateRequest): unknown {
  try {
    const response = yield call(apiPresenceTypeCreate, action.payload);

    yield put(actionPresenceTypeCreateSuccess(response.data.id));
    yield put(actionPresenceTypeCreateReset());
    showNotification("success", "Creato!", "Tipologia Turno creata con successo");
  } catch (e) {
    yield put(actionPresenceTypeCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaPresenceTypeList(action: TActionPresenceTypeListRequest): unknown {
  try {
    yield put(actionPresenceTypeListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TPresenceType[]>> = yield call(apiPresenceTypeList);

    yield put(actionPresenceTypeListSuccess(response.data.data));
    yield put(actionPresenceTypeListLoadingSuccess());
  } catch (e) {
    yield put(actionPresenceTypeListFailure());
    yield put(actionPresenceTypeListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaPresenceTypeUpdate(action: TActionPresenceTypeUpdateRequest): unknown {
  try {
    yield call(apiPresenceTypeUpdate, action.payload);

    yield all([put(actionPresenceTypeUpdateSuccess()), put(actionPresenceTypeUpdateReset())]);
    showNotification("success", "Aggiornato!", "Tipologia Turno modificata con successo");
  } catch (e) {
    yield put(actionPresenceTypeUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaPresenceTypeDelete(action: TActionPresenceTypeDeleteRequest): unknown {
  try {
    yield call(apiPresenceTypeDelete, action.payload);

    yield all([
      put(actionPresenceTypeDeleteSuccess(action.payload)),
      put(actionPresenceTypeDeleteReset()),
    ]);
    showNotification("success", "Eliminato!", "Tipologia Turno eliminata con successo");
  } catch (e) {
    yield put(actionPresenceTypeDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
