/** @format */

import {
  TActionPermissionListRequest,
  TActionPermissionListSuccess,
  TActionPermissionListFailure,
  TActionPermissionListLoadingRequest,
  TActionPermissionListLoadingSuccess,
  TActionPermissionListLoadingFailure,
} from "../declarations/actions/permission";
import { TActionType } from "../declarations/actionTypes";
import { TAPIPermissionListRequest } from "../declarations/apis/permissions";
import { TPermission } from "../declarations/maps/permissions";

//List
export const actionPermissionListRequest = (
  payload: TAPIPermissionListRequest,
): TActionPermissionListRequest => ({
  type: TActionType.permissionListRequest,
  payload,
});

export const actionPermissionListSuccess = (
  payload: TPermission[],
): TActionPermissionListSuccess => ({
  type: TActionType.permissionListSuccess,
  payload,
});

export const actionPermissionListFailure = (): TActionPermissionListFailure => ({
  type: TActionType.permissionListFailure,
});

//---

//Loading

export const actionPermissionListLoadingRequest = (): TActionPermissionListLoadingRequest => ({
  type: TActionType.permissionListLoadingRequest,
});

export const actionPermissionListLoadingSuccess = (): TActionPermissionListLoadingSuccess => ({
  type: TActionType.permissionListLoadingSuccess,
});

export const actionPermissionListLoadingFailure = (): TActionPermissionListLoadingFailure => ({
  type: TActionType.permissionListLoadingFailure,
});
