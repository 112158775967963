/** @format */

import React, { useEffect } from "react";
import { Col, Row, Skeleton } from "antd";
import { UICard } from "../../ui/Card";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { DocumentTypesTab } from "./tabs/DocumentTypes";
import RoutedTabs, { RouteConfigObject } from "../../ui/RoutedTabs";
import { TabsProps } from "antd/lib/tabs";
import { SETTINGSTABS } from "../../../constants/settings";
import { GeneralInfoTab } from "./tabs/GeneralInfo";
import RequestTypesTab from "./tabs/RequestTypes";
import { useDispatch, useSelector } from "react-redux";
import { actionJustificativeListRequest } from "../../../redux-modules/actions/actionsJustificatives";
import ModelDetails from "./tabs/ModelDetails";
import TriggersTab from "./tabs/Triggers";
import { actionTriggerListRequest } from "../../../redux-modules/actions/actionsTrigger";
import PresenceTypes from "./tabs/PresenceTypes";
import {
  selectorJustificativeListLoading,
  selectorPresenceTypeListLoading,
  selectorTriggerListLoading,
} from "../../../redux-modules/selectors/selectorsUI";
import { actionPresenceTypeListRequest } from "../../../redux-modules/actions/actionsPresenceTypes";

const ViewSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const justificativesLoading = useSelector(selectorJustificativeListLoading);
  const triggersLoading = useSelector(selectorTriggerListLoading);
  const presenceTypeLoading = useSelector(selectorPresenceTypeListLoading);

  useEffect(() => {
    dispatch(actionTriggerListRequest({}));
    dispatch(actionPresenceTypeListRequest());
    dispatch(
      actionJustificativeListRequest({
        query: "",
        columns: [""],
      }),
    );
  }, []);

  const routeTabsConfig: RouteConfigObject = {
    triggers: {
      label: "Triggers Notifiche",
      component: TriggersTab,
      getRoute: (url: string): string => `${url}${SETTINGSTABS.TRIGGERS}`,
    },
    "tipologia-presenze": {
      label: "Tipologie Presenze",
      component: PresenceTypes,
      getRoute: (url: string): string => `${url}${SETTINGSTABS.PRESENCE_TYPES}`,
    },
    "campi-aggiuntivi": {
      label: "Campi Aggiuntivi",
      component: ModelDetails,
      getRoute: (url: string): string => `${url}${SETTINGSTABS.MODEL_DETAILS}`,
    },
    "tipologia-richiesta": {
      label: "Giustificativi",
      component: RequestTypesTab,
      getRoute: (url: string): string => `${url}${SETTINGSTABS.REQUEST_TYPES}`,
    },
    "tipologia-documento": {
      label: "Tipologia Documenti",
      component: DocumentTypesTab,
      getRoute: (url: string): string => `${url}${SETTINGSTABS.DOCUMENT_TYPES}`,
    },
    "/": {
      label: "Informazioni Generali",
      component: GeneralInfoTab,
      getRoute: (url: string): string => url,
    },
    /* shiftType: {
      label: "Tipologia Turni",
      component: Test,
      getRoute: (url: string): string => `${url}/shiftsType`,
    }, */
  };

  const tabsProps: TabsProps = {
    tabPosition: "left",
  };

  return (
    <>
      <Row>
        <Col>
          <UITitle level={2} color={BaseColor}>
            Configurazione
          </UITitle>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <UICard>
            <RoutedTabs
              tabsProps={tabsProps}
              routeConfig={routeTabsConfig}
              basePath="/configurazione"
            />
          </UICard>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(ViewSettings);
