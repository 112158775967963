/** @format */

import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";

interface Props extends InputProps {}

export const UIInput = styled(Input)<Props>`
  border-radius: 7px;

  .ant-input-affix-wrapper {
    border-radius: 7px !important;
  }
  .ant-input-password {
    border-radius: 7px !important;
  }
`;
