/** @format */

import React, { FC, useState } from "react";
import { Action } from "redux";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { DeleteOutlined, RedoOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { UIText } from "../../ui/Text";
import { UITitle } from "../../ui/Title";
import UIModal from "../../ui/Modal";
import { UIInput } from "../../ui/Input";
import { BaseColor } from "../../../theme/maps/Colors";

interface Props {
  actionToDispatch: () => Action | void;
  removeButtonLabel: string;
  title: string;
  text: string;
  disable?: boolean;
  enable?: boolean;
}
export const DeleteCard: FC<Props> = ({
  actionToDispatch,
  removeButtonLabel,
  title,
  text,
  disable = false,
  enable = false,
}: Props): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [removeText, setRemoveText] = useState("");
  const handleRemove = (): Action | void => actionToDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (
      removeText.trim().toLowerCase() === (disable ? "disabilita" : enable ? "abilita" : "elimina")
    ) {
      handleRemove();
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <UIModal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        okText={disable ? "Disabilita" : enable ? "Abilita" : "Elimina"}
        okButtonProps={{
          style: enable
            ? { borderRadius: "7px", backgroundColor: BaseColor, borderColor: BaseColor }
            : { borderRadius: "7px", backgroundColor: "#e00102", borderColor: "#ac0000" },
        }}
        cancelButtonProps={{ style: { borderRadius: "7px" } }}
        onCancel={handleCancel}
      >
        <UIText>
          Per confermare l'operzione digita{" "}
          {disable ? "disabilita" : enable ? "abilita" : "elimina"}:
        </UIText>
        <UIInput onChange={(e): void => setRemoveText(e.target.value)} />
      </UIModal>
      <UICard>
        <Row>
          <Col>
            <UITitle level={5}>{title}</UITitle>
          </Col>
        </Row>
        <Row justify="space-between" align="middle">
          <Col>
            <UIText>{text}</UIText>
          </Col>
          <Col>
            <UIButton
              onClick={showModal}
              style={{ backgroundColor: "#e00102", borderColor: "transparent", color: "#fff" }}
              hoverBgColor="#da3233"
            >
              {enable ? <RedoOutlined /> : <DeleteOutlined />}
              {removeButtonLabel}
            </UIButton>
          </Col>
        </Row>
      </UICard>
    </>
  );
};
