/** @format */

import { Row, Col, Select, Form, Skeleton } from "antd";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { TModel } from "../../../../constants/maps/BackendEnums";
import { TModelDetail } from "../../../../redux-modules/declarations/maps/modelDetails";
import { TStore } from "../../../../redux-modules/declarations/store";
import { selectorDepartmentList } from "../../../../redux-modules/selectors/selectorsDepartments";
import { selectorModelDetailsByModel } from "../../../../redux-modules/selectors/selectorsModelDetails";
import { UIInput } from "../../../ui/Input";
import { UISelect } from "../../../ui/Select";
const { Option } = Select;

interface Props {
  groups: [];
  locations: [];
}

export const ProfessionalInfoTab: FC<Props> = ({ groups, locations }: Props): JSX.Element => {
  const details: TModelDetail[] = useSelector((store: TStore) =>
    selectorModelDetailsByModel(store, TModel.User, true),
  );
  const departments = useSelector(selectorDepartmentList);
  return (
    <>
      <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="location"
            label="Sede predefinita"
            extra="seleziona la sede dell'utente"
            rules={[{ required: true, message: "Inserisci la sede dell'utente!" }]}
          >
            <UISelect>
              {locations.map(location => (
                //@ts-ignore
                <Option key={location.id} value={location.id}>
                  {
                    //@ts-ignore
                    location.name
                  }
                </Option>
              ))}
            </UISelect>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name="department"
            label="Reparto"
            extra="seleziona il reparto dell'utente"
            rules={[{ required: true, message: "Inserisci il reparto dell'utente!" }]}
          >
            <UISelect>
              {departments.map(department => (
                //@ts-ignore
                <Option key={department.id} value={department.id}>
                  {
                    //@ts-ignore
                    department.name
                  }
                </Option>
              ))}
            </UISelect>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col md={12}>
          {!groups.length && <Skeleton.Input active={true} size={"default"} />}
          {!!groups.length && (
            <Form.Item
              name="groups"
              label="Gruppo"
              extra="seleziona il gruppo dell'utente"
              rules={[{ required: false, message: "Inserisci il gruppo  dell'utente!" }]}
            >
              <UISelect mode="multiple">
                {groups.map(group => (
                  //@ts-ignore
                  <Option key={group.id} value={group.id}>
                    {
                      //@ts-ignore
                      group.name
                    }
                  </Option>
                ))}
              </UISelect>
            </Form.Item>
          )}
        </Col>
      </Row>
      {details.map(detail => (
        <Row gutter={[12, 0]}>
          <Col md={12}>
            <Form.Item
              name={"detail?" + detail.id}
              label={detail.field_name.charAt(0).toUpperCase() + detail.field_name.slice(1)}
              required={detail.is_required}
            >
              <UIInput
                //@ts-ignore
                type={detail.column_type_name}
              />
            </Form.Item>
          </Col>
        </Row>
      ))}
    </>
  );
};
