/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaNotificationDelete,
  sagaNotificationList,
  sagaNotificationRead,
} from "../sagas/sagasNotifications";

export function* watcherNotifications(): unknown {
  yield takeLatest(TActionType.notificationListRequest, sagaNotificationList);
  yield takeLatest(TActionType.notificationDeleteRequest, sagaNotificationDelete);
  yield takeLatest(TActionType.notificationReadRequest, sagaNotificationRead);
  yield takeLatest(TActionType.notificationReadSuccess, sagaNotificationList);
  yield takeLatest(TActionType.notificationDeleteSuccess, sagaNotificationList);
}
