/** @format */

import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { productAssignedListColumns } from "../../../../constants/tables/productAssignedListColumns";
import { TWarehouse } from "../../../../redux-modules/declarations/maps/warehouses";
import { useDebounce } from "../../../../redux-modules/hooks/useDebounce";
import { AddProductAssignedDrawer } from "../../../layout/SideDialogs/AddProductAssigned";
import { UIButton } from "../../../ui/Button";
import { UISearch } from "../../../ui/SearchInput";
import { UITable } from "../../../ui/Table";

/** @format */
interface Props {
  assigned_products: unknown[];
  warehouseInfo: TWarehouse;
  updateData: () => void;
}
export const WarehouseAssignedTab = ({
  assigned_products,
  warehouseInfo,
  updateData,
}: Props): JSX.Element => {
  const [showingAddModal, setShowingAddModal] = useState(false);
  const [searchingVal, setSearchingVal] = useState("");
  const [filteredTable, setFilteredTable] = useState<unknown[]>();

  const handleSearch = (value: string, list: unknown[] | false = false): void => {
    const filterTable = list
      ? list.filter(o =>
          // @ts-ignore
          Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
        )
      : assigned_products.filter(o =>
          // @ts-ignore
          Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
        );
    setFilteredTable(filterTable);
  };

  let parolaCercata = useDebounce(searchingVal, 500);
  useEffect(() => {
    handleSearch(searchingVal);
  }, [parolaCercata]);

  const openModal = () => setShowingAddModal(true);
  const closeModal = () => setShowingAddModal(false);
  return (
    <>
      <AddProductAssignedDrawer
        warehouse_id={warehouseInfo.id}
        updateData={updateData}
        visible={showingAddModal}
        onClose={closeModal}
      />
      <Row
        justify="space-between"
        align="middle"
        gutter={[16, 16]}
        style={{ marginBottom: "10px" }}
      >
        <Col>
          <UISearch
            placeholder={"Cerca assegnazione"}
            //onSearch={(e): void => handleSearch(e)}
            //allowClear
            onChange={(e): void => setSearchingVal(e.target.value)}
          />
        </Col>
        <Col>
          <UIButton type="primary" onClick={openModal}>
            + Aggiungi assegnazione
          </UIButton>
        </Col>
      </Row>
      <UITable
        size="middle"
        data-cy="product-assigned-list-table"
        columns={[...productAssignedListColumns({ callback: updateData })]}
        dataSource={filteredTable || assigned_products}
      />
    </>
  );
};
