/** @format */

import {
  TActionPresenceTypeListFailure,
  TActionPresenceTypeListRequest,
  TActionPresenceTypeListSuccess,
} from "../declarations/actions/presenceTypes";
import { TActionType } from "../declarations/actionTypes";
import { TPresenceTypes } from "../declarations/maps/presenceTypes";

type Actions =
  | TActionPresenceTypeListRequest
  | TActionPresenceTypeListSuccess
  | TActionPresenceTypeListFailure;

export const reducerPresenceTypes = (
  prevStore: TPresenceTypes = { presenceTypeList: [] },
  action: Actions,
): TPresenceTypes => {
  switch (action.type) {
    case TActionType.presenceTypeListRequest:
      return { ...prevStore, presenceTypeList: [] };
    case TActionType.presenceTypeListSuccess:
      return { ...prevStore, presenceTypeList: action.payload };
    case TActionType.presenceTypeListFailure:
      return { ...prevStore, presenceTypeList: [] };
    default:
      return prevStore;
  }
};
