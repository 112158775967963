/** @format */

import {
  TActionTravelListRequest,
  TActionTravelListSuccess,
  TActionTravelListFailure,
  TActionTravelListLoadingRequest,
  TActionTravelListLoadingSuccess,
  TActionTravelListLoadingFailure,
  TActionTravelCreateRequest,
  TActionTravelCreateSuccess,
  TActionTravelCreateFailure,
  TActionTravelUpdateRequest,
  TActionTravelUpdateSuccess,
  TActionTravelUpdateFailure,
  TActionTravelUpdateReset,
  TActionTravelDeleteRequest,
  TActionTravelDeleteSuccess,
  TActionTravelDeleteFailure,
  TActionTravelDeleteReset,
} from "../declarations/actions/travels";
import { TAction, TActionType } from "../declarations/actionTypes";
import {
  TTravelList,
  TTravelCreateRequest,
  TTravelUpdateRequest,
} from "../declarations/maps/travels";

//List
export const actionTravelListRequest = (
  date_from: string,
  date_to: string,
): TActionTravelListRequest => ({
  type: TActionType.travelListRequest,
  payload: { date_from, date_to },
});

export const actionTravelListSuccess = (payload: TTravelList): TActionTravelListSuccess => ({
  type: TActionType.travelListSuccess,
  payload,
});

export const actionTravelListFailure = (): TActionTravelListFailure => ({
  type: TActionType.travelListFailure,
});

//---

//Loading

export const actionTravelListLoadingRequest = (): TActionTravelListLoadingRequest => ({
  type: TActionType.travelListLoadingRequest,
});

export const actionTravelListLoadingSuccess = (): TActionTravelListLoadingSuccess => ({
  type: TActionType.travelListLoadingSuccess,
});

export const actionTravelListLoadingFailure = (): TActionTravelListLoadingFailure => ({
  type: TActionType.travelListLoadingFailure,
});

// ---
//Create
export const actionTravelCreateRequest = (
  payload: TTravelCreateRequest,
): TActionTravelCreateRequest => ({ type: TActionType.travelCreateRequest, payload });

export const actionTravelCreateSuccess = (payload: string): TActionTravelCreateSuccess => ({
  type: TActionType.travelCreateSuccess,
  payload,
});
export const actionTravelCreateFailure = (): TActionTravelCreateFailure => ({
  type: TActionType.travelCreateFailure,
});
export const actionTravelCreateReset = (): TAction => ({
  type: TActionType.travelCreateReset,
});
// ---
// Update
export const actionTravelUpdateRequest = (
  payload: TTravelUpdateRequest,
): TActionTravelUpdateRequest => ({
  type: TActionType.travelUpdateRequest,
  payload,
});

export const actionTravelUpdateSuccess = (): TActionTravelUpdateSuccess => ({
  type: TActionType.travelUpdateSuccess,
});

export const actionTravelUpdateFailure = (): TActionTravelUpdateFailure => ({
  type: TActionType.travelUpdateFailure,
});

export const actionTravelUpdateReset = (): TActionTravelUpdateReset => ({
  type: TActionType.travelUpdateReset,
});

// ---
// Delete
export const actionTravelDeleteRequest = (payload: string): TActionTravelDeleteRequest => ({
  type: TActionType.travelDeleteRequest,
  payload,
});

export const actionTravelDeleteSuccess = (payload: string): TActionTravelDeleteSuccess => ({
  type: TActionType.travelDeleteSuccess,
  payload,
});

export const actionTravelDeleteFailure = (): TActionTravelDeleteFailure => ({
  type: TActionType.travelDeleteFailure,
});

export const actionTravelDeleteReset = (): TActionTravelDeleteReset => ({
  type: TActionType.travelDeleteReset,
});

// ---
