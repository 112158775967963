/** @format */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../constants/api";
import apiClient from "../../utils/apiClient";
import { showNotification } from "../../utils/showNotification";
import { actionGroupsListRequest } from "../actions/actionsGroups";
import { actionLocationListRequest } from "../actions/actionsLocations";
import { actionShiftListRequest } from "../actions/actionsShifts";
import { TUser } from "../declarations/maps/auth";
import { TDepartment } from "../declarations/maps/departments";
import { TGroup } from "../declarations/maps/groups";
import {
  TDepatmentShiftDetail,
  TGroupShiftDetail,
  TShift,
  TUserShiftDetail,
} from "../declarations/maps/shifts";
import { TShiftType } from "../declarations/maps/shiftTypes";
import { TStore } from "../declarations/store";
import { selectorDepartmentList } from "../selectors/selectorsDepartments";
import { selectorGroupsList } from "../selectors/selectorsGroups";
import {
  selectorShiftList,
  selectorShiftListByGroupAndDepartment,
} from "../selectors/selectorsShifts";
import { selectorShiftTypeList } from "../selectors/selectorsShiftTypes";
import {
  selectorDepartmentListLoading,
  selectorGroupsListLoading,
  selectorShiftListLoading,
  selectorShiftTypeListLoading,
} from "../selectors/selectorsUI";

interface Props {
  format: "Ore" | "Minuti";
  date_from: string;
  date_to: string;
  selectedDepartmentId: string[];
  selectedGroupId: string[];
}

interface Return {
  groupList: TGroup[];
  departmentList: TDepartment[];
  shiftTypeList: TShiftType[];
  shiftList: TShift[];
  isLoading: boolean;
  filteredGroupList: TGroup[];
  filteredDepartmentList: TDepartment[];
  userList: TUser[];
  fetchCountersData: () => void;
  replyShifts: (
    users_id: string[],
    start_date_from: string,
    start_date_to: string,
    end_date_from: string,
    end_date_to: string,
    group_id?: string,
    department_id?: string,
  ) => void;
  isCounterLoading: boolean;
  userShiftDetails: TUserShiftDetail[];
  groupShiftDetails: TGroupShiftDetail[];
  departmentShiftDetails: TDepatmentShiftDetail[];
}

export const useShift = ({
  format,
  date_from,
  date_to,
  selectedDepartmentId,
  selectedGroupId,
}: Props): Return => {
  const dispatch = useDispatch();
  const groupList = useSelector(selectorGroupsList)?.data || [];
  const departmentList = useSelector(selectorDepartmentList);
  const shiftTypeList = useSelector(selectorShiftTypeList);
  const [userList, setUserList] = useState<TUser[]>();
  const shiftList = useSelector((store: TStore) =>
    selectorShiftListByGroupAndDepartment(store, selectedGroupId, selectedDepartmentId),
  );
  const helperShiftList = useSelector(selectorShiftList);
  const [isLoading, setIsLoading] = useState(true);
  const [isCounterLoading, setIsCounterLoading] = useState(true);
  const [usersShiftDetails, setUsersShiftDetails] = useState<TUserShiftDetail[]>([]);
  const [groupShiftDetails, setGroupShiftDetails] = useState<TGroupShiftDetail[]>([]);
  const [userAbsences, setUserAbsences] = useState<TShift[]>([]);
  const [departmentShiftDetails, setDepartmentShiftDetails] = useState<TDepatmentShiftDetail[]>([]);
  const isGroupLoading = useSelector(selectorGroupsListLoading);
  const isDepartmentLoading = useSelector(selectorDepartmentListLoading);
  const isShiftTypeLoading = useSelector(selectorShiftTypeListLoading);
  const isShiftLoading = useSelector(selectorShiftListLoading);

  useEffect(() => {
    //@ts-ignore
    dispatch(actionGroupsListRequest({ page_size: 100 }));
    apiClient.get(API_ENDPOINTS.USERS_LIST + "?all=true").then(res => {
      setUserList(res.data.data);
    });
  }, []);

  useEffect(() => {
    dispatch(actionShiftListRequest({ date_from, date_to }));
    dispatch(actionLocationListRequest({}));
  }, [date_from, date_to]);

  /*useEffect(() => {
    if (shiftList.length > 0) {
      fetchCountersData();
    }
  }, [shiftList.length]);*/

  useEffect(() => {
    if (helperShiftList.length > 0) {
      fetchCountersData();
    }
  }, [helperShiftList.length]);

  useEffect(() => {
    if (
      isGroupLoading ||
      isDepartmentLoading ||
      isShiftTypeLoading ||
      isShiftLoading ||
      !userList
    ) {
      setIsLoading(true);
    } else {
      if (!isShiftLoading) setIsLoading(false);
    }
  }, [isGroupLoading, isDepartmentLoading, isShiftTypeLoading, isShiftLoading, userList]);

  useEffect(() => {
    if (userList) {
      fetchCountersData();
    }
  }, [date_from, userList]);

  useEffect(() => {
    let groupShiftDetailsCur: TGroupShiftDetail[] = [];

    let departmentsShiftDetailsCur: TDepatmentShiftDetail[] = [];

    if (usersShiftDetails && userList) {
      setIsCounterLoading(true);
      const gruppiSelezionati = selectedGroupId.length
        ? groupList.filter(group => selectedGroupId.includes(group.id)).map(e => e.id)
        : groupList.map(e => e.id);

      const repartiSelezionati = selectedDepartmentId.length
        ? departmentList
            .filter(department => selectedDepartmentId.includes(department.id))
            .map(e => e.id)
        : departmentList.map(e => e.id);

      usersShiftDetails.forEach(userShiftDetail => {
        if (repartiSelezionati.includes(userShiftDetail.department_id)) {
          gruppiSelezionati.forEach(groupId => {
            if (userShiftDetail.group_ids.includes(groupId)) {
              const y = departmentsShiftDetailsCur.find(
                e => e.group_id === groupId && e.department_id === userShiftDetail.department_id,
              );

              if (y) {
                y.minuti_da_contratto += userShiftDetail.minuti_da_contratto;
                y.minuti_assegnati += userShiftDetail.minuti_assegnati;
                y.delta_minuti += userShiftDetail.delta_minuti;
                y.ore_da_contratto = minutesToHours(y.minuti_da_contratto);
                y.ore_assegnate = minutesToHours(y.minuti_assegnati);
                y.delta_ore = minutesToHours(y.delta_minuti);
              } else {
                departmentsShiftDetailsCur.push({
                  group_id: groupId,
                  department_id: userShiftDetail.department_id,
                  minuti_da_contratto: userShiftDetail.minuti_da_contratto,
                  ore_da_contratto: minutesToHours(userShiftDetail.minuti_da_contratto),
                  minuti_assegnati: userShiftDetail.minuti_assegnati,
                  ore_assegnate: minutesToHours(userShiftDetail.minuti_assegnati),
                  delta_minuti: userShiftDetail.delta_minuti,
                  delta_ore: minutesToHours(userShiftDetail.delta_minuti),
                });
              }
            }
          });
        }
      });

      departmentsShiftDetailsCur.forEach(departmentShiftDetail => {
        const x = groupShiftDetailsCur.find(e => e.group_id === departmentShiftDetail.group_id);
        if (x) {
          x.minuti_da_contratto += departmentShiftDetail.minuti_da_contratto;
          x.minuti_assegnati += departmentShiftDetail.minuti_assegnati;
          x.delta_minuti += departmentShiftDetail.delta_minuti;
          x.ore_da_contratto = minutesToHours(x.minuti_da_contratto);
          x.ore_assegnate = minutesToHours(x.minuti_assegnati);
          x.delta_ore = minutesToHours(x.delta_minuti);
        } else {
          groupShiftDetailsCur.push({
            group_id: departmentShiftDetail.group_id,
            minuti_da_contratto: departmentShiftDetail.minuti_da_contratto,
            ore_da_contratto: minutesToHours(departmentShiftDetail.minuti_da_contratto),
            minuti_assegnati: departmentShiftDetail.minuti_assegnati,
            ore_assegnate: minutesToHours(departmentShiftDetail.minuti_assegnati),
            delta_minuti: departmentShiftDetail.delta_minuti,
            delta_ore: minutesToHours(departmentShiftDetail.delta_minuti),
          });
        }
      });
      setGroupShiftDetails(Object.values(groupShiftDetailsCur));
      setDepartmentShiftDetails(Object.values(departmentsShiftDetailsCur));
      setIsCounterLoading(false);
    }
  }, [usersShiftDetails, selectedGroupId, selectedDepartmentId]);

  const fetchCountersData = () => {
    setIsCounterLoading(true);
    setIsLoading(true);

    if (userList) {
      apiClient
        .post(API_ENDPOINTS.SHIFT_DETAILS, {
          user_ids: userList.map(user => user.id),
          date_from: date_from,
          date_to: date_to,
        })
        .then(res => {
          setUsersShiftDetails(res.data);
          setIsCounterLoading(false);

          apiClient
            .get(API_ENDPOINTS.SHIFT_ABSENCES, {
              params: {
                date_from: date_from,
                date_to: date_to,
              },
            })
            .then(res => {
              setUserAbsences(res.data);
              if (!isShiftLoading) setIsLoading(false);
            })
            .catch(err => {
              setIsLoading(false);
            });
        });
    } else {
      setIsCounterLoading(false);
      if (!isShiftLoading) setIsLoading(false);
    }
  };

  const replyShifts = (
    user_ids: string[],
    start_date_from: string,
    start_date_to: string,
    end_date_from: string,
    end_date_to: string,
    group_id?: string,
    department_id?: string,
  ): void => {
    apiClient
      .post(API_ENDPOINTS.SHIFTS + "/repeat", {
        user_ids,
        department_id,
        group_id,
        start_date_from,
        start_date_to,
        end_date_from,
        end_date_to,
      })
      .then(res => {
        showNotification("success", "Turni Replicati", "Turni replicati correttamente");
        if (date_from === end_date_from && date_to === end_date_to) {
          dispatch(actionShiftListRequest({ date_from, date_to }));
        }
      });
  };

  return {
    groupList,
    departmentList,
    shiftTypeList,
    shiftList: [...shiftList, ...userAbsences],
    isLoading,
    filteredGroupList: selectedGroupId.length
      ? groupList.filter(group => selectedGroupId.includes(group.id))
      : groupList,
    filteredDepartmentList: selectedDepartmentId.length
      ? departmentList.filter(department => selectedDepartmentId.includes(department.id))
      : departmentList,
    userList: userList || [],
    fetchCountersData: fetchCountersData,
    isCounterLoading,
    userShiftDetails: usersShiftDetails,
    groupShiftDetails: groupShiftDetails,
    departmentShiftDetails: departmentShiftDetails,
    replyShifts: replyShifts,
  };
};

const minutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const minutes_ = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes_.toString().padStart(2, "0")}`;
};
