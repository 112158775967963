/** @format */

import React, { memo, FC } from "react";
import { Routes } from "../../utils/Routes";
import { useSelector } from "react-redux";
import { selectorAuthenticated } from "../../redux-modules/selectors/selectorsAuth";
import { useHistory } from "react-router";

interface Props {
  noStyle?: boolean;
  component: FC;
}

export const RenderRouteGuest: FC<Props> = memo(
  ({ component: Component, noStyle = false }: Props): JSX.Element => {
    const history = useHistory();
    const isAuthenticated = useSelector(selectorAuthenticated);

    if (!!isAuthenticated) history.push(Routes.home);

    return (
      <>
        <div style={!!noStyle ? { height: "100%" } : { padding: "120px 0px" }}>
          <Component />
        </div>
      </>
    );
  },
);
RenderRouteGuest.displayName = "RenderRouteGuest";
