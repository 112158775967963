/** @format */

import {
  TActionExpenseListFailure,
  TActionExpenseListRequest,
  TActionExpenseListSuccess,
} from "../declarations/actions/expenses";
import { TActionType } from "../declarations/actionTypes";
import { TExpenses } from "../declarations/maps/expenses";

type Actions = TActionExpenseListRequest | TActionExpenseListSuccess | TActionExpenseListFailure;

export const reducerExpenses = (
  prevStore: TExpenses = { expenseList: [] },
  action: Actions,
): TExpenses => {
  switch (action.type) {
    case TActionType.expenseListRequest:
      return { ...prevStore, expenseList: [] };
    case TActionType.expenseListSuccess:
      return { ...prevStore, expenseList: action.payload };
    case TActionType.expenseListFailure:
      return { ...prevStore, expenseList: [] };
    default:
      return prevStore;
  }
};
