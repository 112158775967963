/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaDeviceCreate,
  sagaDeviceDelete,
  sagaDeviceList,
  sagaDeviceUpdate,
} from "../sagas/sagasDevices";

export function* watcherDevices(): unknown {
  yield takeLatest(TActionType.deviceCreateRequest, sagaDeviceCreate);
  yield takeLatest(TActionType.deviceListRequest, sagaDeviceList);
  yield takeLatest(TActionType.deviceDeleteRequest, sagaDeviceDelete);
  yield takeLatest(TActionType.deviceUpdateRequest, sagaDeviceUpdate);
}
