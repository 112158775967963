/** @format */

import { AxiosResponse } from "axios";
import { put, call } from "redux-saga/effects";
import { apiPermissionList } from "../../api/apiPermissions";
import { showNotification } from "../../utils/showNotification";
import {
  actionPermissionListLoadingRequest,
  actionPermissionListLoadingSuccess,
  actionPermissionListFailure,
  actionPermissionListLoadingFailure,
  actionPermissionListSuccess,
} from "../actions/actionsPermission";
import { TActionPermissionListRequest } from "../declarations/actions/permission";
import { TAPIResponse } from "../declarations/apis/general";
import { TGroup } from "../declarations/maps/groups";

export function* sagaPermissionList(action: TActionPermissionListRequest): unknown {
  try {
    yield put(actionPermissionListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TGroup[]>> = yield call(
      apiPermissionList,
      action.payload,
    );

    yield put(actionPermissionListLoadingSuccess());
    //@ts-ignore
    yield put(actionPermissionListSuccess(response.data.permissions));
  } catch (e) {
    yield put(actionPermissionListFailure());
    yield put(actionPermissionListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      Object.values(e.data.errors).join(`\n`),
    );
  }
}
