/** @format */

import { TGroup, TGroupComplete, TGroupForm } from "../../redux-modules/declarations/maps/groups";

export const utilityFormatApiGroup = (group: TGroup): TGroup => ({
  id: group.id,
  name: group.name,
  //@ts-ignore
  location: group.location || "",
  manager: group.manager || "",
  //@ts-ignore
  usersCount: group.usersCount || "",
});

export const utilityFormatApiGroupList = (list: TGroup[]): TGroup[] =>
  list.map(group => utilityFormatApiGroup(group));

export const utilityFormatFindList = (group: TGroupComplete): TGroupForm => ({
  id: group.id,
  name: group.name,
  manager: group.manager?.id,
  vicemanager: group.vicemanager?.id,
  users: group.users.map(e => e.id),
});
