/** @format */

import {
  TActionPresenceTypeListRequest,
  TActionPresenceTypeListSuccess,
  TActionPresenceTypeListFailure,
  TActionPresenceTypeListLoadingRequest,
  TActionPresenceTypeListLoadingSuccess,
  TActionPresenceTypeListLoadingFailure,
  TActionPresenceTypeCreateRequest,
  TActionPresenceTypeCreateSuccess,
  TActionPresenceTypeCreateFailure,
  TActionPresenceTypeUpdateRequest,
  TActionPresenceTypeUpdateSuccess,
  TActionPresenceTypeUpdateFailure,
  TActionPresenceTypeUpdateReset,
  TActionPresenceTypeDeleteRequest,
  TActionPresenceTypeDeleteSuccess,
  TActionPresenceTypeDeleteFailure,
  TActionPresenceTypeDeleteReset,
} from "../declarations/actions/presenceTypes";
import { TAction, TActionType } from "../declarations/actionTypes";
import {
  TPresenceTypeList,
  TPresenceTypeCreateRequest,
  TPresenceTypeUpdateRequest,
} from "../declarations/maps/presenceTypes";

//List
export const actionPresenceTypeListRequest = (): TActionPresenceTypeListRequest => ({
  type: TActionType.presenceTypeListRequest,
});

export const actionPresenceTypeListSuccess = (
  payload: TPresenceTypeList,
): TActionPresenceTypeListSuccess => ({
  type: TActionType.presenceTypeListSuccess,
  payload,
});

export const actionPresenceTypeListFailure = (): TActionPresenceTypeListFailure => ({
  type: TActionType.presenceTypeListFailure,
});

//---

//Loading

export const actionPresenceTypeListLoadingRequest = (): TActionPresenceTypeListLoadingRequest => ({
  type: TActionType.presenceTypeListLoadingRequest,
});

export const actionPresenceTypeListLoadingSuccess = (): TActionPresenceTypeListLoadingSuccess => ({
  type: TActionType.presenceTypeListLoadingSuccess,
});

export const actionPresenceTypeListLoadingFailure = (): TActionPresenceTypeListLoadingFailure => ({
  type: TActionType.presenceTypeListLoadingFailure,
});

// ---
//Create
export const actionPresenceTypeCreateRequest = (
  payload: TPresenceTypeCreateRequest,
): TActionPresenceTypeCreateRequest => ({ type: TActionType.presenceTypeCreateRequest, payload });

export const actionPresenceTypeCreateSuccess = (
  payload: string,
): TActionPresenceTypeCreateSuccess => ({
  type: TActionType.presenceTypeCreateSuccess,
  payload,
});
export const actionPresenceTypeCreateFailure = (): TActionPresenceTypeCreateFailure => ({
  type: TActionType.presenceTypeCreateFailure,
});
export const actionPresenceTypeCreateReset = (): TAction => ({
  type: TActionType.presenceTypeCreateReset,
});
// ---
// Update
export const actionPresenceTypeUpdateRequest = (
  payload: TPresenceTypeUpdateRequest,
): TActionPresenceTypeUpdateRequest => ({
  type: TActionType.presenceTypeUpdateRequest,
  payload,
});

export const actionPresenceTypeUpdateSuccess = (): TActionPresenceTypeUpdateSuccess => ({
  type: TActionType.presenceTypeUpdateSuccess,
});

export const actionPresenceTypeUpdateFailure = (): TActionPresenceTypeUpdateFailure => ({
  type: TActionType.presenceTypeUpdateFailure,
});

export const actionPresenceTypeUpdateReset = (): TActionPresenceTypeUpdateReset => ({
  type: TActionType.presenceTypeUpdateReset,
});

// ---
// Delete
export const actionPresenceTypeDeleteRequest = (
  payload: string,
): TActionPresenceTypeDeleteRequest => ({
  type: TActionType.presenceTypeDeleteRequest,
  payload,
});

export const actionPresenceTypeDeleteSuccess = (
  payload: string,
): TActionPresenceTypeDeleteSuccess => ({
  type: TActionType.presenceTypeDeleteSuccess,
  payload,
});

export const actionPresenceTypeDeleteFailure = (): TActionPresenceTypeDeleteFailure => ({
  type: TActionType.presenceTypeDeleteFailure,
});

export const actionPresenceTypeDeleteReset = (): TActionPresenceTypeDeleteReset => ({
  type: TActionType.presenceTypeDeleteReset,
});

// ---
