/** @format */

import React from "react";
import { Menu, Dropdown } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { UIText } from "../ui/Text";
import { BaseColor } from "../../theme/maps/Colors";
import { useDispatch } from "react-redux";
import { actionJustificativeDeleteRequest } from "../../redux-modules/actions/actionsJustificatives";
import { useHistory } from "react-router";
import { Routes } from "../../utils/Routes";

const RequestTypeListColumnsRenderer = (value: string): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onDelete = (): void => {
    dispatch(actionJustificativeDeleteRequest(value));
  };
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          history.push(Routes.settingsRequestTypeEdit.replace(":id_request_type", value));
        }}
        icon={<EditOutlined />}
      >
        Gestisci
      </Menu.Item>
      <Menu.Item key="2" onClick={onDelete} icon={<DeleteOutlined />}>
        Elimina
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <UIText css={"font-size: 24px; cursor: pointer;"} color={BaseColor}>
        ...
      </UIText>
    </Dropdown>
  );
};

export default RequestTypeListColumnsRenderer;
