/** @format */

import { Row, Col, DatePickerProps } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import { selectorDepartmentList } from "../../../../redux-modules/selectors/selectorsDepartments";
import { selectorGroupsList } from "../../../../redux-modules/selectors/selectorsGroups";
import { UIDatePicker } from "../../../ui/DatePicker";
import UIModal from "../../../ui/Modal";
import { UISelect } from "../../../ui/Select";
import { UIText } from "../../../ui/Text";
const { Option } = UISelect;

interface Props {
  isModalVisible: boolean;
  closeModal: () => void;
  userList: TUser[];
  onOk: (
    users_id: string[],
    start_date_from: string,
    start_date_to: string,
    end_date_from: string,
    end_date_to: string,
    group_id?: string,
    department_id?: string,
  ) => void;
}
export const RepeatShiftModal = ({
  isModalVisible,
  closeModal,
  userList,
  onOk,
}: Props): JSX.Element => {
  const groupList = useSelector(selectorGroupsList);
  const departmentList = useSelector(selectorDepartmentList);
  const [selectedGroup, setSelectedGroup] = useState<string>(
    groupList.data.length ? groupList.data[0].id : "",
  );
  const [selectedDepartment, setSelectedDepartment] = useState<string>();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment>(
    moment().subtract(1, "week"),
  );
  const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment>(moment());
  const [selectionDescription, setSelectionDescription] = useState("");

  useEffect(() => {
    if (!selectedUsers.length) {
      if (!selectedDepartment) {
        //ho selezionato solo il gruppo
        setSelectionDescription(
          'Tutto il gruppo "' + groupList.data.find(e => e.id == selectedGroup)?.name + '"',
        );
      } else {
        // ho selezionato dei reparti
        setSelectionDescription(
          'Il reparto "' +
            departmentList.find(e => e.id == selectedDepartment)?.name +
            '" del  gruppo "' +
            groupList.data.find(e => e.id == selectedGroup)?.name +
            '"',
        );
      }
    } else {
      setSelectionDescription(
        "Gli utenti: " +
          userList
            .filter(e => selectedUsers.includes(e.id))
            .map(e => e.surname + " " + e.name)
            .join(", ")
            .toString(),
      );
    }
  }, [selectedDepartment, selectedGroup, selectedUsers, groupList, departmentList, userList]);

  useEffect(() => {
    if (selectedUsers.length > 0) {
      setSelectedGroup("");
      setSelectedDepartment(undefined);
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (selectedGroup) {
      setSelectedUsers([]);
    }
  }, [selectedGroup]);

  const handleOk = (): void => {
    if (selectedGroup || selectedDepartment || selectedUsers.length > 0) {
      onOk(
        selectedUsers,
        selectedStartDate.clone().startOf("week").format("YYYY-MM-DD"),
        selectedStartDate.clone().endOf("week").format("YYYY-MM-DD"),
        selectedStartDate.clone().add(1, "week").startOf("week").format("YYYY-MM-DD"),
        selectedEndDate.clone().endOf("week").format("YYYY-MM-DD"),
        selectedGroup,
        selectedDepartment,
      );
    }
    closeModal();
  };

  const customFormat: DatePickerProps["format"] = value =>
    `${value.clone().startOf("week").format("DD/MM/YYYY")} - ${value
      .clone()
      .endOf("week")
      .format("DD/MM/YYYY")}`;

  useEffect(() => {
    if (!isModalVisible) {
      setSelectedGroup(groupList.data.length ? groupList.data[0].id : "");
      setSelectedDepartment(undefined);
      setSelectedUsers([]);
    }
  }, [isModalVisible]);
  return (
    <UIModal
      width={600}
      title={"Replica Turni"}
      visible={isModalVisible}
      onOk={handleOk}
      okText="Replica"
      okButtonProps={{
        style: { borderRadius: "7px" },
      }}
      cancelButtonProps={{ style: { borderRadius: "7px" } }}
      onCancel={closeModal}
    >
      <Row gutter={[8, 8]} style={{ marginBottom: "10px" }}>
        <Col span={8}>
          <Row>
            <Col>
              <UIText>Gruppo:</UIText>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <UISelect
                showSearch
                value={selectedGroup}
                style={{ width: "100%" }} //@ts-ignore
                onChange={value => setSelectedGroup(value)}
                filterOption={(input, option) =>
                  ((option!.children as unknown) as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {groupList.data.map(e => (
                  <Option key={e.id} value={e.id}>
                    {e.name}
                  </Option>
                ))}
              </UISelect>
            </Col>
          </Row>
        </Col>
        {selectedGroup && (
          <Col span={8}>
            <Row>
              <Col>
                <UIText>Reparto:</UIText>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <UISelect
                  showSearch
                  value={selectedDepartment}
                  placeholder="Tutti"
                  style={{ width: "100%" }} //@ts-ignore
                  onChange={value => setSelectedDepartment(value)}
                  filterOption={(input, option) =>
                    ((option!.children as unknown) as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option value={undefined}>Tutti</Option>
                  {departmentList.map(e => (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  ))}
                </UISelect>
              </Col>
            </Row>
          </Col>
        )}
        <Col span={selectedGroup ? 8 : 16}>
          <Row>
            <Col>
              <UIText>Utenti:</UIText>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <UISelect
                mode="multiple"
                showSearch
                value={selectedUsers}
                placeholder={"Tutti"}
                style={{ width: "100%" }}
                //@ts-ignore
                onChange={value => setSelectedUsers(value)}
                filterOption={(input, option) =>
                  ((option!.children as unknown) as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {userList.map(e => (
                  <Option key={e.id} value={e.id}>
                    {e.surname + " " + e.name}
                  </Option>
                ))}
              </UISelect>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[14, 14]}>
        <Col span={12}>
          <UIText>Settimana di origine:</UIText>
        </Col>
        <Col span={12}>
          <UIText>Fino alla settimana:</UIText>
        </Col>
      </Row>
      <Row gutter={[14, 14]}>
        <Col span={12}>
          <CustomWeekPicker
            value={selectedStartDate}
            //@ts-ignore
            onChange={(v: Moment | null) => setSelectedStartDate(v)}
            picker="week"
            format={customFormat}
          />
        </Col>
        <Col span={12}>
          <CustomWeekPicker
            value={selectedEndDate}
            //@ts-ignore
            onChange={(v: Moment | null) => setSelectedEndDate(v)}
            picker="week"
            format={customFormat}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "15px" }}>
        <Col span={24}>
          <UIText strong>Selezione corrente:</UIText>
          <br />
          <UIText>{selectionDescription}</UIText>
        </Col>
      </Row>
    </UIModal>
  );
};

const CustomWeekPicker = styled(UIDatePicker)`
  .ant-picker-cell-week {
    display: none !important;
  }

  [aria-label="empty cell"] {
    display: none;
  }
`;
