/** @format */

import { Col, Divider, Drawer, Form, Row, Skeleton } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../../constants/api";
import { TModel } from "../../../../constants/maps/BackendEnums";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import { TSizeUnit } from "../../../../redux-modules/declarations/maps/justificatives";
import { TModelDetail } from "../../../../redux-modules/declarations/maps/modelDetails";
import {
  TRequest,
  TRequestCreateRequest,
} from "../../../../redux-modules/declarations/maps/requests";
import { TStore } from "../../../../redux-modules/declarations/store";
import { selectorJustificativeList } from "../../../../redux-modules/selectors/selectorsJustificatives";
import { selectorModelDetailsByModel } from "../../../../redux-modules/selectors/selectorsModelDetails";
import apiClient from "../../../../utils/apiClient";
import { UIButton } from "../../../ui/Button";
import { UICard } from "../../../ui/Card";
import { UIDatePicker } from "../../../ui/DatePicker";
import { UIInput } from "../../../ui/Input";
import { UISelect } from "../../../ui/Select";
import { UITextArea } from "../../../ui/Textarea";
const { Option } = UISelect;
const { RangePicker } = UIDatePicker;

interface Props {
  visible: boolean;
  onClose?: () => void;
  onSave?: (info: TRequestCreateRequest) => void;
  request?: TRequest;
}

export const RequestCreateDrawer: FC<Props> = ({
  visible,
  onClose,
  onSave,
  request,
}: Props): JSX.Element => {
  //@ts-ignore
  const [form] = Form.useForm();
  const [userList, setUserList] = useState<TUser[]>([]);
  const [justificativeTypeSelected, setJustificativeTypeSelected] = useState(undefined);
  const justificatives = useSelector(selectorJustificativeList);
  const modelDetails = useSelector((store: TStore) =>
    selectorModelDetailsByModel(store, TModel.JustificativeType),
  );

  const getModelDetails = (): TModelDetail[] => {
    return modelDetails.filter(
      el => el.model_id == form.getFieldValue("justificative_type_id") && !!el.is_visible,
    );
  };

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.USERS_LIST + "?all=true").then(res => {
      setUserList(res.data.data);
    });
  }, []);

  const handleClose = (): void => {
    if (onClose)
      // quello che vogliamo
      onClose();
  };
  const handleSave = (): void => {
    form.validateFields().then(() => {
      const saveObj = {
        justificative_type_id: form.getFieldValue("justificative_type_id"),
        user_id: form.getFieldValue("user_id"),
        datetime_from: form.getFieldValue("date")[0].format("YYYY-MM-DD HH:mm:ss"),
        datetime_to: form.getFieldValue("date")[1].format("YYYY-MM-DD HH:mm:ss"),
        note: form.getFieldValue("note"),
        details: form.getFieldValue("details")
          ? form
              .getFieldValue("details")
              //@ts-ignore
              .map((el, i) => ({ id: i, value: el }))
              //@ts-ignore
              .filter(el => !!el.value)
          : [],
      };
      form.resetFields();
      if (onSave) onSave(saveObj);
    });
  };

  //@ts-ignore
  const onChangeForm = (vchanged): void => {
    if (vchanged["justificative_type_id"])
      setJustificativeTypeSelected(vchanged["justificative_type_id"]);
  };

  if (!userList.length || !justificatives)
    return (
      <Drawer
        title="Nuova Richiesta"
        placement="right"
        onClose={handleClose}
        closable={true}
        visible={visible}
        width={400}
      >
        <UICard style={{ marginBottom: "20px", border: "none" }}>
          <Skeleton active />
        </UICard>
      </Drawer>
    );

  return (
    <Drawer
      title="Nuova richiesta"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <UIButton onClick={onClose} style={{ marginRight: 8 }}>
            Annulla
          </UIButton>
          <UIButton onClick={handleSave} type="primary" data-cy="new-request-button-save">
            Crea
          </UIButton>
        </div>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSave}
        data-cy="new-document-form"
        onValuesChange={onChangeForm}
      >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="justificative_type_id"
                  label="Tipologia Richiesta"
                  rules={[{ required: true, message: "Seleziona la tipologia della richiesta" }]}
                >
                  <UISelect>
                    {justificatives.data.map(justificative => (
                      <Option value={justificative.id} key={justificative.id}>
                        {justificative.name}
                      </Option>
                    ))}
                  </UISelect>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name="user_id"
                  label="Utente"
                  rules={[{ required: true, message: "Seleziona un utente" }]}
                >
                  <UISelect
                    showSearch
                    filterOption={(input, option): boolean =>
                      ((option!.children as unknown) as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {userList.map(user => (
                      <Option value={user.id} key={user.id}>
                        {user.surname + " " + user.name}
                      </Option>
                    ))}
                  </UISelect>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="date"
                  label="Periodo"
                  rules={[{ required: true, message: "Seleziona il periodo della richiesta" }]}
                >
                  <CustomRangePicker
                    //@ts-ignore
                    showTime={
                      justificatives.data.find(el => el.id == justificativeTypeSelected)
                        ?.size_unit === TSizeUnit.GIORNI
                        ? false
                        : { format: "HH:mm" }
                    }
                    format={
                      justificatives.data.find(el => el.id == justificativeTypeSelected)
                        ?.size_unit === TSizeUnit.GIORNI
                        ? "DD/MM/YYYY"
                        : "DD/MM/YYYY HH:mm"
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="note"
                  label="Note"
                  rules={[{ required: false, message: "Inserisci delle informazioni aggiuntive" }]}
                >
                  <UITextArea />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {getModelDetails().map(md => (
                  <Form.Item
                    key={md.id}
                    name={["details", md.id]}
                    label={<span style={{ textTransform: "capitalize" }}>{md.field_name}</span>}
                    rules={[{ required: md.is_required, message: "Selezionare " + md.field_name }]}
                  >
                    <UIInput
                      //@ts-ignore
                      type={md.column_type_name}
                    />
                  </Form.Item>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export const CustomRangePicker = styled(RangePicker)`
  border-radius: 7px;

  .ant-picker-panel-container {
    border-radius: 7px !important;
  }
  .ant-picker-ok ant-btn {
    border-radius: 7px !important;
  }
`;
