/** @format */

import { Row, Col, Tabs, notification, Skeleton } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  TNotificationMethod,
  TTrigger,
} from "../../../../../redux-modules/declarations/maps/triggers";
import { selectorTriggerList } from "../../../../../redux-modules/selectors/selectorsTrigger";
import { selectorTriggerListLoading } from "../../../../../redux-modules/selectors/selectorsUI";
import { UICard } from "../../../../ui/Card";
import { UITitle } from "../../../../ui/Title";
import { DynamicModelTab } from "../ModelDetails/Tab";
import { TriggerTab } from "./partials/TriggerTab";
const { TabPane } = Tabs;

interface CategorizedTrigger {
  //@ts-ignore
  [key: TNotificationMethod]: TTrigger[];
}

const TriggersTab = (): JSX.Element => {
  const [categorizedTriggers, setCategorizedTriggers] = React.useState<CategorizedTrigger>();
  const triggersLoading = useSelector(selectorTriggerListLoading);
  const triggers = useSelector(selectorTriggerList);

  useEffect(() => {
    setCategorizedTriggers(
      triggers.reduce((acc, trigger) => {
        const category = trigger.notification_method;
        //@ts-ignore
        if (!acc[`${category}`]) {
          //@ts-ignore
          acc[`${category}`] = [];
        }
        //@ts-ignore
        acc[`${category}`].push(trigger);
        return acc;
      }, {}),
    );
  }, [triggers]);

  useEffect(() => {
    console.log(categorizedTriggers);
  }, [categorizedTriggers]);

  if (triggersLoading)
    return (
      <UICard style={{ marginBottom: "20px" }}>
        <Skeleton active />
      </UICard>
    );
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <UITitle level={4}>Triggers delle Notifiche</UITitle>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {categorizedTriggers && (
            <Tabs defaultActiveKey={Object.keys(categorizedTriggers)[0]}>
              {Object.values(categorizedTriggers).map((obj, type) => (
                <TabPane
                  key={obj[0].notification_method}
                  tab={"Notifiche " + obj[0].notification_method}
                >
                  <Tabs defaultActiveKey={obj[0].id}>
                    {
                      //@ts-ignore
                      obj.map(trigger => (
                        <TabPane key={trigger.id} tab={trigger.model + " " + trigger.action}>
                          <TriggerTab trigger={trigger as TTrigger} />
                        </TabPane>
                      ))
                    }
                  </Tabs>
                </TabPane>
              ))}
            </Tabs>
          )}
        </Col>
      </Row>
    </>
  );
};

export default React.memo(TriggersTab);
