/** @format */

import { AxiosResponse } from "axios";
import { put, all, call } from "redux-saga/effects";
import {
  apiProductCreate,
  apiProductUpdate,
  apiProductDelete,
  apiProductsList,
} from "../../api/apiProducts";
import { showNotification } from "../../utils/showNotification";
import {
  actionProductCreateSuccess,
  actionProductCreateReset,
  actionProductCreateFailure,
  actionProductListLoadingRequest,
  actionProductListSuccess,
  actionProductListLoadingSuccess,
  actionProductListFailure,
  actionProductListLoadingFailure,
  actionProductUpdateSuccess,
  actionProductUpdateReset,
  actionProductUpdateFailure,
  actionProductDeleteSuccess,
  actionProductDeleteReset,
  actionProductDeleteFailure,
} from "../actions/actionsProducts";
import {
  TActionProductCreateRequest,
  TActionProductListRequest,
  TActionProductUpdateRequest,
  TActionProductDeleteRequest,
} from "../declarations/actions/products";
import { TAPIResponse } from "../declarations/apis/general";
import { TProduct } from "../declarations/maps/products";

export function* sagaProductCreate(action: TActionProductCreateRequest): unknown {
  try {
    const response = yield call(apiProductCreate, action.payload);

    yield put(actionProductUpdateSuccess());
    yield put(actionProductUpdateReset());
    showNotification("success", "Creato!", "Prodotto creato con successo");
  } catch (e) {
    yield put(actionProductCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaProductList(action: TActionProductListRequest): unknown {
  try {
    yield put(actionProductListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TProduct[]>> = yield call(apiProductsList);

    yield put(actionProductListSuccess(response.data.data));
    yield put(actionProductListLoadingSuccess());
  } catch (e) {
    yield put(actionProductListFailure());
    yield put(actionProductListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaProductUpdate(action: TActionProductUpdateRequest): unknown {
  try {
    yield call(apiProductUpdate, action.payload);

    yield all([put(actionProductUpdateSuccess()), put(actionProductUpdateReset())]);
    showNotification("success", "Aggiornato!", "Prodotto modificato con successo");
  } catch (e) {
    yield put(actionProductUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaProductDelete(action: TActionProductDeleteRequest): unknown {
  try {
    yield call(apiProductDelete, action.payload);

    yield all([put(actionProductDeleteSuccess(action.payload)), put(actionProductDeleteReset())]);
    showNotification("success", "Eliminato!", "Prodotto eliminato con successo");
  } catch (e) {
    yield put(actionProductDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
