/** @format */

import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { useHistory } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UISelect } from "../../ui/Select";
import { API_ENDPOINTS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import { actionGroupCreateRequest } from "../../../redux-modules/actions/actionsGroups";
import { useDispatch, useSelector } from "react-redux";
import { selectorGroupCreated } from "../../../redux-modules/selectors/selectorsUI";
import { UILink } from "../../ui/Link";
import { useForm } from "antd/lib/form/Form";
const { Option } = Select;

export const ViewGroupCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const history = useHistory();
  const [userList, setUserList] = useState<[]>([]);
  const isGroupCreated = useSelector(selectorGroupCreated);

  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      name: e.name,
      description: e.description,
      location: e.location,
      parentGroups: e.parentGroups,
      childrenGroups: e.childrenGroups,
      users: e.users,
      manager_id: e.manager,
      requests: e.requests,
      notifications: e.notifications,
      workableDays: e.workableDays,
    };
    //dispatch
    dispatch(actionGroupCreateRequest(contentObj));
  };

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.USERS_LIST + "?all=true").then(res => {
      setUserList(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (isGroupCreated) history.push(Routes.groups);
  }, [isGroupCreated]);

  //@ts-ignore
  const onChangeValues = (value, x) => {
    console.log(x);
    /*if (Object.keys(value)[0] === "manager") {
      //console.log(form.getFieldValue("users"));
      form.setFieldsValue({
        users: form.getFieldValue("users")
          ? [...form.getFieldValue("users"), value.manager]
          : [value.manager],
      });
    }*/
  };

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Nuovo Gruppo
          </UITitle>
        </Col>
      </Row>
      <Form
        name="new-group"
        className="new-group"
        data-cy="new-group-form"
        onFinish={handleSave}
        onValuesChange={onChangeValues}
        layout="vertical"
        form={form}
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: "Inserisci un nome!" }]}
                    extra="Il nome del gruppo"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="manager"
                    label="Manager"
                    rules={[{ required: true, message: "Seleziona almeno un utente!" }]}
                    extra={`Il manager del gruppo`}
                  >
                    <UISelect
                      showSearch
                      filterOption={(input, option) =>
                        ((option!.children as unknown) as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {userList.map(user => (
                        //@ts-ignore
                        <Option key={user.id} value={user.id}>
                          {
                            //@ts-ignore
                            user.name + " " + user.surname
                          }
                        </Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={24}>
                  <Form.Item
                    name="users"
                    label="Utenti"
                    rules={[{ required: true, message: "Seleziona almeno un utente!" }]}
                    extra={`Gli utenti del gruppo`}
                  >
                    <UISelect
                      showSearch
                      mode="multiple"
                      filterOption={(input, option) =>
                        ((option!.children as unknown) as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {userList.map(user => (
                        //@ts-ignore
                        <Option key={user.id} value={user.id}>
                          {
                            //@ts-ignore
                            user.name + " " + user.surname
                          }
                        </Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" align="bottom" gutter={[12, 12]}>
                <Col>
                  <UILink route={Routes.groups} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item noStyle>
                    <UIButton type="primary" htmlType="submit" data-cy="new-group-button">
                      + Crea Gruppo
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
    </>
  );
};
