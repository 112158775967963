/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row, Skeleton, Slider } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import UITransition from "../../ui/Transition";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectorRequestsList } from "../../../redux-modules/selectors/selectorsRequests";
import { RequestCalendar } from "../../layout/RequestCalendar";
import { RequestCreateDrawer } from "../../layout/SideDialogs/RequestCreate";
import { TRequestCreateRequest } from "../../../redux-modules/declarations/maps/requests";
import { actionRequestCreateRequest } from "../../../redux-modules/actions/actionsRequest";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { selectorJustificativeList } from "../../../redux-modules/selectors/selectorsJustificatives";
import styled from "styled-components";
import { UIText } from "../../ui/Text";
import { DiffOutlined, SettingOutlined } from "@ant-design/icons";
import { ImportMalattiaModal } from "../../layout/SideDialogs/ImportMalattia";
import apiClient from "../../../utils/apiClient";
import { API_ENDPOINTS } from "../../../constants/api";
import { CustomStyleProvider } from "../../utils/CustomStyleProvider";
import { actionCustomStyleOptionUpdateRequest } from "../../../redux-modules/actions/actionsUI";
import { selectorCustomStyleOptions } from "../../../redux-modules/selectors/selectorsUI";

const ViewRequestList = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [newRequestVisible, setNewRequestVisible] = useState(false);
  const [importMalattiaVisible, setImportMalattiaVisible] = useState(false);
  const justificativeList = useSelector(selectorJustificativeList);

  const [showCustomStyleOptions, setShowCustomStyleOptions] = useState(false);
  const customStyleOptions = useSelector(selectorCustomStyleOptions);
  const [zoomLevel, setZoomLevel] = useState(customStyleOptions["RequestsListZoomLevel"]);

  useEffect(() => {
    if (customStyleOptions["RequestsListZoomLevel"] !== zoomLevel) {
      dispatch(
        actionCustomStyleOptionUpdateRequest({
          ...customStyleOptions,
          RequestsListZoomLevel: zoomLevel,
        }),
      );
    }
  }, [zoomLevel]);

  const onNewRequestClose = (): void => setNewRequestVisible(false);
  const showNewRequestDrawer = (): void => setNewRequestVisible(true);

  const onImportMalattiaClose = (): void => setImportMalattiaVisible(false);
  const showImportMalattiaDrawer = (): void => setImportMalattiaVisible(true);
  const permissionList = useSelector(selectorPermissionList);
  const canAddRequest = hasPermission(permissionList, "Request", "Canmanage");
  const canImportMalattia = hasPermission(permissionList, "Request", "Canmanage");
  const handleNewRequestSave = (info: TRequestCreateRequest): void => {
    dispatch(actionRequestCreateRequest(info));
    onNewRequestClose();
  };

  return (
    <UITransition>
      <CustomStyleProvider section="Requests">
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col style={{ display: "inline-flex", alignItems: "baseline" }}>
            <UITitle level={2} color={BaseColor}>
              Richieste
            </UITitle>
            <span
              style={{
                fontSize: "18px",
                color: BaseColor,
                cursor: "pointer",
                marginLeft: "10px",
                position: "relative",
                top: "-3px",
              }}
              onClick={() => setShowCustomStyleOptions(!showCustomStyleOptions)}
            >
              <SettingOutlined />
            </span>
          </Col>
          <span style={{ display: "flex" }}>
            {canImportMalattia && (
              <Col>
                <UIButton type="primary" onClick={showImportMalattiaDrawer}>
                  <DiffOutlined />
                  Importa XML INPS
                </UIButton>
              </Col>
            )}
            {canAddRequest && (
              <Col>
                <UIButton type="primary" onClick={showNewRequestDrawer}>
                  + Nuova Richiesta
                </UIButton>
              </Col>
            )}
          </span>
        </Row>
        {showCustomStyleOptions && (
          <UITransition>
            <Row gutter={[8, 8]} style={{ marginBottom: "10px" }}>
              <Col span={24}>
                <UICard>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <UITitle level={5} color={BaseColor}>
                        Personalizza pagina
                      </UITitle>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={8}>
                      <UIText>Livello di zoom</UIText>
                      <Slider
                        min={0.7}
                        max={1}
                        onChange={(value: number) => setZoomLevel(value)}
                        value={typeof zoomLevel === "number" ? zoomLevel : 0}
                        step={0.1}
                        tooltipVisible={false}
                        marks={{
                          0.7: "70%",
                          0.8: "80%",
                          0.9: "90%",
                          1: "100%",
                        }}
                      />
                    </Col>
                  </Row>
                </UICard>
              </Col>
            </Row>
          </UITransition>
        )}
        <CustomCard>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CustomDiv>
                {!justificativeList.data.length && (
                  <span style={{ height: "20px" }}>
                    <Skeleton.Input active size={"small"} style={{ marginRight: "20px" }} />
                    <Skeleton.Input active size={"small"} style={{ marginRight: "20px" }} />
                    <Skeleton.Input active size={"small"} style={{ marginRight: "20px" }} />
                    <Skeleton.Input active size={"small"} style={{ marginRight: "20px" }} />
                  </span>
                )}
                {justificativeList.data.map(justificative => (
                  <span style={{ marginRight: "25px" }} key={justificative.id}>
                    <span
                      style={{
                        backgroundColor: justificative.color,
                        borderRadius: "3px",
                        marginRight: "3px",
                      }}
                    >
                      {"\u00A0"}
                      {"\u00A0"}
                      {"\u00A0"}
                      {"\u00A0"}
                      {"\u00A0"}
                    </span>
                    <UIText>{justificative.name}</UIText>
                  </span>
                ))}
              </CustomDiv>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <RequestCalendar />
            </Col>
          </Row>
        </CustomCard>
        <RequestCreateDrawer
          onClose={onNewRequestClose}
          visible={newRequestVisible}
          onSave={handleNewRequestSave}
        />
        <ImportMalattiaModal
          isModalVisible={importMalattiaVisible}
          closeModal={(): void => setImportMalattiaVisible(false)}
        />
      </CustomStyleProvider>
    </UITransition>
  );
};

const CustomDiv = styled.div`
  padding: 15px;
  margin-bottom: 10px;
  background-color: #fafafa;
  border-radius: 7px;
`;

const CustomCard = styled(UICard)`
  .ant-card-body {
    padding: 10px;
  }
`;
export default ViewRequestList;
