/** @format */

import {
  TActionDepartmentListRequest,
  TActionDepartmentListSuccess,
  TActionDepartmentListFailure,
  TActionDepartmentListLoadingRequest,
  TActionDepartmentListLoadingSuccess,
  TActionDepartmentListLoadingFailure,
  TActionDepartmentCreateRequest,
  TActionDepartmentCreateSuccess,
  TActionDepartmentCreateFailure,
  TActionDepartmentUpdateRequest,
  TActionDepartmentUpdateSuccess,
  TActionDepartmentUpdateFailure,
  TActionDepartmentUpdateReset,
  TActionDepartmentDeleteRequest,
  TActionDepartmentDeleteSuccess,
  TActionDepartmentDeleteFailure,
  TActionDepartmentDeleteReset,
} from "../declarations/actions/departments";
import { TAction, TActionType } from "../declarations/actionTypes";
import {
  TDepartment,
  TDepartmentCreateRequest,
  TDepartmentUpdateRequest,
} from "../declarations/maps/departments";

//List
export const actionDepartmentListRequest = (): TActionDepartmentListRequest => ({
  type: TActionType.departmentListRequest,
});

export const actionDepartmentListSuccess = (
  payload: TDepartment[],
): TActionDepartmentListSuccess => ({
  type: TActionType.departmentListSuccess,
  payload,
});

export const actionDepartmentListFailure = (): TActionDepartmentListFailure => ({
  type: TActionType.departmentListFailure,
});

//---

//Loading

export const actionDepartmentListLoadingRequest = (): TActionDepartmentListLoadingRequest => ({
  type: TActionType.departmentListLoadingRequest,
});

export const actionDepartmentListLoadingSuccess = (): TActionDepartmentListLoadingSuccess => ({
  type: TActionType.departmentListLoadingSuccess,
});

export const actionDepartmentListLoadingFailure = (): TActionDepartmentListLoadingFailure => ({
  type: TActionType.departmentListLoadingFailure,
});

// ---
//Create
export const actionDepartmentCreateRequest = (
  payload: TDepartmentCreateRequest,
): TActionDepartmentCreateRequest => ({ type: TActionType.departmentCreateRequest, payload });

export const actionDepartmentCreateSuccess = (payload: string): TActionDepartmentCreateSuccess => ({
  type: TActionType.departmentCreateSuccess,
  payload,
});
export const actionDepartmentCreateFailure = (): TActionDepartmentCreateFailure => ({
  type: TActionType.departmentCreateFailure,
});
export const actionDepartmentCreateReset = (): TAction => ({
  type: TActionType.departmentCreateReset,
});
// ---
// Update
export const actionDepartmentUpdateRequest = (
  payload: TDepartmentUpdateRequest,
): TActionDepartmentUpdateRequest => ({
  type: TActionType.departmentUpdateRequest,
  payload,
});

export const actionDepartmentUpdateSuccess = (): TActionDepartmentUpdateSuccess => ({
  type: TActionType.departmentUpdateSuccess,
});

export const actionDepartmentUpdateFailure = (): TActionDepartmentUpdateFailure => ({
  type: TActionType.departmentUpdateFailure,
});

export const actionDepartmentUpdateReset = (): TActionDepartmentUpdateReset => ({
  type: TActionType.departmentUpdateReset,
});

// ---
// Delete
export const actionDepartmentDeleteRequest = (payload: string): TActionDepartmentDeleteRequest => ({
  type: TActionType.departmentDeleteRequest,
  payload,
});

export const actionDepartmentDeleteSuccess = (payload: string): TActionDepartmentDeleteSuccess => ({
  type: TActionType.departmentDeleteSuccess,
  payload,
});

export const actionDepartmentDeleteFailure = (): TActionDepartmentDeleteFailure => ({
  type: TActionType.departmentDeleteFailure,
});

export const actionDepartmentDeleteReset = (): TActionDepartmentDeleteReset => ({
  type: TActionType.departmentDeleteReset,
});

// ---
