/** @format */

import {
  TActionProductListFailure,
  TActionProductListRequest,
  TActionProductListSuccess,
} from "../declarations/actions/products";
import { TActionType } from "../declarations/actionTypes";
import { TProducts } from "../declarations/maps/products";

type Actions = TActionProductListRequest | TActionProductListSuccess | TActionProductListFailure;

export const reducerProducts = (
  prevStore: TProducts = { productList: [] },
  action: Actions,
): TProducts => {
  switch (action.type) {
    case TActionType.productListRequest:
      return { ...prevStore, productList: [] };
    case TActionType.productListSuccess:
      return { ...prevStore, productList: action.payload };
    case TActionType.productListFailure:
      return { ...prevStore, productList: [] };
    default:
      return prevStore;
  }
};
