/** @format */

import {
  PermissionAction,
  PermissionModel,
  TPermission,
} from "../../redux-modules/declarations/maps/permissions";

export const hasPermission = (
  permissionList: TPermission[],
  model: PermissionModel,
  permission: PermissionAction,
): boolean => {
  const permissionElement = permissionList.find(el => el.action == permission && el.model == model);
  return !!permissionElement;
};
