/** @format */

import {
  Row,
  Col,
  Cascader,
  Spin,
  Empty,
  Progress,
  Calendar,
  Skeleton,
  Tooltip,
  Avatar,
  Form,
  FormProps,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { BaseColor } from "../../../theme/maps/Colors";
import { UICard } from "../../ui/Card";
import { UITitle } from "../../ui/Title";
import UITransition from "../../ui/Transition";
import moment from "moment";
import { CustomRangePicker } from "../../layout/SideDialogs/RequestCreate";
import { API_ENDPOINTS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import { TUser } from "../../../redux-modules/declarations/maps/auth";
import {
  CloseOutlined,
  ContainerOutlined,
  DeliveredProcedureOutlined,
  EyeOutlined,
  FileExcelOutlined,
  LeftCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
  PrinterOutlined,
  RightCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  SwapOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import { UIButton } from "../../ui/Button";
import styled from "styled-components";
import { TPresenceExportType } from "../../../redux-modules/declarations/maps/presences";
import { UserRow } from "./partials/UserRow";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";
import LazyList from "lazylist-react";
import { showNotification } from "../../../utils/showNotification";
import { apiReportPResencesStatus } from "../../../api/apiPresences";
import { sleep } from "../../../utils/sleep";
import { ShiftStatistics } from "./partials/ShiftStatistics";
import { PresenceStatistics } from "./partials/PresenceStatistics";
import { UIDatePicker } from "../../ui/DatePicker";
import { UIInput } from "../../ui/Input";
import { useDebouncedInput } from "../../../redux-modules/hooks/useDebouncedInput";
import { useDispatch, useSelector } from "react-redux";
import { actionCollapseRequest } from "../../../redux-modules/actions/actionsUI";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { MissingUsersModal } from "../../layout/SideDialogs/ReportMissingUsers";
import { UISelect } from "../../ui/Select";
import UIModal from "../../ui/Modal";
import { CustomCalendar } from "../../layout/Widgets/CreateRequest/partials/MyRequestsCalendar";
import { UICheckbox } from "../../ui/Checkbox";
import { selectorLocationsList } from "../../../redux-modules/selectors/selectorsLocations";
import { UIText } from "../../ui/Text";
import { RequestStatistics } from "./partials/RequestStatistics";
import { selectorJustificativeList } from "../../../redux-modules/selectors/selectorsJustificatives";

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

export interface THoliday {
  id: string;
  description: string;
  date: string;
  ricorsivo: boolean;
  offices: string[];
}
export interface ReportData {
  user: TUser;
  totals: { [k: string]: string };
  date: { [k: string]: { [k: string]: string } };
}

export const ViewReportPresences = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dateFrom, setDateFrom] = useState(moment().startOf("month"));
  const [dateTo, setDateTo] = useState(moment().endOf("month"));
  const [dateFromProd, setDateFromProd] = useState(moment().startOf("month"));
  const [dateToProd, setDateToProd] = useState(moment().endOf("month"));

  const [dateFromAnomalie, setDateFromAnomalie] = useState(moment().startOf("month"));
  const [dateToAnomalie, setDateToAnomalie] = useState(moment().endOf("month"));

  const [dateFromFerie, setDateFromFerie] = useState(moment().startOf("month"));
  const [dateToFerie, setDateToFerie] = useState(moment().endOf("month"));

  const [dateFromMalattie, setDateFromMalattie] = useState(moment().startOf("month"));
  const [dateToMalattie, setDateToMalattie] = useState(moment().endOf("month"));

  const [dateFromReportGiustificativi, setDateFromReportGiustificativi] = useState(moment().startOf("month"));
  const [dateToReportGiustificativi, setDateToReportGiustificativi] = useState(moment().endOf("month"));

  const [debouncedQuery, currentQuery, setQuery] = useDebouncedInput("", () => true, false, 600);

  const [percentage, setPercentage] = useState<string | undefined>();
  const [percentageProd, setPercentageProd] = useState<string | undefined>();
  const [userList, setUserList] = useState<TUser[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValuesProd, setSelectedValuesProd] = useState([]);
  const [selectedValuesAnomalie, setSelectedValuesAnomalie] = useState([]);
  const [selectedValuesFerie, setSelectedValuesFerie] = useState([]);
  const [selectedValuesMalattie, setSelectedValuesMalattie] = useState([]);
  const [selectedValuesReportGiustificativi, setSelectedValuesReportGiustificativi] = useState([]);
  const [reportData, setReportData] = useState<{ [k: string]: ReportData }>();
  const [reportDataFiltered, setReportDataFiltered] = useState<ReportData[]>();
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const [isXlsLoading, setIsXlsLoading] = useState(false);
  const [isXlsProdLoading, setIsXlsProdLoading] = useState(false);
  const [isCSVProdLoading, setIsCSVProdLoading] = useState(false);
  const [isTeamSystemLoading, setIsTeamSystemLoading] = useState(false);
  const [isZucchettiLoading, setIsZucchettiLoading] = useState(false);
  const [isJOBLoading, setIsJOBLoading] = useState(false);
  const [isAnomalieLoading, setIsAnomalieLoading] = useState(false);
  const [isFerieLoading, setIsFerieLoading] = useState(false);
  const [isMalattieLoading, setIsMalattieLoading] = useState(false);
  const [isReportGiustificativiLoading, setIsReportGiustificativiLoading] = useState(false);
  const [isQueryLoading, setIsQueryLoading] = useState(false);
  const [utentiMancanti, setUtentiMancanti] = useState<string[]>([]);
  const [showUtentiMancantiModal, setShowUtentiMancantiModal] = useState(false);
  const permissionList = useSelector(selectorPermissionList);
  const canExportTS = hasPermission(permissionList, "Report", "Teamsystem");
  const canExportProd = hasPermission(permissionList, "Report", "Produttivita");
  const canExportZucchetti = hasPermission(permissionList, "Report", "Zucchetti");
  const canExportJOB = hasPermission(permissionList, "Report", "JOB");
  const canViewAnalytics = hasPermission(permissionList, "Report", "Analitiche");
  const canViewPresenze = hasPermission(permissionList, "Report", "Presenze");
  const canViewAnomalie = hasPermission(permissionList, "Report", "Anomalie");
  const canViewFerie = hasPermission(permissionList, "Report", "Ferie");
  const canViewMalattie = hasPermission(permissionList, "Report", "Malattie");
  const canViewReportGiustificativi = hasPermission(permissionList, "Report", "Giustificativi");
  const canViewBlockMonth = hasPermission(permissionList, "BlockMonth", "Create");
  const canViewHolidayCalendar = hasPermission(permissionList, "HolidayCalendar", "Create");
  const canExportXls = hasPermission(permissionList, "Report", "Xls");
  const canExportXlsProduttivita = hasPermission(permissionList, "Report", "Xlsproduttivita");
  const [isBlockMonthVisible, setIsBlockMonthVisible] = useState(false);
  const [blockMonths, setBlockMonths] = useState<string[]>([]);
  const [holidayCalendar, setHolidayCalendar] = useState<THoliday[]>([]);
  const [isBlockMonthLoading, setIsBlockMonthLoading] = useState(false);
  const [isHolidayCalendarLoading, setIsHolidayCalendarLoading] = useState(false);
  const [selectedHolidayCalendar, setSelectedHolidayCalendar] = useState<string | undefined>(
    undefined,
  );
  const [form] = Form.useForm();
  const offices = useSelector(selectorLocationsList);
  const justificatives = useSelector(selectorJustificativeList);
  const [justificativeTypeId, setJustificativeTypeId] = useState("")
  const { Option } = UISelect;

  const getHolidayByDate = (
    date: string,
  ):
    | {
        id: string;
        description: string;
        date: string;
        date_from?: string;
        date_to?: string;
        ricorsivo: boolean;
        offices: string[];
      }
    | undefined => {
    const holiday = holidayCalendar.find(h => h.date == date);
    const hilday_period = holidayCalendar.filter(h => h.description == holiday?.description);
    const holiday_period_ordered = hilday_period.sort((a, b) => {
      return moment(a.date).isBefore(moment(b.date)) ? -1 : 1;
    });

    return holiday
      ? hilday_period.length > 1
        ? {
            ...holiday,
            date_from: holiday_period_ordered[0].date,
            date_to: holiday_period_ordered[holiday_period_ordered.length - 1].date,
          }
        : holiday
      : undefined;
  };

  const onRemoveHoliday = (date?: string) => {
    if (date) {
      const holiday = getHolidayByDate(date);
      apiClient.delete(API_ENDPOINTS.HOLIDAY_CALENDAR + "/" + holiday?.id).then(() => {
        setSelectedHolidayCalendar(undefined);
        updateHolidayCalendar();
      });
    }
  };

  const getConfig = (date: string, action: "block" | "unblock") => {
    return UIModal.confirm({
      title: `Sei sicuro di voler ${action == "block" ? "blocc" : "sblocc"}are il mese di ${moment(
        date,
      ).format("MMMM")}?`,
      content: `Sei sicuro di voler ${
        action == "block" ? "blocc" : "sblocc"
      }are il mese di ${moment(date).format("MMMM")}?`,
      okText: "Conferma",
      cancelText: "Annulla",
      onOk: async () => {
        try {
          const res = await apiClient.post(
            action == "block" ? API_ENDPOINTS.BLOCK_MONTHS : API_ENDPOINTS.UNBLOCK_MONTHS,
            {
              date_from: moment(date).startOf("month").format("YYYY-MM-DD"),
              date_to: moment(date).endOf("month").format("YYYY-MM-DD"),
            },
          );

          //@ts-ignore
          if (!!res.data) {
            showNotification(
              "success",
              `Mese ${action == "block" ? "blocc" : "sblocc"}ato correttamente`,
              `Il mese di ${moment(date).format("MMMM")} è stato ${
                action == "block" ? "blocc" : "sblocc"
              }ato correttamente`,
            );
            updateBlockMonths();
          } else {
            showNotification(
              "error",
              //@ts-ignore
              res.message,
              "Si è verificato un errore durante il blocco del mese di " +
                moment(date).format("MMMM"),
            );
          }
        } catch (e) {
          //@ts-ignore
          showNotification("error", e.message);
        }
      },
    });
  };
  const [monthRichieste, setMonthRichieste] = useState<moment.Moment | null>(moment());
  const [monthTurni, setMonthTurni] = useState<moment.Moment | null>(moment());
  const [monthPresenze, setMonthPresenze] = useState<moment.Moment | null>(moment());

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const changeDates = (dates: moment.Moment[]) => {
    setDateFrom(dates[0]);
    setDateTo(dates[1]);
  };

  const changeDatesProd = (dates: moment.Moment[]) => {
    setDateFromProd(dates[0]);
    setDateToProd(dates[1]);
  };

  const changeDatesAnomalie = (dates: moment.Moment[]) => {
    setDateFromAnomalie(dates[0]);
    setDateToAnomalie(dates[1]);
  };
  const changeDatesFerie = (dates: moment.Moment[]) => {
    setDateFromFerie(dates[0]);
    setDateToFerie(dates[1]);
  };
  const changeDatesMalattie = (dates: moment.Moment[]) => {
    setDateFromMalattie(dates[0]);
    setDateToMalattie(dates[1]);
  };

  const changeDatesReportGiustificativi = (dates: moment.Moment[]) => {
    setDateFromReportGiustificativi(dates[0]);
    setDateToReportGiustificativi(dates[1]);
  };

  useEffect(() => {
    setIsUserLoading(true);
    apiClient.get(API_ENDPOINTS.USERS_LIST + "?all=true").then(res => {
      setUserList(res.data.data);
      setIsUserLoading(false);
    });

    updateBlockMonths();
    updateHolidayCalendar();
  }, []);

  const updateBlockMonths = () => {
    setIsBlockMonthLoading(true);
    apiClient.get(API_ENDPOINTS.BLOCK_MONTHS).then(res => {
      setBlockMonths(res.data);
      setIsBlockMonthLoading(false);
    });
  };

  const updateHolidayCalendar = () => {
    setIsHolidayCalendarLoading(true);
    apiClient.get(API_ENDPOINTS.HOLIDAY_CALENDAR).then(res => {
      setHolidayCalendar(res.data);
      setIsHolidayCalendarLoading(false);
    });
  };

  useEffect(() => {
    if (!!utentiMancanti.length) {
      setShowUtentiMancantiModal(true);
    } else {
      setShowUtentiMancantiModal(false);
    }
  }, [utentiMancanti]);

  useEffect(() => {
    if (!!reportData) {
      dispatch(actionCollapseRequest());
      if (!debouncedQuery) {
        setReportDataFiltered(Object.values(reportData));
      } else {
        setReportDataFiltered(
          Object.values(reportData).filter(el =>
            JSON.stringify({ name: el.user.name, surname: el.user.surname })
              .toLowerCase()
              .includes(debouncedQuery.toLowerCase()),
          ),
        );
      }
    }
  }, [debouncedQuery, reportData]);

  useEffect(() => {
    if (currentQuery != debouncedQuery) {
      setIsQueryLoading(true);
    } else {
      setIsQueryLoading(false);
    }
  }, [currentQuery, debouncedQuery]);

  useEffect(() => {
    if (userList) {
      let options: Option[] = [];
      userList.forEach(user => {
        //controllo se esiste già il gruppo
        user.groups.forEach(group => {
          if (options.find(el => el.value == "group_" + group.id)) {
            if (
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.find(e => e.value == "department_" + user.department.id)
            ) {
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.find(e => e.value == "department_" + user.department.id)
                ?.children?.push({
                  value: user.id,
                  label: user.surname + " " + user.name,
                }) || console.log("");
            } else {
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.push({
                  value: "department_" + user.department.id,
                  label: group.name + " - " + user.department.name,
                  children: [
                    {
                      value: user.id,
                      label: user.surname + " " + user.name,
                    },
                  ],
                }) || console.log("");
            }
          } else {
            options.push({
              value: "group_" + group.id,
              label: group.name,
              children: [
                {
                  value: "department_" + user.department.id,
                  label: group.name + " - " + user.department.name,
                  children: [{ value: user.id, label: user.surname + " " + user.name }],
                },
              ],
            });
          }
        });
      });
      setOptions(options);
    }
  }, [userList]);

  const onChangeFilters = (value: unknown) => {
    //@ts-ignore
    setSelectedValues(value);
  };

  const onChangeFiltersProd = (value: unknown) => {
    //@ts-ignore
    setSelectedValuesProd(value);
  };

  const onChangeFiltersAnomalie = (value: unknown) => {
    //@ts-ignore
    setSelectedValuesAnomalie(value);
  };
  const onChangeFiltersFerie = (value: unknown) => {
    //@ts-ignore
    setSelectedValuesFerie(value);
  };
  const onChangeFiltersMalattie = (value: unknown) => {
    //@ts-ignore
    setSelectedValuesMalattie(value);
  };

  const onChangeFiltersReportGiustificativi = (value: unknown) => {
    //@ts-ignore
    setSelectedValuesReportGiustificativi(value);
  };

  const onChangeJustificativeTypeId = (value: unknown) =>{
    setJustificativeTypeId(value as string)
  }

  const getUsersArray = (array: string[]): string[] => {
    let users: string[] = [];
    if (array.length > 0) {
      array.forEach((value: string) => {
        // se gruppo
        if (value.length == 1) {
          let gruppo = value[0].split("_")[1];
          userList.forEach(user => {
            if (user.groups.find(group => group.id == gruppo)) {
              users.push(user.id);
            }
          });
        }
        // se dipartimento
        else if (value.length == 2) {
          let gruppo = value[0].split("_")[1];
          let dipartimento = value[1].split("_")[1];
          userList.forEach(user => {
            if (
              user.groups.find(group => group.id == gruppo) &&
              user.department.id == dipartimento
            ) {
              users.push(user.id);
            }
          });
        }
        // se utente
        else if (value.length == 3) {
          users.push(value[2]);
        }
      });
    } else {
      users = userList.map(user => user.id);
    }

    return users;
  };

  const exportPlain = () => {
    setIsShowLoading(true);
    const users = getUsersArray(selectedValues);
    let stillPolling = true;

    apiClient
      .get(API_ENDPOINTS.REPORT_PRESENCES_START)
      .then(async r => {
        apiClient
          .post(process.env.REACT_APP_API_URL + "/export_payslip", {
            user_ids: users,
            date_from: dateFrom.format("YYYY-MM-DD"),
            date_to: dateTo.format("YYYY-MM-DD"),
            export_type_id: TPresenceExportType.PLAIN,
            temp_id: r.data.id,
          })
          .then(res => {
            stillPolling = false;
            setPercentage(undefined);
            setReportData(res.data);
            setIsShowLoading(false);
          })
          .catch(err => {
            stillPolling = false;
            setPercentage(undefined);
            setIsShowLoading(false);
            showNotification("error", "Oops..", "Errore durante la generazione del report");
          });

        //VA IN POLLINGO
        let counter = 0;
        const id = r.data.id;
        while (stillPolling) {
          apiReportPResencesStatus(id)
            .then(res => {
              //@ts-ignore
              if (res.data.status === "completed") {
                stillPolling = false;
                setPercentage(undefined);
              } else {
                //@ts-ignore
                setPercentage(res.data.msg);
              }
            })
            .catch(e => {
              stillPolling = false;
              setPercentage(undefined);
              setIsShowLoading(false);
              showNotification("error", "Oops..", "Errore durante la generazione del report");
            });

          if (counter > 90) {
            stillPolling = false;
            setPercentage(undefined);
          }

          counter++;
          await sleep(2000);
        }
      })
      .catch(e => {
        setPercentage(undefined);
        setIsShowLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportCsv = () => {
    setIsCSVLoading(true);
    const users = getUsersArray(selectedValues);
    let stillPolling = true;

    apiClient
      .get(API_ENDPOINTS.REPORT_PRESENCES_START)
      .then(async r => {
        apiClient
          .post(process.env.REACT_APP_API_URL + "/export_payslip", {
            user_ids: users,
            date_from: dateFrom.format("YYYY-MM-DD"),
            date_to: dateTo.format("YYYY-MM-DD"),
            export_type_id: TPresenceExportType.CSV,
            temp_id: r.data.id,
          })
          .then(res => {
            stillPolling = false;
            setPercentage(undefined);
            setIsCSVLoading(false);
            window.open(res.data.url, "_blank");
          })
          .catch(err => {
            setPercentage(undefined);
            stillPolling = false;
            setIsCSVLoading(false);
            showNotification("error", "Oops..", "Errore durante la generazione del report");
          });

        //VA IN POLLINGO
        let counter = 0;
        const id = r.data.id;
        while (stillPolling) {
          apiReportPResencesStatus(id)
            .then(res => {
              //@ts-ignore
              if (res.data.status === "completed") {
                stillPolling = false;
                setPercentage(undefined);
              } else {
                //@ts-ignore
                setPercentage(res.data.msg);
              }
            })
            .catch(e => {
              setPercentage(undefined);
              stillPolling = false;
              setIsCSVLoading(false);
              showNotification("error", "Oops..", "Errore durante la generazione del report");
            });

          if (counter > 90) {
            stillPolling = false;
            setPercentage(undefined);
          }

          counter++;
          await sleep(2000);
        }
      })
      .catch(e => {
        setPercentage(undefined);
        stillPolling = false;
        setIsShowLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportXls = () => {
    setIsXlsLoading(true);
    const users = getUsersArray(selectedValues);
    let stillPolling = true;

    apiClient
      .get(API_ENDPOINTS.REPORT_PRESENCES_START)
      .then(async r => {
        apiClient
          .post(process.env.REACT_APP_API_URL + "/export_payslip", {
            user_ids: users,
            date_from: dateFrom.format("YYYY-MM-DD"),
            date_to: dateTo.format("YYYY-MM-DD"),
            export_type_id: TPresenceExportType.XLS,
            temp_id: r.data.id,
          })
          .then(res => {
            stillPolling = false;
            setPercentage(undefined);
            setIsXlsLoading(false);
            window.open(res.data.url, "_blank");
          })
          .catch(err => {
            setPercentage(undefined);
            stillPolling = false;
            setIsXlsLoading(false);
            showNotification("error", "Oops..", "Errore durante la generazione del report");
          });

        //VA IN POLLINGO
        let counter = 0;
        const id = r.data.id;
        while (stillPolling) {
          apiReportPResencesStatus(id)
            .then(res => {
              //@ts-ignore
              if (res.data.status === "completed") {
                stillPolling = false;
                setPercentage(undefined);
              } else {
                //@ts-ignore
                setPercentage(res.data.msg);
              }
            })
            .catch(e => {
              setPercentage(undefined);
              stillPolling = false;
              setIsXlsLoading(false);
              showNotification("error", "Oops..", "Errore durante la generazione del report");
            });

          if (counter > 90) {
            stillPolling = false;
            setPercentage(undefined);
          }

          counter++;
          await sleep(2000);
        }
      })
      .catch(e => {
        setPercentage(undefined);
        stillPolling = false;
        setIsShowLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportCsvWithCache = () => {
    setIsCSVLoading(true);
    const users = getUsersArray(selectedValues);
    apiClient
      .post(process.env.REACT_APP_API_URL + "/export_payslip", {
        user_ids: users,
        date_from: dateFrom.format("YYYY-MM-DD"),
        date_to: dateTo.format("YYYY-MM-DD"),
        export_type_id: TPresenceExportType.CSV_WITH_CACHE,
      })
      .then(res => {
        setIsCSVLoading(false);
        window.open(res.data.url, "_blank");
      })
      .catch(err => {
        setIsCSVLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportTeamSystemWithCache = () => {
    setIsTeamSystemLoading(true);
    const users = getUsersArray(selectedValues);
    apiClient
      .post(process.env.REACT_APP_API_URL + "/export_payslip", {
        user_ids: users,
        date_from: dateFrom.format("YYYY-MM-DD"),
        date_to: dateTo.format("YYYY-MM-DD"),
        export_type_id: TPresenceExportType.TEAMSYSTEM_WITH_CACHE,
      })
      .then(res => {
        setIsTeamSystemLoading(false);
        window.open(res.data.url, "_blank");
      })
      .catch(err => {
        setIsTeamSystemLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportZucchettiWithCache = () => {
    setIsZucchettiLoading(true);
    const users = getUsersArray(selectedValues);
    apiClient
      .post(process.env.REACT_APP_API_URL + "/export_payslip", {
        user_ids: users,
        date_from: dateFrom.format("YYYY-MM-DD"),
        date_to: dateTo.format("YYYY-MM-DD"),
        export_type_id: TPresenceExportType.ZUCCHETTI_WITH_CACHE,
      })
      .then(res => {
        setIsZucchettiLoading(false);
        window.open(res.data.url, "_blank");
      })
      .catch(err => {
        setIsZucchettiLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportCsvProd = () => {
    setIsCSVProdLoading(true);
    let stillPolling = true;
    const users = getUsersArray(selectedValuesProd);
    apiClient
      .get(API_ENDPOINTS.REPORT_PRESENCES_START)
      .then(async r => {
        apiClient
          .post(process.env.REACT_APP_API_URL + "/export_payslip", {
            user_ids: users,
            date_from: dateFromProd.format("YYYY-MM-DD"),
            date_to: dateToProd.format("YYYY-MM-DD"),
            export_type_id: TPresenceExportType.PRODUTTIVITA,
            temp_id: r.data.id,
          })
          .then(res => {
            setPercentageProd(undefined);
            setIsCSVProdLoading(false);
            window.open(res.data.url, "_blank");
          })
          .catch(err => {
            setPercentageProd(undefined);
            setIsCSVProdLoading(false);
            showNotification("error", "Oops..", "Errore durante la generazione del report");
          });

        //VA IN POLLINGO
        let counter = 0;
        const id = r.data.id;
        while (stillPolling) {
          apiReportPResencesStatus(id)
            .then(res => {
              //@ts-ignore
              if (res.data.status === "completed") {
                stillPolling = false;
                setPercentageProd(undefined);
              } else {
                //@ts-ignore
                setPercentageProd(res.data.msg);
              }
            })
            .catch(e => {
              setPercentageProd(undefined);
              stillPolling = false;
              setIsCSVLoading(false);
              showNotification("error", "Oops..", "Errore durante la generazione del report");
            });

          if (counter > 90) {
            stillPolling = false;
            setPercentageProd(undefined);
          }

          counter++;
          await sleep(2000);
        }
      })
      .catch(e => {
        setPercentageProd(undefined);
        stillPolling = false;
        setIsShowLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportXlsProd = () => {
    setIsXlsProdLoading(true);
    let stillPolling = true;
    const users = getUsersArray(selectedValuesProd);
    apiClient
      .get(API_ENDPOINTS.REPORT_PRESENCES_START)
      .then(async r => {
        apiClient
          .post(process.env.REACT_APP_API_URL + "/export_payslip", {
            user_ids: users,
            date_from: dateFromProd.format("YYYY-MM-DD"),
            date_to: dateToProd.format("YYYY-MM-DD"),
            export_type_id: TPresenceExportType.XLS_PRODUTTIVITA,
            temp_id: r.data.id,
          })
          .then(res => {
            setPercentageProd(undefined);
            setIsXlsProdLoading(false);
            window.open(res.data.url, "_blank");
          })
          .catch(err => {
            setPercentageProd(undefined);
            setIsXlsProdLoading(false);
            showNotification("error", "Oops..", "Errore durante la generazione del report");
          });

        //VA IN POLLINGO
        let counter = 0;
        const id = r.data.id;
        while (stillPolling) {
          apiReportPResencesStatus(id)
            .then(res => {
              //@ts-ignore
              if (res.data.status === "completed") {
                stillPolling = false;
                setPercentageProd(undefined);
              } else {
                //@ts-ignore
                setPercentageProd(res.data.msg);
              }
            })
            .catch(e => {
              setPercentageProd(undefined);
              stillPolling = false;
              setIsXlsProdLoading(false);
              showNotification("error", "Oops..", "Errore durante la generazione del report");
            });

          if (counter > 90) {
            stillPolling = false;
            setPercentageProd(undefined);
          }

          counter++;
          await sleep(2000);
        }
      })
      .catch(e => {
        setPercentageProd(undefined);
        stillPolling = false;
        setIsShowLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportTeamSystem = () => {
    setIsTeamSystemLoading(true);
    const users = getUsersArray(selectedValues);
    let stillPolling = true;

    apiClient
      .get(API_ENDPOINTS.REPORT_PRESENCES_START)
      .then(async r => {
        apiClient
          .post(process.env.REACT_APP_API_URL + "/export_payslip", {
            user_ids: users,
            date_from: dateFrom.format("YYYY-MM-DD"),
            date_to: dateTo.format("YYYY-MM-DD"),
            export_type_id: TPresenceExportType.TEAMSYSTEM,
            temp_id: r.data.id,
          })
          .then(res => {
            stillPolling = false;
            setPercentage(undefined);
            setIsTeamSystemLoading(false);
            if (res.data.utenti_mancanti.length > 0) {
              setUtentiMancanti(res.data.utenti_mancanti);
            }
            window.open(res.data.url, "_blank");
          })
          .catch(err => {
            setPercentage(undefined);
            stillPolling = false;
            setIsTeamSystemLoading(false);
            showNotification("error", "Oops..", "Errore durante la generazione del report");
          });

        //VA IN POLLINGO
        let counter = 0;
        const id = r.data.id;
        while (stillPolling) {
          apiReportPResencesStatus(id)
            .then(res => {
              //@ts-ignore
              if (res.data.status === "completed") {
                stillPolling = false;
                setPercentage(undefined);
              } else {
                //@ts-ignore
                setPercentage(res.data.msg);
              }
            })
            .catch(e => {
              setPercentage(undefined);
              stillPolling = false;
              setIsTeamSystemLoading(false);
              showNotification("error", "Oops..", "Errore durante la generazione del report");
            });

          if (counter > 90) {
            stillPolling = false;
            setPercentage(undefined);
          }

          counter++;
          await sleep(2000);
        }
      })
      .catch(e => {
        setPercentage(undefined);
        stillPolling = false;
        setIsShowLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportZucchetti = () => {
    setIsZucchettiLoading(true);
    const users = getUsersArray(selectedValues);
    let stillPolling = true;

    apiClient
      .get(API_ENDPOINTS.REPORT_PRESENCES_START)
      .then(async r => {
        apiClient
          .post(process.env.REACT_APP_API_URL + "/export_payslip", {
            user_ids: users,
            date_from: dateFrom.format("YYYY-MM-DD"),
            date_to: dateTo.format("YYYY-MM-DD"),
            export_type_id: TPresenceExportType.ZUCCHETTI,
            temp_id: r.data.id,
          })
          .then(res => {
            stillPolling = false;
            setPercentage(undefined);
            setIsZucchettiLoading(false);
            if (res.data.utenti_mancanti.length > 0) {
              setUtentiMancanti(res.data.utenti_mancanti);
            }

            window.open(res.data.url, "_blank");
          })
          .catch(err => {
            setPercentage(undefined);
            stillPolling = false;
            setIsZucchettiLoading(false);
            showNotification("error", "Oops..", "Errore durante la generazione del report");
          });

        //VA IN POLLINGO
        let counter = 0;
        const id = r.data.id;
        while (stillPolling) {
          apiReportPResencesStatus(id)
            .then(res => {
              //@ts-ignore
              if (res.data.status === "completed") {
                stillPolling = false;
                setPercentage(undefined);
              } else {
                //@ts-ignore
                setPercentage(res.data.msg);
              }
            })
            .catch(e => {
              setPercentage(undefined);
              stillPolling = false;
              setIsZucchettiLoading(false);
              showNotification("error", "Oops..", "Errore durante la generazione del report");
            });
          if (counter > 90) {
            stillPolling = false;
            setPercentage(undefined);
          }
          counter++;
          await sleep(2000);
        }
      })
      .catch(e => {
        setPercentage(undefined);
        stillPolling = false;
        setIsShowLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportJOB = () => {
    setIsJOBLoading(true);
    const users = getUsersArray(selectedValues);
    let stillPolling = true;

    apiClient
      .get(API_ENDPOINTS.REPORT_PRESENCES_START)
      .then(async r => {
        apiClient
          .post(process.env.REACT_APP_API_URL + "/export_payslip", {
            user_ids: users,
            date_from: dateFrom.format("YYYY-MM-DD"),
            date_to: dateTo.format("YYYY-MM-DD"),
            export_type_id: TPresenceExportType.JOB,
            temp_id: r.data.id,
          })
          .then(res => {
            stillPolling = false;
            setPercentage(undefined);
            setIsJOBLoading(false);
            if (res.data.utenti_mancanti.length > 0) {
              setUtentiMancanti(res.data.utenti_mancanti);
            }
            window.open(res.data.url, "_blank");
          })
          .catch(err => {
            setPercentage(undefined);
            stillPolling = false;
            setIsJOBLoading(false);
            showNotification("error", "Oops..", "Errore durante la generazione del report");
          });

        //VA IN POLLINGO
        let counter = 0;
        const id = r.data.id;
        while (stillPolling) {
          apiReportPResencesStatus(id)
            .then(res => {
              //@ts-ignore
              if (res.data.status === "completed") {
                stillPolling = false;
                setPercentage(undefined);
              } else {
                //@ts-ignore
                setPercentage(res.data.msg);
              }
            })
            .catch(e => {
              setPercentage(undefined);
              stillPolling = false;
              setIsJOBLoading(false);
              showNotification("error", "Oops..", "Errore durante la generazione del report");
            });

          if (counter > 90) {
            stillPolling = false;
            setPercentage(undefined);
          }

          counter++;
          await sleep(2000);
        }
      })
      .catch(e => {
        setPercentage(undefined);
        stillPolling = false;
        setIsShowLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  }

  const goBack = () => setReportData(undefined);

  const handleSaveHolidays = async (values: FormProps) => {
    setIsHolidayCalendarLoading(true);
    const obj = {
      ...values,
      //@ts-ignore
      date_from: values.date[0].format("YYYY-MM-DD"),
      //@ts-ignore
      date_to: values.date[1].format("YYYY-MM-DD"),
      //@ts-ignore
      office_ids: values.office_ids.some(o => o === "all") ? [] : values.office_ids,
    };
    console.log(obj);
    apiClient
      .post(API_ENDPOINTS.HOLIDAY_CALENDAR, obj)
      .then(res => {
        showNotification("success", "Successo", "Nuova festività creata con successo");
        form.resetFields();
        setIsHolidayCalendarLoading(false);
        updateHolidayCalendar();
      })
      .catch(err => {
        showNotification("error", "Oops..", err.response.data.message);
        setIsHolidayCalendarLoading(false);
      });
  };

  const exportAnomalie = () => {
    setIsAnomalieLoading(true);
    const users = getUsersArray(selectedValuesAnomalie);
    apiClient
      .post(
        process.env.REACT_APP_API_URL + "/export_payslip",
        {
          user_ids: users,
          date_from: dateFromAnomalie.format("YYYY-MM-DD"),
          date_to: dateToAnomalie.format("YYYY-MM-DD"),
          export_type_id: TPresenceExportType.ANOMALIE,
        },
        {
          responseType: "blob",
        },
      )
      .then(res => {
        setIsAnomalieLoading(false);
        //@ts-ignore
        const url = URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("target", "_blank");
        link.setAttribute(
          "download",
          "Export Anomalie " +
            `${dateFromAnomalie.format("YYYY-MM-DD")}` +
            " - " +
            `${dateToAnomalie.format("YYYY-MM-DD")}` +
            ".pdf",
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        setIsAnomalieLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportFerie = () => {
    setIsFerieLoading(true);
    const users = getUsersArray(selectedValuesFerie);
    apiClient
      .post(
        process.env.REACT_APP_API_URL + "/export_payslip",
        {
          user_ids: users,
          date_from: dateFromFerie.format("YYYY-MM-DD"),
          date_to: dateToFerie.format("YYYY-MM-DD"),
          export_type_id: TPresenceExportType.FERIE,
        },
        {
          responseType: "blob",
        },
      )
      .then(res => {
        setIsFerieLoading(false);
        //@ts-ignore
        const url = URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("target", "_blank");
        link.setAttribute(
          "download",
          "Export Ferie " +
            `${dateFromFerie.format("YYYY-MM-DD")}` +
            " - " +
            `${dateToFerie.format("YYYY-MM-DD")}` +
            ".pdf",
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        setIsFerieLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportMalattie = () => {
    setIsMalattieLoading(true);
    const users = getUsersArray(selectedValuesMalattie);
    apiClient
      .post(
        process.env.REACT_APP_API_URL + "/export_payslip",
        {
          user_ids: users,
          date_from: dateFromMalattie.format("YYYY-MM-DD"),
          date_to: dateToMalattie.format("YYYY-MM-DD"),
          export_type_id: TPresenceExportType.MALATTIA,
        },
        {
          responseType: "blob",
        },
      )
      .then(res => {
        setIsMalattieLoading(false);

        //@ts-ignore
        const url = URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("target", "_blank");
        link.setAttribute(
          "download",
          "Export Malattie " +
            `${dateFromMalattie.format("YYYY-MM-DD")}` +
            " - " +
            `${dateToMalattie.format("YYYY-MM-DD")}` +
            ".pdf",
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        setIsMalattieLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  const exportReportGiustificativi = () => {
    console.log(justificativeTypeId);
    if(justificativeTypeId === "" || justificativeTypeId == undefined){
      showNotification("error", "Oops..", "Selezionare un giustificativo.");
      return
    }

    setIsReportGiustificativiLoading(true);
    const users = getUsersArray(selectedValuesReportGiustificativi);
    apiClient
      .post(
        process.env.REACT_APP_API_URL + "/export_payslip",
        {
          user_ids: users,
          date_from: dateFromReportGiustificativi.format("YYYY-MM-DD"),
          date_to: dateToReportGiustificativi.format("YYYY-MM-DD"),
          export_type_id: TPresenceExportType.REPORT_GIUSTIFICATIVI,
          justificative_type_id: justificativeTypeId 
        },
        {
          responseType: "blob",
        },
      )
      .then(res => {
        setIsReportGiustificativiLoading(false);

        //@ts-ignore
        const url = URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("target", "_blank");
        link.setAttribute(
          "download",
          "Export Report Giustificativi " +
            `${dateFromReportGiustificativi.format("YYYY-MM-DD")}` +
            " - " +
            `${dateToReportGiustificativi.format("YYYY-MM-DD")}` +
            ".pdf",
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        setIsReportGiustificativiLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col style={{ display: "inline-flex", alignItems: "baseline" }}>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Report e Analitiche
          </UITitle>
          {canViewBlockMonth && (
            <span
              style={{
                fontSize: "18px",
                color: BaseColor,
                cursor: "pointer",
                marginLeft: "10px",
                position: "relative",
                top: "-3px",
              }}
              onClick={() => setIsBlockMonthVisible(!isBlockMonthVisible)}
            >
              <SettingOutlined />
            </span>
          )}
        </Col>
      </Row>
      {isBlockMonthVisible && (
        <UITransition>
          <Row gutter={[8, 8]}>
            {canViewBlockMonth && (
              <Col span={6}>
                <UICard style={{ marginBottom: "8px" }}>
                  <Row justify="space-between" style={{ marginBottom: "15px" }}>
                    <Col>
                      <UITitle color={BaseColor} level={4}>
                        Disabilita mesi
                      </UITitle>
                    </Col>
                    <Col
                      style={{ color: BaseColor, cursor: "pointer", fontSize: "15px" }}
                      onClick={() => setIsBlockMonthVisible(false)}
                    >
                      <CloseOutlined /> Chiudi
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: "7px",
                        }}
                      >
                        {isBlockMonthLoading ? (
                          <div style={{ padding: "10px", width: "300px" }}>
                            <Skeleton />
                          </div>
                        ) : (
                          <CustomMonthCalendar
                            fullscreen={false}
                            headerRender={({ value, type, onChange, onTypeChange }) => {
                              const start = 0;
                              const end = 12;
                              const monthOptions = [];

                              let current = value.clone();
                              const localeData = value.localeData();
                              const months = [];
                              for (let i = 0; i < 12; i++) {
                                current = current.month(i);
                                months.push(localeData.monthsShort(current));
                              }

                              for (let i = start; i < end; i++) {
                                monthOptions.push(
                                  <UISelect.Option key={i} value={i} className="month-item">
                                    {months[i]}
                                  </UISelect.Option>,
                                );
                              }

                              const year = value.year();
                              const month = value.month();
                              const options = [];
                              for (let i = year - 10; i < year + 10; i += 1) {
                                options.push(
                                  <UISelect.Option key={i} value={i} className="year-item">
                                    {i}
                                  </UISelect.Option>,
                                );
                              }
                              return (
                                <div style={{ padding: 8, textAlign: "center" }}>
                                  <Row gutter={8} justify="center">
                                    <Col>
                                      <Row justify="center">
                                        <span
                                          className="back-arrow"
                                          onClick={() => {
                                            onChange(value.clone().subtract(1, "year"));
                                          }}
                                        >
                                          <LeftCircleOutlined />
                                        </span>
                                        <span className="year-name">
                                          {value.clone().format("YYYY")}
                                        </span>
                                        <span
                                          className="next-arrow"
                                          onClick={() => {
                                            onChange(value.clone().add(1, "year"));
                                          }}
                                        >
                                          <RightCircleOutlined />
                                        </span>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                            mode="year"
                            monthFullCellRender={(date: moment.Moment) => {
                              const formattedDate = date.format("YYYY-MM-DD");
                              const isBlocked = !!blockMonths.find(e => e == formattedDate);
                              return (
                                <Tooltip
                                  title={`Il mese di ${date.format("MMMM")} è attualmente ${
                                    isBlocked ? "blo" : "sblo"
                                  }ccato`}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "80%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "7px",
                                      cursor: "pointer",
                                      border: "1px solid white",
                                      backgroundColor: isBlocked ? "#f0f0f0" : "white",
                                    }}
                                    onClick={() => {
                                      getConfig(formattedDate, isBlocked ? "unblock" : "block");
                                    }}
                                  >
                                    {date.format("MMM")}
                                  </div>
                                </Tooltip>
                              );
                            }}
                          />
                        )}
                      </div>
                    </Col>
                    <Col></Col>
                  </Row>
                </UICard>
              </Col>
            )}
            {!canViewHolidayCalendar && (
              <Col span={18}>
                <UICard style={{ marginBottom: "8px" }}>
                  <Row justify="space-between" style={{ marginBottom: "15px" }}>
                    <Col>
                      <UITitle color={BaseColor} level={4}>
                        Calendario festività
                      </UITitle>
                    </Col>
                    <Col
                      style={{ color: BaseColor, cursor: "pointer", fontSize: "15px" }}
                      onClick={() => setIsBlockMonthVisible(false)}
                    >
                      <CloseOutlined /> Chiudi
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]}>
                    <Col span={8}>
                      {isHolidayCalendarLoading ? (
                        <div style={{ padding: "10px" }}>
                          <Skeleton />
                        </div>
                      ) : (
                        <CustomHolidayCalendar
                          style={{ borderRadius: "7px" }}
                          fullscreen={false}
                          //@ts-ignore
                          headerRender={({ value, type, onChange, onTypeChange }) => {
                            const current = moment(value.clone());
                            return (
                              <Row justify="center" style={{ padding: "8px" }}>
                                <span
                                  className="back-arrow"
                                  onClick={() => {
                                    onChange(current.clone().subtract(1, "month"));
                                  }}
                                >
                                  <LeftCircleOutlined />
                                </span>
                                <span
                                  className="month-name"
                                  data-date={current.clone().format("YYYY-MM-DD")}
                                >
                                  {current.clone().format("MMMM YYYY")}
                                </span>
                                <span
                                  className="next-arrow"
                                  onClick={() => {
                                    onChange(current.clone().add(1, "month"));
                                  }}
                                >
                                  <RightCircleOutlined />
                                </span>
                              </Row>
                            );
                          }}
                          dateFullCellRender={(value: moment.Moment) => {
                            const data = holidayCalendar.filter(
                              e => e.date == value.format("YYYY-MM-DD"),
                            );
                            if (!data.length) return <span>{value.format("DD")}</span>;
                            const bgColor = BaseColor;
                            return (
                              <>
                                <Tooltip key={data[0].id} title={data[0].description}>
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setSelectedHolidayCalendar(data[0].date)}
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor: bgColor,
                                      }}
                                    >
                                      {value.format("DD")}
                                    </Avatar>
                                  </span>
                                </Tooltip>
                              </>
                            );
                          }}
                        />
                      )}
                    </Col>
                    <Col span={16}>
                      {!!selectedHolidayCalendar ? (
                        <>
                          <Row gutter={[16, 16]}>
                            <Col style={{ paddingTop: "8px" }}>
                              <span
                                onClick={() => setSelectedHolidayCalendar(undefined)}
                                style={{ cursor: "pointer", color: BaseColor }}
                              >
                                <LeftOutlined />
                              </span>
                            </Col>
                            <Col>
                              <UITitle level={4} color={BaseColor}>
                                {getHolidayByDate(selectedHolidayCalendar)?.description}
                              </UITitle>
                            </Col>
                          </Row>
                          <Row gutter={[16, 16]} style={{ marginBottom: "18px" }}>
                            <Col>
                              <UITitle level={4}>Periodo: </UITitle>
                              <UIText>
                                {!!getHolidayByDate(selectedHolidayCalendar)?.date_from
                                  ? moment(
                                      getHolidayByDate(selectedHolidayCalendar)?.date_from,
                                    ).format("DD/MM/YYYY") +
                                    " - " +
                                    moment(
                                      getHolidayByDate(selectedHolidayCalendar)?.date_to,
                                    ).format("DD/MM/YYYY")
                                  : moment(getHolidayByDate(selectedHolidayCalendar)?.date).format(
                                      "DD/MM/YYYY",
                                    )}
                              </UIText>
                            </Col>
                          </Row>
                          <Row gutter={[16, 16]} style={{ marginBottom: "18px" }}>
                            <Col>
                              <UITitle level={4}>Ricorsivo: </UITitle>
                              <UIText>
                                {!!getHolidayByDate(selectedHolidayCalendar)?.ricorsivo
                                  ? "Si"
                                  : "No"}
                              </UIText>
                            </Col>
                          </Row>
                          <Row gutter={[16, 16]} style={{ marginBottom: "18px" }}>
                            <Col>
                              <UITitle level={4}>Sedi: </UITitle>
                              <UIText>
                                {getHolidayByDate(selectedHolidayCalendar)
                                  ?.offices?.map(e => offices.data.find(el => el.id == e)?.name)
                                  .join(", ")
                                  .toString()}
                              </UIText>
                            </Col>
                          </Row>
                          <Row gutter={[16, 16]} style={{ marginBottom: "18px" }}>
                            <Col>
                              <UIButton
                                onClick={() =>
                                  onRemoveHoliday(getHolidayByDate(selectedHolidayCalendar)?.date)
                                }
                                style={{
                                  borderRadius: "7px",
                                  backgroundColor: "#e00102",
                                  borderColor: "#e00102",
                                  color: "white",
                                }}
                              >
                                Elimina{" "}
                                {!!getHolidayByDate(selectedHolidayCalendar)?.date_from
                                  ? "Periodo"
                                  : "Festività"}
                              </UIButton>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row>
                            <UITitle level={4} color={BaseColor}>
                              Aggiungi festività
                            </UITitle>
                          </Row>
                          <Form layout="vertical" form={form} onFinish={handleSaveHolidays}>
                            <Row gutter={[16, 16]}>
                              <Col span={24}>
                                <Row gutter={[16, 16]}>
                                  <Col span={12}>
                                    <Form.Item
                                      name="description"
                                      label="Descrizione"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Inserisci il nome della festività",
                                        },
                                      ]}
                                    >
                                      <UIInput />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      name="is_recurrent"
                                      label="E' ricorrente?"
                                      valuePropName="checked"
                                      rules={[{ required: false, message: "Si ripete ogni anno?" }]}
                                    >
                                      <StyledCheckbox />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                  <Col span={12}>
                                    <Form.Item
                                      name="date"
                                      label="Periodo"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Seleziona il periodo della festività",
                                        },
                                      ]}
                                    >
                                      <CustomRangePicker
                                        //@ts-ignore
                                        showTime={false}
                                        style={{ width: "100%" }}
                                        format={"DD/MM/YYYY"}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      name="office_ids"
                                      label="Sedi"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Seleziona le sedi per cui è valida la festività",
                                        },
                                      ]}
                                    >
                                      <UISelect
                                        mode="multiple"
                                        placeholder="Seleziona le sedi"
                                        style={{ minHeight: "60px" }}
                                      >
                                        <UISelect.Option key="all" value={"all"}>
                                          Tutte
                                        </UISelect.Option>
                                        {offices.data.map(e => (
                                          <UISelect.Option key={e.id} value={e.id}>
                                            {e.name}
                                          </UISelect.Option>
                                        ))}
                                      </UISelect>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row justify="end">
                              <Col>
                                <Form.Item>
                                  <UIButton
                                    type="primary"
                                    htmlType="submit"
                                    loading={isHolidayCalendarLoading}
                                  >
                                    Aggiungi
                                  </UIButton>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      )}
                    </Col>
                  </Row>
                </UICard>
              </Col>
            )}
          </Row>
        </UITransition>
      )}
      {!!reportData ? (
        <UICard>
          <Row justify="start" style={{ marginBottom: "15px" }}>
            <Col style={{ color: BaseColor, cursor: "pointer", fontSize: "17px" }} onClick={goBack}>
              <LeftCircleOutlined /> Nuovo Report{" "}
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginBottom: "15px" }}>
            <span>
              <UIInput
                value={currentQuery}
                onChange={e => setQuery(e.target.value)}
                suffix={isQueryLoading ? <LoadingOutlined /> : null}
                placeholder={"Cerca Dipendente"}
                allowClear
                prefix={<SearchOutlined style={{ marginRight: "5px", color: "#d9d9d9" }} />}
              />
            </span>
            <span style={{ display: "inline-flex" }}>
              <Col style={{ marginRight: "5px", marginBottom: "5px" }}>
                <UIButton
                  onClick={exportCsvWithCache}
                  loading={isCSVLoading}
                  style={{ backgroundColor: "#5c894b", borderColor: "#1e5107", color: "#fff" }}
                  hoverBgColor="#7f9d74!important"
                >
                  <FileExcelOutlined /> Esporta in CSV
                </UIButton>
              </Col>
              {canExportTS && (
                <Col style={{ marginRight: "5px", marginBottom: "5px" }}>
                  <UIButton
                    onClick={exportTeamSystemWithCache}
                    loading={isTeamSystemLoading}
                    style={{
                      backgroundImage: "linear-gradient(to right ,#17509e, #e95756)",
                      borderColor: "#001d70",
                      color: "#fff",
                    }}
                    hoverBgColor="#0245a2!important"
                  >
                    <DeliveredProcedureOutlined /> Esporta per TS
                  </UIButton>
                </Col>
              )}
              {canExportZucchetti && (
                <Col style={{ marginRight: "10px", marginBottom: "3px" }}>
                  <UIButton
                    onClick={exportZucchettiWithCache}
                    loading={isZucchettiLoading}
                    style={{
                      backgroundColor: "#0072bc",
                      borderColor: "#001d70",
                      color: "#fff",
                    }}
                    hoverBgColor="#0245a2!important"
                  >
                    <SwapOutlined /> Esporta per Zucchetti
                  </UIButton>
                </Col>
              )}
              <Col>
                <UIButton type="primary" onClick={handlePrint}>
                  <PrinterOutlined /> Stampa
                </UIButton>
              </Col>
            </span>
          </Row>
          <span ref={componentRef}>
            <style>
              {`
                @media print {
                  .user-row{
                    margin: 0px 5px;
                    page-break-before: auto;
                  }
                  .ant-typography{
                    font-size: 10px;
                  }
                }
                `}
            </style>

            {reportDataFiltered && (
              <LazyList>
                {reportDataFiltered.map(value => (
                  <UserRow dataRow={value} />
                ))}
              </LazyList>
            )}
          </span>
        </UICard>
      ) : (
        <>
          <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
            {canViewPresenze && (
              <Col lg={12} xs={24}>
                <UICard>
                  <Row>
                    <UITitle color={BaseColor} level={4}>
                      Report Presenze
                    </UITitle>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <UITitle level={5}>Seleziona un periodo</UITitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <CustomRangePicker
                            //@ts-ignore
                            onChange={changeDates}
                            format="DD/MM/YYYY"
                            clearIcon={false}
                            value={[dateFrom, dateTo]}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "15px" }}>
                        <Col span={24}>
                          <UITitle level={5}>Seleziona utenti</UITitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <CustomCascader
                            style={{ width: "100%" }}
                            loading={!!isUserLoading}
                            notFoundContent={
                              isUserLoading ? (
                                <Spin size="small" />
                              ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              )
                            }
                            showSearch
                            options={options}
                            //@ts-ignore
                            onChange={onChangeFilters}
                            multiple
                            value={selectedValues}
                            placeholder={
                              <span>
                                <SearchOutlined style={{ marginRight: "5px" }} /> - Tutti -
                              </span>
                            }
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "15px" }}>
                        <Col style={{ marginRight: "10px", marginBottom: "3px" }}>
                          <UIButton loading={isShowLoading} type="primary" onClick={exportPlain}>
                            <EyeOutlined /> Visualizza
                          </UIButton>
                        </Col>
                        <Col style={{ marginRight: "10px", marginBottom: "3px" }}>
                          <UIButton
                            onClick={exportCsv}
                            loading={isCSVLoading}
                            style={{
                              backgroundColor: "#4b8389",
                              borderColor: "#07512a",
                              color: "#fff",
                            }}
                            hoverBgColor="#62a3aa!important"
                          >
                            <ContainerOutlined /> Esporta CSV
                          </UIButton>
                        </Col>
                        {canExportXls && (
                          <Col style={{ marginRight: "10px", marginBottom: "3px" }}>
                            <UIButton
                              onClick={exportXls}
                              loading={isXlsLoading}
                              style={{
                                backgroundColor: "#5c894b",
                                borderColor: "#1e5107",
                                color: "#fff",
                              }}
                              hoverBgColor="#7f9d74!important"
                            >
                              <FileExcelOutlined /> Esporta XLS
                            </UIButton>
                          </Col>
                        )}
                        {canExportTS && (
                          <Col style={{ marginRight: "10px", marginBottom: "3px" }}>
                            <UIButton
                              onClick={exportTeamSystem}
                              loading={isTeamSystemLoading}
                              style={{
                                backgroundImage: "linear-gradient(to right ,#17509e, #e95756)",
                                borderColor: "#001d70",
                                color: "#fff",
                              }}
                              hoverBgColor="#0245a2!important"
                            >
                              <DeliveredProcedureOutlined /> Esporta Tracciato TS
                            </UIButton>
                          </Col>
                        )}
                        {canExportZucchetti && (
                          <Col style={{ marginRight: "10px", marginBottom: "3px" }}>
                            <UIButton
                              onClick={exportZucchetti}
                              loading={isZucchettiLoading}
                              style={{
                                backgroundColor: "#0072bc",
                                borderColor: "#001d70",
                                color: "#fff",
                              }}
                              hoverBgColor="#0245a2!important"
                            >
                              <SwapOutlined /> Esporta per Zucchetti
                            </UIButton>
                          </Col>
                        )}
                        {canExportJOB && (
                          <Col style={{ marginRight: "10px", marginBottom: "3px" }}>
                            <UIButton
                              onClick={exportJOB}
                              loading={isJOBLoading}
                              style={{
                                backgroundColor: "#e95756",
                                borderColor: "#001d70",
                                color: "#fff",
                              }}
                              hoverBgColor="#ED7877!important">
                              <DeliveredProcedureOutlined /> Esporta Tracciato JOB
                            </UIButton>
                          </Col>
                        )}
                        <MissingUsersModal
                          isModalVisible={showUtentiMancantiModal}
                          closeModal={() => setUtentiMancanti([])}
                          missingUsers={utentiMancanti}
                        />
                      </Row>
                      {!!percentage && (
                        <Row>
                          <Col span={24}>
                            <ProgressCustom
                              percent={parseInt(percentage)}
                              strokeColor={BaseColor}
                              style={{ width: "100%", marginTop: "15px" }}
                              status="active"
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </UICard>
              </Col>
            )}
            {canExportProd && (
              <Col lg={12} xs={24}>
                <UICard>
                  <Row>
                    <UITitle color={BaseColor} level={4}>
                      Report Produttività
                    </UITitle>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UITitle level={5}>Seleziona un periodo</UITitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CustomRangePicker
                        //@ts-ignore
                        onChange={changeDatesProd}
                        format="DD/MM/YYYY"
                        clearIcon={false}
                        value={[dateFromProd, dateToProd]}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col span={24}>
                      <UITitle level={5}>Seleziona utenti</UITitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <CustomCascader
                        style={{ width: "100%" }}
                        loading={!!isUserLoading}
                        notFoundContent={
                          isUserLoading ? (
                            <Spin size="small" />
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )
                        }
                        showSearch
                        options={options}
                        //@ts-ignore
                        onChange={onChangeFiltersProd}
                        multiple
                        value={selectedValuesProd}
                        placeholder={
                          <span>
                            <SearchOutlined style={{ marginRight: "5px" }} /> - Tutti -
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col>
                      <UIButton
                        onClick={exportCsvProd}
                        loading={isCSVProdLoading}
                        style={{
                          backgroundColor: "#4b8389",
                          borderColor: "#07512a",
                          color: "#fff",
                        }}
                        hoverBgColor="#62a3aa!important"
                      >
                        <FileExcelOutlined /> Esporta CSV
                      </UIButton>
                    </Col>
                    {canExportXlsProduttivita && (
                      <Col style={{ marginLeft: "10px", marginBottom: "3px" }}>
                        <UIButton
                          onClick={exportXlsProd}
                          loading={isXlsProdLoading}
                          style={{
                            backgroundColor: "#5c894b",
                            borderColor: "#1e5107",
                            color: "#fff",
                          }}
                          hoverBgColor="#7f9d74!important"
                        >
                          <FileExcelOutlined /> Esporta XLS
                        </UIButton>
                      </Col>
                    )}
                  </Row>
                  {!!percentageProd && (
                    <Row>
                      <Col span={24}>
                        <ProgressCustom
                          percent={parseInt(percentageProd)}
                          strokeColor={BaseColor}
                          style={{ width: "100%", marginTop: "15px" }}
                          status="active"
                        />
                      </Col>
                    </Row>
                  )}
                </UICard>
              </Col>
            )}
          </Row>

          <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
            {canViewAnomalie && (
              <Col lg={8} xs={24}>
                <UICard>
                  <Row>
                    <UITitle color={BaseColor} level={4}>
                      Report Anomalie
                    </UITitle>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UITitle level={5}>Seleziona un periodo</UITitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CustomRangePicker
                        //@ts-ignore
                        onChange={changeDatesAnomalie}
                        format="DD/MM/YYYY"
                        clearIcon={false}
                        value={[dateFromAnomalie, dateToAnomalie]}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col span={24}>
                      <UITitle level={5}>Seleziona utenti</UITitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <CustomCascader
                        style={{ width: "100%" }}
                        loading={!!isUserLoading}
                        notFoundContent={
                          isUserLoading ? (
                            <Spin size="small" />
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )
                        }
                        showSearch
                        options={options}
                        //@ts-ignore
                        onChange={onChangeFiltersAnomalie}
                        multiple
                        value={selectedValuesAnomalie}
                        placeholder={
                          <span>
                            <SearchOutlined style={{ marginRight: "5px" }} /> - Tutti -
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col>
                      <UIButton onClick={exportAnomalie} loading={isAnomalieLoading} type="primary">
                        Esporta Anomalie
                      </UIButton>
                    </Col>
                  </Row>
                </UICard>
              </Col>
            )}
            {canViewFerie && (
              <Col lg={8} xs={24}>
                <UICard>
                  <Row>
                    <UITitle color={BaseColor} level={4}>
                      Report Ferie
                    </UITitle>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UITitle level={5}>Seleziona un periodo</UITitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CustomRangePicker
                        //@ts-ignore
                        onChange={changeDatesFerie}
                        format="DD/MM/YYYY"
                        clearIcon={false}
                        value={[dateFromFerie, dateToFerie]}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col span={24}>
                      <UITitle level={5}>Seleziona utenti</UITitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <CustomCascader
                        style={{ width: "100%" }}
                        loading={!!isUserLoading}
                        notFoundContent={
                          isUserLoading ? (
                            <Spin size="small" />
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )
                        }
                        showSearch
                        options={options}
                        //@ts-ignore
                        onChange={onChangeFiltersFerie}
                        multiple
                        value={selectedValuesFerie}
                        placeholder={
                          <span>
                            <SearchOutlined style={{ marginRight: "5px" }} /> - Tutti -
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col>
                      <UIButton
                        onClick={exportFerie}
                        loading={isFerieLoading}
                        style={{
                          backgroundColor: "rgb(233 171 79)",
                          borderColor: "#906c36",
                          color: "#fff",
                        }}
                        hoverBgColor="#ebba71!important"
                      >
                        Esporta Ferie
                      </UIButton>
                    </Col>
                  </Row>
                </UICard>
              </Col>
            )}
            {canViewMalattie && (
              <Col lg={8} xs={24}>
                <UICard>
                  <Row>
                    <UITitle color={BaseColor} level={4}>
                      Report Malattie
                    </UITitle>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UITitle level={5}>Seleziona un periodo</UITitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CustomRangePicker
                        //@ts-ignore
                        onChange={changeDatesMalattie}
                        format="DD/MM/YYYY"
                        clearIcon={false}
                        value={[dateFromMalattie, dateToMalattie]}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col span={24}>
                      <UITitle level={5}>Seleziona utenti</UITitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <CustomCascader
                        style={{ width: "100%" }}
                        loading={!!isUserLoading}
                        notFoundContent={
                          isUserLoading ? (
                            <Spin size="small" />
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )
                        }
                        showSearch
                        options={options}
                        //@ts-ignore
                        onChange={onChangeFiltersMalattie}
                        multiple
                        value={selectedValuesMalattie}
                        placeholder={
                          <span>
                            <SearchOutlined style={{ marginRight: "5px" }} /> - Tutti -
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col>
                      <UIButton
                        onClick={exportMalattie}
                        loading={isMalattieLoading}
                        //@ts-ignore
                        type="danger"
                      >
                        Esporta Malattie
                      </UIButton>
                    </Col>
                  </Row>
                </UICard>
              </Col>
            )}
            {canViewReportGiustificativi && (
            <Col lg={8} xs={24}>
              <UICard>
                <Row>
                  <UITitle color={BaseColor} level={4}>
                    Report Giustificativi
                  </UITitle>
                </Row>
                <Row>
                  <Col span={24}>
                    <UITitle level={5}>Tipologia Richiesta</UITitle>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="justificative_type_id"
                      rules={[{ required: true, message: "Seleziona la tipologia della richiesta" }]}
                    >
                      <UISelect onChange={onChangeJustificativeTypeId}>
                        {justificatives.data.map(justificative => (
                          <Option value={justificative.id} key={justificative.id}>
                            {justificative.name}
                          </Option>
                        ))}
                      </UISelect>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <UITitle level={5}>Seleziona un periodo</UITitle>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CustomRangePicker
                      //@ts-ignore
                      onChange={changeDatesReportGiustificativi}
                      format="DD/MM/YYYY"
                      clearIcon={false}
                      value={[dateFromReportGiustificativi, dateToReportGiustificativi]}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col span={24}>
                    <UITitle level={5}>Seleziona utenti</UITitle>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CustomCascader
                      style={{ width: "100%" }}
                      loading={!!isUserLoading}
                      notFoundContent={
                        isUserLoading ? (
                          <Spin size="small" />
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )
                      }
                      showSearch
                      options={options}
                      //@ts-ignore
                      onChange={onChangeFiltersReportGiustificativi}
                      multiple
                      value={selectedValuesReportGiustificativi}
                      placeholder={
                        <span>
                          <SearchOutlined style={{ marginRight: "5px" }} /> - Tutti -
                        </span>
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col>
                    <UIButton
                      onClick={exportReportGiustificativi}
                      loading={isReportGiustificativiLoading}
                      //@ts-ignore
                      type="danger"
                    >
                      Esporta Report
                    </UIButton>
                  </Col>
                </Row>
              </UICard>
            </Col>
            )}
          </Row>
          <Row gutter={[16, 16]}>
            {canViewAnalytics && (
              <>
                <Col lg={8} xs={24}>
                  <UICard>
                    <Row justify="space-between">
                      <Col>
                        <UITitle color={BaseColor} level={4}>
                          Richieste
                        </UITitle>
                      </Col>
                      <Col>
                        <UIDatePicker
                          onChange={(value: moment.Moment | null): void => setMonthRichieste(value)}
                          value={monthRichieste}
                          picker="month"
                          format="MMMM"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <RequestStatistics date={monthRichieste} />
                      </Col>
                    </Row>
                  </UICard>
                </Col>

                <Col lg={8} xs={24}>
                  <UICard>
                    <Row justify="space-between">
                      <Col>
                        <UITitle color={BaseColor} level={4}>
                          Turni
                        </UITitle>
                      </Col>
                      <Col>
                        <UIDatePicker
                          onChange={(value: moment.Moment | null): void => setMonthTurni(value)}
                          value={monthTurni}
                          picker="month"
                          format="MMMM"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <ShiftStatistics date={monthTurni} />
                      </Col>
                    </Row>
                  </UICard>
                </Col>
                <Col lg={8} xs={24}>
                  <UICard>
                    <Row justify="space-between">
                      <Col>
                        <UITitle color={BaseColor} level={4}>
                          Presenze
                        </UITitle>
                      </Col>
                      <Col>
                        <UIDatePicker
                          onChange={(value: moment.Moment | null): void => setMonthPresenze(value)}
                          value={monthPresenze}
                          picker="month"
                          format="MMMM"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <PresenceStatistics date={monthPresenze} />
                      </Col>
                    </Row>
                  </UICard>
                </Col>
              </>
            )}
          </Row>
        </>
      )}
    </UITransition>
  );
};

const CustomCascader = styled(Cascader)`
  .ant-select-selector {
    border-radius: 7px !important;
  }
`;

const ProgressCustom = styled(Progress)`
  font-size: 16px !important;
  .ant-progress-inner {
    height: 11px;
  }

  .ant-progress-bg {
    height: 11px !important;
  }
`;

const CustomMonthCalendar = styled(Calendar)`
  .back-arrow {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: ${BaseColor};
  }

  .next-arrow {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: ${BaseColor};
  }

  .year-name {
    font-size: 17px;
    font-weight: bold;
    margin: 0px 20px;
    text-transform: capitalize;
    color: ${BaseColor};
  }
  .ant-picker-calendar-mini {
    border-radius: 7px !important;
  }
  .ant-picker-calendar-mini .ant-picker-panel {
    border-radius: 0 0 7px 7px !important;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 8px 8px !important;
  }
`;

const CustomHolidayCalendar = styled(CustomCalendar)`
  .ant-picker-date-panel {
    margin-top: 8px !important;
  }
`;

const StyledCheckbox = styled(UICheckbox)`
  .ant-checkbox-inner {
    width: 32px;
    height: 32px;
    border-radius: 7px;
  }
  .ant-checkbox-inner:after {
    left: 10px;
  }
  .ant-checkbox {
    font-size: 0px;
  }
`;
