/** @format */

import { Select } from "antd";
import styled from "styled-components";

export const UISelect = styled(Select)`
  border-radius: 7px;

  .ant-select-selector {
    border-radius: 7px !important;
  }
  .ant-select-selection-item {
    border-radius: 7px;
  }
`;
