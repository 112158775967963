/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TFleetCreateRequest,
  TFleetUpdateRequest,
} from "../redux-modules/declarations/maps/fleets";
import apiClient from "../utils/apiClient";

export const apiFleetList = (): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.FLEETS);
};

export const apiFleetCreate = (fleet: TFleetCreateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.post(API_ENDPOINTS.FLEETS, fleet);

export const apiFleetUpdate = (fleet: TFleetUpdateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.FLEETS + "/" + fleet.id, fleet);

export const apiFleetDelete = (fleetId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.FLEETS + "/" + fleetId);
