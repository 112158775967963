/** @format */

import { Col, Row } from "antd";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { selectorPermissionList } from "../../../../redux-modules/selectors/selectorsPermissions";
import { TUserDocument } from "../../../../utils/format/userDocumentsTable";
import { hasPermission } from "../../../../utils/permissions/hasPermission";
import { UIFinder } from "../../../ui/Finder";

interface Props {
  onAddDocument: () => void;
  documents: TUserDocument[];
  user_id: string;
  canCreate?: boolean;
}

export const DocumentsTabEdit: FC<Props> = ({
  onAddDocument,
  documents,
  user_id,
  canCreate,
}: Props): JSX.Element => {
  const permissionsList = useSelector(selectorPermissionList);
  const canCreateDocument = hasPermission(permissionsList, "PersonalDocument", "Create");
  return (
    <>
      <Row style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Col xs={24}>
          <UIFinder
            documents={documents}
            canCreate={canCreate}
            showExpiring
            onAddFile={canCreateDocument ? onAddDocument : undefined}
            personalDocumentUserId={user_id}
          />
        </Col>
      </Row>
    </>
  );
};
