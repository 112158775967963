/** @format */

import { DatePicker } from "antd";
import styled from "styled-components";
const { RangePicker } = DatePicker;

export const UIRangePicker = styled(RangePicker)`
  border-radius: 7px;
  width: 100%;
`;
