/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import {
  actionTravelCreateSuccess,
  actionTravelCreateReset,
  actionTravelCreateFailure,
  actionTravelListLoadingRequest,
  actionTravelListLoadingSuccess,
  actionTravelListFailure,
  actionTravelListLoadingFailure,
  actionTravelUpdateSuccess,
  actionTravelUpdateReset,
  actionTravelUpdateFailure,
  actionTravelDeleteSuccess,
  actionTravelDeleteReset,
  actionTravelDeleteFailure,
  actionTravelListSuccess,
} from "../actions/actionsTravels";
import {
  TActionTravelCreateRequest,
  TActionTravelListRequest,
  TActionTravelUpdateRequest,
  TActionTravelDeleteRequest,
} from "../declarations/actions/travels";
import { TTravel } from "../declarations/maps/travels";
import {
  apiTravelCreate,
  apiTravelUpdate,
  apiTravelDelete,
  apiTravelList,
} from "../../api/apiTravels";

export function* sagaTravelCreate(action: TActionTravelCreateRequest): unknown {
  try {
    const response = yield call(apiTravelCreate, action.payload);

    yield put(actionTravelCreateSuccess(response.data.id));
    yield put(actionTravelCreateReset());
    showNotification("success", "Creato!", "Viaggio creato con successo");
  } catch (e) {
    yield put(actionTravelCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaTravelList(action: TActionTravelListRequest): unknown {
  try {
    yield put(actionTravelListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TTravel[]>> = yield call(
      apiTravelList,
      action.payload,
    );

    yield put(actionTravelListSuccess(response.data.data));
    yield put(actionTravelListLoadingSuccess());
  } catch (e) {
    yield put(actionTravelListFailure());
    yield put(actionTravelListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaTravelUpdate(action: TActionTravelUpdateRequest): unknown {
  try {
    yield call(apiTravelUpdate, action.payload);

    yield all([put(actionTravelUpdateSuccess()), put(actionTravelUpdateReset())]);
    showNotification("success", "Aggiornato!", "Viaggio modificato con successo");
  } catch (e) {
    yield put(actionTravelUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaTravelDelete(action: TActionTravelDeleteRequest): unknown {
  try {
    yield call(apiTravelDelete, action.payload);

    yield all([put(actionTravelDeleteSuccess(action.payload)), put(actionTravelDeleteReset())]);
    showNotification("success", "Eliminato!", "Viaggio eliminato con successo");
  } catch (e) {
    yield put(actionTravelDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
