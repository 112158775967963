/** @format */

import { Menu, MenuItemProps } from "antd";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { usePermissionForRoute } from "../../../utils/permissions/routeToPermissionMap";
import { Routes } from "../../../utils/Routes";
import { UIText } from "../Text";

interface Props {
  route: Routes | string;
  label: string | JSX.Element;
  icon?: string | JSX.Element;
}

export const UILink = ({ route, label }: Props): JSX.Element => {
  const history = useHistory();
  const hasPermission = usePermissionForRoute(route as Routes);

  if (!hasPermission) return <></>;

  return <StyledLink onClick={() => history.push(route)}>{label}</StyledLink>;
};

const StyledLink = styled.span`
  cursor: pointer;
  color: ${process.env.REACT_APP_THEME_COLOR};
`;

interface PropsItem extends MenuItemProps {
  to: Routes;
  title: string;
}

export const UILinkItem = (props: PropsItem): JSX.Element => {
  const history = useHistory();
  const hasPermission = usePermissionForRoute(props.to as Routes);

  if (!hasPermission) return <></>;

  return (
    <StyledMenuItem onClick={() => history.push(props.to)} {...props}>
      <UIText>{props.title}</UIText>
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled(Menu.Item)`
  cursor: pointer;
  color: ${process.env.REACT_APP_THEME_COLOR};
`;
