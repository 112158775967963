/** @format */

import {
  TActionTravelListFailure,
  TActionTravelListRequest,
  TActionTravelListSuccess,
} from "../declarations/actions/travels";
import { TActionType } from "../declarations/actionTypes";
import { TTravels } from "../declarations/maps/travels";

type Actions = TActionTravelListRequest | TActionTravelListSuccess | TActionTravelListFailure;

export const reducerTravels = (
  prevStore: TTravels = { travelList: [] },
  action: Actions,
): TTravels => {
  switch (action.type) {
    case TActionType.travelListRequest:
      return { ...prevStore, travelList: [] };
    case TActionType.travelListSuccess:
      return { ...prevStore, travelList: action.payload };
    case TActionType.travelListFailure:
      return { ...prevStore, travelList: [] };
    default:
      return prevStore;
  }
};
