/** @format */

import {
  TActionAlertGenerate,
  TActionAlertRemove,
  TActionCustomStylesOptionListFailure,
  TActionCustomStylesOptionListRequest,
  TActionCustomStylesOptionListSuccess,
  TActionCustomStylesOptionUpdateFailure,
  TActionCustomStylesOptionUpdateRequest,
  TActionCustomStylesOptionUpdateReset,
  TActionCustomStylesOptionUpdateSuccess,
  TActionMenuCollapseRequest,
  TActionMenuUncollapseRequest,
  TActionNavigationFailure,
  TActionNavigationRequest,
  TActionNavigationSuccess,
  TActionStaticAlertGenerate,
  TActionStaticAlertRemove,
} from "../declarations/actions/ui";
/** @format */

import { TActionType } from "../declarations/actionTypes";
import { TAlert, TCustomStyleOptions } from "../declarations/maps/ui";

export const actionNavigationRequest = (payload: number): TActionNavigationRequest => ({
  type: TActionType.navigationRequest,
  payload,
});

export const actionNavigationSuccess = (payload: unknown): TActionNavigationSuccess => ({
  type: TActionType.navigationSuccess,
  payload,
});

export const actionNavigationFailure = (): TActionNavigationFailure => ({
  type: TActionType.navigationFailure,
});

// Alerts
export const actionAlertGenerate = (payload: TAlert): TActionAlertGenerate => ({
  type: TActionType.alertGenerate,
  payload,
});

export const actionAlertRemove = (): TActionAlertRemove => ({
  type: TActionType.alertRemove,
});

export const actionStaticAlertGenerate = (payload: TAlert): TActionStaticAlertGenerate => ({
  type: TActionType.staticAlertGenerate,
  payload,
});

export const actionStaticAlertRemove = (): TActionStaticAlertRemove => ({
  type: TActionType.staticAlertRemove,
});

export const actionCollapseRequest = (): TActionMenuCollapseRequest => ({
  type: TActionType.menuCollapseRequest,
});

export const actionUncollapseRequest = (): TActionMenuUncollapseRequest => ({
  type: TActionType.menuUncollapseRequest,
});

export const actionCustomStylesOptionListRequest = (): TActionCustomStylesOptionListRequest => ({
  type: TActionType.CustomStyleOptionListRequest,
});

export const actionCustomStylesOptionListSuccess = (
  payload: TCustomStyleOptions,
): TActionCustomStylesOptionListSuccess => ({
  type: TActionType.CustomStyleOptionListSuccess,
  payload,
});

export const actionCustomStylesOptionListFailure = (): TActionCustomStylesOptionListFailure => ({
  type: TActionType.CustomStyleOptionListFailure,
});

export const actionCustomStyleOptionUpdateRequest = (
  payload: TCustomStyleOptions,
): TActionCustomStylesOptionUpdateRequest => ({
  type: TActionType.CustomStyleOptionUpdateRequest,
  payload,
});

export const actionCustomStyleOptionUpdateSuccess = (
  payload: TCustomStyleOptions,
): TActionCustomStylesOptionUpdateSuccess => ({
  type: TActionType.CustomStyleOptionUpdateSuccess,
  payload,
});

export const actionCustomStyleOptionUpdateFailure = (): TActionCustomStylesOptionUpdateFailure => ({
  type: TActionType.CustomStyleOptionUpdateFailure,
});

export const actionCustomStyleOptionUpdateReset = (): TActionCustomStylesOptionUpdateReset => ({
  type: TActionType.CustomStyleOptionUpdateReset,
});
