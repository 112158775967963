/** @format */

import { Row, Col } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { ReportData } from "..";
import { UIText } from "../../../ui/Text";

interface Props {
  dataRow: ReportData;
}
export const UserRow = ({ dataRow }: Props): JSX.Element => {
  const dayWidth = 100 / Object.keys(dataRow.date).length;
  return (
    <>
      <Row style={{ marginBottom: "15px" }} className="user-row">
        <Col span={3}>
          <UIText strong>Utente</UIText>
        </Col>
        <Col span={2}>
          <UIText strong>Tipologia</UIText>
        </Col>
        <Col span={18} style={{ display: "flex" }}>
          {Object.keys(dataRow.date).map(date => (
            <span
              style={{
                width: 100 / Object.keys(dataRow.date).length + "%",
              }}
            >
              <UIText strong>{moment(date, "DD/MM/YYYY").format("DD")}</UIText>
            </span>
          ))}
        </Col>
        <Col span={1}>
          <UIText strong>Tot</UIText>
        </Col>
      </Row>
      <Row style={{ marginBottom: "15px", borderTop: "1px solid #d9d9d9" }}>
        <Col span={3}>
          <Row>
            <Col>
              <UIText style={{ fontSize: "17px" }} strong>
                {dataRow.user.surname + " " + dataRow.user.name}
              </UIText>
            </Col>
          </Row>
          <Row>
            <Col>
              <UIText strong>{dataRow.user.fiscal_code}</UIText>
            </Col>
          </Row>
          <Row>
            <Col>
              <UIText strong>Ore da Contr.: {dataRow.user.contract_weekly_hours}</UIText>
            </Col>
          </Row>
          <Row>
            <Col>
              <UIText strong>
                Singola timbratura: {dataRow.user.is_single_badge ? "Si" : "No"}
              </UIText>
            </Col>
          </Row>
          {dataRow.user.is_forfettizzato ? (
            <Row>
              <Col>
                <UIText strong>
                  Utente con Straordinario Forfettizzato
                </UIText>
              </Col>
            </Row>
          ) : null}
        </Col>
        <Col span={2}>
          {Object.keys(Object.values(dataRow.date)[0]).map(key => (
            <Row>
              <Col>
                <UIText>{key}</UIText>
              </Col>
            </Row>
          ))}
        </Col>
        <Col span={18} style={{ display: "flex" }}>
          {Object.values(dataRow.date).map((day, date) => (
            <DayCell style={{ width: dayWidth + "%" }}>
              {Object.values(day).map(just => (
                <Row>
                  <Col>
                    <UIText
                      strong={
                        //@ts-ignore
                        just != 0 && just !== "0.00"
                      }
                      style={{ color: just === "Err." ? "red" : "black" }}
                    >
                      {just}
                    </UIText>
                  </Col>
                </Row>
              ))}
            </DayCell>
          ))}
        </Col>
        <Col span={1}>
          {Object.values(dataRow.totals).map(just => (
            <Row>
              <Col>
                <UIText
                  strong={
                    //@ts-ignore
                    just != 0 && just !== "0.00"
                  }
                >
                  {just}
                </UIText>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
};

const DayCell = styled.div``;
