/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import { TAPIPermissionListRequest } from "../redux-modules/declarations/apis/permissions";
import apiClient from "../utils/apiClient";

export const apiPermissionList = (
  params: TAPIPermissionListRequest,
): Promise<AxiosResponse<unknown>> => apiClient.get(API_ENDPOINTS.PERMISSIONS, { params });
