/** @format */

import { Row, Col } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectorJustificativeList } from "../../../../redux-modules/selectors/selectorsJustificatives";
import { UIButton } from "../../../ui/Button";
import { WidgetCard } from "../RecentDocuments";
import { MyRequestsCalendar } from "./partials/MyRequestsCalendar";
import { NewRequestForm } from "./partials/NewRequestForm";

export const WidgetMyRequest = (): JSX.Element => {
  const [creatingType, setCreatingType] = useState<string | undefined>();
  const justificativeTypes = useSelector(selectorJustificativeList);

  return (
    <>
      <WidgetCard title="Nuova Richiesta">
        <>
          <Row style={{ marginBottom: "20px" }}>
            <Col span={24}>
              {justificativeTypes.data.map(justificative => (
                <UIButton
                  key={justificative.id}
                  style={{
                    backgroundColor: justificative.color,
                    color: "white",
                    opacity: !creatingType ? 1 : creatingType === justificative.id ? 1 : 0.6,
                    marginRight: "5px",
                    marginBottom: "3px",
                    fontWeight: "bold",
                    border: "none",
                  }}
                  onClick={() => setCreatingType(justificative.id)}
                >
                  {justificative.name}
                </UIButton>
              ))}
            </Col>
          </Row>
          {!!creatingType ? (
            <NewRequestForm creatingType={creatingType} onClose={setCreatingType} />
          ) : (
            <MyRequestsCalendar />
          )}
        </>
      </WidgetCard>
    </>
  );
};
