/** @format */

import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { apiJustificativeStatusList } from "../../api/apiJustificativeStatuses";
import { showNotification } from "../../utils/showNotification";
import {
  actionJustificativeStatusListFailure,
  actionJustificativeStatusListLoadingFailure,
  actionJustificativeStatusListLoadingRequest,
  actionJustificativeStatusListLoadingSuccess,
  actionJustificativeStatusListSuccess,
} from "../actions/actionsJustificativeStatuses";
import { TAPIResponse } from "../declarations/apis/general";

export function* sagaJustificativeStatusList(): unknown {
  try {
    yield put(actionJustificativeStatusListLoadingRequest());
    const response: AxiosResponse<TAPIResponse<unknown>> = yield call(apiJustificativeStatusList);
    //@ts-ignore
    yield put(actionJustificativeStatusListSuccess(response.data.data));
    yield put(actionJustificativeStatusListLoadingSuccess());
  } catch (e) {
    yield put(actionJustificativeStatusListFailure());
    yield put(actionJustificativeStatusListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
