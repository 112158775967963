/** @format */

import {
  TActionWarehouseListRequest,
  TActionWarehouseListSuccess,
  TActionWarehouseListFailure,
  TActionWarehouseListLoadingRequest,
  TActionWarehouseListLoadingSuccess,
  TActionWarehouseListLoadingFailure,
  TActionWarehouseCreateRequest,
  TActionWarehouseCreateSuccess,
  TActionWarehouseCreateFailure,
  TActionWarehouseUpdateRequest,
  TActionWarehouseUpdateSuccess,
  TActionWarehouseUpdateFailure,
  TActionWarehouseUpdateReset,
  TActionWarehouseDeleteRequest,
  TActionWarehouseDeleteSuccess,
  TActionWarehouseDeleteFailure,
  TActionWarehouseDeleteReset,
} from "../declarations/actions/warehouses";
import { TActionType, TAction } from "../declarations/actionTypes";
import {
  TWarehouse,
  TWarehouseCreateRequest,
  TWarehouseUpdateRequest,
} from "../declarations/maps/warehouses";

//List
export const actionWarehouseListRequest = (): TActionWarehouseListRequest => ({
  type: TActionType.warehouseListRequest,
});

export const actionWarehouseListSuccess = (payload: TWarehouse[]): TActionWarehouseListSuccess => ({
  type: TActionType.warehouseListSuccess,
  payload,
});

export const actionWarehouseListFailure = (): TActionWarehouseListFailure => ({
  type: TActionType.warehouseListFailure,
});

//---

//Loading

export const actionWarehouseListLoadingRequest = (): TActionWarehouseListLoadingRequest => ({
  type: TActionType.warehouseListLoadingRequest,
});

export const actionWarehouseListLoadingSuccess = (): TActionWarehouseListLoadingSuccess => ({
  type: TActionType.warehouseListLoadingSuccess,
});

export const actionWarehouseListLoadingFailure = (): TActionWarehouseListLoadingFailure => ({
  type: TActionType.warehouseListLoadingFailure,
});

// ---
//Create
export const actionWarehouseCreateRequest = (
  payload: TWarehouseCreateRequest,
): TActionWarehouseCreateRequest => ({ type: TActionType.warehouseCreateRequest, payload });
export const actionWarehouseCreateSuccess = (payload: string): TActionWarehouseCreateSuccess => ({
  type: TActionType.warehouseCreateSuccess,
  payload,
});
export const actionWarehouseCreateFailure = (): TActionWarehouseCreateFailure => ({
  type: TActionType.warehouseCreateFailure,
});
export const actionWarehouseCreateReset = (): TAction => ({
  type: TActionType.warehouseCreateReset,
});
// ---
// Update
export const actionWarehouseUpdateRequest = (
  payload: TWarehouseUpdateRequest,
): TActionWarehouseUpdateRequest => ({
  type: TActionType.warehouseUpdateRequest,
  payload,
});

export const actionWarehouseUpdateSuccess = (): TActionWarehouseUpdateSuccess => ({
  type: TActionType.warehouseUpdateSuccess,
});

export const actionWarehouseUpdateFailure = (): TActionWarehouseUpdateFailure => ({
  type: TActionType.warehouseUpdateFailure,
});
export const actionWarehouseUpdateReset = (): TActionWarehouseUpdateReset => ({
  type: TActionType.warehouseUpdateReset,
});

// ---
// Delete
export const actionWarehouseDeleteRequest = (payload: string): TActionWarehouseDeleteRequest => ({
  type: TActionType.warehouseDeleteRequest,
  payload,
});

export const actionWarehouseDeleteSuccess = (payload: string): TActionWarehouseDeleteSuccess => ({
  type: TActionType.warehouseDeleteSuccess,
  payload,
});

export const actionWarehouseDeleteFailure = (): TActionWarehouseDeleteFailure => ({
  type: TActionType.warehouseDeleteFailure,
});
export const actionWarehouseDeleteReset = (): TActionWarehouseDeleteReset => ({
  type: TActionType.warehouseDeleteReset,
});
// ---
