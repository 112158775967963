/** @format */

import { TModel } from "../../constants/maps/BackendEnums";
import { TModelDetail } from "../declarations/maps/modelDetails";
import { TStore } from "../declarations/store";

export const selectorModelDetailsList = (store: TStore): TModelDetail[] =>
  store.modelDetails.modelDetailList || [];

export const selectorModelDetailsByModel = (
  store: TStore,
  model: TModel,
  show_only_is_visible: boolean = false,
): TModelDetail[] => {
  if (!store.modelDetails.modelDetailList) return [];

  const lista = store.modelDetails.modelDetailList.filter(el => el.model === model);
  if (!show_only_is_visible) return lista;

  return lista.filter(el => !!el.is_visible == true);
};
