/** @format */

import { TJustificative, TJustificativeList } from "../declarations/maps/justificatives";
import { TStore } from "../declarations/store";

export const selectorJustificativeList = (store: TStore): TJustificativeList =>
  store.justificatives.justificativeList || {
    data: [],
    pagination: { current: 0, pageSize: 0, lastPage: 0, total: 0 },
    loading: false,
  };

export const selectorJustificative = (store: TStore, id: string): TJustificative | undefined =>
  store.justificatives.justificativeList?.data.find(justificative => justificative.id === id) ||
  undefined;
