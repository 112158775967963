/** @format */

import {
  TActionJustificativeStatusListRequest,
  TActionJustificativeStatusListSuccess,
  TActionJustificativeStatusListFailure,
  TActionJustificativeStatusListLoadingRequest,
  TActionJustificativeStatusListLoadingSuccess,
  TActionJustificativeStatusListLoadingFailure,
} from "../declarations/actions/justificativeStatuses";
import { TActionType } from "../declarations/actionTypes";
import { TJustificativeStatus } from "../declarations/maps/justificatives";

//List
export const actionJustificativeStatusListRequest = (payload: {}): TActionJustificativeStatusListRequest => ({
  type: TActionType.justificativeStatusListRequest,
  payload,
});

export const actionJustificativeStatusListSuccess = (
  payload: TJustificativeStatus[],
): TActionJustificativeStatusListSuccess => ({
  type: TActionType.justificativeStatusListSuccess,
  payload,
});

export const actionJustificativeStatusListFailure = (): TActionJustificativeStatusListFailure => ({
  type: TActionType.justificativeStatusListFailure,
});

//---

//Loading

export const actionJustificativeStatusListLoadingRequest = (): TActionJustificativeStatusListLoadingRequest => ({
  type: TActionType.justificativeStatusListLoadingRequest,
});

export const actionJustificativeStatusListLoadingSuccess = (): TActionJustificativeStatusListLoadingSuccess => ({
  type: TActionType.justificativeStatusListLoadingSuccess,
});

export const actionJustificativeStatusListLoadingFailure = (): TActionJustificativeStatusListLoadingFailure => ({
  type: TActionType.justificativeStatusListLoadingFailure,
});

// ---
