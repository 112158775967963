/** @format */

import {TActionGroupsListRequest, TActionGroupsListSuccess, TActionGroupsListFailure} from '../declarations/actions/groups';
import { TActionType } from "../declarations/actionTypes";
import { TGroups } from '../declarations/maps/groups';

type Actions = TActionGroupsListRequest | TActionGroupsListSuccess | TActionGroupsListFailure;

export const reducerGroups = (prevStore: TGroups = {}, action: Actions): TGroups => {
  switch (action.type) {
    case TActionType.groupsListRequest:
      return { ...prevStore, groupList: {
        data: [],
        pagination: { current: 0, pageSize: 0, lastPage: 0, total: 0 },
        loading: false,
      } };
    case TActionType.groupsListSuccess:
      return { ...prevStore, groupList: action.payload };
    case TActionType.groupsListFailure:
      return { ...prevStore, groupList: {
        data: [],
        pagination: { current: 0, pageSize: 0, lastPage: 0, total: 0 },
        loading: false,
      } };
    default:
      return prevStore;
  }
};
