/** @format */

import { watcherAuth } from "./watcherAuth";
import { watcherCache } from "./watcherCache";
import { watcherDocumentTypes } from "./watcherDocumentTypes";
import { watcherGroups } from "./watcherGroups";
import { watcherLocations } from "./watcherLocations";
import { watcherUsers } from "./watcherUsers";
import { watcherDocuments } from "./watcherDocuments";
import { watcherPermissions } from "./watcherPermissions";
import { watcherJustificatives } from "./watcherJustificatives";
import { watcherModelDetails } from "./watcherModelDetails";
import { watcherRequests } from "./watcherRequests";
import { watcherJustificativeStatuses } from "./watcherJustificativeStatuses";
import { watcherNotifications } from "./watcherNotifications";
import { watcherTriggers } from "./watcherTriggers";
import { watcherDevices } from "./watcherDevices";
import { watcherPresenceTypes } from "./watcherPresenceTypes";
import { watcherPresences } from "./watcherPresences";
import { watcherDepartments } from "./watcherDepartments";
import { watcherShiftTypes } from "./watcherShiftTypes";
import { watcherShifts } from "./watcherShifts";
import { watcherCommunications } from "./watcherCommunications";
import { watcherProducts } from "./watcherProducts";
import { watcherWarehouses } from "./watcherWarehouses";
import { watcherFleets } from "./watcherFleets";
import { watcherReservations } from "./watcherReservations";
import { watcherTravels } from "./watcherTravels";
import { watcherExpenses } from "./watcherExpenses";
/** @format */

export const watchers = [
  watcherAuth,
  watcherCache,
  watcherPermissions,
  watcherUsers,
  watcherGroups,
  watcherLocations,
  watcherDocumentTypes,
  watcherDocuments,
  watcherJustificatives,
  watcherJustificativeStatuses,
  watcherModelDetails,
  watcherRequests,
  watcherTriggers,
  watcherNotifications,
  watcherDevices,
  watcherPresenceTypes,
  watcherPresences,
  watcherDepartments,
  watcherShiftTypes,
  watcherShifts,
  watcherCommunications,
  watcherProducts,
  watcherWarehouses,
  watcherFleets,
  watcherReservations,
  watcherTravels,
  watcherExpenses,
];
