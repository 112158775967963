/** @format */

import React, { useEffect } from "react";
import { Checkbox, Col, Form, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { useHistory } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UIText } from "../../ui/Text";
import { useDispatch, useSelector } from "react-redux";
import { selectorLocationCreated } from "../../../redux-modules/selectors/selectorsUI";
import { actionLocationCreateRequest } from "../../../redux-modules/actions/actionsLocations";
import { UITable } from "../../ui/Table";
import { UILink } from "../../ui/Link";

export const ViewLocationCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLocationCreated = useSelector(selectorLocationCreated);

  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      name: e.name,
      address: e.address,
      cap: e.cap,
      works_on_monday: e.works_on_monday || false,
      works_on_tuesday: e.works_on_tuesday || false,
      works_on_wednesday: e.works_on_wednesday || false,
      works_on_thursday: e.works_on_thursday || false,
      works_on_friday: e.works_on_friday || false,
      works_on_saturday: e.works_on_saturday || false,
      works_on_sunday: e.works_on_sunday || false,
    };
    //dispatch

    dispatch(actionLocationCreateRequest(contentObj));
  };

  useEffect(() => {
    if (isLocationCreated) history.push(Routes.locations);
  }, [isLocationCreated]);

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Nuova Sede
          </UITitle>
        </Col>
      </Row>
      <Form
        name="new-group"
        className="new-group"
        data-cy="new-location-form"
        onFinish={handleSave}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    label={<UIText strong={true}>Nome</UIText>}
                    rules={[{ required: true, message: "Inserisci un nome!" }]}
                    extra="Il nome del gruppo"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="address"
                    label={<UIText strong={true}>Indirizzo</UIText>}
                    rules={[{ required: true, message: "Inserisci un indirizzo valido!" }]}
                    extra="La via/piazza della sede"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={8}>
                  <Form.Item
                    name="cap"
                    label={<UIText strong={true}>Cap</UIText>}
                    rules={[
                      { required: true, message: "Inserisci il codice di avviamento postale!" },
                    ]}
                    extra="Il CAP della sede"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <UIText strong={true}>Giorni Lavorativi Settimanali</UIText>
                </Col>
              </Row>
              <Row gutter={[8, 8]} style={{ marginTop: "10px" }}>
                <Col xs={24} md={24}>
                  <Row>
                    <Col xs={24}>
                      <UITable
                        size="middle"
                        pagination={false}
                        columns={[
                          { title: "Lun", dataIndex: "lun", key: "lun" },
                          { title: "Mar", dataIndex: "mar", key: "mar" },
                          { title: "Mer", dataIndex: "mer", key: "mer" },
                          { title: "Gio", dataIndex: "gio", key: "gio" },
                          { title: "Ven", dataIndex: "ven", key: "ven" },
                          { title: "Sab", dataIndex: "sab", key: "sab" },
                          { title: "Dom", dataIndex: "dom", key: "dom" },
                        ]}
                        dataSource={[
                          {
                            key: "1",
                            lun: (
                              <Form.Item name="works_on_monday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            mar: (
                              <Form.Item name="works_on_tuesday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            mer: (
                              <Form.Item name="works_on_wednesday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            gio: (
                              <Form.Item name="works_on_thursday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            ven: (
                              <Form.Item name="works_on_friday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            sab: (
                              <Form.Item name="works_on_saturday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            dom: (
                              <Form.Item name="works_on_sunday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row justify="end" gutter={[24, 24]}>
                <Col>
                  <UILink route={Routes.locations} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item>
                    <UIButton type="primary" data-cy="new-location-button" htmlType="submit">
                      + Crea Sede
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
    </>
  );
};
