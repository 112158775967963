/** @format */

import { Col, Drawer, Form, Row, Select, Skeleton } from "antd";
import moment from "moment";
import { ChangeEventExtra } from "rc-tree-select/lib/TreeSelect";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../../../constants/api";
import { actionDocumentTypeListRequest } from "../../../../redux-modules/actions/actionsDocumentType";
import { TDocumentCreateRequest } from "../../../../redux-modules/declarations/maps/documents";
import { TDocumentType } from "../../../../redux-modules/declarations/maps/documentTypes";
import { TGroup } from "../../../../redux-modules/declarations/maps/groups";
import { selectorGenericDocumentTypesList } from "../../../../redux-modules/selectors/selectorsDocumentTypes";
import apiClient from "../../../../utils/apiClient";
import { UIButton } from "../../../ui/Button";
import { UICard } from "../../../ui/Card";
import { UIDatePicker } from "../../../ui/DatePicker";
import { UIFileUploader } from "../../../ui/FileUploader";
import { UISelect } from "../../../ui/Select";
import { UITreeSelect } from "../../../ui/TreeSelect";
const { Option } = Select;
const { SHOW_PARENT } = UITreeSelect;

interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: (files: TDocumentCreateRequest) => void;
  loading: boolean;
}

export const NewDocumentDrawer: FC<Props> = ({
  visible,
  onClose,
  onSave,
  loading,
}: Props): JSX.Element => {
  const [form] = Form.useForm();
  const [newFile, setNewFile] = useState<File | null>();
  const [documentTypes, setDocumentTypes] = useState<TDocumentType[]>([]);
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [userList, setUserList] = useState<[]>([]);
  const [showUserList, setShowUserList] = useState<boolean>(false);
  const [needYearMonth, setNeedYearMonth] = useState<boolean>(false);
  const [hideMonth, setHideMonth] = useState<boolean>(false);

  //@ts-ignore
  const dispatch = useDispatch();
  const documentTypeList = useSelector(selectorGenericDocumentTypesList);

  const handleUpload = (e: FileList | object[]): void => {
    if (e[0]) {
      //@ts-ignore
      setNewFile(e[0]?.originFileObj);
    }
  };

  const handleSave = (): void => {
    if (!!newFile) {
      form.validateFields().then(formValues => {
        if (showUserList) {
          if (formValues.users.includes("all")) {
            //@ts-ignore
            formValues.users = userList.map(e => e.id);
          }
          if (
            formValues.users.filter((str: string): boolean => `${str}`.startsWith("group")).length >
            0
          ) {
            const groups = formValues.users
              .filter((str: string): boolean => `${str}`.startsWith("group"))
              .map((e: string): string => `${e}`.split("?")[1]);
            formValues.users = [
              ...getUserListByGroupIds(groups, userList).map(e => e.id),
              ...formValues.users.filter((str: string): boolean => !`${str}`.startsWith("group")),
            ];
          }
        }

        const files: TDocumentCreateRequest = {
          users: formValues.users,
          document_type_id: formValues.document_type_id,
          expiring_at: formValues.expiring_at
            ? moment(formValues.expiring_at).format("YYYY-MM-DD")
            : undefined,
          file: newFile,
          year: formValues.year ? formValues.year : undefined,
          month: formValues.month ? formValues.month : undefined,
        };
        onSave(files);
        form.resetFields();
      });
    }
  };

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.USERS_LIST + "?all=true").then(res => {
      setUserList(res.data.data);
    });

    dispatch(actionDocumentTypeListRequest());
  }, []);

  useEffect(() => {
    //@ts-ignore
    if (documentTypeList) setDocumentTypes(documentTypeList);
  }, [documentTypeList]);

  const yearsArray = (): string[] => {
    const yearsArray = [];

    var startDate = moment().subtract(5, "year").format("YYYY"),
      endDate = moment().format("YYYY");

    for (var from = +startDate, to = +endDate; from <= to; from++) {
      yearsArray.push("" + from);
    }
    return yearsArray;
  };

  const monthsArray = [...Array(12).keys()].map(e => e + 1);

  const onChangeForm = (): void => {
    if (form) {
      const docTypeSelected = form.getFieldValue("document_type_id");

      if (
        documentTypeList
          .filter(e => !!e.shared)
          .map(e => e.id)
          .includes(docTypeSelected)
      ) {
        setShowUserList(true);
      } else {
        form.setFieldsValue({
          users: [],
        });
        setShowUserList(false);
      }

      if (
        documentTypeList
          .filter(e => !!e.split)
          .map(e => e.id)
          .includes(docTypeSelected)
      ) {
        setNeedYearMonth(true);
        setHideMonth(false);
      } else {
        setNeedYearMonth(false);
        setHideMonth(form.getFieldValue("year") === undefined);
      }
    }
  };
  const handleClose = (): void => {
    form.resetFields();
    onClose();
  };

  const onChange = (newValue: unknown, labelList: ReactNode[], extra: ChangeEventExtra): void => {
    //@ts-ignore
    setSelectedUser(newValue);
  };

  const tProps = {
    treeData: [
      {
        key: "all",
        value: "all",
        label: "Tutti i dipendenti",
        children: getGroupListByUsersList(userList).map(group => ({
          key: "group?" + group.id,
          value: "group?" + group.id,
          label: group.name,
          children: getUserListByGroupId(group.id, userList).map(user => ({
            key: user.id,
            value: user.id,
            label: user.surname + " " + user.name,
          })),
        })),
      },
    ],
    value: selectedUser,
    onChange,
    treeCheckable: true,
    treeDefaultExpandedKeys: ["all"],
    showCheckedStrategy: SHOW_PARENT,
    //@ts-ignore
    filterTreeNode: (input, option): boolean => {
      return ((option!.label as unknown) as string).toLowerCase().includes(input.toLowerCase());
    },
    style: {
      width: "100%",
    },
  };

  return (
    <Drawer
      title="Nuovo Documento"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <UIButton onClick={onClose} style={{ marginRight: 8 }}>
            Annulla
          </UIButton>
          <UIButton
            onClick={handleSave}
            type="primary"
            loading={loading}
            data-cy="new-document-button-save"
          >
            Carica
          </UIButton>
        </div>
      }
    >
      {!!loading ? (
        <UICard style={{ marginBottom: "20px", border: "none" }}>
          <Skeleton active />
        </UICard>
      ) : (
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSave}
          data-cy="new-document-form"
          onValuesChange={onChangeForm}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="document_type_id"
                label="Tipologia"
                rules={[{ required: true, message: "Inserisci una tipologia di documento" }]}
              >
                <UISelect>
                  {documentTypes.map(dtype => (
                    <Option value={dtype.id} key={dtype.id}>
                      {dtype.name}
                    </Option>
                  ))}
                </UISelect>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="year"
                label="Anno di Rif."
                rules={[{ required: needYearMonth, message: "Seleziona anno" }]}
              >
                <UISelect>
                  {yearsArray().map(y => (
                    <Option value={y} key={y}>
                      {y}
                    </Option>
                  ))}
                </UISelect>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="month"
                hidden={hideMonth}
                label="Mese di Rif."
                rules={[{ required: needYearMonth, message: "Seleziona mese" }]}
              >
                <UISelect>
                  {monthsArray.map(m => (
                    <Option value={m} key={m}>
                      {m}
                    </Option>
                  ))}
                </UISelect>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="expiring_at"
                label="Scadenza"
                rules={[
                  { required: false, message: "Seleziona la data di scadenza del documento" },
                ]}
              >
                <UIDatePicker />
              </Form.Item>
            </Col>
          </Row>
          {showUserList && (
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="users"
                  label="Utenti"
                  rules={[{ required: true, message: "Seleziona almeno un utente!" }]}
                  extra={`Gli utenti che riceveranno il documento`}
                >
                  <UITreeSelect {...tProps} />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={16}>
            <Col span={24}>
              <UIFileUploader handleUpload={handleUpload} />
            </Col>
          </Row>
        </Form>
      )}
    </Drawer>
  );
};

interface reducedUser {
  id: number;
  name: string;
  surname: string;
  groups: TGroup[];
}
const getGroupListByUsersList = (users: reducedUser[]): TGroup[] => {
  let fullGroupList: TGroup[] = [];
  users.forEach(user => {
    user.groups.forEach(userGroup => (fullGroupList[parseInt(userGroup.id)] = userGroup));
  });
  return fullGroupList;
};
const getUserListByGroupId = (groupId: string, users: reducedUser[]): reducedUser[] => {
  let userList: reducedUser[] = [];
  users.forEach(user => {
    user.groups.forEach(userGroup => {
      if (userGroup.id === groupId) {
        userList.push(user);
      }
    });
  });
  return userList;
};

const getUserListByGroupIds = (groupIds: string[], users: reducedUser[]): reducedUser[] => {
  let userList: reducedUser[] = [];
  users.forEach(user => {
    user.groups.forEach(userGroup => {
      if (groupIds.includes(`${userGroup.id}`)) {
        userList.push(user);
      }
    });
  });
  return userList;
};
