/** @format */

import {
  TActionDeviceListFailure,
  TActionDeviceListRequest,
  TActionDeviceListSuccess,
} from "../declarations/actions/devices";
import { TActionType } from "../declarations/actionTypes";
import { TDevices } from "../declarations/maps/devices";

type Actions = TActionDeviceListRequest | TActionDeviceListSuccess | TActionDeviceListFailure;

export const reducerDevices = (
  prevStore: TDevices = { deviceList: [] },
  action: Actions,
): TDevices => {
  switch (action.type) {
    case TActionType.deviceListRequest:
      return { ...prevStore, deviceList: [] };
    case TActionType.deviceListSuccess:
      return { ...prevStore, deviceList: action.payload };
    case TActionType.deviceListFailure:
      return { ...prevStore, deviceList: [] };
    default:
      return prevStore;
  }
};
