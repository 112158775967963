/** @format */

import {
  TActionJustificativeListRequest,
  TActionJustificativeListSuccess,
  TActionJustificativeListFailure,
} from "../declarations/actions/justificatives";
import { TActionType } from "../declarations/actionTypes";
import { TJustificatives } from "../declarations/maps/justificatives";

type Actions =
  | TActionJustificativeListRequest
  | TActionJustificativeListSuccess
  | TActionJustificativeListFailure;

export const reducerJustificatives = (
  prevStore: TJustificatives = {},
  action: Actions,
): TJustificatives => {
  switch (action.type) {
    case TActionType.justificativeListRequest:
      return {
        ...prevStore,
        justificativeList: {
          data: [],
          pagination: { current: 0, pageSize: 0, lastPage: 0, total: 0 },
          loading: false,
        },
      };
    case TActionType.justificativeListSuccess:
      return { ...prevStore, justificativeList: action.payload };
    case TActionType.justificativeListFailure:
      return {
        ...prevStore,
        justificativeList: {
          data: [],
          pagination: { current: 0, pageSize: 0, lastPage: 0, total: 0 },
          loading: false,
        },
      };
    default:
      return prevStore;
  }
};
