import { TLocation } from "../../redux-modules/declarations/maps/locations";

export const utilityFormatApiLocation = (location: TLocation): TLocation => ({
  id: location.id,
    name: location.name,
    //@ts-ignore
    location: location.address || '',
  });
  

export const utilityFormatApiLocationList = (list: TLocation[]): TLocation[] =>
  list.map(location => utilityFormatApiLocation(location));

