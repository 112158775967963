/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import { TShiftCreateRequest, TShiftListQuery } from "../redux-modules/declarations/maps/shifts";
import apiClient from "../utils/apiClient";

export const apiShiftList = (payload?: TShiftListQuery): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(
    API_ENDPOINTS.SHIFTS +
      (payload?.date_to ? `?date_from=${payload.date_from}&date_to=${payload.date_to}` : ``),
  );
};

export const apiShiftDelete = (payload: string): Promise<AxiosResponse<unknown>> => {
  return apiClient.delete(API_ENDPOINTS.SHIFTS + "/" + payload);
};

export const apiShiftCreate = (shift: TShiftCreateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.post(API_ENDPOINTS.SHIFTS, shift);
