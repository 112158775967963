/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TDocumentComplete } from "../../../redux-modules/declarations/maps/documents";
import { FileComponent } from "./partials/FileComponent";
import { Folder } from "./partials/Folder";
import backImageThemeAzure from "../../../assets/svg/back.svg";
import backImageThemeMinimal from "../../../assets/svg/back_theme2.svg";
import { FinderBreadcrumbs } from "./partials/Breadcrumbs";
import { BaseColor } from "../../../theme/maps/Colors";
import { hexToRgb } from "../../../utils/styles";
import { UIText } from "../Text";
import { PlusCircleOutlined } from "@ant-design/icons";
import { AddFile } from "./partials/AddFile";
import { Skeleton } from "antd";
import { UICard } from "../Card";
import { usePermission } from "../../../redux-modules/hooks/usePermission";
import { useSelector } from "react-redux";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../../utils/permissions/hasPermission";

interface Props {
  documents: TDocumentComplete[];
  showExpiring?: boolean;
  onAddFile?: () => void;
  personalDocumentUserId?: string;
  theme?: TTheme;
  loading?: boolean;
  canCreate?: boolean;
}

type TViews = "categories" | "years" | "months" | "files";

export type TTheme = "azure" | "minimal";

export const UIFinder = ({
  documents,
  onAddFile,
  showExpiring = false,
  personalDocumentUserId,
  theme = "azure",
  loading = false,
  canCreate = true,
}: Props): JSX.Element => {
  const [categories, setCategories] = useState<string[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const [months, setMonths] = useState<string[]>([]);
  const [currentView, setCurrentView] = useState<TViews>();
  const [currentCategory, setCurrentCategory] = useState<string | null>(null);
  const [currentYear, setCurrentYear] = useState<string | null>(null);
  const [currentMonth, setCurrentMonth] = useState<string | null>(null);
  const permissionList = useSelector(selectorPermissionList);
  const canAddFiles = hasPermission(permissionList, "Document", "Create");

  useEffect(() => {
    setCategories(documents.map(e => e.document_type_name ?? "none"));
    setYears(documents.map(e => e.year ?? "none"));
    setMonths(documents.map(e => e.month ?? "none"));
    setCurrentView("categories");

    if (!!document.querySelector("[data-finder]")) {
      //@ts-ignore
      document.querySelector("[data-finder]").addEventListener("contextmenu", function (e) {
        e.preventDefault();
        e.stopPropagation();
      });
    }
  }, []);

  useEffect(() => {
    onBreadcrumbClickHome();
  }, [documents]);
  const getCategories = () => {
    return [...new Set(documents.map(e => e.document_type_name ?? "null"))];
  };

  const getYearsByCategory = (category: string): string[] => {
    return [
      ...new Set(
        documents.filter(e => e.document_type_name === category).map(e => e.year ?? "null"),
      ),
    ];
  };

  const getMonthsByCategoryAndYear = (category: string, year: string): string[] => {
    return [
      ...new Set(
        documents
          .filter(e => e.document_type_name === category && e.year === year)
          .map(e => e.month ?? "null"),
      ),
    ];
  };

  const onCategoryClick = (category: string) => {
    setCurrentCategory(category);
    setCurrentView("years");
  };

  const onYearClick = (year: string) => {
    setCurrentYear(year);
    setCurrentView("months");
  };

  const onMonthClick = (month: string) => {
    setCurrentMonth(month);
    setCurrentView("files");
  };

  const onBackClick = () => {
    if (currentView === "categories") {
      setCurrentView("categories");
    } else if (currentView === "years") {
      setCurrentView("categories");
      setCurrentCategory(null);
    } else if (currentView === "months") {
      setCurrentView("years");
      setCurrentYear(null);
    } else if (currentView === "files") {
      setCurrentView("months");
      setCurrentMonth(null);
    }
  };
  const onBreadcrumbClickHome = () => {
    setCurrentView("categories");
    setCurrentCategory(null);
    setCurrentYear(null);
    setCurrentMonth(null);
  };
  const onBreadcrumbClickCategory = (category: string) => {
    setCurrentCategory(category);
    setCurrentView("years");
    setCurrentYear(null);
    setCurrentMonth(null);
  };
  const onBreadcrumbClickYear = (year: string) => {
    setCurrentYear(year);
    setCurrentView("months");
    setCurrentMonth(null);
  };
  const getFiles = () => {
    return documents.filter(
      e =>
        e.document_type_name == currentCategory && e.year == currentYear && e.month == currentMonth,
    );
  };

  if (!!loading)
    return (
      <UICard style={{ marginBottom: "20px" }}>
        <Skeleton active />
      </UICard>
    );
  return (
    <span data-finder="">
      <FinderBreadcrumbs
        category={currentCategory}
        year={currentYear}
        month={currentMonth}
        onClickHome={onBreadcrumbClickHome}
        onClickCategory={onBreadcrumbClickCategory}
        onClickYear={onBreadcrumbClickYear}
        filesCount={getCategories().length}
      />
      <StyledDiv>
        {currentView !== "categories" && (
          <span onClick={onBackClick}>
            <StyledImg src={theme === "azure" ? backImageThemeAzure : backImageThemeMinimal} />
          </span>
        )}
        {currentView === "categories" &&
          getCategories().length > 0 &&
          getCategories().map(category => (
            <Folder theme={theme} folder_name={category} onClick={onCategoryClick} />
          ))}
        {currentView === "years" &&
          currentCategory &&
          getYearsByCategory(currentCategory).length > 0 &&
          getYearsByCategory(currentCategory).map(year => (
            <Folder theme={theme} folder_name={year} onClick={onYearClick} />
          ))}
        {currentView === "months" &&
          currentCategory &&
          currentYear &&
          getMonthsByCategoryAndYear(currentCategory, currentYear).length > 0 &&
          getMonthsByCategoryAndYear(currentCategory, currentYear).map(month => (
            <Folder theme={theme} folder_name={month} onClick={onMonthClick} />
          ))}
        {getFiles().map(file => (
          <FileComponent
            theme={theme}
            file={file}
            showExpiring={showExpiring}
            personalDocumentUserId={personalDocumentUserId}
          />
        ))}
        {(getCategories().length != 0 ||
          //@ts-ignore
          getYearsByCategory(currentCategory).length != 0 ||
          //@ts-ignore
          getMonthsByCategoryAndYear(currentCategory, currentYear).length != 0 ||
          getFiles().length != 0) &&
        canAddFiles &&
        canCreate ? (
          <AddFile theme={theme} onClick={onAddFile} />
        ) : (
          <span></span>
        )}
        {getCategories().length === 0 &&
          //@ts-ignore
          getYearsByCategory(currentCategory).length === 0 &&
          //@ts-ignore
          getMonthsByCategoryAndYear(currentCategory, currentYear).length === 0 &&
          getFiles().length === 0 && (
            <FilesNotFoundDiv>
              Nessun documento disponibile <br />
              {!!onAddFile && canAddFiles && (
                <span
                  onClick={onAddFile}
                  style={{
                    cursor: "pointer",
                    marginTop: "5px",
                    color: "var(--antd-wave-shadow-color)",
                    fontWeight: 600,
                  }}
                >
                  <PlusCircleOutlined style={{ marginRight: "5px" }} />
                  <UIText color={"var(--antd-wave-shadow-color)"}>Nuovo Documento</UIText>
                </span>
              )}
            </FilesNotFoundDiv>
          )}
      </StyledDiv>
    </span>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.07"});
  border-radius: 7px;
  min-height: 150px;
`;
const FilesNotFoundDiv = styled.div`
  width: 100%;
  text-align: center;
  align-self: center;
  font-style: italic;
  font-size: 17px;
`;
const StyledImg = styled.img`
  cursor: pointer;
  margin: 10px;
  margin-right: 20px;
  padding: 5px;
  width: 50px;
  &:hover {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.1"});
    border: 1px solid rgb(${hexToRgb("" + BaseColor) + ", 0.2"});
    border-radius: 7px;
  }
`;
