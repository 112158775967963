/** @format */

import { Col } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { UIText } from "../../../ui/Text";

interface Props {
  day: moment.Moment;
}

const IndexDayBase = ({ day }: Props): JSX.Element => {
  return (
    <Col span={3} className="pipallino" style={{ textAlign: "center" }}>
      <UIText
        style={{
          textTransform: "capitalize",
          cursor: "default",
          fontSize: "15px",
          fontWeight: 500,
        }}
      >
        {moment(day).format("ddd DD")}
      </UIText>
    </Col>
  );
};

export const IndexDay = styled(IndexDayBase)`
  .pipallino {
    border-left: 1px solid black;
    text-align: left !important;
  }
  .pipallino::last-of-type {
    border-right: 1px solid black;
  }
`;
