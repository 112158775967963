/** @format */

export const productAvailableListColumns = [
  {
    title: "Prodotto",
    dataIndex: "product_name",
    key: "product_name",
  },
  {
    title: "Stato",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Giacenza",
    dataIndex: "giacenza",
    key: "giacenza",
  },
  {
    title: "Assegnati",
    dataIndex: "assegnati",
    key: "assegnati",
  },
];
