/** @format */

import { Col } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { UIText } from "../../../ui/Text";

interface Props {
  activeDay: moment.Moment;
  time: string;
}

const IndexDayBase = ({ time, activeDay }: Props): JSX.Element => {
  return (
    <Col span={1} className="pipallino" style={{ textAlign: "center" }}>
      <UIText
        style={{
          textTransform: "capitalize",
          cursor: "default",
          fontSize: "13px",
        }}
      >
        {moment(time, "HH:mm").format("HH")}
      </UIText>
    </Col>
  );
};

export const IndexDay = styled(IndexDayBase)`
  .pipallino {
    border-left: 1px solid black;
    text-align: left !important;
  }
  .pipallino::last-of-type {
    border-right: 1px solid black;
  }

  .pipallino span {
    position: relative;
    left: -10px;
  }
`;
