/** @format */

import { TAPIResponse } from "../../redux-modules/declarations/apis/general";
import { TUser } from "../../redux-modules/declarations/maps/auth";
import {
  TUserDataSource,
  TUserDataSourceList,
  TUserList,
} from "../../redux-modules/declarations/maps/users";
import { utilityFormatApiUserList } from "./apiUser";

export const utilityFormatUserTable = (
  list: TAPIResponse<TUser[]>,
  loading: boolean = false,
): TUserList => ({
  data: utilityFormatApiUserList(list.data), //passaggio al momento inutile, ma che servirà per tutte le altre (anche per questa, refactoring)
  pagination: {
    current: list.meta?.current_page || 0,
    pageSize: list.meta?.per_page || 0,
    lastPage: list.meta?.last_page || 0,
    total: list.meta?.total || 0,
  },
  loading,
});

export const utilityFormatUsersToTableDataSource = (user: TUser): TUserDataSource => ({
  avatar: user.avatar,
  key: user.id,
  id: user.id,
  user: user.surname + " " + user.name,
  role: user.role,
  email: user.email,
  mobile: user.mobile,
  groups: user.groups,
  is_disabled: user.is_disabled,
});

export const utilityFormatUsersToTableDataSourceList = (list: TUser[]): TUserDataSourceList =>
  list.map(user => utilityFormatUsersToTableDataSource(user));
