/** @format */

import { FileTextOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Form,
  Drawer,
  Row,
  Col,
  Cascader,
  Empty,
  Spin,
  Divider,
  Radio,
  Skeleton,
  Image,
  List,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../../constants/api";
import { actionExpenseCreateSuccess } from "../../../../redux-modules/actions/actionsExpenses";
import { actionFleetListRequest } from "../../../../redux-modules/actions/actionsFleets";
import { actionTravelCreateSuccess } from "../../../../redux-modules/actions/actionsTravels";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import { ExpenseTypes, TExpense } from "../../../../redux-modules/declarations/maps/expenses";
import { TTravel } from "../../../../redux-modules/declarations/maps/travels";
import { selectorLoggedUser } from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorFleetList } from "../../../../redux-modules/selectors/selectorsFleets";
import apiClient from "../../../../utils/apiClient";
import { showNotification } from "../../../../utils/showNotification";
import { UIButton } from "../../../ui/Button";
import { UICard } from "../../../ui/Card";
import { UICheckbox } from "../../../ui/Checkbox";
import { UIDatePicker } from "../../../ui/DatePicker";
import { UIInput } from "../../../ui/Input";
import { UISelect } from "../../../ui/Select";
import { UITextArea } from "../../../ui/Textarea";
import { UITitle } from "../../../ui/Title";
import { hexToRgb } from "../../../../utils/styles";
import { BaseColor } from "../../../../theme/maps/Colors";
const { Option } = UISelect;

interface Props {
  visible: boolean;
  onClose: (refresh?: boolean) => void;
  travels: TTravel[];
  expense?: TExpense;
}

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

export const ExpenseShowDrawer = ({ visible, onClose, travels, expense }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loggedUser = useSelector(selectorLoggedUser);
  const [options, setOptions] = useState<Option[]>([]);
  const [isChilometrico, setIsChilometrico] = useState(false);
  const [isAziendale, setIsAziendale] = useState(false);
  const [isAndataERitorno, setIsAndataERitorno] = useState(false);
  const fleetList = useSelector(selectorFleetList);
  const [loading, setLoading] = useState(false);

  const [selectedValues, setSelectedValues] = useState([]);

  const onChangeFilters = (value: unknown) => {
    //@ts-ignore
    setSelectedValues(value);
  };

  const handleClose = () => {
    if (onClose)
      // quello che vogliamo
      onClose();
    form.resetFields();
  };

  const onRemove = () => {
    apiClient.delete(API_ENDPOINTS.EXPENSES + "/" + expense?.id).then(res => {
      form.resetFields();
      onClose(true);
    });
  };

  const onChangeForm = () => {
    if (form) {
      const categorySelected = form.getFieldValue("expense_type");
      const ritornoSelected = form.getFieldValue("is_return_included");
      const tipoMezzo = form.getFieldValue("fleet_type");

      if (categorySelected === "Carburante") {
        setIsChilometrico(true);
      } else {
        setIsChilometrico(false);
      }

      if (tipoMezzo === "Aziendale") {
        setIsAziendale(true);
      } else {
        setIsAziendale(false);
      }

      if (!!ritornoSelected) {
        setIsAndataERitorno(true);
      } else {
        setIsAndataERitorno(false);
      }
    }
  };

  const onSave = async () => {
    setLoading(true);
    await form.validateFields().then(values => {
      const obj = {
        note: form.getFieldValue("note"),
        expense_type: form.getFieldValue("expense_type"),
        fleet_type: form.getFieldValue("fleet_type"),
        departure: form.getFieldValue("departure"),
        arrival: form.getFieldValue("arrival"),
        is_return_included: form.getFieldValue("is_return_included"),
        km_distance: form.getFieldValue("km_distance"),
        fleet_id: form.getFieldValue("fleet_id"),
        rimborso_previsto: form.getFieldValue("rimborso_previsto"),
        rimborso_richiesto: form.getFieldValue("rimborso_richiesto"),
        rimborso_approvato: form.getFieldValue("rimborso_approvato"),
        date: form.getFieldValue("date").format("YYYY-MM-DD"),
        travel_id: form.getFieldValue("travel_id"),
        user_id: loggedUser?.id,
        status: "Accettata",
      };
      apiClient
        .put(API_ENDPOINTS.EXPENSES + "/" + expense?.id, obj)
        .then(res => {
          showNotification("success", "Nota spese salvata", "Nota spese salvata con successo!");
          form.resetFields();
          setLoading(false);
          // OPERARE SUL REDUCER
          dispatch(actionExpenseCreateSuccess(res.data.data));
          if (onClose) onClose(true);
        })
        .catch(err => {
          form.resetFields();
          setLoading(false);
          showNotification(
            "error",
            "Errore", //@ts-ignore
            err.response.data.errors
              ? //@ts-ignore
                Object.values(err.response.data.errors).join(`\n`)
              : //@ts-ignore
                err.response.data.message,
          );
        });
    });
  };

 const downloadExpenseFile = (fileId: string, filename: string) => {
    apiClient
      .get(API_ENDPOINTS.AUTH_EXPENSE_DOWNLOAD.replace(":expense_id", fileId), {responseType: "blob",})
      .then(res => {
        console.log(res);
        let url = window.URL.createObjectURL(
          new Blob([res.data]),
        );
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          filename,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        if(link.parentNode != null){
          link.parentNode!.removeChild(link);
        }
      })
      .catch(e => {
        showNotification(
          "error",
          "Oops..",
          //@ts-ignore
          e.response.data.message ?? Object.values(e.response.data.errors).join(`\n`),
        );
      });
  };

  useEffect(() => {
    dispatch(actionFleetListRequest());
  }, []);

  useEffect(() => {
    if (!!expense) {
      onChangeForm();
    } else {
      form.resetFields();
    }
  }, [expense]);

  if (!expense)
    return (
      <Drawer
        title="Nuova Nota Spese"
        placement="right"
        onClose={handleClose}
        closable={true}
        visible={visible}
        width={400}
      >
        <UICard style={{ marginBottom: "20px", border: "none" }}>
          <Skeleton active />
        </UICard>
      </Drawer>
    );

  if (!!expense)
    return (
      <Drawer
        title="Gestisci Nota Spese"
        placement="right"
        onClose={handleClose}
        closable={true}
        visible={visible}
        width={400}
        footer={
          <Row justify="space-between">
            <Col style={{ display: "flex" }}>
              {expense.status == "Pendente" && (
                <Form.Item key="submit" name="submit">
                  <UIButton
                    form="form_expense_edit"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                  >
                    Approva
                  </UIButton>
                </Form.Item>
              )}
              <UIButton
                onClick={onRemove}
                style={{
                  borderRadius: "7px",
                  marginLeft: "5px",
                  backgroundColor: "#e00102",
                  borderColor: "#e00102",
                  color: "white",
                  display: "inline-block",
                }}
              >
                Elimina
              </UIButton>
            </Col>
            <Col>
              <UIButton onClick={() => onClose()}>Annulla</UIButton>
            </Col>
          </Row>
        }
      >
        <Row>
          <Col span={24}>
            <>
              <Form
                id="form_expense_edit"
                form={form}
                initialValues={{
                  ...expense,
                  rimborso_approvato: !!expense.rimborso_approvato
                    ? expense.rimborso_approvato
                    : expense.rimborso_richiesto,
                }}
                disabled={expense.status == "Accettata"}
                onFinish={onSave}
                onValuesChange={onChangeForm}
              >
                <Row>
                  <Col span={24}>
                    <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                      <Col span={24}>
                        <Form.Item
                          name="date"
                          label="Data"
                          rules={[{ required: true, message: "Indica la data della spesa" }]}
                        >
                          <UIDatePicker format={"DD/MM/YYYY"} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                      <Col span={24}>
                        <Form.Item
                          name="expense_type"
                          label="Categoria"
                          rules={[{ required: true, message: "Indica la tipologia di spesa" }]}
                        >
                          <UISelect>
                            {ExpenseTypes.map(type => (
                              <Option value={type} key={type}>
                                {type}
                              </Option>
                            ))}
                          </UISelect>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                      <Col span={24}>
                        <Form.Item
                          name="travel_id"
                          label="Viaggio di riferimento"
                          rules={[{ required: false, message: "Dai un nome al viaggio" }]}
                        >
                          <UISelect disabled>
                            <Option value={undefined} key={"nessuno"}>
                              Nessuno
                            </Option>
                            {travels.map(travel => (
                              <Option value={travel.id} key={travel.id}>
                                {travel.name} - {travel.description}
                              </Option>
                            ))}
                          </UISelect>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                      <Col span={24}>
                        <Form.Item
                          name="note"
                          label="Note"
                          rules={[
                            { required: false, message: "Ulteriori dettagli relativi alla spesa" },
                          ]}
                        >
                          <UITextArea />
                        </Form.Item>
                      </Col>
                    </Row>
                    {isChilometrico && (
                      <>
                        <Divider />
                        <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                          <Col span={24}>
                            <Form.Item
                              name="fleet_type"
                              label="Tipologia Mezzo"
                              rules={[
                                { required: true, message: "Indica il tipo di mezzo utilizzato" },
                              ]}
                            >
                              <Radio.Group>
                                <Radio value={"Aziendale"}>Aziendale</Radio>
                                <Radio value={"Personale"}>Personale</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                        {isAziendale && (
                          <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                            <Col span={24}>
                              <Form.Item
                                name="fleet_id"
                                label="Mezzo utilizzato"
                                rules={[{ required: true, message: "Indica il mezzo utilizzato" }]}
                              >
                                <UISelect>
                                  {fleetList.map(fleet => (
                                    <Option value={fleet.id} key={fleet.id}>
                                      {fleet.marca + " - " + fleet.targa}
                                    </Option>
                                  ))}
                                </UISelect>
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                          <Col span={24}>
                            <Form.Item
                              name="departure"
                              label="Città di partenza"
                              rules={[{ required: true, message: "Indica la partenza" }]}
                            >
                              <UIInput />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                          <Col span={24}>
                            <Form.Item
                              name="arrival"
                              label="Città di arrivo"
                              rules={[{ required: true, message: "Indica la destinazione" }]}
                            >
                              <UIInput />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                          <Col span={24}>
                            <Form.Item
                              name="km_distance"
                              label="Km percorsi"
                              rules={[{ required: true, message: "Indica i km percorsi" }]}
                            >
                              <UIInput />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                          <Col span={24}>
                            <Form.Item
                              name="is_return_included"
                              label="A / R"
                              valuePropName="checked"
                              rules={[{ required: false, message: "Considera andata e ritorno" }]}
                            >
                              <UICheckbox />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Divider />
                    {isAziendale && (
                      <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                        <Col span={24}>
                          <Form.Item name="rimborso_previsto" label="Rimborso previsto">
                            <UIInput type="number" step="0.1" suffix="€" disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                      <Col span={24}>
                        <Form.Item
                          name="rimborso_richiesto"
                          label="Rimborso richiesto"
                          rules={[{ required: true, message: "Indica il valore del rimborso" }]}
                        >
                          <UIInput type="number" disabled step="0.1" suffix="€" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                      <Col span={24}>
                        <Form.Item
                          name="rimborso_approvato"
                          label="Rimborso approvato"
                          rules={[{ required: true, message: "Indica il valore approvato" }]}
                        >
                          <UIInput type="number" step="0.1" suffix="€" />
                        </Form.Item>
                      </Col>
                    </Row>
                    {expense.attachments.length > 0 && (
                      <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                        <Col span={24}>
                          <UITitle level={5}>Allegati</UITitle>
                          {expense.attachments.map((attachment, index) => (
                            <CustomAttachmentItem onClick={() => downloadExpenseFile(attachment.id, attachment.filename)}>
                              <Col>
                                <FileTextOutlined />
                              </Col>
                              <Col>
                                <Row>
                                  <strong>Allegato nr. {index + 1}</strong>
                                </Row>
                                <Row>
                                    Nome del file:
                                </Row>
                                <Row>
                                    <i>{attachment.filename}</i>
                                </Row>
                              </Col>
                            </CustomAttachmentItem>
                          ))}
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Form>
            </>
          </Col>
        </Row>
      </Drawer>
    );

  return <></>;
};

const CustomImage = styled.span`
  margin-bottom: 5px;
  .ant-image {
    margin-bottom: 5px;
  }
  .ant-image-mask {
    border-radius: 7px !important;
  }
`;

const CustomAttachmentItem = styled(List.Item)`
  cursor: pointer;
  padding: 10px;
  border-radius: 7px;
  justify-content: start !important;

  &:hover {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.15"}) !important;
  }
`;