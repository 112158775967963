/** @format */

import {
  TActionPresenceListRequest,
  TActionPresenceListSuccess,
  TActionPresenceListFailure,
  TActionPresenceListLoadingRequest,
  TActionPresenceListLoadingSuccess,
  TActionPresenceListLoadingFailure,
  TActionPresenceCreateRequest,
  TActionPresenceCreateSuccess,
  TActionPresenceCreateFailure,
} from "../declarations/actions/presences";
import { TAction, TActionType } from "../declarations/actionTypes";
import {
  TPresenceList,
  TPresenceCreateRequest,
  TPresenceListQuery,
  TCustomPresenceList,
  TCustomPresence,
} from "../declarations/maps/presences";

//List
export const actionPresenceListRequest = (
  payload: TPresenceListQuery,
): TActionPresenceListRequest => ({
  type: TActionType.presenceListRequest,
  payload,
});

export const actionPresenceListSuccess = (
  payload: TCustomPresenceList,
): TActionPresenceListSuccess => ({
  type: TActionType.presenceListSuccess,
  payload,
});

export const actionPresenceListFailure = (): TActionPresenceListFailure => ({
  type: TActionType.presenceListFailure,
});

//---

//Loading

export const actionPresenceListLoadingRequest = (): TActionPresenceListLoadingRequest => ({
  type: TActionType.presenceListLoadingRequest,
});

export const actionPresenceListLoadingSuccess = (): TActionPresenceListLoadingSuccess => ({
  type: TActionType.presenceListLoadingSuccess,
});

export const actionPresenceListLoadingFailure = (): TActionPresenceListLoadingFailure => ({
  type: TActionType.presenceListLoadingFailure,
});

// ---
//Create
export const actionPresenceCreateRequest = (
  payload: TPresenceCreateRequest,
): TActionPresenceCreateRequest => ({ type: TActionType.presenceCreateRequest, payload });

export const actionPresenceCreateSuccess = (
  payload?: TCustomPresence,
): TActionPresenceCreateSuccess => ({
  type: TActionType.presenceCreateSuccess,
  payload,
});
export const actionPresenceCreateFailure = (): TActionPresenceCreateFailure => ({
  type: TActionType.presenceCreateFailure,
});
export const actionPresenceCreateReset = (): TAction => ({
  type: TActionType.presenceCreateReset,
});
// ---

export const actionPresenceDeleteSuccess = (payload: string): TAction => ({
  type: TActionType.presenceDeleteSuccess,
  payload,
});

export const actionPresenceUpdateSuccess = (payload: TCustomPresence): TAction => ({
  type: TActionType.presenceUpdateSuccess,
  payload,
});
