/** @format */

import { Col, Form, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionWarehouseUpdateRequest } from "../../../../redux-modules/actions/actionsWarehouses";
import { TWarehouse } from "../../../../redux-modules/declarations/maps/warehouses";
import { selectorLocationsList } from "../../../../redux-modules/selectors/selectorsLocations";
import { Routes } from "../../../../utils/Routes";
import { UIButton } from "../../../ui/Button";
import { UICard } from "../../../ui/Card";
import { UIInput } from "../../../ui/Input";
import { UILink } from "../../../ui/Link";
import { UISelect } from "../../../ui/Select";
import { UIText } from "../../../ui/Text";
import { UITextArea } from "../../../ui/Textarea";
const { Option } = UISelect;

interface Props {
  warehouseInfo: TWarehouse;
}
export const WarehouseInfoTab = ({ warehouseInfo }: Props) => {
  const dispatch = useDispatch();
  const locationList = useSelector(selectorLocationsList);

  //@ts-ignore
  const handleUpdate = (e): void => {
    const contentObj = {
      id: warehouseInfo.id,
      name: e.name,
      description: e.description,
      note: e.note,
      office_id: e.office_id,
    };
    //dispatch
    dispatch(actionWarehouseUpdateRequest(contentObj));
  };

  return (
    <Form
      name="edit-warehouse"
      className="edit-warehouse"
      data-cy="edit-warehouse-form"
      onFinish={handleUpdate}
      initialValues={warehouseInfo}
      layout="vertical"
    >
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24}>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="name"
                label={<UIText strong={true}>Nome</UIText>}
                rules={[{ required: false, message: "Inserisci un nome valido!" }]}
                extra="Il nome del magazzino"
              >
                <UIInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={12}>
              <Form.Item
                name="office_id"
                label={<UIText strong={true}>Sede</UIText>}
                rules={[{ required: true, message: "Seleziona una sede!" }]}
                extra="La sede associata al magazzino"
              >
                <UISelect>
                  {locationList.data.map(location => (
                    <Option value={location.id}>{location.name}</Option>
                  ))}
                </UISelect>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={18}>
              <Form.Item
                name="description"
                label={<UIText strong={true}>Descrizione</UIText>}
                extra="Breve descrizione del magazzino"
              >
                <UIInput />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={18}>
              <Form.Item
                name="note"
                label={<UIText strong={true}>Note</UIText>}
                extra="Note aggiuntive"
              >
                <UITextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={[24, 24]}>
            <Col>
              <UILink route={Routes.warehouses} label={<UIButton>Annulla</UIButton>} />
            </Col>
            <Col>
              <Form.Item>
                <UIButton type="primary" data-cy="new-warehouse-button" htmlType="submit">
                  + Salva Magazzino
                </UIButton>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
