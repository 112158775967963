/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import { TAPIJustificativeListRequest } from "../redux-modules/declarations/apis/justificatives";
import {
  TJustificativeCreateRequest,
  TJustificativeUpdateRequest,
} from "../redux-modules/declarations/maps/justificatives";
import apiClient from "../utils/apiClient";

export const apiJustificativeList = (
  params: TAPIJustificativeListRequest,
): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.JUSTIFICATIVE_TYPES);
};

export const apiJustificativesStatusesList = (): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.JUSTIFICATIVE_STATUSES);
};

export const apiJustificativeCreate = (
  request: TJustificativeCreateRequest,
): Promise<AxiosResponse<unknown>> => apiClient.post(API_ENDPOINTS.JUSTIFICATIVE_TYPES, request);

export const apiJustificativeUpdate = (
  request: TJustificativeUpdateRequest,
): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.JUSTIFICATIVE_TYPES + "/" + request.id, request);

export const apiJustificativeDelete = (justificativeId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.JUSTIFICATIVE_TYPES + "/" + justificativeId);
