/** @format */
//@ts-nocheck
import { Avatar, List, Spin } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import classNames from "classnames";
import { NoticeIconData } from "./index";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  actionNotificationDeleteRequest,
  actionNotificationDeleteReset,
  actionNotificationReadRequest,
} from "../../../redux-modules/actions/actionsNotfication";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { selectorUnreadNotification } from "../../../redux-modules/selectors/selectorNotifications";
import { BaseColor } from "../../../theme/maps/Colors";
import noNotification from "../../../assets/svg/no_notification.svg";

export interface NoticeIconTabProps {
  count?: number;
  name?: string;
  showClear?: boolean;
  showViewMore?: boolean;
  style?: React.CSSProperties;
  title: string;
  tabKey: string;
  data?: NoticeIconData[];
  onClick?: (item: NoticeIconData) => void;
  onClear?: () => void;
  emptyText?: string;
  clearText?: string;
  viewMoreText?: string;
  list: NoticeIconData[];
}
const NoticeList: React.SFC<NoticeIconTabProps> = ({
  data = [],
  onClick,
  onClear,
  title,
  onViewMore,
  emptyText,
  showClear = true,
  clearText,
  viewMoreText,
  showViewMore = false,
}) => {
  const dispatch = useDispatch();
  const unreads = useSelector(selectorUnreadNotification);
  const [readAllLoading, setReadallLoading] = React.useState(false);

  useEffect(() => {
    if (unreads.length == 0) {
      setReadallLoading(false);
    }
  }, [unreads]);

  if (!data || data.length === 0) {
    return (
      <div className={"notFound"} style={{ textAlign: "center" }}>
        <img src={noNotification} alt="not found" />
        <div>{emptyText}</div>
      </div>
    );
  }
  return (
    <CustomDiv className="notif-container">
      <List<NoticeIconData>
        className={"list"}
        style={{
          maxHeight: "350px",
          overflowY: "auto",
        }}
        dataSource={data}
        renderItem={(item, i): unknown => {
          const itemCls = classNames("item", {
            ["read"]: item.read_at,
          });
          // eslint-disable-next-line no-nested-ternary
          const leftIcon = item.avatar ? (
            typeof item.avatar === "string" ? (
              <Avatar className={"avatar"} shape="square" src={item.avatar} />
            ) : (
              <span className={"iconElement"}>{item.avatar}</span>
            )
          ) : null;

          return (
            <List.Item className={itemCls} style={{ cursor: "pointer" }} key={item.key || i}>
              <List.Item.Meta
                className={"meta"}
                avatar={leftIcon}
                title={
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div
                      className={"title"}
                      onClick={(): unknown => {
                        dispatch(actionNotificationReadRequest({ ids: [item.id] }));
                        item.onRemove();
                        item.onClick();
                      }}
                    >
                      {item.title}
                    </div>
                    <div
                      className="close"
                      style={{ marginRight: "10px", fontSize: "15px", color: "rgb(234, 4, 39)" }}
                      onClick={e => {
                        dispatch(actionNotificationDeleteRequest({ ids: [item.id] }));
                        //item.onRemove(); //chiusura della lista

                        //e.stopPropagation();
                      }}
                    >
                      <CloseCircleOutlined />
                    </div>
                  </div>
                }
                description={
                  <div
                    onClick={(): unknown => {
                      dispatch(actionNotificationReadRequest({ ids: [item.id] }));
                      item.onRemove();
                      item.onClick();
                    }}
                  >
                    <div className={"description"}>{item.description}</div>
                    <div
                      className={"datetime"}
                      style={{ fontSize: "13px", fontWeight: 500, float: "right" }}
                    >
                      {moment(item.created_at).format("DD/MM/YYYY HH:mm")}
                    </div>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      <div className={"bottomBar"}>
        {clearText && unreads.length > 0 ? (
          <div
            style={{
              cursor: "pointer",
              border: "1px solid #f0f0f0",
              borderRadius: "7px",
              padding: "3px",
              color: BaseColor,
            }}
            onClick={() => {
              setReadallLoading(true);
              dispatch(actionNotificationReadRequest({ ids: unreads.map(e => e.id) }));
            }}
          >
            {readAllLoading ? (
              <Spin size="small" style={{ marginRight: "10px" }} />
            ) : (
              <CheckCircleOutlined style={{ marginRight: "10px" }} />
            )}

            {clearText}
          </div>
        ) : null}
      </div>
    </CustomDiv>
  );
};

const CustomDiv = styled.div`
  li.ant-list-item.item.read {
    opacity: 0.5 !important;
  }
  .bottomBar {
    padding: 10px 0px;
    text-align: center;
  }
  .ant-list-item-meta-avatar {
    margin-right: 0px;
    margin: 2px 8px !important;
  }
`;

const CustomNoticeList = styled(NoticeList)`
  .list::-webkit-scrollbar {
    display: none;
  }
  .ant-list-item-meta-avatar {
    margin-right: 0px;
    margin: 0px 8px !important;
  }
  .meta {
    width: 100%;
  }
  .item {
    padding-right: 24px;
    padding-left: 24px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;
  }
  .avatar {
    margin-top: 4px;
    background: #fff;
  }
  .iconElement {
    font-size: 32px;
  }
  .read {
    opacity: 0.4 !important;
  }
  .list:last-child {
    border-bottom: 0;
  }
  .list:hover {
    background: @primary-1;
  }
  .title {
    margin-bottom: 8px;
    font-weight: normal;
  }
  .description {
    font-size: 12px;
    line-height: @line-height-base;
  }
  .datetime {
    margin-top: 4px;
    font-size: 7px;
    line-height: @line-height-base;
  }
  .extra {
    float: right;
    margin-top: -1.5px;
    margin-right: 0;
    color: @text-color-secondary;
    font-weight: normal;
  }
  .list {
    max-height: 400px;
    overflow: auto;
  }
  .loadMore {
    padding: 8px 0;
    color: @primary-6;
    text-align: center;
    cursor: pointer;
  }
  .loadedAll {
    color: rgba(0, 0, 0, 0.25);
    cursor: unset;
  }
  .notFound {
    padding: 73px 0 88px;
    color: @text-color-secondary;
    text-align: center;
  }
  .notFound > img {
    display: inline-block;
    height: 76px;
    margin-bottom: 16px;
  }
  .bottomBar {
    height: 46px;
    color: @text-color;
    line-height: 46px;
    text-align: center;
    border-top: 1px solid @border-color-split;
    border-radius: 0 0 @border-radius-base @border-radius-base;
    transition: all 0.3s;
  }
  .bottomBar > div:only-child {
    width: 100%;
  }
  .bottomBar > div:not(:only-child):last-child {
    border-left: 1px solid @border-color-split;
  }
  .bottomBar > div {
    display: inline-block;
    width: 50%;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;
  }
`;

export default CustomNoticeList;
