/** @format */

import { TDepartment } from "../declarations/maps/departments";
import { TStore } from "../declarations/store";

export const selectorDepartmentList = (store: TStore): TDepartment[] =>
  store.departments.departmentList || [];

export const selectorDepartment = (store: TStore, id: string): TDepartment | undefined =>
  store.departments.departmentList?.find(department => department.id === id) || undefined;
