/** @format */

import React, { FC, useEffect, useState } from "react";
import { Fade } from "./partials/Fade";

interface Props {
  children?: React.ReactNode | React.ReactNodeArray;
}

const UITransition: FC<Props> = ({ children }): JSX.Element => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(true);
    return (): void => {
      setShow(false);
    };
  }, []);

  return <Fade show={show}>{children}</Fade>;
};

export default UITransition;
