/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaShiftTypeCreate,
  sagaShiftTypeDelete,
  sagaShiftTypeList,
  sagaShiftTypeUpdate,
} from "../sagas/sagasShiftTypes";

export function* watcherShiftTypes(): unknown {
  yield takeLatest(TActionType.shiftTypeCreateRequest, sagaShiftTypeCreate);
  yield takeLatest(TActionType.shiftTypeListRequest, sagaShiftTypeList);
  yield takeLatest(TActionType.shiftTypeDeleteRequest, sagaShiftTypeDelete);
  yield takeLatest(TActionType.shiftTypeUpdateRequest, sagaShiftTypeUpdate);
}
