/** @format */

import {
  TActionShiftTypeListRequest,
  TActionShiftTypeListSuccess,
  TActionShiftTypeListFailure,
  TActionShiftTypeListLoadingRequest,
  TActionShiftTypeListLoadingSuccess,
  TActionShiftTypeListLoadingFailure,
  TActionShiftTypeCreateRequest,
  TActionShiftTypeCreateSuccess,
  TActionShiftTypeCreateFailure,
  TActionShiftTypeUpdateRequest,
  TActionShiftTypeUpdateSuccess,
  TActionShiftTypeUpdateFailure,
  TActionShiftTypeUpdateReset,
  TActionShiftTypeDeleteRequest,
  TActionShiftTypeDeleteSuccess,
  TActionShiftTypeDeleteFailure,
  TActionShiftTypeDeleteReset,
} from "../declarations/actions/shiftTypes";
import { TAction, TActionType } from "../declarations/actionTypes";
import {
  TShiftType,
  TShiftTypeCreateRequest,
  TShiftTypeUpdateRequest,
} from "../declarations/maps/shiftTypes";

//List
export const actionShiftTypeListRequest = (): TActionShiftTypeListRequest => ({
  type: TActionType.shiftTypeListRequest,
});

export const actionShiftTypeListSuccess = (payload: TShiftType[]): TActionShiftTypeListSuccess => ({
  type: TActionType.shiftTypeListSuccess,
  payload,
});

export const actionShiftTypeListFailure = (): TActionShiftTypeListFailure => ({
  type: TActionType.shiftTypeListFailure,
});

//---

//Loading

export const actionShiftTypeListLoadingRequest = (): TActionShiftTypeListLoadingRequest => ({
  type: TActionType.shiftTypeListLoadingRequest,
});

export const actionShiftTypeListLoadingSuccess = (): TActionShiftTypeListLoadingSuccess => ({
  type: TActionType.shiftTypeListLoadingSuccess,
});

export const actionShiftTypeListLoadingFailure = (): TActionShiftTypeListLoadingFailure => ({
  type: TActionType.shiftTypeListLoadingFailure,
});

// ---
//Create
export const actionShiftTypeCreateRequest = (
  payload: TShiftTypeCreateRequest,
): TActionShiftTypeCreateRequest => ({ type: TActionType.shiftTypeCreateRequest, payload });

export const actionShiftTypeCreateSuccess = (payload: string): TActionShiftTypeCreateSuccess => ({
  type: TActionType.shiftTypeCreateSuccess,
  payload,
});
export const actionShiftTypeCreateFailure = (): TActionShiftTypeCreateFailure => ({
  type: TActionType.shiftTypeCreateFailure,
});
export const actionShiftTypeCreateReset = (): TAction => ({
  type: TActionType.shiftTypeCreateReset,
});
// ---
// Update
export const actionShiftTypeUpdateRequest = (
  payload: TShiftTypeUpdateRequest,
): TActionShiftTypeUpdateRequest => ({
  type: TActionType.shiftTypeUpdateRequest,
  payload,
});

export const actionShiftTypeUpdateSuccess = (): TActionShiftTypeUpdateSuccess => ({
  type: TActionType.shiftTypeUpdateSuccess,
});

export const actionShiftTypeUpdateFailure = (): TActionShiftTypeUpdateFailure => ({
  type: TActionType.shiftTypeUpdateFailure,
});

export const actionShiftTypeUpdateReset = (): TActionShiftTypeUpdateReset => ({
  type: TActionType.shiftTypeUpdateReset,
});

// ---
// Delete
export const actionShiftTypeDeleteRequest = (payload: string): TActionShiftTypeDeleteRequest => ({
  type: TActionType.shiftTypeDeleteRequest,
  payload,
});

export const actionShiftTypeDeleteSuccess = (payload: string): TActionShiftTypeDeleteSuccess => ({
  type: TActionType.shiftTypeDeleteSuccess,
  payload,
});

export const actionShiftTypeDeleteFailure = (): TActionShiftTypeDeleteFailure => ({
  type: TActionType.shiftTypeDeleteFailure,
});

export const actionShiftTypeDeleteReset = (): TActionShiftTypeDeleteReset => ({
  type: TActionType.shiftTypeDeleteReset,
});

// ---
