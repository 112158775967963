/** @format */

import { put, call, all, select } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import {
  apiNotificationList,
  apiNotificationRead,
  apiNotificationDelete,
} from "../../api/apiNotifications";
import {
  actionNotificationListLoadingRequest,
  actionNotificationListSuccess,
  actionNotificationListLoadingSuccess,
  actionNotificationListFailure,
  actionNotificationListLoadingFailure,
  actionNotificationReadSuccess,
  actionNotificationReadFailure,
  actionNotificationDeleteSuccess,
  actionNotificationDeleteReset,
  actionNotificationDeleteFailure,
} from "../actions/actionsNotfication";
import {
  TActionNotificationListRequest,
  TActionNotificationReadRequest,
  TActionNotificationDeleteRequest,
} from "../declarations/actions/notification";
import { TNotification } from "../declarations/maps/notifications";

export function* sagaNotificationList(action: TActionNotificationListRequest): unknown {
  const user_id = yield select(state => state.auth.user?.id);

  if (!!user_id) {
    try {
      yield put(actionNotificationListLoadingRequest());

      const user_id = yield select(state => state.auth.user.id);

      const response: AxiosResponse<TAPIResponse<TNotification[]>> = yield call(
        apiNotificationList,
        user_id,
      );

      yield put(actionNotificationListSuccess(response.data.data));
      yield put(actionNotificationListLoadingSuccess());
    } catch (e) {
      yield put(actionNotificationListFailure());
      yield put(actionNotificationListLoadingFailure());
      showNotification(
        "error",
        "Oops..",
        //@ts-ignore
        e.response.data.errors
          ? //@ts-ignore
            Object.values(e.response.data.errors).join(`\n`)
          : //@ts-ignore
            e.response.data.message,
      );
    }
  }
}

export function* sagaNotificationRead(action: TActionNotificationReadRequest): unknown {
  try {
    yield call(apiNotificationRead, action.payload);

    yield put(actionNotificationReadSuccess());
  } catch (e) {
    yield put(actionNotificationReadFailure());
  }
}

export function* sagaNotificationDelete(action: TActionNotificationDeleteRequest): unknown {
  try {
    yield call(apiNotificationDelete, action.payload);

    yield all([put(actionNotificationDeleteSuccess()), put(actionNotificationDeleteReset())]);
  } catch (e) {
    yield put(actionNotificationDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
