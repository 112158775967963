/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaProductCreate,
  sagaProductDelete,
  sagaProductList,
  sagaProductUpdate,
} from "../sagas/sagasProducts";

export function* watcherProducts(): unknown {
  yield takeLatest(TActionType.productCreateRequest, sagaProductCreate);
  yield takeLatest(TActionType.productListRequest, sagaProductList);
  yield takeLatest(TActionType.productDeleteRequest, sagaProductDelete);
  yield takeLatest(TActionType.productUpdateRequest, sagaProductUpdate);
}
