/** @format */

import {
  TActionDocumentListFailure,
  TActionDocumentListRequest,
  TActionDocumentListSuccess,
} from "../declarations/actions/document";
import { TActionType } from "../declarations/actionTypes";
import { TDocuments } from "../declarations/maps/documents";

type Actions = TActionDocumentListRequest | TActionDocumentListSuccess | TActionDocumentListFailure;

export const reducerDocuments = (prevStore: TDocuments = {}, action: Actions): TDocuments => {
  switch (action.type) {
    case TActionType.documentListRequest:
      return { ...prevStore, documentList: [] };
    case TActionType.documentListSuccess:
      return { ...prevStore, documentList: action.payload };
    case TActionType.documentListFailure:
      return { ...prevStore, documentList: [] };
    default:
      return prevStore;
  }
};
