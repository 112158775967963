/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import { TAPITriggerListRequest } from "../redux-modules/declarations/apis/trigger";
import { TTriggerUpdateRequest } from "../redux-modules/declarations/maps/triggers";
import apiClient from "../utils/apiClient";

export const apiTriggerList = (params: TAPITriggerListRequest): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.TRIGGERS);
};

export const apiTriggerUpdate = (trigger: TTriggerUpdateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.TRIGGERS + "/" + trigger.id, trigger);
