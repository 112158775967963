/** @format */

import { TDocumentType } from "../declarations/maps/documentTypes";
import { TStore } from "../declarations/store";

export const selectorDocumentTypesList = (store: TStore): TDocumentType[] =>
  store.documentTypes.documentTypeList || [];

export const selectorPersonalDocumentTypesList = (store: TStore): TDocumentType[] => {
  if (!store.documentTypes.documentTypeList) return [];
  return store.documentTypes.documentTypeList.filter(el => !el.shared && !el.split);
};

export const selectorGenericDocumentTypesList = (store: TStore): TDocumentType[] => {
  if (!store.documentTypes.documentTypeList) return [];
  return store.documentTypes.documentTypeList.filter(el => el.shared || el.split);
};
