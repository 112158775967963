/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import {
  actionDeviceCreateSuccess,
  actionDeviceCreateReset,
  actionDeviceCreateFailure,
  actionDeviceListLoadingRequest,
  actionDeviceListLoadingSuccess,
  actionDeviceListFailure,
  actionDeviceListLoadingFailure,
  actionDeviceUpdateSuccess,
  actionDeviceUpdateReset,
  actionDeviceUpdateFailure,
  actionDeviceDeleteSuccess,
  actionDeviceDeleteReset,
  actionDeviceDeleteFailure,
  actionDeviceListSuccess,
} from "../actions/actionsDevices";
import {
  TActionDeviceCreateRequest,
  TActionDeviceListRequest,
  TActionDeviceUpdateRequest,
  TActionDeviceDeleteRequest,
} from "../declarations/actions/devices";
import { TDevice } from "../declarations/maps/devices";
import {
  apiDeviceCreate,
  apiDeviceUpdate,
  apiDeviceDelete,
  apiDeviceList,
} from "../../api/apiDevices";

export function* sagaDeviceCreate(action: TActionDeviceCreateRequest): unknown {
  try {
    const response = yield call(apiDeviceCreate, action.payload);

    yield put(actionDeviceCreateSuccess(response.data.id));
    yield put(actionDeviceCreateReset());
    showNotification("success", "Creato!", "Dispositivo creato con successo");
  } catch (e) {
    yield put(actionDeviceCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaDeviceList(action: TActionDeviceListRequest): unknown {
  try {
    yield put(actionDeviceListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TDevice[]>> = yield call(apiDeviceList);

    yield put(actionDeviceListSuccess(response.data.data));
    yield put(actionDeviceListLoadingSuccess());
  } catch (e) {
    yield put(actionDeviceListFailure());
    yield put(actionDeviceListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaDeviceUpdate(action: TActionDeviceUpdateRequest): unknown {
  try {
    yield call(apiDeviceUpdate, action.payload);

    yield all([put(actionDeviceUpdateSuccess()), put(actionDeviceUpdateReset())]);
    showNotification("success", "Aggiornato!", "Dispositivo modificato con successo");
  } catch (e) {
    yield put(actionDeviceUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaDeviceDelete(action: TActionDeviceDeleteRequest): unknown {
  try {
    yield call(apiDeviceDelete, action.payload);

    yield all([put(actionDeviceDeleteSuccess(action.payload)), put(actionDeviceDeleteReset())]);
    showNotification("success", "Eliminato!", "Dispositivo eliminato con successo");
  } catch (e) {
    yield put(actionDeviceDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
