/** @format */

import { Row, Col } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { productAssignedUserListColumns } from "../../../../constants/tables/productAssignedListColumns";
import { selectorPermissionList } from "../../../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../../../utils/permissions/hasPermission";
import { AddProductAssignedUserDrawer } from "../../../layout/SideDialogs/AddProductAssignedUser";
import { UIButton } from "../../../ui/Button";
import { UITable } from "../../../ui/Table";
interface Props {
  updateData: () => void;
  userAssignments: unknown[];
  user_id: string;
}
export const UserAssignmentTab = ({ updateData, userAssignments, user_id }: Props) => {
  const [showingAddModal, setShowingAddModal] = useState(false);
  const permissionList = useSelector(selectorPermissionList);
  const canRiconsegnare = hasPermission(permissionList, "Warehouse", "Update");
  const canAssegnare = hasPermission(permissionList, "Warehouse", "Create");

  const openModal = () => setShowingAddModal(true);
  const closeModal = () => setShowingAddModal(false);

  return (
    <>
      <AddProductAssignedUserDrawer
        user_id={user_id}
        updateData={updateData}
        visible={showingAddModal}
        onClose={closeModal}
      />
      <Row justify="end" align="middle" gutter={[16, 16]} style={{ marginBottom: "10px" }}>
        <Col>
          {canAssegnare && (
            <UIButton type="primary" onClick={openModal}>
              + Aggiungi assegnazione
            </UIButton>
          )}
        </Col>
      </Row>
      <UITable
        style={{ marginBottom: "10px" }}
        size="middle"
        data-cy="product-assigned-list-table"
        columns={[
          ...productAssignedUserListColumns({
            callback: updateData,
            canRiconsegnare: canRiconsegnare,
            user_id: user_id,
          }),
        ]}
        dataSource={userAssignments}
      />
    </>
  );
};
