/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import {
  actionShiftTypeCreateSuccess,
  actionShiftTypeCreateReset,
  actionShiftTypeCreateFailure,
  actionShiftTypeListLoadingRequest,
  actionShiftTypeListLoadingSuccess,
  actionShiftTypeListFailure,
  actionShiftTypeListLoadingFailure,
  actionShiftTypeUpdateSuccess,
  actionShiftTypeUpdateReset,
  actionShiftTypeUpdateFailure,
  actionShiftTypeDeleteSuccess,
  actionShiftTypeDeleteReset,
  actionShiftTypeDeleteFailure,
  actionShiftTypeListSuccess,
} from "../actions/actionsShiftTypes";
import {
  TActionShiftTypeCreateRequest,
  TActionShiftTypeListRequest,
  TActionShiftTypeUpdateRequest,
  TActionShiftTypeDeleteRequest,
} from "../declarations/actions/shiftTypes";
import { TShiftType } from "../declarations/maps/shiftTypes";
import {
  apiShiftTypeCreate,
  apiShiftTypeUpdate,
  apiShiftTypeDelete,
  apiShiftTypeList,
} from "../../api/apiShiftTypes";

export function* sagaShiftTypeCreate(action: TActionShiftTypeCreateRequest): unknown {
  try {
    const response = yield call(apiShiftTypeCreate, action.payload);

    yield put(actionShiftTypeCreateSuccess(response.data.id));
    yield put(actionShiftTypeCreateReset());
    showNotification("success", "Creato!", "Tipologia Turno creata con successo");
  } catch (e) {
    yield put(actionShiftTypeCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaShiftTypeList(action: TActionShiftTypeListRequest): unknown {
  try {
    yield put(actionShiftTypeListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TShiftType[]>> = yield call(apiShiftTypeList);

    yield put(actionShiftTypeListSuccess(response.data.data));
    yield put(actionShiftTypeListLoadingSuccess());
  } catch (e) {
    yield put(actionShiftTypeListFailure());
    yield put(actionShiftTypeListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaShiftTypeUpdate(action: TActionShiftTypeUpdateRequest): unknown {
  try {
    yield call(apiShiftTypeUpdate, action.payload);

    yield all([put(actionShiftTypeUpdateSuccess()), put(actionShiftTypeUpdateReset())]);
    showNotification("success", "Aggiornato!", "Tipologia Turno modificata con successo");
  } catch (e) {
    yield put(actionShiftTypeUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaShiftTypeDelete(action: TActionShiftTypeDeleteRequest): unknown {
  try {
    yield call(apiShiftTypeDelete, action.payload);

    yield all([
      put(actionShiftTypeDeleteSuccess(action.payload)),
      put(actionShiftTypeDeleteReset()),
    ]);
    showNotification("success", "Eliminato!", "Tipologia Turno eliminata con successo");
  } catch (e) {
    yield put(actionShiftTypeDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
