/** @format */

export const BaseColor = process.env.REACT_APP_THEME_COLOR;

export enum Colors {
  primary = "#F7F6F1",
  primaryBorder = "rgba(247, 246, 241,.25)",
  cream = "#F7F6F1",
  background = "#181818",
  disabled = "#adadad4d",
  gray = "#F7F6F1",
  dark = "#292929",
  green = "#2BAC76",
  blue = "#2c79a2",
  red = "#E01E5A",
  yellow = "#F5A623",
  darkGray = "#525252",
  darkBlue = "#0081c2",
  darkBlueRGBA = "rgba(0, 121, 255, .25)",
  title = "#419CDA",
  loadingSublabel = "#373737",
  subGroup = "#292929",
  grayLabel = "#7B7B78",
  giarno = "rgb(235, 161, 52)",
}
