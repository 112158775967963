/** @format */

import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { UIText } from "../../components/ui/Text";
import {
  actionCommunicationCreateRequest,
  actionCommunicationDeleteRequest,
} from "../../redux-modules/actions/actionsCommunications";
import { selectorPermissionList } from "../../redux-modules/selectors/selectorsPermissions";
import { BaseColor } from "../../theme/maps/Colors";
import { hasPermission } from "../../utils/permissions/hasPermission";
import { Routes } from "../../utils/Routes";

export const communicationListColumns = [
  {
    title: "Titolo",
    dataIndex: "title",
    key: "title",
    showMobile: true,
  },
  {
    title: "Data creazione",
    dataIndex: "sent_at",
    key: "sent_at",
    showMobile: true,
  },
  { title: "N° Destinatari", dataIndex: "receiver_users", key: "receiver_users", showMobile: true },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const permissionList = useSelector(selectorPermissionList);

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => history.push(Routes.communicationsView.replace(":id_communication", row.id))}
        icon={<EditOutlined />}
      >
        Visualizza
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          dispatch(actionCommunicationDeleteRequest(row.id));
        }}
        icon={<DeleteOutlined />}
      >
        Elimina
      </Menu.Item>
    </Menu>
  );

  if (!hasPermission(permissionList, "Communication", "Update")) return <></>;
  return (
    <>
      <Dropdown overlay={menu}>
        <UIText css={"font-size: 24px; cursor: pointer;"} color={BaseColor}>
          <MoreOutlined />
        </UIText>
      </Dropdown>
    </>
  );
};
