/** @format */
import { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";

type Return = [
  string,
  string,
  (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => void,
  boolean,
];

export const useDebouncedInput = (
  initialValue: string = "",
  functionToValidate: (text: string) => boolean = (): boolean => true,
  blockDuringWriting: boolean = false,
  delay: number = 300,
): Return => {
  const [value, setValue] = useState(initialValue);
  const isValid = !value || functionToValidate(value);

  const debouncedValue = useDebounce(value, delay) as string;

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string): void => {
    const text = typeof event === "string" ? event : event.target.value;
    const isValidType = functionToValidate(text);
    if (!blockDuringWriting) setValue(text);
    else if (isValidType) setValue(text);
  };

  return [debouncedValue, value, onChange, isValid];
};
