/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import {
  TActionLocationCreateRequest,
  TActionLocationDeleteRequest,
  TActionLocationListRequest,
  TActionLocationUpdateRequest,
} from "../declarations/actions/location";
import {
  apiLocationCreate,
  apiLocationDelete,
  apiLocationsList,
  apiLocationUpdate,
} from "../../api/apiLocations";
import {
  actionLocationCreateFailure,
  actionLocationCreateReset,
  actionLocationCreateSuccess,
  actionLocationDeleteReset,
  actionLocationDeleteSuccess,
  actionLocationListFailure,
  actionLocationListLoadingFailure,
  actionLocationListLoadingRequest,
  actionLocationListLoadingSuccess,
  actionLocationListSuccess,
  actionLocationUpdateFailure,
  actionLocationUpdateReset,
  actionLocationUpdateSuccess,
} from "../actions/actionsLocations";
import { utilityFormatLocationTable } from "../../utils/format/locationTable";
import { TLocation } from "../declarations/maps/locations";
import { actionGroupDeleteFailure } from "../actions/actionsGroups";
import { showNotification } from "../../utils/showNotification";

export function* sagaLocationCreate(action: TActionLocationCreateRequest): unknown {
  try {
    const response = yield call(apiLocationCreate, action.payload);

    yield put(actionLocationCreateSuccess(response.data.id));
    yield put(actionLocationCreateReset());
    showNotification("success", "Creata!", "Sede creata con successo");
  } catch (e) {
    yield put(actionLocationCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaLocationList(action: TActionLocationListRequest): unknown {
  try {
    yield put(actionLocationListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TLocation[]>> = yield call(
      apiLocationsList,
      action.payload,
    );

    const locationList = utilityFormatLocationTable(response.data);

    //@ts-ignore
    yield put(actionLocationListSuccess(locationList));
    yield put(actionLocationListLoadingSuccess());
  } catch (e) {
    yield put(actionLocationListFailure());
    yield put(actionLocationListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaLocationUpdate(action: TActionLocationUpdateRequest): unknown {
  try {
    yield call(apiLocationUpdate, action.payload);

    yield all([put(actionLocationUpdateSuccess()), put(actionLocationUpdateReset())]);
    showNotification("success", "Aggiornata!", "Sede modificata con successo");
  } catch (e) {
    yield put(actionLocationUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaLocationDelete(action: TActionLocationDeleteRequest): unknown {
  try {
    yield call(apiLocationDelete, action.payload);

    yield all([put(actionLocationDeleteSuccess(action.payload)), put(actionLocationDeleteReset())]);
    showNotification("success", "Eliminata!", "Sede eliminata con successo");
  } catch (e) {
    yield put(actionGroupDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
