/** @format */

import { TCommunication } from "../declarations/maps/communications";
import { TStore } from "../declarations/store";

export const selectorCommunicationList = (store: TStore): TCommunication[] =>
  store.communications.communicationList || [];

export const selectorCommunication = (store: TStore, id: string): TCommunication | undefined =>
  store.communications.communicationList?.find(communication => communication.id === id) ||
  undefined;
