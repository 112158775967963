/** @format */

export enum Routes {
  home = "/",
  login = "/login",
  initialConfig = "/setup",
  forgotPassword = "/recupero/password",
  resetPassword = "/reset/password/:token",
  badge = "/timbratura",
  users = "/utenti",
  usersNew = "/utenti/nuovo",
  usersEdit = "/utenti/:id_user",
  usersEditProfessionalInfo = "/utenti/:id_user/informazioni-professionali",
  usersEditAggiuntiveInfo = "/utenti/:id_user/informazioni-aggiuntive",
  usersEditDocuments = "/utenti/:id_user/documenti",
  usersEditAssignments = "/utenti/:id_user/dotazioni",
  usersEditCommunications = "/utenti/:id_user/comunicazioni",
  groups = "/gruppi",
  groupsNew = "/gruppi/nuovo",
  groupsEdit = "/gruppi/:id_group",
  departments = "/reparti",
  departmentsNew = "/reparti/nuovo",
  departmentsEdit = "/reparti/:id_department",
  locations = "/sedi",
  locationsNew = "/sedi/nuova",
  locationsEdit = "/sedi/:id_location",
  userProfile = "/profilo",
  documents = "/documenti",
  documentsNew = "/documenti/nuovo",
  documentsManagement = "/gestione/documenti",
  settings = "/configurazione",
  settingsDocumentsType = "/configurazione/tipologia-documento",
  settingsDocumentsTypeEdit = "/configurazione/tipologia-documento/:id_document_type",
  settingsRequestType = "/configurazione/tipologia-richiesta",
  settingsRequestTypeEdit = "/configurazione/tipologia-richiesta/:id_request_type",
  settingsModelDetails = "/configurazione/campi-aggiuntivi",
  settingsTriggers = "/configurazione/triggers",
  settingsPresenceTypes = "/configurazione/tipologia-presenze",
  settingsPresenceTypesEdit = "/configurazione/tipologia-presenze/:id_presence_type",
  settingsPresenceTypesNew = "/configurazione/tipologia-presenze/nuovo",
  requests = "/richieste",
  requestNew = "/richiesta/nuova",
  requestTypeNew = "/tipo/richiesta/nuova",
  devices = "/dispositivi",
  devicesEdit = "/dispositivi/:id_device",
  devicesCreate = "/dispositivi/nuovo",
  presenceTypes = "/tipologie-presenze",
  shiftTypes = "/tipologie-turni",
  shiftTypesCreate = "/tipologie-turni/nuova",
  shiftTypesEdit = "/tipologie-turni/:id_shift_type",
  shifts = "/turni",
  presences = "/presenze",
  presencesReport = "/report/presenze",
  products = "/prodotti",
  productsNew = "/prodotti/nuovo",
  productsEdit = "/prodotti/:id_product",
  warehouses = "/magazzini",
  warehousesNew = "/magazzini/nuovo",
  warehousesEdit = "/magazzini/:id_warehouse",
  warehousesAvailable = "/magazzini/:id_warehouse/disponibilita",
  warehousesAssigned = "/magazzini/:id_warehouse/assegnazioni",
  comunications = "/comunicazioni",
  comunicationsNew = "/comunicazioni/nuova",
  communicationsView = "/comunicazioni/:id_communication",
  fleets = "/mezzi",
  fleetsNew = "/mezzi/nuovo",
  fleetsEdit = "/mezzi/:id_fleet",
  reservations = "/prenotazioni",
  reservationsNew = "/prenotazioni/nuova",
  reservationsEdit = "/prenotazioni/:id_reservation",
  travelsAndExpenses = "/viaggi-e-spese",
  travelsAndExpensesNew = "/viaggi-e-spese/nuovo",
  travelsAndExpensesEdit = "/viaggi-e-spese/:id_travel_expense",
}
