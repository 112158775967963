/** @format */

import {
  TActionDepartmentListFailure,
  TActionDepartmentListRequest,
  TActionDepartmentListSuccess,
} from "../declarations/actions/departments";
import { TActionType } from "../declarations/actionTypes";
import { TDepartments } from "../declarations/maps/departments";

type Actions =
  | TActionDepartmentListRequest
  | TActionDepartmentListSuccess
  | TActionDepartmentListFailure;

export const reducerDepartments = (
  prevStore: TDepartments = { departmentList: [] },
  action: Actions,
): TDepartments => {
  switch (action.type) {
    case TActionType.departmentListRequest:
      return { ...prevStore, departmentList: [] };
    case TActionType.departmentListSuccess:
      return { ...prevStore, departmentList: action.payload };
    case TActionType.departmentListFailure:
      return { ...prevStore, departmentList: [] };
    default:
      return prevStore;
  }
};
