/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import {
  actionFleetCreateSuccess,
  actionFleetCreateReset,
  actionFleetCreateFailure,
  actionFleetListLoadingRequest,
  actionFleetListLoadingSuccess,
  actionFleetListFailure,
  actionFleetListLoadingFailure,
  actionFleetUpdateSuccess,
  actionFleetUpdateReset,
  actionFleetUpdateFailure,
  actionFleetDeleteSuccess,
  actionFleetDeleteReset,
  actionFleetDeleteFailure,
  actionFleetListSuccess,
} from "../actions/actionsFleets";
import {
  TActionFleetCreateRequest,
  TActionFleetListRequest,
  TActionFleetUpdateRequest,
  TActionFleetDeleteRequest,
} from "../declarations/actions/fleets";
import { TFleet } from "../declarations/maps/fleets";
import { apiFleetCreate, apiFleetUpdate, apiFleetDelete, apiFleetList } from "../../api/apiFleets";

export function* sagaFleetCreate(action: TActionFleetCreateRequest): unknown {
  try {
    const response = yield call(apiFleetCreate, action.payload);

    yield put(actionFleetCreateSuccess(response.data.id));
    yield put(actionFleetCreateReset());
    showNotification("success", "Creato!", "Mezzo creato con successo");
  } catch (e) {
    yield put(actionFleetCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaFleetList(action: TActionFleetListRequest): unknown {
  try {
    yield put(actionFleetListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TFleet[]>> = yield call(apiFleetList);

    yield put(actionFleetListSuccess(response.data.data));
    yield put(actionFleetListLoadingSuccess());
  } catch (e) {
    yield put(actionFleetListFailure());
    yield put(actionFleetListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaFleetUpdate(action: TActionFleetUpdateRequest): unknown {
  try {
    yield call(apiFleetUpdate, action.payload);

    yield all([put(actionFleetUpdateSuccess()), put(actionFleetUpdateReset())]);
    showNotification("success", "Aggiornato!", "Mezzo modificato con successo");
  } catch (e) {
    yield put(actionFleetUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaFleetDelete(action: TActionFleetDeleteRequest): unknown {
  try {
    yield call(apiFleetDelete, action.payload);

    yield all([put(actionFleetDeleteSuccess(action.payload)), put(actionFleetDeleteReset())]);
    showNotification("success", "Eliminato!", "Mezzo eliminato con successo");
  } catch (e) {
    yield put(actionFleetDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
