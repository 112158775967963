/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import {
  actionDepartmentCreateSuccess,
  actionDepartmentCreateReset,
  actionDepartmentCreateFailure,
  actionDepartmentListLoadingRequest,
  actionDepartmentListLoadingSuccess,
  actionDepartmentListFailure,
  actionDepartmentListLoadingFailure,
  actionDepartmentUpdateSuccess,
  actionDepartmentUpdateReset,
  actionDepartmentUpdateFailure,
  actionDepartmentDeleteSuccess,
  actionDepartmentDeleteReset,
  actionDepartmentDeleteFailure,
  actionDepartmentListSuccess,
} from "../actions/actionsDepartments";
import {
  TActionDepartmentCreateRequest,
  TActionDepartmentListRequest,
  TActionDepartmentUpdateRequest,
  TActionDepartmentDeleteRequest,
} from "../declarations/actions/departments";
import { TDepartment } from "../declarations/maps/departments";
import {
  apiDepartmentCreate,
  apiDepartmentUpdate,
  apiDepartmentDelete,
  apiDepartmentList,
} from "../../api/apiDepartments";

export function* sagaDepartmentCreate(action: TActionDepartmentCreateRequest): unknown {
  try {
    const response = yield call(apiDepartmentCreate, action.payload);

    yield put(actionDepartmentCreateSuccess(response.data.id));
    yield put(actionDepartmentCreateReset());
    showNotification("success", "Creato!", "Reparto creato con successo");
  } catch (e) {
    yield put(actionDepartmentCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaDepartmentList(action: TActionDepartmentListRequest): unknown {
  try {
    yield put(actionDepartmentListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TDepartment[]>> = yield call(apiDepartmentList);

    yield put(actionDepartmentListSuccess(response.data.data));
    yield put(actionDepartmentListLoadingSuccess());
  } catch (e) {
    yield put(actionDepartmentListFailure());
    yield put(actionDepartmentListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaDepartmentUpdate(action: TActionDepartmentUpdateRequest): unknown {
  try {
    yield call(apiDepartmentUpdate, action.payload);

    yield all([put(actionDepartmentUpdateSuccess()), put(actionDepartmentUpdateReset())]);
    showNotification("success", "Aggiornato!", "Reparto modificato con successo");
  } catch (e) {
    yield put(actionDepartmentUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaDepartmentDelete(action: TActionDepartmentDeleteRequest): unknown {
  try {
    yield call(apiDepartmentDelete, action.payload);

    yield all([
      put(actionDepartmentDeleteSuccess(action.payload)),
      put(actionDepartmentDeleteReset()),
    ]);
    showNotification("success", "Eliminato!", "Reparto eliminato con successo");
  } catch (e) {
    yield put(actionDepartmentDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
