/** @format */

import { TAPIResponse } from "../../redux-modules/declarations/apis/general";
import {
  TLocation,
  TLocationDataSource,
  TLocationDataSourceList,
  TLocationList,
} from "../../redux-modules/declarations/maps/locations";
import { utilityFormatApiLocationList } from "./apiLocation";

export const utilityFormatLocationTable = (
  list: TAPIResponse<TLocation[]>,
  loading: boolean = false,
): TLocationList => ({
  data: utilityFormatApiLocationList(list.data), //passaggio al momento inutile, ma che servirà per tutte le altre (anche per questa, refactoring)
  pagination: {
    current: list.meta?.current_page || 0,
    pageSize: list.meta?.per_page || 0,
    lastPage: list.meta?.last_page || 0,
    total: list.meta?.total || 0,
  },
  loading,
});

export const utilityFormatLocationsToTableDataSource = (
  location: TLocation,
): TLocationDataSource => ({
  id: location.id,
  key: location.id,
  name: location.name,
  //@ts-ignore
  location: location.location || "",
});

export const utilityFormatLocationsToTableDataSourceList = (
  list: TLocation[],
): TLocationDataSourceList =>
  list.map(location => utilityFormatLocationsToTableDataSource(location));
