/** @format */

import { CheckCircleOutlined, RetweetOutlined } from "@ant-design/icons";
import { Avatar, Col, Divider, Drawer, Form, Row, Skeleton, Tag } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../../../constants/api";
import {
  actionShiftDeleteRequest,
  actionShiftUpdateRequest,
  actionShiftUpdateSuccess,
} from "../../../../redux-modules/actions/actionsShifts";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import { TShift } from "../../../../redux-modules/declarations/maps/shifts";
import { selectorLoggedUser } from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorPermissionList } from "../../../../redux-modules/selectors/selectorsPermissions";
import { selectorShiftTypeList } from "../../../../redux-modules/selectors/selectorsShiftTypes";
import { BaseColor } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { hasPermission } from "../../../../utils/permissions/hasPermission";
import { showNotification } from "../../../../utils/showNotification";
import { darkenFromHex, stringToHex } from "../../../../utils/strings/stringToColor";
import { UIButton } from "../../../ui/Button";
import { UICard } from "../../../ui/Card";
import { UISelect } from "../../../ui/Select";
import { UIText } from "../../../ui/Text";
import { UITextArea } from "../../../ui/Textarea";
import { UITitle } from "../../../ui/Title";
const { Option } = UISelect;

interface Props {
  visible: boolean;
  onClose?: () => void;
  shift?: TShift;
  modalLoading?: boolean;
}

export const ShowShiftDrawer: FC<Props> = ({
  visible,
  onClose,
  shift,
  modalLoading,
}: Props): JSX.Element => {
  //@ts-ignore
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [user, setUser] = useState<TUser>();
  const shiftTypes = useSelector(selectorShiftTypeList);
  const [showLoading, setShowLoading] = useState(modalLoading);
  const permissionList = useSelector(selectorPermissionList);
  const loggedUser = useSelector(selectorLoggedUser);
  const [switchNote, setSwitchNote] = useState("");
  const [switchingId, setSwitchingId] = useState(null);
  const [isAcceptingSwitch, setIsAcceptingSwitch] = useState(false);
  const canDeletePlanning = hasPermission(permissionList, "ShiftPlanning", "Delete");
  const canManageSwitch = hasPermission(permissionList, "ShiftPlanning", "Canmanageswitch");
  const canAskForSwitch =
    hasPermission(permissionList, "ShiftPlanning", "Askforswitch") &&
    shift?.user.id == loggedUser?.id;

  useEffect(() => {
    if (shift) {
      setUser(shift.user);
    }
  }, [shift]);

  const handleClose = () => {
    if (onClose) {
      // quello che vogliamo
      setIsAcceptingSwitch(false);
      setSwitchNote("");
      onClose();
    }
  };

  const onRemove = () => {
    if (shift) {
      dispatch(actionShiftDeleteRequest(shift.id));
    }
    handleClose();
  };

  const askForSwitch = () => {
    apiClient
      .post(API_ENDPOINTS.SHIFTS_SWITCH, {
        shift_planning_id: shift?.id,
        pending_switch_message: switchNote,
        pending_switch: 1,
      })
      .then(res => {
        handleClose();
        showNotification(
          "success",
          "Richiesta cambio turno",
          "Richiesta di cambio turno inviata correttamente",
        );
        dispatch(
          actionShiftUpdateSuccess({
            id: `${shift?.id}`,
            pending_switch: true,
            pending_switch_message: switchNote,
          }),
        );
      })
      .catch(err => {
        showNotification(
          "error",
          "iechiesta cambio turno",
          "Errore durante l'invio della richiesta di cambio turno",
        );
      });
  };

  const denySwitch = () => {
    apiClient
      .post(API_ENDPOINTS.SHIFTS_SWITCH, {
        shift_planning_id: shift?.id,
        pending_switch_message: "",
        pending_switch: "00",
      })
      .then(res => {
        dispatch(
          actionShiftUpdateSuccess({
            id: `${shift?.id}`,
            pending_switch: false,
            pending_switch_message: "",
          }),
        );
        handleClose();
      });
  };

  const onSwitchShift = () => {
    apiClient
      .post(API_ENDPOINTS.SHIFTS_SWITCH_MANAGE, {
        shift_planning_id: shift?.id,
        shift_type_id: switchingId,
      })
      .then(res => {
        handleClose();
        showNotification("success", "Cambio turno", "Cambio turno effettuato correttamente");
        dispatch(actionShiftUpdateSuccess({ ...res.data.data, old_id: shift?.id }));
      })
      .catch(err => {
        showNotification(
          "error",
          "Richiesta cambio turno",
          "Errore durante l'invio della richiesta di cambio turno",
        );
        setSwitchingId(null);
        setIsAcceptingSwitch(false);
      });
  };
  const onSwitchShiftChange = (value: string) => {
    //@ts-ignore
    setSwitchingId(value);
  };

  if (!user || !shiftTypes)
    return (
      <Drawer
        title="Turno"
        placement="right"
        onClose={handleClose}
        closable={true}
        visible={visible}
        width={400}
      >
        <UICard style={{ marginBottom: "20px", border: "none" }}>
          <Skeleton active />
        </UICard>
      </Drawer>
    );

  return (
    <Drawer
      title="Turno"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <Row justify="space-between">
          <Col>
            <UIButton
              onClick={onRemove}
              style={{
                display: canDeletePlanning ? "block" : "none",
                borderRadius: "7px",
                backgroundColor: "#e00102",
                borderColor: "#e00102",
                color: "white",
              }}
            >
              Elimina
            </UIButton>
          </Col>
          <Col>
            <UIButton onClick={onClose}>Annulla</UIButton>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          {!shift ? (
            <UICard style={{ marginBottom: "20px", border: "none" }}>
              <Skeleton active />
            </UICard>
          ) : (
            <>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Avatar
                    size={64}
                    //@ts-ignore
                    src={shift.user?.avatar}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <UITitle level={3} color={BaseColor}>
                    {shift.user.name + " " + shift.user.surname}
                  </UITitle>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Tag
                    key={"group"}
                    color={darkenFromHex(stringToHex(shift.group.name))}
                    style={{ borderRadius: "5px" }}
                  >
                    {shift.group.name}
                  </Tag>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <UITitle level={5} color={BaseColor}>
                    Info
                  </UITitle>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <UIText>Tipologia Turno:</UIText>{" "}
                      <UIText style={{ fontWeight: "bold" }}>
                        <Tag
                          color={"#b7b7d5"}
                          style={{
                            borderRadius: "5px",
                          }}
                        >
                          {shift.shift_type.name}
                        </Tag>
                      </UIText>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UIText>Data:</UIText>{" "}
                      <UIText style={{ fontWeight: "bold" }}>
                        {moment(shift.date).format("DD/MM/YYYY")}
                      </UIText>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UIText>Dalle:</UIText>{" "}
                      <UIText style={{ fontWeight: "bold" }}>{shift.shift_type.time_from}</UIText>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UIText>Alle:</UIText>{" "}
                      <UIText style={{ fontWeight: "bold" }}>{shift.shift_type.time_to}</UIText>
                    </Col>
                  </Row>
                  {shift.office && (
                    <Row>
                      <Col span={24}>
                        <UIText>Sede:</UIText>{" "}
                        <UIText style={{ fontWeight: "bold" }}>{shift.office?.name}</UIText>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              {!!shift.creator_user && (
                <>
                  <Divider />
                  <Row>
                    <Col span={24}>
                      <UIText>Assegnato da:</UIText>
                      <UIText style={{ fontWeight: "bold", marginLeft: "3px" }}>
                        {shift.creator_user}
                      </UIText>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UIText>Assegnato il:</UIText>
                      <UIText style={{ fontWeight: "bold", marginLeft: "3px" }}>
                        {shift.creator_date}
                      </UIText>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
      {canAskForSwitch && (
        <Row>
          <Col span={24}>
            <Divider />
            <UITitle level={5} color={BaseColor}>
              Cambio Turno
            </UITitle>
            {!!shift?.pending_switch ? (
              <>
                <UIText>Richiesta cambio turno in fase di approvazione</UIText>
              </>
            ) : (
              <>
                <UITextArea
                  value={switchNote}
                  onChange={e => setSwitchNote(e.target.value)}
                  style={{ marginBottom: "10px" }}
                  placeholder="Inserisci una motivazione per il cambio turno"
                ></UITextArea>
                <UIButton disabled={!switchNote} onClick={askForSwitch} type="primary">
                  <RetweetOutlined />
                  Richiedi cambio turno
                </UIButton>
              </>
            )}
          </Col>
        </Row>
      )}
      {canManageSwitch && !!shift?.pending_switch && (
        <Row>
          <Col span={24}>
            <Divider />
            <UITitle level={5} color={BaseColor}>
              Gestisci Cambio Turno
            </UITitle>
            <Row>
              <Col span={24}>
                <UIText style={{ fontWeight: "bold", marginRight: "4px" }}>Messaggio:</UIText>
                <UIText>{shift.pending_switch_message}</UIText>
              </Col>
            </Row>
            <Row style={{ marginTop: "3px" }}>
              <Col span={24}>
                <UIButton type="primary" onClick={() => setIsAcceptingSwitch(true)}>
                  Gestisci
                </UIButton>
                <UIButton
                  onClick={denySwitch}
                  style={{
                    marginLeft: "3px",
                    borderRadius: "7px",
                    backgroundColor: "#d65959",
                    borderColor: "#d65959",
                    color: "white",
                  }}
                >
                  Rifiuta
                </UIButton>
              </Col>
            </Row>
            {isAcceptingSwitch && (
              <Row>
                <Col span={24}>
                  <UISelect
                    //@ts-ignore
                    onChange={onSwitchShiftChange}
                    style={{ marginTop: "4px", marginRight: "4px", width: "70%" }}
                    showSearch
                    filterOption={(input, option) =>
                      ((option!.children as unknown) as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {shiftTypes.map((shiftType, index) => (
                      <Option value={shiftType.id}>{shiftType.name}</Option>
                    ))}
                  </UISelect>
                  <UIButton onClick={onSwitchShift} type="primary">
                    <CheckCircleOutlined style={{ marginRight: "3px" }} /> Ok
                  </UIButton>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      )}
    </Drawer>
  );
};
