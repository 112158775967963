/** @format */

import { Row, Col, Tag, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../../../constants/api";
import { selectorJustificativeList } from "../../../../redux-modules/selectors/selectorsJustificatives";
import { selectorRequestListUpdated } from "../../../../redux-modules/selectors/selectorsUI";
import apiClient from "../../../../utils/apiClient";
import { UICard } from "../../../ui/Card";
import { UIText } from "../../../ui/Text";
import { WidgetCard } from "../RecentDocuments";

interface Counters {
  ferie_maturate: string;
  ferie_godute: string;
  ferie_residuo: string;
  rol_maturati: string;
  rol_goduti: string;
  rol_residuo: string;
  malattie_godute: string;
}

export const WidgetHolidaysCounter = (): JSX.Element => {
  const justificativeTypes = useSelector(selectorJustificativeList);
  const [justificativeCounter, setJustificativeCounter] = useState<Counters | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const isRequestCreated = useSelector(selectorRequestListUpdated);

  const fetchData = () => {
    setIsLoading(true);
    apiClient
      .get(API_ENDPOINTS.JUSTIFICATIVE_COUNTER)
      .then(r => {
        setJustificativeCounter(r.data);
        setIsLoading(false);
      })
      .catch(err => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isRequestCreated) {
      fetchData();
    }
  }, [isRequestCreated]);

  return (
    <>
      <WidgetCard title="Contatori Giustificativi">
        <Row justify="space-evenly" gutter={[12, 0]}>
          <Col xs={24} xl={8}>
            <UICard>
              <Row>
                <Col style={{ textAlign: "center" }} span={24}>
                  {isLoading ? (
                    <Spin size="large" />
                  ) : (
                    <UIText style={{ fontSize: "40px" }}>
                      {justificativeCounter?.ferie_godute}
                    </UIText>
                  )}
                  <UIText style={{ fontSize: "15px" }}>{!isLoading && "gg"}</UIText>
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: "center" }} span={24}>
                  <Tag
                    style={{
                      backgroundColor: "rgb(234, 145, 97)",
                      border: "none",
                      height: "5px",
                      width: "100%",
                    }}
                  >
                    {"\u00A0"}
                  </Tag>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ textAlign: "center", display: "flex", flexDirection: "column" }}
                  span={24}
                >
                  <UIText style={{ fontSize: "15px", fontWeight: 500 }}>Ferie</UIText>
                  <UIText style={{ fontSize: "15px", fontWeight: 500 }}>Richieste</UIText>
                </Col>
              </Row>
            </UICard>
          </Col>
          <Col xs={24} xl={8}>
            <UICard>
              <Row>
                <Col style={{ textAlign: "center" }} span={24}>
                  {isLoading ? (
                    <Spin size="large" />
                  ) : (
                    <UIText style={{ fontSize: "40px" }}>
                      {justificativeCounter?.malattie_godute}
                    </UIText>
                  )}
                  <UIText style={{ fontSize: "15px" }}>{!isLoading && "gg"}</UIText>
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: "center" }} span={24}>
                  <Tag
                    style={{
                      backgroundColor: "rgb(173, 46, 97)",
                      border: "none",
                      height: "5px",
                      width: "100%",
                    }}
                  >
                    {"\u00A0"}
                  </Tag>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ textAlign: "center", display: "flex", flexDirection: "column" }}
                  span={24}
                >
                  <UIText style={{ fontSize: "15px", fontWeight: 500 }}>Malattie</UIText>
                  <UIText style={{ fontSize: "15px", fontWeight: 500 }}>Richieste</UIText>
                </Col>
              </Row>
            </UICard>
          </Col>
          <Col xs={24} xl={8}>
            <UICard>
              <Row>
                <Col style={{ textAlign: "center" }} span={24}>
                  {isLoading ? (
                    <Spin size="large" />
                  ) : (
                    <UIText style={{ fontSize: "40px" }}>{justificativeCounter?.rol_goduti}</UIText>
                  )}
                  <UIText style={{ fontSize: "15px" }}>{!isLoading && "h"}</UIText>
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: "center" }} span={24}>
                  <Tag
                    style={{
                      backgroundColor: "rgb(128, 194, 193)",
                      border: "none",
                      height: "5px",
                      width: "100%",
                    }}
                  >
                    {"\u00A0"}
                  </Tag>
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ textAlign: "center", display: "flex", flexDirection: "column" }}
                  span={24}
                >
                  <UIText style={{ fontSize: "15px", fontWeight: 500 }}>ROL</UIText>
                  <UIText style={{ fontSize: "15px", fontWeight: 500 }}>Richiesti</UIText>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </WidgetCard>
    </>
  );
};
