/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import {
  actionShiftCreateSuccess,
  actionShiftCreateReset,
  actionShiftCreateFailure,
  actionShiftListLoadingRequest,
  actionShiftListLoadingSuccess,
  actionShiftListFailure,
  actionShiftListLoadingFailure,
  actionShiftListSuccess,
  actionShiftDeleteFailure,
  actionShiftDeleteReset,
  actionShiftDeleteSuccess,
} from "../actions/actionsShifts";
import {
  TActionShiftCreateRequest,
  TActionShiftDeleteRequest,
  TActionShiftListRequest,
} from "../declarations/actions/shifts";
import { TShift } from "../declarations/maps/shifts";
import { apiShiftCreate, apiShiftDelete, apiShiftList } from "../../api/apiShifts";
import moment from "moment";

export function* sagaShiftCreate(action: TActionShiftCreateRequest): unknown {
  try {
    const response = yield call(apiShiftCreate, action.payload);

    yield put(actionShiftCreateSuccess(response.data.data));
    yield put(actionShiftCreateReset());
    showNotification(
      "success",
      "Turno assegnato: ",
      response.data.data.shift_type.name +
        " per l'utente " +
        response.data.data.user.name +
        " " +
        response.data.data.user.surname +
        " per il giorno " +
        moment(response.data.data.date).format("DD/MM/YYYY"),
    );
  } catch (e) {
    yield put(actionShiftCreateFailure());
    yield put(actionShiftCreateReset());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaShiftList(action: TActionShiftListRequest): unknown {
  try {
    yield put(actionShiftListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TShift[]>> = yield call(
      apiShiftList,
      action.payload,
    );

    yield put(actionShiftListSuccess(response.data.data));
    yield put(actionShiftListLoadingSuccess());
  } catch (e) {
    yield put(actionShiftListFailure());
    yield put(actionShiftListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaShiftDelete(action: TActionShiftDeleteRequest): unknown {
  try {
    yield call(apiShiftDelete, action.payload);

    yield all([put(actionShiftDeleteSuccess(action.payload)), put(actionShiftDeleteReset())]);
    showNotification("success", "Eliminato!", "Turno eliminato con successo");
  } catch (e) {
    yield put(actionShiftDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
