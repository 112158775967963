/** @format */

import { useDispatch, useSelector } from "react-redux";
import { actionPermissionListRequest } from "../actions/actionsPermission";
import { PermissionAction, PermissionModel, TPermission } from "../declarations/maps/permissions";
import { selectorPermissionList } from "../selectors/selectorsPermissions";
import { selectorPermissionListLoading } from "../selectors/selectorsUI";

interface usePermissionReturn {
  checkPermission: (model: PermissionModel, action: PermissionAction) => boolean;
  permissionsByModel: (model: PermissionModel) => TPermission[];
  permissionsByAction: (action: PermissionAction) => TPermission[];
  updatePermissions: () => void;
  isPermissionLoading: boolean;
}

export const usePermission = (): usePermissionReturn => {
  const permissionList = useSelector(selectorPermissionList);
  const permissionLoading = useSelector(selectorPermissionListLoading);
  const dispatch = useDispatch();

  const updatePermissions = () => {
    dispatch(actionPermissionListRequest({}));
  };

  return {
    checkPermission: (model: PermissionModel, action: PermissionAction) =>
      !!permissionList.find(el => el.action == action && el.model == model),
    permissionsByModel: (model: PermissionModel) => permissionList.filter(e => e.model == model),
    permissionsByAction: (action: PermissionAction) =>
      permissionList.filter(e => e.action == action),
    updatePermissions,
    isPermissionLoading: permissionLoading,
  };
};
