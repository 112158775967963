/** @format */

import { Row, Col, Select, Form, Skeleton } from "antd";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectorDepartmentList } from "../../../../redux-modules/selectors/selectorsDepartments";
import { UICheckbox } from "../../../ui/Checkbox";
import { UIInput } from "../../../ui/Input";
import { UISelect } from "../../../ui/Select";
const { Option } = Select;

interface Props {
  groups: [];
  locations: [];
  groupsLoading: boolean;
  is_pausapranzo_enabled: boolean;
  canManageForfettizzato: boolean;
}

export const ProfessionalInfoTabEdit: FC<Props> = ({
  groups,
  locations,
  groupsLoading,
  is_pausapranzo_enabled,
  canManageForfettizzato,
}: Props): JSX.Element => {
  const departments = useSelector(selectorDepartmentList);
  
  const [initialPausaPranzoEnabled, setInitialPausaPranzoEnabled] = React.useState<boolean>(
    is_pausapranzo_enabled,
  );

  useEffect(() => {
    console.log(initialPausaPranzoEnabled);
  }, [initialPausaPranzoEnabled]);

  return (
    <>
      <Row gutter={[12, 0]}>
        <Col md={22} xl={14}>
          <Row gutter={[16, 16]}>
            <Col span={10}>
              <Form.Item name="office" label="Sede predefinita" extra="La sede dell'utente">
                <UISelect>
                  {locations.map(location => (
                    //@ts-ignore
                    <Option key={location.id} value={location.id}>
                      {
                        //@ts-ignore
                        location.name
                      }
                    </Option>
                  ))}
                </UISelect>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item name="department" label="Reparto" extra="Il reparto dell'utente">
                <UISelect>
                  {departments.map(department => (
                    //@ts-ignore
                    <Option key={department.id} value={department.id}>
                      {
                        //@ts-ignore
                        department.name
                      }
                    </Option>
                  ))}
                </UISelect>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                name="daily_hours"
                label="Ore Giornaliere"
                extra="Le ore giornaliere del dipendente"
              >
                <UIInput type="number" step={"0.01"} max={24.0} min={0} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="contract_weekly_hours"
                label="Ore Settimanali"
                extra="Le ore settimanali del dipendente"
              >
                <UIInput type="number" step={"0.01"} max={168.0} min={0} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="contract_weekly_days"
                label="Giorni Settimanali"
                extra="I giorni lavorativi del dipendente"
              >
                <UIInput type="number" step={"1"} max={7} min={0} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="is_pausapranzo_enabled"
                    label="Calcola pausa pranzo"
                    extra="Calcolare la pausa pranzo sui turni del dipendente"
                    rules={[{ required: false }]}
                    valuePropName="checked"
                  >
                    <UICheckbox onChange={e => setInitialPausaPranzoEnabled(e.target.checked)} />
                  </Form.Item>
                </Col>
                {initialPausaPranzoEnabled && (
                  <Col span={12}>
                    <Form.Item
                      name="pausapranzo_hours"
                      label="Ore Pausa Pranzo"
                      extra="Le ore di pausa pranzo del dipendente"
                    >
                      <UIInput type="number" step={"0.5"} max={24.0} min={0.5} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[12, 10]}>
        <Col md={10}>
          {groupsLoading && (
            <>
              <Skeleton.Input active={true} size={"default"} style={{ width: "200px" }} />
              <br />
              <br />
              <Skeleton.Input active={true} size={"default"} style={{ width: "280px" }} />
            </>
          )}
          {!groupsLoading && (
            <Form.Item
              name="groups"
              label="Gruppo"
              extra="Il gruppo dell'utente"
              rules={[{ required: false, message: "Inserisci il gruppo  dell'utente!" }]}
            >
              <UISelect mode="multiple">
                {groups.map(group => (
                  //@ts-ignore
                  <Option key={group.id} value={group.id}>
                    {
                      //@ts-ignore
                      group.name
                    }
                  </Option>
                ))}
              </UISelect>
            </Form.Item>
          )}
        </Col>
        <Col span={3}>
          <Form.Item
            name="is_single_badge"
            label="Singola Timbratura"
            rules={[{ required: false }]}
            valuePropName="checked"
          >
            <UICheckbox />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            name="is_presence_in_app"
            label="Timbratura in App"
            rules={[{ required: false }]}
            valuePropName="checked"
          >
            <UICheckbox />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            name="is_nfc_enabled"
            label="Timbratura con NFC"
            rules={[{ required: false }]}
            valuePropName="checked"
          >
            <UICheckbox />
          </Form.Item>
        </Col>
        {canManageForfettizzato && (
          <Col span={3}>
            <Form.Item
              name="is_forfettizzato"
              label="Straordinario Forfettizzato"
              rules={[{ required: false }]}
              valuePropName="checked"
            >
              <UICheckbox />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};
