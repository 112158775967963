/** @format */

import {
  AlertOutlined,
  IssuesCloseOutlined,
  LoadingOutlined,
  NotificationOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Avatar, Col, Collapse, DatePicker, Row, Skeleton, Tooltip } from "antd";
import moment from "moment";
import React, { Ref, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../constants/api";
import { TUser } from "../../../redux-modules/declarations/maps/auth";
import { TDepartment } from "../../../redux-modules/declarations/maps/departments";
import { TGroup } from "../../../redux-modules/declarations/maps/groups";
import {
  TDepatmentShiftDetail,
  TGroupShiftDetail,
  TShift,
  TUserShiftDetail,
} from "../../../redux-modules/declarations/maps/shifts";
import { TShiftType } from "../../../redux-modules/declarations/maps/shiftTypes";
import { BaseColor } from "../../../theme/maps/Colors";
import apiClient from "../../../utils/apiClient";
import { getRangeByDate } from "../../../utils/format/rangeBetweenTwoDates";
import { hexToRgb } from "../../../utils/styles";
import { UIText } from "../../ui/Text";
import { UITitle } from "../../ui/Title";
import { AddShiftModal } from "../SideDialogs/AddShift";
import { ShowShiftDrawer } from "../SideDialogs/ShowShift";
import { IndexDay } from "./partials/IndexDay";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { useSelector } from "react-redux";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { useDebouncedInput } from "../../../redux-modules/hooks/useDebouncedInput";
import { UIInput } from "../../ui/Input";
import { selectorShiftListLoading } from "../../../redux-modules/selectors/selectorsUI";
import { THoliday } from "../../view/ViewReportPresences";

const { Panel } = Collapse;

interface Props {
  loading: boolean;
  format: "Ore" | "Minuti";
  activeDate: moment.Moment;
  shiftList: TShift[];
  groupList: TGroup[];
  departmentList: TDepartment[];
  shiftTypeList: TShiftType[];
  userList: TUser[];
  isCounterLoading: boolean;
  userShiftDetails: TUserShiftDetail[];
  groupShiftDetails: TGroupShiftDetail[];
  departmentShiftDetails: TDepatmentShiftDetail[];
  fetchCountersData: () => void;
}

interface CreateArea {
  user_id: string;
  user_name: string;
  date: string;
}

export const ShiftsCalendar = ({
  loading,
  format,
  activeDate,
  shiftList,
  groupList,
  departmentList,
  shiftTypeList,
  userList,
  isCounterLoading,
  userShiftDetails,
  groupShiftDetails,
  departmentShiftDetails,
  fetchCountersData,
}: Props): JSX.Element => {
  const [activeShift, setActiveShift] = useState<TShift | null>(null);
  const [activeCreateArea, setActiveCreateArea] = useState<CreateArea | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [_, setModalLoading] = useState(false);
  const [openedGroups, setOpenedGroups] = useState<string[]>([]);
  const [openedDepartments, setOpenedDepartments] = useState([]);
  const [activeRange, setActiveRange] = useState<moment.Moment[]>();
  const rowRef: Ref<HTMLDivElement> | undefined = useRef(null);
  const permissionList = useSelector(selectorPermissionList);
  const canCreatePlanning = hasPermission(permissionList, "ShiftPlanning", "Create");
  const [currentShiftList, setCurrentShiftList] = useState(shiftList);
  const [currentUserList, setCurrentUserList] = useState(userList);
  const [debouncedQuery, currentQuery, setQuery] = useDebouncedInput("", () => true, false, 600);
  const [isQueryLoading, setIsQueryLoading] = useState(false);
  const canManageSwitch = hasPermission(permissionList, "ShiftPlanning", "Show"); // Show => Canmanageswitch
  const isShiftListLoading = useSelector(selectorShiftListLoading);
  const [holidaysArr, setHolidaysArr] = useState<THoliday[]>([]);

  useEffect(() => {
    console.log(currentUserList);
  }, [currentUserList]);

  useEffect(() => {
    setActiveRange(getRangeByDate(activeDate));
  }, [activeDate]);

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.HOLIDAY_CALENDAR).then(r => setHolidaysArr(r.data));
  }, []);

  const getHolidayByDateAndUser = (date: moment.Moment, user_office: string): boolean => {
    const holiday = holidaysArr.find(
      e => moment(e.date).isSame(date, "day") && e.offices.includes(user_office),
    );
    return !!holiday;
  };

  const getUserByGroupAndDepartment = (groupId: string, departmentId: string): TUser[] => {
    return currentUserList.filter(
      (user: TUser) =>
        user.groups.map(e => e.id).includes(groupId) && user.department.id === departmentId,
    );
  };

  const getShiftsByUserAndDate = (userId: string, date: moment.Moment): TShift[] =>
    currentShiftList.filter(
      (shift: TShift) => shift.user.id === userId && moment(shift.date).isSame(date, "day"),
    );

  const showGroup = (group_id: string): boolean => {
    const groupThereIs = currentUserList.find(e => e.groups.map(e => e.id).includes(group_id));
    if (
      !debouncedQuery ||
      (groupThereIs && departmentList.map(e => e.id).includes(groupThereIs?.department.id))
    )
      return true;
    return false;
  };

  const canShowGroupAndDepartment = (group_id: string, department_id: string): boolean => {
    if (
      currentUserList.find(
        e => e.groups.map(e => e.id).includes(group_id) && e.department.id === department_id,
      )
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (!!debouncedQuery) {
      setCurrentShiftList(
        shiftList.filter((shift: TShift) =>
          (shift.user.name + " " + shift.user.surname)
            .toLowerCase()
            .includes(debouncedQuery.toLowerCase()),
        ),
      );
      setCurrentUserList(
        userList.filter((user: TUser) =>
          (user.name + " " + user.surname).toLowerCase().includes(debouncedQuery.toLowerCase()),
        ),
      );
    } else {
      setCurrentShiftList(shiftList);
      setCurrentUserList(userList);
    }
  }, [shiftList, debouncedQuery, userList]);

  useEffect(() => {
    if (currentQuery != debouncedQuery) {
      setIsQueryLoading(true);
    } else {
      setIsQueryLoading(false);
    }
  }, [currentQuery, debouncedQuery]);

  const handleOpenModal = (shift_id: string, user: string): void => {
    //const presence = getPresenceByIndex(index, user);
    if (shift_id) {
      setShowModal(true);
      setModalLoading(true);
      apiClient
        .get(API_ENDPOINTS.SHIFTS + `/${shift_id}`)
        .then(res => {
          setActiveShift(res.data.data);
          setModalLoading(false);
        })
        .catch(err => {});
    }
  };
  const handleCloseModal = (): void => {
    setShowModal(false);
    setActiveShift(null);
  };

  const handleCloseCreateModal = (): void => {
    setShowCreateModal(false);
    setActiveCreateArea(null);
  };
  useEffect(() => {
    if (activeCreateArea) {
      setShowCreateModal(true);
    }
  }, [activeCreateArea]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={4}></Col>
            <Col span={16} style={{ textAlign: "center" }}>
              <UITitle level={4} style={{ textTransform: "capitalize" }} color={BaseColor}>
                {activeDate.format("MMMM YYYY")}
              </UITitle>
            </Col>
            <Col span={4}></Col>
          </Row>
        </Col>
      </Row>
      <Row className="giorni" style={{marginBottom: "16px"}}>
        <Col span={4}>
          <UIInput
            value={currentQuery}
            onChange={e => setQuery(e.target.value)}
            suffix={isQueryLoading ? <LoadingOutlined /> : null}
            placeholder={"Cerca Dipendente"}
            allowClear
            prefix={<SearchOutlined style={{ marginRight: "5px", color: "#d9d9d9" }} />}
          />
        </Col>
        <Col span={20}>
          <Row >
            {!!activeRange &&
              activeRange.map(day => <IndexDay key={day.format("DD")} day={day} />)}
          </Row>
        </Col>
      </Row>
      <Row>
        <StyledSpan className="container_calenda">
          <StyledCollapse
            //@ts-ignore
            onChange={(e): void => setOpenedGroups(e)}
            activeKey={openedGroups}
            className="calenda"
            bordered={false}
            style={{ borderRadius: "7px", border: "2px solid #e9e7e8" }}
          >
            {groupList.map(
              group =>
                showGroup(group.id) && (
                  <CustomPanel
                    header={
                      <Row className="group-header">
                        <Col
                          span={4}
                          className="group-name"
                          data-side-left
                          style={{
                            fontWeight: 600,
                            fontSize: "17px",
                          }}
                        >
                          {group.name}{" "}
                          <span className="ant-collapse-expand-icon" style={{ fontWeight: "bold" }}>
                            <span
                              role={"img"}
                              className={"anticon anticon-right ant-collapse-arrow buono"}
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="right"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                                style={{
                                  //@ts-ignore
                                  transform: openedGroups.includes(group.id) ? "" : "rotate(90deg)",
                                }}
                              >
                                <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                              </svg>
                            </span>
                          </span>
                        </Col>
                        <Col
                          span={20}
                          data-side-right
                          onClick={(e): void => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <Row data-days-cells>
                            {activeRange &&
                              activeRange.map(c => (
                                <Col
                                  span={3}
                                  key={c.format("DD")}
                                  style={{
                                    background:
                                      "repeating-linear-gradient(-45deg, rgb(240 237 237), rgb(234 231 231) 2px, rgb(229, 229, 247) 3px, rgb(229, 229, 247) 4px)",
                                  }}
                                >
                                  {"\u00A0"}
                                </Col>
                              ))}
                            <Col span={3} style={{ padding: "5px", lineHeight: "1.4" }}>
                              {isCounterLoading ? (
                                <SyncOutlined
                                  spin
                                  style={{ verticalAlign: "bottom", padding: "0px 5px" }}
                                />
                              ) : (
                                <>
                                  <Row>
                                    <Col>
                                      <>
                                        Assegnato:{" "}
                                        <span style={{ fontWeight: 700 }}>
                                          {format === "Ore"
                                            ? groupShiftDetails.find(e => e.group_id === group.id)
                                                ?.ore_assegnate
                                            : groupShiftDetails.find(e => e.group_id === group.id)
                                                ?.minuti_assegnati}
                                        </span>
                                      </>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <>
                                        Da contratto:{" "}
                                        <span style={{ fontWeight: 700 }}>
                                          {format === "Ore"
                                            ? groupShiftDetails.find(e => e.group_id === group.id)
                                                ?.ore_da_contratto
                                            : groupShiftDetails.find(e => e.group_id === group.id)
                                                ?.minuti_da_contratto}
                                        </span>
                                      </>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <>
                                        Delta:{" "}
                                        <span
                                          style={{
                                            fontWeight: 700,
                                            color:
                                              !!departmentShiftDetails.find(
                                                e => e.group_id === group.id,
                                              )?.delta_minuti &&
                                              //@ts-ignore
                                              departmentShiftDetails.find(
                                                e => e.group_id === group.id,
                                              )?.delta_minuti < 0
                                                ? "red"
                                                : "green",
                                          }}
                                        >
                                          {format === "Ore"
                                            ? groupShiftDetails.find(e => e.group_id === group.id)
                                                ?.delta_ore
                                            : departmentShiftDetails.find(
                                                e => e.group_id === group.id,
                                              )?.delta_minuti}
                                        </span>
                                      </>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    }
                    key={group.id}
                  >
                    <StyledCollapse
                      //@ts-ignore
                      onChange={(e): void => setOpenedDepartments(e)}
                      activeKey={openedDepartments}
                      className="calenda"
                      bordered={false}
                      style={{ borderRadius: "7px" }}
                    >
                      {departmentList.map(
                        department =>
                          canShowGroupAndDepartment(group.id, department.id) && (
                            <CustomPanel
                              className="department-collapse"
                              header={
                                <Row className="department-header">
                                  <Col
                                    span={4}
                                    className="department-name"
                                    data-side-left
                                    style={{ fontSize: "17px" }}
                                  >
                                    {department.name}
                                    <span
                                      className="ant-collapse-expand-icon"
                                      style={{ fontWeight: "bold", marginLeft: "5px" }}
                                    >
                                      <span
                                        role={"img"}
                                        className={"anticon anticon-right ant-collapse-arrow buono"}
                                      >
                                        <svg
                                          viewBox="64 64 896 896"
                                          focusable="false"
                                          data-icon="right"
                                          width="1em"
                                          height="1em"
                                          fill="currentColor"
                                          aria-hidden="true"
                                          style={{
                                            //@ts-ignore
                                            transform: openedDepartments.includes(department.id)
                                              ? ""
                                              : "rotate(90deg)",
                                          }}
                                        >
                                          <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </Col>
                                  <Col
                                    span={20}
                                    className="department-users-col"
                                    data-side-right
                                    onClick={(e): void => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Row data-days-cells className="department-users-row">
                                      {activeRange &&
                                        activeRange.map(c => (
                                          <Col
                                            className="department-users-day"
                                            span={3}
                                            key={c.format("DD")}
                                            style={{
                                              background:
                                                "repeating-linear-gradient(-45deg, rgb(240 237 237), rgb(234 231 231) 2px, rgb(229, 229, 247) 3px, rgb(229, 229, 247) 4px)",
                                            }}
                                          >
                                            {"\u00A0"}
                                          </Col>
                                        ))}
                                      <Col
                                        span={3}
                                        className="department-users-day"
                                        style={{ padding: "5px", lineHeight: "1.2" }}
                                      >
                                        {isCounterLoading ? (
                                          <SyncOutlined
                                            spin
                                            style={{ verticalAlign: "bottom", padding: "0px 5px" }}
                                          />
                                        ) : (
                                          <>
                                            <Row>
                                              <Col>
                                                <>
                                                  Assegnato:{" "}
                                                  <span style={{ fontWeight: 700 }}>
                                                    {format === "Ore"
                                                      ? departmentShiftDetails.find(
                                                          e =>
                                                            e.department_id === department.id &&
                                                            e.group_id === group.id,
                                                        )?.ore_assegnate
                                                      : departmentShiftDetails.find(
                                                          e =>
                                                            e.department_id === department.id &&
                                                            e.group_id === group.id,
                                                        )?.minuti_assegnati}
                                                  </span>
                                                </>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <>
                                                  Da contratto:{" "}
                                                  <span style={{ fontWeight: 700 }}>
                                                    {format === "Ore"
                                                      ? departmentShiftDetails.find(
                                                          e =>
                                                            e.department_id === department.id &&
                                                            e.group_id === group.id,
                                                        )?.ore_da_contratto
                                                      : departmentShiftDetails.find(
                                                          e =>
                                                            e.department_id === department.id &&
                                                            e.group_id === group.id,
                                                        )?.minuti_da_contratto}
                                                  </span>
                                                </>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <>
                                                  Delta:{" "}
                                                  <span
                                                    style={{
                                                      fontWeight: 700,
                                                      color:
                                                        !!departmentShiftDetails.find(
                                                          e =>
                                                            e.department_id === department.id &&
                                                            e.group_id === group.id,
                                                        )?.delta_minuti &&
                                                        //@ts-ignore
                                                        departmentShiftDetails.find(
                                                          e =>
                                                            e.department_id === department.id &&
                                                            e.group_id === group.id,
                                                        )?.delta_minuti < 0
                                                          ? "red"
                                                          : "green",
                                                    }}
                                                  >
                                                    {format === "Ore"
                                                      ? departmentShiftDetails.find(
                                                          e =>
                                                            e.department_id === department.id &&
                                                            e.group_id === group.id,
                                                        )?.delta_ore
                                                      : departmentShiftDetails.find(
                                                          e =>
                                                            e.department_id === department.id &&
                                                            e.group_id === group.id,
                                                        )?.delta_minuti}
                                                  </span>
                                                </>
                                              </Col>
                                            </Row>
                                          </>
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              }
                              id={group.id + "_" + department.id}
                              key={group.id + "_" + department.id}
                            >
                              {getUserByGroupAndDepartment(group.id, department.id).map(user => (
                                <Row key={user.id}>
                                  <Col
                                    span={4}
                                    key={user.id}
                                    data-side-left
                                    data-user
                                    style={{
                                      borderRight: "1px solid #bcbcbc",
                                      display: "flex",
                                      alignItems: "center",
                                      borderTop: "1px solid #bcbcbc",
                                    }}
                                  >
                                    <Avatar
                                      src={user.avatar}
                                      style={{ marginRight: "10px", width: "20px", height: "20px" }}
                                    />
                                    <UIText style={{ fontWeight: 600, fontSize: "15px" }}>
                                      {user.surname} {user.name}
                                    </UIText>
                                    {!!user.is_single_badge && (
                                      <Tooltip
                                        title={
                                          "Quest'utente ha attivata l'opzione di Singola Timbratura"
                                        }
                                      >
                                        <IssuesCloseOutlined style={{ marginLeft: "5px" }} />
                                      </Tooltip>
                                    )}
                                  </Col>
                                  <Col
                                    span={20}
                                    data-side-right
                                    data-user
                                    style={{ borderTop: "1px solid #bcbcbc" }}
                                  >
                                    <Row data-days-cells ref={rowRef}>
                                      <>
                                        {activeRange &&
                                          activeRange.map(c => (
                                            <Col
                                              span={3}
                                              className="user-day"
                                              key={moment(c).format("DD")}
                                              style={{
                                                borderRight: "1px solid #bcbcbc",
                                                backgroundColor:
                                                userShiftDetails.find(
                                                  e => e.user_id === user.id,
                                                )?.holidays.find(date => date === moment(c).format("YYYY-MM-DD")) !== undefined ? "#efadadb3" 
                                                    : moment(c).format("DD/MM/YYYY") ===
                                                      moment().format("DD/MM/YYYY")
                                                    ? "#fffee5b3"
                                                    : "#fff",
                                              }}
                                            >
                                              {loading || isShiftListLoading ? (
                                                <span
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    textAlign: "center",
                                                    display: "flex",
                                                    flexFlow: "column",
                                                  }}
                                                >
                                                  <Skeleton.Input
                                                    active
                                                    size="small"
                                                    className="paddingati"
                                                  />
                                                  <Skeleton.Input
                                                    active
                                                    size="small"
                                                    className="paddingati"
                                                  />
                                                </span>
                                              ) : (
                                                <>
                                                  <span className="sfondo-giorno">
                                                    {moment(c).format("ddd DD")}
                                                  </span>
                                                  {!!getShiftsByUserAndDate(user.id, moment(c))
                                                    .length ? (
                                                    <>
                                                      {getShiftsByUserAndDate(
                                                        user.id,
                                                        moment(c),
                                                      ).map(e => (
                                                        <Row justify="center" key={e.id}>
                                                          <Col
                                                            span={20}
                                                            className={
                                                              !e.is_absence
                                                                ? "shift-item"
                                                                : "absence-item"
                                                            }
                                                            style={{
                                                              backgroundColor: !!e.is_absence
                                                                ? e.shift_type.color
                                                                : "#e5e5f7",
                                                            }}
                                                            onClick={
                                                              !e.is_absence
                                                                ? (): void =>
                                                                    handleOpenModal(e.id, e.user.id)
                                                                : undefined
                                                            }
                                                          >
                                                            <Row>
                                                              <Col span={24}>
                                                                {e.shift_type.name}
                                                                {!!e.pending_switch &&
                                                                  canManageSwitch && (
                                                                    <NotificationOutlined
                                                                      style={{
                                                                        marginLeft: "8px",
                                                                        fontSize: "14px",
                                                                        color: "red",
                                                                      }}
                                                                    />
                                                                  )}
                                                              </Col>
                                                            </Row>
                                                            {e.show_office && (
                                                              <Row>
                                                                <Col span={24}>
                                                                  <small>{e.office?.name}</small>
                                                                </Col>
                                                              </Row>
                                                            )}
                                                          </Col>
                                                        </Row>
                                                      ))}
                                                      {canCreatePlanning ? (
                                                        <Row justify="center">
                                                          <Col
                                                            span={20}
                                                            style={{ textAlign: "center" }}
                                                          >
                                                            <PlusCircleOutlined
                                                              onClick={(): void =>
                                                                setActiveCreateArea({
                                                                  user_id: user.id,
                                                                  user_name:
                                                                    user.name + " " + user.surname,
                                                                  date: moment(c).format(
                                                                    "YYYY-MM-DD",
                                                                  ),
                                                                })
                                                              }
                                                              className="add-shift-icon"
                                                              style={{
                                                                fontSize: "22px",
                                                                cursor: "pointer",
                                                                color: BaseColor,
                                                                margin: "14px",
                                                                opacity: 0.2,
                                                              }}
                                                            />
                                                          </Col>
                                                        </Row>
                                                      ) : (
                                                        <span style={{ marginBottom: "15px" }}>
                                                          {"\u00A0"}
                                                        </span>
                                                      )}
                                                    </>
                                                  ) : canCreatePlanning ? (
                                                    <Row
                                                      justify="center"
                                                      style={{ height: "100%" }}
                                                    >
                                                      <Col
                                                        span={20}
                                                        style={{
                                                          textAlign: "center",
                                                          alignSelf: "center",
                                                        }}
                                                      >
                                                        <PlusCircleOutlined
                                                          onClick={(): void =>
                                                            setActiveCreateArea({
                                                              user_id: user.id,
                                                              user_name:
                                                                user.name + " " + user.surname,
                                                              date: moment(c).format("YYYY-MM-DD"),
                                                            })
                                                          }
                                                          className="add-shift-icon"
                                                          style={{
                                                            fontSize: "22px",
                                                            cursor: "pointer",
                                                            color: BaseColor,
                                                            margin: "12px",
                                                            opacity: 0.2,
                                                          }}
                                                        />
                                                      </Col>
                                                    </Row>
                                                  ) : (
                                                    <span style={{ marginBottom: "15px" }}>
                                                      {"\u00A0"}
                                                    </span>
                                                  )}
                                                </>
                                              )}
                                            </Col>
                                          ))}
                                      </>
                                      <Col span={3} style={{ padding: "5px", lineHeight: "1.5" }}>
                                        {isCounterLoading ? (
                                          <SyncOutlined
                                            spin
                                            style={{ verticalAlign: "bottom", padding: "0px 5px" }}
                                          />
                                        ) : (
                                          <>
                                            <Row>
                                              <Col>
                                                <>
                                                  Assegnato:{" "}
                                                  <span style={{ fontWeight: 700 }}>
                                                    {format === "Ore"
                                                      ? userShiftDetails.find(
                                                          e => e.user_id === user.id,
                                                        )?.ore_assegnate
                                                      : userShiftDetails.find(
                                                          e => e.user_id === user.id,
                                                        )?.minuti_assegnati}
                                                  </span>
                                                </>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <>
                                                  Da contratto:{" "}
                                                  <span style={{ fontWeight: 700 }}>
                                                    {format === "Ore"
                                                      ? userShiftDetails.find(
                                                          e => e.user_id === user.id,
                                                        )?.ore_da_contratto
                                                      : userShiftDetails.find(
                                                          e => e.user_id === user.id,
                                                        )?.minuti_da_contratto}
                                                  </span>
                                                </>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <>
                                                  Delta:{" "}
                                                  <span
                                                    style={{
                                                      fontWeight: 700,
                                                      color:
                                                        !!userShiftDetails.find(
                                                          e => e.user_id === user.id,
                                                        )?.delta_minuti &&
                                                        //@ts-ignore
                                                        userShiftDetails.find(
                                                          e => e.user_id === user.id,
                                                        )?.delta_minuti < 0
                                                          ? "red"
                                                          : "green",
                                                    }}
                                                  >
                                                    {format === "Ore"
                                                      ? userShiftDetails.find(
                                                          e => e.user_id === user.id,
                                                        )?.delta_ore
                                                      : userShiftDetails.find(
                                                          e => e.user_id === user.id,
                                                        )?.delta_minuti}
                                                  </span>
                                                </>
                                              </Col>
                                            </Row>
                                          </>
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              ))}
                            </CustomPanel>
                          ),
                      )}
                    </StyledCollapse>
                  </CustomPanel>
                ),
            )}
          </StyledCollapse>
        </StyledSpan>
      </Row>
      <ShowShiftDrawer
        visible={showModal}
        modalLoading={false}
        onClose={handleCloseModal}
        shift={activeShift || undefined}
      />
      {!!activeCreateArea && (
        <AddShiftModal
          closeModal={handleCloseCreateModal}
          reloadData={fetchCountersData}
          isModalVisible={showCreateModal}
          user_id={activeCreateArea.user_id}
          user_name={activeCreateArea.user_name}
          date={activeCreateArea.date}
        />
      )}
    </>
  );
};

const StyledSpan = styled.span`
  overflow: scroll;
  width: 100%;
  height: 55vh;
  padding-bottom: 15px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 9px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  .calenda {
    min-width: 1205px;
  }
  .giorni {
    min-width: 1205px;
    margin-bottom: 10px;
  }
  .giorni .ant-cascader .ant-select-selector {
    border-radius: 7px;
  }

  .pipallino {
    text-align: left !important;
  }

  .shift-item {
    background-color: #e5e5f7;
    border-radius: 7px;
    margin: 6px 0px 0px 0px;
    padding: 3px 0px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }


  .absence-item {
    border-radius: 7px;
    margin: 6px 0px 0px 0px;
    padding: 3px 0px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    cursor: default;
  }

  .add-shift-icon {
    transition: opacity 0.4s ease-in-out;
  }
  .add-shift-icon:hover {
    opacity: 1 !important;
  }
  .sfondo-giorno {
    position: absolute;
    bottom: -3px;
    font-size: 18px;
    opacity: 0;
    left: 2px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    cursor: default;
    transition: opacity 0.2s ease-in-out;
  }

  .user-day:hover .sfondo-giorno {
    opacity: 0.2;
  }

  .ant-collapse-borderless {
    background-color: white;
  }
  .paddingati {
    padding: 10px 10px 0 10px;
    align-self
  }
`;

// mettere in file singolo
const StyledCollapse = styled(Collapse)`
  border-radius: 7px;
  position: relative !important;

  .ant-collapse-header {
    display: block !important;
  }
  .ant-collapse:first-child {
    border-radius: 7px;
  }
  .ant-collapse-item:last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  /*
  .ant-collapse-item:last-of-type [data-side-left]:not([data-user]) {
    border-bottom-left-radius: 7px;
  }
*/
  /*
  .ant-collapse-item:first-of-type {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }*/

  /*
  .ant-collapse-item [data-side-right]:not([data-user]) [data-days-cells] {
    border-bottom-right-radius: 7px;
  }
*/
  .ant-collapse-item:first-of-type
    [data-side-right]:not([data-user])
    [data-days-cells]
    .ant-col:last-of-type {
    border-top-right-radius: 7px;
  }

  .ant-collapse-item:last-of-type {
    border-bottom-right-radius: 7px;
  }
  .ant-collapse .ant-col-1:last-of-type {
    border-bottom-right-radius: 7px;
  }
  .ant-collapse-item:first-of-type [data-side-left]:not([data-user]):not(.department-name) {
    border-top-left-radius: 7px;
  }

  .ant-collapse-item:last-of-type .ant-row:last-child .ant-col:last-of-type {
    border-bottom-left-radius: 7px;
  }
  .ant-collapse-header[aria-expanded="true"] [data-side-left] {
    border-bottom-left-radius: 0px !important;
  }
  .department-name {
    padding-left: 30px !important;
  }
  /*
  .ant-collapse-item .ant-row[data-days-cells]:not(.department-users-row) {
    border-bottom-right-radius: 7px;
  }*/
  .ant-collapse-item-active .ant-row[data-days-cells]:not(.department-users-row) {
    border-bottom-right-radius: 0px;
  }
  /*
  .department-collapse .department-users-row {
    border-bottom-right-radius: 7px !important;
  }*/
  .ant-collapse-item-active.department-collapse .department-users-row {
    border-bottom-right-radius: 0px !important;
  }
`;
// mettere in file singolo
const CustomPanel = styled(Panel)`
  .ant-collapse-header {
    padding: 0px !important;
  }

  .ant-row[data-days-cells] {
    height: 100% !important;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }
  .ant-collapse-arrow:not(.buono) {
    display: none !important;
  }

  .group-name {
    padding: 12px 10px;
    place-self: center;
  }
  .group-header {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.25"}) !important;
  }
  /*
  .group-header:first-of-type {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }*/
  [data-side-left] {
    padding: 12px 10px;
  }
  [data-side-left][data-user] {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.20"}) !important;
  }
  .department-header {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.30"}) !important;
  }
  .department-name {
    place-self: center;
  }
  [data-side-right]:not([data-user]) {
    //pointer-events: none !important;
    cursor: default;
    touch-action: none;
  }

  .ant-col .request-cell {
    border: 0px !important;
    border-right: 1px solid #bcbcbc;

    background-size: 10px 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-weight: bold;
    text-transform: capitalize;

    height: 70%;
    margin-top: 7px;
  }

  .department-collapse {
    border-top-left-radius: 0px !important;
  }
  .department-users-row {
    border-top-right-radius: 0px !important;
  }
`;
