/** @format */

import { Col, Divider, Form, Row, Select, Skeleton, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TInputType, TModel } from "../../../../../constants/maps/BackendEnums";
import {
  actionModelDetailCreateRequest,
  actionModelDetailDeleteRequest,
  actionModelDetailListRequest,
  actionModelDetailUpdateRequest,
} from "../../../../../redux-modules/actions/actionsModelDetail";
import { TStore } from "../../../../../redux-modules/declarations/store";
import { useDebounce } from "../../../../../redux-modules/hooks/useDebounce";
import { selectorJustificativeList } from "../../../../../redux-modules/selectors/selectorsJustificatives";
import { selectorModelDetailsByModel } from "../../../../../redux-modules/selectors/selectorsModelDetails";
import { selectorPresenceTypeList } from "../../../../../redux-modules/selectors/selectorsPresenceTypes";
import {
  selectorModelDetailLoading,
  selectorModelDetailUpdated,
} from "../../../../../redux-modules/selectors/selectorsUI";
import { BaseColor, Colors } from "../../../../../theme/maps/Colors";
import { UIButton } from "../../../../ui/Button";
import { UICard } from "../../../../ui/Card";
import { UICheckbox } from "../../../../ui/Checkbox";
import { UIInput } from "../../../../ui/Input";
import { UISelect } from "../../../../ui/Select";
import { UIText } from "../../../../ui/Text";
import { UITitle } from "../../../../ui/Title";

interface Props {
  model: TModel;
}

export const DynamicModelTab = ({ model }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [needToShowModels, setNeedToShowModels] = useState(false);
  const [canShowModelsSelect, setCanShowModelsSelect] = useState(false);
  const [lastUpdatedObject, setLastUpdatedObject] = useState("");
  const [form] = useForm();
  const [formCreate] = useForm();
  const modelDetails = useSelector((store: TStore) => selectorModelDetailsByModel(store, model));
  const modelDetailsColumns = Object.values(TInputType);
  const justificatives = useSelector(selectorJustificativeList);
  const presenceTypes = useSelector(selectorPresenceTypeList);
  const modelDetailLoading = useSelector(selectorModelDetailLoading);
  const modelDetailUpdated = useSelector(selectorModelDetailUpdated);
  //@ts-ignore
  const handleCreate = e => {
    const objCreate = {
      ...e,
      model: model,
    };
    dispatch(actionModelDetailCreateRequest(objCreate));
    formCreate.resetFields();
  };
  //@ts-ignore
  const onChange = valueChanges => {
    const modelDetailId = Object.keys(valueChanges)[0].split("?")[1];
    const fieldName = form.getFieldValue("field_name?" + modelDetailId);
    const modelId = form.getFieldValue("model_id?" + modelDetailId);
    const columnTypeName = form.getFieldValue("column_type_name?" + modelDetailId);
    const is_required = form.getFieldValue("is_required?" + modelDetailId);
    const is_visible = form.getFieldValue("is_visible?" + modelDetailId);
    const is_export_code = form.getFieldValue("is_export_code?" + modelDetailId);
    const defaultV = form.getFieldValue("default?" + modelDetailId);
    const updateObject = {
      id: modelDetailId,
      model: model,
      model_id: modelId,
      field_name: fieldName,
      column_type_name: columnTypeName,
      is_required: is_required,
      is_visible: is_visible,
      is_export_code: is_export_code,
      default: defaultV,
    };
    setLastUpdatedObject(JSON.stringify(updateObject));
    //dispatch(actionModelDetailUpdateRequest(updateObject));
  };

  const onDelete = (id: string) => {
    dispatch(actionModelDetailDeleteRequest(id));
  };
  const debouncedObject = useDebounce(lastUpdatedObject, 700);

  useEffect(() => {
    if (debouncedObject) {
      //@ts-ignore
      dispatch(actionModelDetailUpdateRequest(JSON.parse(debouncedObject)));
    }
  }, [debouncedObject]);
  // Realizzo i valori iniziali
  const modelDetailsForFormArray = modelDetails.map(md => ({
    ["field_name?" + md.id]: md.field_name,
    ["column_type_name?" + md.id]: md.column_type_name,
    ["model_id?" + md.id]: md.model_id,
    ["is_required?" + md.id]: md.is_required,
    ["is_visible?" + md.id]: md.is_visible,
    ["is_export_code?" + md.id]: md.is_export_code,
    ["default?" + md.id]: md.default,
  }));
  let nuovoOggetto = {};
  modelDetailsForFormArray.forEach(md => {
    for (const key in md) {
      if (Object.prototype.hasOwnProperty.call(md, key)) {
        //@ts-ignore
        nuovoOggetto[key] = md[key];
      }
    }
  });

  useEffect(() => {
    setNeedToShowModels(model === "JustificativeType");
    setCanShowModelsSelect(
      (model === "JustificativeType" || model === "PresenceType") && !!justificatives.data.length,
    );
  }, []);

  useEffect(() => {
    if (modelDetailUpdated) {
      dispatch(actionModelDetailListRequest());
    }
  }, [modelDetailUpdated]);

  if (modelDetailLoading)
    return (
      <UICard style={{ marginBottom: "20px" }}>
        <Skeleton active />
      </UICard>
    );
  return (
    <>
      {!modelDetailLoading && (
        <Form form={form} initialValues={nuovoOggetto} onValuesChange={onChange}>
          <Row>
            <Col span={24}>
              {modelDetails.map(modelDetail => (
                <Fragment key={modelDetail.id}>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Row gutter={[12, 12]}>
                        <Col span={10}>
                          <UIText strong>Titolo</UIText>
                        </Col>
                        <Col span={4}>
                          <UIText strong>Required</UIText>
                        </Col>

                        <Col span={4}>
                          <UIText strong>Visible</UIText>
                        </Col>

                        <Col span={4}>
                          <UIText strong>Export Code</UIText>
                        </Col>
                      </Row>
                      <Row gutter={[12, 12]}>
                        <Col span={10}>
                          <Form.Item name={"field_name?" + modelDetail.id}>
                            <UIInput placeholder="Titolo" value={modelDetail.field_name} />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item valuePropName="checked" name={"is_required?" + modelDetail.id}>
                            <UICheckbox />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item valuePropName="checked" name={"is_visible?" + modelDetail.id}>
                            <UICheckbox />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            valuePropName="checked"
                            name={"is_export_code?" + modelDetail.id}
                          >
                            <UICheckbox />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <UIButton
                            bgColor={"red"}
                            color={Colors.primary}
                            hoverBgColor={"red"}
                            onClick={() => onDelete(modelDetail.id)}
                          >
                            -
                          </UIButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Row gutter={[12, 12]}>
                        <Col span={24}>
                          <UIText strong>Tipologia</UIText>
                        </Col>
                      </Row>
                      <Row gutter={[12, 12]}>
                        <Col span={20}>
                          <Form.Item name={"column_type_name?" + modelDetail.id}>
                            <UISelect>
                              {modelDetailsColumns.map(mdc => (
                                <Select.Option value={mdc}>{mdc}</Select.Option>
                              ))}
                            </UISelect>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {canShowModelsSelect && (
                    <Row gutter={[12, 12]}>
                      <Col span={12}>
                        <Row gutter={[12, 12]}>
                          <Col span={24}>
                            <UIText strong>Modello</UIText>
                          </Col>
                        </Row>
                        <Row gutter={[12, 12]}>
                          <Col span={20}>
                            <Form.Item name={"model_id?" + modelDetail.id}>
                              <UISelect>
                                {model === "JustificativeType" &&
                                  justificatives.data.map(justificative => (
                                    <Select.Option value={`${justificative.id}`}>
                                      {justificative.name}
                                    </Select.Option>
                                  ))}
                                {model === "PresenceType" &&
                                  presenceTypes.map(presence => (
                                    <Select.Option value={`${presence.id}`}>
                                      {presence.name}
                                    </Select.Option>
                                  ))}
                              </UISelect>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Form.Item name={"default?" + modelDetail.id}>
                        <UIInput placeholder="00000" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[12, 12]}>
                    <Divider />
                  </Row>
                </Fragment>
              ))}
            </Col>
          </Row>
        </Form>
      )}
      <Row>
        <Col span={24}>
          <UITitle level={4}>Nuovo campo aggiuntivo</UITitle>
        </Col>
      </Row>
      <Form form={formCreate} onFinish={handleCreate}>
        <Row>
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Row gutter={[12, 12]}>
                  <Col span={10}>
                    <UIText strong>Titolo</UIText>
                  </Col>
                  <Col span={4}>
                    <UIText strong>Required</UIText>
                  </Col>

                  <Col span={4}>
                    <UIText strong>Visible</UIText>
                  </Col>

                  <Col span={4}>
                    <UIText strong>Export Code</UIText>
                  </Col>
                </Row>
                <Row gutter={[12, 12]}>
                  <Col span={10}>
                    <Form.Item name="field_name">
                      <UIInput placeholder="Titolo" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item valuePropName="checked" name={"is_required"}>
                      <UICheckbox />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item valuePropName="checked" name={"is_visible"}>
                      <UICheckbox />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item valuePropName="checked" name={"is_export_code"}>
                      <UICheckbox />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Row gutter={[12, 12]}>
                  <Col span={24}>
                    <UIText strong>Tipologia</UIText>
                  </Col>
                </Row>
                <Row gutter={[12, 12]}>
                  <Col span={12}>
                    <Form.Item name="column_type_name">
                      <UISelect style={{ width: "100%" }}>
                        {modelDetailsColumns.map(mdc => (
                          <Select.Option value={mdc}>{mdc}</Select.Option>
                        ))}
                      </UISelect>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            {needToShowModels && (
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Row gutter={[12, 12]}>
                    <Col span={24}>
                      <UIText strong>Modello</UIText>
                    </Col>
                  </Row>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Form.Item name="model_id">
                        <UISelect style={{ width: "100%" }}>
                          {justificatives.data.map(justificative => (
                            <Select.Option value={`${justificative.id}`}>
                              {justificative.name}
                            </Select.Option>
                          ))}
                        </UISelect>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item>
                  <UIButton
                    htmlType="submit"
                    bgColor={BaseColor}
                    color={Colors.primary}
                    hoverBgColor={Colors.darkBlue}
                  >
                    Aggiungi
                  </UIButton>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};
