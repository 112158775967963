/** @format */

import { useSelector } from "react-redux";
import { matchPath } from "react-router";
import {
  PermissionAction,
  PermissionModel,
} from "../../redux-modules/declarations/maps/permissions";
import { selectorLoggedUser } from "../../redux-modules/selectors/selectorsAuth";
import { selectorPermissionList } from "../../redux-modules/selectors/selectorsPermissions";
import { Routes } from "../Routes";

export const usePermissionForRoute = (route: Routes): boolean => {
  const permissionList = useSelector(selectorPermissionList);
  const loggedUser = useSelector(selectorLoggedUser);

  const hasPermission = (model: PermissionModel, permission: PermissionAction): boolean =>
    !!permissionList.find(el => el.action == permission && el.model == model);

  if (utilityPathMatchRoute(route, Routes.home)) return true;
  if (utilityPathMatchRoute(route, Routes.users)) return hasPermission("User", "Update");
  if (utilityPathMatchRoute(route, Routes.usersNew)) return hasPermission("User", "Create");
  if (utilityPathMatchRoute(route, Routes.usersEdit)) {
    return hasPermission("User", "Profile");
  }
  if (utilityPathMatchRoute(route, Routes.usersEditProfessionalInfo))
    return hasPermission("User", "Profile");
  if (utilityPathMatchRoute(route, Routes.usersEditAggiuntiveInfo))
    return hasPermission("User", "Profile");
  if (utilityPathMatchRoute(route, Routes.usersEditAssignments))
    return hasPermission("Product", "Personalproducts");
  if (utilityPathMatchRoute(route, Routes.usersEditCommunications))
    return hasPermission("Communication", "Personalcommunications");
  if (utilityPathMatchRoute(route, Routes.groups)) return hasPermission("Group", "Show");
  if (utilityPathMatchRoute(route, Routes.groupsEdit)) return hasPermission("Group", "Update");
  if (utilityPathMatchRoute(route, Routes.groupsNew)) return hasPermission("Group", "Create");
  if (utilityPathMatchRoute(route, Routes.devices)) return hasPermission("Device", "Show");
  if (utilityPathMatchRoute(route, Routes.devicesEdit)) return hasPermission("Device", "Update");
  if (utilityPathMatchRoute(route, Routes.devicesCreate)) return hasPermission("Device", "Create");
  if (utilityPathMatchRoute(route, Routes.locations)) return hasPermission("Office", "Show");
  if (utilityPathMatchRoute(route, Routes.locationsNew)) return hasPermission("Office", "Create");
  if (utilityPathMatchRoute(route, Routes.locationsEdit)) return hasPermission("Office", "Update");
  if (utilityPathMatchRoute(route, Routes.departments)) return hasPermission("Department", "Show");
  if (utilityPathMatchRoute(route, Routes.departmentsNew))
    return hasPermission("Department", "Create");
  if (utilityPathMatchRoute(route, Routes.departmentsEdit))
    return hasPermission("Department", "Update");
  if (utilityPathMatchRoute(route, Routes.settings)) return hasPermission("Setting", "Show");
  if (utilityPathMatchRoute(route, Routes.settingsDocumentsType))
    return hasPermission("DocumentType", "Show");
  if (utilityPathMatchRoute(route, Routes.settingsDocumentsTypeEdit))
    return hasPermission("DocumentType", "Update");
  if (utilityPathMatchRoute(route, Routes.settingsRequestType))
    return hasPermission("JustificativeType", "Show");
  if (utilityPathMatchRoute(route, Routes.settingsModelDetails))
    return hasPermission("ModelDetail", "Show");
  if (utilityPathMatchRoute(route, Routes.settingsTriggers))
    return hasPermission("Setting", "Update");
  if (utilityPathMatchRoute(route, Routes.settingsPresenceTypes))
    return hasPermission("PresenceType", "Update");
  if (utilityPathMatchRoute(route, Routes.settingsPresenceTypesEdit))
    return hasPermission("PresenceType", "Update");
  if (utilityPathMatchRoute(route, Routes.settingsPresenceTypesNew))
    return hasPermission("PresenceType", "Create");
  if (utilityPathMatchRoute(route, Routes.presences)) return hasPermission("Presence", "Show");
  if (utilityPathMatchRoute(route, Routes.presencesReport)) return hasPermission("Report", "Show");
  if (utilityPathMatchRoute(route, Routes.shiftTypes)) return hasPermission("ShiftType", "Show");
  if (utilityPathMatchRoute(route, Routes.shifts)) return hasPermission("ShiftPlanning", "Show");
  if (utilityPathMatchRoute(route, Routes.documents)) return hasPermission("Document", "Show");
  if (utilityPathMatchRoute(route, Routes.documentsNew)) return hasPermission("Document", "Create");
  if (utilityPathMatchRoute(route, Routes.documentsManagement))
    return hasPermission("Document", "Update");
  if (utilityPathMatchRoute(route, Routes.usersEditDocuments))
    return hasPermission("PersonalDocument", "Show");
  if (utilityPathMatchRoute(route, Routes.products)) return hasPermission("Product", "Show");
  if (utilityPathMatchRoute(route, Routes.productsEdit)) return hasPermission("Product", "Update");
  if (utilityPathMatchRoute(route, Routes.productsNew)) return hasPermission("Product", "Create");
  if (utilityPathMatchRoute(route, Routes.warehouses)) return hasPermission("Warehouse", "Show");
  if (utilityPathMatchRoute(route, Routes.warehousesEdit))
    return hasPermission("Warehouse", "Update");
  if (utilityPathMatchRoute(route, Routes.warehousesAssigned))
    return hasPermission("Warehouse", "Update");
  if (utilityPathMatchRoute(route, Routes.warehousesAvailable))
    return hasPermission("Warehouse", "Update");
  if (utilityPathMatchRoute(route, Routes.warehousesNew)) return hasPermission("Warehouse", "Show");
  if (utilityPathMatchRoute(route, Routes.comunications))
    return hasPermission("Communication", "Show");
  if (utilityPathMatchRoute(route, Routes.comunicationsNew))
    return hasPermission("Communication", "Create");
  if (utilityPathMatchRoute(route, Routes.communicationsView))
    return hasPermission("Communication", "Show");
  if (utilityPathMatchRoute(route, Routes.fleets)) return hasPermission("Fleet", "Show");
  if (utilityPathMatchRoute(route, Routes.fleetsNew)) return hasPermission("Fleet", "Create");
  if (utilityPathMatchRoute(route, Routes.fleetsEdit)) return hasPermission("Fleet", "Update");
  if (utilityPathMatchRoute(route, Routes.reservations))
    return hasPermission("Reservation", "Show");
  if (utilityPathMatchRoute(route, Routes.reservationsNew))
    return hasPermission("Reservation", "Create");
  if (utilityPathMatchRoute(route, Routes.reservationsEdit))
    return hasPermission("Reservation", "Update");
  if (utilityPathMatchRoute(route, Routes.travelsAndExpenses))
    return hasPermission("Travel", "Show");
  if (utilityPathMatchRoute(route, Routes.travelsAndExpensesNew))
    return hasPermission("Travel", "Create");
  if (utilityPathMatchRoute(route, Routes.travelsAndExpensesEdit))
    return hasPermission("Travel", "Update");
  if (utilityPathMatchRoute(route, Routes.requests)) return true;
  if (utilityPathMatchRoute(route, Routes.requestNew)) return true;
  if (utilityPathMatchRoute(route, Routes.requestTypeNew)) return true;
  return false;
};

export const utilityPathMatchRoute = (
  path: string,
  route: Routes,
  exact: boolean = true,
): Boolean => {
  return (
    matchPath(path, {
      path: route,
      exact: exact,
      strict: false,
    })?.isExact || false
  );
};
