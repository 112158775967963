/** @format */

import { G2, Heatmap, HeatmapConfig } from "@ant-design/plots";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { API_ENDPOINTS } from "../../../../constants/api";
import apiClient from "../../../../utils/apiClient";

interface Props {
  date: moment.Moment | null;
}
export const ShiftStatistics = ({ date }: Props): JSX.Element => {
  const [shiftData, setShiftData] = useState<HeatmapConfig>();
  const [shiftLoading, setShiftLoading] = useState(false);

  useEffect(() => {
    setShiftLoading(true);
    apiClient
      .get(
        API_ENDPOINTS.ANALITICHE_TURNI.replace(
          ":date",
          date ? date.format("YYYY-MM-01") : moment().format("YYYY-MM-01"),
        ),
      )
      .then(res => {
        const config: HeatmapConfig = {
          // @ts-ignore
          data: res.data.map(e => ({
            ...e,
            day: parseInt(moment(e.date, "YYYY-MM-DD").subtract(1, "days").format("d")),
            week:
              moment(e.date, "YYYY-MM-DD").week() -
              moment(e.date, "YYYY-MM-DD").startOf("month").week(),
            date: moment(e.date).format("DD/MM/YYYY"),
            month: 1,
          })),
          height: 400,
          autoFit: false,
          xField: "week",
          yField: "day",
          colorField: "turni",
          reflect: "y",
          shape: "boundary-polygon",
          meta: {
            day: {
              type: "cat",
              values: ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"],
            },
            week: {
              type: "cat",
            },
            turni: {
              sync: true,
            },
            date: {
              type: "cat",
            },
          },
          yAxis: {
            grid: null,
          },
          tooltip: {
            title: "date",
            showMarkers: false,
            formatter: (datum: unknown) => {
              return {
                name: "Turni",
                // @ts-ignore
                value: datum.turni,
              };
            },
          },
          interactions: [
            {
              type: "element-active",
            },
          ],
          xAxis: {
            position: "top",
            tickLine: null,
            line: null,
            label: {
              offset: 12,
              style: {
                fontSize: 12,
                fill: "#666",
                textBaseline: "top",
              },
              formatter: val => {
                return "";
              },
            },
          },
        };
        setShiftData(config as HeatmapConfig);
        setShiftLoading(false);
      });
  }, [date]);

  G2.registerShape("polygon", "boundary-polygon", {
    draw(cfg, container) {
      const group = container.addGroup();
      const attrs = {
        stroke: "#fff",
        lineWidth: 1,
        fill: cfg.color,
        paht: [],
      };
      const points = cfg.points;
      const path = [
        // @ts-ignore
        ["M", points[0].x, points[0].y],
        // @ts-ignore
        ["L", points[1].x, points[1].y],
        // @ts-ignore
        ["L", points[2].x, points[2].y],
        // @ts-ignore
        ["L", points[3].x, points[3].y],
        ["Z"],
      ]; // @ts-ignore

      attrs.path = this.parsePath(path);
      group.addShape("path", {
        attrs,
      });

      // @ts-ignore
      if (cfg.data.lastWeek) {
        const linePath = [
          // @ts-ignore
          ["M", points[2].x, points[2].y],
          // @ts-ignore
          ["L", points[3].x, points[3].y],
        ];

        group.addShape("path", {
          attrs: {
            // @ts-ignore
            path: this.parsePath(linePath),
            lineWidth: 4,
            stroke: "#404040",
          },
        });

        // @ts-ignore
        if (cfg.data.lastDay) {
          group.addShape("path", {
            attrs: {
              // @ts-ignore
              path: this.parsePath([
                // @ts-ignore
                ["M", points[1].x, points[1].y],
                // @ts-ignore
                ["L", points[2].x, points[2].y],
              ]),
              lineWidth: 4,
              stroke: "#404040",
            },
          });
        }
      }

      return group;
    },
  });

  return <>{shiftData && <Heatmap {...shiftData} />}</>;
};
