/** @format */

import { createBrowserHistory } from "history";
import { Store, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { utilityGetFromLocalStorage } from "../utils/cache/getFromLocalStorage";
import { reducers } from "./reducers";
import { createMiddlewares } from "./middlewares";
import { middlwareSaga } from "./middlewares/middlewareSaga";
import { watchers } from "./watchers";
import { KEY_APP_NAME } from "../constants/general";
import { TStore } from "./declarations/store";

export const history = createBrowserHistory();

export const configureStore = (): Store => {
  const prevStore: TStore = utilityGetFromLocalStorage(KEY_APP_NAME);
  const enhancer = composeWithDevTools({ trace: true, traceLimit: 25 });
  const store = createStore(
    reducers(history),
    prevStore,
    enhancer(applyMiddleware(...createMiddlewares(history))),
  );

  //@ts-ignore
  watchers.forEach(watcher => middlwareSaga.run(watcher));

  return store;
};
