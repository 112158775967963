/** @format */

import { History } from "history";
import { combineReducers } from "redux";
import { reducerAuth } from "./reducerAuth";
import { reducerUI } from "./reducerUI";
import { reducerUsers } from "./reducerUsers";
import { reducerGroups } from "./reducerGroups";
import { reducerLocations } from "./reducerLocations";
import { reducerDocumentTypes } from "./reducerDocumentTypes";
import { connectRouter } from "connected-react-router";
import { reducerDocuments } from "./reducerDocuments";
import { reducerPermission } from "./reducerPermissions";
import { reducerPersonalDocuments } from "./reducerPersonalDocuments";
import { reducerJustificatives } from "./reducerJustificatives";
import { reducerModelDetails } from "./reducerModelDetails";
import { reducerRequests } from "./reducerRequests";
import { reducerJustificativeStatuses } from "./reducerJustificativeStatuses";
import { reducerTriggers } from "./reducerTriggers";
import { reducerNotifications } from "./reducerNotifications";
import { reducerDevices } from "./reducerDevices";
import { reducerPresenceTypes } from "./reducerPresenceTypes";
import { reducerPresences } from "./reducerPresences";
import { reducerDepartments } from "./reducerDepartments";
import { reducerShiftTypes } from "./reducerShiftTypes";
import { reducerShifts } from "./reducerShifts";
import { reducerCommunications } from "./reducerCommunications";
import { reducerWarehouses } from "./reducerWarehouses";
import { reducerProducts } from "./reducesProducts";
import { reducerFleets } from "./reducerFleets";
import { reducerReservations } from "./reducerReservations";
import { reducerTravels } from "./reducerTravels";
import { reducerExpenses } from "./reducerExpenses";

export const reducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    ui: reducerUI,
    auth: reducerAuth,
    permissions: reducerPermission,
    users: reducerUsers,
    groups: reducerGroups,
    locations: reducerLocations,
    documentTypes: reducerDocumentTypes,
    documents: reducerDocuments,
    personalDocuments: reducerPersonalDocuments,
    justificatives: reducerJustificatives,
    justificativeStatuses: reducerJustificativeStatuses,
    modelDetails: reducerModelDetails,
    requests: reducerRequests,
    triggers: reducerTriggers,
    notifications: reducerNotifications,
    devices: reducerDevices,
    presenceTypes: reducerPresenceTypes,
    presences: reducerPresences,
    departments: reducerDepartments,
    shiftTypes: reducerShiftTypes,
    shifts: reducerShifts,
    communications: reducerCommunications,
    products: reducerProducts,
    warehouses: reducerWarehouses,
    fleets: reducerFleets,
    reservations: reducerReservations,
    travels: reducerTravels,
    expenses: reducerExpenses,
  });
