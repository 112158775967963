/** @format */

import { RetweetOutlined } from "@ant-design/icons";
import moment from "moment";
import React from "react";
import { UIButton } from "../../components/ui/Button";
import { WarehouseOperation } from "../../redux-modules/declarations/maps/warehouses";
import apiClient from "../../utils/apiClient";
import { showNotification } from "../../utils/showNotification";
import { API_ENDPOINTS } from "../api";

interface Props {
  callback: () => void;
  canRiconsegnare?: boolean;
  user_id?: string;
}
export const productAssignedListColumns = ({ callback }: Props) => [
  {
    title: "Prodotto",
    dataIndex: "product_name",
    key: "product_name",
  },
  {
    title: "Stato",
    dataIndex: "product_status",
    key: "product_status",
  },
  {
    title: "Q.tà",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Seriale",
    dataIndex: "serial",
    key: "serial",
  },
  {
    title: "Utente",
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "Data assegnazione",
    dataIndex: "data_creazione",
    key: "data_creazione",
    render: function render(text: string): JSX.Element {
      return <>{moment(text).format("DD/MM/YYYY")}</>;
    },
  },
  {
    title: "Data riconsegna prevista",
    dataIndex: "data_riconsegna_prevista",
    key: "data_riconsegna_prevista",
    render: function render(text: string): JSX.Element {
      return <>{!!text ? moment(text).format("DD/MM/YYYY") : "N.D."}</>;
    },
  },
  {
    title: "Data riconsegna effettiva",
    dataIndex: "data_riconsegna_effettiva",
    key: "data_riconsegna_effettiva",
    render: function render(text: string): JSX.Element {
      return <>{!!text ? moment(text).format("DD/MM/YYYY") : "N.D."}</>;
    },
  },
  {
    title: "Riconsegna",
    dataIndex: "riconsegna",
    key: "riconsegna",
    render: function render(text: string, record: unknown): JSX.Element {
      const action = () => {
        apiClient
          .post(API_ENDPOINTS.WAREHOUSES_WORKFLOW, {
            // @ts-ignore
            assegnazione_id: record.id,
            // @ts-ignore
            product_id: record.product_id,
            // @ts-ignore
            user_id: record.user_id,
            // @ts-ignore
            quantity: record.quantity,
            // @ts-ignore
            serial: record.serial,
            // @ts-ignore
            effective_return_delivery_date: moment().format("YYYY-MM-DD"),
            // @ts-ignore
            product_status: record.product_status,
            // @ts-ignore
            warehouse_id: record.warehouse_id,
            operation_type: WarehouseOperation.RICONSEGNA,
          })
          .then(res => {
            showNotification("success", "Bene!", "Riconsegna effettuata con successo");
            callback();
          })
          .catch(err => {
            showNotification("error", "Errore", err.message);
          });
      };
      return (
        <>
          {
            //@ts-ignore
            !record.data_riconsegna_effettiva && (
              <UIButton type="primary" onClick={action}>
                <RetweetOutlined />
                Effettua riconsegna
              </UIButton>
            )
          }
        </>
      );
    },
  },
];

export const productAssignedUserListColumns = ({ callback, canRiconsegnare, user_id }: Props) => {
  return [
    {
      title: "Prodotto",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Stato",
      dataIndex: "product_status",
      key: "product_status",
    },
    {
      title: "Q.tà",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Seriale",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Magazzino",
      dataIndex: "warehouse_name",
      key: "warehouse_name",
    },
    {
      title: "Data assegnazione",
      dataIndex: "data_creazione",
      key: "data_creazione",
      render: function render(text: string): JSX.Element {
        return <>{moment(text).format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Data riconsegna prevista",
      dataIndex: "data_riconsegna_prevista",
      key: "data_riconsegna_prevista",
      render: function render(text: string): JSX.Element {
        return <>{!!text ? moment(text).format("DD/MM/YYYY") : "N.D."}</>;
      },
    },
    {
      title: "Data riconsegna effettiva",
      dataIndex: "data_riconsegna_effettiva",
      key: "data_riconsegna_effettiva",
      render: function render(text: string): JSX.Element {
        return <>{!!text ? moment(text).format("DD/MM/YYYY") : "N.D."}</>;
      },
    },
    canRiconsegnare
      ? {
          title: "Riconsegna",
          dataIndex: "riconsegna",
          key: "riconsegna",
          render: function render(text: string, record: unknown): JSX.Element {
            const action = () => {
              apiClient
                .post(API_ENDPOINTS.WAREHOUSES_WORKFLOW, {
                  // @ts-ignore
                  assegnazione_id: record.id,
                  // @ts-ignore
                  product_id: record.product_id,
                  user_id: user_id,
                  // @ts-ignore
                  quantity: record.quantity,
                  // @ts-ignore
                  serial: record.serial,
                  // @ts-ignore
                  effective_return_delivery_date: moment().format("YYYY-MM-DD"),
                  // @ts-ignore
                  product_status: record.product_status,
                  // @ts-ignore
                  warehouse_id: record.warehouse_id,
                  operation_type: WarehouseOperation.RICONSEGNA,
                })
                .then(res => {
                  showNotification("success", "Bene!", "Riconsegna effettuata con successo");
                  callback();
                })
                .catch(err => {
                  showNotification("error", "Errore", err.message);
                });
            };
            return (
              <>
                {
                  //@ts-ignore
                  !record.data_riconsegna_effettiva && (
                    <UIButton type="primary" onClick={action}>
                      <RetweetOutlined />
                      Effettua riconsegna
                    </UIButton>
                  )
                }
              </>
            );
          },
        }
      : {},
  ];
};
