/** @format */

import { TUI } from "../declarations/maps/ui";
import { TActionType } from "../declarations/actionTypes";
import {
  TActionAlertGenerate,
  TActionAlertRemove,
  TActionAppLoadingFailure,
  TActionAppLoadingRequest,
  TActionAppLoadingSuccess,
  TActionCustomStylesOptionListFailure,
  TActionCustomStylesOptionListSuccess,
  TActionCustomStylesOptionUpdateReset,
  TActionCustomStylesOptionUpdateSuccess,
  TActionMenuCollapseRequest,
  TActionMenuUncollapseRequest,
  TActionStaticAlertGenerate,
  TActionStaticAlertRemove,
} from "../declarations/actions/ui";
import {
  TActionUserAvatarReset,
  TActionUserAvatarSuccess,
  TActionUserCreateReset,
  TActionUserCreateSuccess,
  TActionUserDeleteReset,
  TActionUserDeleteSuccess,
  TActionUserDocumentUploadReset,
  TActionUserDocumentUploadSuccess,
  TActionUsersListLoadingFailure,
  TActionUsersListLoadingRequest,
  TActionUsersListLoadingSuccess,
  TActionUserUpdateReset,
  TActionUserUpdateSuccess,
} from "../declarations/actions/users";
import {
  TActionGroupCreateReset,
  TActionGroupCreateSuccess,
  TActionGroupDeleteReset,
  TActionGroupDeleteSuccess,
  TActionGroupsListLoadingFailure,
  TActionGroupsListLoadingRequest,
  TActionGroupsListLoadingSuccess,
  TActionGroupUpdateReset,
  TActionGroupUpdateSuccess,
} from "../declarations/actions/groups";
import {
  TActionLocationCreateReset,
  TActionLocationCreateSuccess,
  TActionLocationDeleteReset,
  TActionLocationDeleteSuccess,
  TActionLocationListLoadingFailure,
  TActionLocationListLoadingRequest,
  TActionLocationListLoadingSuccess,
  TActionLocationUpdateReset,
  TActionLocationUpdateSuccess,
} from "../declarations/actions/location";
import {
  TActionDocumentTypeCreateReset,
  TActionDocumentTypeCreateSuccess,
  TActionDocumentTypeDeleteReset,
  TActionDocumentTypeDeleteSuccess,
  TActionDocumentTypeUpdateReset,
  TActionDocumentTypeUpdateSuccess,
} from "../declarations/actions/documentType";
import { TActionLoginFailure, TActionLoginReset } from "../declarations/actions/auth";
import {
  TActionPermissionListLoadingRequest,
  TActionPermissionListLoadingSuccess,
  TActionPermissionListLoadingFailure,
} from "../declarations/actions/permission";
import {
  TActionDocumentListLoadingFailure,
  TActionDocumentListLoadingRequest,
  TActionDocumentListLoadingSuccess,
} from "../declarations/actions/document";
import {
  TActionJustificativeUpdateReset,
  TActionJustificativeUpdateSuccess,
  TActionJustificativeDeleteReset,
  TActionJustificativeDeleteSuccess,
  TActionJustificativeCreateSuccess,
  TActionJustificativeCreateReset,
  TActionJustificativeListLoadingRequest,
  TActionJustificativeListLoadingSuccess,
  TActionJustificativeListLoadingFailure,
} from "../declarations/actions/justificatives";
import {
  TActionModelDetailCreateReset,
  TActionModelDetailCreateSuccess,
  TActionModelDetailDeleteReset,
  TActionModelDetailDeleteSuccess,
  TActionModelDetailListFailure,
  TActionModelDetailListRequest,
  TActionModelDetailListSuccess,
} from "../declarations/actions/modelDetail";
import {
  TActionRequestCreateRequest,
  TActionRequestCreateReset,
  TActionRequestCreateSuccess,
  TActionRequestDeleteRequest,
  TActionRequestDeleteReset,
  TActionRequestDeleteSuccess,
  TActionRequestListLoadingFailure,
  TActionRequestListLoadingRequest,
  TActionRequestListLoadingSuccess,
} from "../declarations/actions/request";
import {
  TActionJustificativeStatusListLoadingFailure,
  TActionJustificativeStatusListLoadingRequest,
  TActionJustificativeStatusListLoadingSuccess,
} from "../declarations/actions/justificativeStatuses";
import {
  TActionTriggerListLoadingFailure,
  TActionTriggerListLoadingRequest,
  TActionTriggerListLoadingSuccess,
} from "../declarations/actions/trigger";
import {
  TActionNotificationListLoadingFailure,
  TActionNotificationListLoadingRequest,
  TActionNotificationListLoadingSuccess,
} from "../declarations/actions/notification";
import {
  TActionDeviceListLoadingRequest,
  TActionDeviceListLoadingSuccess,
  TActionDeviceListLoadingFailure,
  TActionDeviceCreateSuccess,
  TActionDeviceCreateReset,
  TActionDeviceDeleteSuccess,
  TActionDeviceDeleteReset,
  TActionDeviceUpdateSuccess,
  TActionDeviceUpdateReset,
} from "../declarations/actions/devices";
import {
  TActionPresenceListLoadingRequest,
  TActionPresenceListLoadingSuccess,
  TActionPresenceListLoadingFailure,
  TActionPresenceCreateSuccess,
  TActionPresenceCreateReset,
  TActionPresenceCreateFailure,
} from "../declarations/actions/presences";
import {
  TActionPresenceTypeListLoadingRequest,
  TActionPresenceTypeListLoadingSuccess,
  TActionPresenceTypeListLoadingFailure,
  TActionPresenceTypeCreateSuccess,
  TActionPresenceTypeCreateReset,
  TActionPresenceTypeUpdateSuccess,
  TActionPresenceTypeUpdateReset,
  TActionPresenceTypeDeleteSuccess,
  TActionPresenceTypeDeleteReset,
} from "../declarations/actions/presenceTypes";
import {
  TActionDepartmentListLoadingRequest,
  TActionDepartmentListLoadingSuccess,
  TActionDepartmentListLoadingFailure,
  TActionDepartmentCreateSuccess,
  TActionDepartmentCreateReset,
  TActionDepartmentDeleteSuccess,
  TActionDepartmentDeleteReset,
  TActionDepartmentUpdateSuccess,
  TActionDepartmentUpdateReset,
} from "../declarations/actions/departments";
import {
  TActionShiftListLoadingRequest,
  TActionShiftListLoadingSuccess,
  TActionShiftListLoadingFailure,
  TActionShiftCreateSuccess,
  TActionShiftCreateReset,
  TActionShiftCreateFailure,
  TActionShiftDeleteSuccess,
  TActionShiftDeleteReset,
} from "../declarations/actions/shifts";
import {
  TActionShiftTypeListLoadingRequest,
  TActionShiftTypeListLoadingSuccess,
  TActionShiftTypeListLoadingFailure,
  TActionShiftTypeCreateSuccess,
  TActionShiftTypeCreateReset,
  TActionShiftTypeUpdateSuccess,
  TActionShiftTypeUpdateReset,
  TActionShiftTypeDeleteSuccess,
  TActionShiftTypeDeleteReset,
} from "../declarations/actions/shiftTypes";
import {
  TActionCommunicationListLoadingRequest,
  TActionCommunicationListLoadingSuccess,
  TActionCommunicationListLoadingFailure,
  TActionCommunicationDeleteSuccess,
  TActionCommunicationDeleteReset,
  TActionCommunicationCreateReset,
  TActionCommunicationCreateSuccess,
} from "../declarations/actions/communications";
import {
  TActionProductListLoadingRequest,
  TActionProductListLoadingSuccess,
  TActionProductListLoadingFailure,
  TActionProductDeleteSuccess,
  TActionProductDeleteReset,
  TActionProductUpdateReset,
  TActionProductUpdateSuccess,
} from "../declarations/actions/products";
import {
  TActionWarehouseListLoadingRequest,
  TActionWarehouseListLoadingSuccess,
  TActionWarehouseListLoadingFailure,
  TActionWarehouseDeleteSuccess,
  TActionWarehouseDeleteReset,
  TActionWarehouseUpdateReset,
  TActionWarehouseUpdateSuccess,
} from "../declarations/actions/warehouses";
import {
  TActionFleetCreateReset,
  TActionFleetCreateSuccess,
  TActionFleetDeleteReset,
  TActionFleetDeleteSuccess,
  TActionFleetListLoadingFailure,
  TActionFleetListLoadingRequest,
  TActionFleetListLoadingSuccess,
  TActionFleetUpdateReset,
  TActionFleetUpdateSuccess,
} from "../declarations/actions/fleets";
import {
  TActionTravelCreateRequest,
  TActionTravelCreateReset,
  TActionTravelCreateSuccess,
  TActionTravelDeleteReset,
  TActionTravelDeleteSuccess,
  TActionTravelListLoadingFailure,
  TActionTravelListLoadingRequest,
  TActionTravelListLoadingSuccess,
  TActionTravelUpdateReset,
  TActionTravelUpdateSuccess,
} from "../declarations/actions/travels";
import {
  TActionExpenseCreateReset,
  TActionExpenseCreateSuccess,
  TActionExpenseDeleteReset,
  TActionExpenseDeleteSuccess,
  TActionExpenseListLoadingFailure,
  TActionExpenseListLoadingRequest,
  TActionExpenseListLoadingSuccess,
  TActionExpenseUpdateReset,
  TActionExpenseUpdateSuccess,
} from "../declarations/actions/expenses";
import {
  TActionReservationCreateReset,
  TActionReservationCreateSuccess,
  TActionReservationDeleteReset,
  TActionReservationDeleteSuccess,
  TActionReservationListLoadingFailure,
  TActionReservationListLoadingRequest,
  TActionReservationListLoadingSuccess,
  TActionReservationUpdateReset,
  TActionReservationUpdateSuccess,
} from "../declarations/actions/reservations";

type Actions =
  | TActionLoginReset
  | TActionLoginFailure
  | TActionAppLoadingRequest
  | TActionAppLoadingSuccess
  | TActionAppLoadingFailure
  | TActionUsersListLoadingRequest
  | TActionUsersListLoadingSuccess
  | TActionUsersListLoadingFailure
  | TActionUserCreateSuccess
  | TActionUserCreateReset
  | TActionGroupsListLoadingRequest
  | TActionGroupsListLoadingSuccess
  | TActionGroupsListLoadingFailure
  | TActionPermissionListLoadingRequest
  | TActionPermissionListLoadingSuccess
  | TActionPermissionListLoadingFailure
  | TActionGroupCreateSuccess
  | TActionGroupCreateReset
  | TActionLocationCreateReset
  | TActionLocationCreateSuccess
  | TActionGroupUpdateReset
  | TActionGroupUpdateSuccess
  | TActionGroupDeleteReset
  | TActionGroupDeleteSuccess
  | TActionUserUpdateReset
  | TActionUserUpdateSuccess
  | TActionUserDeleteSuccess
  | TActionUserDeleteReset
  | TActionLocationUpdateReset
  | TActionLocationUpdateSuccess
  | TActionLocationDeleteSuccess
  | TActionLocationDeleteReset
  | TActionUserAvatarSuccess
  | TActionUserAvatarReset
  | TActionUserDocumentUploadSuccess
  | TActionUserDocumentUploadReset
  | TActionDocumentTypeCreateSuccess
  | TActionDocumentTypeCreateReset
  | TActionDocumentTypeUpdateSuccess
  | TActionDocumentTypeUpdateReset
  | TActionDocumentTypeDeleteSuccess
  | TActionDocumentTypeDeleteReset
  | TActionAlertGenerate
  | TActionAlertRemove
  | TActionStaticAlertGenerate
  | TActionStaticAlertRemove
  | TActionLocationListLoadingRequest
  | TActionLocationListLoadingSuccess
  | TActionLocationListLoadingFailure
  | TActionDocumentListLoadingRequest
  | TActionDocumentListLoadingSuccess
  | TActionDocumentListLoadingFailure
  | TActionJustificativeUpdateReset
  | TActionJustificativeUpdateSuccess
  | TActionJustificativeDeleteReset
  | TActionJustificativeDeleteSuccess
  | TActionJustificativeCreateSuccess
  | TActionJustificativeCreateReset
  | TActionJustificativeListLoadingRequest
  | TActionJustificativeListLoadingSuccess
  | TActionJustificativeListLoadingFailure
  | TActionModelDetailListRequest
  | TActionModelDetailListSuccess
  | TActionModelDetailListFailure
  | TActionModelDetailCreateReset
  | TActionModelDetailDeleteReset
  | TActionModelDetailCreateSuccess
  | TActionModelDetailDeleteSuccess
  | TActionRequestCreateRequest
  | TActionRequestCreateReset
  | TActionRequestDeleteRequest
  | TActionRequestDeleteSuccess
  | TActionRequestListLoadingRequest
  | TActionRequestListLoadingSuccess
  | TActionRequestListLoadingFailure
  | TActionRequestCreateSuccess
  | TActionRequestDeleteReset
  | TActionJustificativeStatusListLoadingFailure
  | TActionJustificativeStatusListLoadingRequest
  | TActionJustificativeStatusListLoadingSuccess
  | TActionTriggerListLoadingRequest
  | TActionTriggerListLoadingSuccess
  | TActionTriggerListLoadingFailure
  | TActionNotificationListLoadingRequest
  | TActionNotificationListLoadingSuccess
  | TActionNotificationListLoadingFailure
  | TActionDeviceListLoadingRequest
  | TActionDeviceListLoadingSuccess
  | TActionDeviceListLoadingFailure
  | TActionDeviceCreateSuccess
  | TActionDeviceCreateReset
  | TActionDeviceDeleteSuccess
  | TActionDeviceDeleteReset
  | TActionDeviceUpdateSuccess
  | TActionDeviceUpdateReset
  | TActionDepartmentListLoadingRequest
  | TActionDepartmentListLoadingSuccess
  | TActionDepartmentListLoadingFailure
  | TActionDepartmentCreateSuccess
  | TActionDepartmentCreateReset
  | TActionDepartmentDeleteSuccess
  | TActionDepartmentDeleteReset
  | TActionDepartmentUpdateSuccess
  | TActionDepartmentUpdateReset
  | TActionPresenceTypeListLoadingRequest
  | TActionPresenceTypeListLoadingSuccess
  | TActionPresenceTypeListLoadingFailure
  | TActionPresenceTypeCreateSuccess
  | TActionPresenceTypeCreateReset
  | TActionPresenceTypeUpdateSuccess
  | TActionPresenceTypeUpdateReset
  | TActionPresenceTypeDeleteSuccess
  | TActionPresenceTypeDeleteReset
  | TActionPresenceListLoadingRequest
  | TActionPresenceListLoadingSuccess
  | TActionPresenceListLoadingFailure
  | TActionPresenceCreateSuccess
  | TActionPresenceCreateReset
  | TActionPresenceCreateFailure
  | TActionShiftTypeListLoadingRequest
  | TActionShiftTypeListLoadingSuccess
  | TActionShiftTypeListLoadingFailure
  | TActionShiftTypeCreateSuccess
  | TActionShiftTypeCreateReset
  | TActionShiftTypeUpdateSuccess
  | TActionShiftTypeUpdateReset
  | TActionShiftTypeDeleteSuccess
  | TActionShiftTypeDeleteReset
  | TActionShiftListLoadingRequest
  | TActionShiftListLoadingSuccess
  | TActionShiftListLoadingFailure
  | TActionShiftCreateSuccess
  | TActionShiftCreateReset
  | TActionShiftCreateFailure
  | TActionShiftDeleteSuccess
  | TActionShiftDeleteReset
  | TActionCommunicationListLoadingRequest
  | TActionCommunicationListLoadingSuccess
  | TActionCommunicationListLoadingFailure
  | TActionCommunicationDeleteSuccess
  | TActionCommunicationDeleteReset
  | TActionCommunicationCreateSuccess
  | TActionCommunicationCreateReset
  | TActionProductListLoadingRequest
  | TActionProductListLoadingSuccess
  | TActionProductListLoadingFailure
  | TActionProductDeleteSuccess
  | TActionProductDeleteReset
  | TActionProductUpdateSuccess
  | TActionProductUpdateReset
  | TActionWarehouseListLoadingRequest
  | TActionWarehouseListLoadingSuccess
  | TActionWarehouseListLoadingFailure
  | TActionWarehouseDeleteSuccess
  | TActionWarehouseDeleteReset
  | TActionWarehouseUpdateSuccess
  | TActionWarehouseUpdateReset
  | TActionRequestCreateSuccess
  | TActionRequestCreateReset
  | TActionMenuCollapseRequest
  | TActionMenuUncollapseRequest
  | TActionFleetUpdateSuccess
  | TActionFleetUpdateReset
  | TActionFleetDeleteSuccess
  | TActionFleetDeleteReset
  | TActionFleetListLoadingRequest
  | TActionFleetListLoadingSuccess
  | TActionFleetListLoadingFailure
  | TActionFleetCreateSuccess
  | TActionFleetCreateReset
  | TActionTravelCreateSuccess
  | TActionTravelCreateReset
  | TActionTravelListLoadingRequest
  | TActionTravelListLoadingSuccess
  | TActionTravelListLoadingFailure
  | TActionTravelDeleteSuccess
  | TActionTravelDeleteReset
  | TActionTravelUpdateSuccess
  | TActionTravelUpdateReset
  | TActionExpenseCreateSuccess
  | TActionExpenseCreateReset
  | TActionExpenseListLoadingRequest
  | TActionExpenseListLoadingSuccess
  | TActionExpenseListLoadingFailure
  | TActionExpenseDeleteSuccess
  | TActionExpenseDeleteReset
  | TActionExpenseUpdateSuccess
  | TActionExpenseUpdateReset
  | TActionReservationCreateSuccess
  | TActionReservationCreateReset
  | TActionReservationListLoadingRequest
  | TActionReservationListLoadingSuccess
  | TActionReservationListLoadingFailure
  | TActionReservationDeleteSuccess
  | TActionReservationDeleteReset
  | TActionReservationUpdateSuccess
  | TActionReservationUpdateReset
  | TActionCustomStylesOptionUpdateSuccess
  | TActionCustomStylesOptionUpdateReset
  | TActionCustomStylesOptionListSuccess
  | TActionCustomStylesOptionListFailure;

export const reducerUI = (
  prevStore: TUI = {
    customStyleOptions: {
      ShiftsListZoomLevel: 1,
      RequestsListZoomLevel: 1,
      PresencesListZoomLevel: 1,
      showOfficeLabelInPresenceList: false,
      showHandonLabelInPresenceList: false,
    },
  },
  action: Actions,
): TUI => {
  switch (action.type) {
    case TActionType.loginFailure:
      return { ...prevStore, loginValidation: action.payload };
    case TActionType.loginReset:
      return { ...prevStore, loginValidation: {} };
    case TActionType.appLoadingRequest:
      return { ...prevStore, loading: true };
    case TActionType.appLoadingSuccess:
      return { ...prevStore, loading: false };
    case TActionType.appLoadingFailure:
      return { ...prevStore, loading: false };
    case TActionType.alertGenerate:
      return { ...prevStore, alert: action.payload };
    case TActionType.alertRemove:
      return { ...prevStore, alert: undefined };
    case TActionType.menuCollapseRequest:
      return { ...prevStore, menuCollapsed: true };
    case TActionType.menuUncollapseRequest:
      return { ...prevStore, menuCollapsed: false };
    case TActionType.staticAlertGenerate:
      return { ...prevStore, staticAlert: action.payload };
    case TActionType.staticAlertRemove:
      return { ...prevStore, staticAlert: undefined };
    case TActionType.usersListLoadingRequest:
      return { ...prevStore, usersListLoading: true };
    case TActionType.usersListLoadingSuccess:
      return { ...prevStore, usersListLoading: false };
    case TActionType.usersListLoadingFailure:
      return { ...prevStore, usersListLoading: false };
    case TActionType.groupsListLoadingRequest:
      return { ...prevStore, groupsListLoading: true };
    case TActionType.groupsListLoadingSuccess:
      return { ...prevStore, groupsListLoading: false };
    case TActionType.groupsListLoadingFailure:
      return { ...prevStore, groupsListLoading: false };
    case TActionType.justificativeListLoadingRequest:
      return { ...prevStore, justificativeListLoading: true };
    case TActionType.justificativeListLoadingSuccess:
      return { ...prevStore, justificativeListLoading: false };
    case TActionType.justificativeListLoadingFailure:
      return { ...prevStore, justificativeListLoading: false };
    case TActionType.deviceListLoadingRequest:
      return { ...prevStore, deviceListLoading: true };
    case TActionType.deviceListLoadingSuccess:
      return { ...prevStore, deviceListLoading: false };
    case TActionType.deviceListLoadingFailure:
      return { ...prevStore, deviceListLoading: false };
    case TActionType.deviceDeleteSuccess:
      return { ...prevStore, deviceDeleted: true };
    case TActionType.deviceDeleteReset:
      return { ...prevStore, deviceDeleted: false };
    case TActionType.deviceCreateSuccess:
      return { ...prevStore, deviceUpdated: true };
    case TActionType.deviceCreateReset:
      return { ...prevStore, deviceUpdated: false };
    case TActionType.deviceUpdateSuccess:
      return { ...prevStore, deviceUpdated: true };
    case TActionType.deviceUpdateReset:
      return { ...prevStore, deviceUpdated: false };

    case TActionType.fleetListLoadingRequest:
      return { ...prevStore, fleetListLoading: true };
    case TActionType.fleetListLoadingSuccess:
      return { ...prevStore, fleetListLoading: false };
    case TActionType.fleetListLoadingFailure:
      return { ...prevStore, fleetListLoading: false };
    case TActionType.fleetDeleteSuccess:
      return { ...prevStore, fleetDeleted: true };
    case TActionType.fleetDeleteReset:
      return { ...prevStore, fleetDeleted: false };
    case TActionType.fleetCreateSuccess:
      return { ...prevStore, fleetUpdated: true };
    case TActionType.fleetCreateReset:
      return { ...prevStore, fleetUpdated: false };
    case TActionType.fleetUpdateSuccess:
      return { ...prevStore, fleetUpdated: true };
    case TActionType.fleetUpdateReset:
      return { ...prevStore, fleetUpdated: false };
    case TActionType.expenseListLoadingRequest:
      return { ...prevStore, expenseListLoading: true };
    case TActionType.expenseListLoadingSuccess:
      return { ...prevStore, expenseListLoading: false };
    case TActionType.expenseListLoadingFailure:
      return { ...prevStore, expenseListLoading: false };
    case TActionType.expenseDeleteSuccess:
      return { ...prevStore, expenseDeleted: true };
    case TActionType.expenseDeleteReset:
      return { ...prevStore, expenseDeleted: false };
    case TActionType.expenseCreateSuccess:
      return { ...prevStore, expenseUpdated: true };
    case TActionType.expenseCreateReset:
      return { ...prevStore, expenseUpdated: false };
    case TActionType.expenseUpdateSuccess:
      return { ...prevStore, expenseUpdated: true };
    case TActionType.expenseUpdateReset:
      return { ...prevStore, expenseUpdated: false };

    case TActionType.travelListLoadingRequest:
      return { ...prevStore, travelListLoading: true };
    case TActionType.travelListLoadingSuccess:
      return { ...prevStore, travelListLoading: false };
    case TActionType.travelListLoadingFailure:
      return { ...prevStore, travelListLoading: false };
    case TActionType.travelDeleteSuccess:
      return { ...prevStore, travelDeleted: true };
    case TActionType.travelDeleteReset:
      return { ...prevStore, travelDeleted: false };
    case TActionType.travelCreateSuccess:
      return { ...prevStore, travelUpdated: true };
    case TActionType.travelCreateReset:
      return { ...prevStore, travelUpdated: false };
    case TActionType.travelUpdateSuccess:
      return { ...prevStore, travelUpdated: true };
    case TActionType.travelUpdateReset:
      return { ...prevStore, travelUpdated: false };

    case TActionType.reservationListLoadingRequest:
      return { ...prevStore, reservationListLoading: true };
    case TActionType.reservationListLoadingSuccess:
      return { ...prevStore, reservationListLoading: false };
    case TActionType.reservationListLoadingFailure:
      return { ...prevStore, reservationListLoading: false };
    case TActionType.reservationDeleteSuccess:
      return { ...prevStore, reservationDeleted: true };
    case TActionType.reservationDeleteReset:
      return { ...prevStore, reservationDeleted: false };
    case TActionType.reservationCreateSuccess:
      return { ...prevStore, reservationUpdated: true };
    case TActionType.reservationCreateReset:
      return { ...prevStore, reservationUpdated: false };
    case TActionType.reservationUpdateSuccess:
      return { ...prevStore, reservationUpdated: true };
    case TActionType.reservationUpdateReset:
      return { ...prevStore, reservationUpdated: false };

    case TActionType.communicationListLoadingRequest:
      return { ...prevStore, communicationListLoading: true };
    case TActionType.communicationListLoadingSuccess:
      return { ...prevStore, communicationListLoading: false };
    case TActionType.communicationListLoadingFailure:
      return { ...prevStore, communicationListLoading: false };
    case TActionType.communicationDeleteSuccess:
      return { ...prevStore, communicationDeleted: true };
    case TActionType.communicationDeleteReset:
      return { ...prevStore, communicationDeleted: false };
    case TActionType.communicationCreateSuccess:
      return { ...prevStore, communicationUpdated: true };
    case TActionType.communicationCreateReset:
      return { ...prevStore, communicationUpdated: false };
    case TActionType.productListLoadingRequest:
      return { ...prevStore, productListLoading: true };
    case TActionType.productListLoadingSuccess:
      return { ...prevStore, productListLoading: false };
    case TActionType.productListLoadingFailure:
      return { ...prevStore, productListLoading: false };
    case TActionType.productDeleteSuccess:
      return { ...prevStore, productDeleted: true };
    case TActionType.productDeleteReset:
      return { ...prevStore, productDeleted: false };
    case TActionType.productUpdateSuccess:
      return { ...prevStore, productUpdated: true };
    case TActionType.productUpdateReset:
      return { ...prevStore, productUpdated: false };

    case TActionType.warehouseListLoadingRequest:
      return { ...prevStore, warehouseListLoading: true };
    case TActionType.warehouseListLoadingSuccess:
      return { ...prevStore, warehouseListLoading: false };
    case TActionType.warehouseListLoadingFailure:
      return { ...prevStore, warehouseListLoading: false };
    case TActionType.warehouseDeleteSuccess:
      return { ...prevStore, warehouseDeleted: true };
    case TActionType.warehouseDeleteReset:
      return { ...prevStore, warehouseDeleted: false };
    case TActionType.warehouseUpdateSuccess:
      return { ...prevStore, warehouseUpdated: true };
    case TActionType.warehouseUpdateReset:
      return { ...prevStore, warehouseUpdated: false };

    case TActionType.departmentListLoadingRequest:
      return { ...prevStore, departmentListLoading: true };
    case TActionType.departmentListLoadingSuccess:
      return { ...prevStore, departmentListLoading: false };
    case TActionType.departmentListLoadingFailure:
      return { ...prevStore, departmentListLoading: false };
    case TActionType.departmentDeleteSuccess:
      return { ...prevStore, departmentDeleted: true };
    case TActionType.departmentDeleteReset:
      return { ...prevStore, departmentDeleted: false };
    case TActionType.departmentCreateSuccess:
      return { ...prevStore, departmentUpdated: true };
    case TActionType.departmentCreateReset:
      return { ...prevStore, departmentUpdated: false };
    case TActionType.departmentUpdateSuccess:
      return { ...prevStore, departmentUpdated: true };
    case TActionType.departmentUpdateReset:
      return { ...prevStore, departmentUpdated: false };

    case TActionType.presenceTypeListLoadingRequest:
      return { ...prevStore, presenceTypeListLoading: true };
    case TActionType.presenceTypeListLoadingSuccess:
      return { ...prevStore, presenceTypeListLoading: false };
    case TActionType.presenceTypeListLoadingFailure:
      return { ...prevStore, presenceTypeListLoading: false };
    case TActionType.presenceTypeDeleteSuccess:
      return { ...prevStore, presenceTypeDeleted: true };
    case TActionType.presenceTypeDeleteReset:
      return { ...prevStore, presenceTypeDeleted: false };
    case TActionType.presenceTypeCreateSuccess:
      return { ...prevStore, presenceTypeUpdated: true };
    case TActionType.presenceTypeCreateReset:
      return { ...prevStore, presenceTypeUpdated: false };
    case TActionType.presenceTypeUpdateSuccess:
      return { ...prevStore, presenceTypeUpdated: true };
    case TActionType.presenceTypeUpdateReset:
      return { ...prevStore, presenceTypeUpdated: false };

    case TActionType.presenceListLoadingRequest:
      return { ...prevStore, presenceListLoading: true };
    case TActionType.presenceListLoadingSuccess:
      return { ...prevStore, presenceListLoading: false };
    case TActionType.presenceListLoadingFailure:
      return { ...prevStore, presenceListLoading: false };
    case TActionType.presenceCreateSuccess:
      return { ...prevStore, presenceUpdated: true };
    case TActionType.presenceCreateFailure:
      return { ...prevStore, presenceUpdated: true };
    case TActionType.presenceCreateReset:
      return { ...prevStore, presenceUpdated: false };

    case TActionType.shiftTypeListLoadingRequest:
      return { ...prevStore, shiftTypeListLoading: true };
    case TActionType.shiftTypeListLoadingSuccess:
      return { ...prevStore, shiftTypeListLoading: false };
    case TActionType.shiftTypeListLoadingFailure:
      return { ...prevStore, shiftTypeListLoading: false };
    case TActionType.shiftTypeDeleteSuccess:
      return { ...prevStore, shiftTypeDeleted: true };
    case TActionType.shiftTypeDeleteReset:
      return { ...prevStore, shiftTypeDeleted: false };
    case TActionType.shiftTypeCreateSuccess:
      return { ...prevStore, shiftTypeUpdated: true };
    case TActionType.shiftTypeCreateReset:
      return { ...prevStore, shiftTypeUpdated: false };
    case TActionType.shiftTypeUpdateSuccess:
      return { ...prevStore, shiftTypeUpdated: true };
    case TActionType.shiftTypeUpdateReset:
      return { ...prevStore, shiftTypeUpdated: false };

    case TActionType.shiftListLoadingRequest:
      return { ...prevStore, shiftListLoading: true };
    case TActionType.shiftListLoadingSuccess:
      return { ...prevStore, shiftListLoading: false };
    case TActionType.shiftListLoadingFailure:
      return { ...prevStore, shiftListLoading: false };
    case TActionType.shiftCreateSuccess:
      return { ...prevStore, shiftUpdated: true };
    case TActionType.shiftCreateFailure:
      return { ...prevStore, shiftUpdated: true };
    case TActionType.shiftCreateReset:
      return { ...prevStore, shiftUpdated: false };
    case TActionType.shiftDeleteSuccess:
      return { ...prevStore, shiftDeleted: true };
    case TActionType.shiftDeleteReset:
      return { ...prevStore, shiftDeleted: false };

    case TActionType.triggerListLoadingRequest:
      return { ...prevStore, triggerListLoading: true };
    case TActionType.triggerListLoadingSuccess:
      return { ...prevStore, triggerListLoading: false };
    case TActionType.triggerListLoadingFailure:
      return { ...prevStore, triggerListLoading: false };
    case TActionType.notificationListLoadingRequest:
      return { ...prevStore, notificationListLoading: true };
    case TActionType.notificationListLoadingSuccess:
      return { ...prevStore, notificationListLoading: false };
    case TActionType.notificationListLoadingFailure:
      return { ...prevStore, notificationListLoading: false };
    case TActionType.permissionListLoadingRequest:
      return { ...prevStore, permissionListLoading: true };
    case TActionType.permissionListLoadingSuccess:
      return { ...prevStore, permissionListLoading: false };
    case TActionType.permissionListLoadingFailure:
      return { ...prevStore, permissionListLoading: false };
    case TActionType.locationListLoadingRequest:
      return { ...prevStore, locationsListLoading: true };
    case TActionType.locationListLoadingSuccess:
      return { ...prevStore, locationsListLoading: false };
    case TActionType.locationListLoadingFailure:
      return { ...prevStore, locationsListLoading: false };
    case TActionType.documentListLoadingRequest:
      return { ...prevStore, documentListLoading: true };
    case TActionType.documentListLoadingSuccess:
      return { ...prevStore, documentListLoading: false };
    case TActionType.documentListLoadingFailure:
      return { ...prevStore, documentListLoading: false };
    case TActionType.justificativeStatusListLoadingRequest:
      return { ...prevStore, justificativeStatusListLoading: true };
    case TActionType.justificativeStatusListLoadingSuccess:
      return { ...prevStore, justificativeStatusListLoading: false };
    case TActionType.justificativeStatusListLoadingFailure:
      return { ...prevStore, justificativeStatusListLoading: false };
    case TActionType.modelDetailListRequest:
      return { ...prevStore, modelDetailLoading: true };
    case TActionType.modelDetailListSuccess:
      return { ...prevStore, modelDetailLoading: false };
    case TActionType.modelDetailListFailure:
      return { ...prevStore, modelDetailLoading: false };
    case TActionType.modelDetailDeleteSuccess:
      return { ...prevStore, modelDetailUpdated: true };
    case TActionType.modelDetailCreateSuccess:
      return { ...prevStore, modelDetailUpdated: true };
    case TActionType.modelDetailDeleteReset:
      return { ...prevStore, modelDetailUpdated: false };
    case TActionType.modelDetailCreateReset:
      return { ...prevStore, modelDetailUpdated: false };
    case TActionType.requestListLoadingRequest:
      return { ...prevStore, requestListLoading: true };
    case TActionType.requestListLoadingSuccess:
      return { ...prevStore, requestListLoading: false };
    case TActionType.requestListLoadingFailure:
      return { ...prevStore, requestListLoading: false };
    case TActionType.requestDeleteSuccess:
      return { ...prevStore, requestUpdated: true };
    case TActionType.requestCreateSuccess:
      return { ...prevStore, requestUpdated: true };
    case TActionType.requestDeleteReset:
      return { ...prevStore, requestUpdated: false };
    case TActionType.requestCreateReset:
      return { ...prevStore, requestUpdated: false };
    case TActionType.userCreateSuccess:
      return { ...prevStore, userCreated: action.payload };
    case TActionType.userCreateReset:
      return { ...prevStore, userCreated: false };
    case TActionType.userUpdateSuccess:
      return { ...prevStore, userUpdated: true };
    case TActionType.userUpdateReset:
      return { ...prevStore, userUpdated: false };
    case TActionType.userDeleteSuccess:
      return { ...prevStore, userDeleted: true };
    case TActionType.userDeleteReset:
      return { ...prevStore, userDeleted: false };
    case TActionType.userAvatarSuccess:
      return { ...prevStore, userAvatarUpdated: true };
    case TActionType.userAvatarReset:
      return { ...prevStore, userAvatarUpdated: false };
    case TActionType.userDocumentUploadSuccess:
      return { ...prevStore, userDocumentUpdated: true };
    case TActionType.userDocumentUploadReset:
      return { ...prevStore, userDocumentUpdated: false };
    case TActionType.groupCreateSuccess:
      return { ...prevStore, groupCreated: true };
    case TActionType.groupCreateReset:
      return { ...prevStore, groupCreated: false };
    case TActionType.groupUpdateSuccess:
      return { ...prevStore, groupUpdated: true };
    case TActionType.groupUpdateReset:
      return { ...prevStore, groupUpdated: false };
    case TActionType.groupDeleteSuccess:
      return { ...prevStore, groupDeleted: true };
    case TActionType.groupDeleteReset:
      return { ...prevStore, groupDeleted: false };
    case TActionType.justificativeCreateSuccess:
      return { ...prevStore, justificativeCreated: true };
    case TActionType.justificativeCreateReset:
      return { ...prevStore, justificativeCreated: false };
    case TActionType.justificativeUpdateSuccess:
      return { ...prevStore, justificativeUpdated: true };
    case TActionType.justificativeUpdateReset:
      return { ...prevStore, justificativeUpdated: false };
    case TActionType.justificativeDeleteSuccess:
      return { ...prevStore, justificativeDeleted: true };
    case TActionType.justificativeDeleteReset:
      return { ...prevStore, justificativeDeleted: false };
    case TActionType.locationCreateSuccess:
      return { ...prevStore, locationCreated: true };
    case TActionType.locationCreateReset:
      return { ...prevStore, locationCreated: false };
    case TActionType.locationUpdateSuccess:
      return { ...prevStore, locationUpdated: true };
    case TActionType.locationUpdateReset:
      return { ...prevStore, locationUpdated: false };
    case TActionType.locationDeleteSuccess:
      return { ...prevStore, locationDeleted: true };
    case TActionType.locationDeleteReset:
      return { ...prevStore, locationDeleted: false };
    case TActionType.documentTypeCreateSuccess:
      return { ...prevStore, documentTypeCreated: true };
    case TActionType.documentTypeCreateReset:
      return { ...prevStore, documentTypeCreated: false };
    case TActionType.documentTypeUpdateSuccess:
      return { ...prevStore, documentTypeUpdated: true };
    case TActionType.documentTypeUpdateReset:
      return { ...prevStore, documentTypeUpdated: false };
    case TActionType.documentTypeDeleteSuccess:
      return { ...prevStore, documentTypeDeleted: true };
    case TActionType.documentTypeDeleteReset:
      return { ...prevStore, documentTypeDeleted: false };
    case TActionType.CustomStyleOptionListSuccess:
      return { ...prevStore, customStyleOptions: action.payload };
    case TActionType.CustomStyleOptionListFailure:
      return { ...prevStore };
    case TActionType.CustomStyleOptionUpdateSuccess:
      return { ...prevStore, customStyleOptionsUpdated: true, customStyleOptions: action.payload };
    case TActionType.CustomStyleOptionUpdateReset:
      return { ...prevStore, customStyleOptionsUpdated: false };
    default:
      return prevStore;
  }
};
