/** @format */

import { Checkbox, Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UIInputPassword } from "../../ui/Input/InputPassword";
//import Logo from "../../../assets/images/logo_notext.png";
import Logo from "../../../assets/svg/logo_strong_vertical.svg";
import { useDispatch, useSelector } from "react-redux";
import { actionLoginRequest, actionLoginReset } from "../../../redux-modules/actions/actionsAuth";
import { Colors, BaseColor } from "../../../theme/maps/Colors";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import { Routes } from "../../../utils/Routes";
import { selectorLoginValidation } from "../../../redux-modules/selectors/selectorsUI";
import { useAlert } from "../../../redux-modules/hooks/useAlert";
import { UIAlert } from "../../ui/Alert";
import { useHistory } from "react-router";

export const ViewLogin = (): JSX.Element => {
  const history = useHistory();
  const validation = useSelector(selectorLoginValidation);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useAlert(6000);
  const dispatch = useDispatch();
  const handleLogin = (loginObject: {}): void => {
    setIsLoading(true);
    //@ts-ignore
    const authObj = { email: loginObject.email, password: loginObject.password };
    dispatch(actionLoginRequest(authObj));
  };

  useEffect(() => {
    return (): void => {
      dispatch(actionLoginReset());
    };
  }, []);

  useEffect(() => {
    setIsLoading(false);
    if (!!Object.values(validation).length) setVisible(true);
  }, [validation]);

  useEffect(() => {
    if (!visible && !!Object.values(validation).length) dispatch(actionLoginReset());
  }, [visible]);

  return (
    <>
      <Row justify="center" gutter={[16, 16]}>
        <Col lg={8} sm={18} xs={24}>
          <UICard
            data-cy="login-card"
            style={{
              boxShadow: "-1px 1px 7px #454545",
            }}
          >
            <Row justify="center" gutter={[16, 16]} style={{ marginBottom: 15 }}>
              <Col>
                <img
                  width={200}
                  src={Logo}
                  style={
                    {
                      //filter: "drop-shadow(1px -1px 0.02rem #aeacac)",
                    }
                  }
                />
              </Col>
            </Row>
            {/*
              <Row justify="center" gutter={[16, 16]}>
              <Col>
                <UITitle
                  level={2}
                  style={{ fontWeight: "bold", filter: "drop-shadow(1px -1px 0.15rem #aeacac)" }}
                  color={BaseColor}
                >
                  {process.env.REACT_APP_NAME?.toUpperCase()}
                </UITitle>
              </Col>
            </Row>
              */}
            {visible && (
              <Row key={"err"} justify="center" gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                <Col span={24}>
                  <UIAlert
                    message="Si è verificato un errore"
                    description={"Controlla i tuoi dati e riprova"}
                    type="error"
                    showIcon
                  />
                </Col>
              </Row>
            )}
            <Form
              data-cy="login-form"
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleLogin}
              onFinishFailed={(e): void => console.log(e)}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Inserisci un'email valida!" }]}
              >
                <UIInput data-cy="email-input" placeholder="Email" prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Inserisci una password valida!" }]}
              >
                <UIInputPassword
                  data-cy="password-input"
                  placeholder="Password"
                  prefix={<UnlockOutlined />}
                />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember_token" valuePropName="checked" noStyle>
                  <Checkbox>Ricordami</Checkbox>
                </Form.Item>
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <UIButton
                  bgColor={BaseColor}
                  color={Colors.primary}
                  hoverBgColor={BaseColor}
                  borderColor={BaseColor}
                  loading={isLoading}
                  htmlType="submit"
                  data-cy="submit-input"
                  fullWidth
                >
                  ACCEDI
                </UIButton>
              </Form.Item>
              <Form.Item name="lostPassword" noStyle>
                <span
                  style={{ color: BaseColor, cursor: "pointer" }}
                  onClick={() => history.push(Routes.forgotPassword)}
                >
                  Password dimenticata?
                </span>
              </Form.Item>
            </Form>
          </UICard>
        </Col>
      </Row>
    </>
  );
};
