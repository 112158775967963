/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TExpenseCreateRequest,
  TExpenseUpdateRequest,
} from "../redux-modules/declarations/maps/expenses";
import apiClient from "../utils/apiClient";

export const apiExpenseList = (payload: {
  date_from: string;
  date_to: string;
}): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.EXPENSES_LIST + `/${payload.date_from}/${payload.date_to}`);
};

export const apiExpenseCreate = (
  expense: TExpenseCreateRequest,
): Promise<AxiosResponse<unknown>> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };

  var formData = new FormData();
  formData.append("attachments", JSON.stringify(expense.attachments));
  formData.append("note", `${expense.note}`);
  formData.append("expense_type", `${expense.expense_type}`);
  formData.append("fleet_type", `${expense.fleet_type}`);
  formData.append("departure", `${expense.departure}`);
  formData.append("arrival", `${expense.arrival}`);
  formData.append("is_return_included", `${expense.is_return_included}`);
  formData.append("km_distance", `${expense.km_distance}`);
  formData.append("fleet_id", `${expense.fleet_id}`);
  formData.append("rimborso_previsto", `${expense.rimborso_previsto}`);
  formData.append("rimborso_richiesto", `${expense.rimborso_richiesto}`);
  formData.append("rimborso_approvato", `${expense.rimborso_approvato}`);
  formData.append("date", `${expense.date}`);
  formData.append("travel_id", `${expense.travel_id}`);
  formData.append("user_id", `${expense.user_id}`);

  return apiClient.post(API_ENDPOINTS.EXPENSES, formData, config);
};

export const apiExpenseUpdate = (expense: TExpenseUpdateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.EXPENSES + "/" + expense.id, expense);

export const apiExpenseDelete = (expenseId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.EXPENSES + "/" + expenseId);
