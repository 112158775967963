/** @format */

import { Row, Col, Dropdown, Menu } from "antd";
import React from "react";
import { Routes } from "../../utils/Routes";
import { UILink } from "../../components/ui/Link";
import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { UIText } from "../../components/ui/Text";
import { BaseColor } from "../../theme/maps/Colors";
import { useDispatch, useSelector } from "react-redux";
import { selectorPermissionList } from "../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../utils/permissions/hasPermission";
import { actionDepartmentDeleteRequest } from "../../redux-modules/actions/actionsDepartments";

export const departmentListColumns = [
  { title: "#", dataIndex: "id", key: "id", showMobile: true },
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
    showMobile: true,
  },
  { title: "N°", dataIndex: "count", key: "count", showMobile: true },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const permissionList = useSelector(selectorPermissionList);

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => history.push(Routes.departmentsEdit.replace(":id_department", row.id))}
        icon={<EditOutlined />}
      >
        Gestisci
      </Menu.Item>

      <Menu.Item
        key="2"
        onClick={() => {
          dispatch(actionDepartmentDeleteRequest(row.id));
        }}
        icon={<DeleteOutlined />}
      >
        Elimina
      </Menu.Item>
    </Menu>
  );

  if (!hasPermission(permissionList, "Department", "Update")) return <></>;
  return (
    <>
      <Dropdown overlay={menu}>
        <UIText css={"font-size: 24px; cursor: pointer;"} color={BaseColor}>
          <MoreOutlined />
        </UIText>
      </Dropdown>
    </>
  );
};
