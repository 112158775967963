/** @format */

import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UITable } from "../../ui/Table";
import { UIButton } from "../../ui/Button";
import { Routes } from "../../../utils/Routes";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorDeviceDeleted,
  selectorDeviceListLoading,
} from "../../../redux-modules/selectors/selectorsUI";
import { UICard } from "../../ui/Card";
import UITransition from "../../ui/Transition";
import { UILink } from "../../ui/Link";
import { selectorDeviceList } from "../../../redux-modules/selectors/selectorsDevices";
import { actionDeviceListRequest } from "../../../redux-modules/actions/actionsDevices";
import { deviceListColumns } from "../../../constants/tables/deviceListColumns";

export const ViewDevicesList = (): JSX.Element => {
  const dispatch = useDispatch();
  const deviceList = useSelector(selectorDeviceList);
  const deviceListLoading = useSelector(selectorDeviceListLoading);
  const isDeviceDeleted = useSelector(selectorDeviceDeleted);

  useEffect(() => {
    dispatch(actionDeviceListRequest());
  }, []);

  useEffect(() => {
    if (isDeviceDeleted) dispatch(actionDeviceListRequest());
  }, [isDeviceDeleted]);

  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Dispositivi
          </UITitle>
        </Col>
        <Col>
          <UILink
            data-cy="new-device-button"
            route={Routes.devicesCreate}
            label={<UIButton type="primary">+ Aggiungi Dispositivo</UIButton>}
          />
        </Col>
      </Row>
      <UICard>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UITable
              size="middle"
              data-cy="device-list-table"
              loading={deviceListLoading}
              columns={deviceListColumns}
              dataSource={deviceList}
            />
          </Col>
        </Row>
      </UICard>
    </UITransition>
  );
};
