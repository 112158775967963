/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaRequestCreate,
  sagaRequestDelete,
  sagaRequestList,
  sagaRequestUpdate,
} from "../sagas/sagasRequests";

export function* watcherRequests(): unknown {
  yield takeLatest(TActionType.requestCreateRequest, sagaRequestCreate);
  yield takeLatest(TActionType.requestListRequest, sagaRequestList);
  yield takeLatest(TActionType.requestDeleteRequest, sagaRequestDelete);
  yield takeLatest(TActionType.requestUpdateRequest, sagaRequestUpdate);
}
