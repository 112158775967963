/** @format */

import { DownloadOutlined, InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import { Row, Col, DatePickerProps, Progress, Skeleton, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { apiReportPResencesStatus } from "../../../../api/apiPresences";
import { API_ENDPOINTS } from "../../../../constants/api";
import { selectorDepartmentList } from "../../../../redux-modules/selectors/selectorsDepartments";
import { selectorGroupsList } from "../../../../redux-modules/selectors/selectorsGroups";
import { BaseColor } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { showNotification } from "../../../../utils/showNotification";
import { sleep } from "../../../../utils/sleep";
import { UIButton } from "../../../ui/Button";
import { UICard } from "../../../ui/Card";
import { UICheckbox } from "../../../ui/Checkbox";
import { UIDatePicker } from "../../../ui/DatePicker";
import { UIFileUploader } from "../../../ui/FileUploader";
import UIModal from "../../../ui/Modal";
import { UITitle } from "../../../ui/Title";

interface Props {
  isModalVisible: boolean;
  closeModal: () => void;
}

interface UserData {
  created_at: string;
  dataFine: string;
  dataInizio: string;
  dataRilascio: string;
  default_checked: string;
  giornataLavorata: string;
  id: string;
  note: string;
  idCertificato: string;
  lavoratore_codiceFiscale: string;
  lavoratore_cognome: string;
  lavoratore_comuneNascita: string;
  lavoratore_dataNascita: string;
  lavoratore_nome: string;
  lavoratore_provinciaNascita: string;
  lavoratore_sesso: string;
  matricolaINPS: string;
  medico_codiceAsl: string;
  medico_codiceFiscale: string;
  medico_codiceRegione: string;
  medico_cognome: string;
  medico_nome: string;
  residenza_cap: string;
  residenza_civico: string;
  residenza_comune: string;
  residenza_provincia: string;
  residenza_via: string;
  ruoloMedico: string;
  tenance_id: string;
  tipoCertificato: "A" | "C" | "I" | "R";
  trauma: string;
  updated_at: string;
  user_id: string;
}

export const ImportMalattiaModal = ({ isModalVisible, closeModal }: Props): JSX.Element => {
  const [usersData, setUsersData] = useState<UserData[] | undefined>();
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [percentage, setPercentage] = useState<string | undefined>();
  const [newFile, setNewFile] = useState<File | null>();
  const [checkedElems, setCheckedElems] = useState<string[]>([]);
  const handleUpload = (e: FileList | object[]): void => {
    if (e[0]) {
      //@ts-ignore
      setNewFile(e[0]?.originFileObj);
    }
  };

  const handleOk = () => {
    apiClient
      .post(API_ENDPOINTS.SAVE_FROM_XML, { xmlimport_ids: checkedElems })
      .then(res => {
        closeModal();
        showNotification("success", "Successo", "Importazione avvenuta con successo");
        console.log(res.data);
      })
      .catch(err => {});
  };

  const fetchData = () => {
    setIsShowLoading(true);
    let stillPolling = true;

    apiClient
      .get(API_ENDPOINTS.REPORT_PRESENCES_START)
      .then(async r => {
        const id = r.data.id;
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        var formData = new FormData();
        //@ts-ignore
        formData.append("xml", newFile);

        apiClient
          .post(API_ENDPOINTS.IMPORT_XML, formData, config)
          .then(res => {
            stillPolling = false;
            setPercentage(undefined);
            setUsersData(res.data);
            setIsShowLoading(false);
          })
          .catch(err => {
            stillPolling = false;
            setPercentage(undefined);
            setIsShowLoading(false);
            showNotification("error", "Oops..", "Errore durante la generazione del report");
          });

        //VA IN POLLINGO
        let counter = 0;
        while (stillPolling) {
          apiReportPResencesStatus(id)
            .then(res => {
              //@ts-ignore
              if (res.data.status === "completed") {
                stillPolling = false;
                setPercentage(undefined);
              } else {
                //@ts-ignore
                setPercentage(res.data.msg);
              }
            })
            .catch(e => {
              stillPolling = false;
              setPercentage(undefined);
              setIsShowLoading(false);
              showNotification("error", "Oops..", "Errore durante la generazione del report");
            });

          if (counter > 90) {
            stillPolling = false;
            setPercentage(undefined);
          }

          counter++;
          await sleep(1000);
        }
      })
      .catch(e => {
        setPercentage(undefined);
        setIsShowLoading(false);
        showNotification("error", "Oops..", "Errore durante la generazione del report");
      });
  };

  useEffect(() => {
    if (!!usersData) setCheckedElems(usersData.filter(e => !!e.default_checked).map(e => e.id));
  }, [usersData]);

  const onClose = () => {
    setUsersData(undefined);
    closeModal();
  };

  return (
    <CustomModal
      title={"Importazione XML Inps"}
      width={1000} //or 1000
      visible={isModalVisible}
      cancelButtonProps={{ style: { borderRadius: "7px" } }}
      onCancel={onClose}
      onOk={handleOk}
    >
      {!usersData?.length && (
        <>
          <Row justify="center" style={{ marginBottom: "10px" }}>
            <Col span={10}>
              <UIFileUploader handleUpload={handleUpload} forXml={true} />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={10}>
              <UIButton
                loading={isShowLoading}
                type="primary"
                onClick={fetchData}
                style={{ width: "100%" }}
              >
                <DownloadOutlined /> Importa
              </UIButton>
            </Col>
          </Row>
        </>
      )}
      {!!percentage && (
        <Row>
          <Col span={24}>
            <ProgressCustom
              percent={parseInt(percentage)}
              strokeColor={BaseColor}
              style={{ width: "100%", marginTop: "15px" }}
              status="active"
            />
          </Col>
        </Row>
      )}
      {!!isShowLoading && (
        <UICard style={{ marginBottom: "20px", border: "none" }}>
          <Skeleton active />
        </UICard>
      )}
      {typeof usersData !== "undefined" && usersData.length === 0 && (
        <Row style={{ marginTop: "15px" }}>
          <Col span={24} style={{ textAlign: "center" }}>
            <UITitle level={4}>Nessun elemento da importare</UITitle>
          </Col>
        </Row>
      )}
      {!!usersData && usersData.length > 0 && !isShowLoading && (
        <Row style={{ marginBottom: "10px" }} className="repeat-container">
          <Col span={24}>
            <Row className="repeat-row">
              <Col span={1} className="repeat-col"></Col>
              <Col span={3} className="repeat-col">
                N° Cert.
              </Col>
              <Col span={2} className="repeat-col">
                Tipo
              </Col>
              <Col span={4} className="repeat-col">
                CF
              </Col>
              <Col span={6} className="repeat-col">
                Dipendente
              </Col>
              <Col span={3} className="repeat-col">
                Inizio
              </Col>
              <Col span={3} className="repeat-col">
                Fine
              </Col>
              <Col span={2} className="repeat-col">
                Note
              </Col>
            </Row>
            {usersData.map(user => (
              <Row className="repeat-row" key={user.id}>
                <Col span={1} className="repeat-col">
                  <StyledCheckbox
                    checked={checkedElems.includes(user.id)}
                    disabled={
                      !usersData
                        .filter(e => !!e.default_checked)
                        .map(e => e.id)
                        .includes(user.id)
                    }
                    value={user.id}
                    onChange={v =>
                      v.target.checked
                        ? setCheckedElems([...checkedElems, user.id])
                        : setCheckedElems(checkedElems.filter(e => e !== user.id))
                    }
                  />
                </Col>
                <Col span={3} className="repeat-col">
                  {user.idCertificato}
                </Col>
                <Col span={2} className="repeat-col">
                  <Tooltip title={charToFullstring(user.tipoCertificato)}>
                    <strong style={{ cursor: "pointer" }}>{user.tipoCertificato}</strong>
                  </Tooltip>
                </Col>
                <Col span={4} className="repeat-col">
                  {user.lavoratore_codiceFiscale}
                </Col>
                <Col span={6} className="repeat-col">
                  {user.lavoratore_nome} {user.lavoratore_cognome}
                </Col>
                <Col span={3} className="repeat-col">
                  {user.dataInizio}
                </Col>
                <Col span={3} className="repeat-col">
                  {user.dataFine}
                </Col>
                <Col span={2} className="repeat-col">
                  {user.note && (
                    <Tooltip title={user.note}>
                      <InfoCircleOutlined style={{ cursor: "pointer", fontSize: "20px" }} />
                    </Tooltip>
                  )}
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      )}
    </CustomModal>
  );
};

const charToFullstring = (char: "A" | "C" | "I" | "R") => {
  switch (char) {
    case "A":
      return "Annullato";
    case "C":
      return "Continuazione";
    case "I":
      return "Inizio";
    case "R":
      return "Ricaduta";
    default:
      return "";
  }
};
const ProgressCustom = styled(Progress)`
  font-size: 16px !important;
  .ant-progress-inner {
    height: 11px;
  }

  .ant-progress-bg {
    height: 11px !important;
  }
`;

const CustomModal = styled(UIModal)`
  .repeat-col {
    padding: 3px 5px;
  }
  .repeat-row {
    border-bottom: 1px solid #f0f0f0;
  }
  .repeat-row:first-of-type .repeat-col {
    font-weight: bold;
  }

  .repeat-container {
    max-height: 500px;
    overflow-y: auto;
  }
`;

export const StyledCheckbox = styled(UICheckbox)`
  .ant-checkbox-inner {
    width: 21px;
    height: 21px;
    border-radius: 7px;
  }
  .ant-checkbox-inner:after {
    left: 6px;
  }
  .ant-checkbox {
    font-size: 0px;
  }
`;
