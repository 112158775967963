/** @format */

import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select, Skeleton, Steps, Switch } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIDatePicker } from "../../ui/DatePicker";
import { UIInput } from "../../ui/Input";
import { UISelect } from "../../ui/Select";
import { UIText } from "../../ui/Text";
import {
  TIncrementalPeriod,
  TJustificativeStatus,
  TPeriodType,
  TSizeUnit,
} from "../../../redux-modules/declarations/maps/justificatives";
import apiClient from "../../../utils/apiClient";
import { API_ENDPOINTS } from "../../../constants/api";
import { useDispatch, useSelector } from "react-redux";
import { actionJustificativeCreateRequest } from "../../../redux-modules/actions/actionsJustificatives";
import moment from "moment";
import { useHistory } from "react-router";
import {
  selectorJustificativeCreated,
  selectorJustificativeStatusListLoading,
} from "../../../redux-modules/selectors/selectorsUI";
import { Routes } from "../../../utils/Routes";
import { UILink } from "../../ui/Link";
import { selectorJustificativeStatusesList } from "../../../redux-modules/selectors/selectorsJustificativeStatuses";

const ViewRequestTypeCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [monthlyAccumulation, setMonthlyAccumulation] = useState(false);
  const [monthlyRemaining, setMonthlyRemaining] = useState(false);
  const [form] = Form.useForm();
  const justificativeStatuses = useSelector(selectorJustificativeStatusesList);
  const loadingStatuses = useSelector(selectorJustificativeStatusListLoading);

  const [disableHoursAccumulation, setDisableHoursAccumulation] = useState(false);
  const [disableAnnualAccumulation, setDisableAnnualAccumulation] = useState(false);

  const isRequestTypeCreated = useSelector(selectorJustificativeCreated);

  useEffect(() => {
    if (isRequestTypeCreated) history.push(Routes.settingsRequestType);
  }, [isRequestTypeCreated]);

  //@ts-ignore
  const handleSave = (e): void => {
    dispatch(
      actionJustificativeCreateRequest({
        name: e.name,
        period_type: e.period_type,
        size_unit: e.size_unit,
        justificative_status_default_id: e.justificative_status_default_id,
        color: e.color,
        is_requestable: e.is_requestable,
        //@ts-ignore
        incremental_period: !!e.incremental_value_year
          ? TIncrementalPeriod.ANNO
          : TIncrementalPeriod.MESE,
        //@ts-ignore
        incremental_value: !!e.incremental_value_year
          ? //@ts-ignore
            e.incremental_value_year
          : //@ts-ignore
            e.incremental_value_month,
        switch_amount_date: moment(e.switch_amount_date).format("DD/MM"),
      }),
    );
  };

  if (loadingStatuses)
    return (
      <UICard style={{ marginBottom: "20px" }}>
        <Skeleton active />
      </UICard>
    );
  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor}>
            Richieste
          </UITitle>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <UICard>
            <Form
              name="new-request"
              className="new-request"
              layout="vertical"
              onFinish={handleSave}
              form={form}
            >
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Form.Item
                    name="name"
                    label={<UIText strong={true}>Titolo</UIText>}
                    rules={[{ required: true, message: "Inserisci un titolo!" }]}
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Form.Item
                    name="period_type"
                    label={<UIText strong={true}>Periodo</UIText>}
                    rules={[{ required: true, message: "Inserisci un periodo!" }]}
                  >
                    <UISelect>
                      {Object.values(TPeriodType).map(e => (
                        <Select.Option key={e} value={e} style={{ textTransform: "capitalize" }}>
                          {e}
                        </Select.Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="size_unit"
                    label={<UIText strong={true}>Unità di misura</UIText>}
                    rules={[{ required: true, message: "Inserisci un unità di misura!" }]}
                  >
                    <UISelect>
                      {Object.values(TSizeUnit).map(e => (
                        <Select.Option key={e} value={e} style={{ textTransform: "capitalize" }}>
                          {e}
                        </Select.Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Row gutter={[16, 16]}>
                    <Col span={21}>
                      <UIText strong={true}>Colore</UIText>
                    </Col>
                    <Col span={3}>
                      <Form.Item name="color" initialValue={null} noStyle>
                        <UIInput type="color" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row gutter={[16, 16]}>
                    <Col span={21}>
                      <UIText strong={true}>E' richiedibile</UIText>
                    </Col>
                    <Col span={3}>
                      <Form.Item name="is_requestable" valuePropName="checked" noStyle>
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Form.Item
                    name="justificative_status_default_id"
                    label={<UIText strong={true}>Stato di default</UIText>}
                  >
                    <UISelect>
                      {justificativeStatuses.map(e => (
                        <Select.Option
                          key={e.id}
                          value={e.id}
                          style={{ textTransform: "capitalize" }}
                        >
                          {e.name}
                        </Select.Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6} />
                <Col span={4}>{monthlyAccumulation && <UIText strong={true}>Mensile</UIText>}</Col>
                <Col span={4}>{monthlyAccumulation && <UIText strong={true}>Annuale</UIText>}</Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Row gutter={[16, 16]}>
                    <Col span={21}>
                      <UIText strong={true}>Maturazione</UIText>
                    </Col>
                    <Col span={3}>
                      <Form.Item name="monthlyAccumulation" valuePropName="checked" noStyle>
                        <Switch onChange={setMonthlyAccumulation} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  {monthlyAccumulation && (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col span={8}>
                          <Row gutter={[16, 8]}>
                            <Col span={16}>
                              <Form.Item name="incremental_value_month" noStyle>
                                <UIInput
                                  disabled={disableHoursAccumulation}
                                  type="number"
                                  placeholder="- - -"
                                  onChange={(event): void =>
                                    setDisableAnnualAccumulation(!!event.target?.value)
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={8}>
                          <Row gutter={[16, 8]}>
                            <Col span={16}>
                              <Form.Item name="incremental_value_year" noStyle>
                                <UIInput
                                  disabled={disableAnnualAccumulation}
                                  type="number"
                                  placeholder="- - -"
                                  onChange={(event): void =>
                                    setDisableHoursAccumulation(!!event.target?.value)
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              {monthlyAccumulation && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col span={6} />
                    <Col span={12}>
                      {monthlyRemaining && <UIText strong={true}>Giorno e mese di scadenza</UIText>}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <Row gutter={[16, 16]}>
                        <Col span={21}>
                          <UIText strong={true}>Residuo mensile</UIText>
                        </Col>
                        <Col span={3}>
                          <Form.Item name="monthlyRemaining" valuePropName="checked" noStyle>
                            <Switch onChange={setMonthlyRemaining} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      {monthlyRemaining && (
                        <>
                          <Row gutter={[16, 16]}>
                            <Col span={10}>
                              <Form.Item name="switch_amount_date" noStyle>
                                <UIDatePicker format={"DD/MM"} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              <Row justify="space-between" align="bottom" gutter={[16, 16]}>
                <Col>
                  <UILink route={Routes.settingsRequestType} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item style={{ display: "inline-block", float: "right" }}>
                    <UIButton type="primary" htmlType="submit">
                      Crea nuova richiesta
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </UICard>
        </Col>
      </Row>
    </>
  );
};

export default ViewRequestTypeCreate;
