/** @format */

import { CalendarOutlined, ColumnWidthOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { BaseColor } from "../../../theme/maps/Colors";

interface AdditionalJSX {
  [key: string]: JSX.Element; //mesi
}

interface Props {
  selectedRange?: string[];
  onChange?: (date_from: moment.Moment, date_to: moment.Moment) => void;
  additionalJSX?: AdditionalJSX;
}
const UIMonthSelector = ({ selectedRange, onChange, additionalJSX }: Props): JSX.Element => {
  const [selectedYear, setSelectedYear] = useState<number>(moment().year());
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>(moment().month());

  const selectWholeYear = (year: number): void => {
    if (onChange && year) onChange(moment([year, 0]), moment([year, 11]).endOf("month"));
  };

  const handleSelectYear = (year: number): void => {
    if (onChange && year) {
      if (selectedMonth !== undefined) {
        onChange(moment([year, selectedMonth]), moment([year, selectedMonth]).endOf("month"));
      } else {
        onChange(moment([year, 0]), moment([year, 11]).endOf("month"));
      }
    }
  };

  const handleSelectMonth = (month: number): void => {
    if (onChange && month !== undefined) {
      onChange(moment([selectedYear, month]), moment([selectedYear, month]).endOf("month"));
    }
  };

  useEffect(() => {
    if (selectedRange) {
      setSelectedYear(moment(selectedRange[0]).year());
      if (moment(selectedRange[1]).diff(moment(selectedRange[0]), "months") === 0) {
        setSelectedMonth(moment(selectedRange[0]).month());
      } else {
        setSelectedMonth(undefined);
      }
    }
  }, [selectedRange]);

  return (
    <CustomContainer>
      <Row justify="center">
        <Col span={20}>
          <Row
            className="years-list"
            justify="space-between"
            style={{ display: "flex", flexWrap: "nowrap" }}
          >
            <Col
              span={8}
              onClick={() => handleSelectYear(moment().subtract(1, "years").year())}
              className={
                "year" + (selectedYear == moment().subtract(1, "years").year() ? " selected" : "")
              }
            >
              {moment().subtract(1, "years").year()}
            </Col>
            <Col
              span={8}
              onClick={() => handleSelectYear(moment().year())}
              className={"year" + (selectedYear == moment().year() ? " selected" : "")}
            >
              {moment().year()}
            </Col>
            <Col
              span={8}
              onClick={() => handleSelectYear(moment().add(1, "years").year())}
              className={
                "year" + (selectedYear == moment().add(1, "years").year() ? " selected" : "")
              }
            >
              {moment().add(1, "years").year()}
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: "10px" }}>
            <Tooltip title="Seleziona anno intero">
              <Col
                span={1}
                className="select-all-year"
                onClick={() => selectWholeYear(selectedYear)}
              >
                <span style={{ fontSize: "16px" }}>
                  <CalendarOutlined />
                </span>
              </Col>
            </Tooltip>
            <Col span={23} className="months-list">
              <Row justify="space-between" style={{ display: "flex", flexWrap: "nowrap" }}>
                <Col
                  span={2}
                  onClick={() => handleSelectMonth(0)}
                  className={
                    "month" +
                    (selectedMonth == 0 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>1</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["1"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["1"]}</Col>
                    </Row>
                  )}
                </Col>

                <Col
                  span={2}
                  onClick={() => handleSelectMonth(1)}
                  className={
                    "month" +
                    (selectedMonth == 1 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>2</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["2"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["2"]}</Col>
                    </Row>
                  )}
                </Col>

                <Col
                  span={2}
                  onClick={() => handleSelectMonth(2)}
                  className={
                    "month" +
                    (selectedMonth == 2 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>3</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["3"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["3"]}</Col>
                    </Row>
                  )}
                </Col>

                <Col
                  span={2}
                  onClick={() => handleSelectMonth(3)}
                  className={
                    "month" +
                    (selectedMonth == 3 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>4</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["4"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["4"]}</Col>
                    </Row>
                  )}
                </Col>

                <Col
                  span={2}
                  onClick={() => handleSelectMonth(4)}
                  className={
                    "month" +
                    (selectedMonth == 4 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>5</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["5"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["5"]}</Col>
                    </Row>
                  )}
                </Col>

                <Col
                  span={2}
                  onClick={() => handleSelectMonth(5)}
                  className={
                    "month" +
                    (selectedMonth == 5 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>6</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["6"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["6"]}</Col>
                    </Row>
                  )}
                </Col>

                <Col
                  span={2}
                  onClick={() => handleSelectMonth(6)}
                  className={
                    "month" +
                    (selectedMonth == 6 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>7</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["7"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["7"]}</Col>
                    </Row>
                  )}
                </Col>

                <Col
                  span={2}
                  onClick={() => handleSelectMonth(7)}
                  className={
                    "month" +
                    (selectedMonth == 7 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>8</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["8"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["8"]}</Col>
                    </Row>
                  )}
                </Col>

                <Col
                  span={2}
                  onClick={() => handleSelectMonth(8)}
                  className={
                    "month" +
                    (selectedMonth == 8 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>9</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["9"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["9"]}</Col>
                    </Row>
                  )}
                </Col>

                <Col
                  span={2}
                  onClick={() => handleSelectMonth(9)}
                  className={
                    "month" +
                    (selectedMonth == 9 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>10</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["10"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["10"]}</Col>
                    </Row>
                  )}
                </Col>

                <Col
                  span={2}
                  onClick={() => handleSelectMonth(10)}
                  className={
                    "month" +
                    (selectedMonth == 10 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>11</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["11"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["11"]}</Col>
                    </Row>
                  )}
                </Col>

                <Col
                  span={2}
                  onClick={() => handleSelectMonth(11)}
                  className={
                    "month" +
                    (selectedMonth == 11 || typeof selectedMonth === "undefined" ? " selected" : "")
                  }
                >
                  <Row>
                    <Col span={24}>12</Col>
                  </Row>
                  {!!additionalJSX && additionalJSX["12"] && (
                    <Row style={{ borderTop: "1px solid #f0f0f0" }}>
                      <Col span={24}>{additionalJSX["12"]}</Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </CustomContainer>
  );
};
export default memo(UIMonthSelector);

const CustomContainer = styled.span`
  display: block;
  //border: 1px solid #424344;
  background-color: #fff;
  padding: 15px 25px;
  border-radius: 7px;
  box-shadow: 0 2px 4px #091e4229, 0 0 0 1px #091e4214;

  .year {
    text-align: center;
    padding: 6px 12px;
    border-radius: 7px;
    cursor: pointer;
    font-size: 15px;
    margin: 2px;
    border: 1px solid #f1f2f5;
  }
  .year:hover {
    border: 1px solid ${BaseColor};
  }
  .month {
    text-align: center;
    padding: 12px;
    border-radius: 7px;
    cursor: pointer;
    font-size: 15px;
    margin: 2px;
    border: 1px solid #f1f2f5;
  }
  .month:hover {
    border: 1px solid ${BaseColor};
  }
  .select-all-year {
    text-align: center;
    padding: 12px 6px;
    cursor: pointer;
  }
  .selected {
    background-color: ${BaseColor};
    border: 1px solid ${BaseColor};
    color: white;
  }
`;
