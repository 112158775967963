/** @format */

import {
  CarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FolderOpenOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Col,
  Collapse,
  Dropdown,
  List,
  Menu,
  Popconfirm,
  Popover,
  Row,
  Skeleton,
  Tooltip,
} from "antd";
import Meta from "antd/lib/card/Meta";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { API_ENDPOINTS, API_PARAMS } from "../../../constants/api";
import { actionExpenseListRequest } from "../../../redux-modules/actions/actionsExpenses";
import { actionTravelListRequest } from "../../../redux-modules/actions/actionsTravels";
import { TUser, TUserMinified } from "../../../redux-modules/declarations/maps/auth";
import { TExpense } from "../../../redux-modules/declarations/maps/expenses";
import { selectorExpenseList } from "../../../redux-modules/selectors/selectorsExpenses";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { selectorTravelList } from "../../../redux-modules/selectors/selectorsTravels";
import {
  selectorExpenseDeleted,
  selectorExpenseListLoading,
  selectorExpenseUpdated,
  selectorTravelDeleted,
  selectorTravelListLoading,
  selectorTravelUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../theme/maps/Colors";
import apiClient from "../../../utils/apiClient";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { Routes } from "../../../utils/Routes";
import { showNotification } from "../../../utils/showNotification";
import { darkenFromHex, stringToHex } from "../../../utils/strings/stringToColor";
import { hexToRgb } from "../../../utils/styles";
import { ExpenseCreateDrawer } from "../../layout/SideDialogs/ExpenseCreate";
import { ExpenseShowDrawer } from "../../layout/SideDialogs/ExpenseShow";
import { ExpenseUserShowDrawer } from "../../layout/SideDialogs/ExpenseShow/user_index";
import { TravelCreateDrawer } from "../../layout/SideDialogs/TravelCreate";
import { CustomUserItem } from "../../renderers/UserInfoCard";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UICheckbox } from "../../ui/Checkbox";
import UIMonthSelector from "../../ui/MonthSelector";
import { UIText } from "../../ui/Text";
import { UITitle } from "../../ui/Title";
import UITransition from "../../ui/Transition";
import { ExportTravelAndExpenseModal } from "./partials/exportModal";
const { Panel } = Collapse;

const formatExpenseToList = (
  expenseList: TExpense[],
): {
  id: string;
  key: string;
  title: string;
  avatar: JSX.Element;
  description: string | undefined;
  status: JSX.Element;
  notravel: boolean;
  user: TUser;
}[] => {
  return expenseList.map(e => ({
    id: e.id,
    key: e.id,
    user: e.user,
    notravel: e.travel_id === null,
    title: e.expense_type + " - " + moment(e.date).format("DD/MM/YYYY"),
    avatar: (
      <Avatar size={45} style={{ backgroundColor: darkenFromHex(stringToHex(e.expense_type)) }}>
        {e.expense_type.charAt(0)}
      </Avatar>
    ),
    description: (e.rimborso_approvato ? e.rimborso_approvato : e.rimborso_richiesto) + "€",
    status:
      e.status == "Pendente" ? (
        <span>
          <ClockCircleOutlined style={{ color: "gray", marginLeft: "5px" }} /> {e.status}
        </span>
      ) : e.status == "Accettata" ? (
        <span>
          <CheckCircleOutlined style={{ color: "green", marginLeft: "5px" }} /> {e.status}
        </span>
      ) : (
        <span>
          <StopOutlined style={{ color: "red", marginLeft: "5px" }} /> {e.status}
        </span>
      ),
  }));
};

export const ViewTravelAndExpenseList: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const permissionList = useSelector(selectorPermissionList);
  const travelList = useSelector(selectorTravelList);
  const [filteredTravelList, setFilteredTravelList] = useState(
    travelList.filter(t => t.status != "Archiviato"),
  );
  const expenseList = useSelector(selectorExpenseList);
  const [showExportTravelAndExpenseModal, setShowExportTravelAndExpenseModal] = useState(false);
  const [currentExpense, setCurrentExpense] = useState<TExpense | undefined>();
  const [formattedExpensesList, setFormattedExpensesList] = useState(
    formatExpenseToList(expenseList),
  );
  const [showArchived, setShowArchived] = useState(false);

  const [selectedRange, setSelectedRange] = useState<string[] | undefined>();

  const isTravelDeleted = useSelector(selectorTravelDeleted);
  const isTravelUpdated = useSelector(selectorTravelUpdated);
  const [createTravelVisible, setCreateTravelVisible] = useState(false);

  const isExpenseDeleted = useSelector(selectorExpenseDeleted);
  const isExpenseUpdated = useSelector(selectorExpenseUpdated);
  const [createExpenseVisible, setCreateExpenseVisible] = useState(false);
  const [showExpenseVisible, setShowExpenseVisible] = useState(false);
  const [showUserExpenseVisible, setShowUserExpenseVisible] = useState(false);

  useEffect(() => {
    if (!!showArchived) {
      console.log();
      setFilteredTravelList(travelList);
    } else {
      setFilteredTravelList(travelList.filter(t => t.status !== "Archiviato"));
    }
  }, [showArchived, travelList]);

  const onCompleteTravel = (travelId: string): void => {
    apiClient.get(API_ENDPOINTS.TRAVEL + "/complete/" + travelId).then(res => {
      if (res.status === 200) {
        showNotification(
          "success",
          "Viaggio completato",
          "Il viaggio è stato completato con successo",
        );
        if (!!selectedRange) dispatch(actionTravelListRequest(selectedRange[0], selectedRange[1]));
        if (!selectedRange)
          dispatch(
            actionTravelListRequest(
              moment().startOf("month").format("YYYY-MM-DD"),
              moment().endOf("month").format("YYYY-MM-DD"),
            ),
          );
      } else {
        showNotification("error", "Errore", "Si è verificato un errore");
      }
    });
  };

  const canCompleteTravel = (travelId: string): boolean => {
    const travel = travelList.find(t => t.id === travelId);
    const expenseInTravelWithStatusPending = expenseList.find(
      e => e.travel_id === travelId && e.status === "Pendente",
    );
    return !!travel && !expenseInTravelWithStatusPending && travel.status != "Completato";
  };

  const canArchiveTravel = (travelId: string): boolean => {
    const travel = travelList.find(t => t.id === travelId);
    const expenseInTravelWithStatusPending = expenseList.find(
      e => e.travel_id === travelId && e.status === "Pendente",
    );
    return !!travel && !expenseInTravelWithStatusPending && travel.status != "Archiviato";
  };

  const canDeleteTravel = (travelId: string): boolean => {
    const travel = travelList.find(t => t.id === travelId);
    const expenseInTravelWithStatusAccepted = expenseList.find(
      e => e.travel_id === travelId && e.status === "Accettata",
    );
    return !!travel && !expenseInTravelWithStatusAccepted;
  };

  const onArchiveTravel = (travelId: string): void => {
    apiClient.get(API_ENDPOINTS.TRAVEL + "/archive/" + travelId).then(res => {
      if (res.status === 200) {
        showNotification(
          "success",
          "Viaggio archiviato",
          "Il viaggio è stato archiviato con successo",
        );
        if (!!selectedRange) dispatch(actionTravelListRequest(selectedRange[0], selectedRange[1]));
        if (!selectedRange)
          dispatch(
            actionTravelListRequest(
              moment().startOf("month").format("YYYY-MM-DD"),
              moment().endOf("month").format("YYYY-MM-DD"),
            ),
          );
      } else {
        showNotification("error", "Errore", "Si è verificato un errore");
      }
    });
  };

  const onChangeShowArchived = (e: CheckboxChangeEvent) => {
    setShowArchived(e.target.checked);
  };

  const onDeleteTravel = (travelId: string): void => {
    apiClient.delete(API_ENDPOINTS.TRAVELS + "/" + travelId).then(res => {
      if (res.status === 200) {
        showNotification(
          "success",
          "Viaggio eliminato",
          "Il viaggio è stato eliminato con successo",
        );
        if (!!selectedRange) dispatch(actionTravelListRequest(selectedRange[0], selectedRange[1]));
        if (!selectedRange)
          dispatch(
            actionTravelListRequest(
              moment().startOf("month").format("YYYY-MM-DD"),
              moment().endOf("month").format("YYYY-MM-DD"),
            ),
          );
      } else {
        showNotification("error", "Errore", "Si è verificato un errore");
      }
    });
  };

  const onCloseTravelCreate = (refresh: boolean = false): void => {
    setCreateTravelVisible(false);
    if (refresh) {
      if (!!selectedRange) dispatch(actionTravelListRequest(selectedRange[0], selectedRange[1]));
      if (!selectedRange)
        dispatch(
          actionTravelListRequest(
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
          ),
        );
    }
  };
  const onCloseExpenseCreate = (refresh: boolean = false): void => {
    setCreateExpenseVisible(false);
    if (refresh) {
      if (!!selectedRange) {
        dispatch(actionTravelListRequest(selectedRange[0], selectedRange[1]));
        dispatch(actionExpenseListRequest(selectedRange[0], selectedRange[1]));
      }
      if (!selectedRange) {
        dispatch(
          actionExpenseListRequest(
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
          ),
        );
        dispatch(
          actionTravelListRequest(
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
          ),
        );
      }
    }
  };
  const onCloseExpenseShow = (refresh: boolean = false): void => {
    setCurrentExpense(undefined);
    if (refresh) {
      if (!!selectedRange) {
        dispatch(actionTravelListRequest(selectedRange[0], selectedRange[1]));
        dispatch(actionExpenseListRequest(selectedRange[0], selectedRange[1]));
      }
      if (!selectedRange) {
        dispatch(
          actionExpenseListRequest(
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
          ),
        );
        dispatch(
          actionTravelListRequest(
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
          ),
        );
      }
    }
  };

  const onCloseExpenseUserShow = (refresh: boolean = false): void => {
    setCurrentExpense(undefined);
    if (refresh) {
      if (!!selectedRange) {
        dispatch(actionTravelListRequest(selectedRange[0], selectedRange[1]));
        dispatch(actionExpenseListRequest(selectedRange[0], selectedRange[1]));
      }
      if (!selectedRange) {
        dispatch(
          actionExpenseListRequest(
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
          ),
        );
        dispatch(
          actionTravelListRequest(
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
          ),
        );
      }
    }
  };

  useEffect(() => {
    if (!!currentExpense) {
      canManageExpense ? setShowExpenseVisible(true) : setShowUserExpenseVisible(true);
    } else {
      canManageExpense ? setShowExpenseVisible(false) : setShowUserExpenseVisible(false);
    }
  }, [currentExpense]);

  const travelListLoading = useSelector(selectorTravelListLoading);
  const expenseListLoading = useSelector(selectorExpenseListLoading);

  const canCreateTravel = hasPermission(permissionList, "Travel", "Create");
  const canCreateExpense = hasPermission(permissionList, "Expense", "Create");

  const canManageExpense = hasPermission(permissionList, "Expense", "Canmanageupdate");

  useEffect(() => {
    if (!!expenseList) {
      setFormattedExpensesList(formatExpenseToList(expenseList));
    }
  }, [expenseList]);

  useEffect(() => {
    dispatch(
      actionTravelListRequest(
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ),
    );
    dispatch(
      actionExpenseListRequest(
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ),
    );
  }, []);

  useEffect(() => {
    if (!!isTravelDeleted || !!isTravelUpdated || isExpenseDeleted || isExpenseUpdated) {
      if (!!selectedRange) {
        dispatch(actionTravelListRequest(selectedRange[0], selectedRange[1]));
        dispatch(actionExpenseListRequest(selectedRange[0], selectedRange[1]));
      } else {
        dispatch(
          actionTravelListRequest(
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
          ),
        );
        dispatch(
          actionExpenseListRequest(
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
          ),
        );
      }
    }
  }, [isTravelDeleted, isTravelUpdated, isExpenseDeleted, isExpenseUpdated]);

  const onMonthChange = (date_from: moment.Moment, date_to: moment.Moment) => {
    setSelectedRange([date_from.format("YYYY-MM-DD"), date_to.format("YYYY-MM-DD")]);
    dispatch(actionTravelListRequest(date_from.format("YYYY-MM-DD"), date_to.format("YYYY-MM-DD")));
    dispatch(
      actionExpenseListRequest(date_from.format("YYYY-MM-DD"), date_to.format("YYYY-MM-DD")),
    );
  };

  const onShowExpense = (expense_id: string): void => {
    setCurrentExpense(expenseList.find(e => e.id == expense_id));
  };

  const menu = (
    <Menu
      items={[
        canCreateTravel
          ? {
              key: "1",
              label: <UIText onClick={() => setCreateTravelVisible(true)}>Aggiungi Viaggio</UIText>,
            }
          : null,
        canCreateExpense
          ? {
              key: "2",
              label: (
                <UIText onClick={() => setCreateExpenseVisible(true)}>Aggiungi Nota Spese</UIText>
              ),
            }
          : null,
      ]}
    />
  );

  return (
    <>
      <ExportTravelAndExpenseModal
        visible={showExportTravelAndExpenseModal}
        onCancel={() => setShowExportTravelAndExpenseModal(false)}
        onExport={obj => {
          apiClient
            .post(API_PARAMS.BASE_URL + "/expenses_export_pdf", obj, {
              responseType: "blob",
            })
            .then(res => {
              //@ts-ignore
              const url = URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
              const link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              link.setAttribute("target", "_blank");
              link.setAttribute(
                "download",
                "Export Nota Spese " + `${obj.date_from}` + " - " + `${obj.date_to}` + ".pdf",
              );
              document.body.appendChild(link);
              link.click();
            });
        }}
      />
      <UITransition>
        <Row justify="center">
          <Col span={20}>
            <UIMonthSelector onChange={onMonthChange} selectedRange={selectedRange} />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col style={{ display: "inline-flex", alignItems: "baseline" }}>
            <UITitle level={2} color={BaseColor} data-cy="page-title">
              Viaggi
            </UITitle>
            <UICheckbox
              onChange={onChangeShowArchived}
              value={showArchived}
              style={{ marginLeft: "10px" }}
            >
              Mostra archiviati
            </UICheckbox>
          </Col>
          {(canCreateTravel || canCreateExpense) && (
            <Col>
              <UIButton
                type="primary"
                onClick={() => setShowExportTravelAndExpenseModal(true)}
                style={{ marginRight: "5px" }}
              >
                Esporta
              </UIButton>
              <Dropdown overlay={menu} placement="bottomLeft">
                <UIButton type="primary">+ Nuovo</UIButton>
              </Dropdown>
            </Col>
          )}
        </Row>
        {!!travelListLoading || !!expenseListLoading ? (
          <UICard style={{ marginBottom: "20px", border: "none" }}>
            <Skeleton active />
          </UICard>
        ) : (
          <UICard>
            {!filteredTravelList.length && (
              <Row>
                <Col
                  style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "7px",
                    padding: "50px",
                    textAlign: "center",
                  }}
                  span={24}
                >
                  <UITitle level={4}>Nessun viaggio in programma questo mese</UITitle>
                </Col>
              </Row>
            )}
            {filteredTravelList.length ? (
              filteredTravelList.map(el => (
                <CustomCollapse accordion key={el.id} style={{ marginBottom: "10px" }}>
                  <Panel
                    header={`${el.name}${el.description ? " - " + el.description : ""} - ${moment(
                      el.date_from,
                    ).format("DD/MM/YYYY")} - ${moment(el.date_to).format("DD/MM/YYYY")} (${
                      el.expenses_count
                    })`}
                    key={el.id}
                  >
                    <List
                      itemLayout="horizontal"
                      locale={{
                        emptyText: "Nessuna nota spese",
                      }}
                      dataSource={formattedExpensesList.filter(e => el.expenses.includes(e.id))}
                      renderItem={item => (
                        <CustomListItem onClick={() => onShowExpense(item.id)}>
                          <Row>
                            <Col style={{ marginRight: "5px" }}>{item.avatar}</Col>
                            <Col>
                              <Row>
                                <Col>
                                  <strong>{item.title}</strong> - {item.user.surname}{" "}
                                  {item.user.name}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <span style={{ color: "rgb(142 143 148)", fontSize: "14px" }}>
                                    {item.description}
                                  </span>
                                  <span style={{ fontSize: "13px" }}>{item.status}</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CustomListItem>
                      )}
                    />
                    <CustomReportItem>
                      <Row style={{ width: "100%", justifyContent: "space-evenly" }}>
                        <Col>
                          <Row justify="center">
                            <Col>
                              <strong>Resoconto Viaggio</strong>
                            </Col>
                          </Row>
                          <Row justify="center">
                            <Col>
                              <span
                                style={{
                                  color: "rgb(142 143 148)",
                                  fontSize: "16px",
                                  marginRight: "5px",
                                }}
                              >
                                Totale richiesto:
                              </span>
                              <span style={{ fontSize: "16px" }}>
                                {el.rimborso_totale_richiesto}€
                              </span>
                            </Col>
                          </Row>
                          <Row justify="center">
                            <Col>
                              <span
                                style={{
                                  color: "rgb(142 143 148)",
                                  fontSize: "16px",
                                  marginRight: "5px",
                                }}
                              >
                                Totale approvato:
                              </span>
                              <span style={{ fontSize: "16px" }}>
                                {el.rimborso_totale_approvato}€
                              </span>
                            </Col>
                          </Row>
                          <Row justify="center">
                            <Col>
                              <span style={{ color: "rgb(142 143 148)", fontSize: "16px" }}>
                                Stato del viaggio:
                              </span>
                              <span style={{ fontSize: "16px" }}>
                                {el.status == "Pendente" ? (
                                  <span>
                                    <ClockCircleOutlined
                                      style={{ color: "gray", marginLeft: "5px" }}
                                    />{" "}
                                    {el.status}
                                  </span>
                                ) : el.status == "Completato" ? (
                                  <span>
                                    <CheckCircleOutlined
                                      style={{ color: "green", marginLeft: "5px" }}
                                    />{" "}
                                    {el.status}
                                  </span>
                                ) : el.status == "Aperto" ? (
                                  <span>
                                    <CarOutlined style={{ color: "green", marginLeft: "5px" }} />{" "}
                                    {el.status}
                                  </span>
                                ) : (
                                  <span>
                                    <StopOutlined style={{ color: "red", marginLeft: "5px" }} />{" "}
                                    {el.status}
                                  </span>
                                )}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row justify="center">
                            <Col>
                              <strong>Gestisci stato del viaggio</strong>
                            </Col>
                          </Row>
                          <Row justify="center">
                            <Col style={{ margin: "2px" }}>
                              {!canCompleteTravel(el.id) ? (
                                <Tooltip title="Prima  di completare un viaggio verifica che tutte le note spesa siano accettate, o il viaggio non sia già completato">
                                  <UIButton size="large" disabled={!canCompleteTravel(el.id)}>
                                    <CheckCircleOutlined style={{ color: "green" }} />
                                    Completa
                                  </UIButton>
                                </Tooltip>
                              ) : (
                                <Popconfirm
                                  overlayInnerStyle={{ borderRadius: "7px", padding: "10px" }}
                                  title="Sei sicuro di voler completare il viaggio?"
                                  onConfirm={() => onCompleteTravel(el.id)}
                                  okText="Si"
                                  cancelText="No"
                                  okButtonProps={{
                                    style: {
                                      borderRadius: "7px",
                                      padding: "0px 10px",
                                      fontSize: "15",
                                    },
                                  }}
                                  cancelButtonProps={{
                                    style: {
                                      borderRadius: "7px",
                                      padding: "0px 10px",
                                      fontSize: "15",
                                    },
                                  }}
                                  icon={<></>}
                                >
                                  <UIButton size="large">
                                    <CheckCircleOutlined style={{ color: "green" }} />
                                    Completa
                                  </UIButton>
                                </Popconfirm>
                              )}
                            </Col>
                            <Col style={{ margin: "2px" }}>
                              {!canArchiveTravel(el.id) ? (
                                <Tooltip title="Prima  di archiviare un viaggio verifica che tutte le note spesa siano accettate, o il viaggio non sia già archiviato">
                                  <UIButton size="large" disabled={!canArchiveTravel(el.id)}>
                                    <FolderOpenOutlined style={{ color: "brown" }} />
                                    Archivia
                                  </UIButton>
                                </Tooltip>
                              ) : (
                                <Popconfirm
                                  overlayInnerStyle={{ borderRadius: "7px", padding: "10px" }}
                                  title="Sei sicuro di voler archiviare il viaggio?"
                                  onConfirm={() => onArchiveTravel(el.id)}
                                  okText="Si"
                                  cancelText="No"
                                  okButtonProps={{
                                    style: {
                                      borderRadius: "7px",
                                      padding: "0px 10px",
                                      fontSize: "15",
                                    },
                                  }}
                                  cancelButtonProps={{
                                    style: {
                                      borderRadius: "7px",
                                      padding: "0px 10px",
                                      fontSize: "15",
                                    },
                                  }}
                                >
                                  <UIButton size="large" disabled={!canArchiveTravel(el.id)}>
                                    <FolderOpenOutlined style={{ color: "brown" }} />
                                    Archivia
                                  </UIButton>
                                </Popconfirm>
                              )}
                            </Col>
                            {canDeleteTravel(el.id) && (
                              <Col style={{ margin: "2px" }}>
                                <Popconfirm
                                  overlayInnerStyle={{ borderRadius: "7px", padding: "10px" }}
                                  title="Sei sicuro di voler eliminare il viaggio?"
                                  onConfirm={() => onDeleteTravel(el.id)}
                                  okText="Si"
                                  cancelText="No"
                                  okButtonProps={{
                                    style: {
                                      borderRadius: "7px",
                                      padding: "0px 10px",
                                      fontSize: "15",
                                    },
                                  }}
                                  cancelButtonProps={{
                                    style: {
                                      borderRadius: "7px",
                                      padding: "0px 10px",
                                      fontSize: "15",
                                    },
                                  }}
                                >
                                  <UIButton
                                    size="large"
                                    style={{
                                      borderRadius: "7px",
                                      backgroundColor: "#e00102",
                                      borderColor: "#ac0000",
                                      color: "white",
                                    }}
                                  >
                                    <DeleteOutlined style={{ color: "white" }} />
                                    Elimina
                                  </UIButton>
                                </Popconfirm>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col>
                          <Row justify="center">
                            <Col>
                              <strong>Partecipanti</strong>
                            </Col>
                          </Row>
                          <Row justify="center" style={{ maxWidth: "300px" }}>
                            {el.users.map((u, i) => (
                              <Col key={i}>
                                <CustomUserItem key={i} user={u} />
                                {el.users.length - 1 !== i && <>,</>}
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </CustomReportItem>
                  </Panel>
                </CustomCollapse>
              ))
            ) : (
              <></>
            )}
          </UICard>
        )}
        {expenseList.filter(e => e.travel_id === null).length > 0 && (
          <>
            <Row
              justify="space-between"
              align="middle"
              gutter={[16, 16]}
              style={{ marginTop: "15px" }}
            >
              <Col>
                <UITitle level={2} color={BaseColor} data-cy="page-title">
                  Altre Note spesa
                </UITitle>
              </Col>
            </Row>
            {!!travelListLoading || !!expenseListLoading ? (
              <UICard style={{ marginBottom: "20px", border: "none" }}>
                <Skeleton active />
              </UICard>
            ) : (
              <UICard>
                <List
                  itemLayout="horizontal"
                  dataSource={formattedExpensesList.filter(e => !!e.notravel)}
                  renderItem={item => (
                    <CustomListItem onClick={() => onShowExpense(item.id)}>
                      <Row>
                        <Col style={{ marginRight: "5px" }}>{item.avatar}</Col>
                        <Col>
                          <Row>
                            <Col>
                              <strong>{item.title}</strong> - {item.user.surname} {item.user.name}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <span style={{ color: "rgb(142 143 148)", fontSize: "14px" }}>
                                {item.description}
                              </span>{" "}
                              <span style={{ fontSize: "13px" }}>{item.status}</span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CustomListItem>
                  )}
                />
              </UICard>
            )}
          </>
        )}
      </UITransition>
      <TravelCreateDrawer visible={createTravelVisible} onClose={onCloseTravelCreate} />
      <ExpenseCreateDrawer
        visible={createExpenseVisible}
        onClose={onCloseExpenseCreate}
        travels={travelList}
      />
      {!!currentExpense && (
        <ExpenseShowDrawer
          visible={showExpenseVisible}
          onClose={onCloseExpenseShow}
          travels={travelList}
          expense={
            currentExpense ? { ...currentExpense, date: moment(currentExpense.date) } : undefined
          }
        />
      )}
      {!!currentExpense && (
        <ExpenseUserShowDrawer
          visible={showUserExpenseVisible}
          onClose={onCloseExpenseUserShow}
          travels={travelList}
          expense={
            currentExpense ? { ...currentExpense, date: moment(currentExpense.date) } : undefined
          }
        />
      )}
    </>
  );
};

const CustomCollapse = styled(Collapse)`
  border-radius: 7px;
  //background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.20"}!important;
  background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.25"}) !important;

  .ant-collapse-item:not(.ant-collapse-item-active):last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: none;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse > .ant-collapse-item-active:last-child {
    border-radius: none;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse-item-active:last-child,
  .ant-collapse-item-active:last-child > .ant-collapse-header {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse-content {
    max-height: 70vh;
    overflow-y: auto;
  }
  .ant-list-bordered {
    border: none;
  }
`;

const CustomListItem = styled(List.Item)`
  cursor: pointer;
  padding: 10px;
  border-radius: 7px;

  &:hover {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.15"}) !important;
  }
`;

const CustomReportItem = styled(List.Item)`
  padding: 10px;
  border-radius: 7px;
  border-top: 1px solid #f0f0f0;
`;
