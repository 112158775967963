/** @format */

import { Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import {
  DataEditorComponent,
  Matrix,
  Point,
  Dimensions,
  Spreadsheet,
  CellComponentProps,
} from "react-spreadsheet";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../constants/api";
import { BaseColor } from "../../../theme/maps/Colors";
import apiClient from "../../../utils/apiClient";
import { Routes } from "../../../utils/Routes";
import { showNotification } from "../../../utils/showNotification";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import UIModal from "../../ui/Modal";
import { UIText } from "../../ui/Text";
import { UITitle } from "../../ui/Title";

const columnLabels = [
  "Nome",
  "Cognome",
  "Email",
  "Codice fiscale",
  "Gruppo",
  "Ruolo",
  "H. Giornaliere",
  "H. Settimanali",
  "GG. Settimanali",
  "E' il Manager?",
  "Cod. Azienda TS",
  "Cod. Filiale TS",
  "Cod. Matricola TS",
];

export const ViewInitialConfig = () => {
  const history = useHistory();
  const [roles, setRoles] = useState<string[]>(["Amministratore", "Manager", "Utente"]);
  const [askingForSecret, setAskingForSecret] = useState(false);
  const [secret, setSecret] = useState("");
  const [configLoading, setConfigLoading] = useState(false);

  const [data, setData] = useState<Matrix<{ value: string }>>([]);

  useEffect(() => {
    if (data.length > 0)
      localStorage.setItem(window.location.hostname + "_data", JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.SETUP_CHECK).then(res => {
      if (res.data.is_configured) {
        history.push(Routes.login);
      } else {
        let tempData = JSON.parse(
          localStorage?.getItem(window.location.hostname + "_data") || "[]",
        );

        if (res.data.roles)
          // @ts-ignore
          setRoles(Object.values(res.data.roles).map((role: { name: string }) => role.name));
        if (res.data.licenses_number) {
          // @ts-ignore
          if (tempData.filter(e => !!e).length) {
            const validData = tempData
              // @ts-ignore
              .filter(e => !!e)
              // @ts-ignore
              .map(el => el.map(e => (!!e ? e : { value: "" })));
            setData([
              ...validData,
              ...Array(parseInt(res.data.licenses_number) - validData.length).map(e =>
                Array(13).fill({ value: "" }),
              ),
            ]);
          } else {
            setData([
              [
                { value: "Mario" },
                { value: "Rossi" },
                { value: "esempio@hrsemplice.it" },
                { value: "ABCDEF90A10G273H" },
                { value: "Amministrazione" },
                { value: "Amministratore" },
                { value: "8" },
                { value: "40" },
                { value: "5" },
                { value: "Si" },
                { value: "1" },
                { value: "1" },
                { value: "1" },
              ],
              [
                { value: "Carlo" },
                { value: "Bianchi" },
                { value: "esempio2@hrsemplice.it" },
                { value: "QWERTY90A10G273H" },
                { value: "Amministrazione" },
                { value: "Utente" },
                { value: "8" },
                { value: "40" },
                { value: "5" },
                { value: "No" },
                { value: "1" },
                { value: "1" },
                { value: "2" },
              ],
              [
                { value: "Andrea" },
                { value: "Verdi" },
                { value: "esempio3@hrsemplice.it" },
                { value: "ZXCVBN90A10G273H" },
                { value: "Centralinisti" },
                { value: "Manager" },
                { value: "8" },
                { value: "40" },
                { value: "5" },
                { value: "Si" },
                { value: "1" },
                { value: "1" },
                { value: "3" },
              ],
              [
                { value: "Marco" },
                { value: "Gialli" },
                { value: "esempio4@hrsemplice.it" },
                { value: "MNBVCX0A10G273H" },
                { value: "Centralinisti" },
                { value: "Utente" },
                { value: "8" },
                { value: "40" },
                { value: "5" },
                { value: "No" },
                { value: "1" },
                { value: "1" },
                { value: "4" },
              ],
              ...Array(parseInt(res.data.licenses_number) - 4).map(e =>
                Array(13).fill({ value: "" }),
              ),
            ]);
          }
        }
      }
    });
  }, []);

  return (
    <>
      <Row justify="center" gutter={[16, 16]}>
        <Col span={24}>
          <UICard
            data-cy="login-card"
            style={{
              boxShadow: "-1px 1px 7px #454545",
            }}
          >
            <span style={{ display: "none" }} id="roles" data-roles={JSON.stringify(roles)}></span>
            <Row justify="center" gutter={[16, 16]} style={{ marginBottom: 15 }}>
              <Col>
                <UITitle level={3} color={BaseColor}>
                  Prima Configurazione HrSemplice
                </UITitle>
              </Col>
            </Row>
            <Row justify="center" gutter={[16, 16]} style={{ marginBottom: 15 }}>
              <CustomCol span={24}>
                <CustomSpreadsheet
                  data={data}
                  onChange={setData}
                  columnLabels={columnLabels}
                  //@ts-ignore
                  DataEditor={x => <DataEditor {...x} />}
                  Cell={Cell}
                />
              </CustomCol>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <UIButton type="primary" onClick={() => setAskingForSecret(true)}>
                  Configura piattaforma
                </UIButton>
              </Col>
            </Row>
          </UICard>
        </Col>
      </Row>
      {askingForSecret && (
        <UIModal
          title="Inserisci la password di configurazione"
          visible={askingForSecret}
          onCancel={() => setAskingForSecret(false)}
          okButtonProps={{ loading: configLoading }}
          onOk={() => {
            setConfigLoading(true);
            apiClient
              .post(API_ENDPOINTS.SETUP, {
                secret,
                data: formatData(data.filter(x => typeof x !== "undefined")),
              })
              .then(res => {
                setConfigLoading(false);
                setAskingForSecret(false);
                if (res.data) {
                  history.push(Routes.login);
                  localStorage.removeItem(window.location.hostname + "_data");
                  showNotification(
                    "success",
                    "Configurazione completata",
                    "La configurazione è stata completata con successo, controlla la tua casella di posta elettronica per accedere alla piattaforma",
                  );
                }
              })
              .catch(err => {
                showNotification("error", "Errore", err.response.data.message);
                setConfigLoading(false);
              });
          }}
        >
          <UIText>
            Inserisci la password che è stata inviata al tuo indirizzo e-mail per completare la
            procedura di configurazione.{" "}
          </UIText>
          <UIInput value={secret} onChange={e => setSecret(e.target.value)} />
        </UIModal>
      )}
    </>
  );
};

export function moveCursorToEnd(el: HTMLInputElement): void {
  el.selectionStart = el.selectionEnd = el.value.length;
}

export function getOffsetRect(element: HTMLElement): Dimensions {
  return {
    width: element.offsetWidth,
    height: element.offsetHeight,
    left: element.offsetLeft,
    top: element.offsetTop,
  };
}

export const Cell: React.FC<CellComponentProps> = ({
  row,
  column,
  DataViewer,
  formulaParser,
  selected,
  active,
  dragging,
  mode,
  data,
  select,
  activate,
  setCellDimensions,
  setCellData,
}): React.ReactElement => {
  const rootRef = React.useRef<HTMLTableCellElement | null>(null);
  const point = React.useMemo(
    (): Point => ({
      row,
      column,
    }),
    [row, column],
  );

  const handleMouseDown = React.useCallback(
    (event: React.MouseEvent<HTMLTableCellElement>) => {
      if (mode === "view") {
        setCellDimensions(point, getOffsetRect(event.currentTarget));

        if (event.shiftKey) {
          select(point);
        } else {
          activate(point);
        }
      }
    },
    [mode, setCellDimensions, point, select, activate],
  );

  const handleMouseOver = React.useCallback(
    (event: React.MouseEvent<HTMLTableCellElement>) => {
      if (dragging) {
        setCellDimensions(point, getOffsetRect(event.currentTarget));
        select(point);
      }
    },
    [setCellDimensions, select, dragging, point],
  );

  React.useEffect(() => {
    const root = rootRef.current;
    if (selected && root) {
      setCellDimensions(point, getOffsetRect(root));
    }
    if (root && active && mode === "view") {
      root.focus();
    }
  }, [setCellDimensions, selected, active, mode, point, data]);

  if (data && data.DataViewer) {
    // @ts-ignore
    DataViewer = data.DataViewer;
  }

  return (
    <td
      ref={rootRef}
      className={"Spreadsheet__cell"}
      onMouseOver={handleMouseOver}
      onMouseDown={handleMouseDown}
      tabIndex={0}
    >
      <DataViewer
        row={row}
        column={column}
        cell={data}
        formulaParser={formulaParser}
        setCellData={setCellData}
      />
    </td>
  );
};

const DataEditor: DataEditorComponent<{ value: string }> = ({ onChange, cell, column }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const selectRolesRef = React.useRef<HTMLSelectElement>(null);
  const selectIsManagerRef = React.useRef<HTMLSelectElement>(null);
  const [roles, setRoles] = useState<string[]>([]);

  const [currentInput, setCurrentInput] = useState<"def" | "roles" | "manager">("def");

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      // @ts-ignore
      onChange({ ...cell, value: event.target.value });
    },
    [onChange, cell],
  );

  React.useEffect(() => {
    if (inputRef.current) {
      moveCursorToEnd(inputRef.current);
    }
  }, [inputRef]);

  const value = cell?.value ?? "";

  useEffect(() => {
    setRoles(JSON.parse(document.getElementById("roles")?.dataset.roles || "[]"));
    if (column == 5) {
      setCurrentInput("roles");
    } else if (column == 9) {
      setCurrentInput("manager");
    } else {
      setCurrentInput("def");
    }
  }, []);

  return (
    <div className="Spreadsheet__data-editor">
      {currentInput == "def" && (
        <input ref={inputRef} type="text" onChange={handleChange} value={value} autoFocus />
      )}
      {currentInput == "roles" && (
        <CustomSelect
          ref={selectRolesRef}
          onChange={handleChange}
          style={{ width: "100%" }}
          value={value}
          autoFocus
        >
          {roles.map((role, index) => (
            <option key={index} value={role}>
              {role}
            </option>
          ))}
        </CustomSelect>
      )}
      {currentInput == "manager" && (
        <CustomSelect ref={selectIsManagerRef} onChange={handleChange} value={value} autoFocus>
          <option value="" disabled>
            - S/N -
          </option>
          <option value="Si">Si</option>
          <option value="No">No</option>
        </CustomSelect>
      )}
    </div>
  );
};

const CustomSpreadsheet = styled(Spreadsheet)`
  .Spreadsheet {
    width: 100%;
  }
  .Spreadsheet__table {
    width: 100%;
  }
`;

const CustomCol = styled(Col)`
  max-height: 510px;
  overflow-y: auto;
`;

const CustomSelect = styled.select`
  &::focus {
    boder: 0;
    outline: 0;
  }
  &::active {
    boder: 0;
    outline: 0;
  }
  boder: 0;
  outline: 0;
`;

const formatData = (data: Matrix<{ value: string }>) => {
  const formattedData = data.map(row => {
    return {
      ...row.map((cell, i) => {
        return cell?.value;
      }),
    };
  });

  return formattedData;
};
