/** @format */

import { useEffect, useState } from "react";

type Return = [boolean, (value: boolean) => void];

export const useAlert = (timeout: number): Return => {
  const [visible, setVisible] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState<number | null>(null);

  useEffect(() => {
    if (currentTimeout) clearTimeout(currentTimeout);
    if (visible) {
      const newTimeout = setTimeout(() => {
        setVisible(false);
      }, timeout);
      // @ts-ignore
      setCurrentTimeout(newTimeout);
    }
  }, [visible]);

  return [visible, setVisible];
};
