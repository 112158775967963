/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TTravelCreateRequest,
  TTravelUpdateRequest,
} from "../redux-modules/declarations/maps/travels";
import apiClient from "../utils/apiClient";

export const apiTravelList = (payload: {
  date_from: string;
  date_to: string;
}): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.TRAVEL_LIST + `/${payload.date_from}/${payload.date_to}`);
};

export const apiTravelCreate = (travel: TTravelCreateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.post(API_ENDPOINTS.TRAVELS, travel);

export const apiTravelUpdate = (travel: TTravelUpdateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.TRAVELS + "/" + travel.id, travel);

export const apiTravelDelete = (travelId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.TRAVELS + "/" + travelId);
