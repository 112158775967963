/** @format */

import {
  TActionTriggerListRequest,
  TActionTriggerListSuccess,
  TActionTriggerListFailure,
  TActionTriggerListLoadingRequest,
  TActionTriggerListLoadingSuccess,
  TActionTriggerListLoadingFailure,
  TActionTriggerUpdateRequest,
  TActionTriggerUpdateSuccess,
  TActionTriggerUpdateFailure,
  TActionTriggerUpdateReset,
} from "../declarations/actions/trigger";
import { TAction, TActionType } from "../declarations/actionTypes";
import { TAPITriggerListRequest } from "../declarations/apis/trigger";
import { TTrigger, TTriggerUpdateRequest } from "../declarations/maps/triggers";

//List
export const actionTriggerListRequest = (
  payload: TAPITriggerListRequest,
): TActionTriggerListRequest => ({
  type: TActionType.triggerListRequest,
  payload,
});

export const actionTriggerListSuccess = (payload: TTrigger[]): TActionTriggerListSuccess => ({
  type: TActionType.triggerListSuccess,
  payload,
});

export const actionTriggerListFailure = (): TActionTriggerListFailure => ({
  type: TActionType.triggerListFailure,
});

//---

//Loading

export const actionTriggerListLoadingRequest = (): TActionTriggerListLoadingRequest => ({
  type: TActionType.triggerListLoadingRequest,
});

export const actionTriggerListLoadingSuccess = (): TActionTriggerListLoadingSuccess => ({
  type: TActionType.triggerListLoadingSuccess,
});

export const actionTriggerListLoadingFailure = (): TActionTriggerListLoadingFailure => ({
  type: TActionType.triggerListLoadingFailure,
});

// ---

// Update
export const actionTriggerUpdateRequest = (
  payload: TTriggerUpdateRequest,
): TActionTriggerUpdateRequest => ({
  type: TActionType.triggerUpdateRequest,
  payload,
});

export const actionTriggerUpdateSuccess = (): TActionTriggerUpdateSuccess => ({
  type: TActionType.triggerUpdateSuccess,
});

export const actionTriggerUpdateFailure = (): TActionTriggerUpdateFailure => ({
  type: TActionType.triggerUpdateFailure,
});

export const actionTriggerUpdateReset = (): TActionTriggerUpdateReset => ({
  type: TActionType.triggerUpdateReset,
});

// ---
