/** @format */

import { Row, Col, List, Avatar, Tooltip, Empty } from "antd";
import React, { CSSProperties, ReactChild } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectorLoggedUser } from "../../../../redux-modules/selectors/selectorsAuth";
import { selectorRecentPersonalDocuments } from "../../../../redux-modules/selectors/selectorsPersonalDocuments";
import { BaseColor } from "../../../../theme/maps/Colors";
import { Routes } from "../../../../utils/Routes";
import { hexToRgb } from "../../../../utils/styles";
import { UICard } from "../../../ui/Card";
import { TTheme } from "../../../ui/Finder";
import { FileComponent } from "../../../ui/Finder/partials/FileComponent";
import { UILink } from "../../../ui/Link";
import { UITitle } from "../../../ui/Title";
import docImageThemeAzure from "../../../../assets/svg/file_theme1.svg";
import docImageThemeMinimal from "../../../../assets/svg/file_theme2.svg";
import moment from "moment";
import { CloseCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import { UIText } from "../../../ui/Text";
import { TStore } from "../../../../redux-modules/declarations/store";
import { API_ENDPOINTS } from "../../../../constants/api";
import apiClient from "../../../../utils/apiClient";
import UITransition from "../../../ui/Transition";
import { isMobileOnly } from "react-device-detect";
import { showNotification } from "../../../../utils/showNotification";

export const WidgetRecentDocuments = ({
  theme,
  mode = "list",
}: {
  theme: TTheme;
  mode?: "grid" | "list";
}): JSX.Element => {
  const loggedUser = useSelector(selectorLoggedUser);
  const recentFiles = useSelector((store: TStore) =>
    selectorRecentPersonalDocuments(store, mode === "grid" ? 5 : 3),
  );
  if (!loggedUser) return <></>;

  const downloadFile = (id : string, filename: string) => {
    console.log("download");
    apiClient
      .get(API_ENDPOINTS.AUTH_DOCUMENT.replace(":document_id", id), {responseType: "blob",})
      .then(res => {
        console.log(res);
        let url = window.URL.createObjectURL(
          new Blob([res.data]),
        );
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          filename,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        if(link.parentNode != null){
          link.parentNode!.removeChild(link);
        }
      })
      .catch(e => {
        showNotification(
          "error",
          "Oops..",
          //@ts-ignore
          e.response.data.message ?? Object.values(e.response.data.errors).join(`\n`),
        );
      });
  };

  return (
    <>
      <WidgetCard
        title="Documenti Recenti"
        goToLabel="I miei documenti"
        goToRoute={Routes.usersEditDocuments.replace(":id_user", `${loggedUser.id}`)}
      >
        <>
          {mode === "grid" && (
            <StyledDiv>
              {recentFiles.map(file => (
                <FileComponent
                  theme={theme}
                  file={file}
                  showExpiring={true}
                  personalDocumentUserId={loggedUser.id}
                  size="small"
                />
              ))}
            </StyledDiv>
          )}
          {mode === "list" && (
            <List
              style={{ minWidth: "100%" }}
              itemLayout="horizontal"
              dataSource={recentFiles}
              locale={{ emptyText: <Empty description="Nessun documento recente" /> }}
              renderItem={(item): JSX.Element => (
                <List.Item style={{ display: "block", padding: "4px 0" }}>
                  <Row>
                    <Col span={12}>
                      <List.Item.Meta
                        style={{ display: "inline-flex", margin: "0 10px" }}
                        avatar={
                          <Avatar
                            shape="square"
                            src={theme === "azure" ? docImageThemeAzure : docImageThemeMinimal}
                          />
                        }
                        title={<span style={{ whiteSpace: "nowrap" }}>{item.filename}</span>}
                        description={item.document_type_name}
                      />
                    </Col>
                    <Col span={12}>
                      <>
                        <Row
                          gutter={[16, 16]}
                          justify="end"
                          style={{ height: isMobileOnly ? "100%" : "inherit" }}
                        >
                          <Col
                            style={{
                              height: isMobileOnly ? "100%" : "inherit",
                              display: isMobileOnly ? "flex" : "inherit",
                              alignItems: isMobileOnly ? "end" : "inherit",
                            }}
                          >
                            {moment(item.expiring_at) < moment() && (
                              <Tooltip
                                title={
                                  "Scaduto il " + moment(item.expiring_at).format("DD/MM/YYYY")
                                }
                              >
                                <CloseCircleTwoTone
                                  twoToneColor="#ff0000"
                                  style={{
                                    fontSize: "17px",
                                    margin: isMobileOnly ? "0px 15px" : "15px",
                                  }}
                                />
                              </Tooltip>
                            )}

                            {moment(item.expiring_at) >= moment() &&
                              moment(item.expiring_at) <= moment().add(7, "days") && (
                                <Tooltip
                                  title={
                                    "Scade il " + moment(item.expiring_at).format("DD/MM/YYYY")
                                  }
                                >
                                  <CheckCircleTwoTone
                                    twoToneColor="#cc6701"
                                    style={{
                                      fontSize: "17px",
                                      margin: isMobileOnly ? "0px 15px" : "15px",
                                    }}
                                  />
                                </Tooltip>
                              )}

                            {(!item.expiring_at ||
                              moment(item.expiring_at) > moment().add(7, "days")) && (
                              <Tooltip
                                title={
                                  item.expiring_at
                                    ? "Scade il " + moment(item.expiring_at).format("DD/MM/YYYY")
                                    : "Nessuna scadenza"
                                }
                              >
                                <CheckCircleTwoTone
                                  twoToneColor="#00bc00"
                                  style={{
                                    fontSize: "17px",
                                    margin: isMobileOnly ? "0px 15px" : "15px",
                                  }}
                                />
                              </Tooltip>
                            )}
                            <span
                              style={{
                                margin: isMobileOnly ? "0px 10px -1px 0px" : "10px 10px 10px 20px",
                                cursor: "pointer",
                              }}
                              onClick={(): void => {
                                apiClient.post(
                                  API_ENDPOINTS.PERSONAL_DOCUMENTS_READ.replace(
                                    ":id_document",
                                    item.id,
                                  ).replace(":id_user", loggedUser.id),
                                );
                                
                                downloadFile(item.id, item.filename);
                              }}
                            >
                              <UIText color={`${process.env.REACT_APP_THEME_COLOR}`}>
                                Scarica
                              </UIText>
                            </span>
                          </Col>
                        </Row>
                      </>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          )}
        </>
      </WidgetCard>
    </>
  );
};

export const WidgetCard = ({
  title,
  goToRoute,
  goToLabel,
  children,
  style,
  customTitleContent,
}: {
  title: string;
  goToLabel?: string;
  goToRoute?: string;
  children: ReactChild;
  style?: CSSProperties;
  customTitleContent?: ReactChild | ReactChild[];
}): JSX.Element => {
  return (
    <UITransition>
      <UICard style={{ ...style, boxShadow: "-2px 2px 7px #e8e8e8" }}>
        <Row justify="space-between">
          <Col>
            <UITitle level={3} color={BaseColor} data-cy="page-title">
              {title}
            </UITitle>
          </Col>
          {customTitleContent && customTitleContent}
          {!isMobileOnly && goToLabel && goToRoute && (
            <Col style={{ paddingTop: "10px" }}>
              <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                <UILink route={`${goToRoute}`} label={`${goToLabel}`} />
              </span>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>{children}</Col>
        </Row>
      </UICard>
    </UITransition>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  // background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.07"});
  border-radius: 7px;
  min-height: 140px;
  padding-top: 10px;
`;
