/** @format */

import React, { FC } from "react";
import { Menu } from "antd";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import { UIText } from "../Text";
import { useHistory } from "react-router";
import { UILink } from "../Link";
import { Routes } from "../../../utils/Routes";

interface Props extends MenuItemProps {
  to: Routes;
  title: string;
}

export const UINavLinkItem: FC<Props> = ({ ...props }: Props): JSX.Element => {
  const history = useHistory();

  return (
    <UILink
      route={props.to}
      label={
        <Menu.Item {...props}>
          <UIText>{props.title}</UIText>
        </Menu.Item>
      }
    />
  );
};
