/** @format */

import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Form, Row, Select, Skeleton } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UISelect } from "../../ui/Select";
import { API_ENDPOINTS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import { utilityFormatFindList } from "../../../utils/format/apiGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorGroupDeleted,
  selectorGroupUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import {
  actionGroupDeleteRequest,
  actionGroupUpdateRequest,
} from "../../../redux-modules/actions/actionsGroups";
import { TGroup } from "../../../redux-modules/declarations/maps/groups";
import { DeleteCard } from "../../layout/DeleteCard";
import { TActionGroupDeleteRequest } from "../../../redux-modules/declarations/actions/groups";
import { UILink } from "../../ui/Link";
import { useForm } from "antd/es/form/Form";
import { TUser } from "../../../redux-modules/declarations/maps/auth";
import { showNotification } from "../../../utils/showNotification";
const { Option } = Select;

export const ViewGroupEdit = (): JSX.Element => {
  //@ts-ignore
  const { id_group } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = useForm();
  const [groupInfo, setGroupInfo] = useState<TGroup>();
  const [userList, setUserList] = useState<TUser[]>([]);
  const [managersList, setManagersList] = useState<TUser[]>([]);
  const isGroupUpdated = useSelector(selectorGroupUpdated);
  const isGroupDeleted = useSelector(selectorGroupDeleted);

  const removeGroup = (): TActionGroupDeleteRequest => dispatch(actionGroupDeleteRequest(id_group));

  useEffect(() => {
    if (isGroupUpdated || isGroupDeleted) history.push(Routes.groups);
  }, [isGroupUpdated, isGroupDeleted]);

  //@ts-ignore
  const handleUpdate = (e): void => {
    const contentObj = {
      id: id_group,
      name: e.name,
      manager_id: e.manager,
      vicemanager_id: e.vicemanager,
      users: e.users,
    };
    //dispatch
    dispatch(actionGroupUpdateRequest(contentObj));
  };

  //@ts-ignore
  const handleChangeValue = (id, user): void => {
    const userRemoved = userList.find(item => item.id == user.value);
    const otherGroups = userRemoved?.groups.filter(group => group.id != id_group);
    const canRemove = !otherGroups || otherGroups.length > 0;
    if (!canRemove) {
      form.setFieldsValue({
        users: [id, ...form.getFieldValue("users")],
      });

      showNotification("warning", "Attenzione", "Questo utente non può rimanere senza gruppo");
    }
  };
  useEffect(() => {
    apiClient.get(API_ENDPOINTS.USERS_LIST + "?all=true").then(res => {
      setUserList(res.data.data);
    });

    apiClient.get(API_ENDPOINTS.USERS_LIST + "?managers=true").then(res => {
      setManagersList(res.data.data);
    });

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      const response = await apiClient.get(API_ENDPOINTS.GROUPS + "/" + id_group);
      //@ts-ignore
      setGroupInfo(utilityFormatFindList(response.data.data));
    };
    fetchData();
  }, []);

  return !!!groupInfo || !managersList || !userList.length ? (
    <UICard style={{ marginBottom: "20px" }}>
      <Skeleton active />
    </UICard>
  ) : (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <Row>
            <Col>
              <UITitle level={2} color={BaseColor} data-cy="page-title">
                Modifica Gruppo
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <UILink route={Routes.groups} label={"Gruppi"} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>{groupInfo ? groupInfo.name : ""}</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
      </Row>
      <Form
        style={{ marginTop: "15px" }}
        name="update-group"
        data-cy="update-group-form"
        className="update-group"
        onFinish={handleUpdate}
        initialValues={groupInfo}
        form={form}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: "Inserisci un nome!" }]}
                    extra="Il nome del gruppo"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="manager"
                    label="Manager"
                    rules={[{ required: true, message: "Seleziona almeno un utente!" }]}
                    extra={`Il manager del gruppo`}
                  >
                    <UISelect
                      showSearch
                      filterOption={(input, option): boolean =>
                        ((option!.children as unknown) as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {managersList.map(
                        (user): JSX.Element => (
                          //@ts-ignore
                          <Option key={user.id} value={user.id}>
                            {
                              //@ts-ignore
                              user.surname + " " + user.name
                            }
                          </Option>
                        ),
                      )}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="vicemanager"
                    label="Vice Manager"
                    rules={[{ required: false, message: "Seleziona almeno un utente!" }]}
                    extra={`Il vice manager del gruppo`}
                  >
                    <UISelect
                      showSearch
                      filterOption={(input, option): boolean =>
                        ((option!.children as unknown) as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {managersList.map(
                        (user): JSX.Element => (
                          //@ts-ignore
                          <Option key={user.id} value={user.id}>
                            {
                              //@ts-ignore
                              user.surname + " " + user.name
                            }
                          </Option>
                        ),
                      )}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={24}>
                  <Form.Item
                    name="users"
                    label="Utenti"
                    rules={[{ required: true, message: "Seleziona almeno un utente!" }]}
                    extra={`Gli utenti del gruppo`}
                  >
                    <UISelect
                      mode="multiple"
                      showSearch
                      //@ts-ignore
                      onDeselect={handleChangeValue}
                      filterOption={(input, option): boolean =>
                        ((option!.children as unknown) as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {userList.map(
                        (user): JSX.Element => (
                          //@ts-ignore
                          <Option key={user.id} value={user.id}>
                            {
                              //@ts-ignore
                              user.surname + " " + user.name
                            }
                          </Option>
                        ),
                      )}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="space-between" align="bottom" gutter={[12, 12]}>
                <Col>
                  <UILink route={Routes.groups} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item noStyle>
                    <UIButton type="primary" data-cy="update-group-button" htmlType="submit">
                      Salva Gruppo
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
      <Row style={{ marginBottom: "30px", marginTop: "30px" }}>
        <Col xs={24}>
          <DeleteCard
            actionToDispatch={removeGroup}
            data-cy="remove-group-card"
            title="Elimina Gruppo"
            text="Cliccando sul bottone elimina eliminerai definitivamente il gruppo selezionato. Assicurati di voler rimuoverlo davvero."
            removeButtonLabel="Elimina Gruppo"
          />
        </Col>
      </Row>
    </>
  );
};
