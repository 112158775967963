/** @format */

import {
  TActionFleetListFailure,
  TActionFleetListRequest,
  TActionFleetListSuccess,
} from "../declarations/actions/fleets";
import { TActionType } from "../declarations/actionTypes";
import { TFleets } from "../declarations/maps/fleets";

type Actions = TActionFleetListRequest | TActionFleetListSuccess | TActionFleetListFailure;

export const reducerFleets = (prevStore: TFleets = { fleetList: [] }, action: Actions): TFleets => {
  switch (action.type) {
    case TActionType.fleetListRequest:
      return { ...prevStore, fleetList: [] };
    case TActionType.fleetListSuccess:
      return { ...prevStore, fleetList: action.payload };
    case TActionType.fleetListFailure:
      return { ...prevStore, fleetList: [] };
    default:
      return prevStore;
  }
};
