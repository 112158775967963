/** @format */

import { Modal } from "antd";
import styled from "styled-components";

const UIModal = styled(Modal)`
  .ant-modal-header {
    border-radius: 7px 7px 0 0 !important;
  }
  .ant-modal-content {
    border-radius: 7px !important;
  }
  .ant-btn {
    border-radius: 7px;
  }
`;

export default UIModal;
