/** @format */

import { Row, Col, Dropdown, Menu } from "antd";
import React from "react";
import { Routes } from "../../utils/Routes";
import { UILink } from "../../components/ui/Link";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { UIText } from "../../components/ui/Text";
import { BaseColor } from "../../theme/maps/Colors";
import { useSelector } from "react-redux";
import { selectorPermissionList } from "../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../utils/permissions/hasPermission";

export const warehouseListColumns = [
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
    showMobile: true,
  },
  {
    title: "Sede",
    dataIndex: "office_name",
    key: "office_name",
    showMobile: true,
  },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  const history = useHistory();
  const permissionList = useSelector(selectorPermissionList);

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => history.push(Routes.warehousesEdit.replace(":id_warehouse", row.id))}
        icon={<EditOutlined />}
      >
        Gestisci
      </Menu.Item>
    </Menu>
  );

  if (!hasPermission(permissionList, "Product", "Update")) return <></>;
  return (
    <>
      <Dropdown overlay={menu}>
        <UIText css={"font-size: 24px; cursor: pointer;"} color={BaseColor}>
          <MoreOutlined />
        </UIText>
      </Dropdown>
    </>
  );
};

export const warehouseAvailableListColumns = [
  {
    title: "Nome",
    dataIndex: "product_name",
    key: "product_name",
  },
  {
    title: "Stato",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Giacenza",
    dataIndex: "giacenza",
    key: "giacenza",
  },
  {
    title: "Assegnati",
    dataIndex: "assegnati",
    key: "assegnati",
  },
];

export const warehouseAssignedListColumns = [
  {
    title: "Nome",
    dataIndex: "product_name",
    key: "product_name",
  },
  {
    title: "Utente",
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "Stato",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Q.tà",
    dataIndex: "quantity",
    key: "quantity",
  },
];
