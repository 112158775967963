/** @format */

import { TShiftType } from "../declarations/maps/shiftTypes";
import { TStore } from "../declarations/store";

export const selectorShiftTypeList = (store: TStore): TShiftType[] =>
  store.shiftTypes.shiftTypeList || [];

export const selectorShiftType = (store: TStore, id: string): TShiftType | undefined =>
  store.shiftTypes.shiftTypeList?.find(shiftType => shiftType.id === id) || undefined;

export const selectorShiftTypeListWorkable = (store: TStore): TShiftType[] => {
  const shiftTypeList = selectorShiftTypeList(store);
  return shiftTypeList.filter(shiftType => parseInt(shiftType.working_minutes) != 0);
};
