/** @format */

import React, { useEffect, useState } from "react";
import { Breadcrumb, Checkbox, Col, Form, Row, Skeleton } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UIText } from "../../ui/Text";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorLocationDeleted,
  selectorLocationUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { API_ENDPOINTS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import {
  actionLocationDeleteRequest,
  actionLocationUpdateRequest,
} from "../../../redux-modules/actions/actionsLocations";
import { UITable } from "../../ui/Table";
import { TLocation } from "../../../redux-modules/declarations/maps/locations";
import { DeleteCard } from "../../layout/DeleteCard";
import { TActionLocationDeleteRequest } from "../../../redux-modules/declarations/actions/location";
import { UILink } from "../../ui/Link";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../../utils/permissions/hasPermission";

export const ViewLocationEdit = (): JSX.Element => {
  //@ts-ignore
  const { id_location } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const [locationInfo, setLocationInfo] = useState<TLocation>();
  const isLocationUpdated = useSelector(selectorLocationUpdated);
  const isLocationDeleted = useSelector(selectorLocationDeleted);
  const permissionList = useSelector(selectorPermissionList);

  const canDeleteLocation = hasPermission(permissionList, "Office", "Delete");

  const removeLocation = (): TActionLocationDeleteRequest =>
    dispatch(actionLocationDeleteRequest(id_location));

  useEffect(() => {
    if (isLocationUpdated || isLocationDeleted) history.push(Routes.locations);
  }, [isLocationUpdated, isLocationDeleted]);

  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      id: id_location,
      name: e.name,
      address: e.address,
      cap: e.cap,
      works_on_monday: e.works_on_monday || false,
      works_on_tuesday: e.works_on_tuesday || false,
      works_on_wednesday: e.works_on_wednesday || false,
      works_on_thursday: e.works_on_thursday || false,
      works_on_friday: e.works_on_friday || false,
      works_on_saturday: e.works_on_saturday || false,
      works_on_sunday: e.works_on_sunday || false,
    };
    //dispatch
    dispatch(actionLocationUpdateRequest(contentObj));
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      const response = await apiClient.get(API_ENDPOINTS.LOCATIONS + "/" + id_location);
      //@ts-ignore
      setLocationInfo(response.data.data);
    };
    fetchData();
  }, []);

  return !!!locationInfo ? (
    <UICard style={{ marginBottom: "20px" }}>
      <Skeleton active />
    </UICard>
  ) : (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <Row>
            <Col>
              <UITitle level={2} color={BaseColor} data-cy="page-title">
                Modifica Sede
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <UILink route={Routes.locations} label={"Sedi"} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>{locationInfo ? locationInfo.name : ""}</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
      </Row>
      <Form
        style={{ marginTop: "15px" }}
        name="update-group"
        className="update-group"
        data-cy="update-location-form"
        onFinish={handleSave}
        initialValues={locationInfo}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: "Inserisci un nome!" }]}
                    extra="Il nome del gruppo"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="address"
                    label="Indirizzo"
                    rules={[{ required: true, message: "Inserisci un indirizzo valido!" }]}
                    extra="La via/piazza della sede"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={8}>
                  <Form.Item
                    name="cap"
                    label="CAP"
                    rules={[
                      { required: false, message: "Inserisci il codice di avviamento postale!" },
                    ]}
                    extra="Il CAP della sede"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <UIText>
                    Giorni Lavorativi Settimanali <i>(0 per indicare un giorno di chiusura)</i>
                  </UIText>
                </Col>
              </Row>
              <Row gutter={[8, 8]} style={{ marginTop: "10px" }}>
                <Col xs={24} md={24}>
                  <Row>
                    <Col xs={24}>
                      <UITable
                        size="middle"
                        pagination={false}
                        columns={[
                          { title: "Lun", dataIndex: "lun", key: "lun" },
                          { title: "Mar", dataIndex: "mar", key: "mar" },
                          { title: "Mer", dataIndex: "mer", key: "mer" },
                          { title: "Gio", dataIndex: "gio", key: "gio" },
                          { title: "Ven", dataIndex: "ven", key: "ven" },
                          { title: "Sab", dataIndex: "sab", key: "sab" },
                          { title: "Dom", dataIndex: "dom", key: "dom" },
                        ]}
                        dataSource={[
                          {
                            key: "1",
                            lun: (
                              <Form.Item name="works_on_monday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            mar: (
                              <Form.Item name="works_on_tuesday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            mer: (
                              <Form.Item name="works_on_wednesday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            gio: (
                              <Form.Item name="works_on_thursday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            ven: (
                              <Form.Item name="works_on_friday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            sab: (
                              <Form.Item name="works_on_saturday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                            dom: (
                              <Form.Item name="works_on_sunday" valuePropName="checked">
                                <Checkbox />
                              </Form.Item>
                            ),
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="bottom"
                gutter={[12, 12]}
                style={{ marginTop: "15px" }}
              >
                <Col>
                  <UILink route={Routes.locations} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item noStyle>
                    <UIButton type="primary" data-cy="update-location-button" htmlType="submit">
                      Salva Sede
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
      {canDeleteLocation && (
        <Row style={{ marginBottom: "30px" }}>
          <Col xs={24}>
            <DeleteCard
              data-cy="remove-location-card"
              actionToDispatch={removeLocation}
              title="Elimina Sede"
              text="Cliccando sul bottone elimina eliminerai definitivamente la sede selezionato. Assicurati di voler rimuoverla."
              removeButtonLabel="Elimina Sede"
            />
          </Col>
        </Row>
      )}
    </>
  );
};
