/** @format */

import { Row, Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { SETTINGSTABS } from "../../../../../constants/settings";
import { justificativeListColumns } from "../../../../../constants/tables/requestTypeListColumns";
import { actionJustificativeListRequest } from "../../../../../redux-modules/actions/actionsJustificatives";
import { selectorJustificativeList } from "../../../../../redux-modules/selectors/selectorsJustificatives";
import {
  selectorJustificativeDeleted,
  selectorJustificativeListLoading,
} from "../../../../../redux-modules/selectors/selectorsUI";
import { Routes } from "../../../../../utils/Routes";
import { UIButton } from "../../../../ui/Button";
import { UITable } from "../../../../ui/Table";
import { UITitle } from "../../../../ui/Title";
import UITransition from "../../../../ui/Transition";
import ViewRequestTypeCreate from "../../../ViewRequestTypeCreate";
import ViewRequestTypeEdit from "../../../ViewRequestTypeEdit";
import { ViewDocumentTypeEdit } from "../DocumentTypes/ViewDocumentTypeEdit";

const RequestTypesTab = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const justificativeDeleted = useSelector(selectorJustificativeDeleted);
  const justificativeListLoading = useSelector(selectorJustificativeListLoading);
  const justificativeList = useSelector(selectorJustificativeList);

  useEffect(() => {
    if (history.location.pathname === Routes.settings + SETTINGSTABS.REQUEST_TYPES) {
      dispatch(
        actionJustificativeListRequest({
          query: "",
          columns: [""],
        }),
      );
    }
  }, [justificativeDeleted]);

  /* const [requestTypes, setRequestTypes] = useState([]);
  const dispatch = useDispatch();
  const requestTypeDeleted = useSelector(selectorRequestTypeDeleted);
  const requestTypeList = useSelector(selectorRequestTypesList);
 */
  /*   useEffect(() => {
    if (history.location.pathname === Routes.settings + SETTINGSTABS.REQUEST_TYPES) {
       dispatch(actionRequestTypeListRequest());
     }
  }, [history.location.pathname, requestTypeDeleted]);
 */

  switch (location.pathname) {
    case Routes.settings + SETTINGSTABS.REQUEST_TYPES + "/nuovo":
      return <ViewRequestTypeCreate />;
    case Routes.settings + SETTINGSTABS.REQUEST_TYPES:
      return (
        <UITransition>
          <Row justify="space-between" align="middle" gutter={[16, 16]}>
            <Col>
              <UITitle level={4}>Giustificativi</UITitle>
            </Col>
            <Col>
              <span
                onClick={() =>
                  history.push(Routes.settings + SETTINGSTABS.REQUEST_TYPES + "/nuovo")
                }
              >
                <UIButton type="primary">Crea Nuova</UIButton>
              </span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <UITable
                loading={justificativeListLoading}
                //@ts-ignore
                columns={justificativeListColumns}
                dataSource={justificativeList.data}
                pagination={false}
                size="middle"
              />
            </Col>
          </Row>
        </UITransition>
      );
    default:
      return <ViewRequestTypeEdit />;
  }
};
export default React.memo(RequestTypesTab);
