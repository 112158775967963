/** @format */

import { Col, Form, Row } from "antd";
import React from "react";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import Logo from "../../../assets/svg/logo_strong_vertical.svg";
//import Logo from "../../../assets/images/logo_notext.png";
import { UITitle } from "../../ui/Title";
import { Colors, BaseColor } from "../../../theme/maps/Colors";
import { MailOutlined } from "@ant-design/icons";
import { UIText } from "../../ui/Text";
import apiClient from "../../../utils/apiClient";
import { API_ENDPOINTS } from "../../../constants/api";
import { showNotification } from "../../../utils/showNotification";
import { Routes } from "../../../utils/Routes";
import { useHistory } from "react-router";

export const ViewForgotPassword = (): JSX.Element => {
  const history = useHistory();
  const handleRecovery = (forgotObject: {}): void => {
    //@ts-ignore
    const mailObj = { email: forgotObject.email };
    apiClient(API_ENDPOINTS.PASSWORD_RECOVERY, {
      method: "POST",
      data: mailObj,
    })
      .then(response => {
        showNotification(
          "success",
          "Recupero password",
          "Se l'indirizzo che hai inserito è presente nel nostro database ti verrà recapitata un'email per procedere col ripristino della password",
        );
      })
      .catch(error => {
        showNotification(
          "error",
          "Recupero password",
          "Errore nella richiesta di ripristino password",
        );
      });
  };

  return (
    <>
      <Row justify="center" gutter={[16, 16]}>
        <Col lg={8} sm={18} xs={24}>
          <UICard
            style={{
              boxShadow: "-1px 1px 7px #454545",
            }}
          >
            <Row justify="center" gutter={[16, 16]} style={{ marginBottom: 15 }}>
              <Col>
                <img
                  width={200}
                  src={Logo}
                  alt="Logo"
                  onClick={() => history.push(Routes.login)}
                  style={{ cursor: "pointer" /*filter: "drop-shadow(1px -1px 0.02rem #aeacac)"*/ }}
                />
              </Col>
            </Row>
            {/*
              <Row justify="center" gutter={[16, 16]}>
              <Col onClick={() => history.push(Routes.login)} style={{ cursor: "pointer" }}>
                <UITitle
                  level={2}
                  style={{ fontWeight: "bold", filter: "drop-shadow(1px -1px 0.15rem #aeacac)" }}
                  color={BaseColor}
                >
                  {process.env.REACT_APP_NAME?.toUpperCase()}
                </UITitle>
              </Col>
            </Row>
              */}
            <Row>
              <Col xs={24}>
                <UITitle level={3} color={Colors.darkGray}>
                  Password dimenticata?
                </UITitle>
              </Col>
            </Row>
            <Row style={{ marginBottom: "25px" }}>
              <Col xs={24}>
                <UIText color={Colors.darkGray}>
                  Inserisci l'indirizzo email utilizzato per accedere; riceverai una mail con le
                  istruzioni per resettare la password.
                </UIText>
              </Col>
            </Row>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleRecovery}
              onFinishFailed={(e): void => console.log(e)}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Inserisci un'email valida!" }]}
              >
                <UIInput placeholder="Email" prefix={<MailOutlined />} />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <UIButton
                  bgColor={BaseColor}
                  color={Colors.primary}
                  hoverBgColor={BaseColor}
                  borderColor={BaseColor}
                  htmlType="submit"
                  fullWidth
                >
                  RESETTA PASSWORD
                </UIButton>
              </Form.Item>
            </Form>
          </UICard>
        </Col>
      </Row>
    </>
  );
};
