/** @format */

import { TModel } from "../../constants/maps/BackendEnums";
import { TUser } from "../declarations/maps/auth";
import { TRequest } from "../declarations/maps/requests";
import { TStore } from "../declarations/store";

export const selectorRequestsList = (store: TStore): TRequest[] => store.requests.requestList || [];

export const selectorRequestsByJustificativeType = (
  store: TStore,
  justificative_type_id: string,
): TRequest[] => {
  if (!store.requests.requestList) return [];
  return store.requests.requestList.filter(
    el => el.justificative_type_id === justificative_type_id,
  );
};

export const selectorRequestsByStatus = (
  store: TStore,
  justificative_status_id: string,
): TRequest[] => {
  if (!store.requests.requestList) return [];
  return store.requests.requestList.filter(el => el.current_status_id === justificative_status_id);
};

export const selectorRequestsByUser = (store: TStore, user_id?: string): TRequest[] => {
  if (!store.requests.requestList || !user_id) return [];
  return store.requests.requestList.filter(el => el.user_id === user_id);
};

export interface TRequestsOwner {
  user: TUser;
  request_id: string;
}
export const selectorRequestsOwners = (store: TStore): TRequestsOwner[] => {
  if (!store.requests.requestList) return [];
  const requests = store.requests.requestList.map(e => ({
    request_id: e.id,
    user: store.users.usersList.data.find(us => us.id === e.user_id),
  }));

  //@ts-ignore
  return requests;
};

/*
export const selectorRequestsOwnersUnique = (store: TStore): TRequestsOwner[] => {
  if (!store.requests.requestList) return [];
  const requests = store.requests.requestList.map(e => ({
    request_id: e.id,
    user: store.users.usersList.data.find(us => us.id === e.user_id),
  }));

  let uniqueIds: string[] = [];

  const uniqueRequestsOwners = requests.filter(element => {
    const isDuplicate = element.user && uniqueIds.includes(element.user?.id);

    if (!isDuplicate && element.user) {
      uniqueIds.push(element.user.id);

      return true;
    }

    return false;
  });

  //@ts-ignore
  return uniqueRequestsOwners;
};
*/

export const selectorRequestsOwnersUnique = (store: TStore): TRequest[] => {
  if (!store.requests.requestList) return [];
  const requests = store.requests.requestList.map(e => ({ request_id: e.id, ...e }));
  let uniqueIds: string[] = [];

  const uniqueRequestsOwners = requests.filter(element => {
    const isDuplicate = element.user && uniqueIds.includes(element.user?.id);
    if (!isDuplicate && element.user) {
      uniqueIds.push(element.user.id);
      return true;
    }
    return false;
  });
  return uniqueRequestsOwners;
};
