/** @format */

import { Col, Dropdown, Menu, Row, Tag } from "antd";
import React, { useState } from "react";
import { Routes } from "../../utils/Routes";
import { UILink } from "../../components/ui/Link";
import { darkenFromHex, stringToHex } from "../../utils/strings/stringToColor";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { UIText } from "../../components/ui/Text";
import { BaseColor } from "../../theme/maps/Colors";
import { title } from "process";
import { Action } from "redux";
import { UIInput } from "../../components/ui/Input";
import UIModal from "../../components/ui/Modal";
import apiClient from "../../utils/apiClient";
import { showNotification } from "../../utils/showNotification";
import { API_ENDPOINTS } from "../api";

export const userListColumns = [
  {
    title: "",
    dataIndex: "avatar",
    key: "avatar",
    showMobile: true,
  },
  {
    title: "Utente",
    dataIndex: "user",
    key: "user",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (row, user, index) => {
      return (
        <>
          {
            user.is_disabled == 1 ? 
            <Col>
              <Row>
                <span style={{color: "red", fontWeight: "bolder", fontStyle: "italic"}}>{user.user}</span>
              </Row>
              <Row>
                <span style={{color: "red", fontWeight: "bolder", fontStyle: "italic"}}>(disabilitato)</span>
              </Row>
            </Col>
            :
            <span>{user.user}</span>
          }
        </>
      );
    },
  },
  {
    title: "Ruolo",
    dataIndex: "role",
    key: "role",
    showMobile: false,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    showMobile: false,
  },
  {
    title: "Gruppi",
    dataIndex: "groups",
    key: "groups",
    showMobile: false,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (row, user, index) => {
      return (
        <>
          {
            //@ts-ignore
            user.groups.map(group => (
              <Tag
                key={index}
                color={darkenFromHex(stringToHex(group.name))}
                style={{ borderRadius: "5px" }}
              >
                {group.name}
              </Tag>
            ))
          }
        </>
      );
    },
  },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [removeText, setRemoveText] = useState("");
  const handleRemove = (): Action | void => {
    apiClient
      .post(API_ENDPOINTS.USER_ENABLE, { user_id: row.id })
      .then(res => {
        if (res.data.success) {
          showNotification(
            "success",
            "Utente abilitato",
            "L'utente è stato abilitato con successo",
          );
          history.push(Routes.usersEdit.replace(":id_user", row.id));
        }
      })
      .catch(err => {
        showNotification("error", "Errore", err.response.data.message);
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (removeText.trim().toLowerCase() === "abilita") {
      handleRemove();
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const menu = (
    <Menu>
      {!!row.is_disabled ? (
        <>
          <Menu.Item key="1" onClick={() => showModal()}>
            Abilita utente
          </Menu.Item>
          <UIModal
            title="Abilita Dipendente"
            visible={isModalVisible}
            onOk={handleOk}
            okText={"Abilita"}
            okButtonProps={{
              style: { borderRadius: "7px", backgroundColor: BaseColor, borderColor: BaseColor },
            }}
            cancelButtonProps={{ style: { borderRadius: "7px" } }}
            onCancel={handleCancel}
          >
            <UIText>Per confermare l'operzione digita {"abilita"}:</UIText>
            <UIInput onChange={(e): void => setRemoveText(e.target.value)} />
          </UIModal>
        </>
      ) : (
        <Menu.Item
          key="1"
          onClick={() => history.push(Routes.usersEdit.replace(":id_user", row.id))}
          icon={<EditOutlined />}
        >
          Gestisci
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <UIText css={"font-size: 24px; cursor: pointer;"} color={BaseColor}>
          <MoreOutlined />
        </UIText>
      </Dropdown>
    </>
  );
};
/*
//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => (
  <Row gutter={[12, 0]}>
    <Col>
      <UILink route={Routes.usersEdit.replace(":id_user", row.id)} label={"Gestisci"} />
    </Col>
  </Row>
);
*/
