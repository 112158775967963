/** @format */

import {
  TActionCommunicationListFailure,
  TActionCommunicationListRequest,
  TActionCommunicationListSuccess,
} from "../declarations/actions/communications";
import { TActionType } from "../declarations/actionTypes";
import { TCommunications } from "../declarations/maps/communications";

type Actions =
  | TActionCommunicationListRequest
  | TActionCommunicationListSuccess
  | TActionCommunicationListFailure;

export const reducerCommunications = (
  prevStore: TCommunications = { communicationList: [] },
  action: Actions,
): TCommunications => {
  switch (action.type) {
    case TActionType.communicationListRequest:
      return { ...prevStore, communicationList: [] };
    case TActionType.communicationListSuccess:
      return { ...prevStore, communicationList: action.payload };
    case TActionType.communicationListFailure:
      return { ...prevStore, communicationList: [] };
    default:
      return prevStore;
  }
};
