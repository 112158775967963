/** @format */

import React, { useState } from "react";
import styled from "styled-components";
import { TDocumentComplete } from "../../../../redux-modules/declarations/maps/documents";
import docImageThemeAzure from "../../../../assets/svg/file_theme1.svg";
import docImageThemeMinimal from "../../../../assets/svg/file_theme2.svg";
import { BaseColor } from "../../../../theme/maps/Colors";
import { hexToRgb } from "../../../../utils/styles";
import { Dropdown, Menu, Tooltip } from "antd";
import {
  DownloadOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { UIFilePreview } from "../../FilePreview";
import {
  actionDocumentDeleteRequest,
  actionDocumentDeleteReset,
  actionDocumentDeleteSuccess,
} from "../../../../redux-modules/actions/actionsDocument";
import moment from "moment";
import { API_ENDPOINTS } from "../../../../constants/api";
import { actionGroupDeleteFailure } from "../../../../redux-modules/actions/actionsGroups";
import {
  actionUserDocumentUploadSuccess,
  actionUserDocumentUploadReset,
} from "../../../../redux-modules/actions/actionsUsers";
import apiClient from "../../../../utils/apiClient";
import { showNotification } from "../../../../utils/showNotification";
import { TTheme } from "..";
import { selectorLoggedUser } from "../../../../redux-modules/selectors/selectorsAuth";

interface Props {
  file: TDocumentComplete;
  showExpiring: boolean;
  theme: TTheme;
  personalDocumentUserId?: string;
  size?: "small" | "medium";
}
export const FileComponent = ({
  file,
  showExpiring,
  theme,
  personalDocumentUserId,
  size = "medium",
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectorLoggedUser);

  const [visiblePreview, setVisiblePreview] = useState<boolean>(false);

  const showModal = (): void => {
    if (!!personalDocumentUserId && personalDocumentUserId == loggedUser?.id) {
      apiClient.post(
        API_ENDPOINTS.PERSONAL_DOCUMENTS_READ.replace(":id_document", file.id).replace(
          ":id_user",
          personalDocumentUserId,
        ),
      );
    }

    setVisiblePreview(true);
  };
  const hideModal = (): void => setVisiblePreview(false);
  const removeItem = (): void => {
    if (!personalDocumentUserId) {
      dispatch(actionDocumentDeleteRequest(file.id));
    } else {
      apiClient
        .delete(
          API_ENDPOINTS.PERSONAL_DOCUMENTS_DELETE.replace(":id_document", file.id).replace(
            ":id_user",
            personalDocumentUserId,
          ),
        )
        .then(res => {
          dispatch(actionDocumentDeleteSuccess(""));
          dispatch(actionDocumentDeleteReset());
          dispatch(actionUserDocumentUploadSuccess());
          dispatch(actionUserDocumentUploadReset());
          showNotification("success", "Eliminato!", "Documento eliminato con successo");
        })
        .catch(e => {
          dispatch(actionGroupDeleteFailure());
          showNotification(
            "error",
            "Oops..",
            //@ts-ignore
            e.response.data.message ?? Object.values(e.response.data.errors).join(`\n`),
          );
        });
    }
  };

  const downloadFile = () => {
    console.log("download");
    apiClient
      .get(API_ENDPOINTS.AUTH_DOCUMENT.replace(":document_id", file.id), {responseType: "blob",})
      .then(res => {
        console.log(res);
        let url = window.URL.createObjectURL(
          new Blob([res.data]),
        );
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          file.filename,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        if(link.parentNode != null){
          link.parentNode!.removeChild(link);
        }
      })
      .catch(e => {
        showNotification(
          "error",
          "Oops..",
          //@ts-ignore
          e.response.data.message ?? Object.values(e.response.data.errors).join(`\n`),
        );
      });
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={showModal} icon={<InfoCircleOutlined />}>
        Dettagli
      </Menu.Item>
      <Menu.Item key="2" onClick={downloadFile} icon={<DownloadOutlined />}>
        Visualizza
      </Menu.Item>

      <Menu.Item key="3" onClick={removeItem} icon={<DeleteOutlined />}>
        Elimina
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <StyledDiv
          //@ts-ignore
          size={size}
        >
          <ExpiringIcon file={file} showExpiring={showExpiring} />
          <StyledImg
            //@ts-ignore
            size={size}
            src={theme === "azure" ? docImageThemeAzure : docImageThemeMinimal}
          />
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: size === "medium" ? "140px" : "115px",
            }}
          >
            {file.filename}
          </span>
        </StyledDiv>
      </Dropdown>
      <UIFilePreview file={file} visible={visiblePreview} onDismiss={hideModal} />
    </>
  );
};

interface expiringIconProps {
  file: TDocumentComplete;
  showExpiring: boolean;
}
const ExpiringIcon = ({ file, showExpiring }: expiringIconProps): JSX.Element => {
  if (showExpiring) {
    if (moment(file.expiring_at) < moment()) {
      return (
        <Tooltip title={"Scaduto il " + moment(file.expiring_at).format("DD/MM/YYYY")}>
          <CloseCircleTwoTone
            twoToneColor="#ff0000"
            style={{
              fontSize: "17px",
              position: "absolute",
              right: "0",
              top: "0",
              marginTop: "2px",
              padding: "0 10px",
            }}
          />
        </Tooltip>
      );
    }

    if (
      moment(file.expiring_at) >= moment() &&
      moment(file.expiring_at) <= moment().add(7, "days")
    ) {
      return (
        <Tooltip title={"Scade il " + moment(file.expiring_at).format("DD/MM/YYYY")}>
          <CheckCircleTwoTone
            twoToneColor="#cc6701"
            style={{
              fontSize: "17px",
              position: "absolute",
              right: "0",
              top: "0",
              marginTop: "2px",
              padding: "0 10px",
            }}
          />
        </Tooltip>
      );
    }

    if (!file.expiring_at || moment(file.expiring_at) > moment().add(7, "days")) {
      return (
        <Tooltip
          title={
            file.expiring_at
              ? "Scade il " + moment(file.expiring_at).format("DD/MM/YYYY")
              : "Nessuna scadenza"
          }
        >
          <CheckCircleTwoTone
            twoToneColor="#00bc00"
            style={{
              fontSize: "17px",
              position: "absolute",
              right: "0",
              top: "0",
              marginTop: "2px",
              padding: "0 10px",
            }}
          />
        </Tooltip>
      );
    }
  }
  return <></>;
};

const StyledImg = styled.img`
  ${//@ts-ignore
  props => props.size === "medium" && "width: 110px;"}
  ${//@ts-ignore
  props => props.size === "small" && "width: 80px;"}
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  border: 1px solid transparent;
  position: relative;
  padding: 5px;
  ${//@ts-ignore
  props => props.size === "medium" && "max-width: 140px;"}
  ${//@ts-ignore
  props => props.size === "small" && "max-width: 115px;"}
  font-weight: 600;
  align-items: center;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.1"});
    border: 1px solid rgb(${hexToRgb("" + BaseColor) + ", 0.2"});
    border-radius: 7px;
  }
`;
