/** @format */

import { LeftOutlined } from "@ant-design/icons";
import { Row, Col, Form, Skeleton } from "antd";
import React, { useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TModel } from "../../../../../constants/maps/BackendEnums";
import { actionRequestCreateRequest } from "../../../../../redux-modules/actions/actionsRequest";
import { TSizeUnit } from "../../../../../redux-modules/declarations/maps/justificatives";
import { TStore } from "../../../../../redux-modules/declarations/store";
import { selectorLoggedUser } from "../../../../../redux-modules/selectors/selectorsAuth";
import { selectorJustificativeList } from "../../../../../redux-modules/selectors/selectorsJustificatives";
import { selectorModelDetailsByModel } from "../../../../../redux-modules/selectors/selectorsModelDetails";
import { selectorRequestListUpdated } from "../../../../../redux-modules/selectors/selectorsUI";
import { UIButton } from "../../../../ui/Button";
import { UICard } from "../../../../ui/Card";
import { UIInput } from "../../../../ui/Input";
import { UITextArea } from "../../../../ui/Textarea";
import { UITitle } from "../../../../ui/Title";
import UITransition from "../../../../ui/Transition";
import { CustomRangePicker } from "../../../SideDialogs/RequestCreate";
interface Props {
  creatingType: string | undefined;
  onClose: (s: string | undefined) => void;
}
export const NewRequestForm = ({ onClose, creatingType }: Props): JSX.Element => {
  const justificativeTypes = useSelector(selectorJustificativeList);
  const loggedUser = useSelector(selectorLoggedUser);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const modelDetails = useSelector((store: TStore) =>
    selectorModelDetailsByModel(store, TModel.JustificativeType),
  );
  const getModelDetails = () => {
    return modelDetails.filter(el => el.model_id == creatingType && !!el.is_visible);
  };
  const requestUpdated = useSelector(selectorRequestListUpdated);
  useEffect(() => {
    if (requestUpdated) onClose(undefined);
  }, [requestUpdated]);

  const handleSave = () => {
    const saveObj = {
      justificative_type_id: creatingType,
      user_id: loggedUser?.id,
      datetime_from: form.getFieldValue("date")[0].format("YYYY-MM-DD HH:mm:ss"),
      datetime_to: form.getFieldValue("date")[1].format("YYYY-MM-DD HH:mm:ss"),
      note: form.getFieldValue("note"),
      details: form.getFieldValue("details")
        ? form
            .getFieldValue("details")
            //@ts-ignore
            .map((el, i) => ({ id: i, value: el }))
            //@ts-ignore
            .filter(el => !!el.value)
        : [],
    };
    // SALVA
    //@ts-ignore
    dispatch(actionRequestCreateRequest(saveObj));
  };

  if (!justificativeTypes)
    return (
      <UICard style={{ marginBottom: "20px", border: "none" }}>
        <Skeleton active />
      </UICard>
    );

  return (
    <UITransition>
      <Row gutter={[6, 6]}>
        <Col span={24}>
          <Row justify="start">
            <Col style={{ marginRight: "15px" }}>
              <span
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={() => onClose(undefined)}
              >
                <LeftOutlined />
              </span>
            </Col>
            <Col>
              <UITitle level={5}>
                Nuova richiesta di{" "}
                <span
                  style={{
                    color: justificativeTypes.data.find(e => e.id == creatingType)?.color,
                  }}
                >
                  {justificativeTypes.data.find(e => e.id == creatingType)?.name}
                </span>
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form layout="vertical" form={form} onFinish={handleSave} data-cy="new-request-form">
                <Row gutter={[16, 16]} style={{ marginTop: isMobileOnly ? "10px" : "0px" }}>
                  <Col xs={24} sm={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="date"
                          label="Periodo"
                          rules={[
                            {
                              required: true,
                              message: "Seleziona il periodo della richiesta",
                            },
                          ]}
                        >
                          <CustomRangePicker
                            //@ts-ignore
                            showTime={
                              justificativeTypes.data.find(el => el.id == creatingType)
                                ?.size_unit === TSizeUnit.GIORNI
                                ? false
                                : { format: "HH:mm" }
                            }
                            format={
                              justificativeTypes.data.find(el => el.id == creatingType)
                                ?.size_unit === TSizeUnit.GIORNI
                                ? "DD/MM/YYYY"
                                : "DD/MM/YYYY HH:mm"
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name="note"
                          label="Note"
                          rules={[
                            {
                              required: false,
                              message: "Inserisci delle informazioni aggiuntive",
                            },
                          ]}
                        >
                          <UITextArea style={{ minHeight: "100px", resize: "none" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={12} style={{ marginBottom: isMobileOnly ? "15px" : "0px" }}>
                    <>
                      {getModelDetails().map(md => (
                        <Row key={md.id}>
                          <Col span={24}>
                            <Form.Item
                              key={md.id}
                              name={["details", md.id]}
                              label={
                                <span style={{ textTransform: "capitalize" }}>{md.field_name}</span>
                              }
                              rules={[
                                {
                                  required: md.is_required,
                                  message: "Selezionare " + md.field_name,
                                },
                              ]}
                            >
                              <UIInput
                                //@ts-ignore
                                type={md.column_type_name}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    </>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item>
                      <UIButton
                        onClick={() => onClose(undefined)}
                        type="primary"
                        style={{ marginRight: 8 }}
                      >
                        Annulla
                      </UIButton>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <UIButton type="primary" htmlType="submit" data-cy="new-request-button-save">
                        Crea
                      </UIButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </UITransition>
  );
};

const CustomUICard = styled(UICard)`
  .ant-card-body {
    padding: 10px;
  }
`;
