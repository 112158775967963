/** @format */

import { TNotification } from "../declarations/maps/notifications";
import { TStore } from "../declarations/store";

export const selectorNotificationList = (store: TStore): TNotification[] =>
  store.notifications.notificationList || [];

export const selectorNotification = (store: TStore, id: string): TNotification | undefined =>
  store.notifications.notificationList?.find(notification => notification.id === id) || undefined;

export const selectorUnreadNotification = (store: TStore): TNotification[] => {
  const notificationList = selectorNotificationList(store);
  return notificationList.filter(notification => !notification.read_at);
};

export const selectorUnreadNotificationCount = (store: TStore): number => {
  const notificationList = selectorNotificationList(store);
  return notificationList.filter(notification => !notification.read_at).length;
};
