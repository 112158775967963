/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaShiftCreate, sagaShiftDelete, sagaShiftList } from "../sagas/sagasShifts";

export function* watcherShifts(): unknown {
  yield takeLatest(TActionType.shiftCreateRequest, sagaShiftCreate);
  yield takeLatest(TActionType.shiftListRequest, sagaShiftList);
  yield takeLatest(TActionType.shiftDeleteRequest, sagaShiftDelete);
}
