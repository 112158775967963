/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaGroupCreate, sagaGroupDelete, sagaGroupsList, sagaGroupUpdate } from "../sagas/sagaGroups";

export function* watcherGroups(): unknown {
  yield takeLatest(TActionType.groupCreateRequest, sagaGroupCreate);
  yield takeLatest(TActionType.groupsListRequest, sagaGroupsList);
  yield takeLatest(TActionType.groupDeleteRequest, sagaGroupDelete);
  yield takeLatest(TActionType.groupUpdateRequest, sagaGroupUpdate);
}
