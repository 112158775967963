import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import { TAPILocationListRequest } from "../redux-modules/declarations/apis/locations";
import { TLocationCreateRequest, TLocationUpdateRequest } from "../redux-modules/declarations/maps/locations";
import apiClient from "../utils/apiClient";


export const apiLocationsList = (params: TAPILocationListRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.get(API_ENDPOINTS.LOCATIONS, { params });

  export const apiLocationCreate = (request: TLocationCreateRequest): Promise<AxiosResponse<unknown>> => apiClient.post(API_ENDPOINTS.LOCATIONS, request);

  export const apiLocationUpdate = (request: TLocationUpdateRequest): Promise<AxiosResponse<unknown>> => 
apiClient.put(API_ENDPOINTS.LOCATIONS + "/" + request.id, request);

export const apiLocationDelete = (locationId: string,): Promise<AxiosResponse<unknown>> =>
apiClient.delete(API_ENDPOINTS.LOCATIONS + '/' + locationId);
