/** @format */

import React from "react";
import { Routes } from "../../utils/Routes";
import { Dropdown, Menu, Tag } from "antd";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { UIText } from "../../components/ui/Text";
import { BaseColor } from "../../theme/maps/Colors";
import { darkenFromHex, stringToHex } from "../../utils/strings/stringToColor";
import { useSelector } from "react-redux";
import { selectorPermissionList } from "../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../utils/permissions/hasPermission";

export const groupListColumns = [
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
    showMobile: true,
    //@ts-ignore
    render: function renderName(text, row, index): JSX.Element {
      return (
        <Tag
          key={index}
          color={darkenFromHex(stringToHex(row.name))}
          style={{ borderRadius: "5px" }}
        >
          {row.name}
        </Tag>
      );
    },
  },
  {
    title: "Manager",
    dataIndex: "manager",
    key: "manager",
    showMobile: false,
  },
  {
    title: "N° Utenti",
    dataIndex: "usersCount",
    key: "usersCount",
    showMobile: false,
  },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  const history = useHistory();
  const permissionList = useSelector(selectorPermissionList);

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={(): void => history.push(Routes.groupsEdit.replace(":id_group", row.id))}
        icon={<EditOutlined />}
      >
        Gestisci
      </Menu.Item>
    </Menu>
  );

  if (!hasPermission(permissionList, "Group", "Update")) return <></>;
  return (
    <>
      <Dropdown overlay={menu}>
        <UIText css={"font-size: 24px; cursor: pointer;"} color={BaseColor}>
          <MoreOutlined />
        </UIText>
      </Dropdown>
    </>
  );
};
/*
//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => (
  <Row gutter={[12, 0]}>
    <Col>
      <UILink route={Routes.groupsEdit.replace(":id_group", row.id)} label={"Gestisci"} />
    </Col>
  </Row>
);
*/
