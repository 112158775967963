/** @format */

import { AxiosResponse } from "axios";
import { put, all, call } from "redux-saga/effects";
import {
  apiCommunicationCreate,
  apiCommunicationDelete,
  apiCommunicationsList,
} from "../../api/apiCommunications";
import { showNotification } from "../../utils/showNotification";
import {
  actionCommunicationCreateSuccess,
  actionCommunicationCreateReset,
  actionCommunicationCreateFailure,
  actionCommunicationListLoadingRequest,
  actionCommunicationListSuccess,
  actionCommunicationListLoadingSuccess,
  actionCommunicationListFailure,
  actionCommunicationListLoadingFailure,
  actionCommunicationDeleteSuccess,
  actionCommunicationDeleteReset,
  actionCommunicationDeleteFailure,
} from "../actions/actionsCommunications";
import {
  TActionCommunicationCreateRequest,
  TActionCommunicationListRequest,
  TActionCommunicationDeleteRequest,
} from "../declarations/actions/communications";
import { TAPIResponse } from "../declarations/apis/general";
import { TCommunication } from "../declarations/maps/communications";

export function* sagaCommunicationCreate(action: TActionCommunicationCreateRequest): unknown {
  try {
    const response = yield call(apiCommunicationCreate, action.payload);

    yield put(actionCommunicationCreateSuccess(response.data.id));
    yield put(actionCommunicationCreateReset());
    showNotification("success", "Creata!", "Comunicazione creata con successo");
  } catch (e) {
    yield put(actionCommunicationCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaCommunicationList(action: TActionCommunicationListRequest): unknown {
  try {
    yield put(actionCommunicationListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TCommunication[]>> = yield call(
      apiCommunicationsList,
    );

    //@ts-ignore
    yield put(actionCommunicationListSuccess(response.data));
    yield put(actionCommunicationListLoadingSuccess());
  } catch (e) {
    yield put(actionCommunicationListFailure());
    yield put(actionCommunicationListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaCommunicationDelete(action: TActionCommunicationDeleteRequest): unknown {
  try {
    yield call(apiCommunicationDelete, action.payload);

    yield all([
      put(actionCommunicationDeleteSuccess(action.payload)),
      put(actionCommunicationDeleteReset()),
    ]);
    showNotification("success", "Eliminata!", "Comunicazione eliminata con successo");
  } catch (e) {
    yield put(actionCommunicationDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
