/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaLocationCreate, sagaLocationDelete, sagaLocationList, sagaLocationUpdate } from "../sagas/sagasLocations";

export function* watcherLocations(): unknown {
  yield takeLatest(TActionType.locationCreateRequest, sagaLocationCreate);
  yield takeLatest(TActionType.locationListRequest, sagaLocationList);
  yield takeLatest(TActionType.locationDeleteRequest, sagaLocationDelete);
  yield takeLatest(TActionType.locationUpdateRequest, sagaLocationUpdate);
}
