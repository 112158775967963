/** @format */

import {
  TActionReservationListFailure,
  TActionReservationListRequest,
  TActionReservationListSuccess,
} from "../declarations/actions/reservations";
import { TActionType } from "../declarations/actionTypes";
import { TReservations } from "../declarations/maps/reservations";

type Actions =
  | TActionReservationListRequest
  | TActionReservationListSuccess
  | TActionReservationListFailure;

export const reducerReservations = (
  prevStore: TReservations = { reservationList: [] },
  action: Actions,
): TReservations => {
  switch (action.type) {
    case TActionType.reservationListRequest:
      return { ...prevStore, reservationList: [] };
    case TActionType.reservationListSuccess:
      return { ...prevStore, reservationList: action.payload };
    case TActionType.reservationListFailure:
      return { ...prevStore, reservationList: [] };
    default:
      return prevStore;
  }
};
