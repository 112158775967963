/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import { TDocumentCreateRequest } from "../redux-modules/declarations/maps/documents";
import apiClient from "../utils/apiClient";

export const apiDocumentList = (): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.DOCUMENTS);
};

export const apiDocumentCreate = (
  request: TDocumentCreateRequest,
): Promise<AxiosResponse<unknown>> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };

  var formData = new FormData();
  formData.append("file", request.file);
  formData.append("users", JSON.stringify(request.users));
  formData.append("document_type_id", request.document_type_id);
  if (request.expiring_at) {
    formData.append("expiring_at", request.expiring_at);
  }
  if (request.year) {
    formData.append("year", request.year);
  }
  if (request.month) {
    formData.append("month", request.month);
  }

  return apiClient.post(API_ENDPOINTS.DOCUMENTS, formData, config);
  apiClient.post(API_ENDPOINTS.DOCUMENTS, request);
};

export const apiDocumentDelete = (documentId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.DOCUMENTS + "/" + documentId);

export const apiPersonalDocumentList = (userId: string): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.PERSONAL_DOCUMENTS.replace(":id_user", userId));
};
