/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TPresenceTypeCreateRequest,
  TPresenceTypeUpdateRequest,
} from "../redux-modules/declarations/maps/presenceTypes";
import apiClient from "../utils/apiClient";

export const apiPresenceTypeList = (): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.PRESENCE_TYPES);
};

export const apiPresenceTypeCreate = (
  presenceType: TPresenceTypeCreateRequest,
): Promise<AxiosResponse<unknown>> => apiClient.post(API_ENDPOINTS.PRESENCE_TYPES, presenceType);

export const apiPresenceTypeUpdate = (
  presenceType: TPresenceTypeUpdateRequest,
): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.PRESENCE_TYPES + "/" + presenceType.id, presenceType);

export const apiPresenceTypeDelete = (presenceTypeId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.PRESENCE_TYPES + "/" + presenceTypeId);
