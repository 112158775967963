/** @format */

import { css } from "styled-components";

export const fadeOutAnimation = css`
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(-10px);
    opacity: 0;
  }
`;

export const fadeOut = css`
  animation: ${fadeOutAnimation} 1s;
`;
