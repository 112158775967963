/** @format */

import React, { memo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectorCustomStyleOptions } from "../../redux-modules/selectors/selectorsUI";

export type SectionType = "Shifts" | "Presences" | "Requests";

export const CustomStyleProvider = memo(
  ({ section, children }: { section: SectionType; children: React.ReactNode }) => {
    const customStyleOptions = useSelector(selectorCustomStyleOptions);

    const zoom = customStyleOptions ? customStyleOptions[`${section}ListZoomLevel`] : 1;

    return (
      <CustomStyle zoom={zoom}>
        <>{children}</>
      </CustomStyle>
    );
  },
);

const CustomStyle = styled.span`
  zoom: ${(props: { zoom: number }): number => props.zoom};
`;

CustomStyleProvider.displayName = "CustomStyleProvider";
