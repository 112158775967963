/** @format */

import { Row, Col, Select, Form } from "antd";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { TModel } from "../../../../constants/maps/BackendEnums";
import { TModelDetail } from "../../../../redux-modules/declarations/maps/modelDetails";
import { TStore } from "../../../../redux-modules/declarations/store";
import { selectorModelDetailsByModel } from "../../../../redux-modules/selectors/selectorsModelDetails";
import { UIInput } from "../../../ui/Input";

interface Props {}

export const DetailsTabEdit: FC<Props> = ({}: Props): JSX.Element => {
  const details: TModelDetail[] = useSelector((store: TStore) =>
    selectorModelDetailsByModel(store, TModel.User, true),
  );
  return (
    <>
      {details.map(detail => (
        <Row gutter={[12, 0]}>
          <Col md={12}>
            <Form.Item
              name={"detail?" + detail.id}
              label={detail.field_name.charAt(0).toUpperCase() + detail.field_name.slice(1)}
              required={detail.is_required}
            >
              <UIInput
                //@ts-ignore
                type={detail.column_type_name}
              />
            </Form.Item>
          </Col>
        </Row>
      ))}
    </>
  );
};
