/** @format */

import { Col, Drawer, Form, Row, Select } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { actionDocumentTypeListRequest } from "../../../../redux-modules/actions/actionsDocumentType";
import { TDocumentCreateRequest } from "../../../../redux-modules/declarations/maps/documents";
import { TDocumentType } from "../../../../redux-modules/declarations/maps/documentTypes";
import { selectorPersonalDocumentTypesList } from "../../../../redux-modules/selectors/selectorsDocumentTypes";
import { UIButton } from "../../../ui/Button";
import { UIDatePicker } from "../../../ui/DatePicker";
import { UIFileUploader } from "../../../ui/FileUploader";
import { UISelect } from "../../../ui/Select";
const { Option } = Select;

interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: (files: TDocumentCreateRequest) => void;
}

export const NewPersonalDocumentDrawer: FC<Props> = ({
  visible,
  onClose,
  onSave,
}: Props): JSX.Element => {
  const [form] = Form.useForm();
  const [newFile, setNewFile] = useState<File | null>();
  const [documentTypes, setDocumentTypes] = useState<TDocumentType[]>([]);
  //@ts-ignore
  const { id_user } = useParams();
  const dispatch = useDispatch();
  const documentTypeList = useSelector(selectorPersonalDocumentTypesList);
  const [hideMonth, setHideMonth] = useState<boolean>(true);

  const handleUpload = (e: FileList | object[]): void => {
    if (e[0]) {
      //@ts-ignore
      setNewFile(e[0]?.originFileObj);
    }
  };

  const handleSave = (): void => {
    if (!!newFile) {
      form.validateFields().then(formValues => {
        const files: TDocumentCreateRequest = {
          users: [id_user],
          document_type_id: formValues.document_type_id,
          expiring_at: formValues.expiring_at
            ? moment(formValues.expiring_at).format("YYYY-MM-DD")
            : undefined,
          file: newFile,
          year: formValues.year ? formValues.year : undefined,
          month: formValues.month ? formValues.month : undefined,
        };
        onSave(files);
        form.resetFields();
      });
    }
  };

  useEffect(() => {
    dispatch(actionDocumentTypeListRequest());
  }, []);

  useEffect(() => {
    //@ts-ignore
    if (documentTypeList) setDocumentTypes(documentTypeList);
  }, [documentTypeList]);

  const yearsArray = (): string[] => {
    const yearsArray = [];

    var startDate = moment().subtract(5, "year").format("YYYY"),
      endDate = moment().format("YYYY");

    for (var from = +startDate, to = +endDate; from <= to; from++) {
      yearsArray.push("" + from);
    }
    return yearsArray;
  };

  const monthsArray = [...Array(12).keys()].map(e => e + 1);

  const handleClose = (): void => {
    form.resetFields();
    onClose();
  };

  // @ts-ignore
  const onChange = (x): void => {
    setHideMonth(form.getFieldValue("year") === undefined);
  };
  return (
    <Drawer
      title="Nuovo Documento"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <UIButton onClick={onClose} style={{ marginRight: 8 }}>
            Annulla
          </UIButton>
          <UIButton onClick={handleSave} type="primary">
            Carica
          </UIButton>
        </div>
      }
    >
      <Form layout="vertical" form={form} onValuesChange={onChange}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="document_type_id"
              label="Tipologia"
              rules={[{ required: true, message: "Inserisci una tipologia di documento" }]}
            >
              <UISelect>
                {documentTypes.map(dtype => (
                  <Option value={dtype.id} key={dtype.id}>
                    {dtype.name}
                  </Option>
                ))}
              </UISelect>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="year"
              label="Anno di Rif."
              rules={[{ required: false, message: "Seleziona anno" }]}
            >
              <UISelect>
                {yearsArray().map(y => (
                  <Option value={y} key={y}>
                    {y}
                  </Option>
                ))}
              </UISelect>
            </Form.Item>
          </Col>
          {!hideMonth && (
            <Col span={12}>
              <Form.Item
                name="month"
                label="Mese di Rif."
                rules={[{ required: false, message: "Seleziona mese" }]}
              >
                <UISelect>
                  {monthsArray.map(m => (
                    <Option value={m} key={m}>
                      {m}
                    </Option>
                  ))}
                </UISelect>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="expiring_at"
              label="Scadenza"
              rules={[{ required: false, message: "Seleziona la data di scadenza del documento" }]}
            >
              <UIDatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <UIFileUploader handleUpload={handleUpload} />
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
