/** @format */

import { Typography } from "antd";
import { TitleProps } from "antd/lib/typography/Title";
import styled from "styled-components";

const { Title } = Typography;

interface Props extends TitleProps {
  color?: string;
}

export const UITitle = styled(Title)<Props>`
  ${(props): string => (props.color ? "color: " + props.color + "!important;" : "")}
`;
