/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import { TAPIGroupsListRequest } from "../redux-modules/declarations/apis/groups";
import {
  TGroupCreateRequest,
  TGroupUpdateRequest,
} from "../redux-modules/declarations/maps/groups";
import apiClient from "../utils/apiClient";

export const apiGroupsList = (params: TAPIGroupsListRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.get(API_ENDPOINTS.GROUPS, { params });

export const apiGroupCreate = (request: TGroupCreateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.post(API_ENDPOINTS.GROUPS, request);

export const apiGroupUpdate = (request: TGroupUpdateRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.GROUPS + "/" + request.id, request);

export const apiGroupDelete = (groupId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.GROUPS + "/" + groupId);
