/** @format */
import moment from "moment";

export const utilityGetDaysArray = (s: string, e: string) => {
  for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) {
    a.push(new Date(d));
  }
  return a;
};

export const utilityGetDatesSiblingsToOneDate = (date: Date): string[] => {
  const dates: string[] = [];
  const dateStart = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 12);
  const dateEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 11);
  const days = utilityGetDaysArray(dateStart.toISOString(), dateEnd.toISOString());
  days.forEach(day => {
    dates.push(day.toISOString());
  });
  return dates;
};

export const getRangeByDate = (date: moment.Moment): moment.Moment[] => {
  let fromDate = moment(date).startOf("week");
  let toDate = moment(date).endOf("week");
  let diff = toDate.add(1, "day").diff(fromDate, "days");
  let range = [];
  for (let i = 0; i < diff; i++) {
    range.push(moment(fromDate).add(i, "days"));
  }
  return range;
};
