/** @format */

import { TCustomPresence, TPresence } from "../declarations/maps/presences";
import { TStore } from "../declarations/store";

export const selectorPresenceList = (store: TStore): TCustomPresence[] =>
  store.presences.presenceList || [];

export const selectorPresence = (store: TStore, id: string): TCustomPresence | undefined =>
  store.presences.presenceList?.find(presence => presence.original.id === id) || undefined;

export const selectorPresencesOwnersUnique = (store: TStore): TCustomPresence[] => {
  if (!store.presences.presenceList) return [];
  const presences = store.presences.presenceList.map(e => ({ presence_id: e.original.id, ...e }));
  let uniqueIds: string[] = [];

  const uniquePresencesOwners = presences.filter(element => {
    const isDuplicate = element.original.user && uniqueIds.includes(element.original.user?.id);
    if (!isDuplicate && element.original.user) {
      uniqueIds.push(element.original.user.id);
      return true;
    }
    return false;
  });
  return uniquePresencesOwners;
};

export const selectorCustomPresencesOwnersUnique = (store: TStore): TCustomPresence[] => {
  if (!store.presences.presenceList) return [];
  const presences = store.presences.presenceList.map(e => ({ presence_id: e.original.id, ...e }));
  let uniqueIds: string[] = [];

  const uniquePresencesOwners = presences.filter(element => {
    const isDuplicate = element.original.user && uniqueIds.includes(element.original.user?.id);
    if (!isDuplicate && element.original.user) {
      uniqueIds.push(element.original.user.id);
      return true;
    }
    return false;
  });
  return uniquePresencesOwners;
};
