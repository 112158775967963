/** @format */

export const SETTINGSTABS = {
  GENERAL_INFO: "",
  DOCUMENT_TYPES: "/tipologia-documento",
  REQUEST_TYPES: "/tipologia-richiesta",
  MODEL_DETAILS: "/campi-aggiuntivi",
  PRESENCE_TYPES: "/tipologia-presenze",
  TRIGGERS: "/triggers",
};

export const USERTABS = {
  PERSONAL_INFO: "",
  DOCUMENTS: "/documenti",
  PROFESIONAL_INFO: "/informazioni-professionali",
  INFORMAZIONI_AGGIUNTIVE: "/informazioni-aggiuntive",
  ASSIGNMENTS: "/dotazioni",
  COMMUNICATIONS: "/comunicazioni",
};

export const WAREHOUSESTABS = {
  GENERAL: "",
  AVAILABLE: "/disponibilita",
  ASSIGNED: "/assegnazioni",
};
