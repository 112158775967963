/** @format */

import React from "react";
import styled from "styled-components";
import { TTheme } from "..";
import folderImageThemeAzure from "../../../../assets/svg/folder2.svg";
import folderImageThemeMinimal from "../../../../assets/svg/folder_theme2.svg";
import { BaseColor } from "../../../../theme/maps/Colors";
import { hexToRgb } from "../../../../utils/styles";

interface Props {
  folder_name: string;
  theme: TTheme;
  onClick: (category: string) => void;
}

export const Folder = ({ folder_name, onClick, theme }: Props): JSX.Element => {
  return folder_name != "null" ? (
    <StyledDiv onClick={() => onClick(folder_name)}>
      <StyledImg src={theme === "azure" ? folderImageThemeAzure : folderImageThemeMinimal} />
      <span>{folder_name}</span>
    </StyledDiv>
  ) : (
    <></>
  );
};

const StyledImg = styled.img`
  width: 110px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  font-weight: 600;

  margin: 10px;
  padding: 5px;
  max-width: 140px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.1"});
    border: 1px solid rgb(${hexToRgb("" + BaseColor) + ", 0.2"});
    border-radius: 7px;
  }
`;
