/** @format */

import React, { useEffect, useState } from "react";
import Export from "../../../assets/svg/export.svg";
import { Col, Row, Tooltip } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UISearch } from "../../ui/SearchInput";
import { UITable } from "../../ui/Table";
import { UIButton } from "../../ui/Button";
import { Routes } from "../../../utils/Routes";
import { useDispatch, useSelector } from "react-redux";
import {
  actionUserListPageSizeRequest,
  actionUsersListRequest,
} from "../../../redux-modules/actions/actionsUsers";
import {
  selectorUserListPageSize,
  selectorUsersList,
} from "../../../redux-modules/selectors/selectorsUsers";
import { useDebouncedInput } from "../../../redux-modules/hooks/useDebouncedInput";
import { TablePaginationConfig } from "antd/lib/table";
import {
  selectorUserDeleted,
  selectorUsersListLoading,
} from "../../../redux-modules/selectors/selectorsUI";
import { userListColumns } from "../../../constants/tables/userListColumns";
import { utilityFormatUsersToTableDataSourceList } from "../../../utils/format/userTable";
import { UIImage } from "../../ui/Image";
import { UICard } from "../../ui/Card";
import { UIText } from "../../ui/Text";
import UITransition from "../../ui/Transition";
import { UILink } from "../../ui/Link";
import apiClient from "../../../utils/apiClient";
import { API_ENDPOINTS } from "../../../constants/api";
import { showNotification } from "../../../utils/showNotification";
import UIModal from "../../ui/Modal";
import { UICheckbox } from "../../ui/Checkbox";
import { UserExportColumns } from "../../../constants/maps/BackendEnums";
import { utilityColumnsToUserReadable } from "../../../utils/strings/stringToHumanReadable";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { e } from "../ViewUserEdit/partials/ViewDocumentDrawer";

interface UserExportFieldsAPI {
  [k: string]: string;
}

export const ViewUserList = (): JSX.Element => {
  const dispatch = useDispatch();
  const userList = useSelector(selectorUsersList);
  const userListLoading = useSelector(selectorUsersListLoading);
  const isUserDeleted = useSelector(selectorUserDeleted);
  const defaultPageSize = useSelector(selectorUserListPageSize);
  const permissionList = useSelector(selectorPermissionList);
  const canDeleteUsers = hasPermission(permissionList, "User", "Delete");
  const canExportUsers = hasPermission(permissionList, "User", "Export");
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [userExportFields, setUserExportFields] = useState<UserExportFieldsAPI>();
  const [checkedParams, setCheckedParams] = useState<string[]>([]);

  useEffect(() => {
    if (isUserDeleted)
      dispatch(
        actionUsersListRequest({
          query: debouncedQuery || undefined,
          columns: ["name", "surname", "email"],
          page_size: defaultPageSize,
        }),
      );
  }, [isUserDeleted]);

  const [debouncedQuery, currentQuery, setQuery] = useDebouncedInput("", () => true, false, 600);

  const formattedUserList = utilityFormatUsersToTableDataSourceList(userList.data);
  const formattedWithIcon = formattedUserList.map(el => ({
    ...el,
    avatar: !!el.is_disabled ? (
      <Tooltip title="Utente disabilitato">
        <UIImage
          //@ts-ignore
          src={el.avatar}
          round
          imgWidth="56px"
          borderColor={BaseColor}
          imgHeight="56px"
        />
      </Tooltip>
    ) : (
      <UIImage
        //@ts-ignore
        src={el.avatar}
        round
        imgWidth="56px"
        borderColor={BaseColor}
        imgHeight="56px"
      />
    ),
  }));

  const showExportModal = () => {
    setIsExportModalVisible(true);
  };

  const handleExportCancel = () => {
    setIsExportModalVisible(false);
  };

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    dispatch(
      actionUsersListRequest({
        page: JSON.stringify(pagination.current || pagination.defaultCurrent),
        query: debouncedQuery || undefined,
        columns: ["name", "surname", "email"],
        page_size: pagination.pageSize,
      }),
    );
  };

  //@ts-ignore
  const onChangeCheckbox = e => {
    if (e.target.checked) setCheckedParams([...checkedParams, e.target.value]);
    else setCheckedParams(checkedParams.filter(el => el !== e.target.value));
  };
  const handleExport = () => {
    apiClient
      .post(API_ENDPOINTS.USERS_EXPORT, {
        params: checkedParams,
      })
      .then(r => {
        if (!!r.data.success) {
          showNotification("success", "Bene!", r.data.success);
          window.open(r.data.url, "_blank");
        }
      });
  };

  useEffect(() => {
    dispatch(
      actionUsersListRequest({
        query: debouncedQuery || undefined,
        columns: ["group", "name", "surname", "email"],
        page_size: defaultPageSize,
      }),
    );
  }, [debouncedQuery]);

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.USER_EXPORT_FIELDS).then(r => {
      setUserExportFields(r.data);
    });
  }, []);

  return (
    <>
      <UIModal
        title={"Esporta Utenti"}
        visible={isExportModalVisible}
        onOk={handleExport}
        okText="Esporta"
        okButtonProps={{
          style: { borderRadius: "7px", backgroundColor: BaseColor, borderColor: BaseColor },
        }}
        cancelButtonProps={{ style: { borderRadius: "7px" } }}
        onCancel={handleExportCancel}
      >
        <UITitle level={4}>Seleziona i campi da esportare</UITitle>
        {!!userExportFields &&
          Object.keys(userExportFields).map(el => (
            <>
              <UICheckbox
                key={el}
                value={el}
                defaultChecked={checkedParams.includes(el)}
                onChange={onChangeCheckbox}
              />
              <UIText style={{ marginLeft: "10px", textTransform: "capitalize" }}>
                {userExportFields[el]}
              </UIText>

              <br />
            </>
          ))}
      </UIModal>
      <UITransition>
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col>
            <UITitle level={2} color={BaseColor} data-cy="page-title">
              Dipendenti
            </UITitle>
          </Col>
          <Col>
            <UILink
              data-cy="new-user-button"
              route={Routes.usersNew}
              label={
                <UIButton type="primary" data-cy="new-user-button">
                  Crea Dipendente
                </UIButton>
              }
            />
          </Col>
        </Row>
        <UICard>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col span={14} />
            <Col span={5}>
              <UISearch placeholder="Cerca..." onChange={setQuery} value={currentQuery} />
            </Col>
            {!!canExportUsers && (
              <Col span={5}>
                <UIButton style={{ width: "100%" }} onClick={showExportModal}>
                  <UIImage css={"margin-right: 8px;"} imgWidth={"13px"} src={Export} />
                  <UIText>Esporta</UIText>
                </UIButton>
              </Col>
            )}
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <UITable
                size="middle"
                data-cy="user-list-table"
                loading={userListLoading}
                columns={userListColumns}
                dataSource={formattedWithIcon}
                onChange={handleTableChange}
                pagination={{
                  ...userList.pagination,
                }}
              />
            </Col>
          </Row>
        </UICard>
      </UITransition>
    </>
  );
};
