/** @format */

import { TActionType } from "../declarations/actionTypes";
import {
  TActionUserListPageSizeFailure,
  TActionUserListPageSizeRequest,
  TActionUserListPageSizeSuccess,
  TActionUsersListFailure,
  TActionUsersListRequest,
  TActionUsersListSuccess,
} from "../declarations/actions/users";
import { TUsers } from "../declarations/maps/users";

type Actions =
  | TActionUsersListRequest
  | TActionUsersListSuccess
  | TActionUsersListFailure
  | TActionUserListPageSizeRequest
  | TActionUserListPageSizeSuccess
  | TActionUserListPageSizeFailure;

export const reducerUsers = (
  prevStore: TUsers = {
    usersList: {
      data: [],
      pagination: { current: 0, pageSize: 15, lastPage: 0, total: 0 },
      loading: false,
    },
  },
  action: Actions,
): TUsers => {
  switch (action.type) {
    case TActionType.usersListRequest:
      return {
        ...prevStore,
        usersList: {
          data: [],
          pagination: { current: 0, pageSize: 15, lastPage: 0, total: 0 },
          loading: false,
        },
      };
    case TActionType.usersListSuccess:
      return { ...prevStore, usersList: action.payload };
    case TActionType.usersListFailure:
      return {
        ...prevStore,
        usersList: {
          data: [],
          pagination: { current: 0, pageSize: 15, lastPage: 0, total: 0 },
          loading: false,
        },
      };
    case TActionType.usersListPageSizeRequest:
      return {
        ...prevStore,
        usersList: {
          ...prevStore.usersList,
          pagination: { ...prevStore.usersList.pagination, pageSize: action.payload },
        },
      };
    default:
      return prevStore;
  }
};
