/** @format */

import { Row, Col, Form, Skeleton, Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../constants/api";
import {
  actionProductDeleteRequest,
  actionProductUpdateRequest,
} from "../../../redux-modules/actions/actionsProducts";
import { TActionProductDeleteRequest } from "../../../redux-modules/declarations/actions/products";
import { TProduct } from "../../../redux-modules/declarations/maps/products";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import {
  selectorProductDeleted,
  selectorProductUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../theme/maps/Colors";
import apiClient from "../../../utils/apiClient";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { Routes } from "../../../utils/Routes";
import { DeleteCard } from "../../layout/DeleteCard";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UICheckbox } from "../../ui/Checkbox";
import { UIInput } from "../../ui/Input";
import { UILink } from "../../ui/Link";
import { UIText } from "../../ui/Text";
import { UITextArea } from "../../ui/Textarea";
import { UITitle } from "../../ui/Title";

export const ViewProductEdit = () => {
  //@ts-ignore
  const { id_product } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();
  const isProductUpdated = useSelector(selectorProductUpdated);
  const isProductDeleted = useSelector(selectorProductDeleted);
  const [productInfo, setProductInfo] = useState<TProduct>();
  const permissionList = useSelector(selectorPermissionList);

  const canDeleteProduct = hasPermission(permissionList, "Product", "Delete");

  const removeProduct = (): TActionProductDeleteRequest =>
    dispatch(actionProductDeleteRequest(id_product));

  //@ts-ignore
  const handleUpdate = (e): void => {
    const contentObj = {
      id: id_product,
      name: e.name,
      description: e.description,
      note: e.note,
      is_specific: e.specific,
    };
    //dispatch

    dispatch(actionProductUpdateRequest(contentObj));
  };

  useEffect(() => {
    if (isProductUpdated || isProductDeleted) history.push(Routes.products);
  }, [isProductUpdated, isProductDeleted]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      const response = await apiClient.get(API_ENDPOINTS.PRODUCTS + "/" + id_product);
      //@ts-ignore
      setProductInfo(response.data.data);
    };
    fetchData();
  }, []);

  return !!!productInfo ? (
    <UICard style={{ marginBottom: "20px" }}>
      <Skeleton active />
    </UICard>
  ) : (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <Row>
            <Col>
              <UITitle level={2} color={BaseColor} data-cy="page-title">
                Modifica Prodotto
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <UILink route={Routes.products} label={"Prodotti"} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>{productInfo ? productInfo.name : ""}</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
      </Row>
      <Form
        style={{ marginTop: "15px" }}
        name="new-product"
        className="new-product"
        data-cy="new-product-form"
        onFinish={handleUpdate}
        initialValues={productInfo}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={10}>
                  <Form.Item
                    name="name"
                    label={<UIText strong={true}>Nome</UIText>}
                    rules={[{ required: false, message: "Inserisci un nome valido!" }]}
                    extra="Il nome del prodotto"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    style={{ marginLeft: "5px" }}
                    name="is_specific"
                    label={<UIText strong={true}>Chiedi seriale</UIText>}
                    valuePropName="checked"
                    extra="Seleziona se andrà indicato il seriale in fase di assegnazione"
                  >
                    <StyledCheckbox />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={18}>
                  <Form.Item
                    name="description"
                    label={<UIText strong={true}>Descrizione</UIText>}
                    extra="Breve descrizione del prodotto"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={18}>
                  <Form.Item
                    name="note"
                    label={<UIText strong={true}>Note</UIText>}
                    extra="Note aggiuntive"
                  >
                    <UITextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" gutter={[24, 24]}>
                <Col>
                  <UILink route={Routes.products} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item>
                    <UIButton type="primary" data-cy="edit-product-button" htmlType="submit">
                      + Modifica Prodotto
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
      {canDeleteProduct && (
        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col xs={24}>
            <DeleteCard
              data-cy="remove-product-card"
              actionToDispatch={removeProduct}
              title="Elimina Prodotto"
              text="Cliccando sul bottone elimina eliminerai definitivamente il prodotto selezionato. Assicurati di voler rimuoverlo."
              removeButtonLabel="Elimina Prodotto"
            />
          </Col>
        </Row>
      )}
    </>
  );
};

const StyledCheckbox = styled(UICheckbox)`
  .ant-checkbox-inner {
    width: 32px;
    height: 32px;
    border-radius: 7px;
  }
  .ant-checkbox-inner:after {
    left: 10px;
  }
  .ant-checkbox {
    font-size: 0px;
  }
`;
