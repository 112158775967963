/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaModelDetailCreate,
  sagaModelDetailDelete,
  sagaModelDetailList,
  sagaModelDetailUpdate,
} from "../sagas/sagasModelDetails";

export function* watcherModelDetails(): unknown {
  yield takeLatest(TActionType.modelDetailCreateRequest, sagaModelDetailCreate);
  yield takeLatest(TActionType.modelDetailListRequest, sagaModelDetailList);
  yield takeLatest(TActionType.modelDetailDeleteRequest, sagaModelDetailDelete);
  yield takeLatest(TActionType.modelDetailUpdateRequest, sagaModelDetailUpdate);
  yield takeLatest(TActionType.modelDetailUpdateSuccess, sagaModelDetailList);
}
