/** @format */

import { Checkbox } from "antd";
import { CheckboxProps } from "antd/es/checkbox";
import styled from "styled-components";

interface Props extends CheckboxProps {}

export const UICheckbox = styled(Checkbox)<Props>`
  border-radius: 5px;
`;
