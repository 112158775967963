/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Avatar, Col, Collapse, Image, Modal, Row, Tag, Tooltip } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { UIText } from "../Text";
import styled from "styled-components";
import { TDocumentComplete } from "../../../redux-modules/declarations/maps/documents";
import moment from "moment";
import UIModal from "../Modal";
import { CustomUserItem } from "../../renderers/UserInfoCard";
import { BaseColor } from "../../../theme/maps/Colors";
import { hexToRgb } from "../../../utils/styles";
const { Panel } = Collapse;

interface Props {
  file: TDocumentComplete;
  visible: boolean;
  onDismiss: () => void;
}

export const UIFilePreview = ({ file, visible, onDismiss }: Props): JSX.Element => {
  //  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  pdfjs.GlobalWorkerOptions.workerSrc = `/pdfWorker.js`;
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const containerRef = useRef<HTMLDivElement>(null);

  //@ts-ignore
  const onDocumentLoadSuccess = (pdf): void => setNumPages(pdf.numPages);

  const pagePrev = (): void => setPageNumber(pageNumber - 1);
  const pageNext = (): void => setPageNumber(pageNumber + 1);
  const isFirstPage = pageNumber <= 1;
  //@ts-ignore
  const isLastPage = pageNumber >= numPages || false;

  return (
    <UIModal
      title="Dettagli file"
      visible={visible}
      onOk={onDismiss}
      //@ts-ignore
      ref={containerRef}
      onCancel={onDismiss}
    >
      <div style={{ width: "100%" }}>
        <StyledDocument
          file={{ url: file.url }}
          onLoadSuccess={onDocumentLoadSuccess}
          error={() => <DetailComponent file={file} />}
        >
          <Page
            pageNumber={pageNumber}
            // 416 default width of the modal
            width={416 / 3}
          />
          <DetailComponent file={file} />
        </StyledDocument>
      </div>
    </UIModal>
  );
};

const DetailComponent = ({ file }: { file: TDocumentComplete }): JSX.Element => {
  return (
    <DetailDiv>
      <Row>
        <Col>
          <UIText>
            Nome: <span style={{ fontWeight: "bold" }}>{file.filename}</span>
          </UIText>
        </Col>
      </Row>

      <Row>
        <Col>
          <UIText>
            Tipologia: <span style={{ fontWeight: "bold" }}>{file.document_type_name}</span>
          </UIText>
        </Col>
      </Row>

      <Row>
        <Col>
          <UIText>
            Data caricamento:{" "}
            <span style={{ fontWeight: "bold" }}>
              {
                //@ts-ignore
                moment(file.created_at).format("DD/MM/YYYY")
              }
            </span>
          </UIText>
        </Col>
      </Row>
      {file.expiring_at && (
        <Row>
          <Col>
            <UIText>
              Scadenza:{" "}
              <span style={{ fontWeight: "bold" }}>
                {moment(file.expiring_at).format("DD/MM/YYYY")}
              </span>
            </UIText>
          </Col>
        </Row>
      )}
      {file.date_read && (
        <Row>
          <Col>
            <UIText>
              Visualizzato il:{" "}
              <span style={{ fontWeight: "bold" }}>
                {moment(file.date_read).format("DD/MM/YYYY HH:mm")}
              </span>
            </UIText>
          </Col>
        </Row>
      )}
      {!!file.receivers && (
        <>
          <Row style={{ marginTop: "5px", width: "100%" }}>
            <Col span={24}>
              <CustomCollapse>
                <Panel header="Non letto da:" key="1">
                  {file.receivers
                    .filter(e => !e.read)
                    .map(e => (
                      <Tag
                        style={{
                          padding: "3px 5px",
                          borderRadius: "7px",
                          cursor: "default",
                          marginBottom: "3px",
                        }}
                        color={!!e.read ? "green" : "geekblue"}
                      >
                        {"\u00A0"}
                        {e.name}
                      </Tag>
                    ))}
                  {!file.receivers.filter(e => !e.read).length && <UIText>Nessuno</UIText>}
                </Panel>
                <Panel header="Letto da:" key="2">
                  {file.receivers
                    .filter(e => e.read)
                    .map(e => (
                      <Tooltip title={"Letta il " + moment(e.read).format("DD/MM/YYYY HH:MM")}>
                        <Tag
                          style={{
                            padding: "3px 5px",
                            borderRadius: "7px",
                            cursor: "default",
                            marginBottom: "3px",
                          }}
                          color={!!e.read ? "green" : "geekblue"}
                        >
                          {"\u00A0"}
                          {e.name}
                        </Tag>
                      </Tooltip>
                    ))}
                  {!file.receivers.filter(e => e.read).length && <UIText>Nessuno</UIText>}
                </Panel>
              </CustomCollapse>
            </Col>
          </Row>
        </>
      )}
    </DetailDiv>
  );
};

const StyledDocument = styled(Document)`
  display: inline-flex;
  .react-pdf__Page {
    border: 1px solid #dfdfdf;
  }
  .react-pdf__Page__annotations annotationLayer {
    display: none;
  }
`;

const DetailDiv = styled.div`
  margin-left: 5px;
`;

const CustomCollapse = styled(Collapse)`
  border-radius: 7px;
  width: 100%;
  background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.25"}) !important;

  .ant-collapse-header {
    padding: 5px 5px;
  }

  .ant-collapse-item:not(.ant-collapse-item-active):last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: none;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse > .ant-collapse-item-active:last-child {
    border-radius: none;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse-item-active:last-child,
  .ant-collapse-item-active:last-child > .ant-collapse-header {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse-content {
    max-height: 180px;
    overflow-y: auto;
  }
  .ant-list-bordered {
    border: none;
  }
`;
