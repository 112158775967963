/** @format */

import { Row, Col, Form } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { actionProductCreateRequest } from "../../../redux-modules/actions/actionsProducts";
import { selectorProductUpdated } from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../theme/maps/Colors";
import { Routes } from "../../../utils/Routes";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UICheckbox } from "../../ui/Checkbox";
import { UIInput } from "../../ui/Input";
import { UILink } from "../../ui/Link";
import { UIText } from "../../ui/Text";
import { UITextArea } from "../../ui/Textarea";
import { UITitle } from "../../ui/Title";

export const ViewProductCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isProductCreated = useSelector(selectorProductUpdated);

  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      name: e.name,
      description: e.description,
      note: e.note,
      is_specific: e.is_specific,
    };
    //dispatch

    dispatch(actionProductCreateRequest(contentObj));
  };

  useEffect(() => {
    if (isProductCreated) history.push(Routes.products);
  }, [isProductCreated]);

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Nuovo Prodotto
          </UITitle>
        </Col>
      </Row>
      <Form
        name="new-product"
        className="new-product"
        data-cy="new-product-form"
        onFinish={handleSave}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={10}>
                  <Form.Item
                    name="name"
                    label={<UIText strong={true}>Nome</UIText>}
                    rules={[{ required: true, message: "Inserisci un nome valido!" }]}
                    extra="Il nome del prodotto"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    style={{ marginLeft: "5px" }}
                    name="is_specific"
                    label={<UIText strong={true}>Chiedi seriale</UIText>}
                    valuePropName="checked"
                    extra="Seleziona se andrà indicato il seriale in fase di assegnazione"
                  >
                    <StyledCheckbox />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={18}>
                  <Form.Item
                    name="description"
                    label={<UIText strong={true}>Descrizione</UIText>}
                    extra="Breve descrizione del prodotto"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col xs={24} md={18}>
                  <Form.Item
                    name="note"
                    label={<UIText strong={true}>Note</UIText>}
                    extra="Note aggiuntive"
                  >
                    <UITextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" gutter={[24, 24]}>
                <Col>
                  <UILink route={Routes.products} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item>
                    <UIButton type="primary" data-cy="new-product-button" htmlType="submit">
                      + Crea Prodotto
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const StyledCheckbox = styled(UICheckbox)`
  .ant-checkbox-inner {
    width: 32px;
    height: 32px;
    border-radius: 7px;
  }
  .ant-checkbox-inner:after {
    left: 10px;
  }
  .ant-checkbox {
    font-size: 0px;
  }
`;
