/** @format */

import { TShift } from "../declarations/maps/shifts";
import { TStore } from "../declarations/store";

export const selectorShiftList = (store: TStore): TShift[] => store.shifts.shiftList || [];

export const selectorShiftListByGroupAndDepartment = (
  store: TStore,
  groupId: string[],
  departmentId: string[],
): TShift[] => {
  const shiftList = selectorShiftList(store);
  return shiftList.filter(
    shift =>
      (!!groupId.length ? groupId.includes(shift.group.id) : true) &&
      (!!departmentId.length ? departmentId.includes(shift.department.id) : true),
  );
};
export const selectorShift = (store: TStore, id: string): TShift | undefined =>
  store.shifts.shiftList?.find(shift => shift.id === id) || undefined;
