/** @format */

import React, { useState } from "react";
import styled from "styled-components";
import { TTheme } from "..";
import addImageThemeAzure from "../../../../assets/svg/add_theme1.svg";
import addImageThemeMinimal from "../../../../assets/svg/add_theme2.svg";
import { BaseColor } from "../../../../theme/maps/Colors";
import { hexToRgb } from "../../../../utils/styles";

interface Props {
  onClick?: () => void;
  theme: TTheme;
}
export const AddFile = ({ onClick, theme }: Props): JSX.Element => {
  return (
    <>
      <StyledDiv onClick={onClick}>
        <StyledImg src={theme === "azure" ? addImageThemeAzure : addImageThemeMinimal} />
        <span
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "140px",
          }}
        >
          Nuovo Documento
        </span>
      </StyledDiv>
    </>
  );
};

const StyledImg = styled.img`
  width: 110px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  border: 1px solid transparent;
  position: relative;
  padding: 5px;
  max-width: 140px;
  font-weight: 600;
  align-items: center;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.1"});
    border: 1px solid rgb(${hexToRgb("" + BaseColor) + ", 0.2"});
    border-radius: 7px;
  }
`;
