/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaFleetCreate,
  sagaFleetDelete,
  sagaFleetList,
  sagaFleetUpdate,
} from "../sagas/sagasFleets";

export function* watcherFleets(): unknown {
  yield takeLatest(TActionType.fleetCreateRequest, sagaFleetCreate);
  yield takeLatest(TActionType.fleetListRequest, sagaFleetList);
  yield takeLatest(TActionType.fleetDeleteRequest, sagaFleetDelete);
  yield takeLatest(TActionType.fleetUpdateRequest, sagaFleetUpdate);
}
