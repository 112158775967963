/** @format */

import { Col, Form, Image, Row } from "antd";
import React from "react";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import Logo from "../../../assets/svg/logo_strong_vertical.svg";
//import Logo from "../../../assets/images/logo_notext.png";
import { UITitle } from "../../ui/Title";
import { Colors, BaseColor } from "../../../theme/maps/Colors";
import { UIText } from "../../ui/Text";
import apiClient from "../../../utils/apiClient";
import { API_ENDPOINTS } from "../../../constants/api";
import { showNotification } from "../../../utils/showNotification";
import { Routes } from "../../../utils/Routes";
import { useHistory, useParams } from "react-router";
import { UIInputPassword } from "../../ui/Input/InputPassword";

export const ViewResetPassword = (): JSX.Element => {
  const history = useHistory();
  //@ts-ignore
  const { token } = useParams();
  const handleRecovery = (forgotObject: {}): void => {
    const decodedMail = atob(token);
    //@ts-ignore
    const mailObj = { email: decodedMail, password: forgotObject.password, token: token };
    apiClient(API_ENDPOINTS.PASSWORD_RESET, {
      method: "POST",
      data: mailObj,
    })
      .then(response => {
        showNotification("success", "Recupero password", "Password ripristinata correttamente!");
        history.push(Routes.login);
      })
      .catch(error => {
        showNotification(
          "error",
          "Recupero password",
          "Errore nella richiesta di ripristino password",
        );
        history.push(Routes.login);
      });
  };

  return (
    <>
      <Row justify="center" gutter={[16, 16]}>
        <Col lg={8} sm={18} xs={24}>
          <UICard
            style={{
              boxShadow: "-1px 1px 7px #454545",
            }}
          >
            <Row justify="center" gutter={[16, 16]} style={{ marginBottom: 15 }}>
              <Col>
                <img
                  width={200}
                  src={Logo}
                  onClick={() => history.push(Routes.login)}
                  style={{ cursor: "pointer" /* filter: "drop-shadow(1px -1px 0.02rem #aeacac)"*/ }}
                />
              </Col>
            </Row>
            {/*
              <Row justify="center" gutter={[16, 16]}>
              <Col onClick={() => history.push(Routes.login)} style={{ cursor: "pointer" }}>
                <UITitle
                  level={2}
                  style={{ fontWeight: "bold", filter: "drop-shadow(1px -1px 0.15rem #aeacac)" }}
                  color={BaseColor}
                >
                  {process.env.REACT_APP_NAME?.toUpperCase()}
                </UITitle>
              </Col>
            </Row>
              */}

            <Row>
              <Col xs={24}>
                <UITitle level={3} color={Colors.darkGray}>
                  Ripristino password
                </UITitle>
              </Col>
            </Row>
            <Row style={{ marginBottom: "25px" }}>
              <Col xs={24}>
                <UIText color={Colors.darkGray}>Indica una nuova password</UIText>
              </Col>
            </Row>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleRecovery}
              onFinishFailed={(e): void => console.log(e)}
            >
              <Form.Item
                name="password"
                label={"Nuova Password"}
                rules={[{ required: true, message: "Inserisci una password valida!" }]}
              >
                <UIInputPassword placeholder="Password" />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Conferma Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Per favore conferma la password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords that you entered do not match!"),
                      );
                    },
                  }),
                ]}
              >
                <UIInputPassword placeholder="Conferma Password" />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <UIButton
                  bgColor={BaseColor}
                  color={Colors.primary}
                  hoverBgColor={BaseColor}
                  borderColor={BaseColor}
                  htmlType="submit"
                  fullWidth
                >
                  RESETTA PASSWORD
                </UIButton>
              </Form.Item>
            </Form>
          </UICard>
        </Col>
      </Row>
    </>
  );
};
