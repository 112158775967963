/** @format */

import React, { useEffect } from "react";
import { Col, Row, Tabs } from "antd";
import { UIText } from "../../../../ui/Text";
import { UITitle } from "../../../../ui/Title";
import { TModel } from "../../../../../constants/maps/BackendEnums";
import { DynamicModelTab } from "./Tab";
import { useDispatch } from "react-redux";
import { actionModelDetailListRequest } from "../../../../../redux-modules/actions/actionsModelDetail";
import { useHistory } from "react-router";
import { SETTINGSTABS } from "../../../../../constants/settings";
import { Routes } from "../../../../../utils/Routes";
const { TabPane } = Tabs;

const ModelDetails = (): JSX.Element => {
  const models = Object.values(TModel);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (history.location.pathname === Routes.settings + SETTINGSTABS.MODEL_DETAILS)
      dispatch(actionModelDetailListRequest());
  }, []);
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <UITitle level={4}>Campi Aggiuntivi</UITitle>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey={models[0]}>
            {models.map(model => (
              <TabPane key={model} tab={model}>
                <DynamicModelTab model={model as TModel} />
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(ModelDetails);
