/** @format */

import { AxiosResponse } from "axios";
import { put, all, call } from "redux-saga/effects";
import {
  apiModelDetailCreate,
  apiModelDetailDelete,
  apiModelDetailList,
  apiModelDetailUpdate,
} from "../../api/apiModelDetails";
import { showNotification } from "../../utils/showNotification";
import {
  actionModelDetailCreateSuccess,
  actionModelDetailCreateReset,
  actionModelDetailCreateFailure,
  actionModelDetailListFailure,
  actionModelDetailUpdateSuccess,
  actionModelDetailUpdateReset,
  actionModelDetailUpdateFailure,
  actionModelDetailDeleteSuccess,
  actionModelDetailDeleteReset,
  actionModelDetailListSuccess,
} from "../actions/actionsModelDetail";
import { actionGroupDeleteFailure } from "../actions/actionsGroups";
import {
  TActionModelDetailCreateRequest,
  TActionModelDetailListRequest,
  TActionModelDetailUpdateRequest,
  TActionModelDetailDeleteRequest,
} from "../declarations/actions/modelDetail";
import { TAPIResponse } from "../declarations/apis/general";
/* import { TModelDetail } from "../declarations/maps/modelDetails";
 */
export function* sagaModelDetailCreate(action: TActionModelDetailCreateRequest): unknown {
  try {
    const response = yield call(apiModelDetailCreate, action.payload);

    yield put(actionModelDetailCreateSuccess(response.data.id));
    yield put(actionModelDetailCreateReset());
    showNotification("success", "Creato!", "Dettaglio creato con successo");
  } catch (e) {
    yield put(actionModelDetailCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaModelDetailList(action: TActionModelDetailListRequest): unknown {
  try {
    const response: AxiosResponse<TAPIResponse<unknown>> = yield call(apiModelDetailList);

    //@ts-ignore
    yield put(actionModelDetailListSuccess(response.data.data));
  } catch (e) {
    yield put(actionModelDetailListFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaModelDetailUpdate(action: TActionModelDetailUpdateRequest): unknown {
  try {
    yield call(apiModelDetailUpdate, action.payload);

    yield all([put(actionModelDetailUpdateSuccess()), put(actionModelDetailUpdateReset())]);
    showNotification("success", "Aggiornato!", "Dettaglio modificato con successo");
  } catch (e) {
    yield put(actionModelDetailUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaModelDetailDelete(action: TActionModelDetailDeleteRequest): unknown {
  try {
    yield call(apiModelDetailDelete, action.payload);

    yield all([
      put(actionModelDetailDeleteSuccess(action.payload)),
      put(actionModelDetailDeleteReset()),
    ]);
    showNotification("success", "Eliminato!", "Dettaglio eliminato con successo");
  } catch (e) {
    yield put(actionGroupDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
