/** @format */

import {
  TActionModelDetailListRequest,
  TActionModelDetailListSuccess,
  TActionModelDetailListFailure,
  TActionModelDetailCreateRequest,
  TActionModelDetailCreateSuccess,
  TActionModelDetailCreateFailure,
  TActionModelDetailUpdateRequest,
  TActionModelDetailUpdateSuccess,
  TActionModelDetailUpdateFailure,
  TActionModelDetailUpdateReset,
  TActionModelDetailDeleteRequest,
  TActionModelDetailDeleteSuccess,
  TActionModelDetailDeleteFailure,
  TActionModelDetailDeleteReset,
} from "../declarations/actions/modelDetail";
import { TActionType, TAction } from "../declarations/actionTypes";
import {
  TModelDetail,
  TModelDetailCreateRequest,
  TModelDetailUpdateRequest,
} from "../declarations/maps/modelDetails";

export const actionModelDetailListRequest = (): TActionModelDetailListRequest => ({
  type: TActionType.modelDetailListRequest,
});

export const actionModelDetailListSuccess = (
  payload: TModelDetail[],
): TActionModelDetailListSuccess => ({
  type: TActionType.modelDetailListSuccess,
  payload,
});

export const actionModelDetailListFailure = (): TActionModelDetailListFailure => ({
  type: TActionType.modelDetailListFailure,
});

export const actionModelDetailCreateRequest = (
  payload: TModelDetailCreateRequest,
): TActionModelDetailCreateRequest => ({ type: TActionType.modelDetailCreateRequest, payload });
export const actionModelDetailCreateSuccess = (
  payload: string,
): TActionModelDetailCreateSuccess => ({ type: TActionType.modelDetailCreateSuccess, payload });
export const actionModelDetailCreateFailure = (): TActionModelDetailCreateFailure => ({
  type: TActionType.modelDetailCreateFailure,
});
export const actionModelDetailCreateReset = (): TAction => ({
  type: TActionType.modelDetailCreateReset,
});

export const actionModelDetailUpdateRequest = (
  payload: TModelDetailUpdateRequest,
): TActionModelDetailUpdateRequest => ({
  type: TActionType.modelDetailUpdateRequest,
  payload,
});

export const actionModelDetailUpdateSuccess = (): TActionModelDetailUpdateSuccess => ({
  type: TActionType.modelDetailUpdateSuccess,
});

export const actionModelDetailUpdateFailure = (): TActionModelDetailUpdateFailure => ({
  type: TActionType.modelDetailUpdateFailure,
});

export const actionModelDetailUpdateReset = (): TActionModelDetailUpdateReset => ({
  type: TActionType.modelDetailUpdateReset,
});

export const actionModelDetailDeleteRequest = (
  payload: string,
): TActionModelDetailDeleteRequest => ({
  type: TActionType.modelDetailDeleteRequest,
  payload,
});

export const actionModelDetailDeleteSuccess = (
  payload: string,
): TActionModelDetailDeleteSuccess => ({
  type: TActionType.modelDetailDeleteSuccess,
  payload,
});

export const actionModelDetailDeleteFailure = (): TActionModelDetailDeleteFailure => ({
  type: TActionType.modelDetailDeleteFailure,
});
export const actionModelDetailDeleteReset = (): TActionModelDetailDeleteReset => ({
  type: TActionType.modelDetailDeleteReset,
});
