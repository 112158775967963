import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import { TDocumentTypeCreateRequest, TDocumentTypeUpdateRequest } from "../redux-modules/declarations/maps/documentTypes";
import apiClient from "../utils/apiClient";

export const apiDocumentTypeList = (): Promise<AxiosResponse<unknown>> => {return apiClient.get(API_ENDPOINTS.DOCUMENT_TYPES);}

export const apiDocumentTypeCreate = (request: TDocumentTypeCreateRequest): Promise<AxiosResponse<unknown>> => 
apiClient.post(API_ENDPOINTS.DOCUMENT_TYPES, request);

export const apiDocumentTypeUpdate = (request: TDocumentTypeUpdateRequest): Promise<AxiosResponse<unknown>> => 
apiClient.put(API_ENDPOINTS.DOCUMENT_TYPES + "/" + request.id, request);

export const apiDocumentTypeDelete = (userId: string,): Promise<AxiosResponse<unknown>> =>
apiClient.delete(API_ENDPOINTS.DOCUMENT_TYPES + '/' + userId);