/** @format */

import {
  TActionWarehouseListFailure,
  TActionWarehouseListRequest,
  TActionWarehouseListSuccess,
} from "../declarations/actions/warehouses";
import { TActionType } from "../declarations/actionTypes";
import { TWarehouses } from "../declarations/maps/warehouses";

type Actions =
  | TActionWarehouseListRequest
  | TActionWarehouseListSuccess
  | TActionWarehouseListFailure;

export const reducerWarehouses = (
  prevStore: TWarehouses = { warehouseList: [] },
  action: Actions,
): TWarehouses => {
  switch (action.type) {
    case TActionType.warehouseListRequest:
      return { ...prevStore, warehouseList: [] };
    case TActionType.warehouseListSuccess:
      return { ...prevStore, warehouseList: action.payload };
    case TActionType.warehouseListFailure:
      return { ...prevStore, warehouseList: [] };
    default:
      return prevStore;
  }
};
