/** @format */

import React, { useEffect, useState } from "react";
import { Avatar, Menu } from "antd";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Routes } from "../../../../utils/Routes";
import { DownOutlined } from "@ant-design/icons";
import { actionLogoutRequest } from "../../../../redux-modules/actions/actionsAuth";
import { useDispatch, useSelector } from "react-redux";
import { TActionLogoutRequest } from "../../../../redux-modules/declarations/actions/auth";
import { selectorLoggedUser } from "../../../../redux-modules/selectors/selectorsAuth";
import NoticeBell from "../../NoticeBell";
import {
  selectorNotificationList,
  selectorUnreadNotificationCount,
} from "../../../../redux-modules/selectors/selectorNotifications";
import { TNotificationPopupInfo } from "../../../../redux-modules/declarations/maps/notifications";
import { utilityNotificationToNotificationPopupInfo } from "../../../../utils/format/notifications";
import { isMobileOnly } from "react-device-detect";
import apiClient from "../../../../utils/apiClient";
import { API_ENDPOINTS } from "../../../../constants/api";

const { SubMenu } = Menu;

const CustomMenu = styled(Menu)`
  float: right;
  justify-content: end;
  width: 100%;
  .ant-menu-submenu-title {
    color: #dfdfdf !important;
  }
  .ant-dropdown-trigger {
    width: 100%;
    text-align: right;
  }
`;

export const UserMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectorLoggedUser);
  const notifications = useSelector(selectorNotificationList);
  const [formattedNotifications, setFormattedNotifications] = useState<TNotificationPopupInfo[]>(
    [],
  );
  const unreadCount = useSelector(selectorUnreadNotificationCount);
  useEffect(() => {
    setFormattedNotifications(
      utilityNotificationToNotificationPopupInfo(notifications, history, user?.id),
    );
  }, [notifications]);

  const logOutHelper = () => {
    apiClient.post(API_ENDPOINTS.LOGOUT).then(res => {
      dispatch(actionLogoutRequest());
    });
  };

  if (isMobileOnly)
    return (
      <CustomMenu theme="dark" mode="horizontal">
        <Menu.Item key="logout" onClick={logOutHelper}>
          <div>Log Out</div>
        </Menu.Item>
      </CustomMenu>
    );
  return (
    <CustomMenu theme="dark" mode="horizontal" style={{ width: "100%", textAlign: "right" }}>
      <NoticeBell loading={false}>
        <NoticeBell.Tab
          tabKey="notification"
          count={unreadCount}
          list={formattedNotifications}
          title="Notifiche"
          emptyText="Nessuna notifica"
          showViewMore
        />
      </NoticeBell>
      <SubMenu
        key="user"
        title={
          <>
            <Avatar size="small" style={{ marginRight: "5px" }} src={user?.avatar} />
            {user?.name}
            <DownOutlined style={{ marginLeft: "5px", fontSize: 12 }} />
          </>
        }
      >
        <Menu.Item
          key="profile"
          onClick={(): void =>
            history.push(Routes.usersEdit.replace(":id_user", user ? user.id : ""))
          }
        >
          <span>Profilo</span>
        </Menu.Item>
        <Menu.Item key="desk-logout" onClick={logOutHelper}>
          <div>Log Out</div>
        </Menu.Item>
      </SubMenu>
    </CustomMenu>
  );
};
