/** @format */

import { Col } from "antd";
import moment from "moment";
import React from "react";
import { BaseColor } from "../../../../theme/maps/Colors";
import { UIText } from "../../../ui/Text";

interface Props {
  activeDay: moment.Moment;
  date: string;
}
export const IndexDay = ({ date, activeDay }: Props): JSX.Element => {
  return (
    <Col span={1} style={{ textAlign: "center" }}>
      <UIText
        style={{
          textTransform: "capitalize",
          cursor: "default",
          fontWeight:
            moment(date).format("DD/MM/YYYY") == moment(activeDay).format("DD/MM/YYYY")
              ? "bold"
              : "normal",
          fontSize: "13px",
          color:
            moment(date).format("DD/MM/YYYY") == moment(activeDay).format("DD/MM/YYYY")
              ? BaseColor
              : moment(date).day() == 6 || moment(date).day() == 0
              ? "#d00000"
              : "black",
        }}
      >
        {moment(date).format("dd DD")}
      </UIText>
    </Col>
  );
};
