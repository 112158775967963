/** @format */

import { Col, Drawer, Form, Row } from "antd";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { API_ENDPOINTS } from "../../../../constants/api";
import { actionFleetCreateSuccess } from "../../../../redux-modules/actions/actionsFleets";
import apiClient from "../../../../utils/apiClient";
import { showNotification } from "../../../../utils/showNotification";
import { UIButton } from "../../../ui/Button";
import { UIInput } from "../../../ui/Input";

interface Props {
  visible: boolean;
  onClose: (refresh?: boolean) => void;
}

export const FleeCreateDrawer: FC<Props> = ({ visible, onClose }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleClose = () => {
    if (onClose)
      // quello che vogliamo
      onClose();
    form.resetFields();
  };

  const onSave = () => {
    form.validateFields().then(values => {
      const obj = {
        modello: form.getFieldValue("modello"),
        marca: form.getFieldValue("marca"),
        targa: form.getFieldValue("targa"),
        costo_per_chilometro: form.getFieldValue("costo_per_chilometro"),
      };
      apiClient
        .post(API_ENDPOINTS.FLEETS, obj)
        .then(res => {
          showNotification("success", "Mezzo salvato", "Mezzo salvato con successo!");
          form.resetFields();
          // OPERARE SUL REDUCER
          dispatch(actionFleetCreateSuccess(res.data.data));
          if (onClose) onClose(true);
        })
        .catch(err => {
          form.resetFields();
          showNotification(
            "error",
            "Errore", //@ts-ignore
            err.response.data.errors
              ? //@ts-ignore
                Object.values(err.response.data.errors).join(`\n`)
              : //@ts-ignore
                err.response.data.message,
          );
        });
    });
  };

  return (
    <Drawer
      title="Nuovo Mezzo"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <Row justify="space-between">
          <Col>
            <Form.Item name="submit">
              <UIButton form="form" key="submit" htmlType="submit" type="primary">
                Salva
              </UIButton>
            </Form.Item>
          </Col>
          <Col>
            <UIButton onClick={() => onClose()}>Annulla</UIButton>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <>
            <Form id="form" form={form} onFinish={onSave}>
              <Row>
                <Col span={24}>
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="marca"
                        label="Marca"
                        rules={[{ required: true, message: "Inserisci la marca del mezzo" }]}
                      >
                        <UIInput />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="modello"
                        label="Modello"
                        rules={[{ required: true, message: "Indica il modello del mezzo" }]}
                      >
                        <UIInput />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={13}>
                      <Form.Item
                        name="targa"
                        label="Targa"
                        rules={[{ required: true, message: "Indica la targa del mezzo" }]}
                      >
                        <UIInput />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="costo_per_chilometro"
                        label="€/Km"
                        rules={[{ required: true, message: "Inserisci il costo per kilometro" }]}
                      >
                        <UIInput type="number" step={0.1} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </>
        </Col>
      </Row>
    </Drawer>
  );
};
