/** @format */

import { TUser } from "../declarations/maps/auth";
import { TUserList } from "../declarations/maps/users";
import { TStore } from "../declarations/store";

export const selectorUsersList = (store: TStore): TUserList =>
  store.users.usersList || {
    data: [],
    pagination: { current: 0, pageSize: 0, lastPage: 0, total: 0 },
    loading: false,
  };

export const selectorUserById = (store: TStore, userId?: string): TUser | undefined => {
  if (!userId) return undefined;
  return store.users.usersList.data.find(user => user.id === userId) || undefined;
};

export const selectorUserListPageSize = (store: TStore): number =>
  store.users.usersList?.pagination.pageSize || 15;
