/** @format */

import {
  TActionJustificativeListRequest,
  TActionJustificativeListSuccess,
  TActionJustificativeListFailure,
  TActionJustificativeListLoadingRequest,
  TActionJustificativeListLoadingSuccess,
  TActionJustificativeListLoadingFailure,
  TActionJustificativeCreateRequest,
  TActionJustificativeCreateSuccess,
  TActionJustificativeCreateFailure,
  TActionJustificativeUpdateRequest,
  TActionJustificativeUpdateSuccess,
  TActionJustificativeUpdateFailure,
  TActionJustificativeUpdateReset,
  TActionJustificativeDeleteRequest,
  TActionJustificativeDeleteSuccess,
  TActionJustificativeDeleteFailure,
  TActionJustificativeDeleteReset,
} from "../declarations/actions/justificatives";
import { TAction, TActionType } from "../declarations/actionTypes";
import { TAPIJustificativeListRequest } from "../declarations/apis/justificatives";
import {
  TJustificativeList,
  TJustificativeCreateRequest,
  TJustificativeUpdateRequest,
} from "../declarations/maps/justificatives";

//List
export const actionJustificativeListRequest = (
  payload: TAPIJustificativeListRequest,
): TActionJustificativeListRequest => ({
  type: TActionType.justificativeListRequest,
  payload,
});

export const actionJustificativeListSuccess = (
  payload: TJustificativeList,
): TActionJustificativeListSuccess => ({
  type: TActionType.justificativeListSuccess,
  payload,
});

export const actionJustificativeListFailure = (): TActionJustificativeListFailure => ({
  type: TActionType.justificativeListFailure,
});

//---

//Loading

export const actionJustificativeListLoadingRequest = (): TActionJustificativeListLoadingRequest => ({
  type: TActionType.justificativeListLoadingRequest,
});

export const actionJustificativeListLoadingSuccess = (): TActionJustificativeListLoadingSuccess => ({
  type: TActionType.justificativeListLoadingSuccess,
});

export const actionJustificativeListLoadingFailure = (): TActionJustificativeListLoadingFailure => ({
  type: TActionType.justificativeListLoadingFailure,
});

// ---
//Create
export const actionJustificativeCreateRequest = (
  payload: TJustificativeCreateRequest,
): TActionJustificativeCreateRequest => ({ type: TActionType.justificativeCreateRequest, payload });
export const actionJustificativeCreateSuccess = (
  payload: string,
): TActionJustificativeCreateSuccess => ({ type: TActionType.justificativeCreateSuccess, payload });
export const actionJustificativeCreateFailure = (): TActionJustificativeCreateFailure => ({
  type: TActionType.justificativeCreateFailure,
});
export const actionJustificativeCreateReset = (): TAction => ({
  type: TActionType.justificativeCreateReset,
});
// ---
// Update
export const actionJustificativeUpdateRequest = (
  payload: TJustificativeUpdateRequest,
): TActionJustificativeUpdateRequest => ({
  type: TActionType.justificativeUpdateRequest,
  payload,
});

export const actionJustificativeUpdateSuccess = (): TActionJustificativeUpdateSuccess => ({
  type: TActionType.justificativeUpdateSuccess,
});

export const actionJustificativeUpdateFailure = (): TActionJustificativeUpdateFailure => ({
  type: TActionType.justificativeUpdateFailure,
});

export const actionJustificativeUpdateReset = (): TActionJustificativeUpdateReset => ({
  type: TActionType.justificativeUpdateReset,
});

// ---
// Delete
export const actionJustificativeDeleteRequest = (
  payload: string,
): TActionJustificativeDeleteRequest => ({
  type: TActionType.justificativeDeleteRequest,
  payload,
});

export const actionJustificativeDeleteSuccess = (
  payload: string,
): TActionJustificativeDeleteSuccess => ({
  type: TActionType.justificativeDeleteSuccess,
  payload,
});

export const actionJustificativeDeleteFailure = (): TActionJustificativeDeleteFailure => ({
  type: TActionType.justificativeDeleteFailure,
});

export const actionJustificativeDeleteReset = (): TActionJustificativeDeleteReset => ({
  type: TActionType.justificativeDeleteReset,
});

// ---
