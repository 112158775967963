/** @format */

import { Col, Form, Row, TimePicker } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { TShiftTypeCreateRequest } from "../../../../redux-modules/declarations/maps/shiftTypes";
import UIModal from "../../../ui/Modal";
import { UICheckbox } from "../../../ui/Checkbox";

interface Props {
  visible: boolean;
  onOk: (obj: TShiftTypeCreateRequest) => void;
  onCancel: () => void;
}

export const NewShiftTypeModal = ({ visible, onOk, onCancel }: Props): JSX.Element => {
  const handleOk = (): void => {
    const shiftType: TShiftTypeCreateRequest = {
      time_from: moment(form.getFieldValue("time_from")).format("HH:mm:ss"),
      time_to: moment(form.getFieldValue("time_to")).format("HH:mm:ss"),
      is_notturno: form.getFieldValue("is_notturno")
    };
    onOk(shiftType);
    form.resetFields();
  };
  const [form] = Form.useForm();
  return (
    <UIModal
      title={"Nuova Tipologia Turno"}
      visible={visible}
      onOk={handleOk}
      okText="Crea"
      okButtonProps={{
        style: { borderRadius: "7px" },
      }}
      cancelButtonProps={{ style: { borderRadius: "7px" } }}
      onCancel={onCancel}
    >
      <Form form={form}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Form.Item name="time_from" label="Dalle" rules={[{ required: true }]}>
                  <CustomTimePicker format={"HH:mm"} showNow={false} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Form.Item name="time_to" label="Alle" rules={[{ required: true }]}>
                  <CustomTimePicker format={"HH:mm"} showNow={false} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Form.Item
            name="is_notturno"
            label="Il turno supera la mezzanotte"
            rules={[{ required: false }]}
            valuePropName="checked"
            initialValue={false}
          >
            <UICheckbox />
          </Form.Item>
        </Row>
      </Form>
    </UIModal>
  );
};
const CustomTimePicker = styled(TimePicker)`
  width: 100%;
  border-radius: 7px;
`;
