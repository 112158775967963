/** @format */

import { TUser, TUserMinified } from "./auth";

export interface TExpense {
  id: string;
  status: TExpenseStatus;
  date: string | moment.Moment;
  travel_id?: string;
  attachments: TAttachment[];
  note?: string;
  rimborso_richiesto: string;
  rimborso_approvato: string;
  user: TUserMinified;
  expense_type: TExpenseType;
  is_chilometrico: boolean;
  fleet_type?: TFleetType;
  departure?: string;
  arrival?: string;
  is_return_included?: boolean;
  km_distance?: string;
  rimborso_previsto?: string;
  fleet_id?: string;
}

export type TExpenseList = TExpense[];

export interface TExpenses {
  expenseList: TExpenseList;
}

export type TFleetType = "Aziendale" | "Personale";

export type TExpenseType =
  | "Hotel"
  | "Trasporti"
  | "Carburante"
  | "Parcheggio"
  | "Noleggio Auto"
  | "Pasti"
  | "Pedaggio"
  | "Taxi"
  | "Telefono e connettività"
  | "Altro";

export const ExpenseTypes = [
  "Hotel",
  "Trasporti",
  "Carburante",
  "Parcheggio",
  "Noleggio Auto",
  "Pasti",
  "Pedaggio",
  "Taxi",
  "Telefono e connettività",
  "Altro",
];

export interface TAttachment {
  id: string;
  url_path: string;
  filename: string;
}

export type TExpenseStatus = "Accettata" | "Pendente" | "Rifiutata";

export interface TExpenseCreateRequest {
  status: TExpenseStatus;
  date: string;
  travel_id?: string;
  note?: string;
  rimborso_richiesto: string;
  rimborso_approvato: string;
  user_id: string;
  expense_type: TExpenseType;
  is_chilometrico: boolean;
  fleet_type?: TFleetType;
  departure?: string;
  arrival?: string;
  is_return_included?: boolean;
  km_distance?: string;
  rimborso_previsto?: string;
  fleet_id?: string;
  attachments: File[];
}

export interface TExpenseUpdateRequest {
  id: string;
  status: TExpenseStatus;
  date: string;
  travel_id?: string;
  note?: string;
  rimborso_richiesto: string;
  rimborso_approvato: string;
  user_id: string;
  expense_type: TExpenseType;
  is_chilometrico: boolean;
  fleet_type?: TFleetType;
  departure?: string;
  arrival?: string;
  is_return_included?: boolean;
  km_distance?: string;
  rimborso_previsto?: string;
  fleet_id?: string;
}
