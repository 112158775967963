/** @format */

export enum TModel {
  "User" = "User",
  "JustificativeType" = "JustificativeType",
  "PresenceType" = "PresenceType",
}
export enum TInputType {
  "text",
  "number",
  "email",
  "date",
  "datetime",
  "time",
  "password",
}

export enum UserExportColumns {
  ID = "id",
  NAME = "name",
  SURNAME = "surname",
  EMAIL = "email",
  FISCAL_CODE = "fiscal_code",
  OFFICE = "office",
  ROLE = "role",
  USER_DETAILS = "user_details",
  FERIE_RESIDUO = "ferie_residuo",
  ROL_RESIDUO = "rol_residuo",
  CONTRACT_WEEKLY_HOURS = "contract_weekly_hours",
}

export enum REQUEST_SIZE_UNITS {
  GIORNI = "GIORNI",
  ORE = "ORE",
}

export enum PresenceTypeIDS{
  TURNO_ORDINARIO = "1",
  STRAORDINARIO = "2",
}