/** @format */

import {
  TActionDeviceListRequest,
  TActionDeviceListSuccess,
  TActionDeviceListFailure,
  TActionDeviceListLoadingRequest,
  TActionDeviceListLoadingSuccess,
  TActionDeviceListLoadingFailure,
  TActionDeviceCreateRequest,
  TActionDeviceCreateSuccess,
  TActionDeviceCreateFailure,
  TActionDeviceUpdateRequest,
  TActionDeviceUpdateSuccess,
  TActionDeviceUpdateFailure,
  TActionDeviceUpdateReset,
  TActionDeviceDeleteRequest,
  TActionDeviceDeleteSuccess,
  TActionDeviceDeleteFailure,
  TActionDeviceDeleteReset,
} from "../declarations/actions/devices";
import { TAction, TActionType } from "../declarations/actionTypes";
import {
  TDeviceList,
  TDeviceCreateRequest,
  TDeviceUpdateRequest,
} from "../declarations/maps/devices";

//List
export const actionDeviceListRequest = (): TActionDeviceListRequest => ({
  type: TActionType.deviceListRequest,
});

export const actionDeviceListSuccess = (payload: TDeviceList): TActionDeviceListSuccess => ({
  type: TActionType.deviceListSuccess,
  payload,
});

export const actionDeviceListFailure = (): TActionDeviceListFailure => ({
  type: TActionType.deviceListFailure,
});

//---

//Loading

export const actionDeviceListLoadingRequest = (): TActionDeviceListLoadingRequest => ({
  type: TActionType.deviceListLoadingRequest,
});

export const actionDeviceListLoadingSuccess = (): TActionDeviceListLoadingSuccess => ({
  type: TActionType.deviceListLoadingSuccess,
});

export const actionDeviceListLoadingFailure = (): TActionDeviceListLoadingFailure => ({
  type: TActionType.deviceListLoadingFailure,
});

// ---
//Create
export const actionDeviceCreateRequest = (
  payload: TDeviceCreateRequest,
): TActionDeviceCreateRequest => ({ type: TActionType.deviceCreateRequest, payload });

export const actionDeviceCreateSuccess = (payload: string): TActionDeviceCreateSuccess => ({
  type: TActionType.deviceCreateSuccess,
  payload,
});
export const actionDeviceCreateFailure = (): TActionDeviceCreateFailure => ({
  type: TActionType.deviceCreateFailure,
});
export const actionDeviceCreateReset = (): TAction => ({
  type: TActionType.deviceCreateReset,
});
// ---
// Update
export const actionDeviceUpdateRequest = (
  payload: TDeviceUpdateRequest,
): TActionDeviceUpdateRequest => ({
  type: TActionType.deviceUpdateRequest,
  payload,
});

export const actionDeviceUpdateSuccess = (): TActionDeviceUpdateSuccess => ({
  type: TActionType.deviceUpdateSuccess,
});

export const actionDeviceUpdateFailure = (): TActionDeviceUpdateFailure => ({
  type: TActionType.deviceUpdateFailure,
});

export const actionDeviceUpdateReset = (): TActionDeviceUpdateReset => ({
  type: TActionType.deviceUpdateReset,
});

// ---
// Delete
export const actionDeviceDeleteRequest = (payload: string): TActionDeviceDeleteRequest => ({
  type: TActionType.deviceDeleteRequest,
  payload,
});

export const actionDeviceDeleteSuccess = (payload: string): TActionDeviceDeleteSuccess => ({
  type: TActionType.deviceDeleteSuccess,
  payload,
});

export const actionDeviceDeleteFailure = (): TActionDeviceDeleteFailure => ({
  type: TActionType.deviceDeleteFailure,
});

export const actionDeviceDeleteReset = (): TActionDeviceDeleteReset => ({
  type: TActionType.deviceDeleteReset,
});

// ---
