/** @format */

import { TAPIResponse } from "../../redux-modules/declarations/apis/general";
import {
  TJustificative,
  TJustificativeDataSource,
  TJustificativeDataSourceList,
  TJustificativeList,
} from "../../redux-modules/declarations/maps/justificatives";
import { utilityFormatApiJustificativeList } from "./apiJustificative";

export const utilityFormatJustificativeTable = (
  list: TAPIResponse<TJustificative[]>,
  loading: boolean = false,
): TJustificativeList => ({
  data: utilityFormatApiJustificativeList(list.data), //passaggio al momento inutile, ma che servirà per tutte le altre (anche per questa, refactoring)
  pagination: {
    current: list.meta?.current_page || 0,
    pageSize: list.meta?.per_page || 0,
    lastPage: list.meta?.last_page || 0,
    total: list.meta?.total || 0,
  },
  loading,
});

export const utilityFormatJustificativeToTableDataSource = (
  group: TJustificative,
): TJustificativeDataSource => ({
  id: group.id,
  key: group.id,
  name: group.name,
  //@ts-ignore
  location: group.location || "",
  //@ts-ignore
  usersCount: group.usersCount || "",
});

export const utilityFormatJustificativeToTableDataSourceList = (
  list: TJustificative[],
): TJustificativeDataSourceList =>
  list.map(group => utilityFormatJustificativeToTableDataSource(group));
