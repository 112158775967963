/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import {
  TWarehouseCreateRequest,
  TWarehouseUpdateRequest,
} from "../redux-modules/declarations/maps/warehouses";
import apiClient from "../utils/apiClient";

export const apiWarehousesList = (): Promise<AxiosResponse<unknown>> =>
  apiClient.get(API_ENDPOINTS.WAREHOUSES);

export const apiWarehouseCreate = (
  request: TWarehouseCreateRequest,
): Promise<AxiosResponse<unknown>> => apiClient.post(API_ENDPOINTS.WAREHOUSES, request);

export const apiWarehouseUpdate = (
  request: TWarehouseUpdateRequest,
): Promise<AxiosResponse<unknown>> =>
  apiClient.put(API_ENDPOINTS.WAREHOUSES + "/" + request.id, request);

export const apiWarehouseDelete = (warehouseId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.WAREHOUSES + "/" + warehouseId);
