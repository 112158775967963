/** @format */

import {
  TActionExpenseListRequest,
  TActionExpenseListSuccess,
  TActionExpenseListFailure,
  TActionExpenseListLoadingRequest,
  TActionExpenseListLoadingSuccess,
  TActionExpenseListLoadingFailure,
  TActionExpenseCreateRequest,
  TActionExpenseCreateSuccess,
  TActionExpenseCreateFailure,
  TActionExpenseUpdateRequest,
  TActionExpenseUpdateSuccess,
  TActionExpenseUpdateFailure,
  TActionExpenseUpdateReset,
  TActionExpenseDeleteRequest,
  TActionExpenseDeleteSuccess,
  TActionExpenseDeleteFailure,
  TActionExpenseDeleteReset,
} from "../declarations/actions/expenses";
import { TAction, TActionType } from "../declarations/actionTypes";
import {
  TExpenseList,
  TExpenseCreateRequest,
  TExpenseUpdateRequest,
} from "../declarations/maps/expenses";

//List
export const actionExpenseListRequest = (
  date_from: string,
  date_to: string,
): TActionExpenseListRequest => ({
  type: TActionType.expenseListRequest,
  payload: { date_from, date_to },
});

export const actionExpenseListSuccess = (payload: TExpenseList): TActionExpenseListSuccess => ({
  type: TActionType.expenseListSuccess,
  payload,
});

export const actionExpenseListFailure = (): TActionExpenseListFailure => ({
  type: TActionType.expenseListFailure,
});

//---

//Loading

export const actionExpenseListLoadingRequest = (): TActionExpenseListLoadingRequest => ({
  type: TActionType.expenseListLoadingRequest,
});

export const actionExpenseListLoadingSuccess = (): TActionExpenseListLoadingSuccess => ({
  type: TActionType.expenseListLoadingSuccess,
});

export const actionExpenseListLoadingFailure = (): TActionExpenseListLoadingFailure => ({
  type: TActionType.expenseListLoadingFailure,
});

// ---
//Create
export const actionExpenseCreateRequest = (
  payload: TExpenseCreateRequest,
): TActionExpenseCreateRequest => ({ type: TActionType.expenseCreateRequest, payload });

export const actionExpenseCreateSuccess = (payload: string): TActionExpenseCreateSuccess => ({
  type: TActionType.expenseCreateSuccess,
  payload,
});
export const actionExpenseCreateFailure = (): TActionExpenseCreateFailure => ({
  type: TActionType.expenseCreateFailure,
});
export const actionExpenseCreateReset = (): TAction => ({
  type: TActionType.expenseCreateReset,
});
// ---
// Update
export const actionExpenseUpdateRequest = (
  payload: TExpenseUpdateRequest,
): TActionExpenseUpdateRequest => ({
  type: TActionType.expenseUpdateRequest,
  payload,
});

export const actionExpenseUpdateSuccess = (): TActionExpenseUpdateSuccess => ({
  type: TActionType.expenseUpdateSuccess,
});

export const actionExpenseUpdateFailure = (): TActionExpenseUpdateFailure => ({
  type: TActionType.expenseUpdateFailure,
});

export const actionExpenseUpdateReset = (): TActionExpenseUpdateReset => ({
  type: TActionType.expenseUpdateReset,
});

// ---
// Delete
export const actionExpenseDeleteRequest = (payload: string): TActionExpenseDeleteRequest => ({
  type: TActionType.expenseDeleteRequest,
  payload,
});

export const actionExpenseDeleteSuccess = (payload: string): TActionExpenseDeleteSuccess => ({
  type: TActionType.expenseDeleteSuccess,
  payload,
});

export const actionExpenseDeleteFailure = (): TActionExpenseDeleteFailure => ({
  type: TActionType.expenseDeleteFailure,
});

export const actionExpenseDeleteReset = (): TActionExpenseDeleteReset => ({
  type: TActionType.expenseDeleteReset,
});

// ---
