/** @format */

import {
  TActionCommunicationListRequest,
  TActionCommunicationListSuccess,
  TActionCommunicationListFailure,
  TActionCommunicationListLoadingRequest,
  TActionCommunicationListLoadingSuccess,
  TActionCommunicationListLoadingFailure,
  TActionCommunicationCreateRequest,
  TActionCommunicationCreateSuccess,
  TActionCommunicationCreateFailure,
  TActionCommunicationDeleteRequest,
  TActionCommunicationDeleteSuccess,
  TActionCommunicationDeleteFailure,
  TActionCommunicationDeleteReset,
  TActionCommunicationReadRequest,
} from "../declarations/actions/communications";
import { TActionType, TAction } from "../declarations/actionTypes";
import {
  TCommunication,
  TCommunicationCreateRequest,
  TCommunicationUpdateRequest,
} from "../declarations/maps/communications";

//List
export const actionCommunicationListRequest = (): TActionCommunicationListRequest => ({
  type: TActionType.communicationListRequest,
});

export const actionCommunicationListSuccess = (
  payload: TCommunication[],
): TActionCommunicationListSuccess => ({
  type: TActionType.communicationListSuccess,
  payload,
});

export const actionCommunicationListFailure = (): TActionCommunicationListFailure => ({
  type: TActionType.communicationListFailure,
});

//---

//Loading

export const actionCommunicationListLoadingRequest = (): TActionCommunicationListLoadingRequest => ({
  type: TActionType.communicationListLoadingRequest,
});

export const actionCommunicationListLoadingSuccess = (): TActionCommunicationListLoadingSuccess => ({
  type: TActionType.communicationListLoadingSuccess,
});

export const actionCommunicationListLoadingFailure = (): TActionCommunicationListLoadingFailure => ({
  type: TActionType.communicationListLoadingFailure,
});

// ---
//Create
export const actionCommunicationCreateRequest = (
  payload: TCommunicationCreateRequest,
): TActionCommunicationCreateRequest => ({ type: TActionType.communicationCreateRequest, payload });
export const actionCommunicationCreateSuccess = (
  payload: string,
): TActionCommunicationCreateSuccess => ({
  type: TActionType.communicationCreateSuccess,
  payload,
});
export const actionCommunicationCreateFailure = (): TActionCommunicationCreateFailure => ({
  type: TActionType.communicationCreateFailure,
});
export const actionCommunicationCreateReset = (): TAction => ({
  type: TActionType.communicationCreateReset,
});
// ---

// Delete
export const actionCommunicationDeleteRequest = (
  payload: string,
): TActionCommunicationDeleteRequest => ({
  type: TActionType.communicationDeleteRequest,
  payload,
});

export const actionCommunicationDeleteSuccess = (
  payload: string,
): TActionCommunicationDeleteSuccess => ({
  type: TActionType.communicationDeleteSuccess,
  payload,
});

export const actionCommunicationDeleteFailure = (): TActionCommunicationDeleteFailure => ({
  type: TActionType.communicationDeleteFailure,
});
export const actionCommunicationDeleteReset = (): TActionCommunicationDeleteReset => ({
  type: TActionType.communicationDeleteReset,
});
// ---

export const actionCommunicationReadRequest = (
  payload: string,
): TActionCommunicationReadRequest => ({
  type: TActionType.communicationReadRequest,
  payload,
});
