/** @format */

import {
  TActionShiftTypeListFailure,
  TActionShiftTypeListRequest,
  TActionShiftTypeListSuccess,
} from "../declarations/actions/shiftTypes";
import { TActionType } from "../declarations/actionTypes";
import { TShiftTypes } from "../declarations/maps/shiftTypes";

type Actions =
  | TActionShiftTypeListRequest
  | TActionShiftTypeListSuccess
  | TActionShiftTypeListFailure;

export const reducerShiftTypes = (
  prevStore: TShiftTypes = { shiftTypeList: [] },
  action: Actions,
): TShiftTypes => {
  switch (action.type) {
    case TActionType.shiftTypeListRequest:
      return { ...prevStore, shiftTypeList: [] };
    case TActionType.shiftTypeListSuccess:
      return { ...prevStore, shiftTypeList: action.payload };
    case TActionType.shiftTypeListFailure:
      return { ...prevStore, shiftTypeList: [] };
    default:
      return prevStore;
  }
};
