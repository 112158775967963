/** @format */

import { takeLatest, takeLeading, takeMaybe } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaPresenceCreate, sagaPresenceList } from "../sagas/sagasPresences";

export function* watcherPresences(): unknown {
  yield takeLeading(TActionType.presenceCreateRequest, sagaPresenceCreate);
  yield takeLatest(TActionType.presenceListRequest, sagaPresenceList);
}
