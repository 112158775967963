/** @format */

import {
  TActionShiftListRequest,
  TActionShiftListSuccess,
  TActionShiftListFailure,
  TActionShiftListLoadingRequest,
  TActionShiftListLoadingSuccess,
  TActionShiftListLoadingFailure,
  TActionShiftCreateRequest,
  TActionShiftCreateSuccess,
  TActionShiftCreateFailure,
  TActionShiftDeleteFailure,
  TActionShiftDeleteRequest,
  TActionShiftDeleteReset,
  TActionShiftDeleteSuccess,
  TActionShiftUpdateFailure,
  TActionShiftUpdateRequest,
  TActionShiftUpdateReset,
  TActionShiftUpdateSuccess,
} from "../declarations/actions/shifts";
import { TAction, TActionType } from "../declarations/actionTypes";
import {
  TShift,
  TShiftCreateRequest,
  TShiftListQuery,
  TShiftUpdateRequest,
} from "../declarations/maps/shifts";

//List
export const actionShiftListRequest = (payload: TShiftListQuery): TActionShiftListRequest => ({
  type: TActionType.shiftListRequest,
  payload,
});

export const actionShiftListSuccess = (payload: TShift[]): TActionShiftListSuccess => ({
  type: TActionType.shiftListSuccess,
  payload,
});

export const actionShiftListFailure = (): TActionShiftListFailure => ({
  type: TActionType.shiftListFailure,
});

//---

//Loading

export const actionShiftListLoadingRequest = (): TActionShiftListLoadingRequest => ({
  type: TActionType.shiftListLoadingRequest,
});

export const actionShiftListLoadingSuccess = (): TActionShiftListLoadingSuccess => ({
  type: TActionType.shiftListLoadingSuccess,
});

export const actionShiftListLoadingFailure = (): TActionShiftListLoadingFailure => ({
  type: TActionType.shiftListLoadingFailure,
});

// ---
//Create
export const actionShiftCreateRequest = (
  payload: TShiftCreateRequest,
): TActionShiftCreateRequest => ({ type: TActionType.shiftCreateRequest, payload });

export const actionShiftCreateSuccess = (payload: TShift): TActionShiftCreateSuccess => ({
  type: TActionType.shiftCreateSuccess,
  payload,
});
export const actionShiftCreateFailure = (): TActionShiftCreateFailure => ({
  type: TActionType.shiftCreateFailure,
});
export const actionShiftCreateReset = (): TAction => ({
  type: TActionType.shiftCreateReset,
});
// ---

// Update
export const actionShiftUpdateRequest = (
  payload: TShiftUpdateRequest,
): TActionShiftUpdateRequest => ({
  type: TActionType.shiftUpdateRequest,
  payload,
});

export const actionShiftUpdateSuccess = (
  payload: TShiftUpdateRequest,
): TActionShiftUpdateSuccess => ({
  type: TActionType.shiftUpdateSuccess,
  payload,
});

export const actionShiftUpdateFailure = (): TActionShiftUpdateFailure => ({
  type: TActionType.shiftUpdateFailure,
});

export const actionShiftUpdateReset = (): TActionShiftUpdateReset => ({
  type: TActionType.shiftUpdateReset,
});

// ---

// Delete
export const actionShiftDeleteRequest = (payload: string): TActionShiftDeleteRequest => ({
  type: TActionType.shiftDeleteRequest,
  payload,
});

export const actionShiftDeleteSuccess = (payload: string): TActionShiftDeleteSuccess => ({
  type: TActionType.shiftDeleteSuccess,
  payload,
});

export const actionShiftDeleteFailure = (): TActionShiftDeleteFailure => ({
  type: TActionType.shiftDeleteFailure,
});

export const actionShiftDeleteReset = (): TActionShiftDeleteReset => ({
  type: TActionType.shiftDeleteReset,
});

// ---
