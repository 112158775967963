/** @format */

import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import styled from "styled-components";

const { Text } = Typography;

interface Props extends TextProps {
  color?: string;
  css?: string;
}

export const UIText = styled(Text)<Props>`
  ${(props): string => (props.color ? "color: " + props.color + "!important;" : "")}
  ${(props): string => props.css || ""}
`;
