/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaPresenceTypeCreate,
  sagaPresenceTypeDelete,
  sagaPresenceTypeList,
  sagaPresenceTypeUpdate,
} from "../sagas/sagasPresenceTypes";

export function* watcherPresenceTypes(): unknown {
  yield takeLatest(TActionType.presenceTypeCreateRequest, sagaPresenceTypeCreate);
  yield takeLatest(TActionType.presenceTypeListRequest, sagaPresenceTypeList);
  yield takeLatest(TActionType.presenceTypeDeleteRequest, sagaPresenceTypeDelete);
  yield takeLatest(TActionType.presenceTypeUpdateRequest, sagaPresenceTypeUpdate);
}
