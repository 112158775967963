/** @format */

import { TPresenceType } from "../declarations/maps/presenceTypes";
import { TStore } from "../declarations/store";

export const selectorPresenceTypeList = (store: TStore): TPresenceType[] =>
  store.presenceTypes.presenceTypeList || [];

export const selectorPresenceType = (store: TStore, id: string): TPresenceType | undefined =>
  store.presenceTypes.presenceTypeList?.find(presenceType => presenceType.id === id) || undefined;
