/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaJustificativeCreate,
  sagaJustificativeDelete,
  sagaJustificativeList,
  sagaJustificativeUpdate,
} from "../sagas/sagasJustificatives";

export function* watcherJustificatives(): unknown {
  yield takeLatest(TActionType.justificativeCreateRequest, sagaJustificativeCreate);
  yield takeLatest(TActionType.justificativeListRequest, sagaJustificativeList);
  yield takeLatest(TActionType.justificativeDeleteRequest, sagaJustificativeDelete);
  yield takeLatest(TActionType.justificativeUpdateRequest, sagaJustificativeUpdate);
}
