/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import { TNotificationReadRequest } from "../redux-modules/declarations/maps/notifications";
import apiClient from "../utils/apiClient";

export const apiNotificationList = (user_id: string): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.NOTIFICATIONS + "/" + user_id);
};

export const apiNotificationRead = (
  notifications: TNotificationReadRequest,
): Promise<AxiosResponse<unknown>> =>
  apiClient.post(API_ENDPOINTS.NOTIFICATION_READ, notifications);

export const apiNotificationDelete = (notificationIds: string[]): Promise<AxiosResponse<unknown>> =>
  apiClient.post(API_ENDPOINTS.NOTIFICATIONS + "/delete", notificationIds);
