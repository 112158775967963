/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification } from "../../utils/showNotification";
import {
  actionExpenseCreateSuccess,
  actionExpenseCreateReset,
  actionExpenseCreateFailure,
  actionExpenseListLoadingRequest,
  actionExpenseListLoadingSuccess,
  actionExpenseListFailure,
  actionExpenseListLoadingFailure,
  actionExpenseUpdateSuccess,
  actionExpenseUpdateReset,
  actionExpenseUpdateFailure,
  actionExpenseDeleteSuccess,
  actionExpenseDeleteReset,
  actionExpenseDeleteFailure,
  actionExpenseListSuccess,
} from "../actions/actionsExpenses";
import {
  TActionExpenseCreateRequest,
  TActionExpenseListRequest,
  TActionExpenseUpdateRequest,
  TActionExpenseDeleteRequest,
} from "../declarations/actions/expenses";
import { TExpense } from "../declarations/maps/expenses";
import {
  apiExpenseCreate,
  apiExpenseUpdate,
  apiExpenseDelete,
  apiExpenseList,
} from "../../api/apiExpenses";

export function* sagaExpenseCreate(action: TActionExpenseCreateRequest): unknown {
  try {
    const response = yield call(apiExpenseCreate, action.payload);

    yield put(actionExpenseCreateSuccess(response.data.id));
    yield put(actionExpenseCreateReset());
    showNotification("success", "Creato!", "Nota spese creata con successo");
  } catch (e) {
    yield put(actionExpenseCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaExpenseList(action: TActionExpenseListRequest): unknown {
  try {
    yield put(actionExpenseListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TExpense[]>> = yield call(
      apiExpenseList,
      action.payload,
    );

    yield put(actionExpenseListSuccess(response.data.data));
    yield put(actionExpenseListLoadingSuccess());
  } catch (e) {
    yield put(actionExpenseListFailure());
    yield put(actionExpenseListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaExpenseUpdate(action: TActionExpenseUpdateRequest): unknown {
  try {
    yield call(apiExpenseUpdate, action.payload);

    yield all([put(actionExpenseUpdateSuccess()), put(actionExpenseUpdateReset())]);
    showNotification("success", "Aggiornato!", "Nota spese modificata con successo");
  } catch (e) {
    yield put(actionExpenseUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}

export function* sagaExpenseDelete(action: TActionExpenseDeleteRequest): unknown {
  try {
    yield call(apiExpenseDelete, action.payload);

    yield all([put(actionExpenseDeleteSuccess(action.payload)), put(actionExpenseDeleteReset())]);
    showNotification("success", "Eliminato!", "Nota spese eliminata con successo");
  } catch (e) {
    yield put(actionExpenseDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
