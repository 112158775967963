/** @format */

import { Col, Row, Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { UICard } from "../../ui/Card";
import Logo from "../../../assets/images/logo_notext.png";
import { useDispatch, useSelector } from "react-redux";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { useHistory } from "react-router";
import { QrReader } from "react-qr-reader";
import styled from "styled-components";
import { UIButton } from "../../ui/Button";
import { SwapOutlined } from "@ant-design/icons";
import { actionPresenceCreateRequest } from "../../../redux-modules/actions/actionsPresences";
import { selectorPresenceUpdated } from "../../../redux-modules/selectors/selectorsUI";

export const ViewBadge = (): JSX.Element => {
  const history = useHistory();
  const [deviceCode, setDeviceCode] = useState("");
  const [writingDeviceCode, setWritingDeviceCode] = useState("");
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [facingMode, setFacingMode] = useState("user");
  const dispatch = useDispatch();
  const isPresenceCreated = useSelector(selectorPresenceUpdated);

  const switchFacingMode = (): void => {
    setFacingMode(facingMode === "user" ? "environment" : "user");
  };
  //@ts-ignore
  const handleResult = (res, err): void => {
    if (!!res && !err) {
      console.log(res);
      if (!isBase64(res.text)) {
        setIsLoading(true);
        const scanData = JSON.parse(res.text);
        res = false;
        dispatch(
          actionPresenceCreateRequest({
            ...scanData,
            origin: "ipad",
            device_code: deviceCode,
          }),
        );
      }
    }

    if (!!err) {
      console.log("errore", err);
    }
  };

  const insertDeviceCode = () => {
    setDeviceCode(writingDeviceCode);
    window.localStorage.setItem("deviceCode", writingDeviceCode);
  };

  const dialerClick = (v: string | number): void => {
    if (typeof v === "string") {
      if (v == "clear") {
        setWritingDeviceCode("");
      } else {
        setWritingDeviceCode(writingDeviceCode.substring(0, writingDeviceCode.length - 1));
      }
    } else {
      setWritingDeviceCode(`${writingDeviceCode}` + `${v}`);
    }
  };

  useEffect(() => {
    if (!!isPresenceCreated) {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }, [isPresenceCreated]);
  useEffect(() => {
    if (!!data) alert(data);
  }, [data]);
  useEffect(() => {
    const currentCode = window.localStorage.getItem("deviceCode");
    if (!!currentCode) setDeviceCode(currentCode);
  }, []);

  return (
    <>
      <Row justify="center" gutter={[16, 16]} style={{ height: "100%" }}>
        <Col lg={10} sm={20} xs={24} style={{ alignSelf: "center" }}>
          <UICard
            data-cy="badge-card"
            style={{
              boxShadow: "-1px 1px 7px #454545",
            }}
          >
            <Row justify="center" gutter={[16, 16]}>
              <Col>
                <img
                  width={80}
                  src={Logo}
                  style={{
                    filter: "drop-shadow(1px -1px 0.02rem #aeacac)",
                  }}
                />
              </Col>
            </Row>
            <Row justify="center" gutter={[16, 16]}>
              <Col>
                <UITitle
                  level={2}
                  style={{ textAlign: "center", fontWeight: "bold", filter: "drop-shadow(1px -1px 0.15rem #aeacac)" }}
                  color={BaseColor}
                >
                  {process.env.REACT_APP_NAME?.toUpperCase()}
                </UITitle>
              </Col>
            </Row>
            {!deviceCode && (
              <>
                <Row justify="center" gutter={[16, 16]}>
                  <Col>
                    <UITitle level={3}>Inserisci il codice del dispositivo</UITitle>
                  </Col>
                </Row>
                <Row justify="center" gutter={[16, 16]}>
                  <Col span={24}>
                    <CusDiv>
                      <table id="dialer_table">
                        <tr>
                          <td id="dialer_input_td" colSpan={3}>
                            <input
                              style={{ textAlign: "center" }}
                              type="number"
                              value={writingDeviceCode}
                              placeholder=""
                            />
                          </td>
                        </tr>
                        <tr className="dialer_num_tr">
                          <td className="dialer_num" onClick={() => dialerClick(1)}>
                            1
                          </td>
                          <td className="dialer_num" onClick={() => dialerClick(2)}>
                            2
                          </td>
                          <td className="dialer_num" onClick={() => dialerClick(3)}>
                            3
                          </td>
                        </tr>
                        <tr className="dialer_num_tr">
                          <td className="dialer_num" onClick={() => dialerClick(4)}>
                            4
                          </td>
                          <td className="dialer_num" onClick={() => dialerClick(5)}>
                            5
                          </td>
                          <td className="dialer_num" onClick={() => dialerClick(6)}>
                            6
                          </td>
                        </tr>
                        <tr className="dialer_num_tr">
                          <td className="dialer_num" onClick={() => dialerClick(7)}>
                            7
                          </td>
                          <td className="dialer_num" onClick={() => dialerClick(8)}>
                            8
                          </td>
                          <td className="dialer_num" onClick={() => dialerClick(9)}>
                            9
                          </td>
                        </tr>
                        <tr className="dialer_num_tr">
                          <td className="dialer_del_td">
                            <img
                              alt="clear"
                              onClick={() => dialerClick("clear")}
                              src="data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJlcmFzZXIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWVyYXNlciBmYS13LTE2IGZhLTd4Ij48cGF0aCBmaWxsPSIjYjFiMWIxIiBkPSJNNDk3Ljk0MSAyNzMuOTQxYzE4Ljc0NS0xOC43NDUgMTguNzQ1LTQ5LjEzNyAwLTY3Ljg4MmwtMTYwLTE2MGMtMTguNzQ1LTE4Ljc0NS00OS4xMzYtMTguNzQ2LTY3Ljg4MyAwbC0yNTYgMjU2Yy0xOC43NDUgMTguNzQ1LTE4Ljc0NSA0OS4xMzcgMCA2Ny44ODJsOTYgOTZBNDguMDA0IDQ4LjAwNCAwIDAgMCAxNDQgNDgwaDM1NmM2LjYyNyAwIDEyLTUuMzczIDEyLTEydi00MGMwLTYuNjI3LTUuMzczLTEyLTEyLTEySDM1NS44ODNsMTQyLjA1OC0xNDIuMDU5em0tMzAyLjYyNy02Mi42MjdsMTM3LjM3MyAxMzcuMzczTDI2NS4zNzMgNDE2SDE1MC42MjhsLTgwLTgwIDEyNC42ODYtMTI0LjY4NnoiIGNsYXNzPSIiPjwvcGF0aD48L3N2Zz4="
                              width="22px"
                              title="Clear"
                            />
                          </td>
                          <td className="dialer_num" onClick={() => dialerClick(0)}>
                            0
                          </td>
                          <td className="dialer_del_td">
                            <img
                              alt="delete"
                              onClick={() => dialerClick("delete")}
                              src="data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJiYWNrc3BhY2UiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjQwIDUxMiIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWJhY2tzcGFjZSBmYS13LTIwIGZhLTd4Ij48cGF0aCBmaWxsPSIjREMxQTU5IiBkPSJNNDY5LjY1IDE4MS42NWwtMTEuMzEtMTEuMzFjLTYuMjUtNi4yNS0xNi4zOC02LjI1LTIyLjYzIDBMMzg0IDIyMi4wNmwtNTEuNzItNTEuNzJjLTYuMjUtNi4yNS0xNi4zOC02LjI1LTIyLjYzIDBsLTExLjMxIDExLjMxYy02LjI1IDYuMjUtNi4yNSAxNi4zOCAwIDIyLjYzTDM1MC4wNiAyNTZsLTUxLjcyIDUxLjcyYy02LjI1IDYuMjUtNi4yNSAxNi4zOCAwIDIyLjYzbDExLjMxIDExLjMxYzYuMjUgNi4yNSAxNi4zOCA2LjI1IDIyLjYzIDBMMzg0IDI4OS45NGw1MS43MiA1MS43MmM2LjI1IDYuMjUgMTYuMzggNi4yNSAyMi42MyAwbDExLjMxLTExLjMxYzYuMjUtNi4yNSA2LjI1LTE2LjM4IDAtMjIuNjNMNDE3Ljk0IDI1Nmw1MS43Mi01MS43MmM2LjI0LTYuMjUgNi4yNC0xNi4zOC0uMDEtMjIuNjN6TTU3NiA2NEgyMDUuMjZDMTg4LjI4IDY0IDE3MiA3MC43NCAxNjAgODIuNzRMOS4zNyAyMzMuMzdjLTEyLjUgMTIuNS0xMi41IDMyLjc2IDAgNDUuMjVMMTYwIDQyOS4yNWMxMiAxMiAyOC4yOCAxOC43NSA0NS4yNSAxOC43NUg1NzZjMzUuMzUgMCA2NC0yOC42NSA2NC02NFYxMjhjMC0zNS4zNS0yOC42NS02NC02NC02NHptMTYgMzIwYzAgOC44Mi03LjE4IDE2LTE2IDE2SDIwNS4yNmMtNC4yNyAwLTguMjktMS42Ni0xMS4zMS00LjY5TDU0LjYzIDI1NmwxMzkuMzEtMTM5LjMxYzMuMDItMy4wMiA3LjA0LTQuNjkgMTEuMzEtNC42OUg1NzZjOC44MiAwIDE2IDcuMTggMTYgMTZ2MjU2eiIgY2xhc3M9IiI+PC9wYXRoPjwvc3ZnPg=="
                              width="25px"
                              title="Delete"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <span id="dialer_call_btn_td" onClick={insertDeviceCode}>
                              OK
                            </span>
                          </td>
                        </tr>
                      </table>
                    </CusDiv>
                  </Col>
                </Row>
              </>
            )}
            {!!deviceCode && (
              <>
                <Row justify="center" gutter={[16, 16]}>
                  <Col span={24}>
                    <UICard
                      style={{
                        marginBottom: "20px",
                        height: "100%",
                        border: "none",
                        display: isLoading ? "block" : "none",
                      }}
                    >
                      <Skeleton active />
                      <Skeleton active />
                    </UICard>
                    {!isLoading && (
                      <CustomQrReader
                        onResult={handleResult}
                        isLoading={isLoading}
                        facingMode={facingMode}
                      />
                    )}
                  </Col>
                </Row>
                <Row justify="center" gutter={[16, 16]} style={{ marginTop: "20px" }}>
                  <Col>
                    <UIButton onClick={switchFacingMode}>
                      <SwapOutlined style={{ marginRight: "5px" }} /> Inverti Camera
                    </UIButton>
                  </Col>
                </Row>
              </>
            )}
          </UICard>
        </Col>
      </Row>
    </>
  );
};

//@ts-ignore
const CustomQrReader = ({ onResult, isLoading, facingMode }) => {
  const lastResult = useRef();

  //@ts-ignore
  const onReadResult = (result, error) => {
    if (!result) return;

    // This callback will keep existing even after
    // this component is unmounted
    // So ignore it (only in this reference) if result keeps repeating
    if (lastResult.current === result.text) {
      return;
    }

    lastResult.current = result.text;
    onResult(result);
  };

  // @ts-ignore
  return (
    <StyledQRReader
      containerStyle={{ display: isLoading ? "none" : "block" }}
      scanDelay={1000}
      constraints={{ facingMode: facingMode }}
      onResult={onReadResult}
    />
  );
};

const StyledQRReader = styled(QrReader)`
  width: 100%;

  video {
    object-fit: cover;
    border-radius: 7px;
  }
`;

const CusDiv = styled.div`
  #dialer_table {
    width: 100%;
    font-size: 1.5em;
  }

  #dialer_table tr td {
    text-align: center;
    height: 50px;
    width: 33%;
  }

  #dialer_table #dialer_input_td {
    border-bottom: 1px solid #fafafa;
  }

  #dialer_table #dialer_input_td input {
    width: 100%;
    border: none;
    font-size: 1.7em;
  }

  /* Remove arrows from type number input : Chrome, Safari, Edge, Opera */
  #dialer_table #dialer_input_td input::-webkit-outer-spin-button,
  #dialer_table #dialer_input_td input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Remove arrows from type number input : Firefox */
  #dialer_table #dialer_input_td input[type="number"] {
    -moz-appearance: textfield;
  }

  #dialer_table #dialer_input_td input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cccccc;
    opacity: 1; /* Firefox */
  }

  #dialer_table #dialer_input_td input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #cccccc;
  }

  #dialer_table #dialer_input_td input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #cccccc;
  }

  #dialer_table #dialer_call_btn_td {
    color: #ffffff;
    background-color: ${BaseColor};
    border: none;
    cursor: pointer;
    width: 100%;
    text-decoration: none;
    padding: 5px 32px;
    text-align: center;
    display: inline-block;
    margin: 10px 2px 4px 2px;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    --webkit-transition: all 300ms ease;
    border-radius: 7px;
  }

  #dialer_table #dialer_call_btn_td:hover {
    background-color: ${BaseColor}eb;
  }

  #dialer_table .dialer_num_tr td {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  #dialer_table .dialer_num_tr td:nth-child(1) {
    border-right: 1px solid #fafafa;
  }

  #dialer_table .dialer_num_tr td:nth-child(3) {
    border-left: 1px solid #fafafa;
  }

  #dialer_table .dialer_num_tr:nth-child(1) td,
  #dialer_table .dialer_num_tr:nth-child(2) td,
  #dialer_table .dialer_num_tr:nth-child(3) td,
  #dialer_table .dialer_num_tr:nth-child(4) td {
    border-bottom: 1px solid #fafafa;
  }

  #dialer_table .dialer_num_tr .dialer_num {
    color: #0b559f;
    cursor: pointer;
  }

  #dialer_table .dialer_num_tr .dialer_num:hover {
    background-color: #fafafa;
  }

  #dialer_table .dialer_num_tr:nth-child(0) td {
    border-top: 1px solid #fafafa;
  }

  #dialer_table .dialer_del_td img {
    cursor: pointer;
  }
`;

export function isBase64(str: string) {
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}
