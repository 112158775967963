/** @format */

import { Row, Col, Dropdown, Menu, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Routes } from "../../utils/Routes";
import { UILink } from "../../components/ui/Link";
import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { UIText } from "../../components/ui/Text";
import { BaseColor } from "../../theme/maps/Colors";
import { useDispatch, useSelector } from "react-redux";
import { selectorPermissionList } from "../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../utils/permissions/hasPermission";
import { FleeUpdateDrawer } from "../../components/layout/SideDialogs/FleetUpdate";
import { selectorFleetUpdated } from "../../redux-modules/selectors/selectorsUI";
import { TFleet } from "../../redux-modules/declarations/maps/fleets";
import {
  actionFleetDeleteRequest,
  actionFleetListRequest,
} from "../../redux-modules/actions/actionsFleets";
import { darkenFromHex, stringToHex } from "../../utils/strings/stringToColor";

/*
  id: string;
  marca: string;
  modello: string;
  targa: string;
  costo_per_chilometro: string;
  office_id?: string;
*/

export const fleetListColumns = [
  { title: "#", dataIndex: "id", key: "id", showMobile: true },
  {
    title: "Marca",
    dataIndex: "marca",
    key: "marca",
    showMobile: true,
  },
  {
    title: "Modello",
    dataIndex: "modello",
    key: "modello",
    showMobile: true,
  },
  {
    title: "Targa",
    dataIndex: "targa",
    key: "targa",
    showMobile: true,
    //@ts-ignore
    render: function renderName(text, row, index): JSX.Element {
      return (
        <Tag
          key={index}
          color={darkenFromHex(stringToHex(row.targa))}
          style={{ borderRadius: "5px", fontSize: "14px" }}
        >
          {row.targa}
        </Tag>
      );
    },
  },
  {
    title: "€/km",
    dataIndex: "costo_per_chilometro",
    key: "costo_per_chilometro",
    showMobile: true,
  },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row_long={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row_long, index }): JSX.Element => {
  const dispatch = useDispatch();
  const permissionList = useSelector(selectorPermissionList);
  const [updateFleetVisible, setUpdateFleetVisible] = useState(false);
  const [initialValues, setInitialValues] = useState<TFleet | undefined>();
  const canDeleteFleet = hasPermission(permissionList, "Fleet", "Delete");

  const onClose = (): void => {
    setUpdateFleetVisible(false);
    setInitialValues(undefined);
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={row => {
          //@ts-ignore
          setInitialValues(row_long);
          setUpdateFleetVisible(true);
        }}
        icon={<EditOutlined />}
      >
        Gestisci
      </Menu.Item>
      {canDeleteFleet && (
        <Menu.Item
          key="2"
          onClick={() => {
            //@ts-ignore
            dispatch(actionFleetDeleteRequest(row_long.id));
          }}
          icon={<DeleteOutlined />}
        >
          Elimina
        </Menu.Item>
      )}
    </Menu>
  );

  if (!hasPermission(permissionList, "Fleet", "Update")) return <></>;
  return (
    <>
      <Dropdown overlay={menu}>
        <UIText css={"font-size: 24px; cursor: pointer;"} color={BaseColor}>
          <MoreOutlined />
        </UIText>
      </Dropdown>
      {!!initialValues && (
        <FleeUpdateDrawer
          visible={updateFleetVisible}
          onClose={onClose}
          initialValues={initialValues}
        />
      )}
    </>
  );
};
