/** @format */

import { SearchOutlined } from "@ant-design/icons";
import { Form, Drawer, Row, Col, Cascader, Empty, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../../constants/api";
import { actionTravelCreateSuccess } from "../../../../redux-modules/actions/actionsTravels";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import apiClient from "../../../../utils/apiClient";
import { showNotification } from "../../../../utils/showNotification";
import { UIButton } from "../../../ui/Button";
import { UIDatePicker } from "../../../ui/DatePicker";
import { UIInput } from "../../../ui/Input";
import { UITextArea } from "../../../ui/Textarea";

interface Props {
  visible: boolean;
  onClose: (refresh?: boolean) => void;
}

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

export const TravelCreateDrawer = ({ visible, onClose }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [userList, setUserList] = useState<TUser[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedValues, setSelectedValues] = useState([]);

  const onChangeFilters = (value: unknown) => {
    //@ts-ignore
    setSelectedValues(value);
  };

  useEffect(() => {
    setIsUserLoading(true);
    apiClient.get(API_ENDPOINTS.USERS_LIST + "?all=true").then(res => {
      setUserList(res.data.data);
      setIsUserLoading(false);
    });
  }, []);

  useEffect(() => {
    if (userList) {
      let options: Option[] = [];
      userList.forEach(user => {
        //controllo se esiste già il gruppo
        user.groups.forEach(group => {
          if (options.find(el => el.value == "group_" + group.id)) {
            if (
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.find(e => e.value == "department_" + user.department.id)
            ) {
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.find(e => e.value == "department_" + user.department.id)
                ?.children?.push({
                  value: user.id,
                  label: user.surname + " " + user.name,
                }) || console.log("");
            } else {
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.push({
                  value: "department_" + user.department.id,
                  label: group.name + " - " + user.department.name,
                  children: [
                    {
                      value: user.id,
                      label: user.surname + " " + user.name,
                    },
                  ],
                }) || console.log("");
            }
          } else {
            options.push({
              value: "group_" + group.id,
              label: group.name,
              children: [
                {
                  value: "department_" + user.department.id,
                  label: group.name + " - " + user.department.name,
                  children: [{ value: user.id, label: user.surname + " " + user.name }],
                },
              ],
            });
          }
        });
      });
      setOptions(options);
    }
  }, [userList]);

  const getUsersArray = (array: string[]): string[] => {
    let users: string[] = [];
    if (array.length > 0) {
      array.forEach((value: string) => {
        // se gruppo
        if (value.length == 1) {
          let gruppo = value[0].split("_")[1];
          userList.forEach(user => {
            if (user.groups.find(group => group.id == gruppo)) {
              users.push(user.id);
            }
          });
        }
        // se dipartimento
        else if (value.length == 2) {
          let gruppo = value[0].split("_")[1];
          let dipartimento = value[1].split("_")[1];
          userList.forEach(user => {
            if (
              user.groups.find(group => group.id == gruppo) &&
              user.department.id == dipartimento
            ) {
              users.push(user.id);
            }
          });
        }
        // se utente
        else if (value.length == 3) {
          users.push(value[2]);
        }
      });
    } else {
      users = userList.map(user => user.id);
    }

    return users;
  };

  const handleClose = () => {
    if (onClose)
      // quello che vogliamo
      onClose();
    form.resetFields();
  };

  const onSave = async () => {
    setIsLoading(true);
    await form.validateFields().then(values => {
      const obj = {
        name: form.getFieldValue("name"),
        description: form.getFieldValue("description"),
        date_from: form.getFieldValue("date_from").format("YYYY-MM-DD"),
        date_to: form.getFieldValue("date_to").format("YYYY-MM-DD"),
        user_ids: getUsersArray(selectedValues),
      };
      apiClient
        .post(API_ENDPOINTS.TRAVELS, obj)
        .then(res => {
          showNotification("success", "Viaggio salvato", "Viaggio salvato con successo!");
          form.resetFields();
          setIsLoading(false);
          // OPERARE SUL REDUCER
          dispatch(actionTravelCreateSuccess(res.data.data));
          if (onClose) onClose(true);
        })
        .catch(err => {
          form.resetFields();
          setIsLoading(false);
          showNotification(
            "error",
            "Errore", //@ts-ignore
            err.response.data.errors
              ? //@ts-ignore
                Object.values(err.response.data.errors).join(`\n`)
              : //@ts-ignore
                err.response.data.message,
          );
        });
    });
  };

  return (
    <Drawer
      title="Nuovo Viaggio"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <Row justify="space-between">
          <Col>
            <Form.Item key="submit" name="submit">
              <UIButton
                form="form_create_travel"
                key="submit"
                htmlType="submit"
                type="primary"
                loading={isLoading}
              >
                Salva
              </UIButton>
            </Form.Item>
          </Col>
          <Col>
            <UIButton onClick={() => onClose()}>Annulla</UIButton>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <>
            <Form id="form_create_travel" form={form} onFinish={onSave}>
              <Row>
                <Col span={24}>
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="name"
                        label="Nome"
                        rules={[{ required: true, message: "Dai un nome al viaggio" }]}
                      >
                        <UIInput />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="description"
                        label="Dove vai?"
                        rules={[{ required: false, message: "" }]}
                      >
                        <UIInput />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="date_from"
                        label="Data inizio"
                        rules={[{ required: true, message: "Indica l'inizio del viaggio'" }]}
                      >
                        <UIDatePicker format={"DD/MM/YYYY"} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="date_to"
                        label="Data fine"
                        rules={[{ required: true, message: "Indica la fine del viaggio" }]}
                      >
                        <UIDatePicker format={"DD/MM/YYYY"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }} gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="user_ids"
                        label="Chi partecipa?"
                        rules={[{ required: false, message: "Indica gli utenti che partecipano" }]}
                      >
                        <CustomCascader
                          style={{ width: "100%" }}
                          loading={!!isUserLoading}
                          notFoundContent={
                            isUserLoading ? (
                              <Spin size="small" />
                            ) : (
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                          }
                          showSearch
                          options={options}
                          //@ts-ignore
                          onChange={onChangeFilters}
                          multiple
                          value={selectedValues}
                          placeholder={
                            <span>
                              <SearchOutlined style={{ marginRight: "5px" }} /> - Tutti -
                            </span>
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </>
        </Col>
      </Row>
    </Drawer>
  );
};

const CustomCascader = styled(Cascader)`
  .ant-select-selector {
    border-radius: 7px !important;
  }
`;
