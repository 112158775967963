/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import {
  sagaCustomStyleOptionsUpdate,
  sagaUserAvatar,
  sagaUserCreate,
  sagaUserDelete,
  sagaUserDocumentUpload,
  sagaUsersList,
  sagaUserUpdate,
} from "../sagas/sagasUsers";

export function* watcherUsers(): unknown {
  yield takeLatest(TActionType.userCreateRequest, sagaUserCreate);
  yield takeLatest(TActionType.usersListRequest, sagaUsersList);
  yield takeLatest(TActionType.userDeleteRequest, sagaUserDelete);
  yield takeLatest(TActionType.userUpdateRequest, sagaUserUpdate);
  yield takeLatest(TActionType.userAvatarRequest, sagaUserAvatar);
  yield takeLatest(TActionType.userDocumentUploadRequest, sagaUserDocumentUpload);
  yield takeLatest(TActionType.CustomStyleOptionUpdateRequest, sagaCustomStyleOptionsUpdate);
  //TODO AGGIUNGERE LA CHIAMARA LIST CUSTOM STYLE AL LOGIN
}
