/** @format */

import {
  TActionReservationListRequest,
  TActionReservationListSuccess,
  TActionReservationListFailure,
  TActionReservationListLoadingRequest,
  TActionReservationListLoadingSuccess,
  TActionReservationListLoadingFailure,
  TActionReservationCreateRequest,
  TActionReservationCreateSuccess,
  TActionReservationCreateFailure,
  TActionReservationUpdateRequest,
  TActionReservationUpdateSuccess,
  TActionReservationUpdateFailure,
  TActionReservationUpdateReset,
  TActionReservationDeleteRequest,
  TActionReservationDeleteSuccess,
  TActionReservationDeleteFailure,
  TActionReservationDeleteReset,
} from "../declarations/actions/reservations";
import { TAction, TActionType } from "../declarations/actionTypes";
import {
  TReservationList,
  TReservationCreateRequest,
  TReservationUpdateRequest,
} from "../declarations/maps/reservations";

//List
export const actionReservationListRequest = (): TActionReservationListRequest => ({
  type: TActionType.reservationListRequest,
});

export const actionReservationListSuccess = (
  payload: TReservationList,
): TActionReservationListSuccess => ({
  type: TActionType.reservationListSuccess,
  payload,
});

export const actionReservationListFailure = (): TActionReservationListFailure => ({
  type: TActionType.reservationListFailure,
});

//---

//Loading

export const actionReservationListLoadingRequest = (): TActionReservationListLoadingRequest => ({
  type: TActionType.reservationListLoadingRequest,
});

export const actionReservationListLoadingSuccess = (): TActionReservationListLoadingSuccess => ({
  type: TActionType.reservationListLoadingSuccess,
});

export const actionReservationListLoadingFailure = (): TActionReservationListLoadingFailure => ({
  type: TActionType.reservationListLoadingFailure,
});

// ---
//Create
export const actionReservationCreateRequest = (
  payload: TReservationCreateRequest,
): TActionReservationCreateRequest => ({ type: TActionType.reservationCreateRequest, payload });

export const actionReservationCreateSuccess = (
  payload: string,
): TActionReservationCreateSuccess => ({
  type: TActionType.reservationCreateSuccess,
  payload,
});
export const actionReservationCreateFailure = (): TActionReservationCreateFailure => ({
  type: TActionType.reservationCreateFailure,
});
export const actionReservationCreateReset = (): TAction => ({
  type: TActionType.reservationCreateReset,
});
// ---
// Update
export const actionReservationUpdateRequest = (
  payload: TReservationUpdateRequest,
): TActionReservationUpdateRequest => ({
  type: TActionType.reservationUpdateRequest,
  payload,
});

export const actionReservationUpdateSuccess = (): TActionReservationUpdateSuccess => ({
  type: TActionType.reservationUpdateSuccess,
});

export const actionReservationUpdateFailure = (): TActionReservationUpdateFailure => ({
  type: TActionType.reservationUpdateFailure,
});

export const actionReservationUpdateReset = (): TActionReservationUpdateReset => ({
  type: TActionType.reservationUpdateReset,
});

// ---
// Delete
export const actionReservationDeleteRequest = (
  payload: string,
): TActionReservationDeleteRequest => ({
  type: TActionType.reservationDeleteRequest,
  payload,
});

export const actionReservationDeleteSuccess = (
  payload: string,
): TActionReservationDeleteSuccess => ({
  type: TActionType.reservationDeleteSuccess,
  payload,
});

export const actionReservationDeleteFailure = (): TActionReservationDeleteFailure => ({
  type: TActionType.reservationDeleteFailure,
});

export const actionReservationDeleteReset = (): TActionReservationDeleteReset => ({
  type: TActionType.reservationDeleteReset,
});

// ---
