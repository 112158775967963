/** @format */

import { TGroupList } from "../declarations/maps/groups";
import { TStore } from "../declarations/store";

export const selectorGroupsList = (store: TStore): TGroupList =>
  store.groups.groupList || {
    data: [],
    pagination: { current: 0, pageSize: 0, lastPage: 0, total: 0 },
    loading: false,
  };
