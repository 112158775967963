/** @format */

import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Row, Col, Avatar, Tooltip, Calendar } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../../../constants/api";
import { TRequest } from "../../../../../redux-modules/declarations/maps/requests";
import { selectorJustificativeList } from "../../../../../redux-modules/selectors/selectorsJustificatives";
import { BaseColor } from "../../../../../theme/maps/Colors";
import apiClient from "../../../../../utils/apiClient";
import UITransition from "../../../../ui/Transition";

export const MyRequestsCalendar = (): JSX.Element => {
  const justificativeTypes = useSelector(selectorJustificativeList);
  const [myRequests, setMyRequests] = useState<TRequest[]>([]);
  const [currentMonthRequest, setCurrentMonthRequest] = useState<TRequest[]>([]);

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.REQUESTS).then(r => setMyRequests(r.data.data));
  }, []);

  useEffect(() => {
    setCurrentMonthRequest(getRequestByMonth());
  }, [myRequests]);

  const getRequestByDay = (date: moment.Moment) =>
    myRequests.filter(el => {
      const from = moment(
        moment(el.datetime_from, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD") + " 00:00:00",
      );
      const to = moment(
        moment(el.datetime_to, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD") + " 23:59:59",
      );

      return date >= from && date <= to;
    });

  const getRequestByMonth = (date?: moment.Moment): TRequest[] => {
    if (!date)
      date = document.querySelector("[data-date]")
        ? moment(document.querySelector("[data-date]")?.getAttribute("data-date"))
        : moment();

    const requests = myRequests.filter(el => {
      const from = moment(
        moment(el.datetime_from, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD") + " 00:00:00",
      );
      const to = moment(
        moment(el.datetime_to, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD") + " 23:59:59",
      );

      //@ts-ignore
      return date.month() >= from.month() && date.month() <= to.month();
    });
    return requests;
  };

  //@ts-ignore
  const headerRender = ({ value, type, onChange, onTypeChange }) => {
    const current = moment(value.clone());
    return (
      <Row justify="center">
        <span
          className="back-arrow"
          onClick={() => {
            onChange(current.clone().subtract(1, "month"));
            //@ts-ignore
            setCurrentMonthRequest(getRequestByMonth(current.clone().subtract(1, "month")));
          }}
        >
          <LeftCircleOutlined />
        </span>
        <span className="month-name" data-date={current.clone().format("YYYY-MM-DD")}>
          {current.clone().format("MMMM YYYY")}
        </span>
        <span
          className="next-arrow"
          onClick={() => {
            onChange(current.clone().add(1, "month"));
            //@ts-ignore
            setCurrentMonthRequest(getRequestByMonth(current.clone().add(1, "month")));
          }}
        >
          <RightCircleOutlined />
        </span>
      </Row>
    );
  };

  const dateCellRender = (value: moment.Moment) => {
    const data = getRequestByDay(value);
    if (!data.length) return <span>{value.format("DD")}</span>;
    const bgColor =
      data.length == 1
        ? justificativeTypes.data.find(e => e.id == data[0].justificative_type_id)?.color
        : data.length == 2
        ? `linear-gradient(${
            justificativeTypes.data.find(e => e.id == data[0].justificative_type_id)?.color
          }, ${justificativeTypes.data.find(e => e.id == data[1].justificative_type_id)?.color})`
        : BaseColor;

    return (
      <>
        {data.length > 1 && (
          <Avatar
            style={{
              background: bgColor,
            }}
          >
            {value.format("DD")}
          </Avatar>
        )}
        {data.length == 1 && (
          <Tooltip
            key={data[0].id}
            title={data[0].justificative_type_name + " - " + data[0].current_status_name}
          >
            <Avatar
              style={{
                backgroundColor: bgColor,
              }}
            >
              {value.format("DD")}
            </Avatar>
          </Tooltip>
        )}
      </>
    );
  };
  return (
    <UITransition>
      <Row
        gutter={[6, 6]}
        style={{
          height: isMobileOnly ? "inherit" : "300px",
          flexFlow: isMobileOnly ? "column" : "inherit",
        }}
      >
        <Col xs={24} sm={15}>
          <CustomCalendar
            style={{ border: "1px solid #f0f0f0", borderRadius: "7px" }}
            fullscreen={false}
            //@ts-ignore
            headerRender={headerRender}
            dateFullCellRender={dateCellRender}
          />
        </Col>
        <Col
          xs={24}
          sm={9}
          style={{
            paddingTop: "0px",
            maxHeight: isMobileOnly ? "none" : "300px",
            overflowY: "scroll",
          }}
        >
          {!currentMonthRequest.length && (
            <Row
              justify="space-around"
              align="middle"
              style={{
                border: "1px solid #606060",
                backgroundColor: "#9b9b9b50",
                borderRadius: "7px",
                padding: "4px",
                margin: "2px 0px",
                textAlign: "center",
              }}
            >
              <Col>
                Nessuna richiesta <br /> per il mese selezionato
              </Col>
            </Row>
          )}
          {
            //@ts-ignore
            currentMonthRequest.map(request => (
              <Fragment key={request.id}>
                <Row
                  justify="space-around"
                  align="middle"
                  style={{
                    border:
                      "1px solid " +
                      justificativeTypes.data.find(e => e.id == request.justificative_type_id)
                        ?.color,
                    backgroundColor:
                      justificativeTypes.data.find(e => e.id == request.justificative_type_id)
                        ?.color + "50",
                    borderRadius: "7px",
                    margin: "5px 0px",
                  }}
                >
                  <Col>
                    {moment(request.datetime_from, "DD/MM/YYYY HH:mm").format("DD/MM HH:mm")} <br />{" "}
                    {moment(request.datetime_to, "DD/MM/YYYY HH:mm").format("DD/MM HH:mm")}
                  </Col>
                  <Col>{request.justificative_type_name}</Col>
                  <Col>{request.current_status_name}</Col>
                </Row>
              </Fragment>
            ))
          }
        </Col>
      </Row>
    </UITransition>
  );
};

export const CustomCalendar = styled(Calendar)`
  .ant-picker-panel {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .ant-picker-cell {
    cursor: default;
  }

  .back-arrow {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: ${BaseColor};
  }

  .next-arrow {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: ${BaseColor};
  }

  .month-name {
    font-size: 17px;
    font-weight: bold;
    margin: 0px 20px;
    text-transform: capitalize;
    color: ${BaseColor};
  }
  .ant-picker-content th {
    font-weight: bold;
  }
`;
