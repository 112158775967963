/** @format */

import React, { useEffect, useState } from "react";
import { Breadcrumb, Checkbox, Col, Form, Row, Skeleton } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UIText } from "../../ui/Text";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorDeviceDeleted,
  selectorDeviceUpdated,
  selectorLocationDeleted,
  selectorLocationUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { API_ENDPOINTS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import {
  actionLocationDeleteRequest,
  actionLocationUpdateRequest,
} from "../../../redux-modules/actions/actionsLocations";
import { UITable } from "../../ui/Table";
import { TLocation } from "../../../redux-modules/declarations/maps/locations";
import { DeleteCard } from "../../layout/DeleteCard";
import { TActionLocationDeleteRequest } from "../../../redux-modules/declarations/actions/location";
import { UILink } from "../../ui/Link";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { TActionDeviceDeleteRequest } from "../../../redux-modules/declarations/actions/devices";
import {
  actionDeviceDeleteRequest,
  actionDeviceUpdateRequest,
} from "../../../redux-modules/actions/actionsDevices";
import { TDevice } from "../../../redux-modules/declarations/maps/devices";
import { UISelect } from "../../ui/Select";
const { Option } = UISelect;

export const ViewDeviceEdit = (): JSX.Element => {
  //@ts-ignore
  const { id_device } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const [deviceInfo, setDeviceInfo] = useState<TDevice>();
  const [locations, setLocations] = useState<TLocation[]>([]);
  const isDeviceUpdated = useSelector(selectorDeviceUpdated);
  const isDeviceDeleted = useSelector(selectorDeviceDeleted);
  const permissionList = useSelector(selectorPermissionList);

  const canDeleteDevice = hasPermission(permissionList, "Device", "Delete");

  const removeDevice = (): TActionDeviceDeleteRequest =>
    dispatch(actionDeviceDeleteRequest(id_device));

  useEffect(() => {
    if (isDeviceUpdated || isDeviceDeleted) history.push(Routes.devices);
  }, [isDeviceUpdated, isDeviceDeleted]);

  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      id: id_device,
      office_id: e.office_id,
      mac_address: e.mac_address,
      nfc_code: e.nfc_code,
      code: e.code,
    };
    //dispatch
    dispatch(actionDeviceUpdateRequest(contentObj));
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      const response = await apiClient.get(API_ENDPOINTS.DEVICES + "/" + id_device);
      const location = await apiClient.get(API_ENDPOINTS.LOCATIONS);
      //@ts-ignore
      setDeviceInfo(response.data.data);
      setLocations(location.data.data);
    };
    fetchData();
  }, []);

  return !!!deviceInfo ? (
    <UICard style={{ marginBottom: "20px" }}>
      <Skeleton active />
    </UICard>
  ) : (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <Row>
            <Col>
              <UITitle level={2} color={BaseColor} data-cy="page-title">
                Modifica Dispositivo
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <UILink route={Routes.devices} label={"Sedi"} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>{deviceInfo ? deviceInfo.office_name : ""}</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
      </Row>
      <Form
        style={{ marginTop: "15px" }}
        name="update-device"
        className="update-device"
        data-cy="update-device-form"
        onFinish={handleSave}
        initialValues={deviceInfo}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="office_id"
                    label={<UIText strong={true}>Sede</UIText>}
                    rules={[{ required: true, message: "Seleziona una sede!" }]}
                    extra="La sede associata al dispositivo"
                  >
                    <UISelect>
                      {locations.map(location => (
                        <Option value={location.id}>{location.name}</Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="mac_address"
                    label={<UIText strong={true}>MAC Address</UIText>}
                    rules={[{ required: false, message: "Inserisci un MAC Address valido!" }]}
                    extra="Il MAC Address del dispositivo"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="nfc_code"
                    label={<UIText strong={true}>Codice NFC</UIText>}
                    rules={[{ required: false, message: "Inserisci un codice NFC valido!" }]}
                    extra="Il codice NFC"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={8}>
                  <Form.Item name="code" label="Codice">
                    <UIInput disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                justify="space-between"
                align="bottom"
                gutter={[12, 12]}
                style={{ marginTop: "15px" }}
              >
                <Col>
                  <UILink route={Routes.devices} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item noStyle>
                    <UIButton type="primary" data-cy="update-device-button" htmlType="submit">
                      Salva Dispositivo
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
      {canDeleteDevice && (
        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col xs={24}>
            <DeleteCard
              data-cy="remove-device-card"
              actionToDispatch={removeDevice}
              title="Elimina Dispositivo"
              text="Cliccando sul bottone elimina eliminerai definitivamente il dispositivo selezionato. Assicurati di voler rimuoverlo."
              removeButtonLabel="Elimina Dispositivo"
            />
          </Col>
        </Row>
      )}
    </>
  );
};
