/** @format */

import {
  TActionProductListRequest,
  TActionProductListSuccess,
  TActionProductListFailure,
  TActionProductListLoadingRequest,
  TActionProductListLoadingSuccess,
  TActionProductListLoadingFailure,
  TActionProductCreateRequest,
  TActionProductCreateSuccess,
  TActionProductCreateFailure,
  TActionProductUpdateRequest,
  TActionProductUpdateSuccess,
  TActionProductUpdateFailure,
  TActionProductUpdateReset,
  TActionProductDeleteRequest,
  TActionProductDeleteSuccess,
  TActionProductDeleteFailure,
  TActionProductDeleteReset,
} from "../declarations/actions/products";
import { TActionType, TAction } from "../declarations/actionTypes";
import {
  TProduct,
  TProductCreateRequest,
  TProductUpdateRequest,
} from "../declarations/maps/products";

//List
export const actionProductListRequest = (): TActionProductListRequest => ({
  type: TActionType.productListRequest,
});

export const actionProductListSuccess = (payload: TProduct[]): TActionProductListSuccess => ({
  type: TActionType.productListSuccess,
  payload,
});

export const actionProductListFailure = (): TActionProductListFailure => ({
  type: TActionType.productListFailure,
});

//---

//Loading

export const actionProductListLoadingRequest = (): TActionProductListLoadingRequest => ({
  type: TActionType.productListLoadingRequest,
});

export const actionProductListLoadingSuccess = (): TActionProductListLoadingSuccess => ({
  type: TActionType.productListLoadingSuccess,
});

export const actionProductListLoadingFailure = (): TActionProductListLoadingFailure => ({
  type: TActionType.productListLoadingFailure,
});

// ---
//Create
export const actionProductCreateRequest = (
  payload: TProductCreateRequest,
): TActionProductCreateRequest => ({ type: TActionType.productCreateRequest, payload });
export const actionProductCreateSuccess = (payload: string): TActionProductCreateSuccess => ({
  type: TActionType.productCreateSuccess,
  payload,
});
export const actionProductCreateFailure = (): TActionProductCreateFailure => ({
  type: TActionType.productCreateFailure,
});
export const actionProductCreateReset = (): TAction => ({ type: TActionType.productCreateReset });
// ---
// Update
export const actionProductUpdateRequest = (
  payload: TProductUpdateRequest,
): TActionProductUpdateRequest => ({
  type: TActionType.productUpdateRequest,
  payload,
});

export const actionProductUpdateSuccess = (): TActionProductUpdateSuccess => ({
  type: TActionType.productUpdateSuccess,
});

export const actionProductUpdateFailure = (): TActionProductUpdateFailure => ({
  type: TActionType.productUpdateFailure,
});
export const actionProductUpdateReset = (): TActionProductUpdateReset => ({
  type: TActionType.productUpdateReset,
});

// ---
// Delete
export const actionProductDeleteRequest = (payload: string): TActionProductDeleteRequest => ({
  type: TActionType.productDeleteRequest,
  payload,
});

export const actionProductDeleteSuccess = (payload: string): TActionProductDeleteSuccess => ({
  type: TActionType.productDeleteSuccess,
  payload,
});

export const actionProductDeleteFailure = (): TActionProductDeleteFailure => ({
  type: TActionType.productDeleteFailure,
});
export const actionProductDeleteReset = (): TActionProductDeleteReset => ({
  type: TActionType.productDeleteReset,
});
// ---
