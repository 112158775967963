/** @format */

import { put, call, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import { showNotification, showNotificationWithButton } from "../../utils/showNotification";
import {
  actionPresenceCreateSuccess,
  actionPresenceCreateReset,
  actionPresenceCreateFailure,
  actionPresenceListLoadingRequest,
  actionPresenceListLoadingSuccess,
  actionPresenceListFailure,
  actionPresenceListLoadingFailure,
  actionPresenceListSuccess,
  actionPresenceUpdateSuccess,
} from "../actions/actionsPresences";
import {
  TActionPresenceCreateRequest,
  TActionPresenceListRequest,
} from "../declarations/actions/presences";
import { TCustomPresence, TPresence } from "../declarations/maps/presences";
import { apiPresenceCreate, apiPresenceList } from "../../api/apiPresences";

export function* sagaPresenceCreate(action: TActionPresenceCreateRequest): unknown {
  try {
    const response = yield call(apiPresenceCreate, action.payload);

    yield put(actionPresenceCreateSuccess());
    yield put(actionPresenceCreateReset());

    if(!!response.data.data.turno_aperto || !!response.data.data.ritardo){
      showNotificationWithButton("warning", "Timbratura", response.data.data.message, "XL", "top");
    }else{
      showNotification("success", "Timbratura", response.data.data.message, 6, "XL", "top");
    }
  } catch (e) {
    yield put(actionPresenceCreateFailure());
    yield put(actionPresenceCreateReset());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
      6,
      "XL",
      "top",
    );
  }
}

export function* sagaPresenceList(action: TActionPresenceListRequest): unknown {
  try {
    yield put(actionPresenceListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TCustomPresence[]>> = yield call(
      apiPresenceList,
      action.payload,
    );

    yield put(actionPresenceListSuccess(response.data.data));
    yield put(actionPresenceListLoadingSuccess());
  } catch (e) {
    yield put(actionPresenceListFailure());
    yield put(actionPresenceListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      e.response.data.errors
        ? //@ts-ignore
          Object.values(e.response.data.errors).join(`\n`)
        : //@ts-ignore
          e.response.data.message,
    );
  }
}
