/** @format */

import { Row, Col, Collapse, List } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../../constants/api";
import { selectorDepartmentList } from "../../../../redux-modules/selectors/selectorsDepartments";
import { selectorGroupsList } from "../../../../redux-modules/selectors/selectorsGroups";
import { BaseColor } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { hexToRgb } from "../../../../utils/styles";
import UIModal from "../../../ui/Modal";
import { UIText } from "../../../ui/Text";
const { Panel } = Collapse;

interface Props {
  isModalVisible: boolean;
  closeModal: () => void;
  missingUsers: string[];
}

interface DateErrors {
  [date: string]: string[];
}
export const MissingUsersModal = ({
  isModalVisible,
  closeModal,
  missingUsers,
}: Props): JSX.Element => {
  return (
    <UIModal
      title={"Utenti Mancanti"}
      visible={isModalVisible}
      width={700}
      cancelButtonProps={{ style: { borderRadius: "7px" } }}
      onCancel={closeModal}
      onOk={closeModal}
    >
      <Row>
        <Col>
          <UIText>
            I seguenti utenti non saranno presenti nell'esportazione in quanto sprovvisti di codici
            di esportazione:
          </UIText>
        </Col>
      </Row>
      <Row style={{ margin: "10px 0px" }}>
        <Col span={24}>
          <CustomCollapse accordion defaultActiveKey={["arr"]}>
            <Panel key={"arr"} header={` (${missingUsers.length} Dipendenti)`}>
              <List
                size="small"
                bordered
                // @ts-ignore
                dataSource={missingUsers}
                renderItem={item => <List.Item>{item}</List.Item>}
              />
            </Panel>
          </CustomCollapse>
        </Col>
      </Row>
    </UIModal>
  );
};

const CustomCollapse = styled(Collapse)`
  border-radius: 7px;
  //background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.20"}!important;
  background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.25"}) !important;

  .ant-collapse-item:not(.ant-collapse-item-active):last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: none;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse > .ant-collapse-item-active:last-child {
    border-radius: none;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse-item-active:last-child,
  .ant-collapse-item-active:last-child > .ant-collapse-header {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-collapse-content {
    max-height: 180px;
    overflow-y: auto;
  }
  .ant-list-bordered {
    border: none;
  }
`;
