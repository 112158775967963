/** @format */

import {
  TActionFleetListRequest,
  TActionFleetListSuccess,
  TActionFleetListFailure,
  TActionFleetListLoadingRequest,
  TActionFleetListLoadingSuccess,
  TActionFleetListLoadingFailure,
  TActionFleetCreateRequest,
  TActionFleetCreateSuccess,
  TActionFleetCreateFailure,
  TActionFleetUpdateRequest,
  TActionFleetUpdateSuccess,
  TActionFleetUpdateFailure,
  TActionFleetUpdateReset,
  TActionFleetDeleteRequest,
  TActionFleetDeleteSuccess,
  TActionFleetDeleteFailure,
  TActionFleetDeleteReset,
} from "../declarations/actions/fleets";
import { TAction, TActionType } from "../declarations/actionTypes";
import { TFleetList, TFleetCreateRequest, TFleetUpdateRequest } from "../declarations/maps/fleets";

//List
export const actionFleetListRequest = (): TActionFleetListRequest => ({
  type: TActionType.fleetListRequest,
});

export const actionFleetListSuccess = (payload: TFleetList): TActionFleetListSuccess => ({
  type: TActionType.fleetListSuccess,
  payload,
});

export const actionFleetListFailure = (): TActionFleetListFailure => ({
  type: TActionType.fleetListFailure,
});

//---

//Loading

export const actionFleetListLoadingRequest = (): TActionFleetListLoadingRequest => ({
  type: TActionType.fleetListLoadingRequest,
});

export const actionFleetListLoadingSuccess = (): TActionFleetListLoadingSuccess => ({
  type: TActionType.fleetListLoadingSuccess,
});

export const actionFleetListLoadingFailure = (): TActionFleetListLoadingFailure => ({
  type: TActionType.fleetListLoadingFailure,
});

// ---
//Create
export const actionFleetCreateRequest = (
  payload: TFleetCreateRequest,
): TActionFleetCreateRequest => ({ type: TActionType.fleetCreateRequest, payload });

export const actionFleetCreateSuccess = (payload: string): TActionFleetCreateSuccess => ({
  type: TActionType.fleetCreateSuccess,
  payload,
});
export const actionFleetCreateFailure = (): TActionFleetCreateFailure => ({
  type: TActionType.fleetCreateFailure,
});
export const actionFleetCreateReset = (): TAction => ({
  type: TActionType.fleetCreateReset,
});
// ---
// Update
export const actionFleetUpdateRequest = (
  payload: TFleetUpdateRequest,
): TActionFleetUpdateRequest => ({
  type: TActionType.fleetUpdateRequest,
  payload,
});

export const actionFleetUpdateSuccess = (): TActionFleetUpdateSuccess => ({
  type: TActionType.fleetUpdateSuccess,
});

export const actionFleetUpdateFailure = (): TActionFleetUpdateFailure => ({
  type: TActionType.fleetUpdateFailure,
});

export const actionFleetUpdateReset = (): TActionFleetUpdateReset => ({
  type: TActionType.fleetUpdateReset,
});

// ---
// Delete
export const actionFleetDeleteRequest = (payload: string): TActionFleetDeleteRequest => ({
  type: TActionType.fleetDeleteRequest,
  payload,
});

export const actionFleetDeleteSuccess = (payload: string): TActionFleetDeleteSuccess => ({
  type: TActionType.fleetDeleteSuccess,
  payload,
});

export const actionFleetDeleteFailure = (): TActionFleetDeleteFailure => ({
  type: TActionType.fleetDeleteFailure,
});

export const actionFleetDeleteReset = (): TActionFleetDeleteReset => ({
  type: TActionType.fleetDeleteReset,
});

// ---
